import { useCallback, useState } from "react";

import { SelectableCalendar } from "../../../models/Calendar";

export const useSelectablePriceCalendar = (): SelectableCalendar => {
  const [courtIndex, setCourtIndex] = useState<number>(-1);
  const [start, setStart] = useState<number>(-1);
  const [end, setEnd] = useState<number>(-1);
  const [selecting, setSelecting] = useState<boolean>(false);

  const resetSelection = useCallback(() => {
    setStart(-1);
    setEnd(-1);
    setCourtIndex(-1);
    setSelecting(false);
  }, []);

  const beginSelection = (row: number, index: number): void => {
    setSelecting(true);
    setCourtIndex(row);
    setStart(index);
    setEnd(index);
  };

  const updateSelection = (
    event: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>,
    row: number,
    index: number,
  ): void => {
    if (!event.type.includes("touch")) {
      event.preventDefault();
    }

    if (selecting) {
      if (row !== courtIndex) {
        endSelection();
        return;
      }
      // This is important to avoid propagation to child elements
      if (event.target === event.currentTarget) {
        setEnd(index);
      }
    }
  };

  const endSelection = (): void => {
    if (selecting) {
      setSelecting(false);
    }
  };

  return {
    state: { row: courtIndex, start, end, selecting },
    beginSelection,
    updateSelection,
    endSelection,
    resetSelection,
  };
};
