import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

import { FacilityWithUtc } from "../../../../../../modules/customer/models/Facility";
import { Group } from "../../../../../../modules/player/models/Group";
import { User } from "../../../../../../modules/player/models/User";

import { useToaster } from "../../../../../../hooks/common/useToaster";

import {
  addMember,
  getGroupById,
  removeMember,
  updateGroup,
} from "../../../../../../modules/player/services/GroupService";
import { getUsers } from "../../../../../../modules/player/services/UserService";

import { Button } from "../../../../../../components/Button";
import { Dialog } from "../../../../../../components/Dialog";
import { TextInput } from "../../../../../../components/TextInput";
import { UserSearch } from "../../../../../../components/UserSearch";
import { UsersList } from "../../../../../../components/UsersList";

interface Props {
  groupId: string;
  facilityId: FacilityWithUtc["id"];
  onHide: any;
  setReload: any;
}

export const EditGroup = ({
  facilityId,
  groupId,
  onHide,
  setReload,
}: Props) => {
  const { toastError, toastSuccess } = useToaster();
  const [name, setName] = useState<string>("");
  const [group, setGroup] = useState<Group>();
  const [members, setMembers] = useState<User[]>([]);

  const onSaveName = async () => {
    try {
      await updateGroup({
        id: groupId,
        name: name,
      });
      toastSuccess.discountGroupUpdated();
      setReload(true);
    } catch (e) {
      toastError.saveDiscountGroupFailed();
    }
  };

  const onAddMember = async (selectedUsers: User[]) => {
    try {
      const updatedGroup = await addMember(groupId, selectedUsers?.[0]?.id);
      selectedUsers = [];
      setGroup(updatedGroup);

      toastSuccess.discountGroupUpdated();
    } catch (e) {
      toastError.saveDiscountGroupFailed();
    }
  };

  const onDeleteMember = async (id: User["id"]) => {
    try {
      const updatedGroup = await removeMember(groupId, id);
      setGroup(updatedGroup);
      toastSuccess.discountGroupMemberDeleted();
    } catch {
      toastError.deleteDiscountGroupMemberFailed();
    }
  };

  useEffect(() => {
    const fetchMembers = async () => {
      const fetchedGroup = await getGroupById(groupId);
      setName(fetchedGroup?.name || "");
      setGroup(fetchedGroup);
    };

    if (groupId) {
      fetchMembers();
    }
  }, [groupId]);

  useEffect(() => {
    const fetchMembers = async () => {
      try {
        const users = await getUsers(group?.members || []);
        setMembers(users);
      } catch (e) {
        toastError.fetchDiscountGroupMembersFailed();
      }
    };

    fetchMembers();
  }, [group, toastError]);

  return (
    <>
      <Dialog onHide={onHide} visible>
        <h4 className="mb-8">
          <FormattedMessage id="admin.facility.settings.groups.edit" />
        </h4>

        <FormattedMessage id="common.name" />
        <div className="flex gap-4">
          <TextInput
            name="name"
            type="text"
            value={name}
            onChange={e => setName(e.target.value)}
            autoFocus
          />
          <Button
            onClick={onSaveName}
            type="primary"
            text="Spara"
            translationName="button.save"
            disabled={!name?.length}
          />
        </div>

        <div className="mt-8">
          <FormattedMessage id="admin.player-overview.add-player" />
          <UserSearch
            facilityId={facilityId}
            title="Sök användare"
            translationId="common.search-user"
            displaySelection={false}
            onChange={onAddMember}
            multiSelect={false}
          />
        </div>

        {members?.length ? (
          <UsersList
            users={members}
            onRemove={({ id }) => onDeleteMember(id)}
            goToProfileActive
          />
        ) : (
          <div className="mt-8 px-12 text-center">
            <FormattedMessage id="pricings.discount-group.add-members" />
          </div>
        )}
      </Dialog>
    </>
  );
};
