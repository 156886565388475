import type { SWRConfiguration } from "swr";
import useSWR from "swr/immutable";

import { Series } from "../../modules/game/models/Series";

import { getScoreboard } from "../../modules/game/services/Serie";

export const useSeriesScoreboard = (
  seriesId?: Series["id"],
  options?: SWRConfiguration,
) => {
  const { data, ...rest } = useSWR(
    seriesId ? ["facility", seriesId] : null,
    ([, seriesId]) => getScoreboard(seriesId).then(res => res.data),
    options,
  );

  return {
    ...rest,
    scoreboard: data,
  };
};
