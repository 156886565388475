import {
  Series,
  SeriesMatch,
  SeriesMatchRequestFilter,
} from "../../../../../modules/game/models/Series";

export interface MatchOverviewPageState {
  errorId: string | null;
  filter: SeriesMatchRequestFilter;
  filtered: boolean;
  loading: boolean;
  matches: SeriesMatch[];
  series: Series | null;
}

export type MatchOverviewPageAction =
  | { type: "ERROR"; payload: { errorId: string } }
  | { type: "SET.LOADING" }
  | {
      type: "SET.MATCHES";
      payload: { matches: SeriesMatch[] };
    }
  | {
      type: "SET.SERIES";
      payload: { series: Series };
    }

  // Filter actions
  | { type: "CLEAR.FILTER" }
  | { type: "SET.DIVISION.IDS"; payload: string[] }
  | { type: "SET.MATCH.STATUS"; payload: number | null }
  | { type: "SET.TEAM.IDS"; payload: string[] }
  | { type: "SET_FILTER"; payload: SeriesMatchRequestFilter };

export const pageReducer = (
  state: MatchOverviewPageState,
  action: MatchOverviewPageAction,
): MatchOverviewPageState => {
  switch (action.type) {
    case "ERROR":
      return {
        ...state,
        loading: false,
        errorId: action.payload.errorId,
      };

    case "SET.LOADING":
      return {
        ...state,
        loading: true,
        errorId: null,
      };

    case "SET.MATCHES":
      return {
        ...state,
        loading: false,
        matches: action.payload.matches,
        filtered: true,
      };

    case "SET.SERIES":
      return {
        ...state,
        series: action.payload.series,
        filtered: false,
      };

    // Filter actions
    case "CLEAR.FILTER":
      return {
        ...state,
        filter: {
          ...state.filter,
          divisionIds: [],
          teamIds: [],
          matchStatus: null,
        },
      };

    case "SET.DIVISION.IDS":
      return {
        ...state,
        filter: { ...state.filter, divisionIds: action.payload, teamIds: [] },
      };

    case "SET.MATCH.STATUS":
      return {
        ...state,
        filter: { ...state.filter, matchStatus: action.payload },
      };

    case "SET.TEAM.IDS":
      return {
        ...state,
        filter: { ...state.filter, teamIds: action.payload },
      };

    case "SET_FILTER":
      return {
        ...state,
        filter: action.payload,
      };

    default: {
      const _exhaustiveCheck: never = action;
      return _exhaustiveCheck;
    }
  }
};
