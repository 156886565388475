import useSWR from "swr/immutable";

import { getFacilities } from "../../modules/customer/services/FacilityService";

export const useAdminFacilities = () => {
  const { data, ...rest } = useSWR("facilities", () =>
    getFacilities().then(res => res.data),
  );

  return {
    ...rest,
    facilities: data,
  };
};
