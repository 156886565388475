import useSWR from "swr";

import type { Series } from "../../modules/game/models/Series";

import { getNextSeason } from "../../modules/game/services/Serie";

export const useSeriesNextSeasonInfo = (seriesId?: Series["id"]) => {
  const { data, ...rest } = useSWR(
    seriesId ? ["seriesNextSeasonInfo", seriesId] : null,
    ([, seriesId]) => getNextSeason(seriesId).then(data => data?.data),
  );

  return {
    ...rest,
    seasonInfo: data,
  };
};
