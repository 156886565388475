import { useIntl } from "react-intl";

import { BookingType } from "../../models/Booking";

const bookingTypeMessageIds = {
  [BookingType.NotBookable]: "common.not-bookable-slots",
  [BookingType.Regular]: "common.booking",
  [BookingType.Series]: "common.serie",
  [BookingType.Activity]: "common.activity",
  [BookingType.Recurring]: "common.recurring-bookings",
  [BookingType.Undefined]: "common.booking",
  [BookingType.Event]: "common.event",
};

const bookingTypeColors = {
  [BookingType.NotBookable]: "#707070",
  [BookingType.Regular]: "#2eca8b",
  [BookingType.Series]: "#17a2b8",
  [BookingType.Activity]: "#94dfeb",
  [BookingType.Recurring]: "#003770",
  [BookingType.Undefined]: "#707070",
  [BookingType.Event]: "#17a2b8",
};

const bookingTypeTextColors = {
  [BookingType.NotBookable]: "#F9F9F9",
  [BookingType.Regular]: "#141517",
  [BookingType.Series]: "#141517",
  [BookingType.Activity]: "#141517",
  [BookingType.Recurring]: "#F9F9F9",
  [BookingType.Undefined]: "#F9F9F9",
  [BookingType.Event]: "#141517",
};

export const useBookingTypes = (bookingType: BookingType): string[] => {
  const intl = useIntl();
  const color = bookingTypeColors[bookingType];
  const textColor = bookingTypeTextColors[bookingType];

  const messageId = bookingTypeMessageIds[bookingType] || "common.booking";
  return [intl.formatMessage({ id: messageId }), color, textColor];
};
