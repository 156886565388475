import { useState } from "react";
import { FormattedMessage } from "react-intl";

import { DateTime } from "luxon";

import { SeriesMatch } from "../../../../../../../modules/game/models/Series";

import { Button } from "../../../../../../../components/Button";
import { Dialog } from "../../../../../../../components/Dialog";
import { ScoreManager } from "../../../../../../../modules/checkout/components/Games/ScoreManager";

interface Props {
  matchInfo: SeriesMatch;
  onFinish: () => void;
}

export const ScoreBox: React.FC<Props> = ({ matchInfo, onFinish }) => {
  const [showRegisterDialog, setShowRegisterDialog] = useState<boolean>();
  const isPassed = DateTime.utc().toMillis() > matchInfo?.startTime.toMillis();

  const handleFinished = () => {
    setShowRegisterDialog(false);
    onFinish?.();
  };

  return (
    <div className="flex flex-col bg-white p-4">
      <div className="flex items-center justify-evenly">
        <div className="flex flex-col">
          <p className="text-base font-bold">
            {matchInfo?.homeTeam?.team?.players?.[0]?.firstName}
          </p>
          <p className="text-base font-bold">
            {matchInfo?.homeTeam?.team?.players?.[1]?.firstName}
          </p>
        </div>
        <div className="inline">
          <div className="flex grow-0 flex-col">
            {matchInfo?.sets?.map((set, i) => (
              <div key={i} className="flex divide-x border-b last:border-b-0">
                <div className="w-10 text-center">{set?.homeGames}</div>
                <div className="w-10 text-center">{set?.opponentGames}</div>
              </div>
            ))}
          </div>
        </div>
        <div className="flex flex-col">
          <p className="text-base font-bold">
            {matchInfo?.opponentTeam?.team?.players?.[0]?.firstName}
          </p>
          <p className="text-base font-bold">
            {matchInfo?.opponentTeam?.team?.players?.[1]?.firstName}
          </p>
        </div>
      </div>

      {isPassed && (
        <div className="mt-2 inline self-center sm:mt-0 sm:self-end">
          <Button type="primary" onClick={() => setShowRegisterDialog(true)}>
            <FormattedMessage id="score.register-title" />
          </Button>
        </div>
      )}

      <Dialog
        onHide={() => {
          setShowRegisterDialog(false);
        }}
        visible={showRegisterDialog}
      >
        <ScoreManager
          isAdminRegister
          matchId={matchInfo?.id}
          onHasUnsavedChanges={null}
          onLocked={handleFinished}
        />
      </Dialog>
    </div>
  );
};
