import { FormattedMessage } from "react-intl";

import { faTennisBall } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

import {
  Membership,
  RenewalType,
} from "../../../../../../modules/player/models/Membership";

interface PriceOptionProps {
  active: boolean;
  selectable: boolean;
}

const PriceOption = styled.label<PriceOptionProps>`
  flex-direction: column;
  padding: 0.5rem;
  flex: 1 1 50%;
  border-radius: 4px;

  ${props =>
    props.selectable &&
    `
    cursor: pointer;
    &:hover {
      ${props => !props.active && "background: var(--gray-100);"};
    }
  `}

  ${props => props.active && "background: var(--gray-150);"}
`;

interface Props {
  membership: Membership;
  priceId: string;
  setPriceId: any;
  active: boolean;
  membershipsNeeded: Membership[];
}

export const AlternativeBody: React.FC<Props> = ({
  membership,
  priceId,
  setPriceId,
  active,
  membershipsNeeded,
}) => {
  const monthly = membership?.membershipPrices.find(
    p => p?.renewal?.renewalType === RenewalType.Monthly,
  );
  const annual = membership?.membershipPrices.find(
    p => p?.renewal?.renewalType === RenewalType.Annually,
  );
  const selected = membership?.membershipPrices.find(p => p?.id === priceId);
  const membershipNeededString = membershipsNeeded
    ? membershipsNeeded?.map(m => m?.name)?.join(" / ")
    : null;

  const change = e => {
    setPriceId(e.target.value);
  };

  return (
    <div className="flex flex-col gap-4 p-4 text-center">
      <h4>{membership?.name}</h4>
      <form className="flex gap-2">
        {monthly && (
          <PriceOption
            active={monthly?.id === selected?.id}
            selectable={!membership?.isCurrentUserSubscribed}
          >
            <h4 className="text-blue-700">{monthly?.price.valueInclTax}</h4>
            <p className="text-xs text-gray-800">
              {monthly.price.currencyCode} /{" "}
              <FormattedMessage id="common.month" />
            </p>
            <div>
              <input
                disabled={active || !membership?.isPurchasable}
                checked={monthly?.id === selected?.id}
                value={monthly?.id}
                type="radio"
                id="monthly"
                name="choice"
                onChange={change}
              />
            </div>
          </PriceOption>
        )}
        {annual && (
          <PriceOption
            active={annual?.id === selected?.id}
            selectable={!membership?.isCurrentUserSubscribed}
          >
            <h4 className="text-blue-700">{annual?.price.valueInclTax}</h4>
            <p className="text-xs text-gray-800">
              {annual.price.currencyCode} /{" "}
              <FormattedMessage id="common.year" />
            </p>
            <div>
              <input
                disabled={active || !membership?.isPurchasable}
                checked={annual?.id === selected?.id}
                value={annual?.id}
                type="radio"
                id="annually"
                name="choice"
                onChange={change}
              />
            </div>
          </PriceOption>
        )}
      </form>
      {!!membership?.benefits?.length && (
        <>
          <div className="border-b border-gray-50" />
          <h5>
            <FormattedMessage
              id="membership.whats-included.title"
              defaultMessage="Detta ingår"
            />
          </h5>
          {membership?.benefits?.map(b => (
            <div className="flex items-center text-left" key={b?.id}>
              <p>
                <span className="mr-2 text-primary">
                  <FontAwesomeIcon icon={faTennisBall} className="inline" />
                </span>
                {b?.description}
              </p>
            </div>
          ))}
        </>
      )}

      {!membership?.isPurchasable && membershipNeededString && (
        <div className="flex h-full items-end justify-center">
          <p className="text-xs">
            <FormattedMessage
              id="membership.non-purchasable.info"
              defaultMessage="Kräver {membership}"
              values={{ membership: membershipNeededString }}
            />
          </p>
        </div>
      )}
    </div>
  );
};
