import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { useIsAuthenticated } from "@azure/msal-react";
import { faBell } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";

import { getMaintenanceMessageColor } from "../helpers/maintenanceMessage";

import { useMaintenanceMessage } from "../hooks/swr/useMaintenanceMessage";

import { Breadcrumbs, CrumbText, useBreadcrumbs } from "./Breadcrumbs";
import { Header } from "./Header/Header";

export const Layout = ({ children }: React.PropsWithChildren<unknown>) => {
  const isAuthenticated = useIsAuthenticated();

  if (!isAuthenticated) return <>{children}</>;

  return (
    <div className="container flex flex-col">
      <div className="mb-16 lg:mb-[110px]">
        <Header />
      </div>

      <MaintenanceMessage />

      <BreadcrumbsAndTitle />

      <main className="mt-6 px-4 pb-4 md:mt-8 lg:mt-10 2xl:px-0">
        {children}
      </main>
    </div>
  );
};

const BreadcrumbsAndTitle = () => {
  const breadcrumbs = useBreadcrumbs();

  return (
    <>
      {breadcrumbs.length > 1 && <Breadcrumbs breadcrumbs={breadcrumbs} />}
      {breadcrumbs.length > 0 && (
        <Title text={breadcrumbs.at(-1)?.title ?? ""} />
      )}
    </>
  );
};

const Title = ({ text }: { text: CrumbText }) => {
  if (!text) {
    return null;
  }

  return (
    <h1 className="ml-4 mt-6 self-start rounded bg-purewhite px-5 py-3 text-xl leading-none text-primary shadow-m md:mt-8 md:px-7 md:py-5 md:text-4xl lg:mt-10 xl:text-[42px]">
      {React.isValidElement(text)
        ? text
        : text instanceof Promise
          ? "..."
          : text}
    </h1>
  );
};

const MaintenanceMessage = () => {
  const intl = useIntl();

  const { maintenanceMessage } = useMaintenanceMessage();

  const messageRef = useRef<HTMLParagraphElement>(null);
  const [isMessageTruncated, setIsMessageTruncated] = useState(false);
  const [showFullMessage, setShowFullMessage] = useState(false);

  useEffect(() => {
    const message = messageRef.current;

    if (message) {
      setIsMessageTruncated(
        message.scrollWidth > message.clientWidth ||
          message.scrollHeight > message.clientHeight,
      );
    }
  }, [showFullMessage, maintenanceMessage?.message]);

  if (!maintenanceMessage || maintenanceMessage.message === "") {
    return null;
  }

  return (
    <div className="mt-3 flex items-center gap-4 bg-purewhite px-4 py-3 lg:gap-6 lg:px-6 lg:py-4">
      <FontAwesomeIcon
        icon={faBell}
        className={clsx(
          "flex-none",
          getMaintenanceMessageColor(maintenanceMessage.severity),
        )}
      />
      <div className="flex min-w-0 flex-auto gap-2">
        <p
          ref={messageRef}
          className={clsx("lg:text-md", !showFullMessage && "truncate")}
        >
          {maintenanceMessage.message}

          {showFullMessage && (
            <>
              {" "}
              <button
                className="align-middle text-xs text-gray-700 underline lg:text-sm"
                onClick={() => setShowFullMessage(false)}
                title={intl.formatMessage({
                  id: "maintenance-message.show-less",
                })}
              >
                <FormattedMessage id="maintenance-message.show-less" />
              </button>
            </>
          )}
        </p>

        <button
          className={clsx(
            "flex-none text-xs text-gray-700 underline lg:text-sm",
            isMessageTruncated && !showFullMessage ? "visible" : "collapse",
          )}
          onClick={() => setShowFullMessage(true)}
          title={intl.formatMessage({
            id: "maintenance-message.show-more",
          })}
        >
          <FormattedMessage id="maintenance-message.show-more" />
        </button>
      </div>
    </div>
  );
};
