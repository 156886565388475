import useSWR, { SWRConfiguration } from "swr";

import { getFriends } from "../../modules/player/services/FriendsService";

import { useCurrentUserId } from "../../recoil/currentUserIdState";

export const useFriends = (options?: SWRConfiguration) => {
  const currentUserId = useCurrentUserId();

  const { data, ...rest } = useSWR(
    currentUserId ? ["friends", currentUserId] : null,
    () => getFriends(),
    options,
  );

  return {
    ...rest,
    friends: data,
  };
};
