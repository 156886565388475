import React, { ReactNode } from "react";
import { useIntl } from "react-intl";

import { IconDefinition, IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Button } from "./Button";
import { Dialog } from "./Dialog";
import { ProgressSpinner } from "./ProgressSpinner";

interface Props {
  icon?: IconDefinition | IconProp;
  visible: boolean;
  title?: string;
  text?: string | ReactNode;
  denyText?: string;
  confirmText?: string;
  loading?: boolean;
  onHide: () => void;
  onCancel: () => void;
  onSubmit: () => void;
  buttonSize?: React.ComponentProps<typeof Button>["size"];
  confirmButtonType?: React.ComponentProps<typeof Button>["type"];
}

export const ConfirmationDialog = (props: React.PropsWithChildren<Props>) => {
  const intl = useIntl();

  return (
    <>
      <Dialog visible={props?.visible} onHide={() => props?.onHide()}>
        {props?.loading ? (
          <ProgressSpinner />
        ) : (
          <div className="p-8 text-center">
            <>
              {props?.icon && (
                <div className="mb-4 flex justify-center text-[3rem] text-primary">
                  <FontAwesomeIcon icon={props.icon} />
                </div>
              )}
              {props?.title && <h3 className="mb-4">{props?.title}</h3>}
              {props?.text && <p className="mt-4">{props?.text}</p>}
            </>

            {props?.children}

            <div className="mt-10 grid grid-cols-2 gap-4">
              <Button
                className={
                  props?.confirmButtonType === "danger"
                    ? "border-pureblack"
                    : ""
                }
                size={props?.buttonSize}
                type="default"
                style={{ justifyContent: "center" }}
                onClick={props?.onCancel}
                text={
                  props?.denyText ||
                  intl.formatMessage({
                    id: "common.cancel",
                  })
                }
              />
              <Button
                size={props?.buttonSize}
                type={props?.confirmButtonType || "primary"}
                onClick={props?.onSubmit}
                text={
                  props?.confirmText ||
                  intl.formatMessage({
                    id:
                      props.confirmButtonType !== "danger"
                        ? "common.yes-continue"
                        : "common.yes-im-sure!",
                  })
                }
              />
            </div>
          </div>
        )}
      </Dialog>
    </>
  );
};
