export interface ApiResponse<T> {
  count: number;
  data: T[];
}

export interface ApiSingleResponse<T> {
  data: T;
  error: string | null;
  isSuccessful: boolean;
}

export interface IDirectory {
  id: string;
  name: string;
}

export enum GenderType {
  Male = 0,
  Female = 1,
  Mix = 2,
}

export enum RecurringBookingType {
  Daily = 0,
  Weekly = 1,
}
