import { useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { faUserMinus, faUserPlus } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

import { useIsMobile } from "../../../hooks/common/useIsMobile";
import { useToaster } from "../../../hooks/common/useToaster";
import { useCurrentUser } from "../../../hooks/swr/useCurrentUser";

import {
  getFriendById,
  removeFriendFromList,
  sendFriendRequest,
} from "../services/FriendsService";

import { Button } from "../../../components/Button";
import { ConfirmationDialog } from "../../../components/ConfirmationDialog";

interface IProp {
  userId?: string;
}

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const FriendRequestButton: React.FC<IProp> = ({ userId }) => {
  const { currentUser } = useCurrentUser();
  const { toastInfo, toastError, toastSuccess } = useToaster();
  const [isFriend, setIsFriend] = useState<boolean>(false);
  const intl = useIntl();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const isMobile = useIsMobile();

  useEffect(() => {
    const abortController = new AbortController();

    if (userId) {
      getIsFriend(userId, abortController);
    }

    return () => abortController.abort();
  }),
    [userId];

  const handleSendFriendReuest = () => {
    sendFriendRequest(userId).then(toastInfo.friendRequestSent);
  };

  const getIsFriend = async (
    userId: string,
    abortController: AbortController,
  ) => {
    try {
      const friend = await getFriendById(userId, abortController?.signal);
      if (friend && !abortController?.signal?.aborted) {
        setIsFriend(friend.isFriend);
      }
    } catch (err) {
      toastError.generalFailure();
    }
  };

  const handleRemoveFormSubmitted = async () => {
    const abortController = new AbortController();

    try {
      const response = await removeFriendFromList(
        userId,
        abortController?.signal,
      );
      if (response && !abortController?.signal?.aborted) {
        setIsFriend(false);
        toastSuccess.friendDeleted();
        setDeleteDialogOpen(false);
      }
    } catch (err) {
      toastError.generalFailure();
    } finally {
      setDeleteDialogOpen(false);
    }
  };

  const handleRemoveButtonClick = () => setDeleteDialogOpen(true);

  return (
    <>
      <div>
        {currentUser?.id !== userId && isFriend != null && (
          <ButtonWrapper>
            {!isFriend ? (
              isMobile ? (
                <div
                  onClick={handleSendFriendReuest}
                  style={{ fontSize: "0.9rem" }}
                >
                  <FontAwesomeIcon icon={faUserPlus} color="var(--primary)" />
                </div>
              ) : (
                <Button
                  type="small"
                  icon={faUserPlus}
                  onClick={handleSendFriendReuest}
                  text="Lägg till vän"
                  translationName="profile.button.add-friend"
                />
              )
            ) : isMobile ? (
              <div
                onClick={handleRemoveButtonClick}
                style={{ fontSize: "0.9rem" }}
              >
                <FontAwesomeIcon icon={faUserMinus} color="var(--primary)" />
              </div>
            ) : (
              <Button
                type="small"
                icon={faUserMinus}
                text="Ta bort vän"
                onClick={handleRemoveButtonClick}
                translationName="profile.button.remove-friend"
              />
            )}
          </ButtonWrapper>
        )}
      </div>

      <div>
        <ConfirmationDialog
          visible={deleteDialogOpen}
          title={intl.formatMessage({
            id: "profile.button.remove-friend",
            defaultMessage: "Ta bort vän",
          })}
          text={intl.formatMessage({
            id: "profile.friendlist.friendlistbody.remove-friend-message",
            defaultMessage: "Vill du verkligen ta bort från vänlistan?",
          })}
          denyText={intl.formatMessage({
            id: "profile.friendlist.friendlistbody.reject",
            defaultMessage: "Nej, behåll vän",
          })}
          confirmText={intl.formatMessage({
            id: "common.confirm.remove",
            defaultMessage: "Ja, ta bort!",
          })}
          loading={false}
          onHide={() => setDeleteDialogOpen(false)}
          onCancel={() => setDeleteDialogOpen(false)}
          onSubmit={handleRemoveFormSubmitted}
        />
      </div>
    </>
  );
};
