import { useMemo, useRef } from "react";

import SimpleCrypto from "simple-crypto-js";

export const useInvitationLink = (bookingId: string): string => {
  const simpleCryptoRef = useRef(
    new SimpleCrypto(process.env.REACT_APP_SIMPLE_CRYPTO_KEY),
  );

  const invitationLink = useMemo(() => {
    const encrypted = simpleCryptoRef.current.encrypt(bookingId);

    return "/booking/invitation/" + encodeURIComponent(encrypted);
  }, [bookingId]);

  return invitationLink;
};
