import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { faClose, faSearch } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InputText } from "primereact/inputtext";

import { SeriesTeam } from "../../../models/Series";

import DivisionsEditorTeam from "./DivisionEditorTeam";

interface IProps {
  teams: SeriesTeam[];
}

const DivisionsEditorTeams: React.FC<IProps> = ({ teams = [] }) => {
  const intl = useIntl();
  const [searchText, setSearchText] = useState<string>("");
  const [filteredTeams, setFilteredTeams] = useState<SeriesTeam[]>([]);

  useEffect(() => {
    if (!teams.length) {
      return setFilteredTeams(teams);
    }

    const searchResult = teams
      .filter(t =>
        t.teamMembers.some(tm =>
          tm.displayName?.match(new RegExp(searchText, "i")),
        ),
      )
      .slice(0, teams?.length);

    setFilteredTeams(searchResult);
  }, [teams, searchText]);

  const handleFilterCleanup = () => {
    setSearchText("");
  };

  return (
    <div className="flex-none bg-gray-100 py-4 md:-m-6 md:mt-0 md:w-64">
      <div className="p-input-icon-right mx-3 mb-3 block">
        {searchText ? (
          <FontAwesomeIcon icon={faClose} onClick={handleFilterCleanup} />
        ) : (
          <FontAwesomeIcon icon={faSearch} />
        )}
        <InputText
          className="w-full"
          value={searchText}
          placeholder={intl.formatMessage({
            id: "divisions-editor-teams.search-input-placeholder",
            defaultMessage: "Sök efter lag/spelare",
          })}
          onChange={e => setSearchText(e.target.value)}
        />
      </div>
      <div>
        {filteredTeams.length > 0 ? (
          <ul className="divide-y divide-gray-200">
            {filteredTeams.map(team => (
              <DivisionsEditorTeam key={team.id} team={team} />
            ))}
          </ul>
        ) : (
          <div className="p-4 text-sm">
            <FormattedMessage
              id="divisions-editor-teams.nomore-teams"
              defaultMessage="Det finns inga fler lag att lägga till / visa"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default DivisionsEditorTeams;
