import React from "react";

import styled from "styled-components";

const TimeSlot = styled.div`
  cursor: pointer;
  width: 100%;
  position: relative;
}
`;
interface IProps {
  row: number;
  column: number;
  color: string;
  selectedColor: string;
  width: number;
  isSelected: boolean;
  onBeginSelection: (row: number, index: number) => void;
  onEndSelection: () => void;
  onUpdateSelection: (
    event: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>,
    row: number,
    index: number,
  ) => void;
}

const PriceCalendarSlot: React.FC<IProps> = ({
  row,
  column,
  width,
  color,
  selectedColor,
  isSelected,
  onBeginSelection,
  onEndSelection,
  onUpdateSelection,
}) => {
  const handleTouchMove = (event: React.TouchEvent<HTMLDivElement>) => {
    const targetEl = document.elementFromPoint(
      event.touches[0].clientX,
      event.touches[0].clientY,
    );

    onUpdateSelection(
      event,
      parseInt(targetEl.getAttribute("data-row")),
      parseInt(targetEl.getAttribute("data-column")),
    );
  };

  return (
    <TimeSlot
      data-row={row}
      data-column={column}
      onMouseDown={() => onBeginSelection(row, column)}
      onMouseUp={onEndSelection}
      onMouseEnter={e => onUpdateSelection(e, row, column)}
      onTouchStart={() => onBeginSelection(row, column)}
      onTouchEnd={onEndSelection}
      onTouchMove={e => handleTouchMove(e)}
      style={{
        background: `${color}`,
      }}
    >
      {isSelected && (
        <div
          style={{
            position: "absolute",
            background: selectedColor,
            width: `calc(100% * ${width})`,
            minHeight: "100%",
            display: "flex",
            zIndex: 2,
            top: 0,
            left: 0,
          }}
        />
      )}
    </TimeSlot>
  );
};

export default PriceCalendarSlot;
