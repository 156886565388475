import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";

import {
  faCalendarClock,
  faCalendarTimes,
  faChevronRight,
  faCreditCard,
  faPerson,
  faSitemap,
  faTableList,
  faTrash,
  faWarehouse,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateTime } from "luxon";
import styled from "styled-components";

import { GenderType } from "../../../../models/common";
import { BuyableType } from "../../../checkout/models/Payment";
import { IActivity } from "../../models/Activity";
import { SeasonStatus } from "../../models/Series";

import { getMyProfilePath } from "../../../../helpers/pathHelpers";

import { useToaster } from "../../../../hooks/common/useToaster";
import { useDateFormat } from "../../../../hooks/useDateFormat";
import { useJoinActivity } from "../../hooks/useJoinActivity";

import { cancelActivity, getMyActivities } from "../../services/Activities";

import { ConfirmationDialog } from "../../../../components/ConfirmationDialog";
import { NothingToShow } from "../../../../components/NothingToShow";
import { ProgressSpinner } from "../../../../components/ProgressSpinner";

import {
  luxonDateFormat,
  luxonDateTimeFormat,
} from "../../../../utils/dateFormats";
import { userProfileTabs } from "../../../player/constants";

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const ActivityCardHeader = styled.div`
  display: flex;
  color: var(--dark);
  font-weight: var(--bold);
  font-size: var(--h5);
  margin-bottom: 0.5rem;
`;

const NotPayedBadge = styled.div`
  position: absolute;
  right: 0.5rem;
  top: -0.75rem;
  background: var(--gray-100);
  padding: 0.25rem;

  color: var(--danger);
  font-size: 0.75rem;
  font-weight: var(--bold);
`;

const ActivityContent = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  font-size: 0.9rem;
  width: 100%;
`;

const ActivityRow = styled.div`
  width: 100%;

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 0.5rem;
  color: var(--gray-400);

  :nth-child(1) {
    margin-bottom: 0.5rem;
  }

  > div {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  > div + div {
    border: 0;
    padding-left: 0;
  }

  @media (min-width: 600px) {
    display: flex;

    > div + div {
      padding-left: 1rem;
      border-left: 1px solid var(--gray-200);
    }
  }
`;

const GameSettings = styled(Link)`
  display: flex;
  font-weight: var(--bold);
  justify-content: flex-end;
  margin: 0.2rem 1;
  padding: 0;
  gap: 0.2rem;
  color: var(--primary);
  cursor: pointer;
  align-items: center;

  :hover {
    color: var(--primary-light);
  }

  svg {
    color: var(--primary);
  }
`;

const ButtonsContainer = styled.div`
  display: grid;
  gap: 0.5rem;
  place-items: flex-end;

  position: absolute;
  right: 1rem;
  bottom: 1rem;

  > div,
  button,
  a {
    width: 100%;
    display: grid;
    grid-template-columns: 1rem 1fr;
    align-items: center;

    gap: 0.25rem;

    color: var(--primary);
    cursor: pointer;
    font-weight: var(--bold);

    svg {
      color: var(--gray-400);
    }
  }
  @media (min-width: 600px) {
    bottom: 1.5rem;
    gap: 0.2rem;
  }
`;

interface IProp {
  page?: number;
  pageSize?: number;
  isUpcoming?: boolean;
}

export const UserActivities: React.FC<IProp> = ({
  isUpcoming = true,
  page = 0,
  pageSize = 2,
}) => {
  const intl = useIntl();

  const [activities, setActivities] = useState<IActivity[]>([]);
  const toaster = useToaster();
  const [activityToCancel, setActivityToCancel] = useState<IActivity | null>(
    null,
  );
  const [cancleActivityModalOpen, setCancleActivityModalOpen] = useState(false);
  const [loadingActivities, setLoadingActivities] = useState(true);
  const [totalActivities, setTotalActivities] = useState(1);

  useEffect(() => {
    const abortController = new AbortController();

    setLoadingActivities(true);

    const fetch = async () => {
      try {
        const result = await getMyActivities(
          page,
          pageSize,
          isUpcoming,
          abortController.signal,
        );

        if (abortController.signal.aborted) return;

        if (result.count > 0) {
          setActivities(result.data);
          setTotalActivities(result.count);
        }
      } catch (e) {
        console.log(e);
      } finally {
        setLoadingActivities(false);
      }
    };

    fetch();

    return () => abortController.abort();
  }, [isUpcoming, page, pageSize]);

  const handleCancel = async (
    activityId: string,
    activityType: BuyableType,
  ) => {
    try {
      const result = await cancelActivity(activityId, activityType);

      if (result) {
        toaster.toastSuccess.bookingCancelled();
        const result = await getMyActivities(page, pageSize, isUpcoming);
        setActivities(result?.data);
      }
    } catch {
      toaster.toastError.cancelBookingFailed();
    } finally {
      setCancleActivityModalOpen(false);
      setActivityToCancel(null);
    }
  };

  return (
    <>
      {isUpcoming && (
        <h3>
          <FormattedMessage id="activities.upcoming-activities" />
        </h3>
      )}
      {loadingActivities ? (
        <ProgressSpinner />
      ) : (
        <>
          {!activities?.length ? (
            <NothingToShow translationId="activities.no-upcoming-activites" />
          ) : (
            <CardWrapper>
              {activities?.map(activity => (
                <ActivityCard
                  key={activity.id}
                  activity={activity}
                  handleCancelActivity={() => {
                    setActivityToCancel(activity);
                    setCancleActivityModalOpen(true);
                  }}
                />
              ))}
              {totalActivities > pageSize && isUpcoming && (
                <GameSettings
                  to={`${getMyProfilePath()}/${userProfileTabs.ACTIVITIES}`}
                >
                  <FormattedMessage id="activity.show-all" />
                  <FontAwesomeIcon size="sm" icon={faChevronRight} />
                </GameSettings>
              )}
            </CardWrapper>
          )}
        </>
      )}

      {cancleActivityModalOpen && activityToCancel && (
        <ConfirmationDialog
          visible
          title={intl.formatMessage({
            id: "activity.cancel-confirmation-message",
          })}
          onCancel={() => {
            setCancleActivityModalOpen(false);
            setActivityToCancel(null);
          }}
          onSubmit={() =>
            handleCancel(activityToCancel.id, activityToCancel.buyableType)
          }
          icon={faTrash}
          text={intl.formatMessage({
            id: "activities.cancel.are-you-sure",
          })}
          denyText={intl.formatMessage({
            id: "common.no",
          })}
          confirmText={intl.formatMessage({
            id: "common.yes",
          })}
          loading={false}
          onHide={() => {
            setCancleActivityModalOpen(false);
            setActivityToCancel(null);
          }}
        />
      )}
    </>
  );
};

const ActivityCard = ({
  activity,
  handleCancelActivity,
}: {
  activity: IActivity;
  handleCancelActivity: (
    activityId: IActivity["id"],
    activityType: BuyableType,
  ) => void;
}) => {
  const { df } = useDateFormat(activity.facilityId);
  const { handlePayActivity, isLoading } = useJoinActivity();
  const { toastInfo } = useToaster();

  return (
    <div className="relative min-h-[7.2rem] rounded border border-gray-50 p-4">
      {!activity?.isPayedForCurrentUser && (
        <NotPayedBadge>
          <FormattedMessage id="common.notpayed" defaultMessage="Ej betald" />
        </NotPayedBadge>
      )}
      <ActivityCardHeader>{activity?.name}</ActivityCardHeader>
      <ActivityContent>
        <div>
          <ActivityRow>
            <div>
              <FontAwesomeIcon icon={faWarehouse} />
              <Link to={`/venues/${activity?.facilityId}`}>
                {activity?.facilityName}
              </Link>
            </div>
            <div>
              <FontAwesomeIcon icon={faSitemap} />
              {BuyableType[activity?.buyableType]}
            </div>
            <div>
              <FontAwesomeIcon icon={faPerson} />
              {GenderType[activity?.gender]}
            </div>
          </ActivityRow>
          <ActivityRow>
            <div>
              <FontAwesomeIcon icon={faCalendarClock} />
              <FormattedMessage id="common.start-time" />{" "}
              {df(
                activity?.startTime,
                activity?.buyableType == 2
                  ? luxonDateFormat
                  : luxonDateTimeFormat,
              )}
            </div>
            {activity?.buyableType === BuyableType.Series && (
              <div>
                <FormattedMessage
                  id="activities.last-cancel-date"
                  values={{
                    date: df(
                      activity?.registrationOpenTo,
                      activity?.buyableType == 2
                        ? luxonDateFormat
                        : luxonDateTimeFormat,
                    ),
                  }}
                />
              </div>
            )}
          </ActivityRow>
        </div>

        <ButtonsContainer>
          {activity?.participantReferenceId &&
            (activity?.seasonStatus === SeasonStatus.Active ||
              activity?.seasonStatus === SeasonStatus.Closed) && (
              <Link
                className="flex"
                to={`/series/${activity?.id}/scoreboard/${activity?.participantReferenceId}`}
              >
                <FontAwesomeIcon icon={faTableList} />
                <FormattedMessage id="activity.scoreboard" />
              </Link>
            )}

          {!activity?.isPayedForCurrentUser && (
            <button
              type="button"
              onClick={() => handlePayActivity(activity)}
              disabled={isLoading}
            >
              <FontAwesomeIcon icon={faCreditCard} />
              <FormattedMessage id="common.pay" />
            </button>
          )}

          {activity?.buyableType === BuyableType.Series && (
            <button
              type="button"
              onClick={() => {
                activity?.registrationOpenTo > DateTime.utc()
                  ? handleCancelActivity(activity?.id, activity?.buyableType)
                  : null;
              }}
              style={
                activity?.registrationOpenTo < DateTime.utc()
                  ? {
                      color: "var(--gray-400)",
                      cursor: "default",
                    }
                  : {}
              }
            >
              <FontAwesomeIcon icon={faCalendarTimes} />
              <FormattedMessage id="common.unbook" />
            </button>
          )}

          {activity?.buyableType === BuyableType.Event && (
            <button
              type="button"
              onClick={() => {
                if (
                  DateTime.utc().plus({
                    hour: activity.facilityBookingRules
                      ?.numberOfHoursBeforeActivityCancellation,
                  }) > activity.startTime
                ) {
                  toastInfo.message("activities.cancel-too-late");
                  return;
                }

                handleCancelActivity(activity?.id, activity?.buyableType);
              }}
            >
              <FontAwesomeIcon icon={faCalendarTimes} />
              <FormattedMessage id="common.unbook" />
            </button>
          )}
        </ButtonsContainer>
      </ActivityContent>
    </div>
  );
};
