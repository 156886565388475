import React, { memo } from "react";

import styled from "styled-components";

import { User } from "../../../../player/models/User";

import { ProfileImageWithFallback } from "../../../../../components/ProfileImageWithFallback";

const ImageWrapper = styled.div.attrs(
  (props: { size: string; shadow: boolean }) => props,
)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${props => props.size};
  height: ${props => props.size};
  border-radius: 50%;
  overflow: hidden;

  img {
    width: ${props => props.size};
    object-fit: cover;
  }

  box-shadow: ${props => (props.shadow ? "10px 10px 20px #00000029" : "none")};
`;

interface IProps {
  user: User;
  className?: string;
  size?: string;
  shadow?: boolean;
  disabled?: boolean;
  title?: string;
}

export const Player: React.FC<IProps> = memo(function Box({
  user,
  className = "",
  size,
  shadow = false,
  disabled = false,
  title,
}) {
  return (
    <div className={`${className} match-player-avatar`}>
      <ImageWrapper size={size ?? "1.875rem"} shadow={shadow}>
        <ProfileImageWithFallback
          width={size}
          height={size}
          firstName={user?.firstName}
          lastName={user?.lastName}
          src={user?.profileImage}
          disabled={disabled}
          title={title}
        />
      </ImageWrapper>
    </div>
  );
});
