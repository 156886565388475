import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ProgressSpinner } from "primereact/progressspinner";

import {
  FacilityAmenity,
  FacilityAmenityUpdateRequest,
} from "../../../../../modules/customer/models/Facility";

import { useToaster } from "../../../../../hooks/common/useToaster";
import { useSelectedFacility } from "../../../../../hooks/swr/useSelectedFacility";

import {
  getAmenityList,
  updateFacilityAmenity,
} from "../../../../../modules/customer/services/FacilityService";

import { FacilitySubmitResetButtons } from "../../../../../modules/customer/components/FacilitySubmitResetButtons";

import { amenitiesIcons } from "../../../../../utils/amenitiesIconMap";

export const Amenities: React.FC = () => {
  const { selectedFacility, mutate } = useSelectedFacility();
  const { toastError, toastSuccess } = useToaster();
  const [loading, setLoading] = useState(true);
  const [amenities, setAmenities] = useState<FacilityAmenity[]>([]);
  const [selectedAmenities, setSelectedAmenities] = useState<string[]>([]);

  useEffect(() => {
    getAmenities(0, 100);
  }, []);

  const getAmenities = async (page: number, pageSize: number) => {
    try {
      const amenities = await getAmenityList(page, pageSize);

      setAmenities(amenities?.data || []);
    } catch {
      toastError.fetchAmenitiesFailed();
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setSelectedAmenities(selectedFacility?.amenities.map(i => i.id));
  }, [selectedFacility]);

  const equals = (a, b) => JSON.stringify(a) === JSON.stringify(b);

  const handleSelectAmenity = async (amenityId: string) => {
    const removeIndex = selectedAmenities.indexOf(amenityId);
    if (removeIndex > -1) {
      selectedAmenities.splice(removeIndex, 1);
      setSelectedAmenities(() => [...selectedAmenities]);
    } else {
      setSelectedAmenities(ids => [...ids, amenityId]);
    }
  };

  const onSubmit = async () => {
    try {
      const amenitiesRequest: FacilityAmenityUpdateRequest = {
        amenities: selectedAmenities,
      };
      const response = await updateFacilityAmenity(
        selectedFacility?.id,
        amenitiesRequest,
      );
      toastSuccess.changesSaved();
      mutate(response.data);

      return true;
    } catch {
      toastError.someChangesUpdateFailed();
    }
  };

  return loading ? (
    <ProgressSpinner />
  ) : (
    <div>
      <h3 className="mb-10 text-xl">
        <FormattedMessage id="common.amenities" />
      </h3>
      <form className="flex flex-wrap gap-8">
        <div className="grid w-full grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
          {amenities?.map(({ id, name, iconName, translationName }) => (
            <div
              key={id}
              onClick={() => handleSelectAmenity(id)}
              className={`align-center flex cursor-pointer gap-4 whitespace-nowrap hover:text-blue-300 focus:text-blue-300 ${
                selectedAmenities?.includes(id)
                  ? "text-primary"
                  : "text-gray-400"
              }`}
            >
              {amenitiesIcons[iconName] && (
                <FontAwesomeIcon
                  className="w-8 text-2xl"
                  icon={amenitiesIcons[iconName]}
                />
              )}
              <h6>
                <FormattedMessage id={translationName} defaultMessage={name} />
              </h6>
            </div>
          ))}
        </div>

        <FacilitySubmitResetButtons
          onSubmit={onSubmit}
          onReset={() => {
            setSelectedAmenities(selectedFacility?.amenities.map(i => i.id));
          }}
          disabled={equals(
            selectedAmenities?.sort(),
            selectedFacility?.amenities.map(i => i.id).sort(),
          )}
        />
      </form>
    </div>
  );
};
