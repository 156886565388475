import React from "react";

import clsx from "clsx";
import { uniqueId } from "lodash";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  label?: string;
  inputId?: string;
}

export const RadioButton = ({ className, label, ...rest }: Props) => {
  const wrapperClasses = clsx("flex items-center gap-3", className);

  const inputId = "id-" + (rest.inputId ?? rest.name ?? uniqueId());

  return (
    <label id={rest.id} className={wrapperClasses} htmlFor={inputId}>
      <input
        id={inputId}
        type="radio"
        className="peer h-5 w-5 rounded-full accent-primary"
        {...rest}
      />

      {label && (
        <p className="peer-disabled:text-gray-700">
          {label}
          {rest.required && <span className="text-error"> *</span>}
        </p>
      )}
    </label>
  );
};
