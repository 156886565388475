import { useIntl } from "react-intl";
import { NavLink, NavLinkProps } from "react-router-dom";

import { IconLookup } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AnimatePresence, MotionProps, motion } from "framer-motion";
import { useRecoilValue } from "recoil";

import { useIsAdmin } from "../../../hooks/swr/useIsAdmin";

import { isHeaderSearchOpenState } from "../Header";
import { adminMenuItems, menuItems } from "./MenuItems";

export const Menu = () => {
  const intl = useIntl();
  const isAdmin = useIsAdmin();
  const isSearchOpen = useRecoilValue(isHeaderSearchOpenState);
  const items = isAdmin ? adminMenuItems : menuItems;

  return (
    <AnimatePresence>
      {!isSearchOpen && (
        <motion.nav
          className="hidden flex-1 divide-x min-[700px]:text-primary min-[850px]:flex"
          variants={{
            visible: {
              transition: { staggerChildren: 0.05 },
            },
            hidden: {
              transition: { staggerChildren: 0.05, staggerDirection: -1 },
            },
          }}
          animate="visible"
          exit="hidden"
          initial="hidden"
        >
          {items.map(item => (
            <MenuSelector
              key={item.textId}
              text={intl.formatMessage({
                id: item.textId,
              })}
              icon={item.icon}
              to={item.to}
              exact={item.exact}
            />
          ))}
        </motion.nav>
      )}
    </AnimatePresence>
  );
};

const MotionNavLink = motion(NavLink);

type Props = NavLinkProps &
  MotionProps & {
    text: string;
    icon: IconLookup;
  };

const MenuSelector = ({ text, icon, ...navLinkProps }: Props) => {
  return (
    <MotionNavLink
      variants={{
        visible: {
          opacity: 1,
          transition: { duration: 0.2 },
        },
        hidden: {
          opacity: 0,
          transition: { duration: 0.1 },
        },
      }}
      className="group flex flex-shrink basis-[150px] items-center justify-center px-4 text-center transition-colors"
      activeClassName="text-purewhite group is-active bg-gradient-to-t from-primary-dark to-primary"
      {...navLinkProps}
    >
      <div className="flex flex-col gap-2">
        <FontAwesomeIcon
          icon={icon}
          size="xl"
          className="text-gray-500 transition-colors group-hover:text-primary group-[.is-active]:text-primary-light"
        />
        <p className="text-sm font-medium text-pureblack transition-colors group-[.is-active]:text-purewhite">
          {text}
        </p>
      </div>
    </MotionNavLink>
  );
};
