import clsx from "clsx";
import { FormikErrors } from "formik";

import { GenderType } from "../../../../../../models/common";
import { GeneralActivityRequest } from "../../../../../../modules/game/models/GeneralActivities";

import { useGenderTypes } from "../../../../../../hooks/useGenderTypes";

import { GenderOption } from "./components/GenderOption";

interface Props {
  className?: string;
  disabled?: boolean;
  label?: string;
  name?: string;
  required?: boolean;
  value: GenderType;
  // TODO: Make this general? Currently only accepts formik.setFieldValue.
  onChange?: (
    field: string,
    value: any,
    shouldValidate?: boolean,
  ) => Promise<void> | Promise<FormikErrors<GeneralActivityRequest>>;
}

export const GenderPicker: React.FC<Props> = ({
  className,
  disabled,
  value,
  label = "",
  name,
  required,
  onChange,
}) => {
  const { genderTypes } = useGenderTypes();

  const wrapperClasses = clsx("flex flex-col gap-1.5 text-sm", className);

  return (
    <div id={name} className={wrapperClasses}>
      <label htmlFor={name} className="font-bold">
        {label}

        {required && <span className="text-red-500"> *</span>}
      </label>
      <div className="flex gap-2.5">
        {genderTypes.map(gender => (
          <GenderOption
            key={gender.id}
            disabled={disabled}
            selected={value === parseInt(gender.id)}
            gender={parseInt(gender.id)}
            onChange={(gender: GenderType) => onChange(name, gender, true)}
          />
        ))}
      </div>
    </div>
  );
};
