import { DateTime } from "luxon";

import { DateOnly } from "../../../models/DateOnly";
import { ImportResponse } from "../../../models/ImportResponse";
import { NewUserRequest, NewUserResponse } from "../../../models/NewUser";
import { Permissions } from "../../../models/Permissions";
import {
  CoinTransactionLog,
  CoinTransactionLogApiResponse,
  TransactionLogs,
  TransactionLogsApiResponse,
} from "../../../models/TransactionLogs";
import { ApiResponse, ApiSingleResponse } from "../../../models/common";
import { Payment } from "../../checkout/models/Payment";
import { FacilityWithUtc } from "../../customer/models/Facility";
import { CardVerifyRequest } from "../models/Membership";
import {
  type AdminOverviewUser,
  type AdminOverviewUserApiResponse,
  AdminOverviewUserInfo,
  AdminOverviewUsersCount,
  AdminOverviewUsersFilters,
  CardInfo,
  CardInfoResponse,
  FacilityUserRelationRequest,
  UpdateUserRequest,
  User,
  UserApiResponse,
} from "../models/User";

import {
  anonymousFetchApi,
  fetchApi,
  upload,
} from "../../../services/legacyApiClient";

const apiVersion = "player/v2";
const apiVersionV3 = "player/v3";

export const getUser = async (
  userId?: string,
  signal?: AbortSignal,
): Promise<User> => {
  const user = (
    (await fetchApi({
      uri: `${apiVersion}/users/${userId}`,
      signal,
    })) as ApiSingleResponse<UserApiResponse>
  )?.data;
  return formatUserFromApi(user);
};

export const adminGetUser = async (
  userId = "",
  signal?: AbortSignal,
): Promise<User> => {
  const user = (
    (await fetchApi({
      uri: `${apiVersion}/users/admin/user/${userId}`,
      signal,
    })) as ApiSingleResponse<UserApiResponse>
  )?.data;
  return formatUserFromApi(user);
};

export const getCurrentUser = async (signal?: AbortSignal): Promise<User> => {
  const user = (
    (await fetchApi({
      uri: `${apiVersion}/users`,
      signal,
    })) as ApiSingleResponse<UserApiResponse>
  )?.data;

  return formatUserFromApi(user);
};

export const getUsers = async (
  userIds?: string[],
  signal?: AbortSignal,
): Promise<User[]> => {
  const users = (
    (await fetchApi({
      method: "POST",
      uri: `${apiVersion}/users`,
      payload: {
        userIds: userIds,
        country: "SE",
      },
      signal,
    })) as ApiSingleResponse<UserApiResponse[]>
  )?.data;

  return users.map(formatUserFromApi);
};

export const saveUser = async (
  data: User,
): Promise<ApiSingleResponse<User>> => {
  const payload = {
    ...data,
    lastActivity: data?.lastActivity?.toUTC().toISO(),
    birthDate: data?.birthDate?.toISO(),
  };

  const response = (await fetchApi({
    method: "PUT",
    uri: `${apiVersion}/users`,
    payload: payload,
  })) as ApiSingleResponse<UserApiResponse>;

  return {
    ...response,
    data: formatUserFromApi(response.data),
  };
};

export const adminUpdateUser = async (
  countryCode: string,
  data: UpdateUserRequest,
) => {
  const payload = {
    ...data,
    nationalIdentificationNumber: data.nationalIdentificationNumber || null,
    birthDate: data.birthDate?.toISO(),
  };

  await fetchApi({
    method: "PUT",
    uri: `${apiVersion}/users/${countryCode}/profile`,
    payload: payload,
  });
};

export const uploadProfileImage = async (file: File): Promise<User> => {
  const data = new FormData();

  data.append("formFiles", file, file.name);

  const user = (
    (await upload(
      `${apiVersion}/users/profileimage`,
      data,
    )) as ApiSingleResponse<UserApiResponse>
  )?.data;

  return formatUserFromApi(user);
};

export const deleteProfileImage = async (): Promise<void> => {
  await fetchApi({
    method: "DELETE",
    uri: `${apiVersion}/users/profileimage`,
  });
};

export const queryUsers = async (
  query: string,
  facilityId: FacilityWithUtc["id"] | null,
  isPublicProfileSearch: boolean,
  signal?: AbortSignal,
): Promise<ApiResponse<User>> => {
  if (typeof facilityId === "undefined") {
    throw new Error("Facility id is undefined");
  }

  const response = (await fetchApi({
    method: "POST",
    uri: `${apiVersion}/users/query`,
    payload: {
      query,
      facilityId,
      isPublicProfileSearch,
    },
    signal,
  })) as ApiResponse<UserApiResponse>;

  return {
    ...response,
    data: response.data.map(formatUserFromApi),
  };
};

export const queryActiveUsers = async (
  query: string,
  facilityId: FacilityWithUtc["id"],
  signal?: AbortSignal,
): Promise<ApiResponse<User>> => {
  const response = (await fetchApi({
    method: "GET",
    uri: `${apiVersion}/users/query/activeusers?query=${encodeURIComponent(
      query,
    )}&facilityId=${facilityId}`,
    signal,
  })) as ApiResponse<UserApiResponse>;

  return {
    ...response,
    data: response.data.map(formatUserFromApi),
  };
};

export const queryPlayers = async (
  query: string,
  facilityId: FacilityWithUtc["id"],
  signal?: AbortSignal,
): Promise<ApiResponse<User>> => {
  const response = (await fetchApi({
    method: "POST",
    uri: `${apiVersion}/users/query/players`,
    payload: {
      query,
      facilityId,
    },
    signal,
  })) as ApiResponse<UserApiResponse>;

  return {
    ...response,
    data: response.data.map(formatUserFromApi),
  };
};

export const importUsersToFacility = async (
  facilityId: FacilityWithUtc["id"],
  file: File,
  system: string,
): Promise<ImportResponse> => {
  const data = new FormData();

  data.append("formFiles", file, file.name);

  return (
    (await upload(
      `${apiVersion}/users/import/${facilityId}/${system}`,
      data,
    )) as ApiSingleResponse<ImportResponse>
  )?.data;
};

export const createUserToFacility = async (
  facilityId: string,
  payload: NewUserRequest,
): Promise<NewUserResponse> => {
  return (
    (await fetchApi({
      method: "POST",
      uri: `${apiVersion}/users/createUser/${facilityId}/`,
      payload,
    })) as ApiSingleResponse<NewUserResponse>
  )?.data;
};

export const getUserCardInfoAsAdmin = async (
  facilityId: string,
  userId: string,
  signal?: AbortSignal,
): Promise<CardInfo | null> => {
  const cardInfo = (await fetchApi({
    method: "GET",
    uri: `${apiVersion}/users/cardInfo/${facilityId}/${userId}`,
    signal,
  })) as ApiSingleResponse<CardInfoResponse>;

  if (!cardInfo.data) {
    return null;
  }

  return formatCardInfoFromApi(cardInfo.data);
};

export const getUserCardInfo = async (
  signal?: AbortSignal,
): Promise<CardInfo | null> => {
  const cardInfo = (await fetchApi({
    method: "GET",
    uri: `${apiVersion}/users/cardInfo`,
    signal,
  })) as ApiSingleResponse<CardInfoResponse | null>;
  if (!cardInfo?.data) return null;
  return formatCardInfoFromApi(cardInfo.data);
};

export const cardVerify = async (
  data: CardVerifyRequest,
  signal?: AbortSignal,
): Promise<Payment> =>
  (
    (await fetchApi({
      method: "POST",
      payload: data,
      uri: `${apiVersion}/users/cardVerify`,
      signal,
    })) as ApiSingleResponse<Payment>
  )?.data;

export const adminUpdateFacilityUserRelation = async (
  userId: User["id"],
  data: FacilityUserRelationRequest,
  signal?: AbortSignal,
): Promise<boolean> => {
  const response = (
    (await fetchApi({
      method: "PUT",
      payload: data,
      uri: `${apiVersion}/users/admin/facilityuserrelation/${userId}`,
      signal,
    })) as ApiSingleResponse<boolean>
  )?.data;

  return response;
};

export const updateFacilityUserRelation = async (
  data: FacilityUserRelationRequest,
  signal?: AbortSignal,
): Promise<User> => {
  const user = (
    (await fetchApi({
      method: "PUT",
      payload: data,
      uri: `${apiVersion}/users/facilityuserrelation`,
      signal,
    })) as ApiSingleResponse<UserApiResponse>
  )?.data;

  return formatUserFromApi(user);
};

export const getUserPermissions = async (
  signal?: AbortSignal,
): Promise<Permissions> =>
  (
    (await fetchApi({
      uri: `${apiVersion}/users/permissions`,
      signal,
    })) as ApiSingleResponse<Permissions>
  )?.data;

export const getUserLocale = async (
  signal?: AbortSignal,
): Promise<User["locale"]> =>
  (
    (await fetchApi({
      uri: `${apiVersion}/users/locale/`,
      signal,
    })) as ApiSingleResponse<User["locale"]>
  )?.data;

export const updateUserLocale = async (
  locale: User["locale"],
  signal?: AbortSignal,
): Promise<boolean> =>
  (
    (await fetchApi({
      method: "PUT",
      uri: `${apiVersion}/users/locale/${locale}`,
      signal,
    })) as ApiSingleResponse<boolean>
  )?.data;

export const getCoinsLogs = async (
  userId: User["id"],
  facilityId: FacilityWithUtc["id"],
  lastPageItemId: string,
  pageSize: number,
  fromDate?: DateOnly,
  toDate?: DateOnly,
  isDescending?: boolean,
  signal?: AbortSignal,
): Promise<ApiSingleResponse<TransactionLogs>> => {
  try {
    const dateRange =
      fromDate && toDate
        ? `&fromDate=${fromDate.toJSON()}&toDate=${toDate.toJSON()}`
        : "";
    const response = await fetchApi<
      ApiSingleResponse<TransactionLogsApiResponse>
    >({
      uri: `${apiVersion}/coins/${userId}/transactionlog/paginated/${facilityId}?lastPageItemId=${lastPageItemId}&PageSize=${pageSize}&orderByDesc=${isDescending}${dateRange}`,
      signal,
    });
    const {
      data: { coinsTransactions, totalCount, filteredCount },
      error,
      isSuccessful,
    } = response as ApiSingleResponse<TransactionLogsApiResponse>;
    return {
      data: {
        coinsTransactions: coinsTransactions.map(formatTransactionLogFromApi),
        totalCount,
        filteredCount,
      },
      error,
      isSuccessful,
    };
  } catch (e) {
    return { data: null, error: e, isSuccessful: false };
  }
};

export const updateUserSubscription = async (
  link: string,
  signal?: AbortSignal,
): Promise<string> =>
  (
    (await anonymousFetchApi({
      method: "PUT",
      uri: `${apiVersion}/users/unsubscribe/${link}`,
      signal,
    })) as ApiSingleResponse<string>
  )?.data;

export const getUsersForAdminOverview = async (
  params: {
    facilityId: FacilityWithUtc["id"];
    lastPage?: number;
    filter: AdminOverviewUsersFilters;
    query?: string | null;
  },
  signal?: AbortSignal,
): Promise<AdminOverviewUserInfo<AdminOverviewUser>> => {
  const urlSearchParams = new URLSearchParams();

  for (const key in params) {
    if (params[key] !== null) {
      if (Array.isArray(params[key])) {
        for (const value of params[key]) {
          urlSearchParams.append(key, value);
        }
      } else if (typeof params[key] === "object") {
        for (const objectKey in params[key]) {
          urlSearchParams.append(`${key}.${objectKey}`, params[key][objectKey]);
        }
      } else {
        urlSearchParams.set(key, params[key]);
      }
    }
  }

  return fetchApi({
    method: "GET",
    uri: `${apiVersionV3}/users/customeroverview/?${urlSearchParams.toString()}`,
    signal,
  }).then(data => {
    if (!data) {
      return { users: [], lastPage: 0 };
    }

    const info = data as ApiSingleResponse<
      AdminOverviewUserInfo<AdminOverviewUserApiResponse>
    >;

    return {
      users: info.data.users.map(user => ({
        ...user,
        lastActivity: user.lastActivity
          ? DateTime.fromISO(user.lastActivity, { setZone: true })
          : null,
      })),
      lastPage: info.data.lastPage,
    };
  });
};

export const getUsersCountForAdminOverview = async (
  params: {
    facilityId: FacilityWithUtc["id"];
    filter: AdminOverviewUsersFilters;
    query?: string | null;
  },
  signal?: AbortSignal,
): Promise<AdminOverviewUsersCount> => {
  const urlSearchParams = new URLSearchParams();

  for (const key in params) {
    if (params[key] !== null) {
      if (Array.isArray(params[key])) {
        for (const value of params[key]) {
          urlSearchParams.append(key, value);
        }
      } else if (typeof params[key] === "object") {
        for (const objectKey in params[key]) {
          urlSearchParams.append(`${key}.${objectKey}`, params[key][objectKey]);
        }
      } else {
        urlSearchParams.set(key, params[key]);
      }
    }
  }

  return (
    (await fetchApi({
      method: "GET",
      uri: `${apiVersionV3}/users/customeroverviewcount/?${urlSearchParams.toString()}`,
      signal,
    })) as ApiSingleResponse<AdminOverviewUsersCount>
  )?.data;
};

export const sendCustomerEmail = async (
  data: Record<string, any>,
  signal?: AbortSignal,
): Promise<ApiSingleResponse<any>> =>
  (await fetchApi({
    method: "POST",
    uri: `${apiVersion}/users/sendemailstocustomers`,
    payload: data,
    signal,
  })) as ApiSingleResponse<any>;

export const getUserCSVExport = async (
  payload: Record<string, any>,
  signal?: AbortSignal,
): Promise<Blob> =>
  (await fetchApi({
    method: "POST",
    uri: `${apiVersion}/users/exportusers`,
    payload,
    signal,
    parseDataAs: "blob",
  })) as Blob;

export const requestUserDeletion = async () => {
  await fetchApi({
    method: "DELETE",
    uri: `${apiVersion}/users/`,
  });
};

export const adminRemoveUserFromVenue = async (
  facilityId: FacilityWithUtc["id"],
  userId: User["id"],
) => {
  await fetchApi({
    method: "DELETE",
    uri: `${apiVersion}/users/admin/facility/${facilityId}/user/${userId}`,
  });
};

const formatUserFromApi = (user: UserApiResponse): User => ({
  ...user,
  lastActivity: user.lastActivity
    ? DateTime.fromISO(user.lastActivity, { setZone: true })
    : null,

  country: user.country ?? "",
  birthDate: user.birthDate ? DateOnly.fromISODate(user.birthDate) : null,
  socialSecurityNumber: user.socialSecurityNumber ?? "",
  streetAddress: user.streetAddress ?? "",
  zipCode: user.zipCode ?? "",
  city: user.city ?? "",

  // Force to booleans because api sends them as null if not set.
  consentEmailNewsMarketingCourt22:
    user.consentEmailNewsMarketingCourt22 ?? false,
  consentEmailNewsMarketingFacility:
    user.consentEmailNewsMarketingFacility ?? false,
  consentPublicProfile: user.consentPublicProfile ?? false,
});

const formatCardInfoFromApi = (cardInfo: CardInfoResponse): CardInfo => ({
  ...cardInfo,
  expiryDate: DateTime.fromISO(cardInfo.expiryDate, { setZone: true }),
});

const formatTransactionLogFromApi = (
  transactionLog: CoinTransactionLogApiResponse,
): CoinTransactionLog => ({
  ...transactionLog,
  createdDate: DateTime.fromISO(transactionLog.createdDate, { setZone: true }),
  previousExpires: DateTime.fromISO(transactionLog.previousExpires, {
    setZone: true,
  }),
  currentExpires: DateTime.fromISO(transactionLog.currentExpires, {
    setZone: true,
  }),
  buyableTypeDetails: {
    ...transactionLog.buyableTypeDetails,
    startDate: DateOnly.fromISODate(
      transactionLog.buyableTypeDetails?.startDate,
    ),
  },
});
