import React, { useEffect, useMemo, useState } from "react";

import styled from "styled-components";

import { FacilityWithUtc } from "../../../../customer/models/Facility";

interface IProps {
  facility?: FacilityWithUtc;
}

const Container = styled.div`
  display: flex;
  height: 2.5rem;
  justify-content: space-between;
  background: var(--gray-100);
`;

const HeaderSlot = styled.div`
  display: flex;
  flex: 1 0 0;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
  font-weight: var(--bold);
  border-left: 1px solid var(--gray-200);
  border-bottom: 1px solid var(--gray-200);
`;

const EmptyBlock = styled.div`
  flex: 0 0 10%;
  overflow: hidden;
  white-space: nowrap;
  height: 100%;
  border-bottom: 1px solid var(--gray-200);
  background: var(--gray-100);
`;

const PriceCalendarHead: React.FC<IProps> = ({ facility }) => {
  const [timeIntervals, setTimeIntervals] = useState<string[]>([]);

  useEffect(() => {
    if (facility) {
      const array = [];
      for (let i = 0; i < 24; i++) {
        array.push(i < 10 ? `0${i}` : `${i}`);
      }
      setTimeIntervals(array);
    }
  }, [facility]);

  const headerCellWidth: string = useMemo(() => {
    return ((100 / timeIntervals.length - 1) * 2).toFixed(2);
  }, []);

  return (
    <Container>
      <EmptyBlock />
      {timeIntervals.map(_openhour => {
        return (
          <HeaderSlot key={_openhour} style={{ width: headerCellWidth + "%" }}>
            {_openhour}
          </HeaderSlot>
        );
      })}
    </Container>
  );
};

export default PriceCalendarHead;
