import { useState } from "react";

import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import type { FacilityWithUtc } from "../../../../../modules/customer/models/Facility";
import {
  type Group,
  GroupTypeStrings,
} from "../../../../../modules/player/models/Group";

import { useFacilityGroups } from "../../../../../hooks/swr/useFacilityGroups";

import { Button } from "../../../../../components/Button";
import { DataTable } from "../../../../../components/DataTable/DataTable";
import { AddGroup } from "./components/AddGroup";
import { EditGroup } from "./components/EditGroup";

interface Props {
  facilityId: FacilityWithUtc["id"];
}

export function Groups({ facilityId }: Props) {
  const [currentGroup, setCurrentGroup] = useState<Group>();
  const [showEditGroupDialog, setShowEditGroupDialog] = useState(false);
  const [showAddGroupDialog, setShowAddGroupDialog] = useState(false);

  const { groups, isLoading, mutate } = useFacilityGroups(
    facilityId,
    GroupTypeStrings.DISCOUNT,
  );

  return (
    <>
      <DataTable
        loading={isLoading}
        data={groups}
        columns={[
          {
            field: "name",
            body: (group: Group) => group?.name,
            id: "common.name",
          },
          {
            field: "action",
            body: ({ id }: Group) => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "1rem",
                }}
              >
                <button
                  type="button"
                  className="p-1 transition-colors hover:text-primary"
                  onClick={() => {
                    setCurrentGroup(
                      groups?.find((group: Group) => group.id === id),
                    );
                    setShowEditGroupDialog(true);
                  }}
                >
                  <FontAwesomeIcon className="cursor-pointer" icon={faPen} />
                </button>
              </div>
            ),
          },
        ]}
      />

      <Button
        className="mt-4 sm:w-full"
        onClick={() => setShowAddGroupDialog(true)}
        translationName="admin.facility.settings.groups.create-new"
        type="add"
        buttonType="button"
      />

      {showEditGroupDialog && currentGroup?.id && (
        <EditGroup
          groupId={currentGroup?.id}
          facilityId={facilityId}
          onHide={() => setShowEditGroupDialog(false)}
          setReload={() => mutate()}
        />
      )}

      {showAddGroupDialog && (
        <AddGroup
          onHide={() => setShowAddGroupDialog(false)}
          facilityId={facilityId}
          setReload={() => mutate()}
        />
      )}
    </>
  );
}
