import { Fragment } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";

import { faAddressCard } from "@fortawesome/pro-solid-svg-icons";

import { Membership } from "../../../../../modules/player/models/Membership";
import { User } from "../../../../../modules/player/models/User";

import { MembershipStatus } from "../../../../../components/MembershipStatus";
import { AdminPlayerGroupSectionCard } from "./components/AdminPlayerGroupSectionCard";

interface Props {
  user: User;
  memberships?: Membership[];
}

export const AdminPlayerGroupSection: React.FC<Props> = ({
  user,
  memberships,
}) => {
  const { formatMessage } = useIntl();

  return (
    <div>
      <h3>
        <FormattedMessage id="common.memberships" />
      </h3>

      <div className="mt-4 flex flex-col gap-y-8">
        <AdminPlayerGroupSectionCard
          icon={faAddressCard}
          title={formatMessage({ id: "common.membership" })}
        >
          {!memberships?.length ? (
            <span>
              <FormattedMessage id="common.no-memberships" />
            </span>
          ) : (
            <div className="grid grid-cols-[1fr_auto] gap-x-4 gap-y-4 whitespace-nowrap">
              {memberships?.map(m => {
                return (
                  <Fragment key={m.id}>
                    <div className="col-span-1 space-x-2">
                      <Link
                        to={`/admin/settings/3?membershipId=${m.id}`}
                        className="font-medium text-black"
                      >
                        {m.name}
                      </Link>
                    </div>

                    <MembershipStatus
                      className="col-span-1"
                      status={m.users.find(u => u.userId === user?.id)?.status}
                    />
                  </Fragment>
                );
              })}
            </div>
          )}
        </AdminPlayerGroupSectionCard>
      </div>
    </div>
  );
};
