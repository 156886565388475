import React, { useCallback, useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { faDownload } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateTime } from "luxon";
import { Calendar } from "primereact/calendar";
import { useRecoilState } from "recoil";
import styled from "styled-components";

import { breakpoints } from "../../../../../appConstants/common";

import { DateOnly } from "../../../../../models/DateOnly";
import {
  SettlementReport,
  SettlementReportResponse,
} from "../../../../../modules/customer/models/SettlementReport";

import { useAppSelector } from "../../../../../hooks/store/store";
import { useFacilityLocalization } from "../../../../../hooks/store/useFacilityLocalization";
import { useCurrencyFormat } from "../../../../../hooks/useCurrencyFormat";
import { useDateFormatWithSelectedFacility } from "../../../../../hooks/useDateFormat";

import {
  downloadSettlementReportPDF,
  getSettlementReports,
} from "../../../../../modules/customer/services/SettlementReportsService";

import { BackEndPaginatedDataTable } from "../../../../../components/DataTable/BackEndPaginatedDataTable";
import { CalendarInput } from "../../../../../components/inputs/CalendarInput";

import { pageNumberState } from "../../../../../recoil/Admin/settings/settlementReportsState";
import { useSelectedFacilityId } from "../../../../../recoil/selectedFacilityIdState";
import { luxonDateFormat } from "../../../../../utils/dateFormats";

const ROWS_PER_PAGE = 10;

const CalendarWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
`;

const CalendarInputWrapper = styled.div`
  @media (min-width: ${breakpoints.MOBILE}) {
    width: 14rem;
  }

  @media (max-width: ${breakpoints.MOBILE}) {
    flex: 1;
  }
`;

const Container = styled.div`
  h3 {
    margin-bottom: 1rem;
  }
`;

export const FacilitySettlementReports: React.FC = () => {
  const intl = useIntl();
  const [settlementReports, setSettlementReports] =
    useState<SettlementReportResponse>(null);
  const [pageNumber, setPageNumber] = useRecoilState<number>(pageNumberState);
  const [fromDate, setFromDate] = useState<DateTime>(null);
  const [toDate, setToDate] = useState<DateTime>(null);
  const [isEndDateSelected, setIsEndDateSelected] = useState<boolean>(false);
  const selectedFacilityId = useSelectedFacilityId();
  const { dfInterval } = useDateFormatWithSelectedFacility();
  const [isLoading, setIsLoading] = useState(false);
  const [resetDates, setResetDates] = useState<boolean>(false);
  const localization = useFacilityLocalization();
  const { cf } = useCurrencyFormat(localization?.currencyCode, {
    minimumFractionDigits: 2,
  });

  const selectedFacility = useAppSelector(
    state => state.facilities.selectedFacility,
  );

  const getReportName = useCallback(
    ({ fromDate, toDate }: SettlementReport) => {
      const period = dfInterval(fromDate, toDate, luxonDateFormat);

      return intl.formatMessage(
        {
          id: "facility-settings.settlement-reports.pdf-name",
          defaultMessage: "Avräkningsrapport {period}",
        },
        { period },
      );
    },
    [],
  );

  const columns = [
    {
      field: "facilityId",
      body: getReportName,
      id: "common.name",
      defaultMessage: "Namn",
    },
    {
      field: "totalAmount",
      body: ({ totalAmount }) => cf(totalAmount),
      id: "common.total-amount",
      defaultMessage: "Totalt belopp",
    },
    {
      field: "pdf",
      body: (row: SettlementReport) =>
        row.fortnoxDocumentNumber && (
          <div
            style={{ cursor: "pointer" }}
            onClick={() =>
              downloadSettlementReportPDF(
                row.fortnoxDocumentNumber,
                getReportName(row),
              )
            }
          >
            <FontAwesomeIcon icon={faDownload} />
          </div>
        ),
      id: "common.download-pdf",
      defaultMessage: "Ladda ned PDF",
    },
  ];

  const fetch = useCallback(
    async (
      dateFrom: DateTime | null,
      dateTo: DateTime | null,
      pageNumber: number,
    ) => {
      setIsLoading(true);
      const reports = await getSettlementReports(
        selectedFacilityId,
        dateFrom ? DateOnly.fromDateTime(dateFrom) : null,
        dateTo ? DateOnly.fromDateTime(dateTo) : null,
        pageNumber,
        ROWS_PER_PAGE,
      );
      reports && setSettlementReports(reports);
      setIsLoading(false);
    },
    [selectedFacilityId, pageNumber],
  );

  useEffect(() => {
    fetch(fromDate, toDate, pageNumber);
  }, [pageNumber, toDate, selectedFacilityId]);

  const ref = useRef<Calendar>();

  return (
    <Container>
      <h3>
        <FormattedMessage
          id="facility-settings.settlement-reports.title"
          values={{ name: selectedFacility?.name }}
          defaultMessage="Avräkningsrapporter för {name}"
        />
      </h3>

      <CalendarWrapper>
        <CalendarInputWrapper>
          <CalendarInput
            placeholder={intl.formatMessage({
              id: "common.filter.date",
              defaultMessage: "Filtrera på datum",
            })}
            ref={ref}
            selectionMode="range"
            onChange={e => {
              if (!e.value) {
                setFromDate(null);
                setToDate(null);
                setResetDates(true);
                fetch(null, null, pageNumber);
                return;
              }

              const [start, end] = e.value as unknown as DateTime[];
              if (start && end) {
                setFromDate(start);
                setToDate(end);
                setIsEndDateSelected(true);
                setPageNumber(1);
                ref.current?.hide();
              }
              if (start && !end) {
                setIsEndDateSelected(false);
              }
            }}
            maxDate={DateTime.local()}
            isEndDateSelected={isEndDateSelected}
            resetDates={resetDates}
          />
        </CalendarInputWrapper>
      </CalendarWrapper>

      <BackEndPaginatedDataTable
        loading={isLoading}
        rowsPerPage={ROWS_PER_PAGE}
        pagination={settlementReports?.totalNUmberOfReports > ROWS_PER_PAGE}
        useColumnBorder
        data={settlementReports?.items.sort((a, b) =>
          b.fromDate < a.fromDate ? -1 : b.fromDate > a.fromDate ? 1 : 0,
        )}
        columns={columns}
        emptyMessage={
          <FormattedMessage
            id="facility-settings.settlement-reports.no-data"
            defaultMessage="Det finns inga avräkningsrapporter"
          />
        }
        totalNumberOfRecords={settlementReports?.totalNUmberOfReports}
      />
    </Container>
  );
};
