import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { useSWRConfig } from "swr";

import { User } from "../../../../modules/player/models/User";

import { useToaster } from "../../../../hooks/common/useToaster";

import { adminUpdateFacilityUserRelation } from "../../../../modules/player/services/UserService";

import { Checkbox } from "../../../../components/Checkbox";
import { ConfirmationDialog } from "../../../../components/ConfirmationDialog";

import { useSelectedFacilityId } from "../../../../recoil/selectedFacilityIdState";

interface Props {
  user: User;
}

export const AdminPlayerConsentSection: React.FC<Props> = ({ user }) => {
  const selectedFacilityId = useSelectedFacilityId();
  const { toastError } = useToaster();
  const { formatMessage } = useIntl();
  const { mutate } = useSWRConfig();
  const [showConfirmationDialog, setShowConfirmationDialog] =
    useState<boolean>(false);
  const [facilityEmailConsent, setFacilityEmailConsent] = useState<boolean>(
    user?.facilityUserRelations?.find(
      fr => fr.facilityId === selectedFacilityId,
    )?.consentEmail || false,
  );

  const handleConsentEmailChange = async () => {
    setFacilityEmailConsent(!facilityEmailConsent);

    try {
      if (!selectedFacilityId || !user?.id) return;

      await adminUpdateFacilityUserRelation(user?.id, {
        facilityId: selectedFacilityId,
        consentEmail: !facilityEmailConsent,
      });

      mutate(["user", user?.id]);
    } catch (e) {
      toastError.message("toast.error.update.generic");
      setFacilityEmailConsent(facilityEmailConsent);
    } finally {
      setShowConfirmationDialog(false);
    }
  };

  return (
    <div className="flex flex-col space-y-4">
      <h3>
        <FormattedMessage id="common.consent" />
      </h3>

      <div className="flex gap-2">
        <Checkbox checked={!!user?.consentPublicProfile} disabled />
        <FormattedMessage id="admin.player.consent.public-profile" />
      </div>
      <div className="flex gap-2">
        <Checkbox
          checked={facilityEmailConsent}
          onChange={() => {
            setShowConfirmationDialog(true);
          }}
          disabled={!facilityEmailConsent}
        />
        <FormattedMessage id="admin.player.consent.facility-emails" />
      </div>

      {showConfirmationDialog && (
        <ConfirmationDialog
          visible
          title={formatMessage({
            id: "admin.player.consent.facility-emails.opt-out.title",
          })}
          text={formatMessage(
            { id: "admin.player.consent.facility-email.opt-out.text" },
            { br: <br /> },
          )}
          confirmText={formatMessage({ id: "common.continue" })}
          denyText={formatMessage({ id: "common.cancel" })}
          onHide={() => setShowConfirmationDialog(false)}
          onCancel={() => setShowConfirmationDialog(false)}
          onSubmit={() => handleConsentEmailChange()}
        ></ConfirmationDialog>
      )}
    </div>
  );
};
