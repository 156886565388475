import * as React from "react";

import clsx from "clsx";

interface OrderDetail {
  heading: string;
  text: React.ReactNode;
}

interface Props {
  orderDetails: OrderDetail[];
  firstWide?: boolean;
}

export const OrderDetails = ({ orderDetails, firstWide }: Props) => {
  return (
    <div className="mt-3 grid gap-5 md:grid-cols-2">
      {orderDetails.map((orderDetail, index) => (
        <React.Fragment key={index}>
          {firstWide
            ? index !== 0 &&
              index % 2 !== 0 && (
                <hr className="hidden border-t md:col-span-2 md:block" />
              )
            : index !== 0 &&
              index % 2 === 0 && (
                <hr className="hidden border-t md:col-span-2 md:block" />
              )}

          <div className={clsx(firstWide && "md:first:col-span-2")} key={index}>
            <OrderDetail
              heading={orderDetail.heading}
              text={orderDetail.text}
            />
          </div>
        </React.Fragment>
      ))}
    </div>
  );
};

export const OrderDetail = ({ heading, text }: OrderDetail) => {
  return (
    <>
      <h3 className="text-base font-medium text-gray-700 lg:text-md">
        {heading}
      </h3>
      <p className="max-w-prose text-md [overflow-wrap:anywhere] lg:text-lg">
        {text}
      </p>
    </>
  );
};
