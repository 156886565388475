import { type ReactNode, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";

import {
  faArrowsRotate,
  faBan,
  faIdBadge,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateTime } from "luxon";
import { InputSwitch } from "primereact/inputswitch";
import type { KeyedMutator } from "swr";

import { BookingType, IBooking } from "../../../../models/Booking";
import { type Payment, PaymentStatus } from "../../../../models/Payment";

import { adminGetPlayerPath } from "../../../../../../helpers/pathHelpers";

import { useIsSuperAdmin } from "../../../../../../hooks/permissions";
import { useOpenBooking } from "../../../../../../hooks/swr/useOpenBooking";
import { useCurrencyFormat } from "../../../../../../hooks/useCurrencyFormat";
import { useDateFormat } from "../../../../../../hooks/useDateFormat";
import { useOpenBookingClassCategoryName } from "../../../../../../hooks/useOpenBookingClassCategoryName";

import { Comment } from "./components/Comment";
import { DataGrid } from "./components/DataGrid";
import { Players } from "./components/Players";

import { useAppLocale } from "../../../../../../recoil/i18nConfigState";

interface Props {
  booking: IBooking;
  onSubmitted: (refreshView?: boolean, isEditDialogOpen?: boolean) => void;
  mutateBooking: KeyedMutator<IBooking>;
}

export const AdminCalendarBookingDetails = ({
  booking,
  mutateBooking,
  onSubmitted,
}: Props) => {
  const { formatMessage } = useIntl();

  const isSuperAdmin = useIsSuperAdmin();

  const { df } = useDateFormat(booking.facilityId);
  const { cf } = useCurrencyFormat(booking?.price.currencyCode);
  const locale = useAppLocale();

  const { openBooking } = useOpenBooking(
    booking.type === BookingType.Open ? booking.facilityId : undefined,
    booking.externalServiceId,
    { refreshInterval: 60_000 },
  );

  const openBookingClassCategoryName = useOpenBookingClassCategoryName(
    openBooking?.classCategory,
  );

  const isBookingEditable = booking.startTime > DateTime.now();

  const completedPayments = booking.payments.filter(
    payment =>
      payment.status === PaymentStatus.FullyCompleted ||
      payment.status === PaymentStatus.MainSplitPaymentCompleted,
  );
  const status = getPaymentStatus(completedPayments);
  const expectedAmount = getExpectedAmount(
    completedPayments,
    booking.individualDefaultPrice?.valueInclTax ?? 0,
    booking?.court?.bookableEntityType?.defaultNumberOfPlayers ?? 0,
    booking.individualFee?.valueInclTax ?? 0,
  );
  const expectedFee = getExpectedFee(
    completedPayments,
    booking?.court?.bookableEntityType?.defaultNumberOfPlayers ?? 0,
    booking?.individualFee?.valueInclTax ?? 0,
    status,
  );
  const paidAmount = getPayedAmount(completedPayments);

  const dataGridData = useMemo(() => {
    const data: { heading: ReactNode; text: ReactNode }[] = [
      {
        heading: <FormattedMessage id="common.date-and-time" />,
        text: (
          <>
            {df(booking.startTime, DateTime.DATETIME_MED)} -{" "}
            {df(booking.endTime, DateTime.TIME_SIMPLE)}
          </>
        ),
      },
      {
        heading: <FormattedMessage id="common.booked-by" />,
        text: (
          <Link to={adminGetPlayerPath(booking.bookedBy.id)}>
            {booking.bookedBy.displayName}
          </Link>
        ),
      },
      {
        heading: <FormattedMessage id="common.court" />,
        text: booking.court.name,
      },
      {
        heading: <FormattedMessage id="common.created" />,
        text:
          booking.createdDate && df(booking.createdDate, DateTime.DATETIME_MED),
      },
    ];

    if (booking.type === BookingType.Open && openBooking) {
      data.push(
        {
          heading: <FormattedMessage id="common.level" />,
          text: `${openBooking.minSkillLevel} - ${openBooking.maxSkillLevel}`,
        },
        {
          heading: <FormattedMessage id="common.class" />,
          text: openBookingClassCategoryName,
        },
        {
          heading: <FormattedMessage id="common.automatic-cancellation-time" />,
          text: openBooking.automaticCancellationTime.hasSame(
            DateTime.utc(),
            "day",
          ) ? (
            <>
              {openBooking.automaticCancellationTime <= DateTime.utc() ? (
                <FormattedMessage id="common.now" />
              ) : (
                <>
                  {openBooking.automaticCancellationTime.toRelative({
                    locale,
                    unit: ["hours", "minutes"],
                  })}
                </>
              )}
              <br />(
              {df(openBooking.automaticCancellationTime, DateTime.DATETIME_MED)}
              )
            </>
          ) : (
            <>
              {df(openBooking.automaticCancellationTime, DateTime.DATETIME_MED)}
            </>
          ),
        },
      );
    }

    if (isSuperAdmin) {
      data.unshift(
        {
          heading: "Booking ID",
          text: booking.id,
        },
        {
          heading: "Booking Number",
          text: booking.bookingNumber,
        },
      );
    }

    return data;
  }, [
    booking.bookedBy.displayName,
    booking.bookedBy.id,
    booking.bookingNumber,
    booking.court.name,
    booking.createdDate,
    booking.endTime,
    booking.id,
    booking.startTime,
    booking.type,
    df,
    isSuperAdmin,
    locale,
    openBooking,
    openBookingClassCategoryName,
  ]);

  return (
    <>
      <h3>
        {booking.type === BookingType.NotBookable ? (
          <FormattedMessage id="calendar.slot.not-bookable" />
        ) : (
          booking.organizer?.displayName || (
            <FormattedMessage id="common.booking-details" />
          )
        )}
      </h3>

      {(booking.isAdmin || booking.type === BookingType.Recurring) && (
        <div className="mt-1 flex gap-2">
          {booking.type === BookingType.NotBookable ? (
            <div className="rounded-full bg-blue-50 px-3 py-0.5">
              <FontAwesomeIcon
                className="mr-1 inline-block"
                icon={faBan}
                title={formatMessage({ id: "calendar.slot.not-bookable" })}
              />
              <FormattedMessage id="calendar.slot.not-bookable" />
            </div>
          ) : booking.isAdmin ? (
            <div className="rounded-full bg-blue-50 px-3 py-0.5">
              <FontAwesomeIcon
                className="mr-1 inline-block"
                icon={faIdBadge}
                title={formatMessage({ id: "common.booked-by-admin" })}
              />
              Admin
            </div>
          ) : null}

          {booking.type === BookingType.Recurring && (
            <div className="flex items-center gap-1 rounded-full bg-blue-50 px-3 py-0.5">
              <FontAwesomeIcon
                className="mr-1 inline-block"
                icon={faArrowsRotate}
                title={formatMessage({ id: "common.recurring" })}
              />
              <FormattedMessage id="common.recurring" />
            </div>
          )}
        </div>
      )}

      <DataGrid data={dataGridData} />

      {booking.type !== BookingType.NotBookable && (
        <>
          <hr className="mt-4" />

          <Players
            booking={booking}
            isBookingEditable={
              isBookingEditable && booking.type !== BookingType.Open
            }
            showPaymentData
            mutateBooking={mutateBooking}
            onSubmitted={onSubmitted}
          />
        </>
      )}

      <hr className="mt-4" />

      <Comment
        booking={booking}
        mutateBooking={mutateBooking}
        onSubmitted={onSubmitted}
      />

      <hr className="mt-4" />

      {booking.type === BookingType.NotBookable && (
        <div className="mt-4 flex items-center text-gray-700">
          <span className="mr-auto">
            <FormattedMessage id="common.lights" />
          </span>
          <FormattedMessage
            id={
              booking.turnOnLight
                ? "common.lights.turned-on"
                : "common.lights.turned-off"
            }
          />
          <InputSwitch
            checked={booking.turnOnLight}
            disabled
            className="ml-3"
          />
        </div>
      )}

      {booking.type !== BookingType.NotBookable && (
        <div className="mt-6 flex flex-col gap-1.5">
          <p className="mb- font-medium text-gray-700">
            <FormattedMessage id="admin-edit-booking-form-pay-info" />
          </p>
          <div className="flex justify-between">
            <p>
              <FormattedMessage id="admin-edit-booking-form-paid-amount" />
            </p>
            <p>{cf(paidAmount)}</p>
          </div>
          <div className="flex justify-between">
            <p>
              <FormattedMessage id="admin-edit-booking-form-bookingfee" />
            </p>
            <p>{cf((paidAmount || 0) > 0 ? expectedFee || 0 : 0)}</p>
          </div>
          <div className="flex justify-between font-bold">
            <p>
              <FormattedMessage id="admin-edit-booking-form-total" />
            </p>
            <p>
              {cf(
                status == PaymentStatus.FullyCompleted
                  ? paidAmount || 0
                  : expectedAmount ||
                      booking?.price.valueInclTax + booking?.fee.valueInclTax ||
                      0,
              )}
            </p>
          </div>
        </div>
      )}
    </>
  );
};

const hasSplitPaymentInfo = (payment: Payment): boolean => {
  return (
    payment?.splitPaymentInfo?.isSplitPayment &&
    !!payment?.splitPaymentInfo?.splittedPriceIncTax
  );
};

const getPayedAmount = (payments: Payment[]): number => {
  if (!payments) return 0;

  if (!payments.length || !(payments.length > 0)) return 0;

  // in case of split payment
  if (payments.every(hasSplitPaymentInfo)) {
    const amounts = payments.map(x => x.splitPaymentInfo.splittedPriceIncTax);

    const totalAmount = amounts.reduce(
      (total, currentValue) => (total = total + currentValue),
      0,
    );
    return totalAmount;
  }

  return payments[0]?.totalPriceIncTax ?? 0;
};

const getPaymentStatus = (payments: Payment[]): PaymentStatus | 0 => {
  if (!payments) return 0;
  if (!payments.length || !(payments.length > 0)) return 0;

  const maxValue = Math.max.apply(
    null,
    payments.map(function (o) {
      return o.status;
    }),
  );

  return maxValue ?? 0;
};

const getExpectedAmount = (
  payments: Payment[],
  individualPriceIncTax: number,
  numberOfPlayers: number,
  individualFeeIncTax: number,
): number => {
  if (
    !payments ||
    !payments.length ||
    !(payments.length > 0) ||
    numberOfPlayers == 0
  )
    return (individualPriceIncTax + individualFeeIncTax) * numberOfPlayers;

  const splitPayments = payments.map(x =>
    Number(x.splitPaymentInfo.isSplitPayment),
  );
  const numberOfSplitPayments = splitPayments.reduce((a, v) => (a = a + v), 0);

  return (
    (individualPriceIncTax + individualFeeIncTax) *
      (numberOfPlayers - numberOfSplitPayments) +
    getPayedAmount(payments)
  );
};

const getExpectedFee = (
  payments: Payment[],
  numberOfPlayers: number,
  individualFeeIncTax: number,
  paymentState: number,
): number => {
  if (
    !payments ||
    !payments.length ||
    !(payments.length > 0) ||
    numberOfPlayers == 0
  )
    return individualFeeIncTax * numberOfPlayers;

  const splitPayments = payments.map(x =>
    Number(x.splitPaymentInfo.isSplitPayment),
  );
  const numberOfSplitPayments = splitPayments.reduce((a, v) => (a = a + v), 0);

  return paymentState == 4
    ? individualFeeIncTax * numberOfPlayers
    : individualFeeIncTax *
        ((numberOfSplitPayments ?? numberOfPlayers) == 0
          ? numberOfPlayers
          : numberOfSplitPayments);
};
