import { faCoins } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

import { useNumberFormat } from "../../../../hooks/useNumberFormat";

interface Props {
  id?: string;
  title: string;
  amount?: number;
  onSelect: (value: string) => void;
  isActive?: boolean;
}

interface ContainerProps {
  isActive?: boolean;
}

const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  border-radius: 4px;
  cursor: pointer;
  padding: 1rem;
  font-size: var(--b3);
  background: ${props =>
    props?.isActive ? "var(--blue-600)" : "var(--gray-100)"};
  color: ${props => (props?.isActive ? "white" : "var(--black)")};
`;

const Icon = styled.div`
  margin-right: 1rem;
`;

const Title = styled.div`
  font-size: var(--b3);
`;

const Amount = styled.div`
  font-size: var(--b2);
`;

export const CoinSelect: React.FC<Props> = ({
  id,
  title,
  amount,
  onSelect,
  isActive,
}) => {
  const { nf } = useNumberFormat({ maximumFractionDigits: 2 });

  return (
    <Container isActive={isActive} onClick={() => onSelect(id)}>
      <Icon>
        <FontAwesomeIcon icon={faCoins} />
      </Icon>
      <div>
        <Title>{title}</Title>
        <Amount>{amount && nf(amount)}</Amount>
      </div>
    </Container>
  );
};
