import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Tooltip } from "primereact/tooltip";

import {
  Series,
  SeriesTeam,
} from "../../../../../../modules/game/models/Series";
import { User } from "../../../../../../modules/player/models/User";

import { useDateFormat } from "../../../../../../hooks/useDateFormat";

import { markSerieAsPaid } from "../../../../../../modules/game/services/Serie";

import { PaymentStatus } from "../../../../../../components/PaymentStatus";
import { HandleSerieTeamDialog } from "../../../../../../modules/game/components/series/HandleSerieTeamDialog";

import {
  luxonDateFormat,
  luxonDateTimeFormat,
} from "../../../../../../utils/dateFormats";

interface IProps {
  serie: Series | null;
  refresh: () => void;
}

const SerieTeamsTable: React.FC<IProps> = ({ serie, refresh }) => {
  const intl = useIntl();
  const { df } = useDateFormat(serie?.facilityId);

  const [showHandleDialog, setShowHandleDialog] = useState<boolean>(false);
  const [selectedTeam, setSelectedTeam] = useState<SeriesTeam>();

  const handleMarkAsPaid = async (user: User) => {
    const teamId = serie?.registeredTeams?.find(t =>
      t?.teamMembers?.find(m => m?.id === user?.id),
    )?.id;
    try {
      const res = await markSerieAsPaid(serie?.id, teamId, user?.id);
      if (res) {
        setTimeout(() => {
          // setTimeout? Really? yes. Backend replies when payment is done.
          // We need to wait for series status updates, and since backend dont wait we do.
          // We guess 1s is enough. It should really really really be enough.
          refresh();
        }, 1000);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getDivisionName = (team: SeriesTeam) =>
    serie.divisions.find(d => d.teams.some(t => t.id === team.id))?.name;

  return (
    <>
      <DataTable
        dataKey="id"
        value={serie?.registeredTeams ?? []}
        rows={serie?.registeredTeams?.length}
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
        responsiveLayout="scroll"
        showGridlines
        stripedRows
      >
        <Column
          body={(team: SeriesTeam) => (
            <div>
              {team?.teamMembers?.map(user => (
                <p key={user?.id}>{user?.displayName}</p>
              ))}
            </div>
          )}
          header={intl.formatMessage({
            id: "common.team",
            defaultMessage: "Lag",
          })}
        />
        <Column
          body={(team: SeriesTeam) => (
            <div>
              {team?.teamMembers?.map(user => (
                <p key={user?.id}>{user?.phoneNumber}</p>
              ))}
            </div>
          )}
          header={intl.formatMessage({
            id: "common.phone",
            defaultMessage: "Telefon",
          })}
        />
        <Column
          body={(team: SeriesTeam) => (
            <div>
              {team?.teamMembers?.map(user => (
                <p key={user?.id}>{user?.emailAddress}</p>
              ))}
            </div>
          )}
          header={intl.formatMessage({
            id: "common.email",
            defaultMessage: "Epost",
          })}
        />
        <Column
          body={(team: SeriesTeam) =>
            team?.teamMembers?.map(user => (
              <>
                {user.adminUser && user.lastModified && (
                  <Tooltip
                    target={`#marked-as-paid-tooltip-${user.id}`}
                    content={`${intl.formatMessage({
                      id: "admin.marked-as-paid-by",
                    })} ${user.adminUser.displayName.split(" ")[0]} - ${df(
                      user.lastModified,
                      luxonDateTimeFormat,
                    )}`}
                  />
                )}
                <div key={user?.id} id={`marked-as-paid-tooltip-${user.id}`}>
                  <PaymentStatus
                    user={user}
                    markedAsPaid={user.adminUser !== null}
                    onMarkAsPaid={handleMarkAsPaid}
                  />
                </div>
              </>
            ))
          }
          header={intl.formatMessage({
            id: "common.payment.status",
          })}
        />
        <Column
          body={getDivisionName}
          header={intl.formatMessage({
            id: "common.division",
            defaultMessage: "Division",
          })}
        />
        <Column
          body={(team: SeriesTeam) =>
            df(team.registrationDate, luxonDateFormat)
          }
          header={intl.formatMessage({
            id: "series.division.team.registered-date",
            defaultMessage: "Registreringsdatum",
          })}
        />
        <Column
          style={{ width: "8.5rem" }}
          align="center"
          body={(team: SeriesTeam) => (
            <div>
              <div
                className="y-1 flex cursor-pointer justify-center rounded-sm border border-gray-200 bg-gray-100 px-2"
                onClick={() => {
                  setSelectedTeam(team);
                  setShowHandleDialog(true);
                }}
              >
                <FormattedMessage id="series.manage.team" />
              </div>
            </div>
          )}
        />
      </DataTable>

      <HandleSerieTeamDialog
        series={serie}
        team={selectedTeam}
        visible={showHandleDialog}
        onHide={() => {
          setShowHandleDialog(false);
          setSelectedTeam(null);
        }}
        handleMarkAsPaid={handleMarkAsPaid}
        refresh={refresh}
      />
    </>
  );
};

export default SerieTeamsTable;
