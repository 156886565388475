import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { faUserPlus } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { RegexExpression } from "../../../../appConstants/RegexExpression";

import { commonRegex } from "../../../../helpers/commonRegex";

import { useToaster } from "../../../../hooks/common/useToaster";

import { createUserToFacility } from "../../../../modules/player/services/UserService";

import { Button } from "../../../../components/Button";
import { Dialog } from "../../../../components/Dialog";
import { TextInput } from "../../../../components/TextInput";

interface Props {
  facilityId: string;
  setReload: any;
}

export const AdminAddNewPlayer = ({ facilityId, setReload }: Props) => {
  const intl = useIntl();
  const [showAddDialog, setShowAddDialog] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const { toastSuccess, toastError } = useToaster();

  const validEmail = (): boolean => {
    return (
      emailAddress?.length > 0 &&
      !!String(emailAddress).toLowerCase().match(RegexExpression.Email)
    );
  };

  const validPhoneNumber = (): boolean => {
    return (
      !phoneNumber ||
      (phoneNumber.length > 0 &&
        !!String(phoneNumber).match(commonRegex.phoneNumber))
    );
  };

  const isValid = (): boolean => {
    return (
      firstName?.length > 0 &&
      lastName?.length > 0 &&
      validEmail() &&
      validPhoneNumber()
    );
  };

  const onSubmit = async () => {
    try {
      await createUserToFacility(facilityId, {
        firstName,
        lastName,
        emailAddress,
        phoneNumber,
      });
      toastSuccess.userSaved();
      setShowAddDialog(false);
      setReload?.(true);
    } catch {
      toastError.generalFailure();
    }
  };

  return (
    <>
      <button
        className="mb-2 flex items-center gap-2 self-end p-2"
        onClick={() => setShowAddDialog(true)}
      >
        <FormattedMessage id="admin.player-overview.add-player" />
        <FontAwesomeIcon icon={faUserPlus} />
      </button>
      {showAddDialog && (
        <Dialog
          visible
          onHide={() => {
            setShowAddDialog(false);
            setFirstName("");
            setLastName("");
            setEmailAddress("");
            setPhoneNumber("");
          }}
          closeOnEscape
        >
          <div className="space-y-8">
            <TextInput
              required
              name="firstName"
              label={intl.formatMessage({ id: "common.firstname" })}
              onChange={e => setFirstName(e?.target?.value)}
              value={firstName}
            />

            <TextInput
              required
              name="lastName"
              label={intl.formatMessage({ id: "common.lastname" })}
              onChange={e => setLastName(e?.target?.value)}
              value={lastName}
            />

            <TextInput
              required
              name="emailAddress"
              label={intl.formatMessage({ id: "common.email" })}
              type="email"
              onChange={e => setEmailAddress(e?.target?.value)}
              value={emailAddress}
            />
            <TextInput
              name="phoneNumber"
              label={intl.formatMessage({ id: "common.phone" })}
              type="tel"
              onChange={e => setPhoneNumber(e?.target?.value)}
              value={phoneNumber}
            />

            <Button
              className="w-full"
              type="primary"
              translationName="common.save"
              onClick={onSubmit}
              disabled={!isValid()}
            />
          </div>
        </Dialog>
      )}
    </>
  );
};
