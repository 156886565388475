import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";

import { faBarsFilter } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  MatchStatus,
  Series,
  SeriesMatchRequestFilter,
} from "../../../../../../../modules/game/models/Series";

import { SelectInput } from "../../../../../../../components/inputs/SelectInput";

interface Props {
  filter?: SeriesMatchRequestFilter;
  series?: Series;
}

export const MatchFilter = ({ filter, series }: Props) => {
  const { formatMessage } = useIntl();
  const location = useLocation();
  const history = useHistory();
  const [teamFilterOptions, setTeamFilterOptions] = useState<
    { label: string; value: string | null }[]
  >([
    {
      label: formatMessage({ id: "series.teams.all" }),
      value: null,
    },
  ]);

  // Filter options
  const divisionFilterOptions = [
    {
      label: formatMessage({ id: "series.divisions.all" }),
      value: null,
    },
    ...(series?.divisions?.map(d => ({ label: d.name, value: d.id })) ?? []),
  ];

  const matchStatusOptions = [
    {
      label: formatMessage({ id: "series.matches.all" }),
      value: null,
    },
    {
      label: formatMessage({ id: "series.matches.finished" }),
      value: MatchStatus.Finished,
    },
    {
      label: formatMessage({ id: "series.matches.upcoming" }),
      value: MatchStatus.Upcoming,
    },
  ];

  useEffect(() => {
    const teams = filter?.divisionIds?.length
      ? series?.divisions
          ?.filter(d => filter.divisionIds?.includes(d.id))
          .flatMap(d => d.teams)
      : series?.divisions.flatMap(d => d.teams);

    const options = [
      teamFilterOptions[0],
      ...(teams?.map(t => ({
        label: t?.teamMembers?.map(m => m.displayName).join(" & "),
        value: t.id,
      })) ?? []),
    ];

    setTeamFilterOptions(options);
  }, [series, filter?.divisionIds]);

  const updateFilterInUrl = (filter: Partial<SeriesMatchRequestFilter>) => {
    const searchParams = new URLSearchParams(location.search);

    Object.entries(filter).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        searchParams.set(key, value[0]);
      } else if (value !== null && value !== undefined) {
        searchParams.set(key, value.toString());
      } else {
        searchParams.delete(key);
      }
    });

    history.replace({
      search: searchParams.toString(),
    });
  };

  return (
    /* Filter selects */
    /* Only selects one option per filter atm though endpoint supports multiple values */

    <div className="mt-14 flex w-full flex-col items-center gap-y-5 sm:flex-row sm:gap-x-5">
      <FontAwesomeIcon
        className="mx-2 text-left text-blue-700"
        size="lg"
        icon={faBarsFilter}
      />

      <SelectInput
        placeholder={formatMessage({ id: "series.divisions.all" })}
        options={divisionFilterOptions}
        value={filter?.divisionIds?.[0] ?? divisionFilterOptions[0].value}
        onChange={e => {
          if (
            filter?.divisionIds === e.value ||
            filter?.divisionIds?.includes(e.value)
          )
            return;

          updateFilterInUrl({
            divisionIds: e.value ? [e.value] : undefined,
          });
        }}
      />

      <SelectInput
        placeholder={formatMessage({ id: "series.matches.all" })}
        options={matchStatusOptions}
        value={filter?.matchStatus}
        onChange={e => {
          if (filter?.matchStatus === e.value) return;

          updateFilterInUrl({
            matchStatus: e.value,
          });
        }}
      />

      <SelectInput
        placeholder={formatMessage({ id: "series.teams.all" })}
        options={teamFilterOptions}
        value={filter?.teamIds?.[0] ?? teamFilterOptions[0].value}
        onChange={e => {
          if (filter?.teamIds === e.value || filter?.teamIds?.includes(e.value))
            return;

          updateFilterInUrl({
            teamIds: e.value ? [e.value] : undefined,
          });
        }}
      />

      <button
        className="mx-2 whitespace-nowrap bg-none text-primary"
        type="button"
        onClick={() =>
          updateFilterInUrl({
            divisionIds: undefined,
            matchStatus: undefined,
            teamIds: undefined,
          })
        }
      >
        {formatMessage({ id: "common.filter.clear" })}
      </button>
    </div>
  );
};
