import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import styled from "styled-components";

import { PolicyPageContent } from "../models/content/PolicyPage";

import {
  getPrivacyPageContent,
  getTermsAndConditionsPageContent,
  getUserAgreementPageContent,
} from "../modules/common/services/ContentService";

import { AppLoadingSpinner } from "../components/AppLoadingSpinner";

import { useAppI18nConfig } from "../recoil/i18nConfigState";

const DocumentContainer = styled.div`
  margin: 4rem;
`;

const Title = styled.h1``;

const Subtitle = styled.h3`
  color: var(--gray-400);
`;

const Header = styled.h2``;

export const PolicyPage: React.FC = () => {
  const location = useLocation();
  const [content, setContent] = useState<PolicyPageContent>();
  const {
    i18nConfig: { languageCode },
  } = useAppI18nConfig();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetch = async () => {
      try {
        let content;
        switch (location.pathname) {
          case "/privacyPolicy":
            content = await getPrivacyPageContent(languageCode);
            break;
          case "/userAgreement":
            content = await getUserAgreementPageContent(languageCode);
            break;
          case "/termsAndConditions":
            content = await getTermsAndConditionsPageContent(languageCode);
            break;
        }
        setContent(content);
      } catch (e) {
        console.error(e.message);
      } finally {
        setLoading(false);
      }
    };

    fetch();
  }, [languageCode, location.pathname]);

  if (loading) {
    return <AppLoadingSpinner />;
  }

  const { agreementSections, titleSection } = content || {};

  const fixBreakRows = (html: string) => html.replaceAll(/\r?\n/g, "<br/>");

  return (
    <div className="container">
      <DocumentContainer>
        <Title>{titleSection.title}</Title>
        <Subtitle>{titleSection.text}</Subtitle>
        <div>
          {agreementSections.map((agreement, index) => (
            <div key={index}>
              <Header>{agreement.title}</Header>
              <div
                dangerouslySetInnerHTML={{
                  __html: fixBreakRows(agreement.text),
                }}
              />
            </div>
          ))}
        </div>
      </DocumentContainer>
    </div>
  );
};
