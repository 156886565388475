import useSWR from "swr";

import { FacilityWithUtc } from "../../modules/customer/models/Facility";

import { getBookableTypesByFacilityId } from "../../modules/customer/services/FacilityService";

export const useBookableTypes = (facilityId?: FacilityWithUtc["id"]) => {
  const { data, ...rest } = useSWR(
    facilityId ? ["bookableTypes", facilityId] : null,
    ([, facilityId]) =>
      getBookableTypesByFacilityId(facilityId).then(res => res?.data),
  );

  return {
    ...rest,
    bookableTypes: data,
  };
};
