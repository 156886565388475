import { atom, useRecoilValue } from "recoil";

import { localStoragePersistenceEffect } from "./effects/localStoragePersistenceEffect";

export const SELECTED_FACILITY_ID_STORAGE_KEY = "selectedFacilityId";

export const selectedFacilityIdState = atom<string | null>({
  key: "selectedFacilityId",
  default: null,
  effects: [localStoragePersistenceEffect(SELECTED_FACILITY_ID_STORAGE_KEY)],
});

export const useSelectedFacilityId = () =>
  useRecoilValue(selectedFacilityIdState);
