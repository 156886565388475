import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import {
  faCog,
  faCopy,
  faEllipsisH,
  faImage,
  faTable,
  faTimes,
  faTrash,
  faUser,
  faUsers,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateTime } from "luxon";
import styled from "styled-components";

import standardImage from "../../../../../assets/images/backgrounds/background3.jpg";

import { BuyableType } from "../../../../checkout/models/Payment";
import { IActivity } from "../../../models/Activity";
import { SeasonStatus } from "../../../models/Series";

import { useToaster } from "../../../../../hooks/common/useToaster";

import { uploadActivityImage } from "../../../services/Activities";
import { deleteSerie } from "../../../services/Serie";

import { ConfirmationDialog } from "../../../../../components/ConfirmationDialog";
import { Dialog } from "../../../../../components/Dialog";
import { ProgressSpinner } from "../../../../../components/ProgressSpinner";
import ImageInput from "../../../../../components/image/ImageInput";

import { AdminAddTeam } from "../../AdminAddTeams";
import AdminCopySerieForm from "../forms/AdminCopySerieForm";
import AdminEditSerieForm from "../forms/AdminEditSerieForm";

const ActionButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  position: absolute;

  position: absolute;
  margin-left: -13rem;
  margin-top: -2rem;
  z-index: 100;

  background: var(--light);
  padding: 0.5rem;
  width: 11.5rem;
  border: 1px solid var(--gray-200);

  ::after {
    content: "";
    width: 0rem;
    height: 0rem;
    position: absolute;
    right: -2rem;
    top: 0;
    transform: rotate(-90deg);
    border-right: 1rem solid transparent;
    border-top: 1rem solid var(--gray-200);
    border-bottom: 1rem solid transparent;
    border-left: 1rem solid transparent;
  }
`;

const ActionMenuItem = styled.div`
  cursor: pointer;
  color: var(--gray-400);
  transition: color 250ms ease-in-out;
  display: flex;

  :hover {
    color: var(--dark);
    transition: color 250ms ease-in-out;
  }

  svg {
    width: 2rem;
  }
`;

const MenuButton = styled.div`
  transition: all 250ms ease-in-out;
`;

interface IProps {
  serie: IActivity;
  handleShowActionButtons: (activityId: string) => void;
  showActionButtons: string;
  refreshData: () => void;
}

const now = DateTime.now();

const AdminSeriesTableActionsColumn = ({
  serie,
  handleShowActionButtons,
  showActionButtons,
  refreshData,
}: IProps) => {
  const history = useHistory();
  const toaster = useToaster();
  const intl = useIntl();
  const [imageLoading, setImageLoading] = useState(false);
  const [image, setImage] = useState(
    serie.imageUrl ? serie.imageUrl : standardImage,
  );
  const [handleAddImageOpen, setHandleAddImageOpen] = useState(false);

  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [addTeamDialogOpen, setAddTeamDialogOpen] = useState(false);
  const [copySerieDialogOpen, setCopySerieDialogOpen] = useState(false);

  const imageUploader = async (file: File) => {
    const update = async () => {
      try {
        setImageLoading(true);
        setImage("");
        const response = await uploadActivityImage(
          serie.id,
          file,
          serie.buyableType,
        );

        if (response != null) {
          toaster.toastSuccess.activityImageChanged();
          setImage(response);
        }
      } catch {
        setImage(serie.imageUrl ? serie.imageUrl : standardImage);
        toaster.toastError.activityImageFailed();
      } finally {
        setImageLoading(false);
      }
    };

    update();
  };

  const onDeleteFormSubmitted = async () => {
    try {
      if (serie?.buyableType === BuyableType.Series)
        await deleteSerie(serie?.id);

      refreshData();
      setDeleteDialogOpen(false);
    } catch (err) {
      toaster.toastError.removeSerieFailed();
    }
  };

  return (
    <>
      <div
        onClick={() => handleShowActionButtons(serie.id)}
        className="cursor-pointer text-xl text-primary"
      >
        <MenuButton>
          {showActionButtons === serie?.id ? (
            <FontAwesomeIcon icon={faTimes} beat />
          ) : (
            <FontAwesomeIcon icon={faEllipsisH} />
          )}
        </MenuButton>
      </div>

      <ActionButtons
        style={
          showActionButtons === serie?.id
            ? { display: "flex" }
            : { display: "none" }
        }
      >
        <ActionMenuItem onClick={() => setAddTeamDialogOpen(true)}>
          <FontAwesomeIcon icon={faUser} />
          {serie.buyableType === BuyableType.Series && (
            <FormattedMessage
              id="series.add-team"
              defaultMessage="Lägg till lag"
            />
          )}
        </ActionMenuItem>

        {serie.buyableType === BuyableType.Series &&
          (serie?.seasonStatus === SeasonStatus.Active ||
            serie?.seasonStatus === SeasonStatus.Closed) && (
            <ActionMenuItem
              onClick={e =>
                e.metaKey
                  ? window.open(
                      "/#/admin/activities/serie/" + serie.id + "/scoreboard",
                    )
                  : history.push(
                      "/admin/activities/serie/" + serie.id + "/scoreboard",
                    )
              }
            >
              <FontAwesomeIcon icon={faTable} />
              <FormattedMessage
                id="series.scoreboard"
                defaultMessage="Scoreboard"
              />
            </ActionMenuItem>
          )}

        <ActionMenuItem
          onClick={e =>
            e.metaKey
              ? window.open("/#/admin/activities/serie/" + serie.id + "/teams")
              : history.push("/admin/activities/serie/" + serie.id + "/teams")
          }
        >
          <FontAwesomeIcon icon={faUsers} />
          {serie.buyableType === BuyableType.Series && (
            <FormattedMessage
              id="series.signed-up-teams"
              defaultMessage="Anmälda lag"
            />
          )}
        </ActionMenuItem>

        <ActionMenuItem onClick={() => setEditDialogOpen(true)}>
          <FontAwesomeIcon icon={faCog} />
          <FormattedMessage id="common.edit" defaultMessage="Redigera" />
        </ActionMenuItem>

        {serie.buyableType === BuyableType.Series &&
          serie.nextSeasonId === null && (
            <ActionMenuItem onClick={() => setCopySerieDialogOpen(true)}>
              <FontAwesomeIcon icon={faCopy} />
              <FormattedMessage
                id="series.create-next-season"
                defaultMessage="Skapa nästa säsong"
              />
            </ActionMenuItem>
          )}

        <ActionMenuItem onClick={() => setHandleAddImageOpen(true)}>
          <FontAwesomeIcon icon={faImage} />
          <FormattedMessage
            id="series.addImage"
            defaultMessage="Hantera omslagsbild"
          />
        </ActionMenuItem>

        {serie.endTime > now && (
          <ActionMenuItem onClick={() => setDeleteDialogOpen(true)}>
            <FontAwesomeIcon icon={faTrash} />
            <FormattedMessage id="common.remove" defaultMessage="Ta bort" />
          </ActionMenuItem>
        )}
      </ActionButtons>

      <Dialog
        visible={handleAddImageOpen}
        onHide={() => setHandleAddImageOpen(false)}
      >
        {imageLoading ? (
          <ProgressSpinner />
        ) : (
          <>
            <div
              style={{
                width: "100%",
                height: "20rem",
                background: "var(--gray-200)",

                display: "flex",
                justifyContent: "center",
              }}
            >
              <img style={{ height: "100%", width: "auto" }} src={image} />
            </div>
            <div style={{ marginBottom: "1rem", marginTop: ".5rem" }}>
              <FormattedMessage
                id="activities.image.description"
                defaultMessage="Bilden kommer att bli beskuren beroende på vart den visas"
              />
            </div>
            <ImageInput onSave={imageUploader} />
          </>
        )}
      </Dialog>

      {addTeamDialogOpen && (
        <Dialog
          visible
          onHide={() => setAddTeamDialogOpen(false)}
          className="admin-series-table__edit-dialog"
        >
          {serie?.buyableType === BuyableType.Series && (
            <AdminAddTeam
              facilityId={serie.facilityId}
              seriesId={serie.id}
              onSubmit={() => setAddTeamDialogOpen(false)}
            />
          )}
        </Dialog>
      )}

      {editDialogOpen && (
        <Dialog visible onHide={() => setEditDialogOpen(false)}>
          <AdminEditSerieForm
            seriesId={serie?.id}
            onSubmit={() => {
              setEditDialogOpen(false);
              refreshData();
            }}
          />
        </Dialog>
      )}

      {copySerieDialogOpen && (
        <Dialog
          visible
          onHide={() => setCopySerieDialogOpen(false)}
          className="admin-series-table__edit-dialog"
        >
          <AdminCopySerieForm
            serieId={serie?.id}
            onSubmit={() => {
              setCopySerieDialogOpen(false);
              refreshData();
            }}
          />
        </Dialog>
      )}

      {deleteDialogOpen && (
        <ConfirmationDialog
          visible
          title={intl.formatMessage({
            id: "admin-series-table.delete-confirmation-message",
          })}
          onCancel={() => setDeleteDialogOpen(false)}
          onSubmit={onDeleteFormSubmitted}
          icon={faTrash}
          text={intl.formatMessage(
            {
              id: "series.remove.are-you-sure",
            },
            { br: <br /> },
          )}
          denyText={intl.formatMessage({
            id: "series.remove.reject",
          })}
          confirmText={intl.formatMessage({
            id: "common.confirm.remove",
            defaultMessage: "Ja, ta bort!",
          })}
          loading={false}
          onHide={() => setDeleteDialogOpen(false)}
        />
      )}
    </>
  );
};

export default AdminSeriesTableActionsColumn;
