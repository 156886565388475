import React, { forwardRef, useCallback, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { OptionProps, components } from "react-select";
import AsyncSelect from "react-select/async";

import clsx from "clsx";
import debounce from "debounce-promise";

import { FacilityWithUtc } from "../modules/customer/models/Facility";
import { User } from "../modules/player/models/User";

import { useFacility } from "../hooks/swr/useFacility";

import {
  queryActiveUsers,
  queryUsers,
} from "../modules/player/services/UserService";

import { useIsAdminMode } from "../recoil/isAdminModeState";
import { useSelectedFacilityId } from "../recoil/selectedFacilityIdState";
import { AvatarNameWrapper } from "./AvatarNameWrapper";
import { UserSearchFriendsList } from "./UserSearchFriendsList";
import { UsersList } from "./UsersList";

interface Props {
  facilityId: FacilityWithUtc["id"] | null;
  name?: string;
  title?: string;
  translationId?: string;
  isDisabled?: boolean;
  isSearchDropdownDisabled?: boolean;
  removeFromInitial?: boolean;
  displaySelection?: boolean;
  errorText?: string;
  defaultValues?: User[];
  multiSelect?: boolean;
  hideFriends?: boolean;
  filterUsers?: User[];
  searchActiveUsers?: boolean;
  onChange: (users: User[]) => void;
  onBlur?: (event: React.FocusEvent<any>) => void;
}

export const UserSearch = forwardRef<any, Props>(
  (
    {
      facilityId,
      name,
      title,
      translationId = "common.search-user",
      isDisabled = false,
      isSearchDropdownDisabled = false,
      removeFromInitial = false,
      errorText,
      defaultValues = [],
      displaySelection = true,
      multiSelect = true,
      hideFriends = false,
      filterUsers,
      searchActiveUsers = false,
      onChange,
      onBlur,
    },
    ref,
  ) => {
    const intl = useIntl();
    const abortControllerRef = useRef<AbortController>();
    const selectedFacilityId = useSelectedFacilityId();
    const [selectedValue, setSelectedValue] = useState<User>(null);
    const [selectedUsers, setSelectedUsers] = useState<User[]>(defaultValues);
    const isAdminMode = useIsAdminMode();

    const loadOptions = useCallback(
      debounce(
        async (inputValue: string) => {
          if (abortControllerRef.current) {
            abortControllerRef.current.abort();
          }

          abortControllerRef.current = new AbortController();

          let users;

          if (searchActiveUsers) {
            users = (
              await queryActiveUsers(
                inputValue,
                selectedFacilityId,
                abortControllerRef.current?.signal,
              )
            ).data;
          } else {
            users = (
              await queryUsers(
                inputValue,
                facilityId,
                false,
                abortControllerRef.current?.signal,
              )
            ).data;
          }

          const filterIds = [
            selectedUsers.map(u => u.id),
            filterUsers?.map(u => u.id),
          ].flatMap(u => u);
          return users.filter(u => !filterIds.includes(u.id));
        },
        500,
        { leading: true },
      ),
      [selectedUsers],
    );

    const onUserAdd = (newUser: User) => {
      setSelectedValue(null);

      if (multiSelect) {
        setSelectedUsers([...selectedUsers, newUser]);
        onChange([...selectedUsers, newUser]);
      } else {
        setSelectedUsers([newUser]);
        onChange([newUser]);
      }
    };

    const onUserRemove = (user: User) => {
      setSelectedUsers(selectedUsers.filter(u => u.id !== user.id));
      onChange(selectedUsers.filter(u => u.id !== user.id));
    };

    const NoOptionsMessage = (props: any) => {
      if (
        !props?.selectProps?.inputValue?.length &&
        !isAdminMode &&
        !hideFriends
      ) {
        return (
          <UserSearchFriendsList
            onClick={onUserAdd}
            selectedUsers={selectedUsers}
          />
        );
      }

      return (
        <components.NoOptionsMessage {...props}>
          <div>
            <p className="text-bold">
              <FormattedMessage id="search.user.not-found" />
            </p>
          </div>
        </components.NoOptionsMessage>
      );
    };

    return (
      <>
        <AsyncSelect
          name={name}
          inputId={name}
          ref={ref}
          placeholder={
            <div style={{ cursor: "pointer" }}>
              {translationId
                ? intl.formatMessage({ id: translationId })
                : title}
              ...
            </div>
          }
          classNames={{
            container: ({ isFocused }) => clsx(isFocused && "shadow-input"),
            valueContainer: () => "px-3 py-1.5",
          }}
          components={{ NoOptionsMessage, Option }}
          value={selectedValue}
          isDisabled={isDisabled || isSearchDropdownDisabled}
          getOptionValue={e => e.id}
          loadOptions={loadOptions}
          onChange={onUserAdd}
          onBlur={onBlur}
          menuPortalTarget={document.body}
          styles={{
            menuPortal: baseStyles => ({
              ...baseStyles,
              zIndex: 9999999999,
            }),
          }}
        />
        {displaySelection && (
          <div className="mt-4">
            <UsersList
              removeFromInitial={removeFromInitial}
              isDisabled={isDisabled}
              users={selectedUsers}
              errorText={errorText}
              onRemove={onUserRemove}
            />
          </div>
        )}
      </>
    );
  },
);
UserSearch.displayName = "UserSearch";

const Option = (props: OptionProps<User>) => {
  const u = props.data;
  const isAdminMode = useIsAdminMode();
  const { facility } = useFacility(u?.favoriteFacilityId);

  return (
    <components.Option {...props}>
      <div>
        <AvatarNameWrapper
          image={u.profileImage}
          name={u.displayName}
          firstName={u.firstName}
          lastName={u.lastName}
          title={u.displayName}
          phoneNumber={u.phoneNumber}
          emailAddress={u.emailAddress}
          includeContact={isAdminMode}
          additionalInfo={facility?.name}
        />
      </div>
    </components.Option>
  );
};
