import {
  type MaintenanceMessageDTO,
  type MaintenanceMessageRequest,
} from "../models/maintenanceMessage";

import { deletee, get, put } from "./apiClient";
import { formatMaintenanceMessage } from "./maintenanceMessageService";

const API_VERSION = "v1";
const API_SERVICE = "/superadmin/maintenance/message";
const API_ENDPOINT = `/${API_VERSION}${API_SERVICE}`;

export const getSuperAdminMaintenanceMessage = async () => {
  const maintenanceMessage = await get<MaintenanceMessageDTO | undefined>({
    endpoint: API_ENDPOINT,
  });

  if (!maintenanceMessage) {
    return undefined;
  }

  return formatMaintenanceMessage(maintenanceMessage);
};

export const upsertSuperAdminMaintenanceMessage = async (
  message: MaintenanceMessageRequest,
) => {
  return put<MaintenanceMessageDTO>({
    endpoint: API_ENDPOINT,
    body: message,
  }).then(formatMaintenanceMessage);
};

export const deleteSuperAdminMaintenanceMessage = async () => {
  await deletee({
    endpoint: API_ENDPOINT,
  });
};
