import { useParams } from "react-router-dom";

import { Card } from "../../components/Card";
import { UserRescheduleCalendar } from "../../modules/checkout/components/Calendar/UserRescheduleCalendar";

interface RescheduleParams {
  bookingId: string;
  facilityId: string;
}

export const ReschedulePage: React.FC = () => {
  const { facilityId, bookingId } = useParams<RescheduleParams>();
  return (
    <Card className="col-span-full">
      <UserRescheduleCalendar facilityId={facilityId} bookingId={bookingId} />
    </Card>
  );
};
