import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { faSend } from "@fortawesome/pro-light-svg-icons";
import { InputSwitch } from "primereact/inputswitch";

import { GeneralActivities } from "../../../../../../../../../modules/game/models/GeneralActivities";
import { User } from "../../../../../../../../../modules/player/models/User";

import { useToaster } from "../../../../../../../../../hooks/common/useToaster";

import { registerToGeneralActivity } from "../../../../../../../../../modules/game/services/GeneralActivities";

import { Button } from "../../../../../../../../../components/Button";
import { Dialog } from "../../../../../../../../../components/Dialog";
import { UserSearch } from "../../../../../../../../../components/UserSearch";

import { EventMessageEditor } from "../EventMessageEditor";
import { ParticipantsTable } from "./ParticipantsTable";

interface Props {
  activity: GeneralActivities;
  refresh: () => void;
}

export const ParticipantList = ({ activity, refresh }: Props) => {
  const { toastError, toastSuccess } = useToaster();
  const intl = useIntl();
  const [allUsersSelected, setAllUsersSelected] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState<
    GeneralActivities["registratedUsers"]
  >([]);
  const [showAddUserDialog, setShowAddUserDialog] = useState(false);
  const [showMessageEditor, setShowMessageEditor] = useState(false);
  const [user, setUser] = useState<User | null>();
  const [sendConfirmationEmailToPlayer, setSendConfirmationEmailToPlayer] =
    useState(true);

  const handleAddUser = async () => {
    if (!user) return;
    try {
      await registerToGeneralActivity(
        activity?.id,
        user?.id,
        sendConfirmationEmailToPlayer,
      );
      toastSuccess.addPlayerToGeneralActivitiy();
      refresh();
    } catch {
      toastError.addPlayerToGeneralActivitiyFailed();
    }
  };

  const onHide = () => {
    setUser(null);
    setShowAddUserDialog(false);
  };

  useEffect(() => {
    selectedUsers.length === activity?.registratedUsers?.length
      ? setAllUsersSelected(true)
      : setAllUsersSelected(false);
  }, [selectedUsers, activity.registratedUsers]);

  return (
    <div>
      <div className="mb-4 flex flex-wrap justify-between gap-8">
        <h3 className="flex-grow text-3xl font-bold">
          <FormattedMessage
            id="activity.admin.edit.participant.list.title"
            values={{
              Thin: chunk => <span className="font-normal">{chunk}</span>,
              participantsCount: activity?.registratedUsers?.length || 0,
              maxParticipants: activity?.numberOfParticipants,
            }}
          />
        </h3>

        <div className="flex max-w-max flex-col gap-6 sm:max-w-none sm:flex-row">
          {!!activity?.registratedUsers?.length && (
            <Button
              type="primary"
              size="small"
              disabled={!selectedUsers.length}
              icon={faSend}
              onClick={() => setShowMessageEditor(true)}
              text={`${intl.formatMessage({
                id: "admin.send.customer.email",
              })} ${selectedUsers.length} / ${
                activity?.registratedUsers?.length
              }`}
            />
          )}

          <Button
            type="default"
            size="small"
            onClick={() => setShowAddUserDialog(true)}
            text={intl.formatMessage({
              id: "activity.admin.add.participant.cta",
            })}
          />
        </div>
      </div>

      {!!activity?.registratedUsers?.length && (
        <ParticipantsTable
          refresh={refresh}
          users={activity?.registratedUsers}
          activityId={activity?.id}
          onSelect={setSelectedUsers}
        />
      )}

      {showAddUserDialog && (
        <Dialog visible onHide={onHide} closeOnEscape>
          <div className="sm:min-w-[400px]">
            <UserSearch
              facilityId={activity?.facilityId}
              multiSelect={false}
              title={intl.formatMessage({
                id: "search.profile.placeholder",
              })}
              errorText=""
              onChange={r => {
                setUser(r[0]);
              }}
            />
          </div>

          <div className="mt-4 flex flex-col items-end gap-3">
            <label className="flex gap-4" htmlFor="send-email">
              <FormattedMessage id="event.participant.add.send-confirmation-email-to-player" />
              <InputSwitch
                id="send-email"
                checked={sendConfirmationEmailToPlayer}
                onChange={e => setSendConfirmationEmailToPlayer(e.value)}
              />
            </label>
            <Button
              disabled={!user}
              type="primary"
              onClick={() => handleAddUser()}
              text={intl.formatMessage({
                id: "activity.admin.add.participant.cta",
              })}
            />
          </div>
        </Dialog>
      )}

      {showMessageEditor && (
        <Dialog
          onHide={() => setShowMessageEditor(false)}
          visible
          className="h-[90%] w-[90%] max-w-[1200px]"
        >
          <EventMessageEditor
            eventId={activity.id}
            isSelectedAll={allUsersSelected}
            recipientList={allUsersSelected ? [] : selectedUsers.map(u => u.id)}
            onComplete={() => setShowMessageEditor(false)}
          />
        </Dialog>
      )}
    </div>
  );
};
