import type { DateTime } from "luxon";
import useSWRImmutable from "swr/immutable";

import { getSlotBasePrice } from "../../modules/checkout/services/Pricing";

export const useSlotBasePrice = (
  facilityId?: string | null,
  courtId?: string | null,
  startTime?: DateTime | null,
  endTime?: DateTime | null,
) => {
  const { data, ...rest } = useSWRImmutable(
    facilityId && courtId && startTime && endTime
      ? ["slotBasePrice", facilityId, courtId, startTime, endTime]
      : undefined,
    ([, facilityId, courtId, startTime, endTime]) => {
      return getSlotBasePrice(facilityId, courtId, startTime, endTime).then(
        response => response.data,
      );
    },
  );

  return {
    ...rest,
    slotBasePrice: data,
  };
};
