import { useMemo } from "react";
import { FormattedMessage } from "react-intl";

import clsx from "clsx";

import { Tab } from "../models/Tabs";

interface Props {
  tabs: Tab[];
  activeIndex: number;
  onActiveIndexChange: (index: number) => void;
}

export const Tabs = ({ tabs, activeIndex = 0, onActiveIndexChange }: Props) => {
  const activeTab = useMemo(
    () => tabs.find(({ id }) => id === activeIndex),
    [activeIndex, tabs],
  );

  if (!activeTab) {
    return null;
  }

  const { Component, id, props } = activeTab;

  return (
    <div className="w-full">
      <div className="flex w-full flex-wrap gap-4 p-4 xl:flex-nowrap xl:gap-0 xl:p-0">
        {tabs.map(({ id, translationId, defaultMessage }) => (
          <div
            className={clsx(
              "flex-auto cursor-pointer whitespace-nowrap rounded border border-gray-50 p-2 text-center text-sm xl:flex-none xl:border-none xl:px-8 xl:py-4 xl:text-base xl:text-inherit",
              activeIndex === id
                ? "border-primary bg-primary text-purewhite xl:bg-purewhite xl:text-pureblack"
                : "bg-purewhite text-pureblack xl:bg-transparent xl:text-gray-700",
            )}
            key={id}
            onClick={() => onActiveIndexChange(id)}
          >
            <FormattedMessage
              id={translationId}
              defaultMessage={defaultMessage}
            />
          </div>
        ))}
      </div>

      <div className="bg-purewhite p-4 lg:p-8">
        {Component && <Component key={id} {...props} />}
      </div>
    </div>
  );
};
