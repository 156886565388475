import { FormattedMessage } from "react-intl";

import { faList } from "@fortawesome/pro-regular-svg-icons";
import { faGrid2 } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { atom, useRecoilState } from "recoil";

import { FacilityWithUtc } from "../../../../../modules/customer/models/Facility";

import { usePaginatedFacilityActivities } from "../../../../../hooks/swr/usePaginatedFacilityActivities";

import { Button } from "../../../../../components/Button";
import { ProgressSpinner } from "../../../../../components/ProgressSpinner";

import { localStoragePersistenceEffect } from "../../../../../recoil/effects/localStoragePersistenceEffect";
import { AdminActivitesGridView } from "./AdminActivitiesGridView";
import { AdminActivitiesTableView } from "./AdminActivitiesTableView";

interface Props {
  facilityId: FacilityWithUtc["id"];
  isUpcoming: boolean;
}

const viewState = atom<"grid" | "table">({
  key: "adminActivitiesListViewType",
  default: "grid",
  effects: [localStoragePersistenceEffect("adminActivitiesListViewType")],
});

export const AdminActivitiesList = ({ facilityId, isUpcoming }: Props) => {
  const [view, setView] = useRecoilState(viewState);
  const { activities, isLoading, isLoadingMore, nextCursor, loadMore, reset } =
    usePaginatedFacilityActivities(facilityId, isUpcoming);

  return (
    <>
      <div className="ml-auto flex items-center gap-1">
        <label
          className="flex size-8 cursor-pointer items-center justify-center rounded-sm border border-gray-50 transition-colors hover:border-current hover:text-primary has-[:checked]:border-current has-[:checked]:text-primary"
          htmlFor="grid"
        >
          <input
            className="hidden"
            type="radio"
            name="view"
            id="grid"
            value="grid"
            onChange={() => setView("grid")}
            checked={view === "grid"}
          />
          <FontAwesomeIcon icon={faGrid2} />
        </label>
        <label
          className="flex size-8 cursor-pointer items-center justify-center rounded-sm border border-gray-50 transition-colors hover:border-current hover:text-primary has-[:checked]:border-current has-[:checked]:text-primary"
          htmlFor="table"
        >
          <input
            className="hidden"
            type="radio"
            name="view"
            id="table"
            value="table"
            onChange={() => setView("table")}
            checked={view === "table"}
          />
          <FontAwesomeIcon icon={faList} />
        </label>
      </div>

      {view === "grid" && (
        <AdminActivitesGridView activities={activities} isLoading={isLoading} />
      )}

      {view === "table" && (
        <AdminActivitiesTableView
          facilityId={facilityId}
          activities={activities}
          isLoading={isLoading}
          refresh={reset}
        />
      )}

      {activities && activities.length > 0 && (
        <div className="mt-5 flex justify-center">
          <Button
            className="flex items-center gap-5"
            onClick={() => loadMore()}
            disabled={!nextCursor || isLoadingMore}
          >
            <span>
              <FormattedMessage id="common.show-more" />
            </span>

            {isLoadingMore && <ProgressSpinner fontSize="1rem" />}
          </Button>
        </div>
      )}
    </>
  );
};
