import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import {
  faCalendarEdit,
  faMedal,
  faTrashAlt,
} from "@fortawesome/pro-light-svg-icons";
import { faArrowUpRightFromSquare } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormik } from "formik";
import { DateTime } from "luxon";
import { InputSwitch } from "primereact/inputswitch";
import styled from "styled-components";

import { VendorType } from "../../../../../customer/models/Access";
import { GeneralActivities } from "../../../../../game/models/GeneralActivities";
import { Series } from "../../../../../game/models/Series";
import { User } from "../../../../../player/models/User";
import {
  BookingType,
  IAdminCancelBookingRequest,
  IBooking,
  ICreateBookingFormValues,
  IRescheduleBookingFormValues,
} from "../../../../models/Booking";
import {
  Payment,
  PaymentMethodName,
  PaymentStatus,
} from "../../../../models/Payment";

import { adminGetPlayerPath } from "../../../../../../helpers/pathHelpers";

import { useIsMounted } from "../../../../../../hooks/common/useIsMounted";
import { useToaster } from "../../../../../../hooks/common/useToaster";
import { useBooking } from "../../../../../../hooks/swr/useBooking";
import { useSeriesMatchInfo } from "../../../../../../hooks/swr/useSeriesMatchInfo";
import { useVendorType } from "../../../../../../hooks/swr/useVendorType";
import { useCurrencyFormat } from "../../../../../../hooks/useCurrencyFormat";
import { useDateFormat } from "../../../../../../hooks/useDateFormat";
import { useNumberFormat } from "../../../../../../hooks/useNumberFormat";

import { getGeneralActivity } from "../../../../../game/services/GeneralActivities";
import { getSeries } from "../../../../../game/services/Serie";
import { adminAddPlayerToBooking } from "../../../../../player/services/GroupService";
import {
  getAllBookingsOnExternalId,
  markAllRecurringBookingsAsPaid,
  markBookingAsPaid,
  rescheduleBooking,
} from "../../../../services/Booking";

import { Button } from "../../../../../../components/Button";
import { ConfirmationDialog } from "../../../../../../components/ConfirmationDialog";
import { Dialog } from "../../../../../../components/Dialog";
import { PaymentStatus as PaymentStatusComponent } from "../../../../../../components/PaymentStatus";
import { ProfileImageWithFallback } from "../../../../../../components/ProfileImageWithFallback";
import { ProgressSpinner } from "../../../../../../components/ProgressSpinner";
import { UserSearch } from "../../../../../../components/UserSearch";

import {
  luxonDateTimeFormat,
  luxonTimeFormat,
} from "../../../../../../utils/dateFormats";
import AdminBookingComment from "./AdminBookingComment";
import AdminConfirmCancellingForm from "./AdminConfirmCancellingForm";
import AdminConfirmRemovePlayerForm from "./AdminConfirmRemovePlayerForm";

const Wrapper = styled.div`
  display: grid;
  width: 100%;
  color: black;
  padding: 0 1rem 1rem 1rem;
`;

const Headlines = styled.div`
  font-size: 0.875rem;
  font-weight: bold;
  color: var(--gray-400);
  margin-bottom: 0.2rem;
  display: flex;
  gap: 4rem;
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 2rem;

  button {
    width: 50%;
  }
`;

const ContentWrapper = styled.div`
  margin-top: 1rem;
  span {
    font-weight: var(--bold);
  }
`;

const TwoColumns = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const ContentWrapperPrice = styled(ContentWrapper)`
  margin-bottom: 2rem;

  ${TwoColumns} {
    span {
      display: flex;
      justify-content: flex-end;
    }
  }
  ${TwoColumns}:not(:nth-child(n+3)) {
    margin-bottom: 0.75em;
    font-weight: var(--bold);
  }
  ${TwoColumns}:nth-child(n+3) {
    font-size: 0.875rem;
    padding: 0.3rem 0;
  }
  ${TwoColumns}:nth-child(n+4) {
    border-top: 1px solid var(--gray-100);
  }
`;

const TeamCaptain = styled.div`
  position: absolute;
  color: var(--yellow-500);
  font-size: var(--b4);
  right: 0;
  bottom: 0.1rem;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0.5) 100%
  );
  border-radius: 50%;
  padding: 1px 2px 0px 2px;
`;

interface IProps {
  bookingId: IBooking["id"];
  rescheduleMode: boolean;
  facilityId: string;
  formValues?: Partial<ICreateBookingFormValues>;
  onSubmitted: (
    isReschedule?: boolean,
    refreshView?: boolean,
    isEditDialogOpen?: boolean,
  ) => void;
}

const AdminEditBookingForm: React.FC<IProps> = ({
  bookingId,
  rescheduleMode,
  facilityId,
  formValues,
  onSubmitted,
}) => {
  const toaster = useToaster();
  const mounted = useIsMounted();

  const [loading, setLoading] = useState<boolean>(true);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [confirmCancellationOpen, setConfirmCancellationOpen] =
    useState<boolean>(false);
  const [confirmRemovePlayerCancelOpen, setConfirmRemovePlayerCancelOpen] =
    useState<boolean>(false);
  const [removePlayerRequest, setRemovePlayerRequest] =
    useState<IAdminCancelBookingRequest>();
  const { nf } = useNumberFormat({ maximumFractionDigits: 2 });
  const [extraBookingInfo, setExtraBookingInfo] = useState<IBooking[]>();
  const [activityInfo, setActivityInfo] = useState<
    GeneralActivities | Series
  >();
  const {
    booking,
    mutate,
    isLoading: bookingIsLoading,
  } = useBooking(
    bookingId,
    "facility,participants,court,payments,bookedby,bookableEntityType,individualprice,games",
  );
  const serieId =
    booking?.type === BookingType.Series ? booking?.externalServiceId : "";
  const matchId =
    booking?.type === BookingType.Series ? booking?.games?.[0]?.id : "";
  const [sendEmail, setSendEmail] = useState(true);
  const [addUserToBooking, setAddUserToBooking] = useState<{
    user: User | undefined;
    loading: boolean;
  }>({
    user: undefined,
    loading: false,
  });
  const { matchInfo } = useSeriesMatchInfo(serieId, matchId);

  const { vendorType } = useVendorType(facilityId);

  const { df } = useDateFormat(facilityId);
  const { cf: bookingCf } = useCurrencyFormat(
    booking?.price.currencyCode || "",
  );
  const { cf: activityCf } = useCurrencyFormat(
    activityInfo?.price?.currencyCode || "",
  );

  useEffect(() => {
    if (booking) {
      fetch();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [booking]);

  const fetch = async () => {
    try {
      setLoading(true);

      if (booking?.type == BookingType.Event && booking?.externalServiceId) {
        const include = "facility,participants,court,payments,bookedby";
        const extraResponse = await getAllBookingsOnExternalId(
          booking?.externalServiceId,
          include,
        );
        setExtraBookingInfo(extraResponse.data);

        const data = await getGeneralActivity(
          booking?.externalServiceId,
          "participants",
        );

        if (data?.data) setActivityInfo(data?.data);
      }
      if (serieId) {
        const data = await getSeries(serieId, "participants");

        if (data?.data) setActivityInfo(data.data);
      }
    } catch (ex) {
      toast.error("Kunde inte hämta bokningen. Försök igen senare.");
    } finally {
      if (mounted.current) {
        setLoading(false);
      }
    }
  };

  const getPaymentMethodString = (
    payment?: IBooking["payments"][number],
  ): string => {
    if (payment?.paymentMethod === PaymentMethodName.External) {
      const adminFirstName =
        payment?.adminUser?.displayName?.split(" ")[0] || "";
      return `${adminFirstName} - ${
        payment?.lastModified
          ? df(payment.lastModified, luxonDateTimeFormat)
          : ""
      }`;
    }

    return payment?.paymentMethod || "";
  };

  const formik = useFormik<IRescheduleBookingFormValues>({
    initialValues: {
      facilityId: formValues?.facilityId,
      courtId: formValues?.courtId,
      startTime: formValues?.startTime,
      endTime: formValues?.endTime,
    },
    onSubmit: async data => {
      if (!booking) {
        return;
      }

      try {
        setSubmitting(true);
        const succeed = await rescheduleBooking(booking?.id, data, sendEmail);

        if (succeed) {
          toast.success("Booking successfully rescheduled");

          onSubmitted();
        }
      } catch (ex: any) {
        toast.error(ex.message);
      } finally {
        setSubmitting(false);
      }
    },
    enableReinitialize: true,
    validateOnMount: true,
  });

  const handleCancelConfirmed = () => {
    setConfirmCancellationOpen(false);

    onSubmitted();
  };

  const handleRescheduleClick = () => {
    onSubmitted(true, false);
  };

  const handleMarkAsPaid = async (u: User, sendEmail = false) => {
    if (!booking) {
      return;
    }

    try {
      const res = await markBookingAsPaid(booking?.id, u?.id, sendEmail);
      if (res) {
        mutate();

        setTimeout(() => {
          // setTimeout? Really? yes. Backend replies when payment is done.
          // We need to wait for series status updates, and since backend dont wait we do.
          // We guess 1s is enough. It should really really really be enough.

          onSubmitted(undefined, true, true);
          fetch();
        }, 1000);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const isLateToUpdate = () => {
    if (!booking) return true;

    return booking.startTime < DateTime.now();
  };

  const handleRemovePlayerCancelConfirmed = () => {
    setConfirmRemovePlayerCancelOpen(false);
    setRemovePlayerRequest(null);
    onSubmitted();
  };

  const handleUnBookPlayerClick = async (u: User, ar: boolean, ra: boolean) => {
    const req: IAdminCancelBookingRequest = {
      alwaysRefund: ar,
      userIds: [u?.id],
      refundAll: ra,
    };
    setRemovePlayerRequest(req);
    setConfirmRemovePlayerCancelOpen(true);
  };

  const completedPayments = booking?.payments.filter(function (payment) {
    return (
      payment?.status === PaymentStatus.FullyCompleted ||
      payment?.status === PaymentStatus.MainSplitPaymentCompleted
    );
  });
  const payedAmount = getPayedAmount(completedPayments);
  const expectedAmount = getExpectedAmount(
    completedPayments,
    booking?.individualDefaultPrice.valueInclTax,
    booking?.court?.bookableEntityType?.defaultNumberOfPlayers,
    booking?.individualFee.valueInclTax,
  );
  const paymentStatus = getPaymentStatus(completedPayments);
  const payedFee = getExpectedFee(
    completedPayments,
    booking?.court?.bookableEntityType?.defaultNumberOfPlayers,
    booking?.individualFee.valueInclTax,
    paymentStatus,
  );

  // These rules should not live in front end code. But they do. Hey ho. Lets go.
  const canReschedule =
    (booking?.type === BookingType.Series && matchInfo?.isRescheduleAllowed) ||
    (!isLateToUpdate() &&
      booking?.type != BookingType.Event &&
      booking?.type != BookingType.Series);

  if (loading || bookingIsLoading) {
    return <ProgressSpinner />;
  }

  return (
    <Wrapper>
      <h3>
        <FormattedMessage id="admin-edit-booking-form-booking-info" />
      </h3>
      <form onSubmit={formik.handleSubmit}>
        {(booking?.type === BookingType.Event ||
          booking?.type === BookingType.Series) && (
          <>
            <ContentWrapper>
              <Headlines>
                <FormattedMessage
                  id={
                    booking?.type === BookingType.Event
                      ? "common.event"
                      : "common.serie"
                  }
                />
              </Headlines>
              <Link
                to={
                  booking?.type === BookingType.Event
                    ? `/admin/activities/event/${activityInfo?.id}`
                    : `/admin/activities/serie/${activityInfo?.id}`
                }
                target="_blank"
              >
                {activityInfo?.name}
                <FontAwesomeIcon
                  className="ml-2"
                  size="xs"
                  icon={faArrowUpRightFromSquare}
                />
              </Link>
            </ContentWrapper>

            {booking?.type === BookingType.Event && (
              <div>
                {extraBookingInfo?.map((extra, key) => (
                  <TwoColumns key={key}>
                    <ContentWrapper>
                      <Headlines>
                        <FormattedMessage id="common.date" />
                      </Headlines>
                      <span>
                        {df(extra.startTime, DateTime.DATETIME_MED)} -{" "}
                        {df(extra.endTime, luxonTimeFormat)}
                      </span>
                    </ContentWrapper>
                    <ContentWrapper>
                      <Headlines>
                        <FormattedMessage id="admin-edit-booking-form-court" />
                      </Headlines>
                      <span>{extra.court.name}</span>
                    </ContentWrapper>
                  </TwoColumns>
                ))}

                <TwoColumns>
                  <ContentWrapper>
                    <Headlines>
                      <FormattedMessage id="common.price-per-person" />
                    </Headlines>
                    <span>{activityCf(activityInfo?.price.valueInclTax)}</span>
                  </ContentWrapper>
                  <ContentWrapper>
                    <Headlines>
                      <FormattedMessage id="common.booked-by" />
                    </Headlines>
                    <span>{booking?.bookedBy?.displayName}</span>
                  </ContentWrapper>
                </TwoColumns>
              </div>
            )}
          </>
        )}

        {booking?.type != BookingType.Event && (
          <>
            <ContentWrapper>
              <div>
                <Headlines>
                  <FormattedMessage id="common.date" />
                </Headlines>

                <span>
                  {booking &&
                    (rescheduleMode && formValues
                      ? df(formik.values.startTime, DateTime.DATETIME_MED)
                      : df(booking.startTime, DateTime.DATETIME_MED))}{" "}
                  -{" "}
                  {booking &&
                    (rescheduleMode && formValues
                      ? df(formik.values.endTime, luxonTimeFormat)
                      : df(booking.endTime, luxonTimeFormat))}
                </span>
              </div>
            </ContentWrapper>

            <ContentWrapper>
              <Headlines>
                <FormattedMessage id="admin-edit-booking-form-court" />
              </Headlines>
              <span>
                {booking?.court?.name}&nbsp;
                <span>{booking?.court?.bookableEntityType?.name}</span>
              </span>
            </ContentWrapper>

            {booking?.type === BookingType.NotBookable &&
              vendorType === VendorType.QT && (
                <ContentWrapper>
                  <Headlines>
                    <FormattedMessage id="admin.booking.create.nonBookable.enable.lights" />
                  </Headlines>
                  <InputSwitch checked={booking.turnOnLight} disabled />
                </ContentWrapper>
              )}

            {booking?.type !== BookingType.NotBookable && (
              <>
                <ContentWrapper>
                  <Headlines>
                    <FormattedMessage id="admin-edit-booking-form-players" />
                  </Headlines>
                  <div className="flex flex-col gap-4">
                    {booking?.participants.map((user, key) => (
                      <div
                        key={key}
                        className="grid grid-cols-[auto_0.5fr_1fr_1rem] items-start gap-4"
                      >
                        <Link
                          className="relative mt-1"
                          to={adminGetPlayerPath(user.id)}
                        >
                          <ProfileImageWithFallback
                            src={user?.profileImage}
                            firstName={user?.firstName}
                            lastName={user?.lastName}
                          />
                          {booking?.organizerId === user?.id && (
                            <TeamCaptain>
                              <FontAwesomeIcon icon={faMedal} />
                            </TeamCaptain>
                          )}
                        </Link>

                        <div>
                          <p className="[overflow-wrap:anywhere]">
                            {user?.displayName}
                          </p>
                          {booking?.type !== BookingType.Series && (
                            <PaymentStatusComponent
                              user={user}
                              showEmailToggle={true}
                              onMarkAsPaid={
                                booking?.isPaid === false
                                  ? handleMarkAsPaid
                                  : undefined
                              }
                            />
                          )}
                        </div>

                        {user?.paymentMethod ? (
                          <div className="flex flex-col items-start text-xs">
                            {user?.paymentMethod ===
                              PaymentMethodName.External && (
                              <span>
                                <FormattedMessage id="admin.marked-as-paid-by" />
                                :
                              </span>
                            )}
                            <span>
                              {getPaymentMethodString(
                                booking.payments.find(
                                  p => p.userId === user?.id,
                                ),
                              )}
                            </span>
                            <span>
                              {user?.paymentMethod === PaymentMethodName.Coins
                                ? nf(user?.totalPayedIncTax)
                                : bookingCf(user?.totalPayedIncTax)}
                            </span>
                          </div>
                        ) : (
                          <div></div>
                        )}

                        {!isLateToUpdate() &&
                          booking?.type !== BookingType.Series &&
                          booking?.organizerId !== user?.id && (
                            <button
                              className="mt-1 place-self-center"
                              type="button"
                              onClick={() =>
                                handleUnBookPlayerClick(user, true, false)
                              }
                            >
                              <FontAwesomeIcon icon={faTrashAlt} />
                            </button>
                          )}
                      </div>
                    ))}
                  </div>
                </ContentWrapper>

                {!isLateToUpdate() &&
                  booking?.facility &&
                  booking.court.bookableEntityType?.defaultNumberOfPlayers &&
                  booking.participants.length <
                    booking.court.bookableEntityType.defaultNumberOfPlayers && (
                    <>
                      <ContentWrapper>
                        <Headlines>
                          <FormattedMessage id="series.add-player" />
                        </Headlines>

                        {/*
                        `!addUserToBooking.user` is a conditional hack because <UserSearch> saves it's own state,
                        causing it to not display previously selected users in later searches.
                      */}
                        {!addUserToBooking.user && (
                          <UserSearch
                            facilityId={booking?.facility.id}
                            multiSelect={false}
                            displaySelection={false}
                            filterUsers={booking.participants}
                            onChange={selectedUsers =>
                              setAddUserToBooking({
                                user: selectedUsers[0],
                                loading: false,
                              })
                            }
                          />
                        )}
                      </ContentWrapper>

                      {addUserToBooking.user && (
                        <ConfirmationDialog
                          visible
                          title={addUserToBooking.user.displayName}
                          text={
                            <FormattedMessage id="admin.edit-booking-form.add-player.confirmation.text" />
                          }
                          loading={addUserToBooking.loading}
                          onHide={() =>
                            setAddUserToBooking({
                              user: undefined,
                              loading: false,
                            })
                          }
                          onCancel={() =>
                            setAddUserToBooking({
                              user: undefined,
                              loading: false,
                            })
                          }
                          onSubmit={async () => {
                            if (!addUserToBooking.user) {
                              return;
                            }

                            setAddUserToBooking(v => ({
                              ...v,
                              loading: true,
                            }));

                            try {
                              await adminAddPlayerToBooking(
                                facilityId,
                                bookingId,
                                booking.groupId,
                                addUserToBooking.user.id,
                              );

                              mutate();
                            } catch (e) {
                              toaster.toastError.unknown();
                            } finally {
                              setAddUserToBooking({
                                user: undefined,
                                loading: false,
                              });
                            }
                          }}
                        />
                      )}
                    </>
                  )}
              </>
            )}
            <ContentWrapper>
              <Headlines>
                <FormattedMessage id="admin-edit-booking-form-bookedby" />
              </Headlines>
              <span>{booking?.bookedBy?.displayName || ""}</span>
            </ContentWrapper>
            <ContentWrapper>
              <Headlines>
                <FormattedMessage id="admin-edit-booking-form-created" />
              </Headlines>
              <span>
                {booking?.createdDate &&
                  df(booking?.createdDate, luxonDateTimeFormat)}
              </span>
            </ContentWrapper>
            <ContentWrapper>
              <AdminBookingComment
                booking={booking}
                facilityId={facilityId}
                fetch={mutate}
                onSubmitted={onSubmitted}
              />

              {rescheduleMode && (
                <div className="mt-5 flex justify-end gap-5 text-sm font-bold text-gray-700">
                  <label htmlFor="sendEmail">
                    <FormattedMessage id="admin.send.participant.email" />
                  </label>
                  <InputSwitch
                    inputId="sendEmail"
                    checked={sendEmail}
                    onChange={() => setSendEmail(!sendEmail)}
                  />
                </div>
              )}
            </ContentWrapper>

            {typeof booking?.type !== "undefined" &&
              booking?.type !== BookingType.Series && (
                <ContentWrapperPrice>
                  <Headlines>
                    <FormattedMessage id="admin-edit-booking-form-pay-info" />
                  </Headlines>

                  <TwoColumns>
                    <FormattedMessage id="admin-edit-booking-form-total" />
                    <span>
                      {bookingCf(
                        paymentStatus == PaymentStatus.FullyCompleted
                          ? payedAmount || 0
                          : expectedAmount ||
                              booking?.price.valueInclTax +
                                booking?.fee.valueInclTax ||
                              0,
                      )}
                    </span>
                  </TwoColumns>
                  <TwoColumns>
                    <span
                      style={
                        (paymentStatus == PaymentStatus.FullyCompleted
                          ? payedAmount || 0
                          : expectedAmount ||
                            booking?.price.valueInclTax +
                              booking?.fee.valueInclTax) === payedAmount
                          ? {
                              justifyContent: "flex-start",
                            }
                          : {
                              color: "var(--danger)",
                              justifyContent: "flex-start",
                            }
                      }
                    >
                      <FormattedMessage id="admin-edit-booking-form-paid-amount" />
                    </span>
                    <span
                      style={
                        (paymentStatus == PaymentStatus.FullyCompleted
                          ? payedAmount || 0
                          : expectedAmount ||
                            booking?.price.valueInclTax +
                              booking?.fee.valueInclTax) === payedAmount
                          ? {}
                          : {
                              color: "var(--danger)",
                            }
                      }
                    >
                      {bookingCf(payedAmount || 0)}
                    </span>
                  </TwoColumns>

                  <TwoColumns>
                    <FormattedMessage id="admin-edit-booking-form-bookingfee" />
                    <span>
                      {bookingCf((payedAmount || 0) > 0 ? payedFee || 0 : 0)}
                    </span>
                  </TwoColumns>
                </ContentWrapperPrice>
              )}
          </>
        )}

        {booking && booking?.type === BookingType.Recurring && (
          <div>
            <MarkAllRecurringBookingsAsPaidButton booking={booking} />
          </div>
        )}

        <ButtonWrapper
          style={
            booking?.type == BookingType.Series
              ? { justifyContent: "flex-end" }
              : {}
          }
        >
          {!isLateToUpdate() &&
            booking?.type != BookingType.Event &&
            booking?.type != BookingType.Series && (
              <Button
                type="danger"
                buttonType="button"
                onClick={() => setConfirmCancellationOpen(true)}
                translationName="button.unbook"
                text="Avboka"
                disabled={submitting}
              />
            )}
          {!rescheduleMode && canReschedule && (
            <Button
              key="reschedule"
              type="primary"
              buttonType="button"
              onClick={handleRescheduleClick}
              translationName="button.reschedule"
              icon={faCalendarEdit}
              text="Ändra bokning"
              disabled={submitting}
            />
          )}

          {rescheduleMode && (
            <Button
              key="save" // This is important to not submit on reschedule click | React issue
              type="primary"
              buttonType="submit"
              translationName="button.book"
              text="Spara"
              disabled={submitting}
            />
          )}
        </ButtonWrapper>
      </form>

      {confirmCancellationOpen && (
        <Dialog visible onHide={() => setConfirmCancellationOpen(false)}>
          <AdminConfirmCancellingForm
            bookingId={booking?.id}
            onCancel={() => setConfirmCancellationOpen(false)}
            onSubmit={handleCancelConfirmed}
          />
        </Dialog>
      )}

      {confirmRemovePlayerCancelOpen && (
        <Dialog visible onHide={() => setConfirmRemovePlayerCancelOpen(false)}>
          <AdminConfirmRemovePlayerForm
            bookingId={booking?.id}
            request={removePlayerRequest}
            onCancel={() => setConfirmRemovePlayerCancelOpen(false)}
            onSubmit={handleRemovePlayerCancelConfirmed}
          />
        </Dialog>
      )}
    </Wrapper>
  );
};

const MarkAllRecurringBookingsAsPaidButton = ({
  booking,
  refetchData,
}: {
  booking: IBooking;
  refetchData?: () => void;
}) => {
  const intl = useIntl();
  const toaster = useToaster();

  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmDialogVisible, setIsConfirmDialogVisible] = useState(false);

  return (
    <>
      <Button
        className="mt-2"
        translationName="admin-edit-booking-form.mark-all-recurring-bookings-as-paid"
        size="small"
        type="default"
        disabled={isLoading}
        loading={isLoading}
        onClick={() => setIsConfirmDialogVisible(true)}
      />

      {isConfirmDialogVisible && (
        <ConfirmationDialog
          visible
          confirmButtonType="danger"
          title={intl.formatMessage({
            id: "admin-edit-booking-form.mark-all-recurring-bookings-as-paid.confirmation.title",
          })}
          onCancel={() => setIsConfirmDialogVisible(false)}
          onHide={() => setIsConfirmDialogVisible(false)}
          onSubmit={async () => {
            setIsConfirmDialogVisible(false);
            setIsLoading(true);

            try {
              await markAllRecurringBookingsAsPaid(booking.id);
              setTimeout(() => {
                // setTimeout? Really? yes. Backend replies when payment is done.
                // We need to wait for series status updates, and since backend dont wait we do.
                // We guess 1s is enough. It should really really really be enough.

                refetchData?.();

                setIsLoading(false);
                toaster.toastSuccess.message(
                  "admin-edit-booking-form.all-recurring-bookings-marked-as-paid.toast.success",
                );
              }, 3000);
            } catch (error) {
              setIsLoading(false);
              toaster.toastError.unknown();
            }
          }}
        />
      )}
    </>
  );
};

const hasSplitPaymentInfo = (payment: Payment): boolean => {
  return (
    payment?.splitPaymentInfo?.isSplitPayment &&
    !!payment?.splitPaymentInfo?.splittedPriceIncTax
  );
};

const getPayedAmount = (payments: Payment[]): number => {
  if (!payments) return 0;

  if (!payments.length || !(payments.length > 0)) return 0;

  // in case of split payment
  if (payments.every(hasSplitPaymentInfo)) {
    const amounts = payments.map(x => x.splitPaymentInfo.splittedPriceIncTax);

    const totalAmount = amounts.reduce(
      (total, currentValue) => (total = total + currentValue),
      0,
    );
    return totalAmount;
  }

  return payments[0]?.totalPriceIncTax ?? 0;
};

const getPaymentStatus = (payments: Payment[]): PaymentStatus | 0 => {
  if (!payments) return 0;
  if (!payments.length || !(payments.length > 0)) return 0;

  const maxValue = Math.max.apply(
    null,
    payments.map(function (o) {
      return o.status;
    }),
  );

  return maxValue ?? 0;
};

const getExpectedAmount = (
  payments: Payment[],
  individualPriceIncTax: number,
  numberOfPlayers: number,
  individualFeeIncTax: number,
): number => {
  if (
    !payments ||
    !payments.length ||
    !(payments.length > 0) ||
    numberOfPlayers == 0
  )
    return (individualPriceIncTax + individualFeeIncTax) * numberOfPlayers;

  const splitPayments = payments.map(x =>
    Number(x.splitPaymentInfo.isSplitPayment),
  );
  const numberOfSplitPayments = splitPayments.reduce((a, v) => (a = a + v), 0);

  return (
    (individualPriceIncTax + individualFeeIncTax) *
      (numberOfPlayers - numberOfSplitPayments) +
    getPayedAmount(payments)
  );
};

const getExpectedFee = (
  payments: Payment[],
  numberOfPlayers: number,
  individualFeeIncTax: number,
  paymentState: number,
): number => {
  if (
    !payments ||
    !payments.length ||
    !(payments.length > 0) ||
    numberOfPlayers == 0
  )
    return individualFeeIncTax * numberOfPlayers;

  const splitPayments = payments.map(x =>
    Number(x.splitPaymentInfo.isSplitPayment),
  );
  const numberOfSplitPayments = splitPayments.reduce((a, v) => (a = a + v), 0);

  return paymentState == 4
    ? individualFeeIncTax * numberOfPlayers
    : individualFeeIncTax *
        ((numberOfSplitPayments ?? numberOfPlayers) == 0
          ? numberOfPlayers
          : numberOfSplitPayments);
};

export default AdminEditBookingForm;
