import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";

import {
  faClose,
  faSearch,
  faSpinnerThird,
} from "@fortawesome/pro-light-svg-icons";
import styled from "styled-components";

import { User } from "../models/User";

import { Button } from "../../../components/Button";

interface StyleProps {
  $visible?: boolean;
  $disabled?: boolean;
}

const SearchWrapper = styled.div<StyleProps>`
  display: flex;
  flex-direction: column;
  background: var(--gray-100);
  height: 100%;
  z-index: 100;
  width: 100%;

  ${props =>
    props.$visible
      ? `
      width: 100%;
      position: absolute;
      display: flex;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0
      z-index: 9;
      border-radius: var(--border-radius);
    `
      : "display: none;"}
`;

const SearchField = styled.input`
  padding: 1rem 3rem 1rem 1rem;
  height: 100%;
  width: 100%;
  position: absolute;
  margin: 0;
  display: flex;
  font-size: 1rem;
  max-width: 100%;
  border: 0;

  &::-ms-clear {
    display: none;
    width: 0;
    height: 0;
  }
  &::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }

  &:placeholder-shown {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &:placeholder-shown {
    text-overflow: ellipsis;
  }
  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }

  &:focus {
    outline: none;
  }
`;

const TriggerSearchButton = styled(Button)`
  padding: 0rem;
  background: transparent !important;
  box-shadow: none;

  svg {
    color: black !important;
  }

  &:hover {
    background: none !important;

    svg {
      color: black !important;
    }
  }
`;

const CloseSearchButton = styled(TriggerSearchButton)`
  padding: 0.5rem;
  position: absolute;
  top: 12%;
  right: 1rem;
`;

interface Props {
  alwaysActive?: boolean;
  showResults?: boolean;
  facilityId?: string;
  onSearching?: (isSearching: boolean) => void;
  onSearchInputChange?: (searchInput: string) => void;
  onSearchResultChange?: (users: User[]) => void;
  isLoading: boolean;
}

export const AdminPlayerSearch: React.FC<Props> = ({
  alwaysActive,
  onSearchInputChange,
  isLoading,
}) => {
  const intl = useIntl();
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [searchInput, setSearchInput] = useState<string>("");

  const toggleVisible = () => setIsVisible(isVisible => !isVisible);

  useEffect(() => setIsVisible(alwaysActive), [alwaysActive]);

  useEffect(() => {
    if (isVisible) {
      document.getElementById("user-search-input")?.focus();
    }
  }, [isVisible]);

  return (
    <>
      <TriggerSearchButton
        onClick={
          alwaysActive
            ? () => onSearchInputChange?.(searchInput)
            : toggleVisible
        }
        type="primary"
        icon={isLoading ? faSpinnerThird : faSearch}
        iconProps={{ spin: isLoading }}
      />
      <SearchWrapper $visible={isVisible}>
        {!alwaysActive && (
          <CloseSearchButton
            onClick={toggleVisible}
            type="primary"
            icon={faClose}
          />
        )}
        <SearchField
          id="user-search-input"
          type="search"
          value={searchInput}
          placeholder={intl.formatMessage({
            id: "search.profile.placeholder",
            defaultMessage: "Sök efter spelare",
          })}
          autoComplete="off"
          onChange={e => setSearchInput(e.target.value)}
          onKeyDown={e =>
            e.key?.toLowerCase() === "enter" &&
            onSearchInputChange?.(searchInput)
          }
        />
      </SearchWrapper>
    </>
  );
};
