import { forwardRef, useEffect, useMemo, useReducer } from "react";

import { DateTime } from "luxon";
import {
  Calendar,
  CalendarChangeParams,
  CalendarChangeTargetOptions,
  CalendarProps,
} from "primereact/calendar";

import { useIsMobile } from "../../hooks/common/useIsMobile";

import { useAppLocale } from "../../recoil/i18nConfigState";

export interface CalendarDateTimeWrapperProps
  extends Omit<CalendarProps, "maxDate" | "minDate" | "onChange" | "value"> {
  maxDate?: DateTime;
  minDate?: DateTime;
  value?: DateTime;
  onChange?: (e: CalendarDateTimeWrapperChangeParams) => void;
}

interface CalendarDateTimeWrapperChangeParams
  extends Omit<CalendarChangeParams, "value" | "target"> {
  value: DateTime;
  target: CalendarDateTimeWrapperChangeTargetOptions;
}

interface CalendarDateTimeWrapperChangeTargetOptions
  extends Omit<CalendarChangeTargetOptions, "value"> {
  value: DateTime;
}

export const CalendarDateTimeWrapper = forwardRef<
  Calendar,
  CalendarDateTimeWrapperProps
>(function CalendarDateTimeWrapper(
  { maxDate, minDate, value, onChange, ...rest },
  ref,
) {
  const isMobile = useIsMobile();

  const locale = useAppLocale();
  const [, forceUpdate] = useReducer(x => x + 1, 0);

  const hour12Format = useMemo(() => {
    const IntlDateTimeFormatter = new Intl.DateTimeFormat(locale, {
      timeStyle: "short",
    });

    const { hour12 } = IntlDateTimeFormatter.resolvedOptions();

    return hour12;
  }, [locale]);

  useEffect(() => {
    forceUpdate();
  }, [locale]);

  const handleDateChange = (e: CalendarChangeParams) => {
    // Intentionally not typed.
    let value;

    if (Array.isArray(e.value)) {
      value = e.value.map(date =>
        date instanceof Date ? DateTime.fromJSDate(date) : null,
      );
    } else if (e.value instanceof Date) {
      value = DateTime.fromJSDate(e.value as Date);
    } else {
      value = null;
    }

    if (onChange) {
      onChange({
        ...e,
        value,
        target: { ...e.target, value },
      });
    }
  };

  const min = minDate ? minDate.toJSDate() : null;
  const max = maxDate ? maxDate.toJSDate() : null;
  const selectedDate = value
    ? Array.isArray(value)
      ? value.map(v => (v instanceof DateTime ? v.toJSDate() : null))
      : value.toJSDate()
    : null;

  return (
    <Calendar
      minDate={min}
      maxDate={max}
      value={selectedDate}
      onChange={handleDateChange}
      hourFormat={hour12Format ? "12" : "24"}
      touchUI={rest.touchUI ?? isMobile}
      {...rest}
      ref={ref}
    />
  );
});
