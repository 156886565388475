import { HashRouter as Router, useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { IPublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { RecoilRoot } from "recoil";

import LanguageWrapper from "./components/LanguageWrapper";

import { AuthedApp } from "./AuthedApp";
import { reactPlugin } from "./applicationInsights";
import { CustomNavigationClient } from "./utils/NavigationClient";

interface Props {
  pca: IPublicClientApplication;
}

const App = ({ pca }: Props) => {
  const history = useHistory();
  const navigationClient = new CustomNavigationClient(history);

  pca.setNavigationClient(navigationClient);

  return (
    <RecoilRoot>
      <MsalProvider instance={pca}>
        <Router>
          <AppInsightsContext.Provider value={reactPlugin}>
            <LanguageWrapper>
              <AuthedApp />
              <ToastContainer position="bottom-right" closeOnClick stacked />
            </LanguageWrapper>
          </AppInsightsContext.Provider>
        </Router>
      </MsalProvider>
    </RecoilRoot>
  );
};

export default App;
