import { atom, useRecoilValue } from "recoil";

import {
  DEFAULT_I18N_CONFIG,
  I18nConfig,
  LOCAL_STORAGE_LOCALE_KEY,
} from "../appConstants/i18n";

export const i18nConfigState = atom<I18nConfig>({
  key: "i18nConfig",
  default: DEFAULT_I18N_CONFIG,
  effects: [
    ({ onSet }) => {
      onSet(newValue => {
        window.localStorage.setItem(LOCAL_STORAGE_LOCALE_KEY, newValue.locale);
      });
    },
  ],
});

export const useAppI18nConfig = () => {
  const i18nConfig = useRecoilValue(i18nConfigState);

  return { i18nConfig };
};

export const useAppLocale = () => {
  const {
    i18nConfig: { locale },
  } = useAppI18nConfig();

  return locale;
};
