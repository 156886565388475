import { DateTime } from "luxon";

import { ApiResponse, ApiSingleResponse } from "../../../models/common";
import {
  GameModel,
  GameViewModel,
  Games,
  GamesApiResponse,
  UpdateGameMatchesRequest,
} from "../../checkout/models/Games";
import { FacilityWithUtc } from "../../customer/models/Facility";

import { fetchApi } from "../../../services/legacyApiClient";

const apiVersion = "game/v4";

export const getGames = async (
  isUpcoming = false,
  page = 1,
  pageSize = 3,
  signal?: AbortSignal,
): Promise<ApiResponse<Games>> => {
  try {
    const include = isUpcoming ? "payments,defaults" : "payments,defaults";
    const response = (await fetchApi({
      uri: `${apiVersion}/games?isUpcoming=${isUpcoming}&page=${page}&pageSize=${pageSize}&include=${include}`,
      signal,
    })) as ApiResponse<GamesApiResponse>;

    return {
      ...response,
      data: response.data.map(formatGameFromApi),
    };
  } catch (err) {
    return { data: [], count: 0 };
  }
};

export const getGame = (
  id: string,
  signal?: AbortSignal,
): Promise<ApiSingleResponse<GameViewModel>> => {
  return fetchApi({ uri: `${apiVersion}/games/single/${id}`, signal })
    .then((data: ApiSingleResponse<GameViewModel>) => {
      return Promise.resolve(data);
    })
    .catch(() => {
      return Promise.reject(null);
    });
};

export const getFavouriteFacility = async (
  signal?: AbortSignal,
): Promise<ApiSingleResponse<FacilityWithUtc["id"]>> =>
  (await fetchApi({
    uri: `${apiVersion}/games/facility/favourite`,
    signal,
  })) as ApiSingleResponse<FacilityWithUtc["id"]>;

export const updateGameMatches = (
  request: UpdateGameMatchesRequest,
): Promise<ApiResponse<GameModel>> => {
  return fetchApi({
    method: "POST",
    uri: `${apiVersion}/games/${request.gameId}/matches`,
    payload: request.matches,
  })
    .then((data: ApiResponse<GameModel>) => {
      return Promise.resolve(data);
    })
    .catch(() => {
      return Promise.reject(null);
    });
};

export const lockAndCalculateResults = (
  gameId: string,
  signal?: AbortSignal,
): Promise<ApiSingleResponse<boolean>> => {
  return fetchApi({
    method: "PUT",
    uri: `${apiVersion}/games/${gameId}/lockandcalculateresult`,
    signal,
  })
    .then((data: ApiSingleResponse<boolean>) => {
      return Promise.resolve(data);
    })
    .catch(() => {
      return Promise.reject(null);
    });
};

function formatGameFromApi(games: GamesApiResponse): Games {
  return {
    ...games,
    startTime: DateTime.fromISO(games.startTime, { setZone: true }),
    endTime: DateTime.fromISO(games.endTime, { setZone: true }),
  };
}
