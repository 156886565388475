import { useIntl } from "react-intl";

import { GroupTypeStrings } from "../../player/models/Group";

interface StandardGroup {
  id: GroupTypeStrings;
  name: string;
}

export const useStandardGroup = (): StandardGroup => {
  const intl = useIntl();

  return {
    id: GroupTypeStrings.STANDARD,
    name: intl.formatMessage({
      id: "pricings.standard-price",
      defaultMessage: "Standardpris",
    }),
  };
};
