import { Fragment, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { type FormikHelpers, useFormik } from "formik";
import { DateTime } from "luxon";
import { Dropdown } from "primereact/dropdown";
import { InputSwitch } from "primereact/inputswitch";
import {
  type InferType,
  array,
  boolean,
  mixed,
  number,
  object,
  string,
} from "yup";

import { DateOnly } from "../../../../../../models/DateOnly";
import { OpenBookingClassCategoryEnum } from "../../../../../../models/OpenBookings";
import { TimeOnly } from "../../../../../../models/TimeOnly";
import { RecurringBookingType } from "../../../../../../models/common";
import { VendorType } from "../../../../../customer/models/Access";
import {
  BookingType,
  type InitialAdminCalendarBookingData,
  PaymentType,
  PlayersFilterType,
} from "../../../../models/Booking";
import type { AvailableSlots } from "../../../../models/Calendar";

import { useToaster } from "../../../../../../hooks/common/useToaster";
import { useIsSuperAdmin } from "../../../../../../hooks/permissions";
import { useCurrentUser } from "../../../../../../hooks/swr/useCurrentUser";
import { useFacility } from "../../../../../../hooks/swr/useFacility";
import { useSlotBasePrice } from "../../../../../../hooks/swr/useSlotBasePrice";
import { useVendorType } from "../../../../../../hooks/swr/useVendorType";
import { useDateFormat } from "../../../../../../hooks/useDateFormat";

import { createOpenBooking } from "../../../../../../services/facilityOpenBookingsService";
import {
  createBooking,
  createRecurringBookings,
} from "../../../../services/Booking";
import { getAvailableSlots } from "../../../../services/Schedule";

import { Button } from "../../../../../../components/Button";
import { CalendarInput } from "../../../../../../components/CalendarInput";
import { Checkbox } from "../../../../../../components/Checkbox";
import { ConfirmationDialog } from "../../../../../../components/ConfirmationDialog";
import { Label } from "../../../../../../components/Label";
import { NumberInput } from "../../../../../../components/NumberInput";
import { ProgressSpinner } from "../../../../../../components/ProgressSpinner";
import { SkillLevelSlider } from "../../../../../../components/SkillLevelSlider";
import { TextInput } from "../../../../../../components/TextInput";
import { UserSearch } from "../../../../../../components/UserSearch";
import { SelectInput } from "../../../../../../components/inputs/SelectInput";

import { DayOfWeek } from "../../../../../../enums/DayOfWeek";

type RecurringType =
  | "never"
  | "weekly"
  | "biweekly"
  | "triweekly"
  | "quadweekly"
  | "daily";

const schema = object({
  type: number()
    .oneOf([
      BookingType.Regular,
      BookingType.NotBookable,
      BookingType.Recurring,
      BookingType.Open,
    ])
    .required(),
  recurringType: string()
    .oneOf<RecurringType>([
      "never",
      "weekly",
      "biweekly",
      "triweekly",
      "quadweekly",
      "daily",
    ])
    .required(),
  daysOfWeek: array()
    .of(number().required())
    .required()
    .when("recurringType", {
      is: (v: RecurringType) =>
        ["weekly", "biweekly", "triweekly", "quadweekly"].includes(v),
      then: schema => schema.min(1),
    }),
  toDate: string().required(),
  turnOnLight: boolean().when("type", {
    is: BookingType.NotBookable,
    then: schema => schema.required(),
  }),
  isFixedPrice: boolean().required(),
  fixedPrice: number().when("isFixedPrice", {
    is: true,
    then: schema => schema.required(),
  }),
  automaticCancellationTime: mixed<DateTime>().required(),
  classCategory: string()
    .oneOf<OpenBookingClassCategoryEnum>([
      OpenBookingClassCategoryEnum.Men,
      OpenBookingClassCategoryEnum.Women,
      OpenBookingClassCategoryEnum.Mixed,
    ])
    .required(),
  minSkillLevel: number().required(),
  maxSkillLevel: number().required(),
  participants: array()
    .of(string().required())
    .required()
    .min(1)
    .when("type", {
      is: (type: BookingType) =>
        [BookingType.NotBookable, BookingType.Open].includes(type),
      then: schema => schema.min(0),
    }),
  comment: string(),
  isShownInCalendar: boolean().required(),
});

type FormValues = InferType<typeof schema>;

interface Props {
  initialBookingData: InitialAdminCalendarBookingData;
  onSubmitted: (refreshView?: boolean) => void;
}

const AdminCreateBookingForm = ({
  initialBookingData: { facilityId, courtId, startTime, endTime },
  onSubmitted,
}: Props) => {
  const intl = useIntl();

  const toaster = useToaster();
  const { vendorType, isLoading } = useVendorType(facilityId);
  const { currentUser } = useCurrentUser();
  const { df, setZone } = useDateFormat(facilityId);
  const isSuperAdmin = useIsSuperAdmin();
  const { facility } = useFacility(facilityId);

  const [availableSlots, setAvailableSlots] = useState<
    AvailableSlots[] | undefined
  >();

  const { slotBasePrice, isLoading: isSlotBasePriceLoading } = useSlotBasePrice(
    facilityId,
    courtId,
    startTime,
    endTime,
  );

  const [skillLevel, setSkillLevel] = useState<[number, number]>([1, 10]);

  const facilityOffsetToAdd =
    DateTime.local({ zone: facility?.localization?.timeZone }).offset -
    DateTime.now().offset;

  const onSubmit = async (
    values: FormValues,
    formikHelpers: FormikHelpers<FormValues>,
  ) => {
    if (!currentUser) {
      return;
    }

    const data = {
      isAdmin: true,
      facilityId,
      courtId,
      startTime,
      endTime,
      type: values.type,
      isOrganizerParticipant: false,
      organizerId: currentUser.id,
      participants:
        values.type === BookingType.NotBookable ? [] : values.participants,
      comment: values.comment,
      isShownInCalendar: values.isShownInCalendar,
      turnOnLight:
        values.type === BookingType.NotBookable
          ? values.turnOnLight
          : undefined,
      paymentType: PaymentType.Solid,
      playersFilterType: PlayersFilterType.Specific,
      fixedPriceIncTax: values.isFixedPrice ? values.fixedPrice : undefined,
    };

    try {
      if (values.recurringType !== "never") {
        const recurringData = {
          ...data,
          fromDate: DateOnly.fromDateTime(startTime),
          toDate: DateOnly.fromISODate(values.toDate),
          startTime: TimeOnly.fromDateTime(setZone(startTime)),
          endTime: TimeOnly.fromDateTime(setZone(endTime)),
          daysOfWeek: values.daysOfWeek,
          recurringGroupType:
            values.recurringType === "daily"
              ? RecurringBookingType.Daily
              : RecurringBookingType.Weekly,
          interval: recurringTypeToInterval(values.recurringType),
        };

        let slots = availableSlots;

        if (!slots) {
          const { data } = await getAvailableSlots(
            recurringData,
            facilityId,
            courtId,
          );

          slots = data;

          const isAllAvailable = slots.every(({ isAvailable }) => isAvailable);

          if (!isAllAvailable) {
            setAvailableSlots(slots);
            formikHelpers.setSubmitting(false);
            return;
          }
        }

        const { data: bookings } = await createRecurringBookings(
          {
            ...recurringData,
            startTime: slots[0].startTime,
            endTime: slots[slots.length - 1].endTime,
          },
          facilityId,
        );

        setAvailableSlots(undefined);

        if (bookings.length > 0) {
          onSubmitted();
        }
      } else {
        setAvailableSlots(undefined);

        if (values.type === BookingType.Open) {
          await createOpenBooking(facilityId, {
            courtId: data.courtId,
            comment: data.comment,
            startTime: data.startTime,
            endTime: data.endTime,
            automaticCancellationTime: values.automaticCancellationTime.minus({
              minutes: facilityOffsetToAdd,
            }),
            fixedPriceIncTax: data.fixedPriceIncTax,
            classCategory: values.classCategory,
            minSkillLevel: values.minSkillLevel,
            maxSkillLevel: values.maxSkillLevel,
            isCommentVisibleInCalendar: data.isShownInCalendar,
          });

          onSubmitted();
        } else {
          const { data: booking } = await createBooking(data, facilityId);

          if (booking) {
            onSubmitted();
          }
        }
      }
    } catch {
      toaster.toastError.unknown();
    }
  };

  const formik = useFormik({
    validationSchema: schema,
    validateOnMount: true,
    initialValues: {
      type: BookingType.Regular,
      recurringType: "never",
      daysOfWeek: [startTime.weekday % 7],
      turnOnLight: false,
      toDate: endTime.plus({ days: 1 }).toISODate(),
      isFixedPrice: false,
      fixedPrice: slotBasePrice?.price.valueInclTax,
      participants: [],
      comment: "",
      isShownInCalendar: false,
      automaticCancellationTime: startTime.plus({
        minutes: facilityOffsetToAdd,
      }),
      classCategory: OpenBookingClassCategoryEnum.Men,
      minSkillLevel: skillLevel[0],
      maxSkillLevel: skillLevel[1],
    },
    onSubmit,
  });

  useEffect(() => {
    if (
      formik.values.type === BookingType.NotBookable &&
      !isSuperAdmin &&
      formik.values.recurringType !== "never"
    ) {
      formik.setFieldValue("recurringType", "never");
    }
  }, [formik, formik.values.type, isSuperAdmin]);

  useEffect(() => {
    if (
      formik.values.recurringType === "never" &&
      formik.values.type === BookingType.Recurring
    ) {
      formik.setFieldValue("type", BookingType.Regular);
    }

    if (
      formik.values.recurringType !== "never" &&
      formik.values.type === BookingType.Regular
    ) {
      formik.setFieldValue("type", BookingType.Recurring);
    }
  }, [formik]);

  // Reset recurring type if open booking since open booking can't be recurring
  useEffect(() => {
    if (
      formik.values.type === BookingType.Open &&
      formik.values.recurringType !== "never"
    ) {
      formik.setFieldValue("recurringType", "never");
    }
  }, [formik]);

  const recurringTypes = [
    {
      label: intl.formatMessage({
        id: "common.never",
      }),
      value: "never",
    },
  ];

  if (formik.values.type !== BookingType.NotBookable || isSuperAdmin) {
    recurringTypes.push(
      {
        label: intl.formatMessage({
          id: "common.every-day",
        }),
        value: "daily",
      },
      {
        label: intl.formatMessage({
          id: "common.every-week",
        }),
        value: "weekly",
      },
      {
        label: intl.formatMessage({
          id: "common.every-other-week",
        }),
        value: "biweekly",
      },
      {
        label: intl.formatMessage({
          id: "common.every-third-week",
        }),
        value: "triweekly",
      },
      {
        label: intl.formatMessage({
          id: "common.every-fourth-week",
        }),
        value: "quadweekly",
      },
    );
  }

  const court = facility?.bookableEntities?.find(court => court.id === courtId);

  if (isLoading || isSlotBasePriceLoading) {
    return <ProgressSpinner />;
  }

  if (
    !slotBasePrice ||
    typeof vendorType === "undefined" ||
    !currentUser ||
    !court
  ) {
    return null;
  }

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <h3>
          <FormattedMessage id="common.create-booking" />
        </h3>
        <h5 className="mb-4">{court.name}</h5>

        <div className="mb-4">
          <SelectInput
            label={intl.formatMessage({
              id: "common.choice.bookingtype",
            })}
            name="type"
            value={formik.values.type}
            options={[
              {
                value:
                  formik.values.recurringType === "never"
                    ? BookingType.Regular
                    : BookingType.Recurring,
                label: intl.formatMessage({
                  id: "receipts.translation.booking.types",
                }),
              },
              {
                value: BookingType.Open,
                label: intl.formatMessage({
                  id: "common.open-booking",
                }),
              },
              {
                value: BookingType.NotBookable,
                label: intl.formatMessage({
                  id: "calendar.slot.not-bookable",
                }),
              },
            ]}
            onChange={formik.handleChange}
          />
        </div>

        {formik.values.type !== BookingType.Open && (
          <div className="mb-4">
            <Label htmlFor="recurringType" className="mb-1.5 block">
              <FormattedMessage id="common.repeat" />
            </Label>

            <Dropdown
              inputId="recurringType"
              name="recurringType"
              value={formik.values.recurringType}
              onChange={formik.handleChange}
              options={recurringTypes}
            />
          </div>
        )}

        {["weekly", "biweekly", "triweekly", "quadweekly"].includes(
          formik.values.recurringType,
        ) && (
          <div className="mb-4">
            <Label htmlFor="weekDay" className="mb-1.5 block">
              <FormattedMessage id="common.weekday" />
            </Label>
            <div className="mb-4 grid grid-cols-7 gap-4">
              {[
                DayOfWeek.Monday,
                DayOfWeek.Tuesday,
                DayOfWeek.Wednesday,
                DayOfWeek.Thursday,
                DayOfWeek.Friday,
                DayOfWeek.Saturday,
                DayOfWeek.Sunday,
              ].map(dayOfWeek => {
                const datetime = DateTime.local({ zone: "utc" }).set({
                  weekday: dayOfWeek,
                });

                return (
                  <div key={dayOfWeek} className="relative">
                    <input
                      name="daysOfWeek"
                      type="checkbox"
                      id={dayOfWeek.toString()}
                      value={dayOfWeek}
                      onChange={e => {
                        if (e.target.checked) {
                          formik.setFieldValue("daysOfWeek", [
                            ...formik.values.daysOfWeek,
                            dayOfWeek,
                          ]);
                        } else {
                          formik.setFieldValue(
                            "daysOfWeek",
                            formik.values.daysOfWeek.filter(
                              item => item !== dayOfWeek,
                            ),
                          );
                        }
                      }}
                      className="peer sr-only"
                      checked={formik.values.daysOfWeek.includes(dayOfWeek)}
                    />
                    <label
                      className="flex aspect-square cursor-pointer select-none items-center justify-center rounded-10 border border-current text-gray-300 outline-2 outline-current transition-colors hover:text-primary peer-checked:font-semibold peer-checked:text-primary peer-checked:outline peer-focus-visible:text-primary"
                      title={df(datetime, { weekday: "long" })}
                      htmlFor={dayOfWeek.toString()}
                    >
                      <>
                        {df(datetime, {
                          weekday: "short",
                        })}
                      </>
                    </label>
                    <span className="pointer-events-none absolute -right-1 -top-1 hidden size-3 items-center justify-center rounded-full bg-primary text-purewhite peer-checked:flex">
                      <FontAwesomeIcon icon={faXmark} className="text-[9px]" />
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        )}

        {formik.values.recurringType !== "never" && (
          <div className="mb-4 grid gap-4 sm:grid-cols-2">
            <CalendarInput
              disabled
              label={intl.formatMessage({ id: "common.startDate" })}
              value={startTime}
            />
            <CalendarInput
              name="toDate"
              label={intl.formatMessage({ id: "common.endDate" })}
              value={
                formik.values.toDate
                  ? DateTime.fromISO(formik.values.toDate)
                  : undefined
              }
              onChange={e =>
                e.value && formik.setFieldValue("toDate", e.value.toISODate())
              }
              minDate={DateTime.local().plus({ days: 1 })}
            />
          </div>
        )}

        <div className="mb-4">
          <div className="grid gap-4 sm:grid-cols-2">
            <CalendarInput
              id="startTime"
              name="startTime"
              label={intl.formatMessage({ id: "common.from" })}
              disabled
              timeOnly
              value={startTime.plus({ minutes: facilityOffsetToAdd })}
            />
            <CalendarInput
              id="endTime"
              name="endTime"
              label={intl.formatMessage({ id: "common.to" })}
              disabled
              timeOnly
              value={endTime.plus({ minutes: facilityOffsetToAdd })}
            />
          </div>
        </div>

        {formik.values.type !== BookingType.NotBookable && (
          <>
            <div className="mb-4">
              <Checkbox
                label={intl.formatMessage({ id: "common.fixed-price" })}
                name="isFixedPrice"
                onChange={formik.handleChange}
                checked={formik.values.isFixedPrice}
              />
            </div>

            <div className="mb-4">
              {formik.values.isFixedPrice ? (
                <NumberInput
                  name="fixedPrice"
                  key="fixedPrice"
                  label={intl.formatMessage({ id: "common.fixed-price" })}
                  value={formik.values.fixedPrice}
                  onChange={formik.handleChange}
                />
              ) : (
                <NumberInput
                  key="price"
                  label={intl.formatMessage({ id: "pricings.standard-price" })}
                  defaultValue={slotBasePrice.price.valueInclTax}
                  disabled
                />
              )}
            </div>
          </>
        )}

        {formik.values.type === BookingType.Open && (
          <>
            <div className="mb-4">
              <CalendarInput
                showTime
                stepMinute={30}
                name="automaticCancellationTime"
                label={intl.formatMessage({
                  id: "common.automatic-cancellation-time",
                })}
                value={formik.values.automaticCancellationTime}
                onChange={e =>
                  e.value &&
                  formik.setFieldValue("automaticCancellationTime", e.value)
                }
                minDate={DateTime.utc()
                  .plus({
                    hours: 2,
                    minutes: facilityOffsetToAdd,
                  })
                  .startOf("hour")}
                maxDate={startTime.plus({ minutes: facilityOffsetToAdd })}
              />
            </div>

            <div className="mb-4">
              <SelectInput
                label={intl.formatMessage({
                  id: "common.class",
                })}
                name="classCategory"
                value={formik.values.classCategory}
                options={[
                  {
                    value: OpenBookingClassCategoryEnum.Men,
                    label: intl.formatMessage({
                      id: "gender-types.male",
                    }),
                  },
                  {
                    value: OpenBookingClassCategoryEnum.Women,
                    label: intl.formatMessage({
                      id: "gender-types.female",
                    }),
                  },
                  {
                    value: OpenBookingClassCategoryEnum.Mixed,
                    label: intl.formatMessage({
                      id: "gender-types.mix",
                    }),
                  },
                ]}
                onChange={formik.handleChange}
              />
            </div>

            <div className="mb-4 flex flex-1 flex-col gap-1.5">
              <Label>
                <FormattedMessage id="common.level" />
              </Label>
              <div className="flex min-w-96 flex-1 items-center pt-[22px] sm:mt-0">
                <span className="mr-8 w-[1.5em] flex-none text-right font-bold">
                  {skillLevel[0].toFixed(1)}
                </span>
                <SkillLevelSlider
                  min={1}
                  max={10}
                  step={0.1}
                  value={skillLevel}
                  onChange={(e, value) => {
                    if (!Array.isArray(value)) {
                      return;
                    }

                    setSkillLevel(value as [number, number]);
                  }}
                  onChangeCommitted={(e, value) => {
                    if (!Array.isArray(value)) {
                      return;
                    }

                    formik.setFieldValue("minSkillLevel", value[0]);
                    formik.setFieldValue("maxSkillLevel", value[1]);
                  }}
                />
                <span className="ml-8 w-[1.5em] flex-none font-bold">
                  {skillLevel[1].toFixed(1)}
                </span>
              </div>
            </div>
          </>
        )}

        {![BookingType.NotBookable, BookingType.Open].includes(
          formik.values.type,
        ) && (
          <div className="mb-4">
            <UserSearch
              isDisabled={
                formik.values.participants.length >=
                (court.bookableEntityType?.defaultNumberOfPlayers ?? Infinity)
              }
              facilityId={facilityId}
              errorText={
                formik.errors?.participants &&
                intl.formatMessage({
                  id: "common.emailAdress.message",
                })
              }
              onChange={value =>
                formik.setFieldValue(
                  "participants",
                  value.map(v => v.id),
                )
              }
            />
          </div>
        )}

        <div className="mb-4">
          <TextInput
            name="comment"
            label={intl.formatMessage({
              id: "common.comment",
            })}
            onChange={formik.handleChange}
            value={formik.values.comment}
          />
          <div className="mt-1 flex justify-end text-sm">
            <label className="flex items-center">
              <input
                type="checkbox"
                name="isShownInCalendar"
                className="mr-2 accent-primary"
                defaultChecked={formik.values.isShownInCalendar}
                onChange={formik.handleChange}
              />
              <FormattedMessage id="common.show-in-calendar" />
            </label>
          </div>
        </div>

        <div className="flex flex-col items-end">
          {formik.values.type === BookingType.NotBookable &&
            vendorType === VendorType.QT && (
              <label
                className="mb-4 flex items-center gap-2"
                htmlFor="turnOnLight"
              >
                <InputSwitch
                  id="turnOnLight"
                  name="turnOnLight"
                  checked={formik.values.turnOnLight}
                  onChange={formik.handleChange}
                />
                <FormattedMessage id="admin.booking.create.nonBookable.enable.lights" />
              </label>
            )}

          <Button
            type="primary"
            buttonType="submit"
            translationName="button.book"
            disabled={!formik.isValid || formik.isSubmitting}
            loading={formik.isSubmitting}
          />
        </div>
      </form>

      {availableSlots && (
        <ConfirmationDialog
          visible
          onHide={() => setAvailableSlots(undefined)}
          title={intl.formatMessage({
            id: "admin.calendar.recurring.unavailable-dialog.title",
          })}
          onSubmit={() => {
            formik.submitForm();
          }}
          onCancel={() => setAvailableSlots(undefined)}
          confirmText={intl.formatMessage({
            id: "admin.calendar.recurring.unavailable-dialog.confirm",
          })}
          denyText={intl.formatMessage({
            id: "admin.calendar.recurring.unavailable-dialog.deny",
          })}
          loading={formik.isSubmitting}
        >
          <div className="mb-14 mt-8 flex justify-center">
            <div className="grid grid-cols-2 gap-x-8 gap-y-4 text-left">
              {availableSlots.map(slot => (
                <Fragment key={slot.startTime.toJSON()}>
                  <div>
                    <p className="text-gray-700">
                      <FormattedMessage id="common.date-and-time" />
                    </p>
                    <span className="font-semibold">
                      {df(slot.startTime, DateTime.DATETIME_MED)} -{" "}
                      {df(slot.endTime, DateTime.TIME_SIMPLE)}
                    </span>
                  </div>
                  <span
                    className={clsx(
                      "place-self-end rounded-lg px-2 font-semibold",
                      slot.isAvailable
                        ? "bg-green-700/10 text-green-700"
                        : "bg-red-600/10 text-red-600",
                    )}
                  >
                    {slot.isAvailable ? (
                      <FormattedMessage id="admin.calendar.recurring.unavailable-dialog.slot.available" />
                    ) : (
                      <FormattedMessage id="admin.calendar.recurring.unavailable-dialog.slot.occupied" />
                    )}
                  </span>
                </Fragment>
              ))}
            </div>
          </div>
        </ConfirmationDialog>
      )}
    </>
  );
};

const recurringTypeToInterval = (recurringType: RecurringType) => {
  switch (recurringType) {
    default:
    case "daily":
      return 1;
    case "weekly":
      return 1;
    case "biweekly":
      return 2;
    case "triweekly":
      return 3;
    case "quadweekly":
      return 4;
  }
};

export default AdminCreateBookingForm;
