import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import {
  type IconLookup,
  faCalendar,
  faCheckCircle,
  faMoneyBill1,
  faSitemap,
  faTableTennisPaddleBall,
  faWarehouse,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateTime } from "luxon";

import { getMyProfileTabPath } from "../../helpers/pathHelpers";

import { useFacility } from "../../hooks/swr/useFacility";
import { useCurrencyFormat } from "../../hooks/useCurrencyFormat";
import { useDateFormat } from "../../hooks/useDateFormat";

import { Button } from "../../components/Button";

import { userProfileTabs } from "../../modules/player/constants";
import { luxonTimeFormat } from "../../utils/dateFormats";
import { getPaymentMethodText } from "../../utils/getPaymentMethodText";
import { CheckoutViewHeading } from "./CheckoutView";
import { useCheckoutState } from "./context/CheckoutContext";

export const Confirmation = () => {
  const { orderType, order } = useCheckoutState();

  return (
    <div className="grid lg:grid-cols-2">
      <div className="flex items-center justify-center bg-purewhite p-5">
        <div className="max-w-[40ch]">
          <div className="text-center">
            <FontAwesomeIcon
              icon={faCheckCircle}
              className="mx-auto text-6xl text-green-700 lg:text-[90px]"
            />
            <CheckoutViewHeading className="mt-3 lg:mt-6">
              {orderType === "booking" && !order.isSplittable ? (
                <FormattedMessage id="checkout.confirmation.non-splittable-booking.heading" />
              ) : (
                <FormattedMessage
                  id={`checkout.confirmation.${orderType}.heading`}
                />
              )}
            </CheckoutViewHeading>
            <p className="mt-3 lg:text-md">
              {orderType === "booking" && !order.isSplittable ? (
                <FormattedMessage id="checkout.confirmation.non-splittable-booking.text" />
              ) : (
                <FormattedMessage
                  id={`checkout.confirmation.${orderType}.text`}
                />
              )}
            </p>
          </div>

          <ul className="mt-4 divide-y border-gray-50 lg:mt-8">
            {orderType === "booking" && <BookingOrderDetails />}
            {orderType === "event" && <EventOrderDetails />}
            {orderType === "series" && <SeriesOrderDetails />}
          </ul>

          {orderType === "booking" ? (
            <LinkButton
              to={getMyProfileTabPath(userProfileTabs.UPCOMPING_BOOKINGS)}
              translationsId="user-profile.tab.my-bookings"
            />
          ) : ["event", "series"].includes(orderType) ? (
            <LinkButton
              to={getMyProfileTabPath(userProfileTabs.ACTIVITIES)}
              translationsId="activities.upcoming-activities"
            />
          ) : null}
        </div>
      </div>

      <picture className="flex place-self-stretch">
        <source srcSet="/checkout-confirmation.avif" type="image/avif" />
        <source srcSet="/checkout-confirmation.webp" type="image/webp" />
        <img
          className="object-cover object-bottom"
          src="/checkout-confirmation.jpg"
          alt="Court22"
        />
      </picture>
    </div>
  );
};

const BookingOrderDetails = () => {
  const state = useCheckoutState();

  const { df, dfInterval } = useDateFormat(state.order.facilityId);
  const { cf } = useCurrencyFormat(state.order.total.currencyCode);

  if (state.orderType !== "booking") {
    return null;
  }

  const { booking } = state;

  return (
    <>
      <OrderDetail
        icon={faCalendar}
        firstRowText={dfInterval(
          booking.startTime,
          booking.endTime,
          luxonTimeFormat,
        )}
        secondRowText={df(booking.startTime, DateTime.DATE_MED)}
      />
      <OrderDetail
        icon={faWarehouse}
        firstRowText={booking.facility.name}
        secondRowText={booking.court.name}
      />
      <OrderDetail
        icon={faMoneyBill1}
        firstRowText={cf(
          state.isSplitPayment || !state.order.isSplittable
            ? state.order.individualTotal.valueInclTax
            : state.order.total.valueInclTax,
        )}
        secondRowText={
          state.selectedPaymentMethod?.instrument
            ? getPaymentMethodText(state.selectedPaymentMethod)
            : ""
        }
      />
    </>
  );
};

const SeriesOrderDetails = () => {
  const state = useCheckoutState();

  const { dfInterval } = useDateFormat(state.order.facilityId);
  const { cf } = useCurrencyFormat(state.order.total.currencyCode);

  if (state.orderType !== "series") {
    return null;
  }

  const { series } = state;

  return (
    <>
      <OrderDetail icon={faSitemap} firstRowText={series.name} />
      <OrderDetail
        icon={faCalendar}
        firstRowText={dfInterval(
          series.startTime,
          series.endTime,
          DateTime.DATE_MED,
        )}
      />
      <OrderDetail icon={faWarehouse} firstRowText={series.facilityName} />
      <OrderDetail
        icon={faMoneyBill1}
        firstRowText={cf(
          state.isSplitPayment || !state.order.isSplittable
            ? state.order.individualTotal.valueInclTax
            : state.order.total.valueInclTax,
        )}
        secondRowText={
          state.selectedPaymentMethod?.instrument
            ? getPaymentMethodText(state.selectedPaymentMethod)
            : ""
        }
      />
    </>
  );
};

const EventOrderDetails = () => {
  const state = useCheckoutState();

  const { df, dfInterval } = useDateFormat(state.order.facilityId);
  const { cf } = useCurrencyFormat(state.order.total.currencyCode);
  const { facility } = useFacility(state.order.facilityId);

  if (state.orderType !== "event") {
    return null;
  }

  const { event } = state;

  return (
    <>
      <OrderDetail icon={faTableTennisPaddleBall} firstRowText={event.name} />
      <OrderDetail
        icon={faCalendar}
        firstRowText={dfInterval(
          event.startTime,
          event.endTime,
          luxonTimeFormat,
        )}
        secondRowText={df(event.startTime, DateTime.DATE_MED)}
      />
      <OrderDetail
        icon={faWarehouse}
        firstRowText={event.facilityName}
        secondRowText={event.courtIdsToPlay
          ?.map(courtId => {
            return facility?.bookableEntities?.find(
              bookableEntity => bookableEntity.id === courtId,
            )?.name;
          })
          .join(", ")}
      />
      <OrderDetail
        icon={faMoneyBill1}
        firstRowText={cf(
          state.isSplitPayment || !state.order.isSplittable
            ? state.order.individualTotal.valueInclTax
            : state.order.total.valueInclTax,
        )}
        secondRowText={
          state.selectedPaymentMethod?.instrument
            ? getPaymentMethodText(state.selectedPaymentMethod)
            : ""
        }
      />
    </>
  );
};

const OrderDetail = ({
  icon,
  firstRowText,
  secondRowText,
}: {
  icon: IconLookup;
  firstRowText: React.ReactNode;
  secondRowText?: React.ReactNode;
}) => {
  return (
    <li className="flex items-center gap-3 py-3 lg:py-4">
      <FontAwesomeIcon
        icon={icon}
        className="text-lg text-primary lg:text-3xl"
        fixedWidth
      />
      <div>
        <p className="text-md lg:text-lg">{firstRowText}</p>
        {secondRowText && (
          <p className="text-gray-700 lg:text-md">{secondRowText}</p>
        )}
      </div>
    </li>
  );
};

const LinkButton = ({
  to,
  translationsId,
}: {
  to: React.ComponentProps<Link>["to"];
  translationsId: React.ComponentProps<typeof FormattedMessage>["id"];
}) => {
  return (
    <Link to={to} className="mt-4 block self-stretch">
      <Button type="primary" className="w-full">
        <FormattedMessage id={translationsId} />
      </Button>
    </Link>
  );
};
