import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { FormattedMessage } from "react-intl";

import { faFileUpload } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import heic2any from "heic2any";
import { ProgressSpinner } from "primereact/progressspinner";
import styled from "styled-components";

import { useToaster } from "../../hooks/common/useToaster";

import ImageInputCropper from "./ImageInputCropper";

interface Props {
  title?: string;
  translationName?: string;
  aspectRatio?: [number, number];
  cropper?: boolean;
  onSave: (file: File) => void;
}

interface StyleProps {
  $isDragActive?: boolean;
  $showBackground?: boolean;
  $isDragAccept?: boolean;
  $isDragReject?: boolean;
}

const Container = styled.div<StyleProps>`
  width: 100%;
  border-radius: var(--border-radius);
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid var(--gray-200);

  ${props =>
    !props.$showBackground &&
    `
    background-color: transparent;
    border:none;
  `}

  h5 {
    width: 100% !important;
  }

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    text-align: center;
  }
`;

const ImageUploadContainer = styled.div<StyleProps>`
  display: flex;
  gap: 1rem;
  min-width: 10rem;
  border-radius: var(--gray-200);
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  position: relative;
  min-height: 10rem;
  border: 2px solid $gray-200;
  cursor: pointer;

  ${props => props.$isDragAccept && "border-color: var(--success);"}
  ${props => props.$isDragReject && "border-color: var(--danger);"}

  svg {
    color: var(--primary);
  }
`;

const ImageInput: React.FC<Props> = ({
  title,
  translationName,
  aspectRatio = [1, 1],
  cropper = false,
  onSave,
}) => {
  const { toastWarning } = useToaster();
  const [imageSrc, setImageSrc] = useState<string>(null);
  const [imageName, setImageName] = useState<string>("");
  const [imageType, setImageType] = useState<string>("");
  const [cropperDialogOpen, setCropperDialogOpen] = useState<boolean>(false);
  const [isLoading] = useState<boolean>(false);

  const handleImageSelect = async (files: File[]) => {
    try {
      if (!cropper) {
        return onSave(files[0]);
      }

      const typeName = files[0]?.name?.split(".")?.pop();
      const myFile = new File([files[0]], `image.${typeName}`, {
        type: files[0].type,
      });

      const fileReader = new FileReader();

      fileReader.onload = async () => {
        const b64 = fileReader.result?.toString();
        // get type from b64 since HEIC is not file type but type of blob.
        const type = b64.match(/:(.*);/)?.[1] || "";
        const typeName = type?.split("/")?.pop();
        setImageSrc(b64 || "");
        setImageName(`${Date.now()}.${typeName}`);
        setImageType(type);
        setCropperDialogOpen(true);
      };

      if (files[0]?.type?.includes("heic")) {
        const jpgBlob = (await heic2any({
          blob: myFile,
          toType: "image/jpeg",
        })) as Blob;
        fileReader.readAsDataURL(jpgBlob);
      } else {
        fileReader.readAsDataURL(myFile);
      }
    } catch {
      toastWarning.invalidImageFormat();
    }
  };

  const {
    getRootProps,
    getInputProps,
    isDragAccept,
    isDragReject,
    isDragActive,
  } = useDropzone({
    multiple: false,
    onDrop: handleImageSelect,
    accept: {
      "image/*": [".png", ".jpg", ".jpeg"],
      "image/heic": [".heic"],
    },
  });

  return (
    <Container
      $isDragActive={isDragActive}
      $showBackground={!cropperDialogOpen}
    >
      {cropper && cropperDialogOpen ? (
        <ImageInputCropper
          imageSrc={imageSrc}
          imageName={imageName}
          imageType={imageType}
          aspectRatio={aspectRatio}
          onSave={(file: File) => {
            setCropperDialogOpen(false);
            onSave(file);
          }}
          onCancel={() => setCropperDialogOpen(false)}
        />
      ) : (
        <>
          <ImageUploadContainer
            $isDragAccept={isDragAccept}
            $isDragReject={isDragReject}
            {...getRootProps({ role: "button" })}
          >
            <input {...getInputProps()} />
            <div style={{ padding: "2rem" }}>
              <FontAwesomeIcon size="3x" icon={faFileUpload} />
              <h5>
                <FormattedMessage
                  id={translationName || "image.upload.drop-here"}
                  defaultMessage={
                    title ?? "Välj eller dra och släpp en ny profilbild här"
                  }
                />
              </h5>
            </div>
            {isLoading && (
              <div
                style={{
                  marginTop: "1rem",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <FormattedMessage
                  id="image.upload.converting"
                  defaultMessage="Konverterar bild"
                />
                <ProgressSpinner />
              </div>
            )}
          </ImageUploadContainer>
        </>
      )}
    </Container>
  );
};

export default ImageInput;
