import React from "react";

import { useRefetchFacilityInformation } from "../hooks/useRefetchFacilityInformation";

import { SubmitResetButtons } from "../../../components/SubmitResetButtons";

interface Props {
  disabled?: boolean;
  onSubmit?: (e?: any) => Promise<unknown>;
  onReset?: (e?: any) => void;
}

export const FacilitySubmitResetButtons: React.FC<Props> = ({
  disabled,
  onSubmit,
  onReset,
}) => {
  const { facilityUpdatedCallback } = useRefetchFacilityInformation();
  return (
    <SubmitResetButtons
      disabled={disabled}
      onReset={onReset}
      onClick={e => {
        if (onSubmit) {
          e.preventDefault();
          e.stopPropagation();
          facilityUpdatedCallback(() => onSubmit(e));
        }
      }}
    />
  );
};
