import { useCallback, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  type GroupBase,
  type GroupHeadingProps,
  type OptionProps,
  PlaceholderProps,
  components,
} from "react-select";
import AsyncSelect from "react-select/async";

import {
  faChevronDown,
  faMagnifyingGlass,
} from "@fortawesome/pro-regular-svg-icons";
import { faCheck, faUser } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { debounce } from "lodash";

import type { User } from "../../../modules/player/models/User";

import { useFriends } from "../../../hooks/swr/useFriends";

import { queryUsers } from "../../../modules/player/services/UserService";

import { ProfileImageWithFallback } from "../../../components/ProfileImageWithFallback";

import { useCheckout, useCheckoutState } from "../context/CheckoutContext";

interface ReactSelectGroup extends GroupBase<User> {
  groupType: "friends" | "players";
  options: User[];
}

export const UserSelect = () => {
  const intl = useIntl();

  const {
    state: { order, coPayingUsers, checkoutStatus },
    dispatch,
  } = useCheckout();

  const { friends } = useFriends();

  const selectedUserIds = coPayingUsers.map(user => user.id);
  const friendsGroup: ReactSelectGroup = useMemo(
    () => ({
      label: intl.formatMessage({ id: "common.friends" }),
      groupType: "friends",
      options: friends ?? [],
    }),
    [friends, intl],
  );

  const searchUsers = (searchString: string) =>
    queryUsers(searchString, null, false).then(response => response.data);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadOptions = useCallback(
    debounce((searchString, callback) => {
      searchUsers(searchString).then(users => {
        const userGroups = users.reduce<Record<string, ReactSelectGroup>>(
          (acc, user) => {
            if (user.id === order.userId) {
              return acc;
            }

            const friend = friends?.find(friend => friend.id === user.id);

            if (friend) {
              acc.friends.options.push(user);
            } else {
              acc.players.options.push(user);
            }

            return acc;
          },
          {
            friends: {
              label: intl.formatMessage({ id: "common.friends" }),
              groupType: "friends",
              options: [],
            },
            players: {
              label: intl.formatMessage({ id: "common.players" }),
              groupType: "players",
              options: [],
            },
          },
        );

        callback([userGroups.friends, userGroups.players]);
      });
    }, 300),
    [friends],
  );

  const isSelectDisabled = checkoutStatus === "paymentPending";

  return (
    <AsyncSelect<User, true, ReactSelectGroup>
      components={{
        Placeholder,
        IndicatorSeparator: null,
        GroupHeading,
        Option,
        MenuList: props => (
          <components.MenuList {...props} className="space-y-2" />
        ),
        Group: ({ children, ...props }) => (
          <components.Group {...props}>
            <div>{children}</div>
          </components.Group>
        ),
        Input: props => (
          <components.Input {...props} className="m-0 text-pureblack" />
        ),
        DropdownIndicator: props => (
          <components.DropdownIndicator {...props} className="p-0">
            <FontAwesomeIcon
              icon={faChevronDown}
              className={clsx(
                "origin-center transition-transform lg:text-lg",
                props.selectProps.menuIsOpen && "rotate-180",
              )}
            />
          </components.DropdownIndicator>
        ),
        Control: ({ children, ...props }) => (
          <components.Control {...props} className="rounded px-4 text-gray-700">
            {props.isFocused && (
              <FontAwesomeIcon
                icon={faMagnifyingGlass}
                className="mr-2 lg:text-lg"
              />
            )}
            {children}
          </components.Control>
        ),
        ValueContainer: props => (
          <components.ValueContainer
            {...props}
            className="py-2 pl-0 pr-2 font-semibold text-gray-700 lg:text-md"
          />
        ),
      }}
      loadingMessage={() => (
        <FormattedMessage id="common.progress-spinner.loading" />
      )}
      isDisabled={isSelectDisabled}
      isMulti
      isClearable={false}
      value={coPayingUsers}
      defaultOptions={[friendsGroup]}
      getOptionLabel={user => user.displayName ?? "n/a"}
      getOptionValue={user => user.id}
      hideSelectedOptions={false}
      blurInputOnSelect={false}
      controlShouldRenderValue={false}
      menuShouldScrollIntoView={false}
      closeMenuOnSelect={false}
      menuPlacement="auto"
      noOptionsMessage={({ inputValue }) =>
        inputValue.length > 0 ? (
          <FormattedMessage
            id="checkout.section.split-payment.dropdown.no-options-message"
            values={{ searchTerm: inputValue }}
          />
        ) : (
          <FormattedMessage id="checkout.section.split-payment.dropdown.placeholder.focused" />
        )
      }
      onChange={users => {
        dispatch({
          type: "SET_CO_PAYING_USERS",
          coPayingUsers: (users as User[]) ?? [],
        });
      }}
      isOptionDisabled={option =>
        selectedUserIds.length >= order.maxCoPayingUsers &&
        !selectedUserIds.includes(option.id)
      }
      loadOptions={loadOptions}
    />
  );
};
const Placeholder = (props: PlaceholderProps<User, true, ReactSelectGroup>) => {
  return (
    <components.Placeholder {...props} className="m-0 text-gray-700">
      {props.isFocused ? (
        <FormattedMessage id="checkout.section.split-payment.dropdown.placeholder.focused" />
      ) : (
        <FormattedMessage id="checkout.section.split-payment.dropdown.placeholder.unfocused" />
      )}
    </components.Placeholder>
  );
};
const GroupHeading = (
  props: GroupHeadingProps<User, true, ReactSelectGroup>,
) => {
  const { data: group } = props;

  return (
    <components.GroupHeading
      {...props}
      className="flex items-center gap-[.375rem] px-3 text-xs font-bold normal-case text-pureblack md:text-sm lg:px-5"
    >
      {group.label}

      <FontAwesomeIcon icon={faUser} />
    </components.GroupHeading>
  );
};
const Option = (
  props: React.PropsWithChildren<OptionProps<User, true, ReactSelectGroup>>,
) => {
  const { data: user } = props;

  const { order } = useCheckoutState();

  const regex = new RegExp(`(${props.selectProps.inputValue})`, "ig");

  return (
    <>
      <components.Option
        {...props}
        className={clsx(
          "group m-0 bg-inherit p-0 px-3 transition-colors lg:px-5",
          props.isDisabled
            ? "cursor-not-allowed opacity-30"
            : "cursor-pointer hover:bg-gray-100",
        )}
      >
        <div className="flex items-center gap-2 lg:gap-5">
          <ProfileImageWithFallback
            hasImage={!!user?.profileImage}
            src={user?.profileImage}
            firstName={user?.firstName}
            lastName={user?.lastName}
            className="h-9 w-9 rounded-full"
          />
          <div
            className={clsx(
              "flex flex-1 items-center justify-between border-b-2 border-gray-50 py-3 font-bold text-pureblack transition-colors group-last:border-none lg:text-md",
              !props.isDisabled && "group-hover:border-transparent",
            )}
          >
            <p
              className="[&_span]:text-primary [&_span]:underline"
              dangerouslySetInnerHTML={{
                __html: props.selectProps.inputValue
                  ? props.label.replace(regex, match => `<span>${match}</span>`)
                  : props.label,
              }}
            />

            <div className="flex items-center justify-center gap-2">
              {props.isSelected && (
                <span className="text-sm font-bold text-primary">
                  <FormattedMessage
                    id="checkout.section.split-payment.dropdown.player-x-of-x"
                    values={{
                      // +2 because:
                      // +1 it's an index
                      // +1 we already have the current user
                      playerCount:
                        props
                          .getValue()
                          .map(user => user.id)
                          .indexOf(user.id) + 2,
                      maxPlayerCount: order.maxPayingUsers,
                    }}
                  />
                </span>
              )}

              {!props.isDisabled && (
                <div
                  className={clsx(
                    "flex h-5 w-5 flex-initial items-center justify-center rounded-sm",
                    props.isSelected ? "bg-primary" : "bg-gray-50",
                  )}
                >
                  {props.isSelected && (
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="text-purewhite"
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </components.Option>
    </>
  );
};
