import styled from "styled-components";

import { Teams } from "../modules/checkout/models/Games";

import { TeamAvatar } from "./TeamAvatar";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

interface Props {
  team: Teams;
}

export const TeamAvatarNameWrapper: React.FC<Props> = ({ team }) => {
  const names = team?.teamMembers?.map?.(u => u?.firstName).join(" & ");
  return (
    <Wrapper>
      <TeamAvatar team={team} />
      {names}
    </Wrapper>
  );
};
