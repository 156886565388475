import React, { useEffect, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory, useParams } from "react-router-dom";

import {
  faBrowser,
  faCalendar,
  faClock,
  faEnvelope,
  faFileSignature,
  faLocationDot,
  faPhone,
  faStar,
  faTableTennis,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

import { breakpoints } from "../../appConstants/common";

import type { FacilityWithUtc } from "../../modules/customer/models/Facility";

import { getFacilityAddress } from "../../helpers/facilityHelpers";

import { useToaster } from "../../hooks/common/useToaster";
import { useFacility } from "../../hooks/swr/useFacility";
import { useDateFormat } from "../../hooks/useDateFormat";
import { useQuery } from "../../hooks/useQuery";

import { BlockTabs } from "../../components/BlockTabs";
import { Card } from "../../components/Card";
import { LogoWithFallback } from "../../components/LogoWithFallback";
import { ProgressSpinner } from "../../components/ProgressSpinner";
import { UserOverviewCalendar } from "../../modules/checkout/components/Calendar/UserOverviewCalendar";
import ActivitiesList from "../../modules/game/components/activities/list/ActivitiesList";

import { amenitiesIcons } from "../../utils/amenitiesIconMap";
import { luxonTimeFormat } from "../../utils/dateFormats";
import { hasValues } from "../../utils/helpers";
import { MembershipTab } from "./MembershipTab";

const LoadingContainer = styled.div`
  min-height: 60vh;
  display: grid;
  place-items: center;
`;

const Container = styled.div`
  min-height: 55vh;
  display: flex;
  gap: 1rem;
  flex-direction: column;

  @media (min-width: ${breakpoints.XL}) {
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }
`;

const Header = styled(Card)`
  display: none;

  @media (min-width: ${breakpoints.XL}) {
    position: relative;
    flex-direction: row;
    padding-top: 3rem;
    display: flex;
  }
`;

const MobileHeader = styled(Card)`
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: 2rem;

  @media (min-width: ${breakpoints.XL}) {
    display: none;
  }
`;

const Description = styled.div`
  font-size: 0.9rem;
  width: 100%;
  font-weight: var(--medium);
  line-height: 1.3rem;
  color: var(--gray-400);

  @media (min-width: ${breakpoints.XL}) {
    width: 50%;
    font-size: 1rem;
  }
`;

const Information = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  h4 {
    margin-bottom: 0.5rem;
  }

  @media (min-width: ${breakpoints.XL}) {
    gap: 2rem;
    width: 30%;

    h4 {
      margin-bottom: 1rem;
    }
  }
`;

const InfoLines = styled.div`
  color: var(--gray-400);
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  font-size: 0.9rem;

  > div {
    display: flex;
    gap: 0.5rem;
    font-weight: var(--medium);

    svg {
      color: var(--gray-400);
    }
  }

  @media (min-width: ${breakpoints.XL}) {
    font-size: 1rem;
  }
`;

const MobileLogoInfoContainer = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;

  @media (min-width: ${breakpoints.MOBILE}) {
    flex-direction: row;
  }
`;
const TabContainer = styled.div`
  min-height: 230px;
`;

interface Params {
  id: string;
}

const tabs = {
  ACTIVITIES: 0,
  BOOK: 1,
  AMENITIES: 2,
  MEMBERSHIP: 4,
};

const queryParam: Record<string, number> = {
  activities: tabs.ACTIVITIES,
  book: tabs.BOOK,
  amenities: tabs.AMENITIES,
  membership: tabs.MEMBERSHIP,
};

const venueTabs = [
  {
    id: tabs.ACTIVITIES,
    icon: faTableTennis,
    translationId: "venue.menuitem.activities",
    name: "Aktiviteter",
  },
  {
    id: tabs.BOOK,
    icon: faCalendar,
    translationId: "venue.menuitem.book",
    name: "Boka",
  },
  {
    id: tabs.AMENITIES,
    icon: faStar,
    translationId: "venue.menuitem.amenities",
    name: "Bekvämligheter",
  },
  {
    id: tabs.MEMBERSHIP,
    icon: faFileSignature,
    translationId: "venue.menuitem.membership",
    name: "Medlemskap",
  },
];

export const VenuePage: React.FC = () => {
  const { id: facilityId } = useParams<Params>();
  const history = useHistory();
  const queries = useQuery();
  const { toastError } = useToaster();
  const activeTab = queries.get("tab") || Object.keys(queryParam)[0];
  const { dfInterval } = useDateFormat(facilityId);
  const { facility, isLoading, error } = useFacility(facilityId);

  useEffect(() => {
    error && toastError.fetchFacilitiesFailed();
  }, [error, toastError]);

  const tabComponents = useMemo(
    () => ({
      [tabs.ACTIVITIES]: {
        Component: ActivitiesList,
        props: { facilityId, isSpecific: true },
      },
      [tabs.BOOK]: {
        Component: UserOverviewCalendar,
        props: { facilityId, isSpecific: true },
      },
      [tabs.AMENITIES]: {
        Component: ({
          amenities,
        }: {
          amenities: FacilityWithUtc["amenities"];
        }) => (
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-3 xl:grid-cols-4">
            {amenities?.map(({ id, name, iconName, translationName }) => (
              <div className="flex items-center gap-4 text-primary" key={id}>
                {amenitiesIcons[iconName] && (
                  <FontAwesomeIcon
                    style={{ fontSize: "1.5rem", width: "2rem" }}
                    icon={amenitiesIcons[iconName]}
                  />
                )}
                <h6>
                  <FormattedMessage
                    id={translationName}
                    defaultMessage={name}
                  />
                </h6>
              </div>
            ))}
          </div>
        ),
        props: { amenities: facility?.amenities },
      },
      [tabs.MEMBERSHIP]: {
        Component: MembershipTab,
        props: { facility, isSpecific: true },
      },
    }),
    [facilityId, facility],
  );

  const { Component, props: componentProps } = useMemo<any>(
    () => tabComponents[queryParam[activeTab]] || {},
    [tabComponents, activeTab],
  );

  const InformationContent = () => (
    <Information>
      <div>
        <h4>
          <FormattedMessage id="common.contact-info" />
        </h4>

        <InfoLines>
          {facility?.email && (
            <div>
              <FontAwesomeIcon icon={faEnvelope} />
              <a href={`mailto:${facility.email}`}>{facility.email}</a>
            </div>
          )}
          {facility?.phone && (
            <div>
              <FontAwesomeIcon icon={faPhone} />
              <a href={`tel:${facility.phone}`}>{facility.phone}</a>
            </div>
          )}
          {facility?.address && hasValues(facility?.address) && (
            <div>
              <FontAwesomeIcon icon={faLocationDot} />
              <span>{getFacilityAddress(facility.address)}</span>
            </div>
          )}
          {facility?.website && (
            <div>
              <FontAwesomeIcon icon={faBrowser} />
              <a
                href={
                  facility.website.match(/^https?:\/\//)
                    ? facility.website
                    : `https://${facility.website}`
                }
                target="_blank"
                rel="noreferrer noopener"
              >
                {facility.website}
              </a>
            </div>
          )}
        </InfoLines>
      </div>
      <div>
        <h4>
          <FormattedMessage id="common.opening-hours" />
        </h4>
        <InfoLines>
          {facility?.openHours && facility.openHours?.length > 0 && (
            <div>
              <FontAwesomeIcon icon={faClock} />
              <span>
                {dfInterval(
                  facility.openHours[0].startTime,
                  facility.openHours[0].endTime,
                  luxonTimeFormat,
                )}
              </span>
            </div>
          )}
        </InfoLines>
      </div>
    </Information>
  );

  return (
    <>
      {isLoading ? (
        <Card>
          <LoadingContainer>
            <ProgressSpinner />
          </LoadingContainer>
        </Card>
      ) : (
        <>
          <Container>
            <Header>
              <LogoWithFallback
                src={facility?.logo}
                width="15rem"
                height="15rem"
              />
              {facility?.description && (
                <Description>{facility?.description}</Description>
              )}
              <InformationContent />
            </Header>
            <MobileHeader>
              <MobileLogoInfoContainer>
                <LogoWithFallback
                  src={facility?.logo}
                  width="20vw"
                  height="20vw"
                />
                <InformationContent />
              </MobileLogoInfoContainer>
              {facility?.description && (
                <Description>{facility?.description}</Description>
              )}
            </MobileHeader>
            <Card>
              <BlockTabs
                active={queryParam[activeTab]}
                tabs={venueTabs}
                onClick={id => {
                  const newTab =
                    Object.keys(queryParam)[
                      Object.values(queryParam).indexOf(id)
                    ];

                  queries.set("tab", newTab);
                  history.push({ search: queries?.toString() });
                }}
              />
              <TabContainer>
                {Component && <Component {...componentProps} />}
              </TabContainer>
            </Card>
          </Container>
        </>
      )}
    </>
  );
};
