import React, { useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import {
  faDownload,
  faPaperPlaneTop,
  faSliders,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ToggleButton as PrimeReactToggleButton } from "primereact/togglebutton";
import styled from "styled-components";

import { breakpoints } from "../../../../appConstants/common";

import {
  AdminOverviewUsersFilters,
  Gender,
} from "../../../../modules/player/models/User";

import { useFacilityMemberships } from "../../../../hooks/swr/useFacilityMemberships";

import MultipleSelectInput from "../../../../components/inputs/MultipleSelectInput";
import { SelectInput } from "../../../../components/inputs/SelectInput";
import { AdminPlayerSearch } from "../../../../modules/player/components/AdminPlayerSearch";

interface StyleProps {
  $visible?: boolean;
}

interface Props {
  filters?: AdminOverviewUsersFilters;
  setFilters?: (filters: AdminOverviewUsersFilters) => void;
  count?: number;
  activeCount?: number;
  total?: number;
  disableActions?: boolean;
  facilityId?: string;
  onDownload?: (value?: any) => any;
  onSendMessage?: () => void;
  onSearching?: (isSearching: boolean) => void;
  onSearchInputChange?: (searchInput: string) => void;
  setSelectedVerifyOption?: (arg: verificationFilter) => void;
  isLoading: boolean;
}

export interface verificationFilter {
  label: string;
  value: string;
}

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  border-color: var(--gray-200);
  border-style: solid;
  border-width: 1px 0;
  background: var(--gray-100);
  height: 3.75rem;
  flex-direction: row-reverse;
  padding-right: 1rem;

  @media (min-width: ${breakpoints.LARGE}) {
    flex-direction: row;
    padding-left: 1rem;
    padding-right: 0;
  }
`;

const Settings = styled.div<StyleProps>`
  font-size: 0.9rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  border-bottom: 1px solid var(--gray-200);
  display: ${props => (props.$visible ? "flex" : "none")};
  //overflow: hidden;

  @media (min-width: ${breakpoints.LARGE}) {
    flex-direction: row;
    align-items: center;
  }
`;

const Action = styled.div`
  width: auto;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
`;

const IconText = styled.span`
  display: none;

  @media (min-width: ${breakpoints.LARGE}) {
    display: block;
  }
`;

const Counter = styled.div`
  display: none;

  @media (min-width: ${breakpoints.LARGE}) {
    display: block;
    flex: 1 1 auto;
  }
`;

const SearchContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;

  svg:not(.p-progress-spinner-svg) {
    position: absolute;
    z-index: 101;
    right: 1rem;
    top: 1.25rem;
  }

  svg.p-progress-spinner-svg {
    top: -0.5rem;
  }

  input {
    color: black !important;
  }

  @media (min-width: ${breakpoints.LARGE}) {
    width: 40%;
  }
`;

const ToggleButton = styled(PrimeReactToggleButton)`
  font-size: 0.9rem;
  font-weight: var(--bold);
  font-weight: 600;
  color: black !important;
`;

const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;

  @media (min-width: ${breakpoints.LARGE}) {
    flex-direction: row;
    align-items: center;
    width: auto;
  }
`;

export const AdminPlayersOverviewTableHeader: React.FC<Props> = ({
  filters,
  disableActions,
  setFilters,
  count,
  total,
  activeCount,
  facilityId,
  onDownload,
  onSendMessage,
  onSearching,
  onSearchInputChange,
  setSelectedVerifyOption,
  isLoading,
}) => {
  const intl = useIntl();
  const [showFilters, setShowFilters] = useState(false);
  const hasActiveFilters =
    filters["gender"] !== undefined ||
    filters["hasLastActivity"] !== undefined ||
    filters["membershipList"]?.length > 0;
  const toggleFilters = () => setShowFilters(show => !show);

  const { memberships, isLoading: isMembershipsLoading } =
    useFacilityMemberships(facilityId);

  const verifyFilterOptions = useMemo(() => {
    return [
      {
        label: intl.formatMessage({
          id: "admin.players.overview.showAll",
          defaultMessage: "Visa alla kunder",
        }),
        value: "showAll",
      },
      {
        label: intl.formatMessage({
          id: "admin.players.overview.verified",
          defaultMessage: "Endast verifierade kunder",
        }),
        value: "verified",
      },
      {
        label: intl.formatMessage({
          id: "admin.players.overview.notVerified",
          defaultMessage: "Endast ej verifierade kunder",
        }),
        value: "notVerified",
      },
    ];
  }, [intl]);

  const GenderToggle = ({ type }: { type: Gender }) => {
    const translation = {
      [Gender.Female]: "profile-settings.gender.female",
      [Gender.Male]: "profile-settings.gender.male",
      [Gender.None]: "profile-settings.gender.none",
    };

    const id = translation[type];

    return (
      <ToggleButton
        onLabel={intl.formatMessage({ id })}
        offLabel={intl.formatMessage({ id })}
        checked={filters?.gender?.at(0) === type}
        onChange={({ value }) => {
          if (!value) {
            const newFilters = { ...filters };
            delete newFilters.gender;

            setFilters(newFilters);
          } else {
            setFilters({
              ...filters,
              gender: [type],
            });
          }
        }}
      />
    );
  };

  return (
    <div>
      <Container>
        <Counter>
          <FormattedMessage
            id="common.results.shows-x-of-x"
            defaultMessage="Visar {shows} av {total}"
            values={{ shows: count, total }}
          />
        </Counter>
        {activeCount && (
          <Counter>
            <FormattedMessage
              id="admin.players.activeCount"
              defaultMessage="{verified} verifierade"
              values={{ verified: activeCount }}
            />
          </Counter>
        )}
        <Action
          style={{
            color: hasActiveFilters ? "var(--primary)" : "black",
          }}
          onClick={toggleFilters}
        >
          <IconText>
            <FormattedMessage
              id="common.show-filter"
              defaultMessage="Visa filter"
            />
          </IconText>
          <FontAwesomeIcon icon={faSliders} />
        </Action>
        <Action
          style={
            disableActions
              ? { color: "var(--gray-600)", cursor: "not-allowed" }
              : { color: "black", cursor: "pointer" }
          }
          onClick={disableActions ? null : onSendMessage}
        >
          <IconText>
            <FormattedMessage id="admin.send.customer.email" />
          </IconText>
          <FontAwesomeIcon icon={faPaperPlaneTop} />
        </Action>
        <Action
          style={
            disableActions
              ? { color: "var(--gray-600)", cursor: "not-allowed" }
              : { color: "black", cursor: "pointer" }
          }
          onClick={disableActions ? null : onDownload}
        >
          <IconText>
            <FormattedMessage id="common.download" defaultMessage="Ladda ned" />
          </IconText>
          <FontAwesomeIcon icon={faDownload} />
        </Action>
        <SearchContainer>
          <AdminPlayerSearch
            alwaysActive
            facilityId={facilityId}
            onSearching={onSearching}
            onSearchInputChange={onSearchInputChange}
            isLoading={isLoading}
          />
        </SearchContainer>
      </Container>

      <Settings $visible={showFilters}>
        <FilterContainer>
          <FormattedMessage
            id="common.gender"
            defaultMessage="Könstillhörighet"
          />
          <div style={{ display: "flex", gap: "1rem" }}>
            <GenderToggle type={Gender.Female} />
            <GenderToggle type={Gender.Male} />
          </div>
        </FilterContainer>

        {!isMembershipsLoading && (
          <div className="w-1/4">
            <MultipleSelectInput
              value={filters?.membershipList}
              options={memberships?.map(i => ({
                label: i.name,
                value: i.id,
              }))}
              onChange={e => {
                if (
                  e === null ||
                  filters["membershipList"]?.length === e.value?.length
                ) {
                  return;
                }

                setFilters({
                  ...filters,
                  membershipList: e.value,
                });
              }}
              placeholder={intl.formatMessage({
                id: "admin.player.overview.filter.membership.placeholder",
                defaultMessage: "Välj medlemskap",
              })}
            />
          </div>
        )}
        <div className="w-1/4">
          <SelectInput
            value={
              "hasLastActivity" in filters
                ? filters.hasLastActivity
                  ? "verified"
                  : "notVerified"
                : "showAll"
            }
            options={verifyFilterOptions.map(({ label, value }) => ({
              label: label,
              value: value,
            }))}
            onChange={e => {
              if (e.value === "showAll") {
                delete filters.hasLastActivity;

                setFilters({
                  ...filters,
                });
              } else if (e.value === "verified") {
                setFilters({
                  ...filters,
                  hasLastActivity: true,
                });
              } else if (e.value === "notVerified") {
                setFilters({
                  ...filters,
                  hasLastActivity: false,
                });
              }

              setSelectedVerifyOption?.(
                verifyFilterOptions?.find(i => i?.value === e.value),
              );
            }}
          />
        </div>
      </Settings>
    </div>
  );
};
