import { useIntl } from "react-intl";

import { array, object, string } from "yup";

import { commonRegex } from "../../../helpers/commonRegex";

const { phoneNumber, url, postalCode, organizationsNumber } = commonRegex;

export const useFormValidationSchema = () => {
  const intl = useIntl();
  const getTranslation = (id: string, defaultMessage?: string) =>
    intl.formatMessage({ id, defaultMessage });

  const generalInfoSchema = object().shape({
    name: string().required(),
    phone: string().matches(
      phoneNumber,
      getTranslation(
        "validation.invalid.phone",
        "Ogiltigt telefonnummer. Får enbart innehålla siffror.",
      ),
    ),
    email: string().email().required(),

    website: string().matches(
      url,
      getTranslation(
        "validation.invalid.website",
        "Hemsidan är inte en giltig url. E.g. http(s)://example.se, www.exemple.se, exemple.se",
      ),
    ),
    address: object().shape({
      country: string(),
      city: string().required(),
      street: string().required(),
      postalCode: string()
        .required()
        .matches(
          postalCode,
          getTranslation(
            "validation.invalid.postalCode",
            "Postnummer får enbart innehålla siffror.",
          ),
        ),
    }),
    shortMessage: string()
      .nullable()
      .max(
        150,
        intl.formatMessage(
          { id: "validation.invalid.shortMessage.length" },
          { maxCharacterCount: 150 },
        ),
      ),
  });

  const editBookableEntitiesSchema = object().shape({
    bookableEntities: array().of(
      object().shape({
        name: string().required(),
        bookableType: string().nullable().required(),
      }),
    ),
  });

  const bookableEntityTypesSchema = object().shape({
    bookableEntityTypes: array().of(
      object().shape({
        name: string().required(),
        sportType: object().nullable().required(),
      }),
    ),
  });

  const facilityPaymentInfoSchema = object().shape(
    {
      invoiceEmail: string().email().required(),
      // plusgiro: string().when("bankgiro", {
      //   is: bankgiro => !bankgiro || !bankgiro?.length,
      //   then: string()
      //     .required(
      //       getTranslation(
      //         "validation.required.giro",
      //         "Bankgiro eller Plusgiro är obligatoriskt.",
      //       ),
      //     )
      //     .max(
      //       8,
      //       getTranslation(
      //         "validation.invalid.pg-length",
      //         "Plusgiro får inte vara längre än 8 tecken.",
      //       ),
      //     )
      //     .matches(
      //       giro,
      //       getTranslation(
      //         "validation.invalid.pg",
      //         "Ogiltigt plusgiro. E.g. 1234567-8.",
      //       ),
      //     ),
      // }),
      // bankgiro: string()
      //   .nullable()
      //   .when("plusgiro", {
      //     is: plusgiro => !plusgiro || !plusgiro?.length,
      //     then: string()
      //       .required(
      //         getTranslation(
      //           "validation.required.giro",
      //           "Bankgiro eller Plusgiro är obligatoriskt.",
      //         ),
      //       )
      //       .max(
      //         9,
      //         getTranslation(
      //           "validation.invalid.bg-length",
      //           "Bankgiro får inte vara längre än 9 tecken.",
      //         ),
      //       )
      //       .matches(
      //         giro,
      //         getTranslation(
      //           "validation.invalid.bg",
      //           "Ogiltigt bankgiro. E.g. 123-4567.",
      //         ),
      //       ),
      //   }),
      registrationNumber: string()
        .required()
        .matches(
          organizationsNumber,
          getTranslation(
            "validation.invalid.org-number",
            "Organisationsnummer ogiltigt. E.g. 987654-1234.",
          ),
        ),
    },
    [["bankgiro", "plusgiro"]],
  );

  return {
    generalInfoSchema,
    editBookableEntitiesSchema,
    bookableEntityTypesSchema,
    facilityPaymentInfoSchema,
  };
};
