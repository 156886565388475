import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";

import { faMagnifyingGlass, faXmark } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AnimatePresence, motion } from "framer-motion";
import { ProgressSpinner } from "primereact/progressspinner";
import { useRecoilState } from "recoil";
import { useDebounce, useOnClickOutside } from "usehooks-ts";

import { User } from "../../../modules/player/models/User";

import { queryUsers } from "../../../modules/player/services/UserService";

import { isHeaderSearchOpenState } from "../Header";
import { SearchResult } from "./SearchResult";

export const Search = () => {
  const [isHeaderSearchOpen, setIsHeaderSearchOpen] = useRecoilState(
    isHeaderSearchOpenState,
  );
  const searchInputRef = useRef<HTMLInputElement>(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [searchResult, setSearchResult] = useState<User[]>([]);
  const abortController = useRef<AbortController | null>(null);
  const searchWrapperRef = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const intl = useIntl();

  const toggleSearch = useCallback(() => {
    setIsHeaderSearchOpen(v => !v);
  }, [setIsHeaderSearchOpen]);

  useOnClickOutside(searchWrapperRef, toggleSearch);

  // Focus search input when opening search
  useLayoutEffect(() => {
    if (isHeaderSearchOpen) {
      searchInputRef.current?.select();
    }
  }, [isHeaderSearchOpen]);

  const onGlobalSearchKeybind = useCallback(
    (e: KeyboardEvent) => {
      if (e.key !== "/") {
        return;
      }

      if (e.target instanceof HTMLInputElement) {
        return;
      }

      toggleSearch();
    },
    [toggleSearch],
  );

  useEffect(() => {
    document.addEventListener("keyup", onGlobalSearchKeybind);

    return () => {
      document.removeEventListener("keyup", onGlobalSearchKeybind);
    };
  }, [onGlobalSearchKeybind]);

  // Close search when navigating
  useEffect(() => {
    setIsHeaderSearchOpen(false);
  }, [location, setIsHeaderSearchOpen]);

  const debouncedSearchTerm = useDebounce(searchTerm, 300);

  useEffect(() => {
    if (!debouncedSearchTerm) {
      setIsLoading(false);
      return;
    }

    if (abortController.current) {
      abortController.current.abort();
    }

    abortController.current = new AbortController();

    setIsLoading(true);
    setSearchResult([]);

    queryUsers(debouncedSearchTerm, null, true, abortController.current.signal)
      .then(response => {
        setSearchResult(response.data);
        setIsLoading(false);
      })
      .catch(e => {
        console.error(e);
      });
  }, [debouncedSearchTerm]);

  return (
    <AnimatePresence>
      {isHeaderSearchOpen && (
        <motion.div
          ref={searchWrapperRef}
          className="absolute -right-[46px] top-1/2 z-10 flex -translate-y-1/2 items-center"
          initial={{ width: "74px" }} // 74px = min-content
          animate={{ width: "100%" }}
          transition={{ duration: 0.2, delay: 0.1 }}
        >
          <FontAwesomeIcon
            icon={faMagnifyingGlass}
            className="pointer-events-none absolute left-4 text-pureblack"
          />

          <input
            ref={searchInputRef}
            onChange={e => setSearchTerm(e.target.value)}
            value={searchTerm}
            type="text"
            onClick={e => e.stopPropagation()}
            onKeyUp={e => e.key === "Escape" && toggleSearch()}
            placeholder={intl.formatMessage({ id: "search.profile" })}
            className="h-10 w-full rounded border border-gray-50 bg-white pl-10 pr-8 text-base text-gray-700 focus:border-purewhite focus:bg-purewhite focus:text-inherit focus:outline focus:outline-blue-500"
          />

          {isLoading ? (
            <ProgressSpinner className="absolute right-[6px] h-5 w-5" />
          ) : (
            <button
              onClick={toggleSearch}
              className="absolute right-2 cursor-pointer p-1 text-pureblack transition-colors hover:text-primary focus-visible:text-primary"
              type="button"
            >
              <FontAwesomeIcon icon={faXmark} className="" />
            </button>
          )}

          {searchResult.length > 0 && (
            <SearchResult searchResult={searchResult} />
          )}
        </motion.div>
      )}
    </AnimatePresence>
  );
};
