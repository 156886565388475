import useSWR, { SWRConfiguration } from "swr";

import { FacilityWithUtc } from "../../modules/customer/models/Facility";

import { getFacility } from "../../modules/customer/services/FacilityService";

export const useFacility = (
  facilityId?: FacilityWithUtc["id"] | null,
  options?: SWRConfiguration,
) => {
  const { data, ...rest } = useSWR(
    facilityId ? ["facility", facilityId] : null,
    ([, facilityId]) => getFacility(facilityId).then(data => data.data),
    options,
  );

  return {
    ...rest,
    facility: data,
  };
};
