import React, { useEffect, useState } from "react";

import { InputSwitch } from "primereact/inputswitch";

import { BuyableType } from "../../../../checkout/models/Payment";
import { IActivity } from "../../../models/Activity";

import { updateGeneralActivitesRegistrationStatus } from "../../../services/GeneralActivities";
import { updateRegistrationStatus } from "../../../services/Serie";

interface IProps {
  serie: IActivity;
  onChange: (id: IActivity["id"], openForRegistration: boolean) => void;
}

const AdminSeriesTableRegistrationColumn: React.FC<IProps> = ({
  serie,
  onChange,
}) => {
  const [checked, setChecked] = useState<boolean>(serie.openForRegistration);

  useEffect(() => {
    setChecked(serie.openForRegistration);
  }, [serie.openForRegistration]);

  const onToggle = async () => {
    setChecked(prevState => !prevState);
    try {
      if (serie.buyableType == BuyableType.Series)
        await updateRegistrationStatus(serie.id, !checked);
      if (serie.buyableType == BuyableType.Event)
        await updateGeneralActivitesRegistrationStatus(serie.id, !checked);
    } catch {
      setChecked(prevState => !prevState);
    }

    onChange(serie.id, !checked);
  };

  return (
    <div className="p-d-flex p-jc-center p-ai-center">
      <InputSwitch
        onChange={onToggle}
        name={`open-for-registration-${serie.id}`}
        checked={checked}
      />
    </div>
  );
};

export default AdminSeriesTableRegistrationColumn;
