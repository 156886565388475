import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";

const reactPlugin = new ReactPlugin();

const applicationInsights = new ApplicationInsights({
  config: {
    connectionString:
      process.env.REACT_APP_APPLICATION_INSIGHTS_CONNECTION_STRING,
    extensions: [reactPlugin],
    enableAutoRouteTracking: true,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,

    // Disables application insights on localhost
    disableTelemetry: process.env.NODE_ENV === "development",
  },
});

applicationInsights.addDependencyInitializer(details => {
  // Disable reporting the maintenance message 404 as an error in application insights
  if (
    process.env.REACT_APP_MAINTENANCE_MESSAGE_JSON_URL &&
    details.item.target ===
      process.env.REACT_APP_MAINTENANCE_MESSAGE_JSON_URL &&
    details.item.responseCode === 404
  ) {
    return false;
  }

  return true;
});

applicationInsights.loadAppInsights();

export { applicationInsights, reactPlugin };
