import type { FacilityWithUtc } from "../modules/customer/models/Facility";

import { get } from "./apiClient";

const API_VERSION = "v1";

const getEndpointBase = (facilityId: FacilityWithUtc["id"]) =>
  `/${API_VERSION}/facilities/${facilityId}/dashboard`;

export const getFacilityDashboard = async (facilityId: FacilityWithUtc["id"]) =>
  get<{ url: string }>({
    endpoint: getEndpointBase(facilityId),
  });
