import { useMemo } from "react";

import { IScheduleResponse } from "../../models/Calendar";

import { isSlotBooked } from "../../components/Calendar/utils";

export const useBookedSlots = (
  schedules: IScheduleResponse[],
  selectedBookingId?: string,
): Map<string, number> =>
  useMemo(() => {
    const bookedSlotIndexes = new Map<string, number>();

    schedules.forEach((court, index) => {
      let count = 0;
      let seekSubIndex = -1;
      let bookingId: string | null = null;

      court.slots.forEach((slot, subIndex) => {
        if (isSlotBooked(slot, selectedBookingId)) {
          if (slot.bookingId !== bookingId) {
            count = 0;
            bookingId = slot.bookingId;
            seekSubIndex = subIndex;
            bookedSlotIndexes.set(`${index}-${subIndex}`, ++count);
          } else {
            if (bookedSlotIndexes.has(`${index}-${seekSubIndex}`)) {
              bookedSlotIndexes.set(`${index}-${seekSubIndex}`, ++count);
            }
          }
        } else {
          bookingId = null;
          seekSubIndex = -1;
          count = 0;
        }
      });
    });

    return bookedSlotIndexes;
  }, [schedules, selectedBookingId]);
