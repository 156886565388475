import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";

import {
  Membership,
  RenewalType,
} from "../../../../modules/player/models/Membership";

import { useFacilityMemberships } from "../../../../hooks/swr/useFacilityMemberships";
import { useCurrencyFormat } from "../../../../hooks/useCurrencyFormat";
import { useQuery } from "../../../../hooks/useQuery";

import { AppLoadingSpinner } from "../../../../components/AppLoadingSpinner";
import { DataTable } from "../../../../components/DataTable/DataTable";
import { MembershipList } from "./components/MembershipList";

import { useSelectedFacilityId } from "../../../../recoil/selectedFacilityIdState";
import { Groups } from "./Groups/Groups";

export const FacilityGroupsManagementSettings = () => {
  const history = useHistory();
  const query = useQuery();

  const membershipId = query.get("membershipId");

  const selectedFacilityId = useSelectedFacilityId();

  const { memberships, isLoading: isLoadingMemberships } =
    useFacilityMemberships(selectedFacilityId);

  const { cf } = useCurrencyFormat(
    memberships?.[0]?.membershipPrices?.[0]?.price?.currencyCode ?? "",
  );

  if (membershipId) {
    return <MembershipList membershipId={membershipId} />;
  }

  return (
    <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
      <div>
        <h3 className="mb-5 text-primary">
          <FormattedMessage id="admin.facility.settings.groups.title" />
        </h3>
        {selectedFacilityId && <Groups facilityId={selectedFacilityId} />}
      </div>

      <div>
        {isLoadingMemberships && (
          <div className="flex justify-center">
            <AppLoadingSpinner hasContainer={false} />
          </div>
        )}

        {!isLoadingMemberships && (
          <>
            <h3 className="mb-5 text-primary">
              <FormattedMessage id="facility-settings.memerships.title" />
            </h3>
            <DataTable
              data={memberships ?? []}
              selectionMode="single"
              onRowClick={e =>
                e.originalEvent.metaKey
                  ? window.open(`?membershipId=${e?.data?.id}`)
                  : history.push(`?membershipId=${e?.data?.id}`)
              }
              columns={[
                {
                  id: "common.name",
                  body: (membership: Membership) => (
                    <div className="flex items-center space-x-2">
                      <div
                        className="h-[15px] w-[15px] rounded-full"
                        style={{ background: membership?.color }}
                      ></div>
                      <div>{membership?.name}</div>
                    </div>
                  ),
                },
                {
                  id: "common.monthly-price",
                  body: (membership: Membership) => {
                    const price = membership?.membershipPrices?.find(
                      p => p?.renewal?.renewalType === RenewalType.Monthly,
                    )?.price;

                    if (!price) return;

                    return cf(price.valueInclTax);
                  },
                },
                {
                  id: "common.yearly-price",
                  body: (membership: Membership) => {
                    const price = membership?.membershipPrices?.find(
                      p => p?.renewal?.renewalType === RenewalType.Annually,
                    )?.price;

                    if (!price) return;

                    return cf(price.valueInclTax);
                  },
                },
                {
                  id: "common.subscriptions",
                  body: (membership: Membership) => (
                    <FormattedMessage
                      id="membership.userCount"
                      defaultMessage="{userCount} st"
                      values={{
                        userCount: membership?.userCount,
                      }}
                    />
                  ),
                },
              ]}
            />
          </>
        )}
      </div>
    </div>
  );
};
