import React from "react";

import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  className?: string;
  label: string;
  translationName?: string;
  icon: IconProp;
  value: string;
}

export const IconInfoBox: React.FC<Props> = ({
  className,
  label,
  icon,
  value,
}) => {
  return (
    <div className={`flex items-center ${className}`}>
      {icon && (
        <div className="mr-4 flex text-3xl text-primary">
          <FontAwesomeIcon icon={icon} />
        </div>
      )}
      <div className="flex flex-col">
        <h5>{label}</h5>
        <div>{value}</div>
      </div>
    </div>
  );
};
