import type { DateOnly } from "./DateOnly";

export interface PendingSwedbankPayBackupPaymentMethod {
  scriptUrl: string;
  paymentOrderId: string;
}

export interface BackupPaymentMethod {
  maskedPan: string;
  cardBrand: keyof typeof CardBrand;
  expiryDate: DateOnly;
  paymentProvider: string;
}

export interface BackupPaymentMethodDTO
  extends Omit<BackupPaymentMethod, "expiryDate"> {
  expiryDate: string;
}

export const CardBrand = {
  Other: "Other",
  Visa: "Visa",
  MasterCard: "MasterCard",
  Maestro: "Maestro",
  AmericanExpress: "AmericanExpress",
} as const;
