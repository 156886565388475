import { Link } from "react-router-dom";

import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  icon: IconDefinition;
  title: string;
}

export const AdminPlayerGroupSectionCard = ({
  icon,
  title,
  children,
}: React.PropsWithChildren<Props>) => {
  return (
    <div className="flex flex-col">
      <div className="flex space-x-2 bg-gray-50 p-4">
        <FontAwesomeIcon icon={icon} size="lg" className="text-primary" />
        <Link to="/admin/settings/3" className="font-bold text-black">
          {title}
        </Link>
      </div>

      <div className="overflow-auto bg-white p-4">{children}</div>
    </div>
  );
};
