import React, { useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import {
  faCalendarMinus,
  faCalendarPlus,
  faClock,
  faExclamationCircle,
  faHandshake,
  faQuestionCircle,
  faSadTear,
  faStopwatch,
  faTableTennis,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormik } from "formik";
import { DateTime } from "luxon";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { InputSwitch } from "primereact/inputswitch";
import { Tooltip } from "primereact/tooltip";
import styled from "styled-components";

import { DateOnly } from "../../../../../../../models/DateOnly";
import { CreateGamePayload } from "../../../../../../checkout/models/Games";
import { SeriesDivision } from "../../../../../models/Series";

import { useToaster } from "../../../../../../../hooks/common/useToaster";
import { useForceDateTimeToSelectedFacilityTimeZone } from "../../../../../../../hooks/datetime/useForceDateTimeToSelectedFacilityTimeZone";
import { useFacility } from "../../../../../../../hooks/swr/useFacility";
import { useIsAdmin } from "../../../../../../../hooks/swr/useIsAdmin";
import { useSeries } from "../../../../../../../hooks/swr/useSeries";
import { useFormValidationSchema } from "../../../../../hooks/useFormValidationSchema";

import { createGames } from "../../../../../services/Serie";

import { Button } from "../../../../../../../components/Button";
import { CalendarDateTimeWrapper } from "../../../../../../../components/CalendarDateTimeWrapper";
import { GameLengthPicker } from "../../../../../../../components/GameLengthPicker";
import { NumberInput } from "../../../../../../../components/NumberInput";
import { ProgressSpinner } from "../../../../../../../components/ProgressSpinner";
import { WeekdayPicker } from "../../../../../../../components/WeekdayPicker";

const FormItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
`;

const FormLabel = styled.label`
  font-size: var(--h4);
  display: block;
`;

interface Props {
  division: SeriesDivision;
  serieId: string;
  facilityId: string;
  onClose?: (e?: any) => any;
  onSubmit?: () => void;
}

const lastStartTime = DateTime.now().set({ hour: 20 }).startOf("hour");
const firstStartTime = DateTime.now().set({ hour: 16 }).startOf("hour");

//Team meetings only limited to 5 at the moment
const numberOfGamesAgainstSameTeam = [
  { id: 1, value: "1" },
  { id: 2, value: "2" },
  { id: 3, value: "3" },
  { id: 4, value: "4" },
  { id: 5, value: "5" },
];

export const CreateGamesForm: React.FC<Props> = ({
  division,
  serieId,
  facilityId,
  onClose,
  onSubmit,
}) => {
  const { toastError, toastSuccess } = useToaster();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { createGameSchema } = useFormValidationSchema();
  const { series } = useSeries(serieId);
  const isAdmin = useIsAdmin();
  const intl = useIntl();

  const { facility, error } = useFacility(facilityId);

  const courts = useMemo(() => {
    const types = facility?.bookableEntityTypes
      ?.filter(
        t =>
          ["Padel", "Pickleball"].includes(t?.sportType?.name) &&
          t?.defaultNumberOfPlayers === 4,
      )
      ?.map(t => t?.id);

    return facility?.bookableEntities?.filter(c =>
      types.includes(c?.bookableType),
    );
  }, [facility]);

  const { forceDateTimeToSelectedFacilityTimeZone } =
    useForceDateTimeToSelectedFacilityTimeZone();

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: createGameSchema,
    initialValues: {
      serieId,
      facilityId,
      divisionId: division?.id,
      weekDays: [],
      courtsToBePlayed: [],
      firstStartDate: series?.startTime,
      lastStartDate: series?.endTime,
      firstStartTime,
      lastStartTime,
      lengthOfGame: 90,
      numberOfGamesAgainstSameTeam: 1,
      rescheduleRules: division?.rescheduleRules,
    },

    onSubmit: async values => {
      setIsLoading(true);

      const data: CreateGamePayload = {
        ...values,
        firstStartDate: DateOnly.fromDateTime(values.firstStartDate),
        lastStartDate: DateOnly.fromDateTime(values.lastStartDate),
        firstStartTime: forceDateTimeToSelectedFacilityTimeZone(
          values.firstStartTime,
        ),
        lastStartTime: forceDateTimeToSelectedFacilityTimeZone(
          values.lastStartTime,
        ),
      };

      try {
        const result = await createGames(data);
        if (result) {
          onClose?.();
          toastSuccess.gamesForSerieCreated();
          onSubmit?.();
        }
      } catch (e) {
        toastError.createGamesFailed();
      } finally {
        setIsLoading(false);
      }
    },
  });

  const handleOnSelectingCourt = e => {
    const _selectedCourts = [...formik.values.courtsToBePlayed];

    if (e.checked) {
      _selectedCourts.push(e.value);
    } else {
      for (let i = 0; i < _selectedCourts.length; i++) {
        const selectedCourts = _selectedCourts[i];

        if (selectedCourts === e.value) {
          _selectedCourts.splice(i, 1);
          break;
        }
      }
    }
    formik.setFieldValue("courtsToBePlayed", _selectedCourts, true);
  };

  return isLoading ? (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <ProgressSpinner />
      <br />
      <FormattedMessage id="series.create-game.generating" />
    </div>
  ) : (
    <form onSubmit={formik.handleSubmit}>
      <h3>
        <FormattedMessage id="series.create-game.information-needed" />
      </h3>

      <div className="mb-8 mt-4 max-w-prose rounded bg-gray-50 p-4">
        <FontAwesomeIcon
          icon={faExclamationCircle}
          style={{ color: "var(--danger)", marginRight: ".3rem" }}
        />
        <FormattedMessage id="series.create-game.reminder.no-change" />
      </div>

      {error ? (
        <FormLabel>
          <FontAwesomeIcon icon={faSadTear} style={{ marginRight: ".3rem" }} />
          <FormattedMessage id="toast.error.fetch.venue" />
        </FormLabel>
      ) : (
        <>
          <div className="flex flex-col gap-8">
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "1rem",
              }}
            >
              <FormItem>
                <FormLabel>
                  <FontAwesomeIcon
                    icon={faStopwatch}
                    className="text-primary"
                    style={{ marginRight: ".5rem" }}
                  />

                  <FormattedMessage id="series.create-game.game-length" />
                </FormLabel>
                <GameLengthPicker
                  preSelected={formik?.values?.lengthOfGame}
                  onSelectionChange={(length: number) =>
                    formik.setFieldValue("lengthOfGame", length, true)
                  }
                />
              </FormItem>
              <FormItem>
                <FormLabel>
                  <FontAwesomeIcon
                    icon={faHandshake}
                    className="text-primary"
                    style={{ marginRight: ".5rem" }}
                  />
                  <FormattedMessage id="series.create-game.number-of-encounters" />
                </FormLabel>
                <Dropdown
                  id="numberOfGamesAgainstSameTeam"
                  name="numberOfGamesAgainstSameTeam"
                  optionValue="id"
                  optionLabel="value"
                  options={numberOfGamesAgainstSameTeam}
                  value={formik.values.numberOfGamesAgainstSameTeam}
                  onChange={formik.handleChange}
                  style={{ maxWidth: "5rem" }}
                />
              </FormItem>
            </div>
            <FormItem>
              <div>
                <FormLabel>
                  <FontAwesomeIcon
                    icon={faTableTennis}
                    className="text-primary"
                    style={{ marginRight: ".5rem" }}
                  />
                  <FormattedMessage id="series.create-game.courts" />
                </FormLabel>
                <small
                  style={{ display: "block", fontWeight: 500, width: "60ch" }}
                >
                  <FormattedMessage id="series.create-game.courts.description" />
                </small>
              </div>

              <div style={{ display: "grid", gap: ".5rem" }}>
                {courts?.map((court, key) => (
                  <div className="checkbox" key={key}>
                    <Checkbox
                      id="courtsToBePlayed"
                      name="courtsToBePlayed"
                      inputId={court.id}
                      value={court.id}
                      checked={formik.values.courtsToBePlayed.includes(
                        court.id,
                      )}
                      onChange={handleOnSelectingCourt}
                      onBlur={formik.handleBlur}
                    />
                    <label className="ml-2 cursor-pointer" htmlFor={court.id}>
                      {court.name}
                    </label>
                  </div>
                ))}
                {formik.errors.courtsToBePlayed && (
                  <small className="p-error">
                    <>{formik.errors.courtsToBePlayed}</>
                  </small>
                )}
              </div>
            </FormItem>

            <div className="grid gap-8 sm:grid-cols-2">
              <FormItem>
                <div>
                  <FormLabel>
                    <FontAwesomeIcon
                      icon={faClock}
                      className="text-primary"
                      style={{ marginRight: ".5rem" }}
                    />

                    <FormattedMessage id="series.create-game.start-time" />
                  </FormLabel>

                  <small
                    style={{ display: "block", fontWeight: 500, width: "45ch" }}
                  >
                    <FormattedMessage id="series.create-game.start-time.description" />
                  </small>
                </div>
                <CalendarDateTimeWrapper
                  selectOtherMonths
                  timeOnly
                  id="firstStartTime"
                  name="firstStartTime"
                  value={formik.values.firstStartTime}
                  stepMinute={30}
                  onChange={formik.handleChange}
                  style={{ width: "10rem" }}
                  readOnlyInput
                  showIcon
                />
              </FormItem>
              <FormItem>
                <div>
                  <FormLabel>
                    <FontAwesomeIcon
                      icon={faClock}
                      className="text-primary"
                      style={{ marginRight: ".5rem" }}
                    />

                    <FormattedMessage id="series.create-game.end-time" />
                  </FormLabel>

                  <small
                    style={{ display: "block", fontWeight: 500, width: "45ch" }}
                  >
                    <FormattedMessage id="series.create-game.end-time.description" />
                  </small>
                </div>
                <CalendarDateTimeWrapper
                  selectOtherMonths
                  timeOnly
                  id="lastStartTime"
                  name="lastStartTime"
                  value={formik.values.lastStartTime}
                  stepMinute={30}
                  onChange={formik.handleChange}
                  style={{ width: "10rem" }}
                  readOnlyInput
                  showIcon
                />
              </FormItem>
              {formik.values.firstStartTime.plus({ hour: 1 }) >
                formik.values.lastStartTime && (
                <small className="p-error">
                  <FormattedMessage id="series.create-game.validation.end-after-start" />
                </small>
              )}
            </div>

            <FormItem>
              <div>
                <FormLabel>
                  <FontAwesomeIcon
                    icon={faCalendarPlus}
                    className="text-primary"
                    style={{ marginRight: ".5rem" }}
                  />

                  <FormattedMessage id="series.create-game.first-game-starts" />
                </FormLabel>

                <small className="block w-[60ch]">
                  <FormattedMessage id="series.create-game.first-game-starts.description" />
                </small>
              </div>
              <CalendarDateTimeWrapper
                selectOtherMonths
                id="firstStartDate"
                name="firstStartDate"
                value={formik.values.firstStartDate}
                minDate={series?.startTime}
                maxDate={series?.endTime}
                onChange={formik.handleChange}
                readOnlyInput
                showIcon
              />
            </FormItem>
            <FormItem>
              <div>
                <FormLabel>
                  <FontAwesomeIcon
                    icon={faCalendarMinus}
                    className="text-primary"
                    style={{ marginRight: ".5rem" }}
                  />
                  <FormattedMessage id="series.create-game.last-game-starts" />
                </FormLabel>

                <small className="block w-[60ch]">
                  <FormattedMessage id="series.create-game.last-game-starts.description" />
                </small>
              </div>
              <CalendarDateTimeWrapper
                selectOtherMonths
                id="lastStartDate"
                name="lastStartDate"
                value={formik.values.lastStartDate}
                minDate={series?.startTime}
                maxDate={series?.endTime}
                onChange={formik.handleChange}
                readOnlyInput
                showIcon
              />

              {formik.values.firstStartDate > formik.values.lastStartDate && (
                <small className="p-error">
                  <FormattedMessage id="series.create-game.validation.start-before-end" />
                </small>
              )}
            </FormItem>

            <FormItem>
              <div>
                <FormLabel>
                  <FontAwesomeIcon
                    icon={faStopwatch}
                    className="mr-2 text-primary"
                  />
                  <FormattedMessage id="series.create-game.game-days" />
                </FormLabel>

                <small className="block w-[60ch]">
                  <FormattedMessage id="series.create-game.game-days.description" />
                </small>
              </div>
              <WeekdayPicker
                preSelected={formik?.values?.weekDays}
                onSelectionChange={(days: number[]) =>
                  formik.setFieldValue("weekDays", days, true)
                }
              />
            </FormItem>

            {isAdmin && (
              <div className="mt-4 flex flex-col gap-6">
                <div>
                  <FormLabel>
                    <FormattedMessage id="series.steps.guide.title.reschedule-rules.title" />
                  </FormLabel>
                  <small>
                    <FormattedMessage id="series.steps.guide.title.reschedule-rules.description" />
                  </small>
                </div>

                <div className="grid grid-cols-2 gap-x-12 gap-y-8">
                  <fieldset>
                    <label htmlFor="limitToSelectedCourts">
                      <FormattedMessage id="series.creating-series.limitToSelectedCourts" />
                    </label>

                    <FontAwesomeIcon
                      icon={faQuestionCircle}
                      id="limitToSelectedCourts-tooltip"
                      className="ml-2 text-base"
                    />
                    <Tooltip
                      className="max-w-prose"
                      target="#limitToSelectedCourts-tooltip"
                      content={intl.formatMessage({
                        id: "series.creating-series.limitToSelectedCourts.tooltip",
                      })}
                    />

                    <div className="mt-4 flex items-center justify-start gap-4">
                      <FormattedMessage id="common.no" />
                      <InputSwitch
                        inputId="limitToSelectedCourts"
                        onChange={changeParams => {
                          formik.setFieldValue(
                            "rescheduleRules.limitToSelectedCourts",
                            changeParams.value,
                          );
                        }}
                        name="rescheduleRules.limitToSelectedCourts"
                        checked={
                          formik.values.rescheduleRules.limitToSelectedCourts
                        }
                      />
                      <FormattedMessage id="common.yes" />
                    </div>
                  </fieldset>

                  <fieldset>
                    <label htmlFor="limitToSelectedTime">
                      <FormattedMessage id="series.creating-series.limitToSelectedTime" />
                    </label>

                    <FontAwesomeIcon
                      icon={faQuestionCircle}
                      id="limitToSelectedTime-tooltip"
                      className="ml-2 text-base"
                    />
                    <Tooltip
                      className="max-w-prose"
                      target="#limitToSelectedTime-tooltip"
                      content={intl.formatMessage({
                        id: "series.creating-series.limitToSelectedTime.tooltip",
                      })}
                    />

                    <div className="mt-4 flex items-center justify-start gap-4">
                      <FormattedMessage id="common.no" />
                      <InputSwitch
                        inputId="limitToSelectedTime"
                        onChange={changeParams => {
                          formik.setFieldValue(
                            "rescheduleRules.limitToSelectedTime",
                            changeParams.value,
                          );
                        }}
                        name="rescheduleRules.limitToSelectedTime"
                        checked={
                          formik.values.rescheduleRules.limitToSelectedTime
                        }
                      />
                      <FormattedMessage id="common.yes" />
                    </div>
                  </fieldset>

                  <fieldset>
                    <label htmlFor="blockPrimetime">
                      <FormattedMessage id="series.creating-series.blockPrimetime" />
                    </label>

                    <FontAwesomeIcon
                      icon={faQuestionCircle}
                      id="blockPrimetime-tooltip"
                      className="ml-2 text-base"
                    />
                    <Tooltip
                      className="max-w-prose"
                      target="#blockPrimetime-tooltip"
                      content={intl.formatMessage({
                        id: "series.creating-series.blockPrimetime.tooltip",
                      })}
                    />

                    <div className="mt-4 flex items-center gap-4">
                      <FormattedMessage id="common.no" />
                      <InputSwitch
                        inputId="blockPrimetime"
                        onChange={changeParams => {
                          formik.setFieldValue(
                            "rescheduleRules.limitToLesserPrioPriceCategories",
                            changeParams.value,
                          );
                        }}
                        name="rescheduleRules.limitToLesserPrioPriceCategories"
                        checked={
                          formik.values.rescheduleRules
                            .limitToLesserPrioPriceCategories
                        }
                      />
                      <FormattedMessage id="common.yes" />
                    </div>
                  </fieldset>

                  <fieldset>
                    <label htmlFor="limitToSelectedWeekDays">
                      <FormattedMessage id="series.creating-series.limitToSelectedWeekDays" />
                    </label>

                    <FontAwesomeIcon
                      icon={faQuestionCircle}
                      id="limitToSelectedWeekDays-tooltip"
                      className="ml-2 text-base"
                    />
                    <Tooltip
                      className="max-w-prose"
                      target="#limitToSelectedWeekDays-tooltip"
                      content={intl.formatMessage({
                        id: "series.creating-series.limitToSelectedWeekDays.tooltip",
                      })}
                    />

                    <div className="mt-4 flex items-center gap-4">
                      <FormattedMessage id="common.no" />
                      <InputSwitch
                        inputId="limitToSelectedWeekDays"
                        onChange={changeParams => {
                          formik.setFieldValue(
                            "rescheduleRules.limitToSelectedWeekDays",
                            changeParams.value,
                          );
                        }}
                        name="rescheduleRules.limitToSelectedWeekDays"
                        checked={
                          formik.values.rescheduleRules.limitToSelectedWeekDays
                        }
                      />
                      <FormattedMessage id="common.yes" />
                    </div>
                  </fieldset>

                  <fieldset>
                    <label htmlFor="maxAllowedBookingReschedules">
                      <FormattedMessage id="series.reschedule-rules.maxAllowedBookingReschedules" />
                    </label>
                    <NumberInput
                      name="rescheduleRules.maxAllowedBookingReschedules"
                      defaultValue={
                        formik.values.rescheduleRules
                          .maxAllowedBookingReschedules
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.errors.rescheduleRules
                          ?.maxAllowedBookingReschedules
                      }
                    />
                  </fieldset>
                </div>
              </div>
            )}
          </div>

          <div className="mt-8 flex justify-end">
            <Button
              buttonType="submit"
              type="primary"
              text="Spara"
              translationName="button.save"
              disabled={!formik.isValid}
            />
          </div>
        </>
      )}
    </form>
  );
};
