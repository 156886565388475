import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";

import {
  faCalendar,
  faChevronRight,
  faClock,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import defaultCardImage from "../../../assets/images/backgrounds/background3.jpg";

import { BuyableType } from "../../../modules/checkout/models/Payment";
import { IActivity } from "../../../modules/game/models/Activity";

import {
  adminGetActivitesEventPath,
  adminGetActivitesSerieMatchesPath,
} from "../../../helpers/pathHelpers";

import { usePaginatedFacilityActivities } from "../../../hooks/swr/usePaginatedFacilityActivities";
import { useSelectedFacility } from "../../../hooks/swr/useSelectedFacility";
import { useDateFormat } from "../../../hooks/useDateFormat";

import { AppLoadingSpinner } from "../../../components/AppLoadingSpinner";
import { BuyableTypeLabel } from "./components/BuyableTypeLabel";

import { luxonDateFormat, luxonTimeFormat } from "../../../utils/dateFormats";

export const AdminMobileActivitiesPage: React.FC = () => {
  const history = useHistory();
  const { selectedFacility } = useSelectedFacility();
  const { df, dfInterval } = useDateFormat(selectedFacility?.id);

  const { activities, isLoading, isLoadingMore, nextCursor, loadMore } =
    usePaginatedFacilityActivities(selectedFacility?.id, true);

  const onEventClick = (event: IActivity) => {
    switch (event.buyableType) {
      case BuyableType.Event:
        history.push(adminGetActivitesEventPath(event.id));
        break;
      case BuyableType.Series:
        history.push(adminGetActivitesSerieMatchesPath(event.id));
        break;
    }
  };

  return (
    <div className="space-y-2 p-2">
      {activities?.map(activity => (
        <div key={activity.id}>
          <BuyableTypeLabel buyableType={activity.buyableType} />
          <div className="rounded-b-md border-b border-l border-r border-gray-50 bg-purewhite p-2 px-4">
            <div
              onClick={() => onEventClick(activity)}
              className="flex items-center justify-between"
            >
              <div className="flex items-center space-x-4">
                <div
                  className="h-12 w-12 rounded-lg bg-cover"
                  style={{
                    background: `url(${activity.imageUrl || defaultCardImage})`,
                  }}
                ></div>
                <div className="flex items-center space-x-2">
                  <div>
                    <p className="text-gray-900">
                      <FontAwesomeIcon icon={faCalendar} />{" "}
                      {df(activity.startTime, luxonDateFormat)}
                    </p>
                    {BuyableType.Event == activity.buyableType && (
                      <p>
                        <FontAwesomeIcon icon={faClock} />{" "}
                        {dfInterval(
                          activity.startTime,
                          activity.endTime,
                          luxonTimeFormat,
                        )}
                      </p>
                    )}
                    {BuyableType.Series == activity.buyableType && (
                      <p>
                        <FormattedMessage id="series.games.schedule" />
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <FontAwesomeIcon
                icon={faChevronRight}
                className="text-gray-700"
              />
            </div>
            <p className="mt-2 font-bold">{activity.name}</p>
          </div>
        </div>
      ))}

      {(isLoading || isLoadingMore) && (
        <div className="flex justify-center">
          <AppLoadingSpinner hasContainer={false} />
        </div>
      )}

      {nextCursor && !isLoadingMore && (
        <div>
          <button
            className="w-full rounded-md border border-gray-50 bg-gradient-to-r from-purewhite to-white p-2 text-black"
            onClick={() => loadMore()}
            disabled={!nextCursor || isLoadingMore}
          >
            <FormattedMessage id="common.show-more" />
          </button>
        </div>
      )}
    </div>
  );
};
