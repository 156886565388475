import { DateTime } from "luxon";

import { DateOnly } from "../../../models/DateOnly";
import { TimeOnly } from "../../../models/TimeOnly";
import { ApiResponse, ApiSingleResponse } from "../../../models/common";
import { ICreateRecurringBookingFormValues } from "../models/Booking";
import { AvailableSlots, IScheduleResponse } from "../models/Calendar";
import { PriceSchedule } from "../models/Pricing";
import { AvailableSlotsResponse, ISchedule } from "../models/api/calendar";
import {
  AvailableSlotsRequest,
  PriceScheduleResponse,
} from "../models/api/pricing";
import {
  AvailableTimesAdminRequest,
  AvailableTimesRequest,
  AvailableTimesResponse,
  TimeRequest,
  TimeResponse,
} from "../models/api/schedule";
import {
  AvailableTimes,
  AvailableTimesAdminParameters,
  AvailableTimesParameters,
  TimeEntry,
  TimeInterval,
} from "../models/schedule";

import { formatSlots } from "../helpers/typesMapping";

import { fetchApi } from "../../../services/legacyApiClient";

const apiVersion = "checkout/v3";

export const getSchedules = async (
  day: DateOnly,
  facilityId: string,
  signal?: AbortSignal,
): Promise<ApiResponse<IScheduleResponse>> => {
  try {
    const response = await fetchApi<ApiResponse<ISchedule>>({
      uri: `${apiVersion}/schedule?facilityId=${facilityId}&day=${day.toJSON()}`,
      signal,
    });
    const { data: responseData, count } = response as ApiResponse<ISchedule>;

    const responseModel: IScheduleResponse[] = responseData.map(data => ({
      ...data,
      slots: formatSlots(data.slots),
    }));
    return { data: responseModel, count };
  } catch (err) {
    return { data: [], count: 0 };
  }
};

export const getAdminOverviewSchedules = async (
  day: DateOnly,
  facilityId: string,
  signal?: AbortSignal,
): Promise<ApiResponse<IScheduleResponse>> => {
  try {
    const response = await fetchApi<ApiResponse<ISchedule>>({
      uri: `${apiVersion}/schedule/admin?facilityId=${facilityId}&day=${day.toJSON()}`,
      signal,
    });
    const { data: responseData, count } = response as ApiResponse<ISchedule>;

    const responseModel: IScheduleResponse[] = responseData.map(data => ({
      ...data,
      slots: formatSlots(data.slots),
    }));
    return { data: responseModel, count };
  } catch (err) {
    return { data: [], count: 0 };
  }
};

export const getPricingSchedule = async (
  facilityIdParam: string,
  priceListId: string,
): Promise<ApiSingleResponse<PriceSchedule>> => {
  try {
    const response = await fetchApi<ApiSingleResponse<PriceScheduleResponse>>({
      uri: `${apiVersion}/schedule/${facilityIdParam}/prices/${priceListId}`,
    });
    const {
      data: { priceListid, facilityId, weekDays },
      error,
      isSuccessful,
    } = response as ApiSingleResponse<PriceScheduleResponse>;

    const responseModel: PriceSchedule = {
      priceListid,
      facilityId,
      weekDays: weekDays.map(({ dayOfWeek, slots }) => ({
        dayOfWeek,
        slots: slots.map(({ scheduleId, categoryId, startTime, endTime }) => ({
          scheduleId,
          categoryId,
          startTime: TimeOnly.fromISOTime(startTime),
          endTime: TimeOnly.fromISOTime(endTime),
        })),
      })),
    };

    return { data: responseModel, error, isSuccessful };
  } catch (err) {
    return err;
  }
};

export const getAvailableSlots = async (
  data: ICreateRecurringBookingFormValues,
  facilityId: string,
  objectId: string,
): Promise<ApiResponse<AvailableSlots>> => {
  try {
    const payload: AvailableSlotsRequest = {
      fromDate: data.fromDate,
      toDate: data.toDate,
      startTime: data.startTime,
      endTime: data.endTime,
      interval: data?.interval,
      daysOfWeek: data?.daysOfWeek,
      recurringGroupType: data?.recurringGroupType,
    };

    const response = await fetchApi<ApiResponse<AvailableSlotsResponse>>({
      method: "POST",
      uri: `${apiVersion}/schedule/availability/${facilityId}/${objectId}`,
      payload,
    });

    const { data: responseData, count } =
      response as ApiResponse<AvailableSlotsResponse>;

    const responseModel: AvailableSlots[] = responseData.map(
      ({ startTime, endTime, ...rest }) => ({
        ...rest,
        startTime: DateTime.fromISO(startTime, { setZone: true }),
        endTime: DateTime.fromISO(endTime, { setZone: true }),
      }),
    );

    return { data: responseModel, count };
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getAvailableTimes = async (
  data: AvailableTimesParameters,
  signal?: AbortSignal,
): Promise<ApiSingleResponse<AvailableTimes>> => {
  try {
    const payload: AvailableTimesRequest = {
      facilityId: data.facilityId,
      courtId: data.courtId,
      bookingType: data.bookingType,
      timeRequests: data.intervals.map((interval: TimeInterval) => {
        const timeRequest: TimeRequest = {
          startTime: interval.startTime.toUTC().toISO(),
          endTime: interval.endTime.toUTC().toISO(),
        };
        return timeRequest;
      }),
    };
    const response = await fetchApi<ApiSingleResponse<AvailableTimesResponse>>({
      method: "POST",
      uri: `${apiVersion}/schedule/availabletimes`,
      payload,
      signal,
    });

    const {
      data: { facilityId, courtId, timeResponses },
      error,
      isSuccessful,
    } = response as ApiSingleResponse<AvailableTimesResponse>;

    const responseModel: AvailableTimes = {
      facilityId,
      courtId,
      entries: timeResponses.map((timeResponse: TimeResponse) => {
        const timeEntry: TimeEntry = {
          startTime: DateTime.fromISO(timeResponse.startTime, {
            setZone: true,
          }),
          endTime: DateTime.fromISO(timeResponse.endTime, { setZone: true }),
          error: timeResponse.error,
        };
        return timeEntry;
      }),
    };
    return { data: responseModel, error, isSuccessful };
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getAvailableTimesForAdmin = async (
  data: AvailableTimesAdminParameters,
  signal?: AbortSignal,
): Promise<ApiSingleResponse<AvailableTimes>> => {
  try {
    // First, map the payload to the API request format
    const payload: AvailableTimesAdminRequest = {
      facilityId: data.facilityId,
      courtId: data.courtId,
      bookingType: data.bookingType,
      reschedulingBookingId: data.reschedulingBookingId || null,
      timeRequests: data.intervals.map((interval: TimeInterval) => {
        const timeRequest: TimeRequest = {
          startTime: interval.startTime.toUTC().toISO(),
          endTime: interval.endTime.toUTC().toISO(),
        };
        return timeRequest;
      }),
    };
    // Second, call the endpoint
    const response = await fetchApi<ApiSingleResponse<AvailableTimesResponse>>({
      method: "POST",
      uri: `${apiVersion}/schedule/admin/availabletimes`,
      payload,
      signal,
    });
    const {
      data: { facilityId, courtId, timeResponses },
      error,
      isSuccessful,
    } = response as ApiSingleResponse<AvailableTimesResponse>;
    // Third, map the response from the API response format to the model format
    const model: AvailableTimes = {
      facilityId: facilityId,
      courtId: courtId,
      entries: timeResponses.map((timeResponse: TimeResponse) => {
        const timeEntry: TimeEntry = {
          startTime: DateTime.fromISO(timeResponse.startTime, {
            setZone: true,
          }),
          endTime: DateTime.fromISO(timeResponse.endTime, { setZone: true }),
          error: timeResponse.error,
        };
        return timeEntry;
      }),
    };
    // Last, construct the response object
    return {
      data: model,
      error,
      isSuccessful,
    };
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getAvailableRescheduleSlots = async (
  bookingId: string,
  day: DateOnly,
  signal?: AbortSignal,
): Promise<ApiResponse<IScheduleResponse>> => {
  try {
    const response = await fetchApi<ApiResponse<ISchedule>>({
      method: "GET",
      uri: `${apiVersion}/schedule/rescheduleavailability/${bookingId}/${day.toJSON()}`,
      signal,
    });

    const { data: responseData, count } = response as ApiResponse<ISchedule>;

    const responseModel: IScheduleResponse[] = responseData.map(data => ({
      ...data,
      slots: formatSlots(data.slots),
    }));

    return { data: responseModel, count };
  } catch (err) {
    return Promise.reject(err);
  }
};
