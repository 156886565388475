import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import styled from "styled-components";

import { breakpoints } from "../../../../appConstants/common";

import { VenueSearch } from "../../../../components/inputs/VenueSearch";

const Container = styled.div`
  display: grid;
  gap: 1rem;

  @media (min-width: ${breakpoints.XL}) {
    gap: 2rem;
  }
`;

const initialSelectedResult = { id: "", name: "" };

export const FindFacilityTimeSlots: React.FC = () => {
  const history = useHistory();
  const [searchInput, setSearchInput] = useState("");
  const [selectedResult, setSelectedResult] = useState(initialSelectedResult);

  useEffect(() => {
    if (!searchInput) {
      setSelectedResult(initialSelectedResult);
    }
  }, [searchInput]);

  useEffect(() => {
    if (selectedResult?.id) {
      history.push(`venues/${selectedResult.id}?tab=book`);
    }
  }, [selectedResult.id]);

  return (
    <Container>
      <VenueSearch
        onInputChange={setSearchInput}
        onSelectResult={setSelectedResult}
      />
    </Container>
  );
};
