import React from "react";
import { FormattedMessage } from "react-intl";

import styled from "styled-components";

import { Series, SeriesDivision } from "../../../models/Series";

import DivisionsEditorDivisionDetailsBody from "./details/DivisionsEditorDivisionDetailsBody";
import DivisionsEditorDivisionDetailsHeader from "./details/DivisionsEditorDivisionDetailsHeader";

interface Props {
  serie: Series;
  division: SeriesDivision;
  onActiveDivisionIdChange: (divisionId: SeriesDivision["id"]) => void;
  onEditDialogStateChange: (state: boolean) => void;
  reload: () => void;
}

const DivisionDetails = styled.div`
  position: absolute;
  z-index: 99;
  margin-top: 5rem;
  width: 40vw;

  @media (min-width: 1290px) {
    margin-left: 0rem;
    left: initial;
  }

  @media (max-width: 795px) {
    margin-left: 0rem;
    left: 50%;
    right: 50%;
    translate: -50% 50%;
    width: 90vw;
  }

  max-width: 42rem;

  display: flex;
  flex-direction: column;
  gap: 2rem;

  background: var(--light);
`;

const DivisionsEditorDivisionDetails = ({
  serie,
  division,
  onActiveDivisionIdChange,
  onEditDialogStateChange,
  reload,
}: Props) => {
  return (
    <DivisionDetails>
      <DivisionsEditorDivisionDetailsHeader
        series={serie}
        division={division}
        onActiveDivisionIdChange={onActiveDivisionIdChange}
        onEditDialogStateChange={onEditDialogStateChange}
        reload={reload}
      />

      <DivisionsEditorDivisionDetailsBody
        reload={reload}
        serie={serie}
        division={division}
      />

      {division?.teams?.length === 0 && (
        <div className="mt-4 text-center">
          <FormattedMessage
            id="series.divisions.empty"
            defaultMessage="Inga lag tillagda än"
          />
        </div>
      )}
    </DivisionDetails>
  );
};

export default DivisionsEditorDivisionDetails;
