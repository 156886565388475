import useSWR, { SWRConfiguration } from "swr";

import { FacilityWithUtc } from "../../modules/customer/models/Facility";

import { getMembershipsForFacility } from "../../modules/player/services/MembershipService";

export const useFacilityMemberships = (
  facilityId?: FacilityWithUtc["id"] | null,
  options?: SWRConfiguration,
) => {
  const { data, ...rest } = useSWR(
    facilityId ? ["facilityMemberships", facilityId] : null,
    ([, facilityId]) => getMembershipsForFacility(facilityId),
    options,
  );

  return {
    ...rest,
    memberships: data,
  };
};
