import React from "react";
import { FormattedMessage } from "react-intl";

import { FacilityBookableEntities } from "./components/FacilityBookableEntities";
import { FacilityPriceGroups } from "./components/FacilityPriceGroups";

export const FacilityBookableObjects: React.FC = () => {
  return (
    <>
      <div className="mb-10 font-bold text-gray-700">
        <p className="max-w-xs">
          <FormattedMessage id="facility-settings.bookable.description.part-1" />
        </p>
        <p className="max-w-xs">
          <FormattedMessage id="facility-settings.bookable.description.part-2" />
        </p>
      </div>
      <div className="flex flex-col gap-20">
        <FacilityBookableEntities />
        <FacilityPriceGroups />
      </div>
    </>
  );
};
