import type { User } from "../modules/player/models/User";

import { Order } from "./Order";

export const PaymentMethodStatus = {
  Enabled: "Enabled",
  Disabled: "Disabled",
  RequiresBackupPaymentMethod: "RequiresBackupPaymentMethod",
  Loading: "Loading",
} as const;

interface PaymentMethodBase {
  readonly provider: string;
  readonly instrument: string;
  status: keyof typeof PaymentMethodStatus;
}

export interface SwedbankPayCardPaymentMethod extends PaymentMethodBase {
  readonly provider: "SwedbankPay";
  readonly instrument: "Card";
}
export interface SwedbankPaySwishPaymentMethod extends PaymentMethodBase {
  readonly provider: "SwedbankPay";
  readonly instrument: "Swish";
}
export interface SwedbankPayMobilePayPaymentMethod extends PaymentMethodBase {
  readonly provider: "SwedbankPay";
  readonly instrument: "MobilePay";
}
export interface SwedbankPayApplePayPaymentMethod extends PaymentMethodBase {
  readonly provider: "SwedbankPay";
  readonly instrument: "ApplePay";
}
export interface SwedbankPayGooglePayPaymentMethod extends PaymentMethodBase {
  readonly provider: "SwedbankPay";
  readonly instrument: "GooglePay";
}

export interface CoinsCreditPaymentMethod extends PaymentMethodBase {
  readonly provider: "Coins";
  readonly instrument: "Credit";
}
export interface CoinsAllowancePaymentMethod extends PaymentMethodBase {
  readonly provider: "Coins";
  readonly instrument: "Allowance";
}

export type PaymentMethod =
  | SwedbankPayCardPaymentMethod
  | SwedbankPaySwishPaymentMethod
  | SwedbankPayMobilePayPaymentMethod
  | SwedbankPayApplePayPaymentMethod
  | SwedbankPayGooglePayPaymentMethod
  | CoinsCreditPaymentMethod
  | CoinsAllowancePaymentMethod;

interface OrderPaymentResponseBase {
  readonly orderId: Order["id"];
  readonly orderPaymentId: string;
}

export interface SwedbankPayOrderPaymentResponse
  extends OrderPaymentResponseBase {
  readonly scriptUrl: string;
}

export type CoinsOrderPaymentResponse = OrderPaymentResponseBase;

export type OrderPaymentResponse =
  | SwedbankPayOrderPaymentResponse
  | CoinsOrderPaymentResponse;

export interface CreateOrderPaymentRequest {
  readonly coPayingUserIds: User["id"][];
  readonly splitPayment: boolean;
}

export interface CreateSwedbankPayOrderPaymentRequest
  extends CreateOrderPaymentRequest {
  readonly paymentMenuUrl: string;
}

export type CreateCoinsOrderPaymentRequest = CreateOrderPaymentRequest;

export const PaymentStatus = {
  Pending: "Pending",
  Aborted: "Aborted",
  Paid: "Paid",
  Refunded: "Refunded",
  RemainderDebtCollected: "RemainderDebtCollected",
} as const;

export type PaymentStatus = keyof typeof PaymentStatus;

export interface CurrentOrderPayment extends OrderPaymentResponseBase {
  readonly paymentMethod: PaymentMethod;
  readonly paymentStatus: PaymentStatus;
}
