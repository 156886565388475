import { useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import Select, { DropdownIndicatorProps, components } from "react-select";

import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { ProgressSpinner } from "primereact/progressspinner";
import { useRecoilState } from "recoil";

import { adminGetHomePath, getHomePath } from "../../helpers/pathHelpers";

import { useAppDispatch, useAppSelector } from "../../hooks/store/store";
import { useAdminFacilities } from "../../hooks/swr/useAdminFacilities";

import { isAdminModeState } from "../../recoil/isAdminModeState";
import { selectedFacilityIdState } from "../../recoil/selectedFacilityIdState";
import { setSelectedFacility } from "../../store/slices/facilities/facilitiesSlice";

interface Option {
  label: string;
  value: string;
}

export const AdminModeAndFacilitySelectDropdown = () => {
  const { facilities, isLoading } = useAdminFacilities();
  const history = useHistory();
  const [isAdminMode, setIsAdminMode] = useRecoilState(isAdminModeState);
  const dispatch = useAppDispatch();
  const selectedFacility = useAppSelector(
    state => state.facilities.selectedFacility,
  );
  const [selectedFacilityId, setSelectedFacilityId] = useRecoilState(
    selectedFacilityIdState,
  );
  const intl = useIntl();

  const options = useMemo(() => {
    if (!facilities) {
      return [];
    }

    const options = facilities.map(facility => ({
      label: facility.name,
      value: facility.id,
    }));

    if (isAdminMode) {
      return [
        {
          label: intl.formatMessage({
            id: "header.admin-mode.change-to.player",
          }),
          value: "player",
        },
        {
          options,
        },
      ];
    }

    return options;
  }, [facilities, intl, isAdminMode]);

  // set selectedFacilityId to first facility if it's not set
  useEffect(() => {
    if (selectedFacilityId) {
      return;
    }

    if (!facilities || facilities?.length < 1) {
      return;
    }

    setSelectedFacilityId(facilities.at(0).id);
  }, [facilities, selectedFacilityId, setSelectedFacilityId]);

  // set selectedFacility when selectedFacilityId changes
  useEffect(() => {
    if (!selectedFacilityId || !facilities) {
      return;
    }

    if (selectedFacility?.id === selectedFacilityId) {
      return;
    }

    dispatch(
      setSelectedFacility({
        facility: facilities.find(
          facility => facility.id === selectedFacilityId,
        ),
      }),
    );
  }, [dispatch, facilities, selectedFacility?.id, selectedFacilityId]);

  return (
    <Select<Option>
      unstyled
      options={options}
      isLoading={isLoading}
      onChange={option => {
        if (option.value === "player") {
          setIsAdminMode(false);
          history.push(getHomePath());
          return;
        }

        setSelectedFacilityId(option.value);

        if (!isAdminMode) {
          setIsAdminMode(true);
          history.push(adminGetHomePath());
        }
      }}
      className="text-sm leading-tight"
      classNames={{
        menu: () =>
          clsx(
            "right-0 mt-1 w-36 rounded border border-white bg-purewhite shadow-md",
          ),
        menuList: () => clsx("pt-1"),
        control: () => clsx("min-h-0 cursor-pointer hover:text-primary"),
        singleValue: props =>
          clsx(
            "max-w-[8rem] leading-none",
            props.selectProps.menuIsOpen && "text-primary",
          ),
        option: props =>
          clsx(
            "cursor-pointer px-2 py-2",
            props.isFocused && "bg-white text-primary",
            props.isSelected && "!bg-primary !text-purewhite",
          ),
        valueContainer: props =>
          clsx("pr-2", !props.selectProps.inputValue && "caret-transparent"),
        groupHeading: () => clsx("mx-2 my-2 border-t border-gray-100"),
        loadingIndicator: () => clsx("hidden"),
      }}
      value={
        isAdminMode
          ? {
              label: selectedFacility?.name,
              value: selectedFacility?.id,
            }
          : null
      }
      placeholder={intl.formatMessage({
        id: "site-mode.player",
      })}
      noOptionsMessage={() => intl.formatMessage({ id: "search.no-results" })}
      components={{ DropdownIndicator }}
    />
  );
};
const DropdownIndicator = (props: DropdownIndicatorProps) => {
  return (
    <components.DropdownIndicator {...props}>
      {props.selectProps.isLoading ? (
        <ProgressSpinner className="h-3 w-3 text-primary" />
      ) : (
        <FontAwesomeIcon
          icon={faChevronDown}
          rotation={props.selectProps.menuIsOpen ? 180 : undefined}
          size="xs"
          className="ml-1 text-primary"
        />
      )}
    </components.DropdownIndicator>
  );
};

// const AdminModeDropdown = () => {
//   const history = useHistory();
//   const [isAdminMode, setIsAdminMode] = useRecoilState(isAdminModeState);
//   const wrapperRef = useRef<HTMLDivElement>(null);

//   const [isOpen, setIsOpen] = useState(false);

//   const onAdminToggle = (toAdmin: boolean) => {
//     setIsAdminMode(toAdmin);
//     setIsOpen(false);
//     toAdmin ? history.push("/admin/booking") : history.push("/");
//   };

//   useOnClickOutside(wrapperRef, () => setIsOpen(false));

//   return (
//     <div className="flex items-center justify-end whitespace-nowrap text-right">
//       <div className="relative text-sm" ref={wrapperRef}>
//         <button
//           type="button"
//           onClick={() => setIsOpen(v => !v)}
//           className="flex items-center hover:text-primary"
//         >
//           {isAdminMode ? (
//             <FormattedMessage id="site-mode.admin" />
//           ) : (
//             <FormattedMessage id="site-mode.player" />
//           )}

//           <FontAwesomeIcon
//             icon={faChevronDown}
//             rotation={isOpen ? 180 : undefined}
//             size="xs"
//             className="ml-1 text-primary"
//           />
//         </button>

//         {isOpen && (
//           <button
//             type="button"
//             className="absolute right-0 top-full mr-3 text-right text-gray-700 hover:text-primary"
//             onClick={() => onAdminToggle(isAdminMode ? false : true)}
//           >
//             {isAdminMode ? (
//               <FormattedMessage id="header.admin-mode.change-to.player" />
//             ) : (
//               <FormattedMessage id="header.admin-mode.change-to.admin" />
//             )}
//           </button>
//         )}
//       </div>
//     </div>
//   );
// };
