import { DateTime } from "luxon";

import type {
  CreateOpenBookingRequest,
  OpenBooking,
  OpenBookingApiResponse,
} from "../models/OpenBookings";
import type { FacilityWithUtc } from "../modules/customer/models/Facility";

import { get, post } from "./apiClient";

const API_VERSION = "v1";

const getEndpointBase = (facilityId: FacilityWithUtc["id"]) =>
  `/${API_VERSION}/facilities/${facilityId}/open-bookings`;

export const createOpenBooking = async (
  facilityId: FacilityWithUtc["id"],
  body: CreateOpenBookingRequest,
): Promise<OpenBooking> => {
  return post<OpenBookingApiResponse>({
    endpoint: getEndpointBase(facilityId),
    body: {
      ...body,
      startTime: body.startTime.toUTC().toISO(),
      endTime: body.endTime.toUTC().toISO(),
      automaticCancellationTime: body.automaticCancellationTime.toUTC().toISO(),
    },
  }).then(formatOpenBooking);
};

export const cancelOpenBooking = async (
  facilityId: FacilityWithUtc["id"],
  id: OpenBooking["id"],
) =>
  post({
    endpoint: `${getEndpointBase(facilityId)}/${id}/cancel`,
    body: {},
    parseDataAs: "none",
  });

export const getOpenBooking = async (
  facilityId: FacilityWithUtc["id"],
  id: OpenBooking["id"],
): Promise<OpenBooking> =>
  get<OpenBookingApiResponse>({
    endpoint: `${getEndpointBase(facilityId)}/${id}`,
  }).then(formatOpenBooking);

const formatOpenBooking = (data: OpenBookingApiResponse): OpenBooking => ({
  ...data,
  startTime: DateTime.fromISO(data.startTime),
  endTime: DateTime.fromISO(data.endTime),
  automaticCancellationTime: DateTime.fromISO(data.automaticCancellationTime),
  participants: data.participants.map(participant => ({
    ...participant,
    paymentDate: participant.paymentDate
      ? DateTime.fromISO(participant.paymentDate)
      : null,
  })),
});
