import useSWR from "swr";

import { getFacilityAdminUsers } from "../../services/permissionService";

import { useSelectedFacilityId } from "../../recoil/selectedFacilityIdState";

export const useSelectedFacilityAdminUsers = () => {
  const selectedFacilityId = useSelectedFacilityId();

  const { data, ...rest } = useSWR(
    selectedFacilityId ? ["adminUsers", selectedFacilityId] : null,
    async ([, selectedFacilityId]) => getFacilityAdminUsers(selectedFacilityId),
  );

  return {
    ...rest,
    adminUsers: data,
  };
};
