import { FormattedMessage, useIntl } from "react-intl";

import { faUsers } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import styled from "styled-components";

import { User } from "../../../player/models/User";
import { IActivity } from "../../models/Activity";

import { AvatarNameWrapper } from "../../../../components/AvatarNameWrapper";

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.h3`
  color: var(--primary);
`;

const IconWrapper = styled.div`
  color: black;
  display: flex;
  gap: 0.5rem;
`;

const SmallHeader = styled.p`
  font-size: var(--b4);
`;

interface Props {
  activity: Pick<IActivity, "name" | "participants" | "maxParticipantsCount">;
}

const ActivityParticipantsList = ({ activity }: Props) => {
  const intl = useIntl();
  const participants = activity?.participants;

  return (
    <div>
      <SmallHeader>
        <FormattedMessage
          id="activity.participants"
          defaultMessage="Deltagare"
        />
      </SmallHeader>
      <TitleWrapper>
        <Title>{activity?.name}</Title>
        <IconWrapper>
          <FontAwesomeIcon icon={faUsers} />
          <FormattedMessage
            id="activity.participants.number"
            defaultMessage="{numberOfParticipants} av {maxNumberOfParticipants}"
            values={{
              numberOfParticipants: activity?.participants?.length,
              maxNumberOfParticipants: activity.maxParticipantsCount,
            }}
          />
        </IconWrapper>
      </TitleWrapper>
      <DataTable dataKey="id" value={participants || []}>
        <Column
          body={(user: User) => (
            <AvatarNameWrapper
              image={user?.profileImage}
              name={user?.displayName}
              firstName={user?.firstName}
              lastName={user?.lastName}
              title={user?.displayName}
            />
          )}
          header={intl.formatMessage({
            id: "common.name",
            defaultMessage: "Namn",
          })}
        />
        <Column
          body={(user: User) => user?.skillLevel}
          header={intl.formatMessage({
            id: "common.skill",
            defaultMessage: "Nivå",
          })}
        />
      </DataTable>
    </div>
  );
};

export default ActivityParticipantsList;
