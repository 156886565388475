import { useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link, useHistory } from "react-router-dom";

import {
  faBars,
  faCalendarDays,
  faCog,
  faCopy,
  faFemale,
  faImage,
  faMale,
  faSend,
  faTable,
  faTrash,
  faUserPlus,
  faUsers,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { groupBy, orderBy } from "lodash";
import { DateTime } from "luxon";
import { Menu } from "primereact/menu";
import type { MenuItem } from "primereact/menuitem";
import { Tooltip } from "primereact/tooltip";
import styled from "styled-components";

import standardImage from "../../../../../assets/images/backgrounds/background3.jpg";

import { GenderType } from "../../../../../models/common";
import { BuyableType } from "../../../../checkout/models/Payment";
import {
  SeasonStatus,
  Series,
  SeriesDivision,
  SeriesTeam,
} from "../../../models/Series";

import {
  adminGetActivitesSeriesPath,
  adminGetActivitiesPath,
} from "../../../../../helpers/pathHelpers";

import { useToaster } from "../../../../../hooks/common/useToaster";
import { useSeries } from "../../../../../hooks/swr/useSeries";
import { useGenderTypes } from "../../../../../hooks/useGenderTypes";

import { uploadActivityImage } from "../../../services/Activities";
import { deleteSerie } from "../../../services/Serie";

import { Button } from "../../../../../components/Button";
import { ConfirmationDialog } from "../../../../../components/ConfirmationDialog";
import { Dialog } from "../../../../../components/Dialog";
import { ProgressSpinner } from "../../../../../components/ProgressSpinner";
import ImageInput from "../../../../../components/image/ImageInput";
import { SeriesMessageEditor } from "../../../../../pages/Admin/activities/series/AdminSeriesDetailsPage/components/SeriesMessageEditor";

import { AdminAddTeam } from "../../AdminAddTeams";
import AdminCopySerieForm from "../forms/AdminCopySerieForm";
import AdminEditSerieForm from "../forms/AdminEditSerieForm";
import { CloseSeason } from "./CloseSeason";
import DivisionsEditorDivision from "./DivisionsEditorDivision";
import AdminCreateDivisionForm from "./details/form/AdminCreateDivisionForm";

const LevelContainer = styled.div`
  display: grid;
  gap: 0.5rem;

  > div {
    padding: 1rem;
    border-radius: var(--border-radius);
    color: var(--dark);
    cursor: pointer;
    border: 1px solid var(--gray-200);
  }
`;

interface IProps {
  serie: Series;
  onDrop: (division: SeriesDivision, team: SeriesTeam) => void;
  reload: () => void;
}

const DivisionsEditorDivisions = ({ serie, onDrop, reload }: IProps) => {
  const intl = useIntl();
  const { getGenderNameById } = useGenderTypes();
  const [activeDivisionId, setActiveDivisionId] =
    useState<SeriesDivision["id"]>("");
  const [createDialogOpen, setCreateDialogOpen] = useState<boolean>(false);
  const [closeDialogOpen, setCloseDialogOpen] = useState<boolean>(false);
  const [showMessageEditor, setShowMessageEditor] = useState(false);

  const divisionsGroupedByLevel = useMemo(() => {
    if (!serie) {
      return [];
    }

    return orderBy(groupBy(serie.divisions, "level"), ["key"], ["asc"]);
  }, [serie]);

  return (
    <>
      <div className="mt-8 flex w-full justify-start md:mb-16 md:mt-0">
        <div className="flex-auto">
          {(serie?.previousSeasonId || serie?.nextSeasonId) && (
            <div className="grid">
              {serie?.previousSeasonId && (
                <Link
                  to={`/admin/activities/serie/${serie?.previousSeasonId}`}
                  className="justify-self-start"
                >
                  <FormattedMessage id="admin.serie.previousSeason.link" />
                </Link>
              )}
              {serie?.nextSeasonId && (
                <Link
                  to={`/admin/activities/serie/${serie?.nextSeasonId}`}
                  className="col-start-2 justify-self-end"
                >
                  <FormattedMessage id="admin.serie.nextSeason.link" />
                </Link>
              )}
            </div>
          )}

          <div className="mt-4 flex flex-wrap items-center justify-between gap-2 text-primary">
            <div className="flex flex-grow items-center gap-2 lg:text-xl">
              {serie?.gender === GenderType.Male && (
                <FontAwesomeIcon icon={faMale} />
              )}
              {serie?.gender === GenderType.Female && (
                <FontAwesomeIcon icon={faFemale} />
              )}
              {serie?.gender === GenderType.Mix && (
                <>
                  <FontAwesomeIcon icon={faMale} />
                  {" / "}
                  <FontAwesomeIcon icon={faFemale} />
                </>
              )}

              <span>{getGenderNameById(serie?.gender.toString())}</span>
            </div>

            <div className="flex flex-wrap gap-2">
              {serie?.nextSeasonId &&
                serie?.seasonStatus === SeasonStatus.Active && (
                  <Button
                    type="primary"
                    buttonType="button"
                    size="small"
                    onClick={() => setCloseDialogOpen(true)}
                    text={intl.formatMessage({
                      id: "admin.serie.season.close.current.cta",
                    })}
                  />
                )}

              {!!serie.registeredTeams.length && (
                <>
                  <Tooltip target=".send-series-message">
                    <FormattedMessage id="admin.send-series-message-tooltip" />
                  </Tooltip>

                  <Button
                    className="send-series-message"
                    type="primary"
                    buttonType="button"
                    size="small"
                    icon={faSend}
                    onClick={() => setShowMessageEditor(true)}
                    translationName="admin.send.customer.email"
                  />
                </>
              )}

              {serie.seasonStatus === SeasonStatus.Active && (
                <Link
                  to={`/admin/activities/serie/${serie.id}/matches`}
                  className="text-inherit"
                >
                  <Button
                    type="primary"
                    size="small"
                    icon={faCalendarDays}
                    text={intl.formatMessage({
                      id: "series.games.schedule.view",
                    })}
                  />
                </Link>
              )}

              <Button
                type="primary"
                buttonType="button"
                size="small"
                onClick={() => setCreateDialogOpen(true)}
                text="Lägg till division"
                translationName="series.add-division"
              />

              <ActionMenu seriesId={serie.id} />
            </div>
          </div>

          {divisionsGroupedByLevel.map(
            (divisions: SeriesDivision[], idx: number) => (
              <div key={`div-${idx}`}>
                <div
                  key={`key-${idx}`}
                  style={{ marginTop: "2rem", marginBottom: "1rem" }}
                >
                  <FormattedMessage id="common.level" defaultMessage="Nivå" />{" "}
                  {idx + 1}
                </div>
                <LevelContainer
                  key={idx}
                  style={{
                    gridTemplateColumns: `repeat(${divisions.length}, 1fr)`,
                  }}
                >
                  {divisions.map((division, key) => (
                    <DivisionsEditorDivision
                      key={key}
                      serie={serie}
                      division={division}
                      isActive={activeDivisionId === division.id}
                      onDrop={onDrop}
                      reload={reload}
                      onActiveDivisionIdChange={setActiveDivisionId}
                    />
                  ))}
                </LevelContainer>
              </div>
            ),
          )}
        </div>
      </div>

      {createDialogOpen && (
        <Dialog visible={true} onHide={() => setCreateDialogOpen(false)}>
          <AdminCreateDivisionForm
            seriesId={serie?.id}
            facilityId={serie?.facilityId}
            onSubmitted={() => setCreateDialogOpen(false)}
          />
        </Dialog>
      )}

      {closeDialogOpen && (
        <CloseSeason
          serie={serie}
          visible={true}
          onHide={() => {
            setCloseDialogOpen(false);
          }}
          onChange={reload}
        />
      )}

      {showMessageEditor && (
        <Dialog
          onHide={() => setShowMessageEditor(false)}
          visible
          className="h-[90%] w-[90%] max-w-[1200px]"
        >
          <SeriesMessageEditor
            seriesId={serie.id}
            onComplete={() => setShowMessageEditor(false)}
          />
        </Dialog>
      )}
    </>
  );
};

const now = DateTime.now();

const ActionMenu = ({ seriesId }: { seriesId: Series["id"] }) => {
  const intl = useIntl();
  const history = useHistory();
  const toaster = useToaster();

  const { series, mutate } = useSeries(seriesId, "participants");

  const menuRef = useRef<Menu>(null);

  const [imageLoading, setImageLoading] = useState(false);
  const [image, setImage] = useState(standardImage);
  const [handleAddImageOpen, setHandleAddImageOpen] = useState(false);

  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [addTeamDialogOpen, setAddTeamDialogOpen] = useState(false);
  const [copySerieDialogOpen, setCopySerieDialogOpen] = useState(false);

  useEffect(() => {
    setImage(series?.imageUrl ? series.imageUrl : standardImage);
  }, [series?.imageUrl]);

  const imageUploader = async (file: File) => {
    const update = async () => {
      try {
        setImageLoading(true);
        setImage(null);
        const response = await uploadActivityImage(
          series.id,
          file,
          BuyableType.Series,
        );

        if (response != null) {
          toaster.toastSuccess.activityImageChanged();
          setImage(response);
        }
      } catch {
        setImage(series?.imageUrl ? series.imageUrl : standardImage);
        toaster.toastError.activityImageFailed();
      } finally {
        setImageLoading(false);
      }
    };

    update();
  };

  const items: MenuItem[] = [
    {
      label: intl.formatMessage({ id: "series.add-team" }),
      icon: <FontAwesomeIcon className="mr-2" fixedWidth icon={faUserPlus} />,
      command: () => setAddTeamDialogOpen(true),
    },
    {
      label: intl.formatMessage({ id: "series.signed-up-teams" }),
      icon: <FontAwesomeIcon className="mr-2" fixedWidth icon={faUsers} />,
      command: e => {
        "metaKey" in e.originalEvent && e.originalEvent.metaKey
          ? window.open(`/#/admin/activities/serie/${series?.id}/teams`)
          : history.push(`/admin/activities/serie/${series?.id}/teams`);
      },
    },
    {
      label: intl.formatMessage({ id: "common.edit" }),
      icon: <FontAwesomeIcon className="mr-2" fixedWidth icon={faCog} />,
      command: () => setEditDialogOpen(true),
    },
  ];

  if (
    series?.seasonStatus === SeasonStatus.Active ||
    series?.seasonStatus === SeasonStatus.Closed
  ) {
    items.splice(1, 0, {
      label: intl.formatMessage({ id: "series.scoreboard" }),
      icon: <FontAwesomeIcon className="mr-2" fixedWidth icon={faTable} />,
      command: e => {
        "metaKey" in e.originalEvent && e.originalEvent.metaKey
          ? window.open(`/#/admin/activities/serie/${series.id}/scoreboard`)
          : history.push(`/admin/activities/serie/${series.id}/scoreboard`);
      },
    });
  }

  if (!series?.nextSeasonId) {
    items.push({
      label: intl.formatMessage({ id: "series.create-next-season" }),
      icon: <FontAwesomeIcon className="mr-2" fixedWidth icon={faCopy} />,
      command: () => setCopySerieDialogOpen(true),
    });
  }

  items.push({
    label: intl.formatMessage({ id: "series.addImage" }),
    icon: <FontAwesomeIcon className="mr-2" fixedWidth icon={faImage} />,
    command: () => setHandleAddImageOpen(true),
  });

  if (series && series?.endTime > now) {
    items.push({
      label: intl.formatMessage({ id: "common.remove" }),
      icon: <FontAwesomeIcon className="mr-2" fixedWidth icon={faTrash} />,
      className: "text-red-500",
      command: () => setDeleteDialogOpen(true),
    });
  }

  return (
    <>
      <Menu ref={menuRef} model={items} className="text-sm" popup />
      <Button
        icon={faBars}
        size="small"
        type="primary"
        onClick={e => menuRef.current?.toggle(e)}
      >
        <FormattedMessage id="common.menu" />
      </Button>

      {handleAddImageOpen && (
        <Dialog visible onHide={() => setHandleAddImageOpen(false)}>
          {imageLoading ? (
            <ProgressSpinner />
          ) : (
            <>
              <div
                style={{
                  width: "100%",
                  height: "20rem",
                  background: "var(--gray-200)",

                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img style={{ height: "100%", width: "auto" }} src={image} />
              </div>
              <div style={{ marginBottom: "1rem", marginTop: ".5rem" }}>
                <FormattedMessage
                  id="activities.image.description"
                  defaultMessage="Bilden kommer att bli beskuren beroende på vart den visas"
                />
              </div>
              <ImageInput onSave={imageUploader} />
            </>
          )}
        </Dialog>
      )}

      {addTeamDialogOpen && (
        <Dialog
          visible
          onHide={() => setAddTeamDialogOpen(false)}
          className="admin-series-table__edit-dialog"
        >
          <AdminAddTeam
            facilityId={series.facilityId}
            seriesId={series.id}
            onSubmit={() => {
              mutate();
              setAddTeamDialogOpen(false);
            }}
          />
        </Dialog>
      )}

      {editDialogOpen && (
        <Dialog visible onHide={() => setEditDialogOpen(false)}>
          <AdminEditSerieForm
            seriesId={series?.id}
            onSubmit={() => {
              setEditDialogOpen(false);
              mutate();
            }}
          />
        </Dialog>
      )}

      {copySerieDialogOpen && (
        <Dialog
          visible
          onHide={() => setCopySerieDialogOpen(false)}
          className="admin-series-table__edit-dialog"
        >
          <AdminCopySerieForm
            serieId={series?.id}
            onSubmit={newSeries => {
              history.push(adminGetActivitesSeriesPath(newSeries.id));
            }}
          />
        </Dialog>
      )}

      {deleteDialogOpen && (
        <ConfirmationDialog
          visible
          title={intl.formatMessage({
            id: "admin-series-table.delete-confirmation-message",
          })}
          confirmButtonType="danger"
          onCancel={() => setDeleteDialogOpen(false)}
          onSubmit={async () => {
            await deleteSerie(series?.id);

            try {
              history.push(adminGetActivitiesPath());
            } catch (error) {
              toaster.toastError.removeSerieFailed();
            }
          }}
          icon={faTrash}
          text={intl.formatMessage(
            {
              id: "series.remove.are-you-sure",
            },
            { br: <br /> },
          )}
          denyText={intl.formatMessage({
            id: "series.remove.reject",
          })}
          confirmText={intl.formatMessage({
            id: "common.confirm.remove",
            defaultMessage: "Ja, ta bort!",
          })}
          loading={false}
          onHide={() => setDeleteDialogOpen(false)}
        />
      )}
    </>
  );
};

export default DivisionsEditorDivisions;
