import {
  IconDefinition,
  faCalendarPlus,
  faCog,
  faTableTennis,
  faUsers,
  faWarehouse,
} from "@fortawesome/pro-light-svg-icons";

import {
  adminGetActivitiesPath,
  adminGetHomePath,
  adminGetPlayersPath,
  adminGetSettingsPath,
  getBookingPath,
  getMyProfilePath,
  getVenuesPath,
} from "../../../helpers/pathHelpers";

export interface MenuItem {
  textId: string;
  icon: IconDefinition;
  to: string;
  exact?: boolean;
}

export const menuItems: MenuItem[] = [
  {
    textId: "navigation.menuitem.booking",
    icon: faCalendarPlus,
    to: getBookingPath(),
    exact: true,
  },
  {
    textId: "navigation.menuitem.venues",
    icon: faWarehouse,
    to: getVenuesPath(),
  },
];

export const mobileMenuItems: MenuItem[] = [
  ...menuItems,
  {
    textId: "navigation.menuitem.myprofile",
    icon: faUsers,
    to: getMyProfilePath(),
  },
];

export const adminMenuItems: MenuItem[] = [
  {
    textId: "navigation.menuitem.overview",
    icon: faCalendarPlus,
    to: adminGetHomePath(),
    exact: true,
  },
  {
    textId: "navigation.menuitem.activity",
    icon: faTableTennis,
    to: adminGetActivitiesPath(),
  },
  {
    textId: "navigation.menuitem.settings",
    icon: faCog,
    to: adminGetSettingsPath(),
  },
  {
    textId: "navigation.menuitem.players",
    icon: faUsers,
    to: adminGetPlayersPath(),
  },
];

export const mobileAdminMenuItems: MenuItem[] = [
  ...adminMenuItems,
  {
    textId: "navigation.menuitem.myprofile",
    icon: faUsers,
    to: getMyProfilePath(),
  },
];
