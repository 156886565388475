import React, { useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";

import { Paginator, PaginatorPageState } from "primereact/paginator";
import styled from "styled-components";

import type { IActivity } from "../../../models/Activity";

import { sortSeries } from "../../../../../helpers/activityHelper";

import { getFacilityActivitiesOverview } from "../../../services/Activities";

import { Card } from "../../../../../components/Card";
import { ProgressSpinner } from "../../../../../components/ProgressSpinner";

import { useCurrentUserId } from "../../../../../recoil/currentUserIdState";
import ActivityInfoCard from "../ActivityInfoCard";
import ActivitiesFilters from "./ActivitiesFilter";

const Wrapper = styled(Card)`
  display: grid;
`;

const Items = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  @media (min-width: 1400px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const Filter = styled.div`
  margin: 0 0 2rem 0;
`;

const NoActivitiesFoundWrapper = styled.div`
  display: grid;
  place-items: center;
  margin-bottom: 1rem;
`;

const Div = ({ children }: React.PropsWithChildren<unknown>) => (
  <div>{children}</div>
);

export interface Filters {
  page: number;
  pageSize: number;
  query: string;
}

interface IProps {
  facilityId?: string;
  isSpecific?: boolean;
  refresh?: boolean;
}

const ActivitiesList: React.FC<IProps> = ({ facilityId, isSpecific }) => {
  const currentUserId = useCurrentUserId();

  const [activities, setActivities] = useState<IActivity[]>([]);
  const [filters, setFilters] = useState<Filters>({
    page: 0,
    pageSize: 999,
    query: "",
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [numberOfFilteredActivities, setNumberOfFilteredActivities] =
    useState<number>(0);

  const abortControllerRef = useRef<AbortController>();

  const activitiesToShow = useMemo(
    () =>
      activities?.filter(activity =>
        facilityId ? activity.facilityId === facilityId : true,
      ),
    [activities, facilityId],
  );

  useEffect(() => {
    const abortController = new AbortController();
    const fetchActivities = async (filters: Filters) => {
      try {
        setIsLoading(true);

        const response = await getFacilityActivitiesOverview(
          filters.page,
          filters.pageSize,
          facilityId,
          abortController.signal,
        );
        if (abortController.signal.aborted) return;
        const data = response?.data;
        const count = response?.count;

        if (data) {
          setNumberOfFilteredActivities(count);
          setActivities(sortSeries(data, currentUserId));
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    };

    fetchActivities(filters);

    return () => abortController.abort();
  }, [filters, facilityId, currentUserId]);

  const handlePageChange = (event: PaginatorPageState) => {
    setFilters(prevState => ({
      ...prevState,
      page: event.page,
      pageSize: event.rows,
    }));
  };

  const WrapperComponent = isSpecific ? Div : Wrapper;

  return (
    <>
      <WrapperComponent>
        {!isSpecific && (
          <Filter>
            <ActivitiesFilters
              filters={filters}
              setFilters={setFilters}
              abortControllerRef={abortControllerRef}
            />
          </Filter>
        )}
        {isLoading ? (
          <ProgressSpinner />
        ) : !activitiesToShow?.length ? (
          <NoActivitiesFoundWrapper>
            <FormattedMessage
              id="activities.search.no-results"
              defaultMessage="Inga aktiviteter hittades"
            />
          </NoActivitiesFoundWrapper>
        ) : (
          <>
            <Items>
              {activitiesToShow?.map(activity => (
                <ActivityInfoCard
                  key={activity.id}
                  activity={activity}
                  showLogo
                />
              ))}
            </Items>
            {!numberOfFilteredActivities && (
              <NoActivitiesFoundWrapper>
                <FormattedMessage
                  id="activities.search.no-results"
                  defaultMessage="Inga aktiviteter hittades"
                />
              </NoActivitiesFoundWrapper>
            )}
            {numberOfFilteredActivities > filters.pageSize && (
              <Paginator
                first={filters.page * filters.pageSize}
                rows={filters.pageSize}
                totalRecords={numberOfFilteredActivities}
                rowsPerPageOptions={[4, 8, 12]}
                onPageChange={handlePageChange}
              />
            )}
          </>
        )}
      </WrapperComponent>
    </>
  );
};

export default ActivitiesList;
