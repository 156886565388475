import { FormattedMessage } from "react-intl";

import { SeriesMatch } from "../../../../../../../modules/game/models/Series";

import { useDateFormat } from "../../../../../../../hooks/useDateFormat";

import { MatchListItem } from "./components/MatchListItem";

interface Props {
  serieId: string;
  filtered: boolean;
  matches?: SeriesMatch[];
}

export const MatchList = ({ serieId, filtered, matches }: Props) => {
  const { df } = useDateFormat(matches?.[0]?.facilityId);

  // No matches exist
  if (!matches?.length && !filtered) {
    return <FormattedMessage id="series.matches.none" />;
  }

  const sortedMatches = Object.entries<SeriesMatch[]>(
    matches?.reduce((list, match) => {
      const date = df(match.startTime, {
        weekday: "long",
        day: "numeric",
        month: "long",
      });

      if (!list[date]) {
        list[date] = [match];
      } else {
        list[date].push(match);
      }

      return list;
    }, {}),
  );

  return sortedMatches?.length ? (
    <ol>
      {sortedMatches?.map((dateEntry, index) => (
        <li key={index}>
          <h2 className="my-10 text-xl">{dateEntry?.[0]}</h2>
          <ul>
            {dateEntry?.[1].map(match => {
              return (
                <MatchListItem
                  key={match?.id}
                  className="my-5"
                  match={match}
                  link={`/admin/activities/serie/${serieId}/match/${match?.id}`}
                />
              );
            })}
          </ul>
        </li>
      ))}
    </ol>
  ) : (
    // No results matching filter
    <FormattedMessage id="common.no-data-to-show" />
  );
};
