import { GeneralActivities } from "../../../../../../../../modules/game/models/GeneralActivities";

import { useToaster } from "../../../../../../../../hooks/common/useToaster";

import { sendMessageToEventParticipants } from "../../../../../../../../modules/game/services/GeneralActivities";

import { MessageEditor } from "../../../../../../../../components/MessageEditor";

interface Props {
  eventId: string;
  isSelectedAll: boolean;
  recipientList: GeneralActivities["registratedUsers"][number]["id"][];
  onComplete: () => void;
}

export const EventMessageEditor = ({
  eventId,
  isSelectedAll,
  recipientList,
  onComplete,
}: Props) => {
  const { toastError, toastSuccess } = useToaster();
  const handleSubmit = async (data: { subject: string; content: string }) => {
    try {
      await sendMessageToEventParticipants(eventId, {
        ...data,
        isSelectedAll,
        participantList: recipientList,
      });
      toastSuccess.message("toast.success.division.email.sent");
    } catch (error) {
      console.error(error);
      toastError.message("toast.error.update.generic");
    } finally {
      onComplete();
    }
  };

  return <MessageEditor onSubmit={handleSubmit} />;
};
