import { FormattedMessage } from "react-intl";

import type { User } from "../../../../modules/player/models/User";

import { useBackupPaymentMethod } from "../../../../hooks/swr/useBackupPaymentMethod";

import { CreditCardCard } from "../../../../components/CreditCardCard";

import { useSelectedFacilityId } from "../../../../recoil/selectedFacilityIdState";

export const BackupPaymentMethod = ({ userId }: { userId: User["id"] }) => {
  const selectedFacilityId = useSelectedFacilityId();
  const { backupPaymentMethod, isLoading, error } = useBackupPaymentMethod(
    selectedFacilityId,
    userId,
  );

  if (isLoading || error) {
    return null;
  }

  if (!backupPaymentMethod) {
    return (
      <p>
        <FormattedMessage id="card-details.no-card" />
      </p>
    );
  }

  return (
    <div>
      <CreditCardCard
        cardBrand={backupPaymentMethod.cardBrand}
        pan={backupPaymentMethod.maskedPan}
        expiryDate={backupPaymentMethod.expiryDate}
      />
    </div>
  );
};
