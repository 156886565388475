import React, { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";

import { faCheck, faLock } from "@fortawesome/pro-light-svg-icons";
import styled from "styled-components";

import { breakpoints } from "../../../../appConstants/common";

import { User } from "../../../player/models/User";
import { BookingType } from "../../models/Booking";
import { MatchModel } from "../../models/Games";

import { useToaster } from "../../../../hooks/common/useToaster";

import {
  getGame,
  lockAndCalculateResults,
  updateGameMatches,
} from "../../../game/services/Games";

import { Button } from "../../../../components/Button";
import { ProgressSpinner } from "../../../../components/ProgressSpinner";

import { ClickableBox } from "./ClickableBox";
import { ConfirmFinalizeDialog } from "./ConfirmFinalizeDialog";
import { Match, MatchBox, initMatch } from "./MatchBox";

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5rem;

  @media (max-width: ${breakpoints.MOBILE}) {
    flex-direction: column;
  }
`;

const FlexPaddingBox = styled.div`
  flex-basis: calc(50% - (0.5rem / 2));
  flex-shrink: 0;

  display: flex;

  > div {
    flex: 1;
  }

  @media (min-width: ${breakpoints.MOBILE}) {
    margin-top: 1rem;

    &:nth-child(2n + 1) {
      height: 172.13px;
      flex-basis: 50%;
    }
  }
`;

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const mapMatchModel = (source: MatchModel, participants: User[]): Match => {
  const actualMatchUserCount = source.teams.reduce(
    (acc, curr) => acc + curr.users.length,
    0,
  );

  if (source.teams.length != 2) {
    source.teams[1] = {
      id: null,
      gameSets: source.teams[0].gameSets.map(set => ({
        score: 0,
        setNumber: set.setNumber,
        id: null,
      })),
      users: [],
    };
  }

  if (actualMatchUserCount != participants.length) {
    switch (participants.length) {
      case 1:
        source.teams[0].users = [participants[0].id];
        break;
      case 2:
        source.teams[0].users = [participants[0].id];
        source.teams[1].users = [participants[1].id];
        break;
      case 3:
        source.teams[0].users = [participants[0].id, participants[1].id];
        source.teams[1].users = [participants[2].id];
        break;
      case 4:
        source.teams[0].users = [participants[0].id, participants[1].id];
        source.teams[1].users = [participants[2].id, participants[3].id];
        break;
    }
  }

  const home = source.teams[0];
  const away = source.teams[1];

  return {
    matchId: source.id,
    teamIds: [home.id, away.id],
    players: [...home.users, ...away.users].map(userId =>
      participants.find(p => p.id == userId),
    ),
    sets: home.gameSets.map((curr, i) => ({
      id: curr.id,
      score: curr.score,
      opponentId: away.gameSets[i].id,
      opponentScore: away.gameSets[i].score,
    })),
  };
};

const mapMatch = (source: Match): MatchModel => {
  return {
    id: source.matchId,
    teams: [
      {
        id: source.teamIds[0] ?? null,
        users: source.players
          .slice(0, source.players.length > 2 ? 2 : 1)
          .map(player => player.id),
        gameSets: source.sets.map((set, i) => ({
          id: set.id,
          score: set.score,
          setNumber: i,
        })),
      },
      {
        id: source.teamIds[1] ?? null,
        users: source.players
          .slice(
            source.players.length > 2 ? 2 : 1,
            source.players.length > 2 ? 4 : 3,
          )
          .map(player => player.id),
        gameSets: source.sets.map((set, i) => ({
          id: set.opponentId,
          score: set.opponentScore,
          setNumber: i,
        })),
      },
    ],
  };
};

export interface ScoreManagerRules {
  minSets: number;
  maxSets: number;
  minMatches: number;
  maxMatches: number;
  lockedTeams: boolean;
}

const seriesRules: ScoreManagerRules = {
  minSets: 3,
  maxSets: 3,
  minMatches: 1,
  maxMatches: 1,
  lockedTeams: true,
};

const commonRules: ScoreManagerRules = {
  minSets: null,
  maxSets: null,
  minMatches: null,
  maxMatches: null,
  lockedTeams: false,
};

interface ScoreManagerProps {
  matchId: string;
  isAdminRegister?: boolean;
  onHasUnsavedChanges: (hasUnsavedChanges: boolean) => void;
  onLocked: () => void;
}

export const ScoreManager: React.FC<ScoreManagerProps> = ({
  matchId,
  isAdminRegister,
  onHasUnsavedChanges,
  onLocked,
}) => {
  useEffect(() => {
    fetchGame();
  }, [matchId]);

  const fetchGame = async () => {
    setLoading(true);
    const { data } = await getGame(matchId);
    const { participants, games, bookingType } = data;
    const matches = games.map(match => mapMatchModel(match, participants));

    if (games.length > 0 && games[0].finished) {
      setSetWins({
        homeTeam: games[0].teams[0].calculatedSetWins,
        awayTeam: games[0].teams[1].calculatedSetWins,
      });
      setScore({
        homeTeam: games[0].teams[0].calculatedPoints,
        awayTeam: games[0].teams[1].calculatedPoints,
      });
    }

    setParticipants(participants);
    setMatches(matches);
    setBookingType(bookingType);
    setLastSavedMatches(matches);
    setLoading(false);
    setLocked(games[0]?.finished ?? false);
  };

  const [setWins, setSetWins] = useState<any>({
    homeTeam: 0,
    awayTeam: 0,
  });
  const [score, setScore] = useState<any>({
    homeTeam: 0,
    awayTeam: 0,
  });
  const [participants, setParticipants] = useState<User[]>([]);
  const [matches, setMatches] = useState<Match[]>([]);
  const [lastSavedMatches, setLastSavedMatches] = useState<Match[]>([]);
  const [isChanged, setChanged] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [bookingType, setBookingType] = useState<BookingType>();
  const [showConfirmFinalizeDialog, setShowConfirmFinalizeDialog] =
    useState<boolean>(false);
  const [isLocked, setLocked] = useState<boolean>(false);

  const rules = useMemo(() => {
    switch (bookingType) {
      case BookingType.Series:
        return seriesRules;
      default:
        return commonRules;
    }
  }, [bookingType]);

  const canAddMatch = useMemo(() => {
    return (
      (!isLocked && rules.maxMatches == null) ||
      matches.length < rules.maxMatches
    );
  }, [matches]);

  const canRemoveMatch = useMemo(() => {
    return (
      (!isLocked && rules.minMatches == null) ||
      matches.length > rules.minMatches
    );
  }, [matches]);

  const saveScore = async () => {
    await updateGameMatches({
      gameId: matchId,
      matches: matches.map(mapMatch),
    });
    setLastSavedMatches(matches);
  };

  const { toastError } = useToaster();

  const finalizeScore = async () => {
    try {
      const { data: success } = await lockAndCalculateResults(matchId);

      if (success) {
        onLocked();
        fetchGame();
      }
    } catch {
      toastError.finalizeScoreFailed();
    }
  };

  useEffect(() => {
    setChanged(JSON.stringify(lastSavedMatches) != JSON.stringify(matches));
  }, [lastSavedMatches, matches]);

  useEffect(() => {
    onHasUnsavedChanges?.(isChanged);
  }, [isChanged]);

  const addMatch = () => {
    if (!canAddMatch) {
      return;
    }
    setMatches([...matches, initMatch(participants)]);
  };

  const removeMatch = index => {
    if (!canRemoveMatch) {
      return;
    }
    setMatches([...matches.slice(0, index), ...matches.slice(index + 1)]);
  };

  const updateMatch = (index, match) =>
    setMatches(
      matches.reduce((acc, curr, ix) => {
        if (index == ix) {
          return [...acc, match];
        }
        return [...acc, curr];
      }, []),
    );

  const intl = useIntl();

  const handleFinalize = async () => {
    if (isAdminRegister) {
      await saveScore();
      return await finalizeScore();
    }
    setShowConfirmFinalizeDialog(true);
  };

  return (
    <>
      <Column>
        {isLocked ? (
          <Header
            title={intl.formatMessage({
              id: "score.register-title.locked",
              defaultMessage: "Resultat",
            })}
            subtitle={
              <div
                style={{
                  textAlign: "center",
                }}
              >
                <span>{`${setWins.homeTeam} - ${setWins.awayTeam} i set`}</span>
                <br />
                <span>{`${score.homeTeam} - ${score.awayTeam} i poäng`}</span>
              </div>
            }
          />
        ) : (
          <Header
            title={intl.formatMessage({
              id: "score.register-title",
              defaultMessage: "Registrera resultat",
            })}
            subtitle={intl.formatMessage({
              id: "score.register-subtitle",
              defaultMessage: "Ange poängen per match och set.",
            })}
          />
        )}
        <Row>
          {isLoading ? (
            <ProgressSpinner />
          ) : (
            <>
              {matches.map((match, index) => (
                <MatchBox
                  key={"match-" + index}
                  readonly={isLocked && !isAdminRegister}
                  initialMatch={match}
                  rules={rules}
                  label={{
                    id: "score.match",
                    defaultMessage: "Match {matchNumber}",
                  }}
                  matchNumber={index + 1}
                  onValueChange={match => updateMatch(index, match)}
                  onRemoveClick={
                    canRemoveMatch ? () => removeMatch(index) : null
                  }
                />
              ))}
              {canAddMatch && (
                <FlexPaddingBox>
                  <ClickableBox
                    label={{
                      id: "score.add-match",
                      defaultMessage: "Lägg till match",
                    }}
                    onClick={() => addMatch()}
                  />
                </FlexPaddingBox>
              )}
            </>
          )}
        </Row>
        {(!isLocked || isAdminRegister) && (
          <ButtonRow>
            <Button
              type="default"
              translationName="score.finalize"
              text="Slutför registrering"
              icon={faLock}
              disabled={isChanged && !isAdminRegister}
              onClick={handleFinalize}
            />
            {!isAdminRegister && (
              <Button
                type="primary"
                translationName="score.save"
                text="Spara resultat"
                icon={faCheck}
                disabled={!isChanged}
                onClick={saveScore}
              />
            )}
          </ButtonRow>
        )}
      </Column>
      <ConfirmFinalizeDialog
        visible={showConfirmFinalizeDialog}
        onConfirmClick={() => {
          setShowConfirmFinalizeDialog(false);
          finalizeScore();
        }}
        onHide={() => setShowConfirmFinalizeDialog(false)}
        onCancelClick={() => setShowConfirmFinalizeDialog(false)}
      />
    </>
  );
};

interface IHeaderProps {
  title: string;
  subtitle?: React.ReactNode;
}

const Title = styled.h3`
  color: black;
`;
const Subtitle = styled.p`
  color: var(--gray-400);
  font-family: var(--semibold);
  font-size: var(--b3);
`;
const Centered = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Header: React.FC<IHeaderProps> = ({ title, subtitle }) => {
  return (
    <Centered>
      <Title>{title}</Title>
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
    </Centered>
  );
};
