import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import {
  adminGetActivitesCreateEventPath,
  adminGetMobileActivitiesPath,
  adminGetMobileBookingPath,
  adminGetMobileBookingsPath,
  adminGetMobileDashboardPath,
  adminGetPlayersPath,
} from "../../../helpers/pathHelpers";

import { useHasFacilityOwnerPermission } from "../../../hooks/permissions";

import { Button } from "../../../components/Button";
import FacilitySelect from "../../../modules/customer/components/FacilitySelect";

const links = [
  {
    link: adminGetMobileBookingPath(),
    translation: "navigation.menuitem.booking",
  },
  {
    link: adminGetMobileBookingsPath(),
    translation: "admin.booking.change",
  },
  {
    link: adminGetActivitesCreateEventPath(),
    translation: "admin.activities.event.create",
  },
  {
    link: adminGetMobileActivitiesPath(),
    translation: "admin.manage.activities",
  },
  {
    link: adminGetPlayersPath(),
    translation: "admin.players-overview",
  },
];

const ownerLinks = [
  ...links,
  {
    link: adminGetMobileDashboardPath(),
    translation: "common.statistics",
  },
];

export const AdminMobilePage: React.FC = () => {
  const { hasFacilityOwnerPermission } = useHasFacilityOwnerPermission();
  const menuLinks = hasFacilityOwnerPermission ? ownerLinks : links;
  return (
    <div className="space-y-2 bg-purewhite p-8">
      <div className="pb-5">
        <FacilitySelect />
      </div>

      {menuLinks.map(link => (
        <div key={link.translation}>
          <Link to={link.link}>
            <Button className="w-full rounded-md border border-gray-50 bg-gradient-to-r from-purewhite to-white p-2 text-black">
              <FormattedMessage id={link.translation} />
            </Button>
          </Link>
        </div>
      ))}
    </div>
  );
};
