import { useMemo } from "react";

import { Slot, WeekDays } from "../../../models/Pricing";

export const useSelectedPriceSlots = (
  row: number,
  categoryId: string,
  start: number,
  end: number,
  schedules: WeekDays[],
) =>
  useMemo(() => {
    if (start === -1 || end === -1) {
      return [];
    }

    const result: Slot[] = [];

    schedules?.forEach((day, index) => {
      day?.slots?.forEach((slot, subIndex) => {
        if (
          index === row &&
          ((end <= subIndex && subIndex <= start) ||
            (start <= subIndex && subIndex <= end))
        ) {
          slot.categoryId = categoryId;
          result.push(slot);
        }
      });
    });
    return result;
  }, [row, start, end, schedules]);
