import { User } from "../../../modules/player/models/User";

export interface Payment {
  id: string;
  paymentMethod: string;
  status: PaymentStatus;
  paymentUrl: string;
  qrCodeSvgData: string;
  userPaymentInfoExists: boolean;
  totalPriceIncTax: number;
  splitPaymentInfo: SplitPaymentInfo;
  userId: string;
}

export enum PaymentStatus {
  Draft = 1,
  Failed = 2,
  FullyCompleted = 4,
  Refunded = 5,
  TimedOut = 6,
  Canceled = 7,
  PendingSplitPayment = 8,
  MainSplitPaymentCompleted = 9,
  Ready = 10,
}

export interface SplitPaymentSelection {
  payers: User[];
  isValid: boolean;
  isActive: boolean;
}

export interface SplitPaymentInfo {
  splitUserIds: string[];
  isSplitPayment: boolean;
  splittedPriceIncTax: number;
}

export interface SplitPaymentPossible {
  isSplitPaymentPossible: boolean;
}

export interface SplitPaymentPossibleRequest {
  externalId: string;
  buyableType: BuyableType;
  userIds: string[];
}

export interface WalletInfo {
  walletCurrencyId: string;
}

export interface PaymentInfo {
  splitPayment?: SplitPaymentInfo;
  wallet?: WalletInfo;
  prefilledInformationIsUsed?: boolean;
}

export enum BuyableType {
  Booking = 1,
  Series = 2,
  Coins = 3,
  Event = 4,
  Membership = 5,
}

export enum PaymentMethodName {
  Swish = "Swish",
  Card = "Card",
  Coins = "Coins",
  External = "External",
  CronaPos = "CronaPos",
}
