import { DateTime } from "luxon";

import { DateOnly } from "../../../models/DateOnly";

export interface AccessTime {
  from: DateTime;
  to: DateTime;
}

interface AccessTimeApiResponse extends Omit<AccessTime, "from" | "to"> {
  from: string;
  to: string;
}

export interface Access {
  id: string;
  time: AccessTime;
  locked: boolean;
}

export interface AccessApiResponse extends Omit<Access, "time"> {
  time: AccessTimeApiResponse;
}

export interface DefaultVendorAccess {
  id: string;
  pinCode: string;
  validFrom: DateOnly;
  validTo: DateOnly | null;
}

export interface DefaultVendorAccessResponse
  extends Omit<DefaultVendorAccess, "validFrom" | "validTo"> {
  validFrom: string;
  validTo: string;
}

export enum VendorType {
  None = 0,
  Default = 1,
  QT = 2,
}
