import { FormattedMessage } from "react-intl";

import {
  faCircleArrowDown,
  faCircleArrowRight,
  faCircleArrowUp,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { SeriesTeam } from "../../../../modules/game/models/Series";

import { ProfileImageWithFallback } from "../../../../components/ProfileImageWithFallback";

interface TeamCardProps {
  team: SeriesTeam;
}

export const TeamCard: React.FC<TeamCardProps> = ({ team }) => {
  const result = team?.divisionResult;

  return (
    <div className="-mx-6 sm:mx-0">
      <div className="h-4 bg-gradient-to-b from-pureblack/10 to-white sm:bg-none"></div>
      <div className="flex w-full bg-white py-9 pl-6 sm:items-center sm:rounded-full sm:p-0">
        <div className="flex">
          <ProfileImageWithFallback
            src={team.teamMembers[0]?.profileImage}
            firstName={team.teamMembers[0]?.firstName}
            lastName={team.teamMembers[0]?.lastName}
            css="border: 1px solid #000000"
            width="5rem"
            height="5rem"
          />
          <ProfileImageWithFallback
            src={team.teamMembers[1]?.profileImage}
            firstName={team.teamMembers[1]?.firstName}
            lastName={team.teamMembers[1]?.lastName}
            css="border: 1px solid #000000; margin-left:-1.5rem"
            width="5rem"
            height="5rem"
          />
        </div>
        <div className="ml-5 flex w-full flex-col sm:flex-row sm:items-center">
          <p className="mr-2 w-max text-base sm:mr-6 lg:text-2xl">
            <FormattedMessage
              id="series.total.points"
              values={{
                points: result?.totalPoints,
              }}
            />
          </p>
          <p className="text-xs text-gray-700">
            <FormattedMessage
              id="series.placement"
              values={{
                placement: result?.position,
                totalPlacements: result?.totalTeams,
              }}
            />
          </p>
          <div className="mt-2 flex flex-row-reverse justify-end sm:ml-auto sm:mr-4 sm:mt-0 sm:flex-row sm:items-center">
            {result?.movingUp ? (
              <>
                <FontAwesomeIcon
                  className="ml-2 text-green-700 sm:ml-0 sm:mr-2"
                  icon={faCircleArrowUp}
                />
                <p className="text-xs text-green-700">
                  <FormattedMessage id="series.new.season.moving.up" />
                </p>
              </>
            ) : result?.movingDown ? (
              <>
                <FontAwesomeIcon
                  className="ml-2 text-red-600 sm:ml-0 sm:mr-2"
                  icon={faCircleArrowDown}
                />
                <p className="text-xs text-red-600">
                  <FormattedMessage id="series.new.season.moving.down" />
                </p>
              </>
            ) : (
              <>
                <FontAwesomeIcon
                  className="ml-2 text-gray-700 sm:ml-0 sm:mr-2"
                  icon={faCircleArrowRight}
                />
                <p className="text-xs text-gray-700">
                  <FormattedMessage id="series.new.season.staying" />
                </p>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="h-4 bg-gradient-to-t from-pureblack/10 to-white sm:bg-none"></div>
    </div>
  );
};
