import styled from "styled-components";

interface StyleProps {
  $isFirst?: boolean;
}

export const BlockContainer = styled.div<StyleProps>`
  margin-top: ${props => (props.$isFirst ? 0 : "1.25rem")};
  display: flex;
  flex-direction: column;
  gap: 1rem;

  h3 {
    color: var(--primary);
  }
`;
