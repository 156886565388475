import { useCallback, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { toast } from "react-toastify";

import {
  faBadminton,
  faEllipsisVertical,
  faFutbol,
  faGolfBall,
  faLockKeyhole,
  faPickleball,
  faRacquet,
  faTableTennis,
  faUser,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown } from "@mui/base/Dropdown";
import { MenuButton } from "@mui/base/MenuButton";
import clsx from "clsx";
import { DateTime } from "luxon";
import { Tooltip } from "primereact/tooltip";

import type { BookableEntity } from "../../../../../customer/models/Facility";
import {
  BookingType,
  type InitialAdminCalendarBookingData,
} from "../../../../models/Booking";
import { IScheduleSlot } from "../../../../models/Calendar";

import { useFacility } from "../../../../../../hooks/swr/useFacility";
import { useBookedSlots } from "../../../../hooks/calendar/useBookedSlots";
import { useAdminFilteredSchedules } from "../../../../hooks/calendar/useSchedules";
import { useSelectableCalendar } from "../../../../hooks/calendar/useSelectableCalendar";

import { updateBookableEntity } from "../../../../../customer/services/FacilityService";

import { Menu, MenuItem } from "../../../../../../components/Menu";
import { SportTypeTranslatedName } from "../../../../../../components/SportTypeTranslatedName";
import { PadelIcon } from "../../../../../../components/icons/PadelIcon";
import { TennisIcon } from "../../../../../../components/icons/TennisIcon";

import { isSlotLateToBook } from "../../utils";
import { useAdminCalendarContextState } from "../AdminCalendarContext";
import AdminCalendarSlot from "./AdminCalendarSlot";

interface Props {
  courtId?: string;
  startTime?: DateTime;
  endTime?: DateTime;
  onSelect: (payload: InitialAdminCalendarBookingData) => void;
  onBookingClick: (bookingId: string) => void;
}

export const AdminCalendarBody = ({
  courtId,
  startTime,
  endTime,
  onSelect,
  onBookingClick,
}: Props) => {
  const {
    facilityId,
    selectedBookingTypes,
    bookingBeingRescheduled,
    filterUnPaid,
  } = useAdminCalendarContextState();
  const { schedules } = useAdminFilteredSchedules();
  const { facility, mutate: mutateFacility } = useFacility(facilityId);

  const {
    state: { row, selecting },
    beginSelection,
    updateSelection,
    endSelection,
    selectedSlots,
    resetSelection,
  } = useSelectableCalendar({
    schedules,
    courtId,
    startTime,
    endTime,
    isAdmin: true,
    facility,
    nrOfSlotsToReschedule: bookingBeingRescheduled
      ? bookingBeingRescheduled?.endTime.diff(
          bookingBeingRescheduled?.startTime,
          "minutes",
        ).minutes / 30
      : 0,
    selectedBookingId: bookingBeingRescheduled?.id,
  });

  const bookedSlots = useBookedSlots(schedules);

  useEffect(() => {
    if (selecting || selectedSlots.length === 0) {
      return;
    }

    const scheduleRow = schedules[row];

    // Check if the court has enough capacity for the booking
    if (
      bookingBeingRescheduled &&
      scheduleRow.objectType.defaultNumberOfPlayers <
        bookingBeingRescheduled.participants.length
    ) {
      toast.error(
        <FormattedMessage id="admin.calendar.reschedule.too-many-participants-for-new-court" />,
      );
      resetSelection();
      return;
    }

    onSelect({
      facilityId: scheduleRow.facilityId,
      courtId: scheduleRow.objectId,
      startTime: selectedSlots[0].startTime,
      endTime: selectedSlots[selectedSlots.length - 1].endTime,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selecting, row]);

  const isSlotSelected = (rowIndex: number, slot: IScheduleSlot) =>
    row === rowIndex &&
    selectedSlots.length > 0 &&
    selectedSlots[0].startTime === slot.startTime &&
    selectedSlots[0].endTime === slot.endTime;

  const updateCourt = useCallback(
    async (bookableEntity: BookableEntity) => {
      const updatedFacility = await updateBookableEntity(facilityId, {
        bookableEntities: [bookableEntity],
      }).then(response => response.data);

      mutateFacility(updatedFacility, false);
    },
    [facilityId, mutateFacility],
  );

  const openCourt = useCallback(
    async (bookableEntity: BookableEntity) => {
      bookableEntity.isHidden = false;

      updateCourt(bookableEntity);
    },
    [updateCourt],
  );

  const closeCourt = useCallback(
    (bookableEntity: BookableEntity) => {
      bookableEntity.isHidden = true;

      updateCourt(bookableEntity);
    },
    [updateCourt],
  );

  return (
    <>
      {schedules.map((court, rowIndex) => {
        const bookableEntity = facility?.bookableEntities?.find(
          entity => entity.id === court.objectId,
        );

        const tooltipId = `tooltip-${court.objectId}`;

        return (
          <div
            className={clsx(
              "flex h-[84px] border-gray-50",
              rowIndex !== 0 && "border-t",
            )}
            key={court.objectId}
          >
            <div className="relative w-[112px] flex-none py-2 pr-2 text-xs">
              <div
                className={clsx(
                  "mb-1.5 min-w-0 truncate text-sm font-semibold",
                  bookableEntity?.isHidden && "text-gray-700 line-through",
                )}
              >
                {court?.objectName}
              </div>

              {bookableEntity?.isHidden ? (
                <>
                  <span id={tooltipId}>
                    <FontAwesomeIcon
                      icon={faLockKeyhole}
                      size="xs"
                      className="mr-1 inline-block"
                    />
                    <FormattedMessage id="admin.courts.closed" />
                  </span>

                  <Tooltip position="right" target={`#${tooltipId}`}>
                    <FormattedMessage id="common.this-court-is-closed" />
                  </Tooltip>
                </>
              ) : (
                <>
                  <div>
                    <FontAwesomeIcon
                      icon={faUser}
                      size="sm"
                      className="mr-1 inline-block w-3.5"
                    />
                    {court?.objectType?.defaultNumberOfPlayers}
                  </div>
                  <div className="mt-1 truncate">
                    {court.objectType.sportType.name === "Padel" ? (
                      <PadelIcon className="mr-1 inline-block w-3.5" />
                    ) : court.objectType.sportType.name === "Tennis" ? (
                      <TennisIcon className="mr-1 inline-block w-3.5" />
                    ) : court.objectType.sportType.name === "Pickleball" ? (
                      <FontAwesomeIcon
                        icon={faPickleball}
                        size="sm"
                        className="mr-1 inline-block"
                      />
                    ) : court.objectType.sportType.name === "Fotbollsgolf" ? (
                      <FontAwesomeIcon
                        icon={faFutbol}
                        size="sm"
                        className="mr-1 inline-block"
                      />
                    ) : court.objectType.sportType.name === "Golf" ? (
                      <FontAwesomeIcon
                        icon={faGolfBall}
                        size="sm"
                        className="mr-1 inline-block"
                      />
                    ) : court.objectType.sportType.name === "Badminton" ? (
                      <FontAwesomeIcon
                        icon={faBadminton}
                        size="sm"
                        className="mr-1 inline-block"
                      />
                    ) : court.objectType.sportType.name === "Squash" ? (
                      <FontAwesomeIcon
                        icon={faRacquet}
                        size="sm"
                        className="mr-1 inline-block"
                      />
                    ) : court.objectType.sportType.name === "Bordtennis" ? (
                      <FontAwesomeIcon
                        icon={faTableTennis}
                        size="sm"
                        className="mr-1 inline-block"
                      />
                    ) : null}
                    <SportTypeTranslatedName
                      sportType={court.objectType.sportType}
                    />
                  </div>
                </>
              )}

              {bookableEntity && (
                <Dropdown>
                  <MenuButton className="absolute bottom-1/2 right-1 translate-y-1/2 px-1 text-md">
                    <FontAwesomeIcon icon={faEllipsisVertical} />
                  </MenuButton>
                  <Menu>
                    {bookableEntity?.isHidden ? (
                      <MenuItem onClick={() => openCourt(bookableEntity)}>
                        <FormattedMessage id="admin.calendar.action.open-court" />
                      </MenuItem>
                    ) : (
                      <MenuItem onClick={() => closeCourt(bookableEntity)}>
                        <FormattedMessage id="admin.calendar.action.close-court" />
                      </MenuItem>
                    )}
                  </Menu>
                </Dropdown>
              )}
            </div>
            <div
              className={clsx(
                "flex flex-grow border-l border-r border-gray-50",
                rowIndex === 0 && "rounded-tl rounded-tr border-t",
                rowIndex === schedules.length - 1 &&
                  "rounded-bl rounded-br border-b",
              )}
              data-row={rowIndex}
            >
              {court.slots.map((slot, slotIndex) => (
                <AdminCalendarSlot
                  key={`${rowIndex}-${slotIndex}`}
                  slot={slot}
                  row={rowIndex}
                  column={slotIndex}
                  widthCoefficient={
                    bookedSlots.get(`${rowIndex}-${slotIndex}`) ||
                    selectedSlots.length
                  }
                  isBooked={bookedSlots.has(`${rowIndex}-${slotIndex}`)}
                  isLateToBook={isSlotLateToBook(slot)}
                  isSelected={isSlotSelected(rowIndex, slot)}
                  isBlurred={
                    bookingBeingRescheduled
                      ? bookingBeingRescheduled.id !== slot.bookingId
                      : slot.bookingType !== null &&
                          selectedBookingTypes?.length
                        ? !selectedBookingTypes?.includes(slot.bookingType)
                        : filterUnPaid
                          ? slot.bookingType === null ||
                            ![
                              BookingType.Regular,
                              BookingType.Recurring,
                              BookingType.Open,
                            ].includes(slot.bookingType) ||
                            !!slot.isPaid
                          : false
                  }
                  startTime={selectedSlots[0]?.startTime}
                  endTime={selectedSlots[selectedSlots.length - 1]?.endTime}
                  onBookedSlotClick={onBookingClick}
                  onBeginSelection={beginSelection}
                  onEndSelection={endSelection}
                  onUpdateSelection={updateSelection}
                  lastBookedSlot={
                    bookedSlots.has(`${rowIndex}-${slotIndex}`)
                      ? court.slots.at(
                          slotIndex +
                            (bookedSlots.get(`${rowIndex}-${slotIndex}`) ?? 0) -
                            1,
                        )
                      : undefined
                  }
                  isBeingRescheduled={
                    bookingBeingRescheduled?.id === slot.bookingId
                  }
                />
              ))}
            </div>
          </div>
        );
      })}
    </>
  );
};
