import { faCheck } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { uniqueId } from "lodash";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  label?: string;
  inputId?: string;
}

export const Checkbox = ({ className, label, inputId, ...rest }: Props) => {
  const wrapperClasses = clsx("flex items-center gap-3", className);

  const checkmarkClasses = clsx(
    `flex h-5 w-5 items-center justify-center rounded-sm text-purewhite`,
    {
      "border border-gray-500": !rest.checked && rest.disabled,
      "border border-primary": !rest.checked && !rest.disabled,
      "bg-primary": rest.checked && !rest.disabled,
      "bg-gray-500": rest.disabled,
    },
  );

  const id = "id-" + (inputId ?? rest.name ?? uniqueId());

  return (
    <label id={rest.id} className={wrapperClasses} htmlFor={id}>
      <div className={checkmarkClasses}>
        {rest.checked && <FontAwesomeIcon icon={faCheck} />}

        <input id={id} type="checkbox" className="appearance-none" {...rest} />
      </div>

      {label && (
        <p className={clsx(rest.disabled && "text-gray-700")}>
          {label}
          {rest.required && <span className="text-error"> *</span>}
        </p>
      )}
    </label>
  );
};
