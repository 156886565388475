import { useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { DateTime } from "luxon";
import { Skeleton } from "primereact/skeleton";

import { Coin, CoinType } from "../../../../modules/player/models/Coins";

import { useToaster } from "../../../../hooks/common/useToaster";

import {
  addOrUpdateCoinsForFacilityByAdmin,
  getCoinsForFacilityByAdmin,
} from "../../../../modules/player/services/CoinsService";

import { Button } from "../../../../components/Button";
import { CalendarInput } from "../../../../components/CalendarInput";
import { NumberInput } from "../../../../components/NumberInput";
import { TextAreaInput } from "../../../../components/TextAreaInput";
import { CoinSelect } from "../../../../modules/player/components/admin/CoinSelect";

interface Props {
  userId: string;
  facilityId: string;
  setIsChangeInCoins: (isChangeInCoins: boolean) => void;
}

export const AdminAddCoinsForm = ({
  userId,
  facilityId,
  setIsChangeInCoins,
}: Props) => {
  const intl = useIntl();
  const [coins, setCoins] = useState<Coin[]>([]);
  const [currentCoin, setCurrentCoin] = useState<Coin>();

  const [id, setId] = useState<string>();
  const [amount, setAmount] = useState("");
  const [coinType, setCoinType] = useState<CoinType>(CoinType.COIN);
  const [comment, setComment] = useState("");
  const [validDate, setValidDate] = useState<DateTime>(
    DateTime.now().plus({ year: 1 }),
  );

  const [loading, setLoading] = useState(false);
  const coinsAbortController = useRef<AbortController>();
  const toaster = useToaster();

  const newCoinObject = {
    expires: DateTime.now().plus({ year: 1 }),
    type: 0,
    amount: 0,
    comment: "",
  };
  const newAllowanceObject = {
    expires: DateTime.now().plus({ year: 1 }),
    type: 1,
    amount: 0,
    comment: "",
  };

  useEffect(() => {
    if (userId && facilityId) {
      coinsAbortController.current = new AbortController();

      const fetchCoins = async (userId: string, facilityId: string) => {
        setLoading(true);
        try {
          const result = await getCoinsForFacilityByAdmin(
            facilityId,
            userId,
            coinsAbortController.current?.signal,
          );
          const c = result?.find(c => c.type === CoinType.COIN);
          if (!c) {
            setCoins([newCoinObject, ...result]);
            setCurrentCoin(newCoinObject);
          } else {
            setCoins(result);
            setCurrentCoin(c);
          }
        } catch {
          toaster.toastError.fetchCoinsFailed();
        } finally {
          setLoading(false);
        }
      };

      fetchCoins(userId, facilityId);
    }
  }, [userId, facilityId]);

  useEffect(() => {
    if (!currentCoin) {
      return;
    }

    setAmount(currentCoin.amount.toString());
    setComment(currentCoin.comment);
    setCoinType(currentCoin.type);
    setId(currentCoin.id);

    if (currentCoin.expires) {
      setValidDate(currentCoin.expires);
    }
  }, [currentCoin]);

  const validAmount = () => {
    return (
      amount.length > 0 &&
      Number.isFinite(+amount) &&
      !Number.isNaN(+amount) &&
      +amount >= 0
    );
  };

  const validComment = () => {
    return comment && comment.length > 0;
  };

  const validForm = () => {
    return validAmount() && validComment() && validDate && coinType?.toString();
  };

  const handleSubmitAddCoins = async () => {
    if (!validForm()) return;

    coinsAbortController.current = new AbortController();
    setLoading(true);
    try {
      const result = await addOrUpdateCoinsForFacilityByAdmin(
        facilityId,
        userId,
        {
          id,
          type: coinType,
          amount: +amount,
          expires: validDate,
          comment,
        },
        coinsAbortController.current?.signal,
      );

      if (result?.length) {
        toaster.toastSuccess.saveCoins();
        setIsChangeInCoins(true);

        const knownIds = coins?.map(c => c.id);
        // find the updated base don id on currentCoin, or find the new id
        const newCoin =
          result?.find(c => c.id === currentCoin?.id) ??
          result?.find(c => !knownIds?.includes(c?.id));
        result?.sort((a, b) => a?.type - b?.type);

        const c = result?.find(c => c.type === CoinType.COIN);
        if (!c) {
          setCoins([newCoinObject, ...result]);
        } else {
          setCoins(result);
        }
        setCurrentCoin(newCoin);
      }
    } catch (e) {
      console.log(e);
      toaster.toastError.saveCoinsFailed();
    } finally {
      setLoading(false);
    }
  };

  const typeToString = (type: number): string => {
    if (!Number.isFinite(type)) return "";

    const enums = [
      { key: "coin.type.coin", defaultMessage: "Coin" },
      { key: "coin.type.allowance", defaultMessage: "Friskvård" },
    ];

    return intl.formatMessage({
      id: enums[type]?.key,
      defaultMessage: enums[type]?.defaultMessage,
    });
  };

  const onChangeCoin = (value: string) => {
    if (value === "new") {
      return setCurrentCoin(newAllowanceObject);
    }

    const selectedCoin = coins.find(coin => coin.id === value);
    setCurrentCoin(selectedCoin);
  };

  return (
    <div className="flex flex-col gap-4">
      <h3>
        <FormattedMessage id="common.coins" defaultMessage="Coins" />
      </h3>

      <div className="grid grid-cols-2 gap-2 pb-4">
        {coins?.map((c, i) => (
          <div key={i}>
            <CoinSelect
              isActive={
                (currentCoin?.id && c?.id === currentCoin?.id) ||
                (currentCoin?.type === CoinType.COIN &&
                  c?.id === currentCoin?.id)
              }
              title={typeToString(c?.type)}
              amount={c?.amount}
              id={c?.id}
              onSelect={onChangeCoin}
            />
          </div>
        ))}
        <CoinSelect
          isActive={
            !currentCoin?.id && currentCoin?.type === CoinType.ALLOWANCE
          }
          title={intl.formatMessage({
            id: "admin.coins.add.allowance",
            defaultMessage: "Lägg till friskvård",
          })}
          id="new"
          onSelect={onChangeCoin}
        />
      </div>

      <div className="grid">
        <h6>
          <FormattedMessage
            id="admin.change.coin.amount"
            defaultMessage="Ändra antal coins"
          />
        </h6>
        {!validAmount() && (
          <small className="p-error p-block">
            <FormattedMessage
              id="form.error.amount"
              defaultMessage="Måste vara ett nummer"
            />
          </small>
        )}
        {loading ? (
          <Skeleton width="100%" height="2rem" />
        ) : (
          <NumberInput
            name="amount"
            value={amount}
            onChange={e => setAmount(e.target.value)}
          />
        )}
      </div>

      <div className="grid">
        <h6 style={{ fontSize: "1rem" }}>
          <FormattedMessage
            id="common.validuntilDate"
            defaultMessage="Giltig till och med"
          />
          *
        </h6>
        {!validDate && (
          <small className="p-error p-block">
            <FormattedMessage
              id="form.error.date"
              defaultMessage="Måste vara ett datum"
            />
          </small>
        )}
        {loading ? (
          <Skeleton width="100%" height="2rem" />
        ) : (
          <CalendarInput
            selectOtherMonths
            value={validDate}
            disabled={currentCoin?.isReadOnly}
            onChange={e => setValidDate(e.value)}
            maxDate={
              coinType === 1 ? DateTime.utc().plus({ year: 1 }) : undefined
            }
          />
        )}
      </div>
      <div className="grid">
        <label className="font-bold">
          <FormattedMessage id="common.comment" defaultMessage="Kommentar" />*
        </label>
        {!validComment() && (
          <small className="p-error p-block">
            <FormattedMessage
              id="form.error.comment"
              defaultMessage="Du måste ange en kommentar"
            />
          </small>
        )}
        {loading ? (
          <Skeleton width="100%" height="2rem" />
        ) : (
          <TextAreaInput
            value={comment || ""}
            onChange={e => setComment(e.target.value)}
          />
        )}
      </div>

      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          onClick={handleSubmitAddCoins}
          size="small"
          type="primary"
          text="Spara"
          translationName="button.save"
          disabled={!validForm()}
        />
      </div>
    </div>
  );
};
