import { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";

import { DateTime } from "luxon";

import { FacilityWithUtc } from "../../../customer/models/Facility";
import { BookingType } from "../../models/Booking";
import { IScheduleSlot } from "../../models/Calendar";
import { AvailableTimesParameters } from "../../models/schedule";

import { useToaster } from "../../../../hooks/common/useToaster";

import {
  getAvailableTimes,
  getAvailableTimesForAdmin,
} from "../../services/Schedule";

import { isSlotLateToBook } from "../../components/Calendar/utils";

export const useSelectableCalendar = ({
  schedules,
  courtId,
  startTime,
  endTime,
  isAdmin = false,
  bookingType,
  facility,
  nrOfSlotsToReschedule = 0,
  selectedBookingId,
}: {
  schedules: any[];
  courtId?: string;
  startTime?: DateTime;
  endTime?: DateTime;
  isAdmin?: boolean;
  bookingType?: BookingType;
  facility?: FacilityWithUtc;
  nrOfSlotsToReschedule?: number;
  selectedBookingId?: string;
}) => {
  const { formatMessage } = useIntl();
  const [courtIndex, setCourtIndex] = useState(-1);
  const [start, setStart] = useState(-1);
  const [end, setEnd] = useState(-1);
  const [selecting, setSelecting] = useState(false);
  const { toastWarning } = useToaster();

  useEffect(() => {
    if (!courtId && !startTime && !endTime) {
      resetSelection();
    }
  }, [courtId, startTime, endTime]);

  const resetSelection = useCallback(() => {
    setStart(-1);
    setEnd(-1);
    setCourtIndex(-1);
    setSelecting(false);
  }, []);

  const isSlotAvailable = (row: number, index: number) =>
    index > -1 &&
    schedules[row].slots.length > index &&
    (!schedules[row].slots[index].isBooked ||
      schedules[row].slots[index].bookingId === selectedBookingId) &&
    !isSlotLateToBook(schedules[row].slots[index]);

  const isPeriodBookable = async (
    start: number,
    end: number,
    row?: number,
  ): Promise<boolean> => {
    // start is bigger when dragging right to left (index weirdness)
    const startTime =
      end > start
        ? schedules[row]?.slots?.[start]?.startTime
        : schedules[row]?.slots?.[end]?.startTime;
    const endTime =
      end > start
        ? schedules[row]?.slots?.[end]?.endTime
        : schedules[row]?.slots?.[start]?.endTime;
    const data: AvailableTimesParameters = {
      courtId: schedules?.[row]?.objectId,
      facilityId: schedules?.[0]?.facilityId,
      bookingType: bookingType,
      intervals: [
        {
          startTime: startTime?.setZone(facility?.localization?.timeZone),
          endTime:
            endTime ??
            schedules[row]?.slots?.[start]?.endTime.setZone(
              facility?.localization?.timeZone,
            ),
        },
      ],
    };

    try {
      const res = isAdmin
        ? await getAvailableTimesForAdmin(data)
        : await getAvailableTimes(data);
      const errors = res?.data?.entries?.find(r => r?.error);
      if (errors) {
        return false;
      }
      return true;
    } catch (e) {
      console.log(e);
    }
  };

  const beginSelection = (
    row: number,
    index: number,
    noDrag?: boolean,
  ): void => {
    if (isSlotLateToBook(schedules[row].slots[index])) {
      toastWarning.message(
        formatMessage({ id: "admin.calendar.toast.too-late-to-book" }),
      );
      resetSelection();
      return;
    }

    if (!isSlotAvailable(row, index)) {
      resetSelection();
      return;
    }

    if (!noDrag) {
      setSelecting(true);
    }
    setCourtIndex(row);
    setStart(index);
    setEnd(index);
  };

  const updateSelection = (
    event: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>,
    row: number,
    index: number,
  ): void => {
    if (!event.type.includes("touch")) {
      event.preventDefault();
    }

    if (selecting) {
      const maxSlots =
        nrOfSlotsToReschedule ||
        schedules[row]?.objectType?.defaultMaxNumberOfSlots;
      const nrOfSelectedSlots = Math.abs(index - start);
      const maxReached = !isAdmin && maxSlots && nrOfSelectedSlots >= maxSlots;

      if (!isSlotAvailable(row, index) || row !== courtIndex || maxReached) {
        endSelection();

        return;
      }

      // This is important to avoid propagation to child elements
      if (event.target === event.currentTarget) {
        setEnd(index);
      }
    }
  };

  const endSelection = async (
    allowOverrideMinNumberOfSlots = false,
  ): Promise<void> => {
    const isSingleSlot =
      schedules[courtIndex]?.objectType?.defaultMinNumberOfSlots === 1;
    let localEnd = end;

    if (selecting) {
      setSelecting(false);

      if (isSingleSlot) {
        return;
      }

      // Only happens when clicking a single slot
      if (start === localEnd) {
        localEnd += nrOfSlotsToReschedule
          ? nrOfSlotsToReschedule - 1
          : allowOverrideMinNumberOfSlots
            ? 0
            : schedules[courtIndex]?.objectType?.defaultMinNumberOfSlots - 1;
      } else if (nrOfSlotsToReschedule) {
        // When rescheduling in admin calendar, we want to keep the same number of slots
        localEnd = start + nrOfSlotsToReschedule - 1;
      }

      if (isSlotAvailable(courtIndex, localEnd)) {
        setEnd(localEnd);
      } else {
        resetSelection();
        !isAdmin && toastWarning.selectionFailed();
        return;
      }

      if (await isPeriodBookable(start, localEnd, courtIndex)) {
        // Intentionally left empty
      } else {
        resetSelection();

        !isAdmin && toastWarning.selectionFailed();

        return;
      }
    }
  };

  const selectedSlots = useMemo(() => {
    if (start === -1 || end === -1) {
      return [];
    }

    const result = [];

    schedules.forEach((court, rowIndex) => {
      court.slots.forEach(
        (slot: IScheduleSlot | IScheduleSlot[], slotIndex: number) => {
          if (
            rowIndex === courtIndex &&
            ((end <= slotIndex && slotIndex <= start) ||
              (start <= slotIndex && slotIndex <= end))
          ) {
            result.push(slot);
          }
        },
      );
    });

    return result;
  }, [courtIndex, start, end, schedules]);

  useEffect(() => {
    resetSelection();
  }, [resetSelection, schedules, selectedBookingId]);

  return {
    state: { row: courtIndex, start, end, selecting },
    beginSelection,
    updateSelection,
    endSelection,
    resetSelection,
    selectedSlots,
  };
};
