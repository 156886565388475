import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { useFormik } from "formik";
import { type InferType, object, string } from "yup";

import { commonRegex } from "../../../../helpers/commonRegex";

import { useToaster } from "../../../../hooks/common/useToaster";

import { createFacility } from "../../../../modules/customer/services/FacilityService";

import { Button } from "../../../../components/Button";
import { TextInput } from "../../../../components/TextInput";
import { SelectInput } from "../../../../components/inputs/SelectInput";

export const NewFacilityForm = ({ onSuccess }: { onSuccess: () => void }) => {
  const intl = useIntl();

  const timeZoneOptions = useMemo(() => {
    return Intl.supportedValuesOf("timeZone").map(timeZone => ({
      value: timeZone,
      label: timeZone,
    }));
  }, []);

  const currencyOptions = useMemo(() => {
    return Intl.supportedValuesOf("currency").map(currency => ({
      value: currency,
      label: currency,
    }));
  }, []);

  const schema = object({
    name: string().required(),
    email: string().email().required(),

    address: object({
      street: string().required(),
      city: string().required(),
      postalCode: string()
        .required()
        .matches(
          commonRegex.postalCode,
          intl.formatMessage({
            id: "validation.invalid.postalCode",
          }),
        ),
      country: string().required(),
    }),

    localization: object({
      currencyCode: string().required(),
      timeZone: string()
        .required()
        .matches(/^[A-Za-z]+\/[A-Za-z_]+$/),
      locale: string()
        .required()
        .matches(/^[a-z]{2}(-[A-Z]{2})?$/),
    }),

    vendorType: string()
      .defined()
      .oneOf(["qt", "default", "none"] as const)
      .required(),

    paymentInformation: object({
      costCenter: string().required(),
      registrationNumber: string()
        .required()
        .matches(
          commonRegex.organizationsNumber,
          intl.formatMessage({
            id: "validation.invalid.org-number",
          }),
        ),
      invoiceEmail: string().email().required(),
    }),
  });

  type FormValues = InferType<typeof schema>;
  const toaster = useToaster();

  const formik = useFormik<FormValues>({
    validationSchema: schema,
    initialValues: {
      name: "",
      email: "",
      address: {
        street: "",
        city: "",
        postalCode: "",
        country: "",
      },

      localization: {
        currencyCode: "",
        timeZone: "",
        locale: "",
      },

      vendorType: "none",

      paymentInformation: {
        costCenter: "",
        registrationNumber: "",
        invoiceEmail: "",
      },
    },
    onSubmit: async formValues => {
      try {
        const result = await createFacility({
          ...formValues,
          localization: {
            currencyCode: formValues.localization.currencyCode,
            timeZone: formValues.localization.timeZone,
            locale: formValues.localization.locale,
          },
          address: {
            street: formValues.address.street || "",
            city: formValues.address.city || "",
            postalCode: formValues.address.postalCode || "",
            country: formValues.address.country || "",
            streetNumber: "",
          },
          paymentInformation: {
            registrationNumber:
              formValues.paymentInformation.registrationNumber,
            invoiceEmail: formValues.paymentInformation.invoiceEmail,
            costCenter: formValues.paymentInformation.costCenter,
            plusgiro: "",
            bankgiro: "",
          },
          isActive: false,
          isSameOpenHoursAllDays: true,
          openHours: [
            {
              id: "0",
              dayOfWeek: 0,
              startTime: "05:00:00",
              endTime: "22:00:00",
            },
            {
              id: "1",
              dayOfWeek: 1,
              startTime: "05:00:00",
              endTime: "22:00:00",
            },
            {
              id: "2",
              dayOfWeek: 2,
              startTime: "05:00:00",
              endTime: "22:00:00",
            },
            {
              id: "3",
              dayOfWeek: 3,
              startTime: "05:00:00",
              endTime: "22:00:00",
            },
            {
              id: "4",
              dayOfWeek: 4,
              startTime: "05:00:00",
              endTime: "22:00:00",
            },
            {
              id: "5",
              dayOfWeek: 5,
              startTime: "05:00:00",
              endTime: "22:00:00",
            },
            {
              id: "6",
              dayOfWeek: 6,
              startTime: "05:00:00",
              endTime: "22:00:00",
            },
          ],
        });

        if (result) {
          onSuccess();
        }

        toaster.toastSuccess.message("Facility created");
      } catch (err) {
        console.error(err);
        toaster.toastError.generalFailure();
      }
    },
  });

  return (
    <>
      <h3>
        <FormattedMessage id="facilities.add-new-venue" />
      </h3>

      <div className="mt-8">
        <form className="space-y-4" onSubmit={formik.handleSubmit}>
          <TextInput
            required
            name="name"
            label={intl.formatMessage({ id: "common.name" })}
            onChange={formik.handleChange}
            value={formik.values.name}
            error={formik.errors.name}
          />
          <TextInput
            required
            name="email"
            type="email"
            label={intl.formatMessage({ id: "common.email" })}
            onChange={formik.handleChange}
            value={formik.values.email}
            error={formik.errors.email}
          />

          <TextInput
            required
            name="address.street"
            label={intl.formatMessage({ id: "common.address.street" })}
            onChange={formik.handleChange}
            value={formik.values.address?.street}
            error={formik.errors.address?.street}
          />
          <TextInput
            required
            name="address.city"
            label={intl.formatMessage({ id: "common.address.city" })}
            onChange={formik.handleChange}
            value={formik.values.address?.city}
            error={formik.errors.address?.city}
          />
          <TextInput
            required
            name="address.postalCode"
            label={intl.formatMessage({ id: "common.address.postal-code" })}
            onChange={formik.handleChange}
            value={formik.values.address?.postalCode}
            error={formik.errors.address?.postalCode}
          />
          <TextInput
            required
            name="address.country"
            label={intl.formatMessage({ id: "common.address.country" })}
            onChange={formik.handleChange}
            value={formik.values.address?.country}
            error={formik.errors.address?.country}
          />

          <SelectInput
            required
            name="localization.currencyCode"
            label={intl.formatMessage({ id: "common.currency-code" })}
            onChange={formik.handleChange}
            value={formik.values.localization.currencyCode}
            options={currencyOptions}
            error={formik.errors.localization?.currencyCode}
          />

          <SelectInput
            required
            name="localization.timeZone"
            label={intl.formatMessage({ id: "common.time-zone" })}
            onChange={formik.handleChange}
            value={formik.values.localization.timeZone}
            options={timeZoneOptions}
            error={formik.errors.localization?.timeZone}
          />

          <div>
            <TextInput
              required
              name="localization.locale"
              label={intl.formatMessage({ id: "common.locale" })}
              onChange={formik.handleChange}
              value={formik.values.localization.locale}
              error={formik.errors.localization?.locale}
            />
            <span className="text-xs text-gray-700">
              <FormattedMessage id="common.example" />: sv-SE, en-US
            </span>
          </div>

          <SelectInput
            required
            name="vendorType"
            label={intl.formatMessage({ id: "common.vendor-type" })}
            onChange={formik.handleChange}
            value={formik.values.vendorType}
            options={[
              { value: "qt", label: "QT" },
              {
                value: "default",
                label: intl.formatMessage({ id: "vendor-type.other" }),
              },
              {
                value: "none",
                label: intl.formatMessage({ id: "vendor-type.none" }),
              },
            ]}
            error={formik.errors.vendorType}
          />

          <div>
            <TextInput
              required
              name="paymentInformation.costCenter"
              label={intl.formatMessage({ id: "common.cost-center" })}
              onChange={formik.handleChange}
              value={formik.values.paymentInformation?.costCenter}
              error={formik.errors.paymentInformation?.costCenter}
            />
            <span className="text-xs text-gray-700">
              setup in Fortnox beforehand
            </span>
          </div>

          <TextInput
            required
            name="paymentInformation.registrationNumber"
            label={intl.formatMessage({ id: "common.org-nr" })}
            onChange={formik.handleChange}
            value={formik.values.paymentInformation?.registrationNumber}
            error={formik.errors.paymentInformation?.registrationNumber}
          />

          <TextInput
            required
            name="paymentInformation.invoiceEmail"
            type="email"
            label={intl.formatMessage({
              id: "facility-settings.bookable.invoice-email",
            })}
            onChange={formik.handleChange}
            value={formik.values.paymentInformation?.invoiceEmail}
            error={formik.errors.paymentInformation?.invoiceEmail}
          />

          <div className="mt-4 flex justify-end">
            <Button
              buttonType="submit"
              type="primary"
              translationName="common.create"
              disabled={!formik.isValid || formik.isSubmitting}
            />
          </div>
        </form>
      </div>
    </>
  );
};
