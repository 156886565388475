import React, { useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import { faCalendar } from "@fortawesome/free-regular-svg-icons";
import { faBadgeCheck } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { DateOnly } from "../../../../../models/DateOnly";
import { FacilityWithUtc } from "../../../../customer/models/Facility";
import { BookingType } from "../../../models/Booking";
import { ICalendarPayload } from "../../../models/Calendar";

import { getVenuePath } from "../../../../../helpers/pathHelpers";

import { useSchedules } from "../../../hooks/calendar/useSchedules";

import { useIsAdminMode } from "../../../../../recoil/isAdminModeState";
import CustomerCalendarContent from "./CustomerCalendarContent";
import CustomerCalendarMenu from "./CustomerCalendarMenu";

interface IProps {
  initialDate?: DateOnly;
  maxDate?: DateOnly;
  minDate?: DateOnly;
  facility: FacilityWithUtc;
  initialPayload?: ICalendarPayload;
  onChange: (data: ICalendarPayload) => void;
  nrOfSlots?: number;
  bookingId?: string;
  reload?: boolean;
  bookingType?: BookingType;
  allowedWeekDays?: number[];
}

const CustomerCalendar: React.FC<IProps> = ({
  facility,
  initialDate,
  maxDate,
  minDate,
  initialPayload,
  onChange,
  nrOfSlots,
  bookingId,
  reload,
  bookingType,
  allowedWeekDays,
}) => {
  const isAdmin = useIsAdminMode();
  const maxDateCalculation = isAdmin
    ? { years: 2 }
    : {
        days: facility.facilityBookingRules?.numberOfDaysBookingDaysAhead || 14,
      };
  maxDate ||= DateOnly.today().plus(maxDateCalculation);

  const [selectedDate, setSelectedDate] = useState<DateOnly>(
    initialDate || DateOnly.today(),
  );

  const { schedules, isLoading, mutate } = useSchedules(
    selectedDate,
    facility.id,
    bookingId,
    isAdmin,
  );

  useEffect(() => {
    if (isLoading) {
      return;
    }

    mutate();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutate, reload]);

  useEffect(() => {
    if (selectedDate > maxDate) {
      setSelectedDate(maxDate);
    }
  }, [maxDate, selectedDate]);

  const noSlots = useMemo(() => {
    if (isLoading) {
      return false;
    }

    if (!schedules || schedules?.length === 0) {
      return true;
    }

    return !schedules.some(s => s.slots.length > 0);
  }, [isLoading, schedules]);

  return (
    <>
      <div>
        <div className="calendar-container">
          <div className="customer-calendar">
            <CustomerCalendarMenu
              selectedDate={selectedDate}
              maxDate={maxDate}
              minDate={minDate}
              onChangeDate={setSelectedDate}
              allowedWeekDays={allowedWeekDays}
            />

            {!noSlots && (
              <CustomerCalendarContent
                facility={facility}
                selectedDate={selectedDate}
                schedules={schedules || []}
                loading={isLoading}
                courtId={initialPayload?.courtId}
                startTime={initialPayload?.startTime}
                endTime={initialPayload?.endTime}
                initialPayload={initialPayload}
                onSelect={onChange}
                nrOfSlots={nrOfSlots}
                bookingId={bookingId}
                bookingType={bookingType}
              />
            )}

            {(bookingId ||
              facility.facilityBookingRules.onlyMemberships.length > 0) &&
              noSlots &&
              !isLoading && (
                <div className="flex items-center justify-center p-6 lg:p-10">
                  <div className="max-w-80 text-center">
                    <FontAwesomeIcon
                      icon={
                        facility.facilityBookingRules.onlyMemberships.length > 0
                          ? faBadgeCheck
                          : faCalendar
                      }
                      size="2x"
                      className="mb-2 inline-block"
                    />
                    {facility.facilityBookingRules.onlyMemberships.length >
                      0 && (
                      <h4 className="mb-2">
                        <FormattedMessage id="calendar.members-only.message.heading" />
                      </h4>
                    )}

                    <p className="text-sm">
                      {facility.facilityBookingRules.onlyMemberships.length >
                      0 ? (
                        <FormattedMessage
                          id="calendar.members-only.message.text"
                          values={{
                            facilityName: facility.name,
                            Bold: chunk => <strong>{chunk}</strong>,
                            membershipLink: chunk => (
                              <Link
                                to={getVenuePath(facility.id, "membership")}
                              >
                                {chunk}
                              </Link>
                            ),
                          }}
                        />
                      ) : (
                        <FormattedMessage id="series.reschedule.empty-day" />
                      )}
                    </p>
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerCalendar;
