import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import type { KeyedMutator } from "swr";

import type { IBooking } from "../../../../../models/Booking";

import { updateBookingComment } from "../../../../../services/Booking";

interface Props {
  booking: IBooking;
  mutateBooking: KeyedMutator<IBooking>;
  onSubmitted: (refreshView?: boolean, isEditDialogOpen?: boolean) => void;
}

export const Comment = ({ booking, mutateBooking, onSubmitted }: Props) => {
  const originalComment = booking.comment || "";

  const [newComment, setNewComment] = useState(originalComment);

  const isCommentChanged = originalComment !== newComment.trim();

  useEffect(() => {
    setNewComment(originalComment);
  }, [booking.id, originalComment]);

  const saveComment = async () => {
    await updateBookingComment(booking.facilityId, {
      comment: newComment,
      id: booking.id,
      isShownInCalendar: booking.isShownInCalendar,
    });

    booking.comment = newComment;
    mutateBooking(booking, false);
    onSubmitted(true, true);
  };

  const updateIsShownInCalendar = async (isShownInCalendar: boolean) => {
    await updateBookingComment(booking.facilityId, {
      comment: originalComment,
      id: booking.id,
      isShownInCalendar,
    });

    booking.isShownInCalendar = isShownInCalendar;
    mutateBooking(booking, false);
    onSubmitted(true, true);
  };

  return (
    <div className="mt-6">
      <div className="mb-1 flex">
        <label htmlFor="comment" className="mr-auto font-medium text-gray-700">
          <FormattedMessage id="common.comment" />
        </label>

        {isCommentChanged && (
          <button
            className={clsx(
              "font-bold transition-colors enabled:text-primary disabled:text-gray-700",
            )}
            onClick={() => saveComment()}
          >
            <FontAwesomeIcon icon={faCheck} className="mr-1 inline-block" />
            <FormattedMessage id="common.save" />
          </button>
        )}
      </div>
      <div className="relative">
        <input
          type="text"
          onChange={e => setNewComment(e.target.value)}
          value={newComment}
          className={clsx(
            "w-full rounded-10 border bg-purewhite px-3 py-2.5 pr-20 text-base text-pureblack",
            "focus-visible:border-transparent focus-visible:shadow-input focus-visible:outline-2 focus-visible:outline-primary",
            "disabled:cursor-not-allowed disabled:bg-neutral-100 disabled:text-gray-700",
            "placeholder:text-gray-700",
          )}
        />

        {isCommentChanged && (
          <button
            className="absolute right-3 top-1/2 -translate-y-1/2 disabled:text-gray-700"
            onClick={() => setNewComment(originalComment)}
          >
            <FormattedMessage id="common.undo" />
          </button>
        )}
      </div>

      <div className="mt-1 flex justify-end">
        <label className="flex items-center has-[:disabled]:text-gray-700">
          <input
            type="checkbox"
            className="mr-2 accent-primary"
            defaultChecked={booking.isShownInCalendar}
            onChange={e => updateIsShownInCalendar(e.target.checked)}
          />
          <FormattedMessage id="common.show-in-calendar" />
        </label>
      </div>
    </div>
  );
};
