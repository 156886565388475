import { FormattedMessage, useIntl } from "react-intl";

import {
  faCheckCircle,
  faCircleExclamation,
  faCoin,
  faCreditCard,
} from "@fortawesome/pro-regular-svg-icons";
import { IconLookup, faHeartPulse } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { Skeleton } from "primereact/skeleton";
import { Tooltip } from "primereact/tooltip";
import { useMediaQuery } from "usehooks-ts";

import {
  type PaymentMethod,
  PaymentMethodStatus,
} from "../../../models/payment";

import { PaymentTerms } from "../../../components/PaymentTerms";

import { CheckoutViewHeading } from "../CheckoutView";
import {
  useCheckoutDispatch,
  useCheckoutState,
} from "../context/CheckoutContext";
import { CoinsAllowance } from "./coins/CoinsAllowance";
import { CoinsCredit } from "./coins/CoinsCredit";
import { SwedbankPayApplePay } from "./swedbank-pay/SwedbankPayApplePay";
import { SwedbankPayCard } from "./swedbank-pay/SwedbankPayCard";
import { SwedbankPayGooglePay } from "./swedbank-pay/SwedbankPayGooglePay";
import { SwedbankPayMobilePay } from "./swedbank-pay/SwedbankPayMobilePay";
import { SwedbankPaySwish } from "./swedbank-pay/SwedbankPaySwish";

export const PaymentMethods = () => {
  const { order, paymentMethods, selectedPaymentMethod, checkoutStatus } =
    useCheckoutState();

  if (order.isPaid) {
    return null;
  }

  return (
    <div>
      <CheckoutViewHeading>
        <FormattedMessage id="checkout.section.payment-methods.heading" />
      </CheckoutViewHeading>

      <ul className="mt-4 space-y-3">
        {paymentMethods.length < 1 && (
          <>
            <li>
              <Skeleton height="" className="h-12 lg:h-[53px]" />
            </li>
            <li>
              <Skeleton height="" className="h-12 lg:h-[53px]" />
            </li>
            <li>
              <Skeleton height="" className="h-12 lg:h-[53px]" />
            </li>
            <li>
              <Skeleton height="" className="h-12 lg:h-[53px]" />
            </li>
          </>
        )}

        {paymentMethods.map(paymentMethod => {
          const isSelectedPaymentMethod =
            selectedPaymentMethod?.instrument === paymentMethod.instrument &&
            selectedPaymentMethod?.provider === paymentMethod.provider;

          if (paymentMethod.status === PaymentMethodStatus.Loading) {
            return (
              <li key={paymentMethod.provider + paymentMethod.instrument}>
                <Skeleton height="" className="h-12 lg:h-[53px]" />
              </li>
            );
          }

          return (
            <PaymentMethodOption
              key={paymentMethod.provider + paymentMethod.instrument}
              paymentMethod={paymentMethod}
              enabled={
                paymentMethod.status === PaymentMethodStatus.Enabled &&
                (checkoutStatus === "idle" || isSelectedPaymentMethod)
              }
              isSelectedPaymentMethod={isSelectedPaymentMethod}
            />
          );
        })}
      </ul>

      <PaymentTerms
        facilityId={order.facilityId}
        className="mt-8 text-center"
      />
    </div>
  );
};

const PaymentMethodInfoMap: Record<
  string,
  {
    component: () => React.JSX.Element;
    translationId: string;
    icon: IconLookup | string;
    disabledIcon?: IconLookup | string;
  }
> = {
  SwedbankPayCard: {
    component: SwedbankPayCard,
    translationId: "checkout.section.payment-methods.card.description",
    icon: faCreditCard,
  },
  SwedbankPaySwish: {
    component: SwedbankPaySwish,
    translationId: "checkout.section.payment-methods.swish.description",
    icon: "/payment-methods/swish-logo-secondary.svg",
    disabledIcon: "/payment-methods/swish-logo-secondary-grayscale.svg",
  },
  SwedbankPayMobilePay: {
    component: SwedbankPayMobilePay,
    translationId: "checkout.section.payment-methods.mobile-pay.description",
    icon: "/payment-methods/mobile-pay.svg",
  },
  SwedbankPayApplePay: {
    component: SwedbankPayApplePay,
    translationId: "checkout.section.payment-methods.apple-pay.description",
    icon: "/payment-methods/apple-pay.svg",
  },
  SwedbankPayGooglePay: {
    component: SwedbankPayGooglePay,
    translationId: "checkout.section.payment-methods.google-pay.description",
    icon: "/payment-methods/google-pay.png",
  },
  CoinsCredit: {
    component: CoinsCredit,
    translationId: "checkout.section.payment-methods.coins-credit.description",
    icon: faCoin,
  },
  CoinsAllowance: {
    component: CoinsAllowance,
    translationId:
      "checkout.section.payment-methods.coins-allowance.description",
    icon: faHeartPulse,
  },
};

const PaymentMethodOption = ({
  paymentMethod,
  isSelectedPaymentMethod,
  enabled,
}: {
  paymentMethod: PaymentMethod;
  isSelectedPaymentMethod: boolean;
  enabled: boolean;
}) => {
  const isMobile = useMediaQuery("(max-width: 539px)");
  const intl = useIntl();
  const dispatch = useCheckoutDispatch();

  const onClick = () => {
    if (isSelectedPaymentMethod) {
      return;
    }

    dispatch({ type: "SET_SELECTED_PAYMENT_METHOD", paymentMethod });
  };

  const paymentMethodInfo =
    PaymentMethodInfoMap[paymentMethod.provider + paymentMethod.instrument];

  if (!paymentMethodInfo) {
    return null;
  }

  const PaymentMethodComponent = paymentMethodInfo.component;
  const iconOrImageSrc = enabled
    ? paymentMethodInfo.icon
    : paymentMethodInfo.disabledIcon ?? paymentMethodInfo.icon;

  const requiresBackupPaymentMethod =
    paymentMethod.status === PaymentMethodStatus.RequiresBackupPaymentMethod;

  return (
    <li
      className={clsx(
        "rounded border transition-colors",
        isSelectedPaymentMethod && "border-green-700",
        !isSelectedPaymentMethod && "border-gray-50",
        enabled &&
          !isSelectedPaymentMethod &&
          "hover:border-primary hover:bg-primary hover:bg-opacity-10",
        !enabled && "bg-white text-gray-700",
      )}
    >
      {requiresBackupPaymentMethod && (
        <Tooltip
          target={`.${paymentMethod.provider + paymentMethod.instrument}`}
          position="top"
          // mouseTrack because on device width < 540px, the tooltip always uses position right (for some reason?!) which fucks up the layout.
          mouseTrack={isMobile}
        />
      )}

      <span
        className={paymentMethod.provider + paymentMethod.instrument}
        data-pr-tooltip={intl.formatMessage({
          id: "payment.method.error.requires-backup-payment-method",
        })}
      >
        <button
          type="button"
          onClick={onClick}
          disabled={!enabled}
          className={clsx(
            "flex w-full items-center justify-between gap-2 px-5 py-3",
            !enabled && "cursor-not-allowed",
            isSelectedPaymentMethod &&
              "pointer-events-none focus-visible:outline-none",
          )}
        >
          {typeof iconOrImageSrc === "string" ? (
            <img
              src={iconOrImageSrc}
              className={clsx(
                "max-h-7 max-w-[25%] flex-initial",
                !enabled && "opacity-60",
              )}
            />
          ) : (
            <FontAwesomeIcon
              icon={iconOrImageSrc}
              className="flex-initial text-lg"
            />
          )}
          <div className="flex min-w-0 items-center gap-5">
            <span className="truncate text-right lg:text-md">
              <FormattedMessage id={paymentMethodInfo.translationId} />
            </span>

            {isSelectedPaymentMethod && (
              <FontAwesomeIcon
                icon={faCheckCircle}
                className="text-green-700 lg:text-md"
              />
            )}

            {requiresBackupPaymentMethod && (
              <FontAwesomeIcon icon={faCircleExclamation} className="text-md" />
            )}
          </div>
        </button>
      </span>

      {isSelectedPaymentMethod && (
        <div className="px-4 py-3 lg:px-10 lg:py-6">
          <PaymentMethodComponent />
        </div>
      )}
    </li>
  );
};
