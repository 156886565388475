import { FacilityWithUtc } from "../modules/customer/models/Facility";
import { User } from "../modules/player/models/User";

export interface UserPermissions {
  role: UserRole;
  facilities: string[];
}

export interface Permissions {
  permissions: UserPermissions[];
}

export enum UserRole {
  FacilityAdmin = 0,
  SuperAdmin = 1,
  Owner = 2,
}

export interface FacilityRoleWithUsers {
  name: string;
  users: FacilityRoleUser[];
}

export interface AddUserPermissionRequest {
  facilityId: FacilityWithUtc["id"];
  userId: User["id"];
  role: UserRole;
}

export type FacilityRoleUser = Pick<
  User,
  "displayName" | "emailAddress" | "id"
>;

export type FacilityAdminUser = Pick<
  User,
  | "id"
  | "displayName"
  | "emailAddress"
  | "facilityUserRelations"
  | "firstName"
  | "lastName"
  | "locale"
  | "phoneNumber"
  | "profileImage"
  | "skillLevel"
>;
