import { DateTime } from "luxon";

import { ApiSingleResponse } from "../../../models/common";
import type { FacilityWithUtc } from "../../customer/models/Facility";
import {
  type AdminFacilityUserCoin,
  type AdminFacilityUserCoinApiResponse,
  Coin,
  CoinApiResponse,
  CoinWallet,
  CoinWalletApiResponse,
} from "../models/Coins";

import { dateTimeToUtcIsoSanitizedToDay } from "../../../helpers/dateTime";

import { fetchApi } from "../../../services/legacyApiClient";

const apiVersion = "player/v2";

export const getUserCoins = async (
  signal?: AbortSignal,
): Promise<CoinWallet[]> => {
  const response = (await fetchApi({
    method: "GET",
    uri: `${apiVersion}/coins`,
    signal,
  })) as ApiSingleResponse<any>;

  const coinWallets = response.data[
    "facilityWallets"
  ] as CoinWalletApiResponse[];

  return coinWallets.map(formatCoinWalletFromApi);
};

export const getCoinsForFacilityByAdmin = async (
  facilityId: string,
  userId: string,
  signal?: AbortSignal,
): Promise<Coin[]> => {
  const response = (await fetchApi({
    uri: `${apiVersion}/coins/${facilityId}/user/${userId}`,
    signal,
  })) as ApiSingleResponse<any>;

  const coins: CoinApiResponse[] = response.data["walletCurrencys"];

  return coins.map(formatCoinFromApi);
};

export const adminGetCoinsForFacility = async (
  facilityId: FacilityWithUtc["id"],
): Promise<AdminFacilityUserCoin[]> => {
  const response = (await fetchApi({
    uri: `${apiVersion}/coins/admin/${facilityId}/users/coins`,
  })) as ApiSingleResponse<any>;

  const adminUserCoins: AdminFacilityUserCoinApiResponse[] = response.data;

  return adminUserCoins.map(coin => ({
    ...coin,
    expiresUtc: DateTime.fromISO(coin.expiresUtc, { setZone: true }),
  }));
};

export const addOrUpdateCoinsForFacilityByAdmin = async (
  facilityId: string,
  userId: string,
  payload: Coin,
  signal?: AbortSignal,
): Promise<Coin[]> => {
  const sanitizedPayload = {
    ...payload,
    expires: dateTimeToUtcIsoSanitizedToDay(payload.expires),
  };

  const response = (await fetchApi({
    method: "POST",
    uri: `${apiVersion}/coins/${facilityId}/user/${userId}`,
    signal,
    payload: sanitizedPayload,
  })) as ApiSingleResponse<any>;

  const coins: CoinApiResponse[] = response.data["walletCurrencys"];

  return coins.map(formatCoinFromApi);
};

const formatCoinFromApi = (coin: CoinApiResponse): Coin => {
  return {
    ...coin,
    expires: DateTime.fromISO(coin.expires, { setZone: true }),
  };
};

const formatCoinWalletFromApi = (
  coinWallet: CoinWalletApiResponse,
): CoinWallet => {
  return {
    ...coinWallet,
    walletCurrencies: coinWallet.walletCurrencies.map(formatCoinFromApi),
  };
};
