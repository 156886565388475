import { faTennisBall } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

interface Props {
  css?: string;
  src?: string;
  width?: string;
  height?: string;
  onClick?: (e?: any) => any;
}

const ImageContainer = styled.div<Props>`
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  width: ${props => props.width || "5rem"};
  height: ${props => props.height || "5rem"};
  border-radius: var(--border-radius);
  background-color: var(--primary);
  color: var(--light);
  flex: 0 0 auto;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: var(--border-radius);
  }
`;

export const LogoWithFallback = ({
  css,
  width,
  height,
  src,
  onClick,
}: Props) => (
  <ImageContainer
    css={css}
    width={width}
    height={height}
    onClick={() => onClick?.()}
  >
    {src ? (
      <img src={src} />
    ) : (
      <FontAwesomeIcon icon={faTennisBall} size="2x" />
    )}
  </ImageContainer>
);
