import { useCallback, useEffect } from "react";
import { createPortal } from "react-dom";
import { FormattedMessage } from "react-intl";
import { useMediaQuery } from "react-responsive";
import { NavLink, useHistory } from "react-router-dom";

import { useMsal } from "@azure/msal-react";
import clsx from "clsx";
import { motion } from "framer-motion";
import { useRecoilState } from "recoil";

import {
  adminGetFAQPath,
  adminGetMobilePath,
  getFAQPath,
  getHomePath,
} from "../../../helpers/pathHelpers";

import { useHasFacilityAdminPermission } from "../../../hooks/permissions";
import { useAppDispatch } from "../../../hooks/store/store";

import { isAdminModeState } from "../../../recoil/isAdminModeState";
import { isMobileMenuOpenState } from "../Header";
import { MobileLanguagePicker } from "../MobileLanguagePicker";
import { MobileSearch } from "../Search/MobileSearch";
import { mobileAdminMenuItems, mobileMenuItems } from "./MenuItems";

export const MobileMenu = () => {
  const { hasFacilityAdminPermission } = useHasFacilityAdminPermission();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { instance } = useMsal();
  const isMobile = useMediaQuery({ query: "(max-width: 849px)" });

  const [isMobileMenuOpen, setMobileMenuOpen] = useRecoilState(
    isMobileMenuOpenState,
  );
  const [isAdminMode, setIsAdminMode] = useRecoilState(isAdminModeState);

  const items = isAdminMode ? mobileAdminMenuItems : mobileMenuItems;

  useEffect(() => {
    if (isMobileMenuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [isMobileMenuOpen]);

  useEffect(() => {
    if (!isMobile) {
      setMobileMenuOpen(false);
    }
  }, [isMobile, setMobileMenuOpen]);

  const logout = useCallback(() => {
    dispatch({ type: "logout" });
    instance.logoutRedirect();
  }, [dispatch, instance]);

  const toggleAdminMode = () => {
    if (!isAdminMode) {
      setIsAdminMode(true);
      history.push(adminGetMobilePath());
    } else {
      setIsAdminMode(false);
      history.push(getHomePath());
    }
    setMobileMenuOpen(false);
  };

  return createPortal(
    <motion.div
      className={clsx(
        "fixed top-0 z-[997] flex w-screen overflow-hidden bg-purewhite pt-16",
      )}
      variants={{
        open: {
          height: "100dvh",
          transition: {
            duration: 0.4,
          },
        },
        closed: {
          height: 0,
          transition: {
            duration: 0.4,
          },
        },
      }}
      animate={isMobileMenuOpen ? "open" : "closed"}
      exit={{ height: 0 }}
      initial={{ height: 0 }}
    >
      <div className="flex w-full flex-col justify-between border-t border-gray-500 px-4">
        <div>
          <div className="py-10">
            <MobileSearch />
          </div>
          <nav className="flex-1 flex-col items-start space-y-4">
            {items.map(item => (
              <div key={item.to}>
                <NavLink
                  className="text-md text-primary-dark"
                  onClick={() => setMobileMenuOpen(false)}
                  to={item.to}
                  exact={item.exact}
                >
                  <FormattedMessage id={item.textId} />
                </NavLink>
              </div>
            ))}
            <p>
              <a
                className="text-md text-primary-dark"
                href={isAdminMode ? adminGetFAQPath() : getFAQPath()}
                target="_blank"
                rel="noreferrer"
              >
                <FormattedMessage id="common.faq" />
              </a>
            </p>
            <MobileLanguagePicker />
          </nav>
        </div>
        <div className="flex flex-col items-center space-y-4 py-8 font-bold text-primary-dark">
          {hasFacilityAdminPermission && (
            <p className="cursor-pointer" onClick={toggleAdminMode}>
              <FormattedMessage
                id={
                  isAdminMode
                    ? "header.admin-mode.change-to.player"
                    : "header.admin-mode.change-to.admin"
                }
              />
            </p>
          )}
          <p className="cursor-pointer" onClick={logout}>
            <FormattedMessage id="common.sign-out" />
          </p>
        </div>
      </div>
    </motion.div>,
    document.body,
  );
};
