import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";

import {
  faCalendarDays,
  faCog,
  faSend,
  faXmark,
} from "@fortawesome/pro-light-svg-icons";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { Tooltip } from "primereact/tooltip";

import { Series, SeriesDivision } from "../../../../models/Series";

import { useToaster } from "../../../../../../hooks/common/useToaster";

import {
  removeDivision,
  removeDivisionGames,
  sendEmailToDivision,
} from "../../../../services/Serie";

import { Button } from "../../../../../../components/Button";
import { ConfirmationDialog } from "../../../../../../components/ConfirmationDialog";
import { Dialog } from "../../../../../../components/Dialog";
import { DivisionMessageEditor } from "../../../../../../pages/Admin/activities/series/AdminSeriesDetailsPage/components/DivisionMessageEditor";

import { useSelectedFacilityId } from "../../../../../../recoil/selectedFacilityIdState";
import { CreateGamesForm } from "./form/CreateGameForm";

interface IProps {
  series: Series;
  division: SeriesDivision;
  onEditDialogStateChange: (state: boolean) => void;
  onActiveDivisionIdChange: (divisionId: SeriesDivision["id"]) => void;
  reload: () => void;
}

const DivisionsEditorDivisionDetailsHeader: React.FC<IProps> = ({
  series,
  division,
  onEditDialogStateChange,
  onActiveDivisionIdChange,
  reload,
}) => {
  const { toastSuccess, toastError } = useToaster();
  const intl = useIntl();
  const selectedFacilityId = useSelectedFacilityId();
  const [createGamesDialogOpen, setCreateGamesDialogOpen] = useState(false);
  const [showConfirmEmail, setShowConfirmEmail] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showConfirmDeleteGames, setShowConfirmDeleteGames] = useState(false);
  const [showMessageEditor, setShowMessageEditor] = useState(false);

  const buttonClasses = "text-sm whitespace-nowrap";

  return (
    <div className="flex flex-col gap-8">
      <div className="flex items-center">
        <button
          className="flex gap-4"
          type="button"
          onClick={() => onEditDialogStateChange(true)}
        >
          <FontAwesomeIcon icon={faCog} size="lg" />
        </button>

        <div className="flex flex-grow flex-col items-center">
          <span>{division.name}</span>
          <span
            className={
              division?.teams?.length < division?.numberOfTeams
                ? "text-success"
                : "text-danger"
            }
          >
            {division?.teams?.length} / {division?.numberOfTeams}
          </span>
        </div>

        <button type="button" onClick={() => onActiveDivisionIdChange("")}>
          <FontAwesomeIcon icon={faXmark} size="lg" />
        </button>
      </div>

      <div className="flex flex-wrap gap-x-4 gap-y-2">
        {!!division?.teams?.length && (
          <>
            <Tooltip target=".send-division-message">
              <FormattedMessage id="admin.send-division-message-tooltip" />
            </Tooltip>
            <Button
              className={clsx(buttonClasses, "send-division-message")}
              type="default"
              size="small"
              icon={faSend}
              text={intl.formatMessage({
                id: "admin.send.customer.email",
              })}
              onClick={() => setShowMessageEditor(true)}
            />
          </>
        )}

        {division?.games?.length ? (
          <Link
            to={`/admin/activities/serie/${series.id}/matches?divisionIds=${division.id}`}
            className="text-inherit"
          >
            <Button
              className={buttonClasses}
              type="default"
              size="small"
              icon={faCalendarDays}
              text={intl.formatMessage({
                id: "series.games.schedule.view",
              })}
            />
          </Link>
        ) : (
          <Button
            className={buttonClasses}
            type="default"
            size="small"
            disabled={
              !selectedFacilityId ||
              !division?.teams ||
              division?.teams?.length < 2
            }
            text={intl.formatMessage({
              id: "series.games.button.genarate",
            })}
            onClick={() => setCreateGamesDialogOpen(true)}
          />
        )}

        <Button
          className={clsx(
            "group",
            buttonClasses,
            division.mostRecentScheduleEmailSent &&
              "border-green-700 text-green-700",
          )}
          type="default"
          size="small"
          disabled={!division?.games || division?.games?.length < 1}
          icon={division.mostRecentScheduleEmailSent ? faCheck : undefined}
          onClick={() => setShowConfirmEmail(true)}
        >
          <span
            className={clsx(
              "group-hover:inline",
              division.mostRecentScheduleEmailSent && "hidden",
            )}
          >
            <FormattedMessage id="series.games.button.send-email" />
          </span>
          {division.mostRecentScheduleEmailSent && (
            <span className="inline group-hover:hidden">
              <FormattedMessage id="series.games.button.email-sent" />
            </span>
          )}
        </Button>

        {!!division?.games?.length && (
          <Button
            className={buttonClasses}
            type="danger"
            size="small"
            text={intl.formatMessage({
              id: "series.games.button.delete-games",
            })}
            onClick={() => setShowConfirmDeleteGames(true)}
          />
        )}

        <Button
          className={buttonClasses}
          type="danger"
          size="small"
          text={intl.formatMessage({
            id: "series.games.button.delete",
          })}
          onClick={() => setShowConfirmDelete(true)}
        />
      </div>

      {showMessageEditor && (
        <Dialog
          onHide={() => setShowMessageEditor(false)}
          visible
          className="h-[90%] w-[90%] max-w-[1200px]"
        >
          <DivisionMessageEditor
            seriesId={series.id}
            divisionId={division.id}
            onComplete={() => setShowMessageEditor(false)}
          />
        </Dialog>
      )}

      {showConfirmEmail && (
        <ConfirmationDialog
          visible
          text={intl.formatMessage({
            id: "divisions.email.send.are-you-sure",
          })}
          confirmText={intl.formatMessage({
            id: "common.send",
          })}
          title={intl.formatMessage({
            id: "series.division.team.remove-confirmation-message",
          })}
          onHide={() => setShowConfirmEmail(false)}
          onCancel={() => setShowConfirmEmail(false)}
          onSubmit={async () => {
            try {
              await sendEmailToDivision(series.id, division?.id);
              reload();
              toastSuccess.divisionEmailSent();
            } catch (e) {
              toastError.generalFailure();
            }
            setShowConfirmEmail(false);
          }}
        />
      )}

      {showConfirmDelete && (
        <ConfirmationDialog
          visible
          confirmButtonType="danger"
          text={intl.formatMessage({
            id: "divisions.delete.are-you-sure",
          })}
          confirmText={intl.formatMessage({
            id: "common.delete",
          })}
          title={intl.formatMessage({
            id: "series.division.team.remove-confirmation-message",
          })}
          onHide={() => setShowConfirmDelete(false)}
          onCancel={() => setShowConfirmDelete(false)}
          onSubmit={async () => {
            try {
              await removeDivision(series.id, division?.id);
              toastSuccess.divisionRemoved();
            } catch (e) {
              toastError.generalFailure();
            } finally {
              reload();
              setShowConfirmDelete(false);
            }
          }}
        />
      )}

      {showConfirmDeleteGames && (
        <ConfirmationDialog
          visible
          confirmButtonType="danger"
          text={intl.formatMessage({
            id: "divisions.delete-games.are-you-sure",
          })}
          confirmText={intl.formatMessage({
            id: "common.delete",
          })}
          title={intl.formatMessage({
            id: "series.division.team.remove-confirmation-message",
          })}
          onHide={() => setShowConfirmDeleteGames(false)}
          onCancel={() => setShowConfirmDeleteGames(false)}
          onSubmit={async () => {
            try {
              await removeDivisionGames(series.id, division?.id);
              toastSuccess.divisionGamesRemoved();
            } catch (e) {
              toastError.generalFailure();
            } finally {
              reload();
              setShowConfirmDeleteGames(false);
            }
          }}
        />
      )}

      {createGamesDialogOpen && selectedFacilityId && (
        <Dialog
          visible
          onHide={() => setCreateGamesDialogOpen(false)}
          className="md:max-w-auto"
        >
          <CreateGamesForm
            serieId={series.id}
            division={division}
            facilityId={selectedFacilityId}
            onClose={() => setCreateGamesDialogOpen(false)}
            onSubmit={() => reload()}
          />
        </Dialog>
      )}
    </div>
  );
};

export default DivisionsEditorDivisionDetailsHeader;
