import { DateTime } from "luxon";

import type {
  CompletedOrderByBooking,
  CompletedOrderByBookingResponse,
} from "../models/completedOrder";
import type { IBooking } from "../modules/checkout/models/Booking";
import type { FacilityWithUtc } from "../modules/customer/models/Facility";

import { get } from "./apiClient";

const API_VERSION = "v1";

const getEndpointBase = (
  facilityId: FacilityWithUtc["id"],
  bookingId: IBooking["id"],
) => `/${API_VERSION}/facilities/${facilityId}/bookings/${bookingId}`;

export const getCompletedOrdersByBooking = (
  facilityId: FacilityWithUtc["id"],
  bookingId: IBooking["id"],
): Promise<CompletedOrderByBooking[]> =>
  get<CompletedOrderByBookingResponse[]>({
    endpoint: `${getEndpointBase(facilityId, bookingId)}/completed-orders`,
  }).then(completedOrders =>
    completedOrders.map(formatCompletedOrderByBooking),
  );

const formatCompletedOrderByBooking = (
  completedOrderByBooking: CompletedOrderByBookingResponse,
): CompletedOrderByBooking => ({
  ...completedOrderByBooking,
  payments: completedOrderByBooking.payments.map(payment => ({
    ...payment,
    created: DateTime.fromISO(payment.created),
    updated: DateTime.fromISO(payment.updated),
  })),
});
