import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { DialogProps, Dialog as PrimereactDialog } from "primereact/dialog";
import styled from "styled-components";

import { useIsMobile } from "../hooks/common/useIsMobile";

const CloseButton = styled.button`
  position: absolute;
  right: -0.75rem;
  top: -0.75rem;
  border-radius: 50%;
  cursor: pointer;
  display: grid;
  place-items: center;
  box-shadow: 0rem 0.2rem 0.5rem rgba(50, 50, 50, 0.2);
  color: var(--primary);

  svg {
    background: var(--light);
    width: 1.5rem;
    height: 1.5rem;
    padding: 0.25em;
    border-radius: 500vw;
    transition: background 250ms ease-in-out;
    color: var(--primary);
  }

  &:hover {
    svg {
      background: var(--primary-dark);
      transition: background 250ms ease-in-out;
    }
  }
`;

interface Props extends DialogProps {
  /**
   * Called before hiding, return true if dialog should hide
   */
  onWillHide?: () => boolean;
}

export const Dialog = (props: Props) => {
  const isMobile = useIsMobile();
  const {
    onWillHide,
    dismissableMask = false,
    closeOnEscape = true,
    closable = true,
    draggable = false,
    resizable = false,
    ...dialogProps
  } = props;

  const onHide = () => {
    const shouldHide = onWillHide?.() ?? true;

    if (shouldHide) {
      props.onHide?.();
    }
  };

  const closeIcon = (
    <CloseButton onClick={onHide}>
      <FontAwesomeIcon icon={faXmark} />
    </CloseButton>
  );

  const mobileCloseIcon = (
    <button onClick={onHide} className="p-1">
      <FontAwesomeIcon icon={faTimes} />
    </button>
  );

  const classes = clsx("w-full max-w-full md:max-w-[32rem]", props?.className);

  return (
    <PrimereactDialog
      className={classes}
      // react-toastify uses 9999
      baseZIndex={9000}
      dismissableMask={dismissableMask}
      closeOnEscape={closeOnEscape}
      closable={closable}
      draggable={draggable}
      icons={closable ? (isMobile ? mobileCloseIcon : closeIcon) : null}
      onHide={onHide}
      maximized={isMobile}
      modal
      resizable={resizable}
      headerClassName="!p-4"
      {...(dialogProps as DialogProps)}
    />
  );
};
