import React from "react";

import { Interval } from "luxon";

import { FacilityWithUtc } from "../../../customer/models/Facility";
import { IScheduleSlot } from "../../models/Calendar";

import { useDateFormat } from "../../../../hooks/useDateFormat";

interface IProps {
  slots: IScheduleSlot[];
  facilityId: FacilityWithUtc["id"];
}

const CalendarHead: React.FC<IProps> = ({ slots, facilityId }) => {
  const { setZone, setLocale } = useDateFormat(facilityId);

  if (slots.length < 2) {
    return null;
  }

  const firstSlot = slots.at(0);
  const lastSlot = slots.at(-1);

  const intervals = Interval.fromDateTimes(
    setLocale(setZone(firstSlot.startTime)),
    setLocale(setZone(lastSlot.endTime)),
  ).splitBy({ hours: 1 });

  return (
    <div className="calendar-row calendar-times">
      <div />
      {intervals.map((interval: Interval, index: number) => {
        const localeParts = interval.start.toLocaleParts({
          hour: "numeric",
          minute: "numeric",
        });

        const hourIndex = localeParts.findIndex(
          (part: Intl.DateTimeFormatPart) => part.type === "hour",
        );

        const minuteStartIndex =
          localeParts[hourIndex + 1].type === "literal"
            ? hourIndex + 2
            : hourIndex + 1;

        const minutes = localeParts
          .slice(minuteStartIndex)
          .map(({ value }) => value)
          .join("");

        return (
          <div key={interval.start.toString()} className="time head-time">
            {localeParts[hourIndex].value}

            <small style={{ marginBottom: "3px", marginLeft: "1px" }}>
              {minutes}
            </small>

            {index !== 0 &&
            interval.start.offset !== intervals.at(index - 1).start.offset
              ? interval.start.toFormat("Z")
              : ""}
          </div>
        );
      })}
    </div>
  );
};

export default React.memo(CalendarHead);
