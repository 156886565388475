import styled from "styled-components";

interface ContainerProps {
  selected: boolean;
}

const Container = styled.div<ContainerProps>`
  padding: 0.5rem;
  font-weight: var(--bold);
  border-radius: 4px;
  border: 2px solid
    ${props => (props?.selected ? "var(--primary)" : "var(--gray-300)")};
  position: relative;
  cursor: pointer;
  user-select: none;
`;

interface Props {
  length: number;
  selected: boolean;
  setSelected: any;
}

export const Length: React.FC<Props> = ({ length, selected, setSelected }) => {
  const handleClick = () => {
    setSelected(length);
  };

  return (
    <Container selected={selected} onClick={handleClick}>
      {`${length} min`}
    </Container>
  );
};
