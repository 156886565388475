import { DateTime } from "luxon";

import { DateOnly } from "../../../models/DateOnly";
import { TimeOnly } from "../../../models/TimeOnly";
import { ApiResponse, ApiSingleResponse } from "../../../models/common";
import {
  CreatePriceListRequestWithUTC,
  CreatePriceListResponseWithUTC,
  CreatePriceRequest,
  PriceCategory,
  PriceListResponseWithUTC,
  PriceSchedule,
  PriceScheduleRequestWithUTC,
  SlotPrice,
} from "../models/Pricing";
import {
  CreatePriceListRequest,
  CreatePriceListResponse,
  PriceListResponse,
  PriceScheduleResponse,
  SlotPriceResponse,
} from "../models/api/pricing";

import { formatPricingResponse } from "../helpers/typesMapping";

import { fetchApi } from "../../../services/legacyApiClient";

const apiVersion = "checkout/v3";

export const getPriceLists = async (
  facilityId?: string,
  signal?: AbortSignal,
): Promise<ApiResponse<PriceListResponseWithUTC>> => {
  try {
    const response = await fetchApi<ApiResponse<PriceListResponse>>({
      uri: `${apiVersion}/pricing/lists/${facilityId}`,
      signal,
    });

    const { data: responseData, count } =
      response as ApiResponse<PriceListResponse>;

    const responseModel: PriceListResponseWithUTC[] = responseData.map(data =>
      formatPricingResponse(data),
    );
    return { data: responseModel, count };
  } catch (e) {
    return { data: [], count: 0 };
  }
};

export const createPriceList = async (
  data: CreatePriceListRequestWithUTC,
): Promise<ApiSingleResponse<CreatePriceListResponseWithUTC>> => {
  try {
    const payload: CreatePriceListRequest = {
      ...data,
      validFrom: data.validFrom?.toISO(),
      validTo: data.validTo?.toISO(),
    };
    const response = await fetchApi<ApiSingleResponse<CreatePriceListResponse>>(
      {
        method: "POST",
        uri: `${apiVersion}/pricing`,
        payload,
      },
    );

    const {
      data: { id, name, validFrom, validTo },
      error,
      isSuccessful,
    } = response as ApiSingleResponse<CreatePriceListResponse>;

    const responseModel: CreatePriceListResponseWithUTC = {
      id,
      name,
      validFrom: DateOnly.fromISODate(validFrom),
      validTo: DateOnly.fromISODate(validTo),
    };
    return { data: responseModel, error, isSuccessful };
  } catch (e) {
    return { data: null, error: e, isSuccessful: false };
  }
};

export const updatePriceList = async (
  priceListId: string,
  data: CreatePriceListRequestWithUTC,
): Promise<ApiSingleResponse<CreatePriceListResponseWithUTC>> => {
  try {
    const payload: CreatePriceListRequest = {
      ...data,
      validFrom: data.validFrom?.toISO(),
      validTo: data.validTo?.toISO(),
    };
    const response = await fetchApi<ApiSingleResponse<CreatePriceListResponse>>(
      {
        method: "PUT",
        uri: `${apiVersion}/pricing/${priceListId}`,
        payload,
      },
    );

    const {
      data: { id, name, validFrom, validTo },
      error,
      isSuccessful,
    } = response as ApiSingleResponse<CreatePriceListResponse>;

    const responseModel: CreatePriceListResponseWithUTC = {
      id,
      name,
      validFrom: DateOnly.fromISODate(validFrom),
      validTo: DateOnly.fromISODate(validTo),
    };
    return { data: responseModel, error, isSuccessful };
  } catch (e) {
    return { data: null, error: e, isSuccessful: false };
  }
};

export const copyPriceList = async (
  copiedPriceListId: PriceListResponseWithUTC["id"],
  details: {
    name: string;
    validFrom: DateOnly;
    validTo?: DateOnly;
  },
) => {
  return await fetchApi<ApiSingleResponse<PriceListResponse>>({
    method: "POST",
    uri: `${apiVersion}/pricing/copypricelist`,
    payload: {
      copiedPriceListId,
      ...details,
    },
  }).then(response => {
    if (typeof response === "boolean") {
      throw new Error("Invalid response");
    }

    return formatPricingResponse(response.data);
  });
};

export const createPriceCategory = async (
  priceListId: string,
  name: string,
): Promise<ApiSingleResponse<PriceCategory>> =>
  (await fetchApi({
    method: "POST",
    uri: `${apiVersion}/pricing/${priceListId}/category`,
    payload: {
      name: name,
    },
  })) as ApiSingleResponse<PriceCategory>;

export const addPriceListGroup = async (
  priceListId: string,
  data: { name: string; id: string },
): Promise<ApiSingleResponse<PriceListResponseWithUTC>> => {
  try {
    const response = await fetchApi<ApiSingleResponse<PriceListResponse>>({
      method: "POST",
      uri: `${apiVersion}/pricing/${priceListId}/group`,
      payload: data,
    });

    const {
      data: resultData,
      error,
      isSuccessful,
    } = response as ApiSingleResponse<PriceListResponse>;

    const responseModel: PriceListResponseWithUTC =
      formatPricingResponse(resultData);
    return { data: responseModel, error, isSuccessful };
  } catch (e) {
    return { data: null, error: e, isSuccessful: false };
  }
};

export const deletePriceListGroup = async (
  priceListId: string,
  groupId: string,
): Promise<ApiSingleResponse<PriceListResponseWithUTC>> => {
  try {
    const response = await fetchApi<ApiSingleResponse<PriceListResponse>>({
      method: "DELETE",
      uri: `${apiVersion}/pricing/${priceListId}/group/${groupId}`,
    });

    const {
      data: resultData,
      error,
      isSuccessful,
    } = response as ApiSingleResponse<PriceListResponse>;

    const responseModel: PriceListResponseWithUTC =
      formatPricingResponse(resultData);
    return { data: responseModel, error, isSuccessful };
  } catch (e) {
    return { data: null, error: e, isSuccessful: false };
  }
};

export const updatePriceCategory = async (
  priceListId: string,
  data: { name: string; priority: number; id: string },
): Promise<ApiSingleResponse<PriceCategory>> =>
  (await fetchApi({
    method: "PUT",
    uri: `${apiVersion}/pricing/${priceListId}/category`,
    payload: data,
  })) as ApiSingleResponse<PriceCategory>;

export const deletePriceCategory = async (
  priceListId: string,
  categoryId: string,
): Promise<ApiSingleResponse<PriceCategory>> =>
  (await fetchApi({
    method: "DELETE",
    uri: `${apiVersion}/pricing/${priceListId}/category/${categoryId}`,
  })) as ApiSingleResponse<PriceCategory>;

export const deletePriceList = async (priceListId: string): Promise<void> => {
  await fetchApi({
    method: "DELETE",
    uri: `${apiVersion}/pricing/${priceListId}`,
  });
};

export const bulkUpdatePrice = async (
  priceListId: string,
  prices: CreatePriceRequest[],
): Promise<ApiResponse<PriceCategory>> =>
  (await fetchApi({
    method: "PUT",
    uri: `${apiVersion}/pricing/${priceListId}/price/bulk`,
    payload: prices,
  })) as ApiResponse<PriceCategory>;

export const createPricingSchedules = async (
  priceListId: string,
  data: PriceScheduleRequestWithUTC,
): Promise<ApiSingleResponse<PriceSchedule>> => {
  try {
    const payload = {
      ...data,
      validFrom: data.validFrom.toISO(),
      validTo: data.validTo.toISO(),
    };
    const response = await fetchApi<ApiSingleResponse<PriceScheduleResponse>>({
      method: "POST",
      uri: `${apiVersion}/pricing/${priceListId}/schedule`,
      payload,
    });

    const {
      data: { priceListid, facilityId, weekDays },
      error,
      isSuccessful,
    } = response as ApiSingleResponse<PriceScheduleResponse>;

    const responseModel: PriceSchedule = {
      priceListid,
      facilityId,
      weekDays: weekDays.map(({ dayOfWeek, slots }) => ({
        dayOfWeek,
        slots: slots.map(({ startTime, endTime, scheduleId, categoryId }) => ({
          startTime: TimeOnly.fromISOTime(startTime),
          endTime: TimeOnly.fromISOTime(endTime),
          scheduleId,
          categoryId,
        })),
      })),
    };
    return { data: responseModel, error, isSuccessful };
  } catch (e) {
    return { data: null, error: e, isSuccessful: false };
  }
};

export const updatePricingSchedules = async (
  priceListId: string,
  data: PriceScheduleRequestWithUTC,
): Promise<ApiSingleResponse<PriceSchedule>> => {
  try {
    const payload = {
      ...data,
      validFrom: data.validFrom.toISO(),
      validTo: data.validTo.toISO(),
    };
    const response = await fetchApi<ApiSingleResponse<PriceScheduleResponse>>({
      method: "PUT",
      uri: `${apiVersion}/pricing/${priceListId}/schedule`,
      payload,
    });

    const {
      data: { priceListid, facilityId, weekDays },
      error,
      isSuccessful,
    } = response as ApiSingleResponse<PriceScheduleResponse>;

    const responseModel: PriceSchedule = {
      priceListid,
      facilityId,
      weekDays: weekDays.map(({ dayOfWeek, slots }) => ({
        dayOfWeek,
        slots: slots.map(({ startTime, endTime, scheduleId, categoryId }) => ({
          startTime: TimeOnly.fromISOTime(startTime),
          endTime: TimeOnly.fromISOTime(endTime),
          scheduleId,
          categoryId,
        })),
      })),
    };
    return { data: responseModel, error, isSuccessful };
  } catch (e) {
    return { data: null, error: e, isSuccessful: false };
  }
};

export const getSlotPrice = async (
  facilityId: string,
  courtId: string,
  startTime: DateTime,
  endTime: DateTime,
  signal?: AbortSignal,
): Promise<ApiSingleResponse<SlotPrice>> => {
  try {
    const response = await fetchApi<ApiSingleResponse<SlotPriceResponse>>({
      uri: `${apiVersion}/pricing/calculateprice/${facilityId}/${courtId}/${startTime
        .toUTC()
        .toISO()}/${endTime.toUTC().toISO()}`,
      signal,
    });
    const { data, error, isSuccessful } =
      response as ApiSingleResponse<SlotPriceResponse>;
    const responseModel: SlotPrice = {
      ...data,
      startTime: DateTime.fromISO(data.startTime, { setZone: true }),
      endTime: DateTime.fromISO(data.endTime, { setZone: true }),
    };
    return { data: responseModel, error, isSuccessful };
  } catch (e) {
    return { data: null, error: e, isSuccessful: false };
  }
};

export const getSlotBasePrice = async (
  facilityId: string,
  courtId: string,
  startTime: DateTime,
  endTime: DateTime,
  signal?: AbortSignal,
): Promise<ApiSingleResponse<SlotPrice>> => {
  try {
    const response = await fetchApi<ApiSingleResponse<SlotPriceResponse>>({
      uri: `${apiVersion}/pricing/basepriceforslot/${facilityId}/${courtId}/${startTime.toISO()}/${endTime.toISO()}`,
      signal,
    });

    const { data, error, isSuccessful } =
      response as ApiSingleResponse<SlotPriceResponse>;

    const responseModel: SlotPrice = {
      ...data,
      startTime: DateTime.fromISO(data.startTime, { setZone: true }),
      endTime: DateTime.fromISO(data.endTime, { setZone: true }),
    };
    return { data: responseModel, error, isSuccessful };
  } catch (e) {
    return { data: null, error: e, isSuccessful: false };
  }
};
