import React from "react";
import { useIntl } from "react-intl";

import { useToaster } from "../../../hooks/common/useToaster";
import { useCurrentUser } from "../../../hooks/swr/useCurrentUser";

import { uploadProfileImage } from "../../../modules/player/services/UserService";

import { Dialog } from "../../../components/Dialog";
import ImageInput from "../../../components/image/ImageInput";

interface Props {
  visible?: boolean;
  onClose?: (e?: any) => any;
}

export const UploadProfileImageDialog: React.FC<Props> = ({
  visible,
  onClose,
}) => {
  const intl = useIntl();
  const { toastError } = useToaster();
  const { mutate } = useCurrentUser();

  const changeProfileImage = async (file: File) => {
    try {
      mutate(async () => {
        return await uploadProfileImage(file);
      }, false);

      onClose();
    } catch {
      toastError.profileImageUploadFailed();
    }
  };

  return (
    <Dialog
      header={intl.formatMessage({
        id: "image.upload.profile-image",
        defaultMessage: "Ladda upp ny profilbild",
      })}
      visible={visible}
      onHide={onClose}
    >
      <ImageInput onSave={changeProfileImage} cropper />
    </Dialog>
  );
};
