import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { AdminScheduleSlot } from "../../../modules/checkout/models/schedule";

import {
  adminGetMobileBookingReschedulePath,
  adminGetMobileBookingsPath,
} from "../../../helpers/pathHelpers";

import AdminEditBookingForm from "../../../modules/checkout/components/Calendar/admin/dialog-content/AdminEditBookingForm";

export const AdminMobileEditBookingPage: React.FC = () => {
  const history = useHistory();
  const data = history?.location?.state as AdminScheduleSlot;

  const onChangeBooking = (
    isReschedule: boolean,
    refreshView: boolean,
    isEditDialogOpen: boolean,
  ) => {
    if (isReschedule) {
      history.replace(
        adminGetMobileBookingReschedulePath(data.facilityId, data.bookingId),
      );
    } else {
      if (refreshView || isEditDialogOpen) return;
      history.goBack();
    }
  };

  useEffect(() => {
    if (!data) {
      history.replace(adminGetMobileBookingsPath());
    }
  }, [data, history]);

  return (
    <div className="space-y-2 bg-purewhite p-2">
      <AdminEditBookingForm
        bookingId={data?.bookingId}
        facilityId={data?.facilityId}
        rescheduleMode={false}
        formValues={data}
        onSubmitted={onChangeBooking}
      />
    </div>
  );
};
