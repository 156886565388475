import { FormattedMessage, useIntl } from "react-intl";

import { useFormik } from "formik";

import { User } from "../../../../../modules/player/models/User";

import { useToaster } from "../../../../../hooks/common/useToaster";
import { useCountryCodes } from "../../../../../hooks/swr/useCountryCodes";
import { useUser } from "../../../../../hooks/swr/useUser";
import { useFormValidationSchema } from "../../../../../modules/admin/hooks/useFormValidationSchema";

import { adminUpdateUser } from "../../../../../modules/player/services/UserService";

import { Button } from "../../../../../components/Button";
import { CustomerInfoInputFields } from "./components/CustomerInfoInputFields";

import { AdminPlayerInfoIcons } from "../AdminPlayerInfoIcons/AdminPlayerInfoIcons";

interface Props {
  user: User;
  isMember: boolean;
}

export const AdminPlayerCustomerInfoSection: React.FC<Props> = ({
  user,
  isMember,
}) => {
  const { adminEditUserInformationSchema } = useFormValidationSchema();
  const { formatMessage } = useIntl();
  const { mutate } = useUser(user.id);
  const { toastSuccess, toastError } = useToaster();
  const { countriesData } = useCountryCodes();

  const formik = useFormik({
    initialValues: {
      id: user.id,
      firstName: user.firstName || "",
      lastName: user.lastName || "",
      phoneNumber: user.phoneNumber || "",
      birthDate: user.birthDate,
      street: user.streetAddress || "",
      zipCode: user.zipCode || "",
      city: user.city || "",
      nationalIdentificationNumber: user.socialSecurityNumber || "",
      country:
        user.country &&
        countriesData?.some(({ countryCode }) => countryCode === user.country)
          ? user.country
          : "",

      skillLevel: user.skillLevel || 1,
    },
    onSubmit: async values => {
      try {
        mutate(
          async () => {
            await adminUpdateUser(values.country, { ...values, id: user.id });
            toastSuccess.userSaved();

            return {
              ...user,
              birthDate: values.birthDate,
              city: values.city,
              country: values.country,
              firstName: values.firstName,
              lastName: values.lastName,
              phoneNumber: values.phoneNumber,
              socialSecurityNumber: values.nationalIdentificationNumber,
              streetAddress: values.street,
              zipCode: values.zipCode,
              skillLevel: values.skillLevel,
            };
          },
          { revalidate: false },
        );
      } catch {
        toastError.updateUserFailed();
      }
    },
    validationSchema: adminEditUserInformationSchema,
    validateOnBlur: true,
    validateOnMount: true,
    enableReinitialize: true,
  });

  return (
    <div className="flex flex-col gap-y-8">
      <h3>
        <FormattedMessage id="admin.player.view.customer.info.title" />
      </h3>
      <form onSubmit={formik.handleSubmit} className="flex flex-col gap-y-8">
        <CustomerInfoInputFields
          errors={formik.errors}
          values={formik.values}
          handleChange={formik.handleChange}
          setFieldValue={formik.setFieldValue}
        />

        <AdminPlayerInfoIcons
          user={user}
          isMember={isMember}
          country={formik.values.country}
          setFieldValue={formik.setFieldValue}
        />

        <Button
          disabled={!formik.isValid || formik.isSubmitting || !formik.dirty}
          type="small"
          buttonType="button"
          onClick={formik.handleSubmit}
          text={formatMessage({ id: "common.save" })}
          className="w-32 self-end"
        />
      </form>
    </div>
  );
};
