import React, { useEffect, useState } from "react";

import styled from "styled-components";

import { Length } from "./components/Length";

const Container = styled.div`
  display: flex;
  gap: 0.5rem;
`;

interface Props {
  onSelectionChange: (v: number) => void;
  preSelected?: number;
}

export const GameLengthPicker: React.FC<Props> = ({
  onSelectionChange,
  preSelected = 90,
}) => {
  const lengths = [90, 60];
  const [selected, setSelected] = useState<number>(preSelected);

  useEffect(() => {
    onSelectionChange?.(selected);
  }, [selected]);

  return (
    <Container>
      {lengths?.map(length => (
        <Length
          setSelected={setSelected}
          selected={selected === length}
          key={length}
          length={length}
        />
      ))}
    </Container>
  );
};
