import { useAppDispatch } from "../../../hooks/store/store";
import { useAdminFacilities } from "../../../hooks/swr/useAdminFacilities";
import { useSelectedFacility } from "../../../hooks/swr/useSelectedFacility";

import { getFacility } from "../services/FacilityService";

import { useSelectedFacilityId } from "../../../recoil/selectedFacilityIdState";
import { setSelectedFacility } from "../../../store/slices/facilities/facilitiesSlice";

interface FacilityRefetch {
  refetchFacilities: () => void;
  facilityUpdatedCallback: (updateFunction: (e: any) => void) => void;
}

export const useRefetchFacilityInformation = (): FacilityRefetch => {
  const selectedFacilityId = useSelectedFacilityId();
  const dispatch = useAppDispatch();
  const { mutate: mutateAdminFacilities } = useAdminFacilities();
  const { mutate: mutateSelectedFacility } = useSelectedFacility();

  const refetchFacilities = () => mutateAdminFacilities();

  const facilityUpdatedCallback = async updateFunction => {
    updateFunction?.().then(async shouldUpdate => {
      if (!shouldUpdate) return;
      const result = await getFacility(selectedFacilityId);
      dispatch(setSelectedFacility({ facility: result.data }));
      mutateSelectedFacility(result.data, false);
    });
  };

  return { facilityUpdatedCallback, refetchFacilities };
};
