import React from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";

import { faCheck } from "@fortawesome/pro-light-svg-icons";

import { Button } from "../../../../components/Button";

interface Props {
  seriesName: string;
  facilityName: string;
}

export const LeavingSeriesConfirmation: React.FC<Props> = ({
  seriesName,
  facilityName,
}) => {
  const history = useHistory();
  const handleClose = () => {
    history.push("/");
  };

  return (
    <div className="flex flex-col bg-purewhite px-10 py-10 text-center md:px-20 md:py-20 xl:px-40 xl:py-40">
      <h2 className="mb-5 text-5xl">
        <FormattedMessage id="series.leave.series.thank.you" />
      </h2>
      <p className="text-lg">
        <FormattedMessage
          id="series.leave.series.confirmation.information"
          values={{
            br: <br />,
            seriesName: seriesName,
            facilityName: facilityName,
          }}
        />
      </p>
      <Button
        className="mx-auto my-10 w-32 bg-gradient-to-r from-blue-500 to-blue-700 py-4 pl-5 pr-4 text-sm text-purewhite shadow-m"
        type="default"
        icon={faCheck}
        translationName="common.close"
        onClick={handleClose}
      />
    </div>
  );
};
