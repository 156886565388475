import useSWR, { SWRConfiguration } from "swr";

import { GeneralActivities } from "../../modules/game/models/GeneralActivities";

import { getGeneralActivity } from "../../modules/game/services/GeneralActivities";

export const useGeneralActivity = (
  activityId?: GeneralActivities["id"] | null,
  include?: string,
  options?: SWRConfiguration,
) => {
  const { data, ...rest } = useSWR(
    activityId ? ["facilityActivities", activityId, include || ""] : null,
    ([, activityId, include]) =>
      getGeneralActivity(activityId, include).then(res => res.data),
    options,
  );

  return {
    ...rest,
    activity: data,
  };
};
