import React, { useState } from "react";
import { useDrag } from "react-dnd";
import { useIntl } from "react-intl";

import { faAngleDown, faAngleUp } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { SeriesTeam } from "../../../models/Series";

import { DndTypes } from "../../../helpers/DndTypes";

interface IProps {
  team: SeriesTeam;
}

const DivisionsEditorTeam: React.FC<IProps> = ({ team }) => {
  const [showTeamInfo, setShowTeamInfo] = useState(null);
  const { formatMessage } = useIntl();
  const [{ opacity }, drag] = useDrag(
    () => ({
      type: DndTypes.DivisionTeam,
      item: team,
      collect: monitor => ({
        opacity: monitor.isDragging() ? 0.4 : 1,
      }),
    }),
    [team],
  );

  return (
    <li
      className="cursor-pointer bg-gray-100 p-4 text-sm transition-colors hover:bg-gray-200"
      ref={drag}
      role="Box"
      style={{ opacity }}
      onClick={() => setShowTeamInfo(!showTeamInfo)}
    >
      <div className="align-start flex justify-between space-x-2">
        <div className="flex grow flex-col">
          {team?.teamMembers?.map((member, idx) => (
            <span key={member.id}>
              {member.displayName + (idx === 0 ? " &" : "")}
            </span>
          ))}
        </div>
        <span className="whitespace-nowrap">
          {formatMessage(
            { id: "activities.level" },
            { skillLevelSpan: team?.combinedSkillLevel ?? "" },
          )}
        </span>
        <FontAwesomeIcon
          icon={showTeamInfo ? faAngleUp : faAngleDown}
          className="pt-1"
        />
      </div>
      {showTeamInfo && (
        <div className="my-5 flex flex-col border-t border-gray-500">
          <h3 className="py-2">
            {formatMessage({ id: "common.contact-info" })}
          </h3>
          {team?.teamMembers?.map(member => (
            <div key={member.id} className="flex flex-col py-2">
              <span>{member.displayName}</span>
              <span>{member.phoneNumber}</span>
              <span> {member.emailAddress}</span>
            </div>
          ))}
        </div>
      )}
    </li>
  );
};

export default DivisionsEditorTeam;
