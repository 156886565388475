import React, { useEffect, useState } from "react";

import styled from "styled-components";

import { Weekday } from "./components/Weekday";

const Container = styled.div`
  display: flex;
  gap: 0.5rem;
`;

interface Props {
  onSelectionChange: (v: number[]) => void;
  preSelected?: number[];
}

const days = [1, 2, 3, 4, 5, 6, 0];

export const WeekdayPicker: React.FC<Props> = ({
  onSelectionChange,
  preSelected = [],
}) => {
  const [selected, setSelected] = useState<number[]>(preSelected);
  const [mouseDown, setMouseDown] = useState<string>();

  useEffect(() => {
    onSelectionChange?.(selected);
  }, [selected]);

  return (
    <Container
      onMouseLeave={() => setMouseDown(null)}
      onMouseUp={() => setMouseDown(null)}
    >
      {days?.map(day => (
        <Weekday
          setMouseDown={setMouseDown}
          mouseDown={mouseDown}
          setSelected={setSelected}
          selected={selected?.includes(day)}
          key={day}
          day={day}
        />
      ))}
    </Container>
  );
};
