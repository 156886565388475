import { useHistory } from "react-router-dom";

import { faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

import { User } from "../modules/player/models/User";

import { adminGetPlayerPath, getProfilePath } from "../helpers/pathHelpers";

import { useFacility } from "../hooks/swr/useFacility";
import { useIsAdmin } from "../hooks/swr/useIsAdmin";

import { AvatarNameWrapper } from "./AvatarNameWrapper";

interface StyleProps {
  $direction?: string;
  $margin?: string;
}

const UserWrapper = styled.div<StyleProps>`
  width: 100%;

  ul {
    list-style: none;
    display: flex;
    flex-direction: ${props =>
      props.$direction === "horizontal" ? "row" : "column"};
    gap: 1rem;
    padding: 0;
    margin: ${props => props.$margin};
    gap: 1rem;

    li {
      display: flex;
      align-items: center;
      flex: 1 1 auto;
      justify-content: space-between;
      flex: 0 0 auto;
      gap: 1rem;
    }
  }
`;

interface Props {
  users: User[];
  isDisabled?: boolean;
  enableRemove?: boolean;
  removeFromInitial?: boolean;
  errorText?: string;
  onRemove?: (e?: any) => any;
  direction?: "horizontal" | "vertical";
  margin?: string;
  hideEmail?: boolean;
  hideName?: boolean;
  goToProfileActive?: boolean;
  includeContact?: boolean;
  organizerId?: string;
  onClick?: (u: User) => void;
}

export const UsersList: React.FC<Props> = ({
  users,
  onRemove,
  errorText,
  removeFromInitial,
  isDisabled,
  enableRemove = true,
  direction = "vertical",
  margin = null,
  hideEmail,
  hideName,
  goToProfileActive,
  includeContact = false,
  organizerId = "",
  onClick,
}) => {
  const isAdmin = useIsAdmin();
  const history = useHistory();

  const handleGoToProfile = (user: User) => {
    history.push(
      isAdmin
        ? `${adminGetPlayerPath(user?.id)}`
        : `${getProfilePath(user?.id)}`,
    );
  };

  if (goToProfileActive) {
    onClick = handleGoToProfile;
  }

  return (
    <UserWrapper $direction={direction} $margin={margin}>
      <ul>
        {errorText && <span>{errorText}</span>}
        {users?.map((user, i) => (
          <UserListItem
            key={user.id}
            user={user}
            onClick={onClick}
            hideEmail={hideEmail}
            hideName={hideName}
            includeContact={includeContact}
            organizerId={organizerId}
            removeFromInitial={removeFromInitial}
            isDisabled={isDisabled}
            enableRemove={enableRemove}
            onRemove={onRemove}
            i={i}
          />
        ))}
      </ul>
    </UserWrapper>
  );
};

type UserProps = Omit<Props, "users"> & {
  user: User;
  i: number;
};

const UserListItem = ({
  user,
  onClick,
  hideEmail,
  hideName,
  includeContact,
  organizerId,
  removeFromInitial,
  isDisabled,
  enableRemove,
  onRemove,
  i,
}: UserProps) => {
  const { facility } = useFacility(user?.favoriteFacilityId);

  return (
    <li key={user.id} className="p-4 hover:bg-blue-50">
      <div
        className={onClick && "cursor-pointer"}
        onClick={() => onClick?.(user)}
      >
        <AvatarNameWrapper
          hideName={hideEmail || hideName}
          image={user?.profileImage}
          name={user?.displayName}
          firstName={user?.firstName}
          lastName={user?.lastName}
          title={user?.displayName}
          phoneNumber={user?.phoneNumber}
          emailAddress={user?.emailAddress}
          includeContact={includeContact}
          isPayed={user?.isPayed}
          isOrganizer={user?.id === organizerId}
          additionalInfo={facility?.name}
        />
      </div>
      {(removeFromInitial && i === 0) || isDisabled
        ? null
        : enableRemove && (
            <FontAwesomeIcon
              style={{ cursor: "pointer" }}
              icon={faTrashAlt}
              onClick={() => onRemove?.(user)}
            />
          )}
    </li>
  );
};
