import { useMemo } from "react";

import {
  type Series,
  SeriesDivision,
  SeriesTeam,
} from "../../../../../../modules/game/models/Series";

import { useToaster } from "../../../../../../hooks/common/useToaster";
import { useSeries } from "../../../../../../hooks/swr/useSeries";

import { addTeamToDivision } from "../../../../../../modules/game/services/Serie";

import { Card } from "../../../../../../components/Card";
import DivisionsEditorDivisions from "../../../../../../modules/game/components/series/divisions/DivisionsEditorDivisions";
import DivisionsEditorTeams from "../../../../../../modules/game/components/series/teams/DivisionEditorTeams";

export const DivisionsEditor = ({ seriesId }: { seriesId: Series["id"] }) => {
  const toaster = useToaster();

  const { series, mutate } = useSeries(seriesId, "participants");

  const teamsToDistribute = useMemo(() => {
    if (!series) {
      return [];
    }

    const distributedTeams: string[] = series.divisions?.reduce(
      (acc, division) => {
        return acc.concat(division.teams?.map(t => t.id));
      },
      [],
    );

    return series?.registeredTeams?.filter(
      team => !distributedTeams.includes(team.id),
    );
  }, [series]);

  const handleTeamDrop = async (division: SeriesDivision, team: SeriesTeam) => {
    mutate(
      async () => {
        const response = await addTeamToDivision({
          serieId: series.id,
          facilityId: series.facilityId,
          divisionId: division.id,
          teamId: team.id,
        });

        if (response.data) {
          toaster.toastSuccess.message("toast.success.division.added");
        }

        return response.data;
      },
      {
        optimisticData(currentData) {
          currentData.divisions
            .find((d: any) => d.id === division.id)
            .teams.push(team);

          currentData.registeredTeams = currentData.registeredTeams.filter(
            (t: any) => t.id !== team.id,
          );

          return currentData;
        },
        rollbackOnError() {
          toaster.toastError.addTeamToDivisionFailed();
          return true;
        },
      },
    );
  };

  return (
    <Card>
      <div className="gap-16 p-2 md:flex xl:gap-28">
        <DivisionsEditorTeams teams={teamsToDistribute} />
        <DivisionsEditorDivisions
          serie={series}
          reload={mutate}
          onDrop={handleTeamDrop}
        />
      </div>
    </Card>
  );
};
