import React, { Dispatch, SetStateAction, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import { faCircleArrowRight } from "@fortawesome/pro-solid-svg-icons";

import { BuyableType } from "../../../../modules/checkout/models/Payment";
import { NextSeasonInfo } from "../../../../modules/game/models/Series";
import { User } from "../../../../modules/player/models/User";

import { getCheckoutPath } from "../../../../helpers/pathHelpers";

import { useToaster } from "../../../../hooks/common/useToaster";
import { useCurrencyFormat } from "../../../../hooks/useCurrencyFormat";
import { useDateFormat } from "../../../../hooks/useDateFormat";

import { cancelActivity } from "../../../../modules/game/services/Activities";
import { createOrder } from "../../../../services/myOrdersService";

import { Button } from "../../../../components/Button";
import { ConfirmationDialog } from "../../../../components/ConfirmationDialog";

import { luxonDateFormat } from "../../../../utils/dateFormats";

interface Props {
  seasonInfo: NextSeasonInfo;
  currentUser: User;
  isPayed: boolean;
  setShowCancelledConfirmation: Dispatch<SetStateAction<boolean>>;
  isTeamCaptain: boolean;
  isCaptainPayed: boolean;
}

export const SeasonInfo: React.FC<Props> = ({
  seasonInfo,
  currentUser,
  isPayed,
  setShowCancelledConfirmation,
  isTeamCaptain,
  isCaptainPayed,
}) => {
  const { id, facilityName, price, startTime, registrationOpenTo, facilityId } =
    seasonInfo;

  const history = useHistory();
  const toaster = useToaster();
  const intl = useIntl();
  const { df } = useDateFormat(facilityId);
  const { cf } = useCurrencyFormat(seasonInfo.price.currencyCode);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);

  const handlePayment = async () => {
    if (id) {
      try {
        const order = await createOrder(facilityId, {
          $type: "series",
          seriesId: id,
        });

        history.push(getCheckoutPath(order.id));
      } catch (err) {
        console.log(err);
        toaster.toastError.generalFailure();
      }
    }
  };

  const handleLeaveSeries = async () => {
    try {
      const result = await cancelActivity(
        id,
        BuyableType.Series,
        currentUser.id,
      );
      if (result) {
        setShowCancelledConfirmation(true);
      }
    } catch (err) {
      console.log(err);
      setShowConfirmation(false);
      toaster.toastError.cancelBookingFailed();
    }
  };

  return (
    <div className="grid-col-1 mt-8 grid border border-gray-50 px-4 pb-5 pt-12 sm:grid-cols-2 lg:px-8">
      <div>
        <p className="text-sm text-gray-700">
          <FormattedMessage id="common.location" />
        </p>
        <p className="mb-6 text-xl font-bold">{facilityName}</p>
        <p className="text-sm text-gray-700">
          <FormattedMessage id="generalActivities.createActivity.fee-per-person" />
        </p>
        <p className="mb-6 text-xl font-bold">{cf(price.valueInclTax)}</p>
        <div className="flex">
          <div className="mr-8">
            <p className="text-sm text-gray-700">
              <FormattedMessage id="common.start-date" />
            </p>
            <p className="text-xl font-bold">
              {df(startTime, luxonDateFormat)}
            </p>
          </div>
          <div>
            <p className="text-sm text-gray-700">
              <FormattedMessage id="series.last.date.notice" />
            </p>
            <p className="mb-6 text-xl font-bold">
              {df(registrationOpenTo, luxonDateFormat)}
            </p>
          </div>
        </div>
      </div>
      <div className="mt-4 flex w-full flex-col items-center sm:ml-auto sm:justify-end">
        {isPayed ? (
          <div className="mb-4 rounded bg-gradient-to-r from-green-500 to-green-700 px-8 py-2 text-purewhite shadow-m">
            <FormattedMessage id="series.paid" />
          </div>
        ) : (
          (isTeamCaptain || (!isTeamCaptain && isCaptainPayed)) && (
            <Button
              className="button-primary mb-4 w-max bg-gradient-to-r from-blue-500 to-blue-700 text-purewhite shadow-m"
              text="Fortsätt till betalning"
              translationName="common.continue-to-payment"
              icon={faCircleArrowRight}
              onClick={handlePayment}
            />
          )
        )}
        {isTeamCaptain && (
          <p
            className="cursor-pointer text-gray-700 underline"
            onClick={() => setShowConfirmation(true)}
          >
            <FormattedMessage id="series.leave.series" />
          </p>
        )}
      </div>
      <ConfirmationDialog
        text={intl.formatMessage({
          id: "series.are.you.sure.leave.series",
        })}
        confirmText={intl.formatMessage({
          id: "series.leave.series",
        })}
        visible={showConfirmation}
        onHide={() => setShowConfirmation(false)}
        onCancel={() => setShowConfirmation(false)}
        onSubmit={handleLeaveSeries}
      />
    </div>
  );
};
