import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";

import styled from "styled-components";

import {
  PriceListResponseWithUTC,
  PricingCalendarPayload,
  Slot,
  WeekDays,
} from "../../../models/Pricing";

import { useSelectablePriceCalendar } from "../../../hooks/calendar/pricing/useSelectablePriceCalendar";
import { useSelectedPriceSlots } from "../../../hooks/calendar/pricing/useSelectedPriceSlots";

import PriceCalendarSlot from "./PriceCalendarSlot";

const DayContainer = styled.div`
  display: flex;
  justify-content: center;
  background: var(--gray-100);
  align-items: center;
  font-weight: var(--bold);
  position: -webkit-sticky; /* Safari */
  position: sticky;
  flex: 0 0 10%;
  overflow: hidden;
  white-space: nowrap;
  height: 100%;
  font-size: 0.9rem;
`;

const Container = styled.div`
  border-right: 1px solid var(--gray-200);
  border-bottom: 1px solid var(--gray-200);
  display: flex;
  height: 2.5rem;
`;
interface IProps {
  selectedCategoryColor: string;
  selectedCategory: string;
  schedules: WeekDays[];
  priceList: PriceListResponseWithUTC;
  requiredGapSize?: number;
  onSelect: (payload: PricingCalendarPayload) => void;
}

const weekday = [
  { id: "week-day.sunday", defaultMessage: "Söndag" },
  { id: "week-day.monday", defaultMessage: "Måndag" },
  { id: "week-day.tuesday", defaultMessage: "Tisdag" },
  { id: "week-day.wednesday", defaultMessage: "Onsdag" },
  { id: "week-day.thursday", defaultMessage: "Torsdag" },
  { id: "week-day.friday", defaultMessage: "Fredag" },
  { id: "week-day.saturday", defaultMessage: "Lördag" },
];

const PriceCalendarBody: React.FC<IProps> = ({
  selectedCategoryColor,
  selectedCategory,
  schedules,
  priceList,
  onSelect,
}) => {
  const {
    state: { row, start, end, selecting },
    beginSelection,
    updateSelection,
    endSelection,
    resetSelection,
  } = useSelectablePriceCalendar();
  const selectedSlots =
    useSelectedPriceSlots(row, selectedCategory, start, end, schedules) ?? [];

  useEffect(() => {
    if (!selecting) {
      let payload: PricingCalendarPayload = null;
      if (row !== -1 && selectedSlots.length > 0) {
        payload = getPayload();
      }
      resetSelection();
      onSelect(payload);
    }
  }, [selecting, row]);

  const getPayload = () => {
    const payload: PricingCalendarPayload = {
      categoryId: selectedSlots[0].categoryId,
      startTime: selectedSlots[0].startTime,
      dayId: schedules[row].dayOfWeek,
      endTime: selectedSlots[selectedSlots.length - 1].endTime,
    };
    return payload;
  };

  const isSlotSelected = (rowIndex: number, slot: Slot) =>
    row === rowIndex &&
    selectedSlots.length > 0 &&
    selectedSlots[0].startTime === slot.startTime &&
    selectedSlots[0].endTime === slot.endTime;
  const getSlotColor = slot => {
    const index = priceList.categories?.findIndex(
      ({ id }) => id === slot.categoryId,
    );

    return slot.categoryId
      ? index < 5
        ? `hsl(218, 100%, ${89 - (index % 5) * 15}%)`
        : `repeating-linear-gradient( 180deg, hsl(218, 100%, ${
            89 - (index % 4) * 15
          }%), hsl(218, 100%, ${89 - (index % 4) * 15}%) 5px, hsl(218, 100%, ${
            89 - (Math.ceil(index / 2) % 5) * 15
          }%) 5px, hsl(218, 100%, ${
            89 - (Math.ceil(index / 2) % 5) * 15
          }%) 11px )`
      : "hsl(218,100%,89%)";
  };

  return (
    <>
      {priceList &&
        selectedSlots &&
        schedules?.map((day, idx) => (
          <Container key={day.dayOfWeek}>
            <DayContainer>
              <FormattedMessage {...weekday[day.dayOfWeek]} />
            </DayContainer>
            {day.slots.map((slot, subIdx) => (
              <PriceCalendarSlot
                key={`${idx}-${subIdx}`}
                row={idx}
                column={subIdx}
                color={getSlotColor(slot)}
                selectedColor={selectedCategoryColor}
                width={selectedSlots.length}
                isSelected={isSlotSelected(idx, slot)}
                onBeginSelection={beginSelection}
                onEndSelection={endSelection}
                onUpdateSelection={updateSelection}
              />
            ))}
          </Container>
        ))}
    </>
  );
};

export default PriceCalendarBody;
