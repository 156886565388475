import { useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { faXmarkLarge } from "@fortawesome/pro-light-svg-icons";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";

import { FacilityAdminUser } from "../../../../../../models/Permissions";
import {
  GeneralActivities,
  GeneralActivityRequest,
} from "../../../../../../modules/game/models/GeneralActivities";
import { User } from "../../../../../../modules/player/models/User";

import { useSelectedFacilityAdminUsers } from "../../../../../../hooks/swr/useFacilityAdminUsers";

import { ProfileImageWithFallback } from "../../../../../../components/ProfileImageWithFallback";
import { UserSearch } from "../../../../../../components/UserSearch";

import { useSelectedFacilityId } from "../../../../../../recoil/selectedFacilityIdState";

interface Props {
  defaultValue: GeneralActivityRequest["coordinators"];
  onChange: (coordinators: GeneralActivityRequest["coordinators"]) => void;
}

interface UserItemProps {
  user: Pick<
    User,
    | "id"
    | "firstName"
    | "lastName"
    | "displayName"
    | "emailAddress"
    | "phoneNumber"
    | "profileImage"
  >;
  selectable?: boolean;
  selected?: boolean;
  showContactDetails?: boolean;
  onClick?: (user: UserItemProps["user"]["id"]) => void;
}

export const CoordinatorSelector = ({ defaultValue, onChange }: Props) => {
  const selectedFacilityId = useSelectedFacilityId();
  const { adminUsers } = useSelectedFacilityAdminUsers();
  const [coordinators, setCoordinators] = useState<
    (GeneralActivities["coordinators"][number] | FacilityAdminUser | User)[]
  >(defaultValue || []);
  const { formatMessage } = useIntl();

  const handleAdminSelect = (user: any) => {
    if (!coordinators.some(coordinator => coordinator.id === user.id)) {
      setCoordinators(coordinators.concat(user));
    } else {
      setCoordinators(
        coordinators.filter(coordinator => coordinator.id !== user.id),
      );
    }
  };

  useEffect(() => {
    const mappedUsers = coordinators.map(user => {
      return {
        id: user.id,
        firstName: user.firstName || "",
        lastName: user.lastName || "",
        displayName: user.displayName || "",
        emailAddress: user.emailAddress || "",
        phoneNumber: user.phoneNumber || "",
        profileImage: user.profileImage || "",
        skillLevel: user.skillLevel || 1,
      };
    });
    onChange(mappedUsers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coordinators]);

  return (
    <div className="grid gap-x-16 gap-y-12 after:absolute after:h-4 after:bg-blue-500 lg:grid-cols-2 lg:gap-y-12">
      <UserSearch
        displaySelection={false}
        multiSelect={false}
        facilityId={selectedFacilityId}
        filterUsers={adminUsers || []}
        translationId="search.profile"
        onChange={user => {
          setCoordinators(coordinators.concat(user));
        }}
      />

      <div className="flex flex-col gap-4 lg:order-last">
        <h4 className="text-base">
          {formatMessage({ id: "admin.venue-administrators" })}
        </h4>
        <div className="flex max-h-[300px] flex-col gap-4 overflow-auto pr-4">
          {adminUsers?.map(admin => (
            <UserItem
              key={admin.id}
              user={admin}
              selectable
              selected={coordinators.some(c => c.id === admin.id)}
              onClick={() => {
                handleAdminSelect(admin);
              }}
            />
          ))}
        </div>
      </div>

      <div className="flex flex-col gap-4 lg:row-span-2">
        <h4 className="text-base">
          {formatMessage({ id: "admin.selected-coordinators" })}
        </h4>
        <div>
          {coordinators.map(coordinator => (
            <div key={coordinator.id} className="flex justify-between">
              <UserItem user={coordinator} showContactDetails />
              <button
                type="button"
                onClick={() =>
                  setCoordinators(
                    coordinators.filter(c => c.id !== coordinator.id),
                  )
                }
              >
                <FontAwesomeIcon
                  icon={faXmarkLarge}
                  className="hover:text-gray-500"
                />
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const UserItem = ({
  user,
  selectable,
  selected,
  showContactDetails,
  onClick,
}: UserItemProps) => {
  const [isSelected, setIsSelected] = useState(selectable && selected);

  useEffect(() => {
    setIsSelected(!!selected);
  }, [selected]);

  const isSelectableClasses = clsx(
    "cursor-pointer px-5 hover:bg-blue-50",
    isSelected
      ? "border border-blue-500 bg-blue-50"
      : "border border-transparent bg-white",
  );

  const classes = clsx(
    "flex flex-grow items-center gap-4 rounded py-2.5",
    selectable && isSelectableClasses,
  );

  return (
    <div
      className={classes}
      onClick={() => {
        onClick?.(user.id);
        selectable && setIsSelected(!isSelected);
      }}
    >
      <ProfileImageWithFallback
        src={user.profileImage}
        firstName={user.firstName}
        lastName={user.lastName}
      />
      <div className="flex flex-grow flex-col">
        <span className="text-md font-bold">{user.displayName}</span>

        {showContactDetails && (
          <div className="flex flex-grow flex-col flex-wrap gap-x-3 text-gray-700 lg:flex-row">
            {user.emailAddress && (
              <a
                className="font-normal text-inherit hover:text-gray-500"
                href={`mailto:${user.emailAddress}`}
              >
                {user.emailAddress}
              </a>
            )}

            {user.emailAddress && user.phoneNumber && (
              <span className="hidden lg:inline">|</span>
            )}

            {user.phoneNumber && (
              <a
                className="font-normal text-inherit hover:text-gray-500"
                href={`tel:${user.phoneNumber}`}
              >
                {user.phoneNumber}
              </a>
            )}
          </div>
        )}
      </div>
      {selectable && isSelected && (
        <FontAwesomeIcon icon={faCheck} className="text-primary" />
      )}
    </div>
  );
};
