import { useState } from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";

import {
  faArrowRight,
  faCircleArrowUp,
} from "@fortawesome/pro-light-svg-icons";
import { faChain } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Tooltip } from "primereact/tooltip";

import { GenderType } from "../../../../../models/common";
import { BuyableType } from "../../../../../modules/checkout/models/Payment";
import { FacilityWithUtc } from "../../../../../modules/customer/models/Facility";
import { IActivity } from "../../../../../modules/game/models/Activity";

import { useToaster } from "../../../../../hooks/common/useToaster";
import { useDateFormat } from "../../../../../hooks/useDateFormat";

import { ProgressSpinner } from "../../../../../components/ProgressSpinner";
import AdminSeriesTableActionsColumn from "../../../../../modules/game/components/series/columns/AdminSeriesTableActionsColumn";
import AdminSeriesTableNameColumn from "../../../../../modules/game/components/series/columns/AdminSeriesTableNameColumn";
import AdminSeriesTableRegistrationColumn from "../../../../../modules/game/components/series/columns/AdminSeriesTableRegistrationColumn";

import {
  luxonDateFormat,
  luxonDateTimeFormat,
} from "../../../../../utils/dateFormats";

interface Props {
  facilityId: FacilityWithUtc["id"];
  activities: IActivity[] | undefined;
  isLoading: boolean;
  refresh?: () => void;
}

export const AdminActivitiesTableView = ({
  facilityId,
  activities,
  isLoading,
  refresh,
}: Props) => {
  const intl = useIntl();
  const { df } = useDateFormat(facilityId);
  const toaster = useToaster();

  const [showActionButtons, setShowActionButtons] = useState<string>("");

  const handleShowActionButtons = (activityId: string) => {
    if (activityId == showActionButtons) setShowActionButtons("");
    else setShowActionButtons(activityId);
  };

  return isLoading ? (
    <ProgressSpinner fontSize="1rem" />
  ) : (
    <>
      <DataTable
        dataKey="id"
        loading={isLoading}
        value={activities}
        showGridlines
        stripedRows
        responsiveLayout="scroll"
        rows={activities?.length}
      >
        <Column
          body={(activity: IActivity) => (
            <AdminSeriesTableNameColumn activity={activity} />
          )}
          header={intl.formatMessage({
            id: "common.name",
            defaultMessage: "Namn",
          })}
        />
        <Column
          body={(activity: IActivity) => (
            <AdminSeriesTableRegistrationColumn
              serie={activity}
              onChange={(activityId, openForRegistration) => {
                if (!activities) {
                  return;
                }

                const index = activities.findIndex(
                  activity => activity.id === activity.id,
                );

                if (index !== -1) {
                  activities[index].openForRegistration = openForRegistration;
                }
              }}
            />
          )}
          header={intl.formatMessage({
            id: "admin-series-table.registration-status-column",
          })}
        />
        <Column
          body={(activity: IActivity) => (
            <span className="flex flex-nowrap items-center gap-4">
              {intl.formatMessage({
                id: `common.${BuyableType[
                  activity.buyableType
                ]?.toLowerCase()}`,
                defaultMessage: BuyableType[activity.buyableType],
              })}
              {activity.buyableType === BuyableType.Event &&
                activity.priority && (
                  <>
                    <Tooltip
                      target={"#prioritized-event-icon" + activity.id}
                      content={intl.formatMessage({
                        id: "admin.activities.prioritized-event-tooltip",
                      })}
                    />
                    <FontAwesomeIcon
                      id={"prioritized-event-icon" + activity.id}
                      icon={faCircleArrowUp}
                      className="text-primary"
                    />
                  </>
                )}
            </span>
          )}
          field="TypeOfActivity"
          header={intl.formatMessage({
            id: "admin-series-table.typeOfActivity",
          })}
        />
        <Column
          body={(activity: IActivity) =>
            df(
              activity.startTime,
              activity.buyableType === BuyableType.Event
                ? luxonDateTimeFormat
                : luxonDateFormat,
            )
          }
          header={intl.formatMessage({
            id: "common.start-date",
          })}
        />
        <Column
          body={(activity: IActivity) =>
            df(activity.startTime, { weekday: "long" })
          }
          header={intl.formatMessage({
            id: "common.weekday",
          })}
        />
        <Column
          body={(activity: IActivity) => (
            <span>
              {intl.formatMessage({
                id: `gender-types.${GenderType[
                  activity.gender
                ]?.toLowerCase()}`,
                defaultMessage: GenderType[activity.gender],
              })}
            </span>
          )}
          field="gender"
          header={intl.formatMessage({
            id: "admin-series-table.type-column",
            defaultMessage: "Typ av serie",
          })}
        />
        <Column
          body={(activity: IActivity) =>
            df(activity.registrationOpenTo, luxonDateFormat)
          }
          header={intl.formatMessage({
            id: "admin-series-table.registration-open-to-column",
          })}
        />
        <Column
          body={(activity: IActivity) =>
            activity.buyableType === BuyableType.Series ? (
              <p>{`${
                activity?.participants?.length / 2
              } / ${activity?.maxParticipantsCount}`}</p>
            ) : (
              <p>{`${activity?.participants?.length} / ${activity?.maxParticipantsCount}`}</p>
            )
          }
          header={intl.formatMessage({ id: "series.signed-up-players" })}
        />
        <Column
          body={(activity: IActivity) =>
            activity.buyableType === BuyableType.Series ? (
              <AdminSeriesTableActionsColumn
                serie={activity}
                handleShowActionButtons={handleShowActionButtons}
                showActionButtons={showActionButtons}
                refreshData={() => refresh?.()}
              />
            ) : process.env.REACT_APP_WEBSITE_URL ? (
              <div className="flex gap-3">
                <Link to={"/admin/activities/event/" + activity.id}>
                  <FontAwesomeIcon icon={faArrowRight} />
                </Link>
                <button
                  type="button"
                  onClick={() => {
                    const url = new URL(
                      process.env.REACT_APP_WEBSITE_URL ?? "",
                    );
                    url.pathname = `/event/${activity?.id}`;

                    navigator.clipboard.writeText(url.toString()).then(() => {
                      toaster.toastSuccess.message("common.link.copied");
                    });
                  }}
                  className="text-primary transition-colors hover:text-primary-dark"
                  id={`copy-event-link-${activity.id}`}
                >
                  <FontAwesomeIcon icon={faChain} />
                </button>
                <Tooltip
                  position="bottom"
                  target={`#copy-event-link-${activity.id}`}
                  content={intl.formatMessage({ id: "common.copy-share-link" })}
                />
              </div>
            ) : null
          }
        />
      </DataTable>
    </>
  );
};
