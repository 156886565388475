import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { faSearch } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { debounce } from "lodash";

import { FacilityWithUtc } from "../../modules/customer/models/Facility";

import { getFacilityAddress } from "../../helpers/facilityHelpers";

import { useIsMobile } from "../../hooks/common/useIsMobile";

import { queryFacilities } from "../../modules/customer/services/FacilityService";

import { LogoWithFallback } from "../LogoWithFallback";
import { ProgressSpinner } from "../ProgressSpinner";

const search = async (input: string, cb: (data: FacilityWithUtc[]) => any) => {
  try {
    const { data } = await queryFacilities(input);
    cb(data);
  } catch {
    cb([]);
  }
};

const debounceQueryFacilities = debounce((input, cb) => {
  search(input, cb);
}, 500);

const initialSelectedResult = { id: "", name: "" };

interface Props {
  showResults?: boolean;
  onInputChange?: (e?: any) => any;
  onResultChange?: (e?: any) => any;
  onSelectResult?: (e?: any) => any;
  onSearchingChange?: (e?: any) => any;
}

export const VenueSearch: React.FC<Props> = ({
  showResults = true,
  onInputChange,
  onResultChange,
  onSelectResult,
  onSearchingChange,
}) => {
  const intl = useIntl();
  const isMobile: boolean = useIsMobile();
  const [searchInput, setSearchInput] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [selectedResult, setSelectedResult] = useState(initialSelectedResult);
  const [isSearching, setIsSearching] = useState(false);
  const [shouldFetch, setShouldFetch] = useState(false);
  const [loading, setLoading] = useState(false);
  const searchInputRef = useRef(null);

  useEffect(() => onInputChange?.(searchInput), [searchInput]);
  useEffect(() => onResultChange?.(searchResults), [searchResults]);
  useEffect(() => onSelectResult?.(selectedResult), [selectedResult]);
  useEffect(() => onSearchingChange?.(isSearching), [isSearching]);
  useEffect(() => {
    setShouldFetch(false);
    setSearchInput(selectedResult.name);
  }, [selectedResult.name]);

  useEffect(() => {
    if (searchInput && shouldFetch) {
      setLoading(true);
      debounceQueryFacilities(searchInput, result => {
        setLoading(false);
        !showResults && setIsSearching(false);
        setSearchResults(result);
      });
    }
  }, [searchInput, showResults]);

  useLayoutEffect(() => {
    if (isMobile) {
      window.scrollTo(0, 0);
    } else {
      searchInputRef.current?.focus();
    }
  }, []);

  return (
    <div className="col-start-1 col-end-[-1] row-start-1 row-end-2 w-full bg-purewhite">
      <div className="relative flex items-center gap-2 border-b-2 border-blue-300">
        <FontAwesomeIcon
          icon={faSearch}
          className={`${searchInput ? "text-black" : "text-gray-600"}`}
        />
        <input
          ref={searchInputRef}
          className={`w-full rounded-none border-0 bg-purewhite px-0 py-2 text-base font-bold placeholder:text-base focus:rounded-none focus:outline-none active:rounded-none active:outline-none md:text-xl ${
            searchInput
              ? "text-pureblack placeholder:text-pureblack"
              : "text-gray-600 placeholder:text-gray-600"
          }`}
          id="search-input"
          placeholder={intl.formatMessage({
            id: "search.venue-or-city",
            defaultMessage: "Sök efter en anläggning eller stad",
          })}
          value={searchInput}
          onChange={e => {
            if (e.target.value) {
              setIsSearching(true);
              setShouldFetch(true);
              setSearchInput(e.target.value);
            } else {
              setIsSearching(false);
              setShouldFetch(false);
              setSearchInput("");
            }
          }}
        />
      </div>
      {showResults && searchInput && (!selectedResult || isSearching) && (
        <div style={{ position: "relative", height: 0 }}>
          <div className="absolute z-[100] flex max-h-[40vh] w-full flex-col gap-4 overflow-auto overflow-x-hidden rounded-b-10 rounded-t-none bg-purewhite p-4 shadow-[1.25rem_1.25rem_2.5rem_rgba(0,0,0,0.3)]">
            {loading ? (
              <div className="[&>div]:mx-auto [&>div]:my-8 [&>div]:text-4xl">
                <ProgressSpinner />
              </div>
            ) : searchResults.length ? (
              searchResults.map(({ id, logo, name, address }, key) => (
                <div
                  className="flex cursor-pointer items-center justify-start gap-4"
                  key={key}
                  onClick={() => {
                    setIsSearching(false);
                    setSelectedResult({ id, name });
                  }}
                >
                  <LogoWithFallback src={logo} width="4rem" height="4rem" />
                  <div className="flex flex-col items-start justify-center gap-2">
                    <span>{name}</span>
                    <span style={{ fontWeight: 400 }}>
                      {getFacilityAddress(address)}
                    </span>
                  </div>
                </div>
              ))
            ) : (
              <span>
                <FormattedMessage
                  id="search.no-results"
                  defaultMessage="Inga resultat"
                />
              </span>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
