import { useIntl } from "react-intl";

import { number, object, string } from "yup";

import { commonRegex } from "../../../helpers/commonRegex";

export const useFormValidationSchema = () => {
  const intl = useIntl();
  const getTranslation = (id: string, defaultMessage = "") =>
    intl.formatMessage({ id, defaultMessage });

  const adminEditUserInformationSchema = object().shape({
    firstName: string()
      .required(getTranslation("validation.required.firstname"))
      .matches(commonRegex.name, getTranslation("validation.invalid.name")),
    lastName: string()
      .required(getTranslation("validation.required.lastname"))
      .matches(commonRegex.name, getTranslation("validation.invalid.name")),
    phoneNumber: string().matches(
      commonRegex.phoneNumber,
      getTranslation("validation.invalid.phone"),
    ),
    nationalIdentificationNumber: string()
      .notRequired()
      .when("country", {
        is: "SE",
        then: schema =>
          schema.matches(
            commonRegex.personalIdentificationNumber.SE,
            getTranslation("validation.national-identification-number.invalid"),
          ),
      })
      .when("country", {
        is: "FI",
        then: schema =>
          schema.matches(
            commonRegex.personalIdentificationNumber.FI,
            getTranslation("validation.national-identification-number.invalid"),
          ),
      }),
    zipCode: string().matches(
      commonRegex.postalCode,
      getTranslation("validation.invalid.postalCode"),
    ),
    city: string().matches(
      /^[a-öA-Ö]+$/,
      getTranslation("validation.invalid.city"),
    ),
    country: string().required(getTranslation("validation.required.country")),
    skillLevel: number()
      .required()
      .min(1)
      .max(10)
      .test(
        "maxDigitsAfterDecimal",
        intl.formatMessage(
          { id: "common.validation.max-x-decimals" },
          { maxDecimalCount: 1 },
        ),
        number => Number.isInteger(number * 10 ** 1),
      ),
  });

  return { adminEditUserInformationSchema };
};
