import { DateTime } from "luxon";

import { DateOnly } from "../../../models/DateOnly";
import { ApiSingleResponse } from "../../../models/common";
import {
  Access,
  AccessApiResponse,
  DefaultVendorAccess,
  DefaultVendorAccessResponse,
  VendorType,
} from "../models/Access";

import { dateTimeToUtcIsoSanitizedToMinutes } from "../../../helpers/dateTime";

import { fetchApi } from "../../../services/legacyApiClient";

const apiVersion = "customer/v2";

export const getAccessTimes = async (
  facilityId: string,
): Promise<ApiSingleResponse<Access[]>> => {
  const response = (await fetchApi({
    method: "GET",
    uri: `${apiVersion}/access/${facilityId}`,
  })) as ApiSingleResponse<AccessApiResponse[]>;

  return {
    ...response,
    data: response.data.map(formatAccessFromApi),
  };
};

export const addAccessTime = async (
  facilityId: string,
  startTime: DateTime,
  endTime: DateTime,
): Promise<ApiSingleResponse<Access[]>> => {
  const startTimeString = dateTimeToUtcIsoSanitizedToMinutes(startTime);
  const endTimeString = dateTimeToUtcIsoSanitizedToMinutes(endTime);

  const response = (await fetchApi({
    method: "POST",
    uri: `${apiVersion}/access/${facilityId}/${startTimeString}/${endTimeString}`,
  })) as ApiSingleResponse<AccessApiResponse[]>;

  return {
    ...response,
    data: response.data.map(formatAccessFromApi),
  };
};

export const removeAccessTime = async (
  facilityId: string,
  commandId: string,
): Promise<ApiSingleResponse<Access[]>> => {
  const response = (await fetchApi({
    method: "DELETE",
    uri: `${apiVersion}/access/${facilityId}/${commandId}`,
  })) as ApiSingleResponse<AccessApiResponse[]>;

  return {
    ...response,
    data: response.data.map(formatAccessFromApi),
  };
};

export const getVendorType = async (
  facilityId: string,
): Promise<ApiSingleResponse<VendorType>> => {
  const response = (await fetchApi({
    method: "GET",
    uri: `${apiVersion}/access/getvendortype/${facilityId}`,
  })) as ApiSingleResponse<VendorType>;

  return response;
};

export const getDefaultVendorAccessTimes = async (
  facilityId: string,
): Promise<ApiSingleResponse<DefaultVendorAccess[]>> => {
  const response = (await fetchApi({
    method: "GET",
    uri: `${apiVersion}/access/pincodeinfo/${facilityId}`,
  })) as ApiSingleResponse<DefaultVendorAccessResponse[]>;

  return {
    ...response,
    data: response.data.map(formatDefaultVendorAccessFromApi),
  };
};

export const saveDefaultVendorAccessDetails = async (
  facilityId: string,
  payload: DefaultVendorAccess[],
): Promise<ApiSingleResponse<DefaultVendorAccess[]>> => {
  const formattedPayload = payload.map(item => ({
    ...item,
    validFrom: item.validFrom.toJSON(),
    validTo: item.validTo && item.validTo.toJSON(),
  }));

  const response = (await fetchApi({
    method: "POST",
    uri: `${apiVersion}/access/defaultvendorpincodes/${facilityId}`,
    payload: { PinCodeInfo: formattedPayload },
  })) as ApiSingleResponse<DefaultVendorAccessResponse[]>;

  return {
    ...response,
    data: response.data.map(formatDefaultVendorAccessFromApi),
  };
};

const formatAccessFromApi = (access: AccessApiResponse): Access => ({
  ...access,
  time: {
    ...access.time,
    from: DateTime.fromISO(access.time.from, { setZone: true }),
    to: DateTime.fromISO(access.time.to, { setZone: true }),
  },
});

const formatDefaultVendorAccessFromApi = (
  defaultVendorAccess: DefaultVendorAccessResponse,
): DefaultVendorAccess => ({
  ...defaultVendorAccess,
  validFrom: DateOnly.fromISODate(defaultVendorAccess.validFrom),
  validTo: defaultVendorAccess.validTo
    ? DateOnly.fromISODate(defaultVendorAccess.validTo)
    : null,
});
