import React, { useCallback, useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import { debounce } from "lodash";
import { DataTable as PrimeReactDataTable } from "primereact/datatable";
import styled from "styled-components";

import {
  AdminOverviewUser,
  AdminOverviewUsersCount,
  AdminOverviewUsersFilters,
  AdminOverviewUsersSorting,
} from "../../../modules/player/models/User";

import { adminGetPlayerPath } from "../../../helpers/pathHelpers";

import { useIsMobile } from "../../../hooks/common/useIsMobile";
import { useToaster } from "../../../hooks/common/useToaster";
import { useDateFormat } from "../../../hooks/useDateFormat";

import {
  getUserCSVExport,
  getUsersCountForAdminOverview,
  getUsersForAdminOverview,
} from "../../../modules/player/services/UserService";

import { AvatarNameWrapper } from "../../../components/AvatarNameWrapper";
import { Button } from "../../../components/Button";
import { Card } from "../../../components/Card";
import { DataTable } from "../../../components/DataTable/DataTable";
import { Dialog } from "../../../components/Dialog";
import { AdminAddNewPlayer } from "./components/AdminAddNewPlayer";
import { AdminPlayersOverviewTableHeader } from "./components/AdminPlayersOverviewTableHeader";
import { AdminSendMessageForm } from "./components/AdminSendMessageForm";

import { useSelectedFacilityId } from "../../../recoil/selectedFacilityIdState";
import { luxonDateFormat } from "../../../utils/dateFormats";

const PlayersOverviewContainer = styled(Card)`
  padding: 0;
  padding-top: 4rem;
  gap: 0;

  table {
    thead th .p-column-title {
      color: black !important;
      font-size: 1rem;
    }

    th,
    td {
      border: none !important;
      border-right: 1px solid #e9ecef !important;
    }
  }

  .p-sortable-column-icon {
    font-size: 0.8rem !important;
    margin-left: 0.2rem !important;
    color: black !important;
  }

  .p-checkbox {
    height: 0.8rem;
    width: 0.8rem;
    .p-checkbox-box {
      border: 1px solid var(--gray-300);
      height: 0.8rem;
      width: 0.8rem;

      &:focus {
        border: 1px solid var(--gray-300);
      }
      &.p-highlight {
        border: none;
        background: var(--primary);
        span {
          display: none;
        }
      }
    }

    .p-focus,
    .p-checkbox-selected {
      box-shadow: none !important;
      border: 1px solid var(--gray-300);
    }
  }
`;

const MobilePlayersOverviewContainer = styled(PlayersOverviewContainer)`
  padding-top: 3rem;

  table {
    thead th {
      font-size: 0.8rem !important;
      padding: 1rem !important;
      font-weight: var(--medium) !important;
      height: 20px !important;

      .p-column-title {
        font-size: 0.8rem;
      }
    }

    tbody tr td {
      padding: 0.5rem 1rem !important;
      font-size: 0.8rem;
      font-weight: var(--medium) !important;
      height: 20px !important;

      button.p-row-toggler {
        width: 1.5rem !important;

        &:hover,
        &:enabled {
          background: none !important;
        }

        &:focus {
          border: none !important;
          box-shadow: none !important;
        }
      }

      img {
        width: 2rem !important;
        height: 2rem !important;
      }
    }
  }

  .p-datatable-wrapper {
    overflow: hidden;
  }

  .p-sortable-column-icon {
    font-size: 0.6rem !important;
  }
`;

const ITEMS_PER_PAGE = 50;

export const AdminPlayersOverviewPage: React.FC = () => {
  const history = useHistory();
  const intl = useIntl();
  const { toastError } = useToaster();
  const isMobile = useIsMobile();

  const selectedFacilityId = useSelectedFacilityId();
  const [loading, setLoading] = useState(false);
  const [players, setPlayers] = useState<AdminOverviewUser[]>([]);
  const [lastPage, setLastPage] = useState(0);
  const [selection, setSelection] = useState<AdminOverviewUser[] | "all">([]);
  const [searchInput, setSearchInput] = useState("");
  const [reload, setReload] = useState(false);
  const [filters, setFilters] = useState<AdminOverviewUsersFilters>({
    membershipList: [],
  });
  const [sorting, setSorting] = useState<AdminOverviewUsersSorting>({
    sortBy: "Name",
    orderType: "asc",
  });
  const [dataCount, setDataCount] = useState<AdminOverviewUsersCount | null>(
    null,
  );

  const tableRef = useRef<PrimeReactDataTable>();
  const usersAbortController = useRef<AbortController>(new AbortController());
  const usersCountAbortController = useRef<AbortController>(
    new AbortController(),
  );

  const [showMessageDialog, setShowMessageDialog] = useState(false);
  const { df } = useDateFormat();

  const fetchPlayers = useCallback(
    async (lastPageId: number) => {
      setLoading(true);

      if (usersAbortController.current) {
        usersAbortController.current.abort();
        usersAbortController.current = new AbortController();
      }
      try {
        const info = await getUsersForAdminOverview(
          {
            facilityId: selectedFacilityId,
            query: searchInput,
            lastPage: lastPageId ?? 0,
            filter: filters,
            ...sorting,
          },
          usersAbortController.current.signal,
        );

        if (info.lastPage > 1) {
          setPlayers([...players, ...info.users]);
        } else {
          setPlayers(info.users || []);
        }
        setLastPage(info.lastPage);
      } catch (error) {
        console.log(error);

        if (!usersAbortController.current?.signal.aborted) {
          toastError.fetchPlayersFailed();
        }
      } finally {
        setLoading(false);
      }
    },
    [filters, players, searchInput, selectedFacilityId, sorting, toastError],
  );

  const fetchPlayersCount = useCallback(async () => {
    if (usersCountAbortController.current) {
      usersCountAbortController.current.abort();
      usersCountAbortController.current = new AbortController();
    }

    try {
      const data = await getUsersCountForAdminOverview(
        {
          facilityId: selectedFacilityId,
          query: searchInput,
          filter: filters,
        },
        usersCountAbortController.current.signal,
      );

      setDataCount(data);
    } catch (error) {
      console.log(error);
    }
  }, [filters, searchInput, selectedFacilityId]);

  useEffect(() => {
    fetchPlayers(0);
    fetchPlayersCount();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, sorting, searchInput, reload, selectedFacilityId]);

  const searchInputChange = (value: string) => {
    setLastPage(0);
    setSearchInput(value);
  };
  const debouncedSearchInputChange = debounce(searchInputChange, 300);

  const columns = [
    {
      isSelectionColumn: true,
      headerStyle: { width: "4%" },
    },
    {
      field: "displayName",
      sortable: true,
      sortField: "Name",
      header: intl.formatMessage({ id: "common.name" }),
      body: ({ profileImage, displayName, firstName, lastName }) => (
        <AvatarNameWrapper
          image={profileImage}
          name={displayName}
          firstName={firstName}
          lastName={lastName}
          width="1.5rem"
          height="1.5rem"
          fontSize="0.9rem"
        />
      ),
      headerStyle: { width: "40%", padding: "0.85rem 0.5rem !important" },
      useInMobile: true,
    },
    {
      field: "phoneNumber",
      header: intl.formatMessage({
        id: "common.phone",
      }),
      useInMobile: true,
      headerStyle: { width: "20%" },
    },
    {
      field: "emailAddress",
      header: intl.formatMessage({
        id: "common.email",
      }),
      sortable: true,
      sortField: "Email",
      headerStyle: { width: "25%" },
    },
    {
      field: "lastActivity",
      header: intl.formatMessage({
        id: "admin.players.lastActivity",
      }),
      body: ({ lastActivity }) =>
        lastActivity ? df(lastActivity, luxonDateFormat) : "-",
      sortable: true,
      sortField: "LoggedIn",
      headerStyle: { width: "25%" },
    },
  ];

  const mobileColumns = [
    {
      isSelectionColumn: true,
      headerStyle: { width: "4%" },
    },
    {
      field: "displayName",
      header: intl.formatMessage({
        id: "common.name",
      }),
      sortable: true,
      sortField: "Name",
      body: ({ profileImage, displayName, firstName, lastName }) => (
        <AvatarNameWrapper
          image={profileImage}
          name={displayName}
          firstName={firstName}
          lastName={lastName}
          width="1.5rem"
          height="1.5rem"
          fontSize="0.9rem"
        />
      ),
      headerStyle: { width: "60%" },
      bodyStyle: { width: "60%" },
    },
    {
      field: "phoneNumber",
      header: intl.formatMessage({
        id: "common.phone",
      }),
      headerStyle: { width: "36%" },
      bodyStyle: { width: "36%" },
    },
    // Below columns are for export excel only
    {
      field: "emailAddress",
      header: intl.formatMessage({
        id: "common.email",
      }),
      bodyStyle: { display: "none" },
      headerStyle: { display: "none" },
    },
  ];

  const exportCSV = async () => {
    const response = await getUserCSVExport({
      filter: filters,
      facilityId: selectedFacilityId,
      query: searchInput,
      isSelectedAll: selection === "all",
      usersList: selection === "all" ? [] : selection.map(u => u.id),
    });

    const url = URL.createObjectURL(response);

    const link = document.createElement("a");
    link.setAttribute("href", url);
    const fileExtension =
      response?.type ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ? "xlsx"
        : "csv";
    link.setAttribute("download", `users.${fileExtension}`);
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const handleOnSelection = e => {
    if (e?.type === "all") {
      setSelection(e?.value?.length === 0 ? [] : "all");
      return;
    }

    if (e?.type === "checkbox") {
      setSelection(e?.value);
    }
  };

  const commonTableProps: Partial<Parameters<typeof DataTable>[0]> = {
    ref: tableRef,
    emptyMessage: intl.formatMessage({
      id: "search.no-results-matching-filter",
      defaultMessage: "Inga resultat matchade valt filter",
    }),
    data: players,
    selection: selection === "all" ? players : selection,
    selectionMode: "multiple",
    onSelectionChange: handleOnSelection,
    loading,
    onRowClick: e =>
      e.originalEvent.metaKey
        ? window.open(`/#${adminGetPlayerPath(e?.data?.id)}`)
        : history.push(adminGetPlayerPath(e?.data?.id)),
    dataKey: "id",
    lazy: true,
    onSort(e) {
      setSorting({
        sortBy: e?.sortField,
        orderType: e?.sortOrder === 1 ? "asc" : "desc",
      });
    },
    sortField: sorting.sortBy,
    sortOrder: sorting.orderType === "asc" ? 1 : -1,
  };

  return (
    <>
      <>
        {isMobile ? (
          <MobilePlayersOverviewContainer>
            <AdminAddNewPlayer
              setReload={setReload}
              facilityId={selectedFacilityId}
            />
            <AdminPlayersOverviewTableHeader
              count={players?.length}
              total={dataCount?.filteredCount}
              activeCount={dataCount?.verifiedCount}
              filters={filters}
              setFilters={setFilters}
              disableActions={!selection?.length}
              facilityId={selectedFacilityId}
              onDownload={exportCSV}
              onSendMessage={() => setShowMessageDialog(true)}
              onSearchInputChange={debouncedSearchInputChange}
              isLoading={loading}
            />
            <DataTable
              rowsPerPage={100}
              columns={mobileColumns}
              {...commonTableProps}
            />
            <div className="my-8 flex justify-center">
              <Button
                onClick={() => fetchPlayers(lastPage)}
                disabled={
                  loading ||
                  players.length < ITEMS_PER_PAGE ||
                  players.length === dataCount?.filteredCount
                }
              >
                <FormattedMessage id="common.show-more" />
              </Button>
            </div>
          </MobilePlayersOverviewContainer>
        ) : (
          <PlayersOverviewContainer>
            <AdminAddNewPlayer
              setReload={setReload}
              facilityId={selectedFacilityId}
            />
            <AdminPlayersOverviewTableHeader
              count={players?.length}
              total={dataCount?.filteredCount}
              activeCount={dataCount?.verifiedCount}
              filters={filters}
              setFilters={setFilters}
              disableActions={!selection?.length}
              facilityId={selectedFacilityId}
              onDownload={exportCSV}
              onSendMessage={() => setShowMessageDialog(true)}
              onSearchInputChange={debouncedSearchInputChange}
              isLoading={loading}
            />
            <DataTable
              rowsPerPage={100}
              columns={columns}
              {...commonTableProps}
            />
            <div className="my-8 flex justify-center">
              <Button
                onClick={() => fetchPlayers(lastPage)}
                disabled={
                  players.length < ITEMS_PER_PAGE ||
                  players.length === dataCount?.filteredCount
                }
              >
                <FormattedMessage id="common.show-more" />
              </Button>
            </div>
          </PlayersOverviewContainer>
        )}
      </>

      {showMessageDialog && (
        <Dialog
          onHide={() => setShowMessageDialog(false)}
          visible={showMessageDialog}
          className="h-[90%] w-[90%] max-w-[1200px]"
        >
          <AdminSendMessageForm
            facilityId={selectedFacilityId}
            users={selection === "all" ? [] : selection}
            selection={selection === "all" ? "all" : "users"}
            filters={filters}
            filteredCount={dataCount?.filteredCount}
            onCompleted={() => setShowMessageDialog(false)}
            query={searchInput}
          />
        </Dialog>
      )}
    </>
  );
};
