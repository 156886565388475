import useSWR from "swr";

import { GameViewModel } from "../../modules/checkout/models/Games";

import { getGame } from "../../modules/game/services/Games";

export const useGame = (gameId?: GameViewModel["id"]) => {
  const { data, ...rest } = useSWR(
    gameId ? ["game", gameId] : null,
    ([, gameId]) => getGame(gameId).then(res => res?.data),
  );

  return {
    ...rest,
    game: data,
  };
};
