import { useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";

import styled from "styled-components";

import { breakpoints } from "../../../../appConstants/common";

import { FacilityWithUtc } from "../../../customer/models/Facility";
import {
  Receipt as ReceiptType,
  SelectedReceiptsFilter,
} from "../../models/Receipts";

import { useToaster } from "../../../../hooks/common/useToaster";

import { getFacilitiesByIds } from "../../../customer/services/FacilityService";
import { getReceipts } from "../../services/Payment";

import { Button } from "../../../../components/Button";
import { ProgressSpinner } from "../../../../components/ProgressSpinner";

import { ALL_ALTERNATIVE } from "../../constants/receipts";
import { Receipt } from "./Receipt";
import { ReceiptsFilters } from "./ReceiptsFilters";

const ReceiptsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;

  @media (min-width: ${breakpoints.LARGE}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: ${breakpoints.XL}) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media (min-width: ${breakpoints.XL}) {
    gap: 2rem;
  }
`;

const LoadingContainer = styled.div`
  display: grid;
  place-items: center;
`;

const ShowMoreContainer = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  @media (min-width: ${breakpoints.XL}) {
    gap: 2rem;
  }
`;

export const Receipts: React.FC = () => {
  const { toastError } = useToaster();
  const [receipts, setReceipts] = useState<ReceiptType[]>([]);
  const [facilities, setFacilities] = useState<FacilityWithUtc[]>([]);
  const [selectedReceiptsFilter, setSelectedReceiptsFilter] =
    useState<SelectedReceiptsFilter>({
      externalIdType: ALL_ALTERNATIVE,
      paymentStatus: ALL_ALTERNATIVE,
      facilityId: ALL_ALTERNATIVE,
    });
  const [loading, setLoading] = useState<boolean>(true);
  const [numberOfReceiptsToShow, setNumberOfReceiptsToShow] =
    useState<number>(12);

  const filteredReceipts = useMemo(() => {
    const fieldsToCheck = ["externalIdType", "paymentStatus", "facilityId"];
    const check = (receipt: ReceiptType, field: string) =>
      selectedReceiptsFilter[field] === ALL_ALTERNATIVE ||
      selectedReceiptsFilter[field] === receipt[field];

    return receipts.filter((receipt: ReceiptType) =>
      fieldsToCheck.every((field: string) => check(receipt, field)),
    );
  }, [receipts, numberOfReceiptsToShow, selectedReceiptsFilter]);

  const getFacilityName = (facilityId: string) =>
    facilities?.find(({ id }) => id === facilityId)?.name || "";

  useEffect(() => {
    const abortController = new AbortController();

    const fetchReceipts = async () => {
      try {
        const receiptsResponse = await getReceipts(abortController.signal);

        if (abortController.signal.aborted) return;
        const facilityIds = Array.from(
          new Set(receiptsResponse.data.receipts?.map(r => r.facilityId)),
        );

        if (!facilityIds?.length) return;

        const response = await getFacilitiesByIds(
          facilityIds,
          abortController.signal,
        );
        if (abortController.signal.aborted) return;

        setFacilities(response?.data);
        setReceipts(
          receiptsResponse.data.receipts.sort(
            (a, b) => b.created.toMillis() - a.created.toMillis(),
          ),
        );
      } catch (e) {
        if (abortController.signal.aborted) return;
        toastError.fetchReceiptsFailed();
      } finally {
        if (!abortController.signal.aborted) {
          setLoading(false);
        }
      }
    };

    fetchReceipts();
    return () => abortController.abort();
  }, []);

  return loading ? (
    <LoadingContainer>
      <ProgressSpinner />
    </LoadingContainer>
  ) : (
    <Container>
      <ReceiptsFilters
        initialSelection={selectedReceiptsFilter}
        receipts={receipts}
        facilitiesEntities={facilities}
        onChange={setSelectedReceiptsFilter}
      />
      <ReceiptsContainer>
        {filteredReceipts
          .slice(0, numberOfReceiptsToShow)
          .map((receipt: ReceiptType, i: number) => (
            <Receipt
              key={i}
              receipt={receipt}
              facilityName={getFacilityName(receipt.facilityId)}
            />
          ))}
      </ReceiptsContainer>
      <ShowMoreContainer>
        <Count
          totalNrOfReceipts={filteredReceipts.length}
          nrOfReceiptsDisplayed={
            numberOfReceiptsToShow > filteredReceipts.length
              ? filteredReceipts.length
              : numberOfReceiptsToShow
          }
        />
        <Button
          text="Visa fler"
          disabled={numberOfReceiptsToShow >= filteredReceipts.length}
          translationName="common.show-more"
          onClick={() => setNumberOfReceiptsToShow(value => value + 10)}
        />
      </ShowMoreContainer>
    </Container>
  );
};

const Count = ({
  nrOfReceiptsDisplayed,
  totalNrOfReceipts,
}: {
  nrOfReceiptsDisplayed: number;
  totalNrOfReceipts: number;
}) => (
  <span>
    <FormattedMessage
      id="common.results.shows-x-of-x"
      defaultMessage="Visar {shows} av {total}"
      values={{
        shows: nrOfReceiptsDisplayed,
        total: totalNrOfReceipts,
      }}
    />
  </span>
);
