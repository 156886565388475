import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

import clsx from "clsx";
import { DateTime } from "luxon";
import useSWRImmutable from "swr/immutable";

import { CoinsWalletEntry } from "../../../../models/coins";
import type { CoinsOrderPaymentResponse } from "../../../../models/payment";

import { useToaster } from "../../../../hooks/common/useToaster";
import { useCurrencyFormat } from "../../../../hooks/useCurrencyFormat";
import { useDateFormat } from "../../../../hooks/useDateFormat";

import {
  getOrderPaymentCoinsWallet,
  payOrderPaymentWithCoinsWalletEntry,
} from "../../../../services/myCoinsService";
import { completeOrderPayment } from "../../../../services/myOrdersService";

import { Button } from "../../../../components/Button";
import { ProgressSpinner } from "../../../../components/ProgressSpinner";

import { luxonDateFormat } from "../../../../utils/dateFormats";
import { useCheckout, useCheckoutState } from "../../context/CheckoutContext";

export const Coins = ({
  createOrderPayment,
}: {
  createOrderPayment: () => Promise<CoinsOrderPaymentResponse>;
}) => {
  const {
    state: { order, checkoutStatus },
    dispatch,
  } = useCheckout();
  const { toastError } = useToaster();
  const { cf } = useCurrencyFormat(order.total.currencyCode);

  const [isLoading, setIsLoading] = useState(true);
  const [orderPaymentResponse, setOrderPaymentResponse] =
    useState<CoinsOrderPaymentResponse>();
  const [selectedCoinsWalletEntryId, setSelectedCoinsWalletEntryId] =
    useState<CoinsWalletEntry["id"]>();

  useEffect(() => {
    createOrderPayment()
      .then(data => {
        setOrderPaymentResponse(data);
      })
      .catch(console.error)
      .finally(() => setIsLoading(false));
  }, [createOrderPayment]);

  const { data: coinsWallet, isLoading: coinsWalletIsLoading } =
    useSWRImmutable(
      orderPaymentResponse
        ? ["coinsWallet", orderPaymentResponse.orderPaymentId]
        : null,
      ([, orderPaymentId]) => getOrderPaymentCoinsWallet(orderPaymentId),
    );

  const onPay = async () => {
    if (!selectedCoinsWalletEntryId || !orderPaymentResponse) {
      return;
    }

    dispatch({
      type: "SET_CHECKOUT_STATUS",
      status: "paymentPending",
    });

    try {
      await payOrderPaymentWithCoinsWalletEntry(
        orderPaymentResponse?.orderPaymentId,
        selectedCoinsWalletEntryId,
      );
    } catch (error) {
      console.error(error);

      toastError.unknown();

      dispatch({
        type: "SET_CHECKOUT_STATUS",
        status: "idle",
      });

      return;
    }

    try {
      await completeOrderPayment(order.id, orderPaymentResponse.orderPaymentId);

      dispatch({
        type: "SET_CHECKOUT_STATUS",
        status: "paymentCompleted",
      });
    } catch (error) {
      console.error(error);

      toastError.unknown();

      dispatch({
        type: "SET_CHECKOUT_STATUS",
        status: "idle",
      });
    }
  };

  if (isLoading || coinsWalletIsLoading) {
    return <ProgressSpinner />;
  }

  if (!orderPaymentResponse) {
    return (
      <p className="text-center text-error">
        <FormattedMessage id="error.unknown" />
      </p>
    );
  }

  if (!coinsWallet) {
    return (
      <p className="text-center text-error">
        <FormattedMessage id="coins.no-coins-for-venue" />
      </p>
    );
  }

  return (
    <>
      <ul className="space-y-3">
        {coinsWallet.entries.map(entry => (
          <Coin
            key={entry.id}
            entry={entry}
            onSelect={entryId => setSelectedCoinsWalletEntryId(entryId)}
          />
        ))}

        {coinsWallet.entries.length === 0 && (
          <Coin
            entry={{
              balance: 0,
              expiresOn: DateTime.invalid("empty"),
              hasSufficientFunds: false,
              id: "empty",
            }}
            onSelect={() => null}
          />
        )}
      </ul>

      <div className="mt-5 flex justify-center md:mt-10">
        <Button
          className="flex items-center gap-4"
          onClick={onPay}
          disabled={
            !selectedCoinsWalletEntryId || checkoutStatus === "paymentPending"
          }
          type="primary"
        >
          <span>
            <FormattedMessage
              id="checkout.section.payment-methods.coins.pay-button.text"
              defaultMessage="Pay {amountAndCurrency} with coins"
              values={{
                amountAndCurrency: cf(coinsWallet.price),
              }}
            />
          </span>
          {checkoutStatus === "paymentPending" && (
            <ProgressSpinner className="m-0 text-purewhite" fontSize=".8rem" />
          )}
        </Button>
      </div>
    </>
  );
};

const Coin = ({
  entry,
  onSelect,
}: {
  entry: CoinsWalletEntry;
  onSelect: (entryId: CoinsWalletEntry["id"]) => void;
}) => {
  const { cf } = useCurrencyFormat("Coins");
  const { order } = useCheckoutState();
  const { df } = useDateFormat(order.facilityId);

  return (
    <li key={entry.id}>
      <label
        className="flex items-start gap-2 font-semibold"
        htmlFor={entry.id}
      >
        <input
          type="radio"
          name="coins"
          id={entry.id}
          disabled={!entry.hasSufficientFunds}
          className="mt-1"
          onChange={() => onSelect(entry.id)}
        />
        <div className="flex-1 justify-between">
          <div className="flex justify-between gap-4">
            <p
              className={clsx(
                "flex gap-1 whitespace-nowrap",
                !entry.hasSufficientFunds && "text-gray-700",
              )}
            >
              <FormattedMessage
                id="checkout.section.payment-methods.coins.amount"
                values={{
                  amount: children => (
                    <span
                      className={clsx(
                        entry.hasSufficientFunds && "text-primary",
                      )}
                    >
                      {children}
                    </span>
                  ),
                  coinsCount: cf(entry.balance),
                }}
              />
            </p>

            {entry.expiresOn.isValid && (
              <time
                className="truncate text-gray-700"
                dateTime={entry.expiresOn.toISO() ?? undefined}
              >
                {" "}
                <FormattedMessage id="common.valid-to" />{" "}
                {df(entry.expiresOn, luxonDateFormat)}
              </time>
            )}
          </div>

          {!entry.hasSufficientFunds && (
            <p className="text-sm text-red-600">
              <FormattedMessage id="checkout.section.payment-methods.coins.not-enough-message" />
            </p>
          )}
        </div>
      </label>
    </li>
  );
};
