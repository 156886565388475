import React from "react";

import styled from "styled-components";

import {
  PriceListResponseWithUTC,
  PricingCalendarPayload,
  WeekDays,
} from "../../../models/Pricing";

import { useAppSelector } from "../../../../../hooks/store/store";

import PriceCalendarBody from "./PriceCalendarBody";
import PriceCalendarHead from "./PriceCalendarHead";

const CalendarContainer = styled.div`
  min-width: 70rem;
`;
interface IProps {
  selectedCategoryColor: string;
  selectedCategory: string;
  priceList: PriceListResponseWithUTC;
  schedules: WeekDays[];
  onSelect: (payload: PricingCalendarPayload) => void;
}

const PriceCalendarContent: React.FC<IProps> = ({
  selectedCategoryColor,
  selectedCategory,
  priceList,
  schedules,
  onSelect,
}) => {
  const {
    facilities: { selectedFacility },
  } = useAppSelector(state => state);

  return (
    <CalendarContainer>
      <PriceCalendarHead facility={selectedFacility} />
      <PriceCalendarBody
        selectedCategory={selectedCategory}
        priceList={priceList}
        selectedCategoryColor={selectedCategoryColor}
        schedules={schedules}
        onSelect={onSelect}
      />
    </CalendarContainer>
  );
};

export default PriceCalendarContent;
