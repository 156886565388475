import { useLocation, useParams } from "react-router-dom";

import type { Order } from "../../models/Order";

import { useOrder } from "../../hooks/swr/useOrder";

import { AppLoadingSpinner } from "../../components/AppLoadingSpinner";

import { CheckoutView } from "./CheckoutView";
import { CheckoutProvider } from "./context/CheckoutContext";

export const CheckoutPage = () => {
  const location = useLocation();
  const { orderId } = useParams<{ orderId: Order["id"] }>();

  const { order, isLoading, error } = useOrder(orderId, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  const searchParams = new URLSearchParams(location.search);

  if (isLoading) {
    return <AppLoadingSpinner />;
  }

  if (!order || error) {
    return <div>No order found</div>;
  }

  return (
    <CheckoutProvider order={order} action={searchParams.get("action") || ""}>
      <CheckoutView />
    </CheckoutProvider>
  );
};
