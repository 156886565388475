export const createUrlSearchParams = (
  params: Record<string, string | number | boolean | undefined | null>,
) => {
  const urlSearchParams = new URLSearchParams();

  Object.entries(params).forEach(([key, value]) => {
    if (value !== undefined && value !== null) {
      urlSearchParams.set(key, value.toString());
    }
  });

  return urlSearchParams;
};
