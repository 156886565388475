import { DateTime } from "luxon";

import { ApiSingleResponse } from "../../../models/common";
import { FacilityWithUtc } from "../../customer/models/Facility";
import {
  BuyableType,
  Payment,
  PaymentMethodName,
  SplitPaymentInfo,
  SplitPaymentPossible,
  SplitPaymentPossibleRequest,
} from "../models/Payment";
import { ReceiptsData } from "../models/Receipts";
import {
  ReceiptDataResponse,
  ReceiptsDataResponse,
} from "../models/api/payment";

import { fetchApi } from "../../../services/legacyApiClient";

const apiVersion = "checkout/v3";

export const forgetPaymentInfo = async (): Promise<boolean | null> => {
  try {
    const response = (await fetchApi<ApiSingleResponse<boolean>>({
      method: "PUT",
      uri: `${apiVersion}/payments/forget`,
    })) as ApiSingleResponse<boolean>;
    return Promise.resolve(response.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getPayment = (
  paymentId: string,
  signal?: AbortSignal,
): Promise<Payment | null> => {
  return fetchApi({
    method: "GET",
    uri: `${apiVersion}/payments/${paymentId}`,
    signal,
  })
    .then((data: any) => {
      return Promise.resolve(data.data);
    })
    .catch(() => {
      return Promise.reject(null);
    });
};

export const sendCallback = (
  paymentId: string,
  signal?: AbortSignal,
): Promise<Payment | null> => {
  return fetchApi({
    method: "POST",
    uri: `${apiVersion}/payments/callback/${paymentId}`,
    signal,
  })
    .then((data: any) => {
      return Promise.resolve(data.data);
    })
    .catch(() => {
      return Promise.reject(null);
    });
};

export const getFacilityPaymentMethods = (
  facilityId: FacilityWithUtc["id"],
  signal?: AbortSignal,
): Promise<PaymentMethodName[] | null> => {
  return fetchApi({
    uri: `${apiVersion}/payments/paymentmethods/facility/${facilityId}`,
    signal,
  })
    .then((data: any) => {
      return Promise.resolve(data.data);
    })
    .catch(() => {
      return Promise.reject(null);
    });
};

export const isSplitPaymentPossible = (
  externalId: string,
  buyableType: BuyableType,
  userIds?: string[],
  signal?: AbortSignal,
): Promise<SplitPaymentPossible | null> => {
  const payload: SplitPaymentPossibleRequest = {
    externalId,
    buyableType,
    userIds,
  };
  return fetchApi({
    method: "POST",
    uri: `${apiVersion}/payments/splitpaymentpossible`,
    payload,
    signal,
  })
    .then((data: any) => {
      return Promise.resolve(data.data);
    })
    .catch(() => {
      return Promise.reject(null);
    });
};

export const getSplitPaymentInfo = (
  externalId: string,
  signal?: AbortSignal,
): Promise<SplitPaymentInfo | null> => {
  return fetchApi({
    uri: `${apiVersion}/payments/splitpaymentinfo/${externalId}`,
    signal,
  })
    .then((data: any) => {
      return Promise.resolve(data.data);
    })
    .catch(() => {
      return Promise.reject(null);
    });
};

export const getReceipts = async (
  signal?: AbortSignal,
): Promise<ApiSingleResponse<ReceiptsData>> => {
  try {
    const response = await fetchApi<ApiSingleResponse<ReceiptsDataResponse>>({
      uri: `${apiVersion}/payments/receipts`,
      signal,
    });

    const { data, error, isSuccessful } =
      response as ApiSingleResponse<ReceiptsDataResponse>;

    const responseModel: ReceiptsData = {
      userId: data.userId,
      receipts: data.receipts.map((receipt: ReceiptDataResponse) => {
        return {
          ...receipt,
          created: DateTime.fromISO(receipt.created, { setZone: true }),
          lastModified: DateTime.fromISO(receipt.lastModified, {
            setZone: true,
          }),
        };
      }),
    };

    return {
      data: responseModel,
      error,
      isSuccessful,
    };
  } catch (err) {
    return {
      data: { userId: "", receipts: [] },
      error: err,
      isSuccessful: false,
    };
  }
};
