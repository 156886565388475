import type { Order } from "../models/Order";
import type { FacilityWithUtc } from "../modules/customer/models/Facility";

import { post } from "./apiClient";

const API_VERSION = "v1";

const getEndpointBase = (
  facilityId: FacilityWithUtc["id"],
  orderId: Order["id"],
) => `/${API_VERSION}/facilities/${facilityId}/orders/${orderId}`;

export const refundOrder = async (
  facilityId: FacilityWithUtc["id"],
  orderId: Order["id"],
) =>
  post({
    endpoint: `${getEndpointBase(facilityId, orderId)}/payment/refund`,
    body: {},
    parseDataAs: "none",
  });

export const refundOrderPayment = async (
  facilityId: FacilityWithUtc["id"],
  orderId: Order["id"],
  paymentId: string,
) =>
  post({
    endpoint: `${getEndpointBase(facilityId, orderId)}/payment/${paymentId}/refund`,
    body: {},
    parseDataAs: "none",
  });
