import { FormattedMessage, useIntl } from "react-intl";

import { faUserGroup } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import styled from "styled-components";

import { Teams } from "../../../../modules/checkout/models/Games";
import { IActivity } from "../../models/Activity";

import { TeamAvatarNameWrapper } from "../../../../components/TeamAvatarNameWrapper";

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.h3`
  color: var(--primary);
`;

const IconWrapper = styled.div`
  color: black;
  display: flex;
  gap: 0.5rem;
`;

const SmallHeader = styled.p`
  font-size: var(--b4);
`;

interface Props {
  activity: IActivity;
}

const SerieParticipantsList: React.FC<Props> = ({ activity }) => {
  const intl = useIntl();
  const teams = activity?.teams;

  return (
    <div>
      <SmallHeader>
        <FormattedMessage
          id="activity.participants"
          defaultMessage="Deltagare"
        />
      </SmallHeader>
      <TitleWrapper>
        <Title>{activity?.name}</Title>
        <IconWrapper>
          <FontAwesomeIcon icon={faUserGroup} />
          <FormattedMessage
            id="activity.participants.number"
            defaultMessage="{numberOfParticipants} av {maxNumberOfParticipants}"
            values={{
              numberOfParticipants: teams?.length,
              maxNumberOfParticipants: activity.maxParticipantsCount,
            }}
          />
        </IconWrapper>
      </TitleWrapper>
      <DataTable dataKey="id" value={teams || []}>
        <Column
          body={(team: Teams) => <TeamAvatarNameWrapper team={team} />}
          header={intl.formatMessage({
            id: "common.teams",
            defaultMessage: "Lag",
          })}
        />
      </DataTable>
    </div>
  );
};

export default SerieParticipantsList;
