import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory, useParams } from "react-router-dom";

import Lottie from "lottie-react";
import { useRecoilState } from "recoil";

import { DateOnly } from "../../../../../models/DateOnly";
import { BuyableType } from "../../../../../modules/checkout/models/Payment";
import { GeneralActivities } from "../../../../../modules/game/models/GeneralActivities";

import { useGeneralActivity } from "../../../../../hooks/swr/useGeneralActivity";
import { useQuery } from "../../../../../hooks/useQuery";

import {
  updateActivityImageUrl,
  uploadActivityImage,
} from "../../../../../modules/game/services/Activities";

import { Card } from "../../../../../components/Card";
import { ProgressSpinner } from "../../../../../components/ProgressSpinner";
import { CreateForm } from "./components/CreateForm";
import { EditForm } from "./components/EditForm";

import { selectedFacilityIdState } from "../../../../../recoil/selectedFacilityIdState";
import successAnimation from "./success.json";

interface Params {
  activityId: GeneralActivities["id"];
}

export const GeneralActivityPage: React.FC = () => {
  const { activityId } = useParams<Params>();
  const [selectedFacilityId, setSelectedFacilityId] = useRecoilState(
    selectedFacilityIdState,
  );
  const [activityToCopy, setActivityToCopy] = useState<GeneralActivities>();
  const isNew = activityId === "new";
  const history = useHistory();
  const query = useQuery();
  const isCreated = !!query?.get("created");
  const { activity, isLoading, mutate, isValidating } = useGeneralActivity(
    !isNew ? activityId : null,
    "payment",
    { revalidateOnFocus: false },
  );

  const onChange = async (image: string | File) => {
    await updateCoverImage(activity?.id, image);
    mutate();
  };

  const onCreate = async (activityId: string, image: string | File) => {
    await updateCoverImage(activityId, image);
    history.replace(`${activityId}?created=success`);
  };

  const onCopy = async (activity: GeneralActivities) => {
    setActivityToCopy(activity);
    history.push("new");
  };

  const updateCoverImage = async (activityId: string, image: string | File) => {
    if (!image) return;
    try {
      if (typeof image === "string") {
        // new default URL choosen
        await updateActivityImageUrl(activityId, image, BuyableType.Event);
      } else {
        await uploadActivityImage(activityId, image, BuyableType.Event);
      }
    } catch (e) {
      // error handle
      console.log(e);
    }
  };

  useEffect(() => {
    if (isNew || !activity) return;

    if (activity?.facilityId !== selectedFacilityId) {
      setSelectedFacilityId(activity?.facilityId);
    }
  }, [selectedFacilityId, setSelectedFacilityId, isNew, activity]);

  return (
    <>
      <div className="sm:flex sm:items-center sm:justify-between">
        {isCreated && (
          <div className="flex items-center">
            <Lottie
              className="w-32"
              animationData={successAnimation}
              loop={false}
            />
            <h3 className="mr-14 text-3xl text-primary ease-in">
              <FormattedMessage id="admin.activities.event.create.successful" />
            </h3>
          </div>
        )}
      </div>
      <Card>
        {isLoading || isValidating ? (
          <ProgressSpinner />
        ) : isNew && selectedFacilityId ? (
          <CreateForm
            onCreate={onCreate}
            startDate={
              query.get("startDate")
                ? DateOnly.fromISODate(query.get("startDate"))
                : undefined
            }
            facilityId={selectedFacilityId}
            prefilledValues={activityToCopy}
          />
        ) : activity ? (
          <EditForm onChange={onChange} activity={activity} onCopy={onCopy} />
        ) : (
          <FormattedMessage
            id="toast.error.update.generic"
            defaultMessage="Ett fel har inträffat."
          />
        )}
      </Card>
    </>
  );
};
