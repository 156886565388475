import { VenueStatistics } from "../../../modules/admin/components/VenueStatistics";
import { AdminCalendar } from "../../../modules/checkout/components/Calendar/admin/AdminCalendar";

import { useSelectedFacilityId } from "../../../recoil/selectedFacilityIdState";

export const AdminBookingPage = () => {
  const selectedFacilityId = useSelectedFacilityId();

  if (!selectedFacilityId) {
    return null;
  }

  return (
    <>
      <div className="mb-5">
        <VenueStatistics />
      </div>
      <AdminCalendar facilityId={selectedFacilityId} />
    </>
  );
};
