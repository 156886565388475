import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { faTrash } from "@fortawesome/pro-light-svg-icons";
import clsx from "clsx";
import { useFormik } from "formik";
import { DateTime } from "luxon";
import { Divider } from "primereact/divider";

import { DateOnly } from "../../../../models/DateOnly";
import { User } from "../../../../modules/player/models/User";

import { useToaster } from "../../../../hooks/common/useToaster";
import { useCountryCodes } from "../../../../hooks/swr/useCountryCodes";
import { useCurrentUser } from "../../../../hooks/swr/useCurrentUser";
import { useFormFieldValidator } from "../../../../modules/customer/hooks/useFormFieldValidator";
import { useFormValidationSchema } from "../../../../modules/player/hooks/useFormValidationSchema";

import {
  requestUserDeletion,
  saveUser,
  uploadProfileImage,
} from "../../../../modules/player/services/UserService";

import { Button } from "../../../../components/Button";
import { Checkbox } from "../../../../components/Checkbox";
import { ConfirmationDialog } from "../../../../components/ConfirmationDialog";
import { Label } from "../../../../components/Label";
import { ProgressSpinner } from "../../../../components/ProgressSpinner";
import { SkillLevelSlider } from "../../../../components/SkillLevelSlider";
import { SubmitResetButtons } from "../../../../components/SubmitResetButtons";
import { TextInput } from "../../../../components/TextInput";
import ImageInput from "../../../../components/image/ImageInput";
import { CalendarInput } from "../../../../components/inputs/CalendarInput";
import { SelectInput } from "../../../../components/inputs/SelectInput";
import { PaymentMethods } from "./components/PaymentMethods/PaymentMethods";

import { skillLevelItems } from "../../../../modules/player/constants";
import { useAppLocale } from "../../../../recoil/i18nConfigState";

export const UserSettings = () => {
  const { toastError, toastSuccess } = useToaster();
  const { editProfileSchema } = useFormValidationSchema();
  const intl = useIntl();
  const { currentUser, mutate } = useCurrentUser();
  const [uploading, setUploading] = useState(false);
  const [resetClicked, setResetClicked] = useState(false);
  const locale = useAppLocale();
  const [showRequestAccountDeletionModal, setShowRequestAccountDeletionModal] =
    useState(false);
  const [
    requestAccountDeletionModalLoading,
    setRequestAccountDeletionModalLoading,
  ] = useState(false);
  const { countriesData } = useCountryCodes();

  const changeProfileImage = async (file: File) => {
    setUploading(true);

    try {
      mutate(async () => {
        return await uploadProfileImage(file);
      }, false);
    } catch {
      toastError.profileImageUploadFailed();
    } finally {
      setUploading(false);
    }
  };

  const formik = useFormik<User>({
    enableReinitialize: true,
    initialValues: currentUser as User,
    validationSchema: editProfileSchema,
    onSubmit: async data => {
      try {
        data.locale ||= locale;

        mutate(
          async () => {
            const response = await saveUser(data);
            toastSuccess.userSaved();

            return response.data;
          },
          { revalidate: false },
        );
      } catch {
        toastError.updateUserFailed();
      }
    },
  });
  const [skillLevel, setSkillLevel] = useState(formik.values.skillLevel ?? 1);

  const { isFormFieldInvalid, getFormErrorMessage } =
    useFormFieldValidator(formik);

  const countryOptions = countriesData?.map(({ countryCode }) => ({
    value: countryCode,
    label: intl.formatMessage({
      id: `common.country.${countryCode.toLowerCase()}`,
    }),
  }));

  return (
    <form
      className="flex flex-col gap-8 xl:gap-14"
      onSubmit={formik.handleSubmit}
      key={resetClicked.toString()}
    >
      <div className="grid xl:grid-cols-2 xl:gap-x-14">
        <div className="flex flex-col gap-4">
          <TextInput
            required
            name="firstName"
            value={formik.values["firstName"]}
            onBlur={formik.handleBlur}
            label={intl.formatMessage({ id: "common.firstname" })}
            type="text"
            onChange={formik.handleChange}
            className={clsx({
              "p-invalid": isFormFieldInvalid("firstName"),
            })}
            maxLength={45}
          />
          {getFormErrorMessage("firstName")}

          <TextInput
            required
            name="lastName"
            label={intl.formatMessage({ id: "common.lastname" })}
            type="text"
            onChange={formik.handleChange}
            value={formik.values["lastName"]}
            onBlur={formik.handleBlur}
            className={clsx({
              "p-invalid": isFormFieldInvalid("lastName"),
            })}
            maxLength={45}
          />
          {getFormErrorMessage("lastName")}

          <TextInput
            name="emailAddress"
            disabled
            label={intl.formatMessage({ id: "common.email" })}
            type="email"
            value={currentUser?.emailAddress}
          />

          <SelectInput
            required
            label="Country"
            translationName="common.address.country"
            value={formik.values.country}
            options={countryOptions}
            onChange={e => formik.setFieldValue("country", e.value)}
          />
          {getFormErrorMessage("country")}

          <h4 className="mt-4">
            <FormattedMessage id="profile.settings.additional-info" />
          </h4>

          <div>
            <Label>
              <FormattedMessage id="common.birth-date" />
            </Label>

            <CalendarInput
              required
              viewDate={DateTime.now().minus({ years: 16 }).toJSDate()}
              maxDate={DateTime.now().minus({ years: 16 })}
              value={formik.values.birthDate?.toDateTime()}
              name="birthDate"
              onChange={e => {
                formik.setFieldValue(
                  "birthDate",
                  e.value ? DateOnly.fromDateTime(e.value) : null,
                );
              }}
              className={clsx({
                "p-invalid": isFormFieldInvalid("birthDate"),
              })}
            />
          </div>
          {getFormErrorMessage("birthDate")}

          {(formik.values.country === "SE" ||
            formik.values.country === "FI") && (
            <>
              <TextInput
                name="socialSecurityNumber"
                label={intl.formatMessage({
                  id:
                    formik.values.country === "FI"
                      ? "profile.settings.national-identification-number.fi"
                      : "profile.settings.national-identification-number.se",
                })}
                placeholder={
                  formik.values.country === "FI" ? "DDMMÅÅCZZZQ" : "YYMMDD-XXXX"
                }
                type="text"
                onChange={formik.handleChange}
                value={formik.values["socialSecurityNumber"]}
                onBlur={formik.handleBlur}
                className={clsx({
                  "p-invalid": isFormFieldInvalid("socialSecurityNumber"),
                })}
                maxLength={11}
              />
              {getFormErrorMessage("socialSecurityNumber")}
            </>
          )}

          <TextInput
            name="streetAddress"
            label={intl.formatMessage({ id: "common.streetaddress" })}
            type="text"
            onChange={formik.handleChange}
            value={formik.values["streetAddress"]}
            onBlur={formik.handleBlur}
            className={clsx({
              "p-invalid": isFormFieldInvalid("streetAddress"),
            })}
          />
          {getFormErrorMessage("streetAddress")}

          <TextInput
            name="zipCode"
            label={intl.formatMessage({ id: "common.zipcode" })}
            type="text"
            onChange={formik.handleChange}
            value={formik.values["zipCode"]}
            onBlur={formik.handleBlur}
            className={clsx({
              "p-invalid": isFormFieldInvalid("zipCode"),
            })}
          />
          {getFormErrorMessage("zipCode")}

          <TextInput
            name="city"
            label={intl.formatMessage({ id: "common.address.city" })}
            type="text"
            onChange={formik.handleChange}
            value={formik.values["city"]}
            onBlur={formik.handleBlur}
            className={clsx({
              "p-invalid": isFormFieldInvalid("city"),
            })}
          />
          {getFormErrorMessage("city")}

          <TextInput
            name="phoneNumber"
            label={intl.formatMessage({ id: "common.phonenumber" })}
            type="text"
            onChange={formik.handleChange}
            value={formik.values["phoneNumber"]}
            onBlur={formik.handleBlur}
            className={clsx({
              "p-invalid": isFormFieldInvalid("phoneNumber"),
            })}
          />
          {getFormErrorMessage("phoneNumber")}

          <SelectInput
            label="Könstillhörighet"
            translationName="common.gender"
            value={formik.values.gender?.toString()}
            options={[
              ["0", "Man", "profile-settings.gender.male"],
              ["1", "Kvinna", "profile-settings.gender.female"],
            ].map(([value, defaultMessage, id]) => ({
              label: intl.formatMessage({
                id,
                defaultMessage,
              }),
              value,
            }))}
            onChange={e => formik.setFieldValue("gender", parseInt(e.value))}
          />

          <SelectInput
            label="Ålderskategori"
            translationName="common.age-category"
            value={formik.values.age}
            options={[
              ["0", "Junior", "profile-settings.age.junior"],
              ["1", "Senior", "profile-settings.age.senior"],
            ].map(([value, defaultMessage, id]) => ({
              label: intl.formatMessage({
                id,
                defaultMessage,
              }),
              value,
            }))}
            onChange={e => formik.setFieldValue("age", e.value)}
          />
        </div>
        <div>
          <Divider className="!my-8 min-[1200px]:hidden" />

          <Section>
            <h5>
              <FormattedMessage id="common.pincode" defaultMessage="Pinkod" />
            </h5>
            <div className="flex flex-col gap-4">
              <strong>{formik?.values?.pinCode}</strong>
              <p>
                <FormattedMessage
                  id="common.pincode.info"
                  values={{
                    b: chunk => <span className="font-bold">{chunk}</span>,
                    br: <br />,
                  }}
                  defaultMessage="<b>Avsluta med E eller #.</b> Din pinkod är personlig och används för att öppna dörren till anläggningen. Koden är aktiv när du har en bokning och gäller i samtliga hallar."
                />
              </p>
            </div>
          </Section>

          <Divider className="!my-8" />

          <Section>
            <h5>
              <FormattedMessage
                id="common.skill"
                defaultMessage="Skicklighet"
              />
            </h5>
            <div className="mt-7 flex w-full flex-col gap-4 leading-5">
              <SkillLevelSlider
                value={skillLevel}
                onChange={(e, value) =>
                  typeof value === "number" && setSkillLevel(value)
                }
                onChangeCommitted={(e, value) =>
                  formik.setFieldValue("skillLevel", value)
                }
                slots={{
                  valueLabel: ({ children }) => (
                    <div className="pointer-events-none absolute bottom-12 left-1/2 -translate-x-1/2">
                      <div className="rounded bg-primary px-4 py-2 text-white">
                        {children}
                      </div>
                      <div className="absolute left-1/2 h-0 w-0 -translate-x-1/2 border-l-[6px] border-r-[6px] border-t-[8px] border-l-transparent border-r-transparent border-t-primary"></div>
                    </div>
                  ),
                }}
              />
              <p>
                <SkillLevelDescription
                  level={Math.floor(formik.values.skillLevel ?? 1)}
                />
              </p>
            </div>
          </Section>

          <Divider className="!my-8" />

          <Section>
            <h5>
              <FormattedMessage
                id="common.profile-image"
                defaultMessage="Profilbild"
              />
            </h5>
            {uploading ? (
              <ProgressSpinner />
            ) : (
              <ImageInput cropper onSave={changeProfileImage} />
            )}
          </Section>

          <Divider className="!my-8" />

          <Section>
            <h5>
              <FormattedMessage id="common.consent" defaultMessage="Samtycke" />
            </h5>
            <div className="flex flex-col gap-4">
              <Checkbox
                label={intl.formatMessage({ id: "consent.court22-emails" })}
                onChange={e =>
                  formik.setFieldValue(
                    "consentEmailNewsMarketingCourt22",
                    e.target.checked,
                  )
                }
                checked={formik.values.consentEmailNewsMarketingCourt22}
              />
              <Checkbox
                label={intl.formatMessage({ id: "consent.facility-emails" })}
                onChange={e =>
                  formik.setFieldValue(
                    "consentEmailNewsMarketingFacility",
                    e.target.checked,
                  )
                }
                checked={formik.values.consentEmailNewsMarketingFacility}
              />
              <Checkbox
                label={intl.formatMessage({ id: "consent.searchable-profile" })}
                onChange={e =>
                  formik.setFieldValue("consentPublicProfile", e.target.checked)
                }
                checked={formik.values.consentPublicProfile}
              />
            </div>
          </Section>

          <Divider className="!my-8" />

          <Section>
            <h5>
              <FormattedMessage id="common.payment-methods" />
            </h5>
            <div>
              <PaymentMethods />
            </div>
          </Section>
        </div>
      </div>

      <SubmitResetButtons
        onReset={() => {
          formik.resetForm();
          setResetClicked(resetClicked => !resetClicked);
        }}
        disabled={!formik.dirty || !formik.isValid}
      />
      <div className="flex justify-end">
        <Button
          size="small"
          type="danger"
          className="w-full sm:w-auto"
          onClick={() => setShowRequestAccountDeletionModal(true)}
        >
          <FormattedMessage id="action.profile.delete" />
        </Button>
      </div>

      {showRequestAccountDeletionModal && (
        <ConfirmationDialog
          visible
          onHide={() => setShowRequestAccountDeletionModal(false)}
          title={intl.formatMessage({ id: "action.profile.delete" })}
          text={intl.formatMessage({
            id: "description.profile.delete-disclaimer",
          })}
          confirmText={intl.formatMessage({ id: "action.profile.delete" })}
          confirmButtonType="danger"
          icon={faTrash}
          onSubmit={async () => {
            setRequestAccountDeletionModalLoading(true);

            try {
              await requestUserDeletion();
              toastSuccess.message("toast.user.delete-request-success");
            } catch {
              toastError.message("form.book-demo.error");
            } finally {
              setRequestAccountDeletionModalLoading(false);
              setShowRequestAccountDeletionModal(false);
            }
          }}
          onCancel={() => setShowRequestAccountDeletionModal(false)}
          loading={requestAccountDeletionModalLoading}
        />
      )}
    </form>
  );
};

const Section = ({ children }: React.PropsWithChildren<unknown>) => (
  <div className="flex flex-col gap-8">{children}</div>
);

const SkillLevelDescription = ({ level }: { level: User["skillLevel"] }) => {
  const result = skillLevelItems.find(({ value }) => value === level);

  if (!result) {
    return null;
  }

  return <FormattedMessage id={result.id} />;
};
