import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { DateTime } from "luxon";
import styled from "styled-components";

import { DateOnly } from "../../../../../models/DateOnly";
import { CreatePriceListRequestWithUTC } from "../../../models/Pricing";

import { forceDateTimeToTimeZone } from "../../../../../helpers/dateTime";

import { useFacility } from "../../../../../hooks/swr/useFacility";

import { Button } from "../../../../../components/Button";
import { TextInput } from "../../../../../components/TextInput";
import { CalendarInput } from "../../../../../components/inputs/CalendarInput";
import { SelectInput } from "../../../../../components/inputs/SelectInput";

const InputContainer = styled.div`
  flex-direction: column;
  display: flex;
`;
const Footer = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
`;

interface Props {
  facilityId: string;
  handleSavePriceList: (
    data: Omit<CreatePriceListRequestWithUTC, "facilityId">,
  ) => void;
}

export const AddPriceListForm: React.FC<Props> = ({
  facilityId,
  handleSavePriceList,
}) => {
  const intl = useIntl();
  const [selectedBookableEntityType, setSelectedBookableEntityType] =
    useState<any>();
  const [priceListName, setPriceListName] = useState<string>();
  const [validTo, setValidTo] = useState<DateTime>();
  const [validFrom, setValidFrom] = useState<DateTime>(DateTime.now());

  const { facility } = useFacility(facilityId);

  return (
    <div className="space-y-4">
      <InputContainer>
        <label htmlFor="name" className="p-block">
          <FormattedMessage id="common.name" defaultMessage="Namn" />
          <br />
          <small>
            <FormattedMessage
              id="pricings.price-list.name"
              defaultMessage="Namn på prislistan"
            />
          </small>
        </label>
        <div>
          <TextInput
            id="name"
            name="name"
            value={priceListName}
            onChange={e => {
              setPriceListName(e.target.value);
            }}
          />
        </div>
      </InputContainer>

      <InputContainer>
        <label htmlFor="name">
          <FormattedMessage id="pricings.court-type" defaultMessage="Bantyp" />
          <br />
          <small>
            <FormattedMessage
              id="pricings.court-type.description"
              defaultMessage="Vilken kategori av banor vill du att priset skall gälla?"
            />
          </small>
        </label>
        <div>
          <SelectInput
            value={selectedBookableEntityType}
            onChange={e => {
              setSelectedBookableEntityType(e.value);
            }}
            options={facility?.bookableEntityTypes?.map(({ name, id }) => ({
              label: name,
              value: id,
            }))}
          />
        </div>
      </InputContainer>

      <InputContainer>
        <label htmlFor="validFrom" className="p-block">
          <FormattedMessage
            id="common.valid-from"
            defaultMessage="Giltig från"
          />
          <br />
          <small>
            <FormattedMessage
              id="pricings.price-list.valid-from.description"
              defaultMessage="Den tid du vill att prislistan skall börja gälla. Lämna tomt för att sätta prislistan till standardlista."
            />
          </small>
        </label>
        <div>
          <CalendarInput
            id="validFrom"
            name="validFrom"
            onChange={e => setValidFrom(e.value)}
          />
        </div>
      </InputContainer>

      <InputContainer>
        <label htmlFor="validTo" className="p-block">
          <FormattedMessage id="common.valid-thru" />
          <br />
          <small>
            <FormattedMessage
              id="pricings.price-list.valid-to.description"
              defaultMessage="Den tid du vill att prislistan skall sluta gälla. Lämna tomt för att sätta prislistan till att gälla tillsvidare."
            />
          </small>
        </label>
        <CalendarInput
          id="validTo"
          name="validTo"
          onChange={e => setValidTo(e.value)}
        />
      </InputContainer>

      <Footer>
        <Button
          type="primary"
          disabled={!selectedBookableEntityType || !priceListName}
          buttonType="button"
          onClick={() =>
            handleSavePriceList({
              name: priceListName,
              bookableEntityType: selectedBookableEntityType,
              validFrom: DateOnly.fromDateTime(
                forceDateTimeToTimeZone(
                  validFrom || DateTime.now(),
                  facility.localization.timeZone,
                ),
              ),
              validTo:
                validTo &&
                DateOnly.fromDateTime(
                  forceDateTimeToTimeZone(
                    validTo,
                    facility.localization.timeZone,
                  ),
                ),
              defaultCategoryName: intl.formatMessage({
                id: "pricing.category.default-name",
              }),
            })
          }
          text="Skapa prislista"
          translationName="pricings.price-list.create"
        />
      </Footer>
    </div>
  );
};
