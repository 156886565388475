import * as React from "react";
import { FormattedMessage } from "react-intl";

import { useCurrencyFormat } from "../../../hooks/useCurrencyFormat";

import { CheckoutViewHeading } from "../CheckoutView";
import { useCheckoutState } from "../context/CheckoutContext";

export const PaymentDetails = () => {
  const { order, isSplitPayment } = useCheckoutState();
  const { cf } = useCurrencyFormat(order.total.currencyCode);

  return (
    <div>
      <CheckoutViewHeading>
        <FormattedMessage id="checkout.section.payment-details.heading" />
      </CheckoutViewHeading>

      <dl className="mt-3 grid grid-cols-2 gap-1 text-gray-700 lg:text-md">
        {order.items.map(item => {
          let translationId = "";

          switch (item.$type) {
            case "membership": {
              translationId = "common.membership";
              break;
            }
            case "event": {
              translationId = "common.event";
              break;
            }
            case "series": {
              translationId = "common.serie";
              break;
            }
            case "booking":
            default: {
              translationId = "common.booking";
              break;
            }
          }

          return (
            <React.Fragment key={item.id}>
              <dt>
                <FormattedMessage id={translationId} />
              </dt>
              <dd className="text-right">
                {cf(
                  (isSplitPayment || !order.isSplittable) &&
                    item.individualPrice
                    ? item.individualPrice.valueInclTax
                    : item.price.valueInclTax,
                )}
              </dd>
            </React.Fragment>
          );
        })}

        <dt>
          <FormattedMessage id="booking.fee" />
        </dt>
        <dd className="text-right">
          {cf(
            isSplitPayment || !order.isSplittable
              ? order.individualTotalFee.valueInclTax
              : order.totalFee.valueInclTax,
          )}
        </dd>

        <dt className="mt-1 text-md font-bold text-pureblack lg:mt-2 lg:text-lg">
          <FormattedMessage id="payment.total" />
        </dt>
        <dd className="mt-2 text-right text-md font-bold text-pureblack lg:text-lg">
          {cf(
            isSplitPayment || !order.isSplittable
              ? order.individualTotal.valueInclTax
              : order.total.valueInclTax,
          )}
        </dd>

        {isSplitPayment && order.isSplittable && (
          <>
            <dt>
              <FormattedMessage id="checkout.section.payment-details.possible-remaining-cost" />
            </dt>
            <dd className="text-right">
              {cf(order.splitRemainder.valueInclTax)}
            </dd>
          </>
        )}
      </dl>
    </div>
  );
};
