import { FormattedMessage } from "react-intl";

import { TabPanel, TabView as _TabView } from "primereact/tabview";
import styled from "styled-components";

import { SuperAdminFacilityOverview as FacilityOverview } from "./tabs/FacilityOverview/FacilityOverview";
import { MaintenanceMessageTab } from "./tabs/MaintenanceMessageTab";

const OverviewLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  gap: 2rem;
  margin-bottom: 5rem;
`;

const TabView = styled(_TabView)`
  margin-top: 1rem;

  ul.p-tabview-nav {
    background: transparent;
    border: 0 !important;

    li {
      border-radius: var(--border-radius) var(--border-radius) 0 0;
      padding: 0rem 1.25rem !important;
    }

    li.p-highlight a {
      color: black !important;
    }

    li a {
      background: transparent !important;
      border-color: transparent !important;
    }

    li a:focus {
      outline: none !important;
      border: 0 !important;
      box-shadow: none !important;
    }

    li.p-tabview-selected {
      border-color: transparent !important;
      background: var(--light) !important;
      color: black !important;
    }
  }
`;

const tabs = [
  {
    id: "navigation.menuitem.venues",
    Component: FacilityOverview,
  },
  {
    id: "common.error-message",
    Component: MaintenanceMessageTab,
  },
];

export const SuperAdmin = () => {
  return (
    <OverviewLayout>
      <TabView>
        {tabs.map(({ id, Component }) => (
          <TabPanel
            key={id}
            className="p-4"
            header={<FormattedMessage id={id} />}
          >
            {Component && <Component />}
          </TabPanel>
        ))}
      </TabView>
    </OverviewLayout>
  );
};
