import React, { useRef, useState } from "react";
import ReactCrop, {
  Crop,
  PixelCrop,
  centerCrop,
  makeAspectCrop,
} from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

import { faUndo } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

import { getCroppedImage } from "../../utils/getCroppedImage";
import { Button } from "../Button";

interface Props {
  imageSrc: string;
  imageName: string;
  imageType: string;
  aspectRatio: [number, number];
  onSave: (file: File) => void;
  onCancel: () => void;
}

const Actions = styled.div`
  display: flex !important;
  flex-direction: row !important;
`;

const ImageInputCropper: React.FC<Props> = ({
  imageSrc,
  imageName,
  imageType,
  aspectRatio,
  onSave,
  onCancel,
}) => {
  const imgRef = useRef<HTMLImageElement>(null);
  const [crop, setCrop] = useState<Crop>();
  const [scale, setScale] = useState<number>(1);
  const [rotation, setRotation] = useState<number>(0);
  const [croppedArea, setCroppedArea] = useState<PixelCrop>();

  const centerAspectCrop = (
    mediaWidth: number,
    mediaHeight: number,
    aspect: number,
  ) => {
    return centerCrop(
      makeAspectCrop(
        {
          unit: "%",
          width: 90,
        },
        aspect,
        mediaWidth,
        mediaHeight,
      ),
      mediaWidth,
      mediaHeight,
    );
  };

  const onImageLoad = (event: React.SyntheticEvent<HTMLImageElement>) => {
    if (aspectRatio) {
      const { width, height } = event.currentTarget;
      setCrop(centerAspectCrop(width, height, aspectRatio[0] / aspectRatio[1]));
    }
  };

  const reset = () => {
    setScale(1);
    setCrop(null);
    setRotation(0);
    setCroppedArea(null);
  };

  const handleSaveClick = async () => {
    const croppedImage = await getCroppedImage(
      imgRef.current,
      croppedArea,
      imageType,
      scale,
      rotation,
    );

    onSave(
      new File([croppedImage], imageName, {
        type: imageType,
      }),
    );

    reset();
  };

  const handelCancelClick = () => {
    reset();
    onCancel();
  };

  return (
    <div>
      <div>
        {Boolean(imageSrc) && (
          <ReactCrop
            ruleOfThirds
            crop={crop}
            onChange={(_, percentCrop) => setCrop(percentCrop)}
            onComplete={c => {
              setCrop(c);
              setCroppedArea(c);
            }}
            aspect={aspectRatio[0] / aspectRatio[1]}
          >
            <img
              ref={imgRef}
              alt="Crop me"
              src={imageSrc}
              style={{ transform: `scale(${scale}) rotate(${rotation}deg)` }}
              onLoad={onImageLoad}
            />
          </ReactCrop>
        )}
        <div onClick={() => setRotation(rotation => rotation + 90)}>
          <FontAwesomeIcon icon={faUndo} flip="horizontal" />
        </div>
      </div>
      <Actions>
        <Button
          translationName="common.cancel"
          text="Avbryt"
          type="danger"
          onClick={handelCancelClick}
        />
        <Button
          translationName="common.upload"
          text="Ladda upp"
          type="primary"
          onClick={handleSaveClick}
        />
      </Actions>
    </div>
  );
};

export default ImageInputCropper;
