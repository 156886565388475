import { Slider, type SliderOwnProps } from "@mui/base/Slider";

export const SkillLevelSlider = ({
  min = 1,
  max = 10,
  step = 0.1,
  ...props
}: SliderOwnProps) => {
  return (
    <Slider
      {...props}
      slots={{
        ...props.slots,
        root: "div",
        rail: "div",
        track: "div",
        mark: "i",
        markLabel: "span",
        thumb: "div",
      }}
      min={min}
      max={max}
      step={step}
      shiftStep={step || undefined}
      disableSwap
      marks={Array.from({ length: max }, (_, i) => ({
        value: i + 1,
        label: i + 1,
      }))}
      slotProps={{
        root: {
          className:
            "flex items-center flex-1 relative py-4 touch-none cursor-pointer",
        },
        rail: {
          className: "w-full rounded-full h-2.5 bg-gray-200 block absolute",
        },
        track: {
          className: "h-2.5 absolute bg-primary",
        },
        mark: {
          className:
            "absolute rounded-full -translate-x-1/2 size-1 bg-purewhite first-of-type:hidden last-of-type:hidden",
        },
        markLabel: {
          className: "absolute bottom-[30px] px-1 -translate-x-1/2",
        },
        thumb: {
          className:
            "size-5 rounded-full -translate-x-1/2 bg-primary outline-[7px] outline-primary-dark/10 block absolute outline",
        },
      }}
    />
  );
};
