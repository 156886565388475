import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory, useParams } from "react-router-dom";

import styled from "styled-components";

import { breakpoints } from "../../appConstants/common";

import profileBackground from "../../assets/images/backgrounds/profile-background.png";

import { getMyProfileTabPath } from "../../helpers/pathHelpers";

import { useIsMobile } from "../../hooks/common/useIsMobile";
import { useCurrentUser } from "../../hooks/swr/useCurrentUser";
import { useUser } from "../../hooks/swr/useUser";

import { Card as _Card } from "../../components/Card";
import { ProfileImageWithFallback } from "../../components/ProfileImageWithFallback";
import { ProgressSpinner } from "../../components/ProgressSpinner";
import { Tabs } from "../../components/Tabs";
import { Bookings } from "../../modules/checkout/components/Games/Bookings";
import { Receipts } from "../../modules/checkout/components/Receipts/Receipts";
import { UserActivities } from "../../modules/game/components/activities/UserActivities";
import { FriendRequestButton } from "../../modules/player/components/FriendRequestButton";
import { FriendsTab } from "../../modules/player/components/FriendsTab";
import { UploadProfileImageDialog } from "./components/UploadProfileImageDialog";
import { UserFacilities } from "./components/UserFacilities";
import { UserSettings } from "./components/UserSettings/UserSettings";

import { userProfileTabs } from "../../modules/player/constants";

const OverviewLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  gap: 0;
  margin-bottom: 5rem;

  @media (min-width: 1014px) {
    gap: 2rem;
  }
`;

const Header = styled.div`
  width: 100%;
  position: relative;
  background: var(--light);
`;

const HeaderImage = styled.div`
  height: 100px;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  @media (min-width: ${breakpoints.XL}) {
    height: 250px;
  }

  &:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: transparent;
    background: linear-gradient(
      0deg,
      rgba(0, 105, 210, 0.5) 0%,
      rgba(0, 55, 112, 0.14609593837535018) 46%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
`;

const ChangeImageWrapper = styled.div`
  border-radius: var(--border-radius);
  padding: 0.2rem 0.75rem;
  background: var(--dark);
  color: var(--light);
  font-weight: var(--medium);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0.75rem;
  cursor: pointer;
  opacity: 0;
  transition: opacity 125ms ease-in-out;
`;

const HeaderProfileImage = styled.div`
  position: absolute;
  top: calc(3.125rem);
  left: 1rem;
  border: 0.2rem solid var(--gray-100);
  background: var(--gray-200);
  width: 6.25rem;
  height: 6.25rem;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: ${breakpoints.XL}) {
    top: calc(250px - 7rem);
    left: 3rem;
    width: 14rem;
    height: 14rem;
    border: 0.5rem solid var(--light);
  }

  img {
    width: auto;
    height: 100%;
  }

  &:hover {
    ${ChangeImageWrapper} {
      opacity: 1;
      transition: opacity 200ms ease-in-out;
    }
  }
`;
const HeaderContent = styled.div`
  margin-left: 0;
  display: grid;
  flex-direction: column;
  align-items: center;
  gap: 0.2rem;

  @media (min-width: ${breakpoints.MOBILE}) {
    margin-left: 0;
  }

  @media (min-width: ${breakpoints.LARGE}) {
    margin-top: 0;
  }
  @media (min-width: ${breakpoints.XL}) {
    margin-left: 18rem;
    min-height: 4rem;
  }
`;

const H3 = styled.h3`
  font-size: 1.125rem;
  font-weight: var(--bold);

  @media (min-width: ${breakpoints.XL}) {
    font-size: 2rem;
  }
`;

const InfoLine = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--gray-400);
  font-weight: var(--medium) !important;
  font-size: 0.7rem;

  @media (min-width: ${breakpoints.XL}) {
    font-size: 1rem;
  }
`;

const ProfileNameWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 2rem;
`;

const HeaderContentContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  padding: 1rem 1rem 2rem 8rem;
  flex-direction: row;

  @media (min-width: ${breakpoints.XL}) {
    padding: 2.5rem 3rem 2.5rem 2rem;
    flex-direction: row;
    margin-top: 0rem;
    gap: 2rem;
    align-items: center;
  }
`;

const ActionContainer = styled.div`
  flex: 1 0 auto;
  display: flex;
  justify-content: flex-end;

  button {
    box-shadow: none;
  }

  @media (min-width: ${breakpoints.XL}) {
    justify-content: flex-end;
  }
`;

const Card = styled(_Card)`
  box-shadow: none;
`;

const tabs = [
  {
    id: userProfileTabs.UPCOMPING_BOOKINGS,
    translationId: "user-profile.tab.my-bookings",
    defaultMessage: "Mina bokningar",
    Component: Bookings,
    props: { isUpcoming: true },
  },
  {
    id: userProfileTabs.PLAYED_GAMES,
    translationId: "user-profile.tab.player-games",
    defaultMessage: "Spelade matcher",
    Component: Bookings,
    props: { isUpcoming: false },
  },
  {
    id: userProfileTabs.ACTIVITIES,
    translationId: "activities.title",
    defaultMessage: "Aktiviteter",
    Component: UserActivities,
    props: { pageSize: 100 },
  },
  {
    id: userProfileTabs.FRIENDS,
    translationId: "user-profile.tab.friends",
    defaultMessage: "Vänner",
    Component: FriendsTab,
  },
  {
    id: userProfileTabs.RECEIPTS,
    translationId: "profile.receipts.label",
    defaultMessage: "Kvitton",
    Component: Receipts,
  },
  {
    id: userProfileTabs.SETTINGS,
    translationId: "user-profile.tab.settings",
    defaultMessage: "Inställningar",
    Component: UserSettings,
  },
  {
    id: userProfileTabs.FACILITIES,
    translationId: "user-profile.tab.facilities",
    defaultMessage: "Mina hallar",
    Component: UserFacilities,
  },
];

interface ProfileParams {
  userId?: string;
  userProfileTabId?: string;
}
export const UserProfilePage = () => {
  const history = useHistory();
  const { userId: userIdParam, userProfileTabId } = useParams<ProfileParams>();
  const { currentUser } = useCurrentUser();

  const activeTab = userProfileTabId
    ? parseInt(userProfileTabId)
    : userProfileTabs.UPCOMPING_BOOKINGS;

  const userId = userIdParam ? userIdParam : currentUser?.id;

  const { user, isLoading } = useUser(userId);
  const isMobile = useIsMobile();
  const isMyProfile = !userId || userId === currentUser?.id;
  const [displayImageModifierDialog, setDisplayImageModifierDialog] =
    useState(false);

  if (isLoading) {
    return <ProgressSpinner />;
  }

  const CardComponent = isMobile
    ? ({ children }: React.PropsWithChildren<{ className: string }>) => (
        <div style={{ background: "white !important" }}>{children}</div>
      )
    : Card;

  return (
    <>
      <OverviewLayout>
        <CardComponent className="p-0">
          <Header>
            <HeaderImage>
              <img src={profileBackground} />
            </HeaderImage>
            <HeaderProfileImage
              key={Date.now()}
              style={isMyProfile ? { cursor: "pointer" } : {}}
              onClick={() =>
                isMyProfile ? setDisplayImageModifierDialog(true) : undefined
              }
            >
              <ProfileImageWithFallback
                src={user?.profileImage}
                firstName={user?.firstName}
                lastName={user?.lastName}
                width="100%"
                height="100%"
                fontSize="6.6rem"
              />
              {isMyProfile && (
                <ChangeImageWrapper>
                  <FormattedMessage
                    id="profile-settings.change-profile-picture"
                    defaultMessage="Byt bild"
                  />
                </ChangeImageWrapper>
              )}
            </HeaderProfileImage>

            <HeaderContentContainer>
              <HeaderContent>
                <ProfileNameWrapper>
                  <H3>{user?.displayName}</H3>
                </ProfileNameWrapper>
                <InfoLine>{user?.emailAddress}</InfoLine>
              </HeaderContent>

              {!isMyProfile && (
                <ActionContainer>
                  <FriendRequestButton userId={userId} />
                </ActionContainer>
              )}
            </HeaderContentContainer>
          </Header>
        </CardComponent>
        {isMyProfile && (
          <Tabs
            tabs={tabs}
            activeIndex={activeTab}
            onActiveIndexChange={index => {
              history.replace(getMyProfileTabPath(index));
            }}
          />
        )}
      </OverviewLayout>

      <UploadProfileImageDialog
        visible={displayImageModifierDialog}
        onClose={() => setDisplayImageModifierDialog(false)}
      />
    </>
  );
};
