import { DateTime } from "luxon";

import { Price } from "../../checkout/models/Pricing";
import { FacilityWithUtc } from "../../customer/models/Facility";

export interface Membership {
  id: string;
  facilityId: FacilityWithUtc["id"];
  name: string;
  description: string;
  color: string;
  membershipPrices: MembershipPrice[];
  groups: MembershipGroup[];
  benefits: MembershipBenefit[];
  users: MembershipUser[];
  isCurrentUserSubscribed: boolean;
  isPurchasable: boolean;
  isSubscriptionMembership: boolean;
  memberCategoriesNeeded: number[];
  memberCategory: number;
  userCount: number;
}

export interface MembershipUser {
  consecutivePaymentAttempts: number;
  expiryDate: DateTime;
  isSubscribed: boolean;
  lastSubscriptionExpirationDate: DateTime;
  membershipPrice: MembershipPrice;
  nextPaymentDate: DateTime;
  paymentReferences: PaymentReference[];
  renewealStatus: RenewalStatus;
  status: MembershipStatus;
  subscriptionCancelByHideMembership: boolean;
  subscriptionDate: DateTime;
  userId: string;
}

export enum MembershipStatus {
  Active = 0,
  Inactive = 1,
  Expired = 2,
}

interface MembershipBenefit {
  name: string;
  description: string;
  facilityId: string;
  sportTypeId?: string;
  id: string;
}

interface MembershipGroup {
  id: string;
}

export interface MembershipPrice {
  description: string;
  expiryDate: DateTime;
  facilityId?: string;
  id: string;
  interval: number;
  intervalType: IntervalType;
  name: string;
  nextRenewalDate: DateTime;
  price: Price;
  renewal: Renewal;
}

enum IntervalType {
  Month = 1,
  Year = 2,
  Day = 3,
}

interface PaymentReference {
  dueDate: DateTime;
  isPayed: boolean;
  lastPaymentRetry: DateTime;
  paymentId: string;
}
interface Renewal {
  annual: boolean;
  fee: number;
  interval: number;
  renewalType: RenewalType;
  timeBeforeRenewal: {
    interval: number;
    intervalType: IntervalType;
    renewInterval?: number;
    renewIntervalType?: IntervalType;
  };
}

export enum RenewalType {
  Monthly = 1,
  Annually = 2,
}

enum RenewalStatus {
  New = 0,
  Renewed = 1,
  RenewalMessageSent = 2,
  UnableToRenew = 3,
  RemoveMembershipSent = 4,
  Removed = 5,
}

export interface MembershipPurchaseRequest {
  membershipId: string;
  membershipPriceId: string;
  paymentCompleteUrl: string;
  cardPaymentUrl: string;
}

export interface CardVerifyRequest {
  paymentCompleteUrl: string;
  cardPaymentUrl: string;
  prefilledInformationIsUsed?: boolean;
}

export interface MembershipApiResponse
  extends Omit<Membership, "users" | "membershipPrices"> {
  users: MembershipUserApiResponse[];
  membershipPrices: MembershipPriceApiResponse[];
}

export interface MembershipUserApiResponse
  extends Omit<
    MembershipUser,
    | "expiryDate"
    | "lastSubscriptionExpirationDate"
    | "membershipPrice"
    | "nextPaymentDate"
    | "paymentReferences"
    | "subscriptionDate"
  > {
  expiryDate: string;
  lastSubscriptionExpirationDate: string;
  membershipPrice: MembershipPriceApiResponse;
  nextPaymentDate: string;
  paymentReferences: PaymentReferenceApiResponse[];
  subscriptionDate: string;
}

export interface PaymentReferenceApiResponse
  extends Omit<PaymentReference, "dueDate" | "lastPaymentRetry"> {
  dueDate: string;
  lastPaymentRetry: string;
}

export interface MembershipPriceApiResponse
  extends Omit<MembershipPrice, "expiryDate" | "nextRenewalDate"> {
  expiryDate: string;
  nextRenewalDate: string;
}
