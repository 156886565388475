import { useEffect, useState } from "react";
import { useIntl } from "react-intl";

import {
  faCalendar,
  faCreditCard,
  faWarehouse,
} from "@fortawesome/pro-light-svg-icons";
import styled from "styled-components";

import { breakpoints } from "../../../../appConstants/common";

import { IDirectory } from "../../../../models/common";
import { FacilityWithUtc } from "../../../customer/models/Facility";
import { BuyableType, PaymentStatus } from "../../models/Payment";
import { Receipt, SelectedReceiptsFilter } from "../../models/Receipts";

import { SelectInput } from "../../../../components/inputs/SelectInput";

import { useAppLocale } from "../../../../recoil/i18nConfigState";
import { ALL_ALTERNATIVE } from "../../constants/receipts";

interface Props {
  initialSelection: SelectedReceiptsFilter;
  receipts: Receipt[];
  facilitiesEntities: FacilityWithUtc[];
  onChange: (data: SelectedReceiptsFilter) => void;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  svg {
    margin-right: 0.5rem;
  }

  @media (min-width: ${breakpoints.XL}) {
    flex-direction: row;
    justify-content: flex-end;
    > div {
      width: 12.5rem;
    }
  }
`;

const FilterContainer = styled.div`
  align-items: center;
  width: 100%;
  display: flex;
  gap: 0.5rem;
`;

export const ReceiptsFilters: React.FC<Props> = ({
  initialSelection,
  receipts,
  facilitiesEntities,
  onChange,
}) => {
  const intl = useIntl();
  const locale = useAppLocale();

  const getTranslation = (id: string) => intl.formatMessage({ id: id });

  const getFacilityLabel = (facilityId: string) => {
    if (facilityId == ALL_ALTERNATIVE) {
      return getTranslation(
        "receipts.translation." + facilityId + ".facilities",
      );
    }

    const facility = facilitiesEntities.find(({ id }) => id === facilityId);
    return facility?.name;
  };

  const getPaymentStatus = (paymentStatuses: string) => {
    if (paymentStatuses == ALL_ALTERNATIVE)
      return getTranslation(
        "receipts.translation." + paymentStatuses + ".statuses",
      );
    ALL_ALTERNATIVE;
    const paymentStatus = PaymentStatus[paymentStatuses];
    if (paymentStatus)
      return getTranslation(
        "receipts.translation." + paymentStatus + ".statuses",
      );
  };

  const getPaymentTypeLabel = (buyableType: string) => {
    if (buyableType == ALL_ALTERNATIVE)
      return getTranslation("receipts.translation." + buyableType + ".types");
    const buyableTypeName = BuyableType[buyableType];
    if (buyableTypeName)
      return getTranslation(
        "receipts.translation." + buyableTypeName?.toLowerCase() + ".types",
      );
  };

  const [types, setTypes] = useState<IDirectory[]>([]);
  const [statuses, setStatuses] = useState<IDirectory[]>([]);
  const [facilities, setFacilities] = useState<IDirectory[]>([]);
  const [selectedType, setSelectedType] = useState<IDirectory>({
    id: initialSelection.externalIdType,
    name: getPaymentTypeLabel(initialSelection.externalIdType),
  });
  const [selectedStatus, setSelectedStatus] = useState<IDirectory>({
    id: initialSelection.paymentStatus,
    name: getPaymentStatus(initialSelection.paymentStatus),
  });
  const [selectedFacility, setSelectedFacility] = useState<IDirectory>({
    id: initialSelection.facilityId,
    name: getFacilityLabel(initialSelection.facilityId),
  });

  useEffect(() => {
    const extractValues = (
      array: any[],
      field: string,
      nameGenerator?: (value: any) => string,
    ) => {
      const uniqueValues = Array.from(
        new Set(array?.map(value => value[field]).filter(x => x)),
      );

      uniqueValues.unshift(ALL_ALTERNATIVE);
      return uniqueValues.map(
        value =>
          ({
            id: value,
            name: nameGenerator?.(value),
          }) as IDirectory,
      );
    };
    setTypes(extractValues(receipts, "externalIdType", getPaymentTypeLabel));
    setStatuses(extractValues(receipts, "paymentStatus", getPaymentStatus));
    setFacilities(extractValues(receipts, "facilityId", getFacilityLabel));
  }, [receipts, locale]);

  useEffect(() => {
    const filter: SelectedReceiptsFilter = {
      externalIdType: selectedType.id,
      paymentStatus: selectedStatus.id,
      facilityId: selectedFacility.id,
    };
    onChange(filter);
  }, [selectedType, selectedStatus, selectedFacility]);

  const filterInputs = [
    {
      icon: faCalendar,
      optionData: types,
      value: selectedType.id,
      onSelect: (value: string) =>
        setSelectedType({ id: value, name: "receipts-filter-types" }),
    },
    {
      icon: faCreditCard,
      optionData: statuses,
      value: selectedStatus.id,
      onSelect: (value: string) =>
        setSelectedStatus({ id: value, name: "receipts-filter-status" }),
    },
    {
      icon: faWarehouse,
      optionData: facilities,
      value: selectedFacility.id,
      onSelect: (value: string) =>
        setSelectedFacility({ id: value, name: "receipts-filter-facility" }),
    },
  ];

  return (
    <Container>
      {filterInputs.map(({ optionData, value, onSelect }, i) => (
        <FilterContainer key={i}>
          <SelectInput
            options={optionData.map(({ name, id }) => ({
              label: name,
              value: id,
            }))}
            value={value}
            onChange={e => onSelect(e.value)}
          />
        </FilterContainer>
      ))}
    </Container>
  );
};
