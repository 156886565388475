export const RegexExpression = {
  Email: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i,

  Number: {
    ThousendsSelector: /\B(:(\d{3})+(?!\d))/g,
  },

  Dates: {
    IsoDateFormat:
      /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?(?:[-+]\d{2}:?\d{2}|Z)?$/g,
  },

  String: {
    AllExceptDigits: /^\D+/g,
  },

  validURL(str: string): boolean {
    const res = str.match(
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)/g,
    );
    return res !== null;
  },
};
