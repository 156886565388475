import { useCallback } from "react";

import { DateTime } from "luxon";

import { DateOnly } from "../models/DateOnly";
import { TimeOnly } from "../models/TimeOnly";
import { FacilityWithUtc } from "../modules/customer/models/Facility";

import { useAppLocale } from "../recoil/i18nConfigState";
import { useSelectedFacilityId } from "../recoil/selectedFacilityIdState";
import { useFacility } from "./swr/useFacility";

type FormatOptions = Parameters<DateTime["toLocaleString"]>[0];

type DatePartToElementMapper = (
  index: number,
  part: Intl.DateTimeFormatPart,
) => JSX.Element;

type AcceptedDateFormat = DateTime | TimeOnly | DateOnly;

export const useDateFormat = (facilityId?: FacilityWithUtc["id"] | null) => {
  const locale = useAppLocale();
  const { facility } = useFacility(facilityId, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  const setZone = useCallback(
    (date: DateTime): DateTime =>
      date.setZone(facilityId ? facility?.localization?.timeZone : "local"),
    [facility?.localization?.timeZone, facilityId],
  );

  const setLocale = useCallback(
    (date: DateTime): DateTime => date.setLocale(locale),
    [locale],
  );

  const modifyDate = useCallback(
    (date: AcceptedDateFormat): DateTime => {
      if (date instanceof TimeOnly || date instanceof DateOnly) {
        return setLocale(date.toDateTime());
      } else {
        return setZone(setLocale(date));
      }
    },
    [setLocale, setZone],
  );

  const df = useCallback(
    (date: AcceptedDateFormat, format: FormatOptions): string =>
      modifyDate(date).toLocaleString(format),
    [modifyDate],
  );

  const dfInterval = useCallback(
    (
      fromDate: AcceptedDateFormat,
      toDate: AcceptedDateFormat,
      format: FormatOptions,
    ) => {
      return [df(fromDate, format), df(toDate, format)].join(" - ");
    },
    [df],
  );

  const dfElements = useCallback(
    (
      date: AcceptedDateFormat,
      format: FormatOptions,
      mapper: DatePartToElementMapper,
    ) => {
      return (
        <>
          {modifyDate(date)
            .toLocaleParts(format)
            .map((part: Intl.DateTimeFormatPart, index: number) => {
              const element = mapper(index, {
                type: part.type,
                value: part.value,
              });

              if (!element) return null;

              return (
                <span key={`dfElement-${date.toString()}-${index}`}>
                  {element}
                </span>
              );
            })
            .filter(element => element !== null)}
        </>
      );
    },
    [modifyDate],
  );

  return {
    df,
    dfInterval,
    dfElements,

    /**
     * Uses `.setZone() ` to set the timezone of the date to the facility's timezone if the facilityId is provided.
     * Otherwise, set the timezone to the local timezone.
     */
    setZone,

    /**
     * Uses `.setLocale()` to set the locale of the date to the app's locale.
     */
    setLocale,
  };
};

export const useDateFormatWithSelectedFacility = () => {
  const selectedFacilityId = useSelectedFacilityId();

  return useDateFormat(selectedFacilityId);
};
