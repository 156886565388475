import primelocale_de from "primelocale/de.json";
import primelocale_en_gb from "primelocale/en-gb.json";
import primelocale_fi from "primelocale/fi.json";
import primelocale_sv from "primelocale/sv.json";
import { addLocale } from "primereact/api";

import de from "../lang/de/content.json";
import en from "../lang/en/content.json";
import fi from "../lang/fi/content.json";
import sv from "../lang/sv/content.json";

const SUPPORTED_LOCALES = ["sv-SE", "en-GB", "fi-FI", "de-DE"] as const;

type SupportedLocale = (typeof SUPPORTED_LOCALES)[number];
type LocaleToAnythingMapping = Record<SupportedLocale, any>;

const primelocaleOptions = {
  "sv-SE": primelocale_sv.sv,
  "en-GB": primelocale_en_gb["en-gb"],
  "fi-FI": primelocale_fi.fi,
  "de-DE": primelocale_de.de,
} satisfies LocaleToAnythingMapping;

export const intlMessages = {
  "sv-SE": sv,
  "en-GB": en,
  "fi-FI": fi,
  "de-DE": de,
} satisfies LocaleToAnythingMapping;

export interface I18nConfig {
  /**
   * Country code, e.g. "se" or "gb".
   */
  countryCode: string;

  /**
   * Locale in the format of language code and country code, e.g. "en-GB".
   */
  locale: SupportedLocale;

  /**
   * Language code, e.g. "en".
   */
  languageCode: string;

  /**
   * Label for the language, e.g. "English".
   */
  label: string;

  /**
   * Abbreviation for the language, e.g. "EN".
   */
  abbreviation: string;
}

export const I18N_CONFIGS: I18nConfig[] = [
  {
    countryCode: "se",
    locale: "sv-SE",
    languageCode: "sv",
    label: "Svenska",
    abbreviation: "SV",
  },
  {
    countryCode: "gb",
    locale: "en-GB",
    languageCode: "en",
    label: "English",
    abbreviation: "EN",
  },
  {
    countryCode: "fi",
    locale: "fi-FI",
    languageCode: "fi",
    label: "Suomi",
    abbreviation: "FI",
  },
  {
    countryCode: "de",
    locale: "de-DE",
    languageCode: "de",
    label: "Deutsch",
    abbreviation: "DE",
  },
];

for (const i18nConfig of I18N_CONFIGS) {
  addLocale(i18nConfig.locale, primelocaleOptions[i18nConfig.locale]);
}

export const DEFAULT_I18N_CONFIG = I18N_CONFIGS[1];

export const LOCAL_STORAGE_LOCALE_KEY = "locale";

export const LOCALE_TO_FLAG_MAP = {
  "sv-SE": "se",
  "en-GB": "gb",
  "fi-FI": "fi",
  "de-DE": "de",
} satisfies LocaleToAnythingMapping;
