import { FormattedMessage, useIntl } from "react-intl";

import { DateTime } from "luxon";

import { useFacility } from "../../../hooks/swr/useFacility";
import { useDateFormat } from "../../../hooks/useDateFormat";

import { luxonTimeFormat } from "../../../utils/dateFormats";
import { CheckoutViewHeading } from "../CheckoutView";
import { useCheckoutState } from "../context/CheckoutContext";
import { OrderDetails } from "./OrderDetails";

export const EventOrderDetails = () => {
  const intl = useIntl();

  const state = useCheckoutState();

  const { facility } = useFacility(state.order.facilityId);
  const { df } = useDateFormat(state.order.facilityId);

  if (state.orderType !== "event") {
    return null;
  }

  const { event } = state;

  const orderDetails = [
    {
      heading: intl.formatMessage({
        id: "common.event",
      }),
      text: event.name,
    },
    {
      heading: intl.formatMessage({
        id: "common.date-and-time",
      }),
      text: (
        <>
          {df(event.startTime, DateTime.DATETIME_MED)}-
          {df(event.endTime, luxonTimeFormat)}
        </>
      ),
    },
    {
      heading: intl.formatMessage({
        id: "common.venue",
      }),
      text: event.facilityName,
    },
    {
      heading: intl.formatMessage({
        id:
          event.courtIdsToPlay?.length ?? 0 > 0
            ? "common.courts"
            : "common.court",
      }),
      text: event.courtIdsToPlay
        ?.map(courtId => {
          return facility?.bookableEntities?.find(
            bookableEntity => bookableEntity.id === courtId,
          )?.name;
        })
        .join(", "),
    },
  ];

  if (facility?.shortMessage) {
    orderDetails.push({
      heading: intl.formatMessage({
        id: "common.facility.info",
      }),
      text: facility?.shortMessage ?? "",
    });
  }

  return (
    <div>
      <CheckoutViewHeading>
        <FormattedMessage id="checkout.section.order-details.heading" />
      </CheckoutViewHeading>

      <OrderDetails orderDetails={orderDetails} firstWide />
    </div>
  );
};
