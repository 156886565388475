import React, { useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";

import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { debounce, sortBy } from "lodash";

import { PaymentStatus } from "../../../modules/checkout/models/Payment";
import { FacilityWithUtc } from "../../../modules/customer/models/Facility";

import { useCurrentUser } from "../../../hooks/swr/useCurrentUser";
import { useFacilityMemberships } from "../../../hooks/swr/useFacilityMemberships";
import { useQuery } from "../../../hooks/useQuery";

import { sendCallback } from "../../../modules/checkout/services/Payment";
import { unsubscribeMembership } from "../../../modules/player/services/MembershipService";

import { AppLoadingSpinner } from "../../../components/AppLoadingSpinner";
import { PendingPayment } from "../../../components/PendingPayment";
import { MembershipOption } from "./components/MembershipOption";

interface Props {
  facility: FacilityWithUtc;
}

export const MembershipTab: React.FC<Props> = ({ facility }) => {
  const query = useQuery();
  const history = useHistory();
  const paymentCompleted = !!query?.get("paymentCompleted");
  const paymentPending = !!query?.get("paymentPending");
  const paymentId = query?.get("paymentId");
  const abortController = useRef<AbortController>();
  const [paymentStatus, setPaymentStatus] = useState<number | string>("init");
  const checkingStatus = paymentStatus === "init";
  const { currentUser } = useCurrentUser();
  const carouselRef = useRef<HTMLDivElement>(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [containerWidth, setContainerWidth] = useState(0);

  const {
    memberships: unsortedMemberships,
    isLoading,
    mutate: fetchMemberships,
  } = useFacilityMemberships(facility?.id);
  const memberships = useMemo(() => {
    return sortBy(unsortedMemberships, [
      m => m?.memberCategory,
      m => m?.membershipPrices?.[0]?.price.valueInclTax,
    ]);
  }, [unsortedMemberships]);
  const purchased = memberships?.find(m => m?.id === query?.get("purchased"));

  const handlePrevClick = () => {
    if (scrollPosition > 0) {
      carouselRef?.current.scrollTo({
        left: scrollPosition - 200,
        behavior: "smooth",
      });
    }
  };

  const handleNextClick = () => {
    if (scrollPosition < containerWidth - window.innerWidth) {
      carouselRef?.current.scrollTo({
        left: scrollPosition + 200,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    if (!carouselRef.current) return;

    const container = carouselRef.current;
    // Looks for size changes in carouselRef to set containerWidth to the right size
    const resizeObserver = new ResizeObserver(
      debounce(() => {
        setContainerWidth(container.scrollWidth);
      }, 500),
    );

    resizeObserver.observe(container);

    const handleScroll = () => setScrollPosition(container.scrollLeft);
    container.addEventListener("scroll", handleScroll);

    return () => {
      resizeObserver.unobserve(container);
      container.removeEventListener("scroll", handleScroll);
    };
  }, [carouselRef.current]);

  useEffect(() => {
    if (!paymentPending && facility) {
      fetchMemberships();
    }
    return () => abortController.current?.abort();
  }, [facility, fetchMemberships, paymentPending]);

  useEffect(() => {
    const checkStatus = async () => {
      try {
        const { status } = await sendCallback(query?.get("paymentId"));
        setPaymentStatus(status);
        fetchMemberships();
      } catch (e) {
        console.error(e.message);
        setPaymentStatus("unknown");
      } finally {
        query?.delete("paymentCompleted");
        history.push({ search: query?.toString() });
      }
    };

    if (paymentCompleted) {
      checkStatus();
    }
  }, [paymentCompleted, fetchMemberships]);

  const handleUnsubscribe = async (
    membershipId: string,
    membershipPriceId: string,
  ) => {
    await unsubscribeMembership(membershipId, membershipPriceId);
    fetchMemberships();
  };

  const membershipsNeeded = (memberCategoriesNeeded: number[]) => {
    const needed = memberships?.filter(m =>
      memberCategoriesNeeded?.includes(m?.memberCategory),
    );
    return needed?.length ? needed : null;
  };

  return (
    <div className="flex flex-col items-center justify-center p-4">
      {!checkingStatus && paymentStatus === PaymentStatus.FullyCompleted && (
        <>
          <h3>
            <FormattedMessage id="membership.purchase.success.title" />
          </h3>
          <p className="text-xs text-gray-500">
            <FormattedMessage
              id="membership.purchase.success.details"
              values={{
                membershipName: (
                  <span className="font-bold">{purchased?.name}</span>
                ),
                facilityName: facility?.name,
              }}
            />
          </p>
        </>
      )}
      {!checkingStatus && paymentStatus !== PaymentStatus.FullyCompleted && (
        <>
          <h3>
            <FormattedMessage
              id="membership.purchase.error"
              values={{
                br: <br />,
              }}
            />
          </h3>
        </>
      )}

      {paymentPending && <PendingPayment paymentId={paymentId} />}

      {!paymentPending && (
        <div
          className="flex w-full gap-x-8 gap-y-12 overflow-x-auto p-4 [-ms-overflow-style:'none'] [scrollbar-width:'none'] md:flex-wrap md:justify-center md:overflow-x-visible [&::-webkit-scrollbar]:hidden"
          ref={carouselRef}
        >
          {isLoading && <AppLoadingSpinner />}
          {!isLoading &&
            memberships?.map(membership => (
              <MembershipOption
                membershipsNeeded={membershipsNeeded(
                  membership?.memberCategoriesNeeded,
                )}
                key={membership?.id}
                membership={membership}
                currentUserId={currentUser?.id}
                handleUnsubscribe={handleUnsubscribe}
              />
            ))}
        </div>
      )}
      <div className="flex md:hidden">
        <FontAwesomeIcon
          icon={faChevronLeft}
          className={`${
            scrollPosition === 0
              ? "m-4 h-5 text-gray-500"
              : "m-4 h-5 cursor-pointer text-blue-500"
          }`}
          onClick={handlePrevClick}
        />
        <FontAwesomeIcon
          icon={faChevronRight}
          className={`${
            scrollPosition >= containerWidth - window.innerWidth
              ? "m-4 h-5 text-gray-500"
              : "m-4 h-5 cursor-pointer text-blue-500"
          }`}
          onClick={handleNextClick}
        />
      </div>
    </div>
  );
};
