import { useState } from "react";
import { FormattedMessage } from "react-intl";

import { FacilityWithUtc } from "../../../../../../modules/customer/models/Facility";
import { GroupType } from "../../../../../../modules/player/models/Group";
import { User } from "../../../../../../modules/player/models/User";

import { useToaster } from "../../../../../../hooks/common/useToaster";

import { createGroup } from "../../../../../../modules/player/services/GroupService";

import { Button } from "../../../../../../components/Button";
import { Dialog } from "../../../../../../components/Dialog";
import { TextInput } from "../../../../../../components/TextInput";
import { UserSearch } from "../../../../../../components/UserSearch";
import { UsersList } from "../../../../../../components/UsersList";

interface Props {
  onHide: any;
  setReload: any;
  facilityId: FacilityWithUtc["id"];
}

export const AddGroup = ({ onHide, facilityId, setReload }: Props) => {
  const { toastSuccess } = useToaster();
  const [name, setName] = useState<string>("");
  const [members, setMembers] = useState<User[]>([]);

  const onSave = async () => {
    await createGroup({
      id: "",
      members: members?.map(u => u?.id),
      name,
      type: GroupType.Discount,
      externalServiceId: facilityId,
    });

    setReload(true);
    onHide();
    toastSuccess.discountGroupCreated();
  };

  const onAddUser = (users: User[]) => {
    setMembers(members => [...members, users?.[0]]);
  };

  const onRemoveUser = (userId: string) => {
    setMembers(members => members.filter(member => member.id !== userId));
  };

  return (
    <>
      <Dialog onHide={onHide} visible>
        <h4 className="mb-8">
          <FormattedMessage id="admin.facility.settings.groups.create-new" />
        </h4>

        <FormattedMessage id="common.name" />
        <div className="flex gap-4">
          <TextInput
            name="name"
            type="text"
            value={name}
            onChange={e => setName(e.target.value)}
            autoFocus
          />
          <Button
            onClick={onSave}
            type="primary"
            text="Spara"
            translationName="button.save"
            disabled={!name?.length}
          />
        </div>

        <div className="mt-8">
          <FormattedMessage id="admin.player-overview.add-player" />
          <UserSearch
            facilityId={facilityId}
            title="Sök användare"
            translationId="common.search-user"
            displaySelection={false}
            onChange={onAddUser}
            multiSelect={false}
          />
        </div>

        {members?.length ? (
          <UsersList users={members} onRemove={({ id }) => onRemoveUser(id)} />
        ) : (
          <div className="mt-8 px-12 text-center">
            <FormattedMessage id="pricings.discount-group.add-members" />
          </div>
        )}
      </Dialog>
    </>
  );
};
