import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import { faCopy } from "@fortawesome/pro-regular-svg-icons";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { DateTime } from "luxon";

import {
  GeneralActivities,
  GeneralActivityRequest,
} from "../../../../../../../modules/game/models/GeneralActivities";

import { useToaster } from "../../../../../../../hooks/common/useToaster";
import { useFacility } from "../../../../../../../hooks/swr/useFacility";

import {
  deleteGeneralActivity,
  updateGeneralActivity,
} from "../../../../../../../modules/game/services/GeneralActivities";

import { Button } from "../../../../../../../components/Button";
import { ConfirmationDialog } from "../../../../../../../components/ConfirmationDialog";
import { ImageSelector } from "../../../../../../../components/image/ImageSelector/ImageSelector";
import { ParticipantList } from "./components/ParticipantList";

import { Form } from "../Form";

interface Props {
  activity: GeneralActivities;
  onChange: (image?: string | File) => void;
  onCopy: (activity: GeneralActivities) => void;
}

export const EditForm = ({ activity, onChange, onCopy }: Props) => {
  const history = useHistory();
  const intl = useIntl();
  const [newImage, setNewImage] = useState<string | File>();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const { toastError } = useToaster();
  const isPassedStart = DateTime.now() > activity?.startTime;
  const [isLoading, setIsLoading] = useState(false);

  const [isShareLinkCopied, setIsShareLinkCopied] = useState(false);

  const { facility } = useFacility(activity.facilityId);

  const facilityOffsetToAdd =
    DateTime.local({ zone: facility?.localization?.timeZone }).offset -
    DateTime.now().offset;

  const initialValues: GeneralActivityRequest = {
    id: activity?.id,
    facilityId: activity?.facilityId,
    name: activity?.name,
    description: activity?.description || "",
    gender: activity?.gender,
    startTime: activity?.startTime
      .plus({ minutes: facilityOffsetToAdd })
      .toLocal(),
    endTime: activity?.endTime.plus({ minutes: facilityOffsetToAdd }).toLocal(),
    price: activity?.price.valueInclTax,
    numberOfParticipants: activity?.numberOfParticipants || 0,
    registrationOpenTo: activity?.registrationOpenTo
      .plus({
        minutes: facilityOffsetToAdd,
      })
      .toLocal(),
    courtIdsToPlay: activity?.courtIdsToPlay || [],
    openDoor: activity?.openDoor,
    isPinCodeEnabled: activity?.isPinCodeEnabled,
    pinCode: activity?.pinCode,
    openForRegistration: activity?.openForRegistration,
    hidden: activity?.hidden,
    automaticCancellationAt: activity?.automaticCancellationAt
      ?.plus({
        minutes: facilityOffsetToAdd,
      })
      .toLocal(),
    minNumberOfParticipants: activity?.minNumberOfParticipants ?? 0,
    priority: activity?.priority ?? null,
    coordinators: activity?.coordinators || [],
    discountPrices: activity?.discountPrices.length
      ? activity?.discountPrices.map(discount => ({
          discountAmount: discount.price.valueInclTax,
          membershipIds: discount.membershipIds,
        }))
      : [{ discountAmount: 0, membershipIds: [] }],
    minSkillLevel: activity.minSkillLevel,
    maxSkillLevel: activity.maxSkillLevel,
  };

  const submit = async (data: GeneralActivityRequest, onError: () => void) => {
    const cleanedData = {
      ...data,
      discountPrices: data.discountPrices.filter(
        discount => discount.membershipIds.length > 0,
      ),
    };

    try {
      await updateGeneralActivity(cleanedData);
      onChange(newImage);
    } catch (e) {
      onError?.();
      // I'm sorry for this...
      e.error === "Some of the requested slots are not available"
        ? toastError.message(
            "admin.activities.event.edit.error.time-not-available",
          )
        : toastError.someChangesUpdateFailed();
    }
  };

  const deleteActivity = async (activityId: string) => {
    try {
      await deleteGeneralActivity(activityId);
      setShowDeleteDialog(false);
      history.replace("/admin/activities");
    } catch (e) {
      toastError.deleteActivityFailed();
      setShowDeleteDialog(false);
    }
  };

  const onShareLinkCopy = async () => {
    if (!process.env.REACT_APP_WEBSITE_URL) {
      return;
    }

    const url = new URL(process.env.REACT_APP_WEBSITE_URL);
    url.pathname = `/event/${activity?.id}`;

    window.navigator.clipboard.writeText(url.toString()).then(() => {
      setIsShareLinkCopied(true);

      setTimeout(() => {
        setIsShareLinkCopied(false);
      }, 2000);
    });
  };

  return activity ? (
    <div>
      <Form
        facilityId={activity?.facilityId}
        actionButtons={
          <>
            {process.env.REACT_APP_WEBSITE_URL && (
              <Button
                size="small"
                icon={isShareLinkCopied ? faCheck : faCopy}
                onClick={() => onShareLinkCopy()}
              >
                {isShareLinkCopied ? (
                  <FormattedMessage id="common.link.copied" />
                ) : (
                  <FormattedMessage id="common.copy-share-link" />
                )}
              </Button>
            )}

            {!isPassedStart && (
              <Button
                size="small"
                type="danger"
                onClick={() => {
                  setShowDeleteDialog(true);
                }}
                text={intl.formatMessage({
                  id: "common.remove",
                })}
              />
            )}

            <Button
              size="small"
              type="default"
              onClick={() => {
                onCopy(activity);
              }}
              text={intl.formatMessage({
                id: "admin.activities.event.copy",
              })}
            />
          </>
        }
        hasChange={!!newImage}
        initialValues={initialValues}
        imageSelector={
          <ImageSelector
            className="w-full max-w-[415px] sm:w-2/5"
            imgSrc={activity?.imageUrl}
            onSelect={setNewImage}
            pickerTitle={intl.formatMessage({
              id: "activity.image.selector.title",
            })}
            pickerDescription={intl.formatMessage({
              id: "activity.image.selector.description",
            })}
            selectableImages={[
              "https://court22storageprod.blob.core.windows.net/public-images/activities/cover/Activity_1.jpg",
              "https://court22storageprod.blob.core.windows.net/public-images/activities/cover/Activity_2.jpg",
              "https://court22storageprod.blob.core.windows.net/public-images/activities/cover/Activity_3.jpg",
              "https://court22storageprod.blob.core.windows.net/public-images/activities/cover/Activity_4.jpg",
              "https://court22storageprod.blob.core.windows.net/public-images/activities/cover/Activity_5.jpg",
            ]}
          />
        }
        onSubmit={submit}
      />
      <div className="my-10 border-b border-gray-50" />
      <ParticipantList activity={activity} refresh={onChange} />

      <ConfirmationDialog
        title={intl.formatMessage({
          id: "admin-generalactivities-table.delete-confirmation-message",
        })}
        text={intl.formatMessage(
          {
            id: "generalactivities.remove.are-you-sure",
          },
          { br: <br /> },
        )}
        confirmText={intl.formatMessage({
          id: "common.yes",
        })}
        denyText={intl.formatMessage({
          id: "common.cancel",
        })}
        confirmButtonType="danger"
        visible={showDeleteDialog}
        onHide={() => setShowDeleteDialog(false)}
        onCancel={() => setShowDeleteDialog(false)}
        onSubmit={() => {
          setIsLoading(true);
          deleteActivity(activity.id);
        }}
        loading={isLoading}
      />
    </div>
  ) : null;
};
