import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import {
  faCalendarPen,
  faEllipsis,
  faMoneyBill,
  faMoneyBillTransfer,
  faTrash,
  faXmark,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown } from "@mui/base/Dropdown";
import { MenuButton } from "@mui/base/MenuButton";
import clsx from "clsx";
import { DateTime } from "luxon";
import { Tooltip } from "primereact/tooltip";
import useSWR from "swr";

import type { CompletedOrderByBooking } from "../../../../../../models/completedOrder";
import { BookingType, type IBooking } from "../../../../models/Booking";

import { useToaster } from "../../../../../../hooks/common/useToaster";
import { useIsSuperAdmin } from "../../../../../../hooks/permissions";
import { useBooking } from "../../../../../../hooks/swr/useBooking";
import { useIsFeatureFlagEnabled } from "../../../../../../hooks/swr/useFeatureFlags";
import { useOpenBooking } from "../../../../../../hooks/swr/useOpenBooking";
import { useCurrencyFormat } from "../../../../../../hooks/useCurrencyFormat";
import { useDateFormat } from "../../../../../../hooks/useDateFormat";

import { getCompletedOrdersByBooking } from "../../../../../../services/facilityBookingsService";
import { cancelOpenBooking } from "../../../../../../services/facilityOpenBookingsService";
import { refundOrderPayment } from "../../../../../../services/facilityOrdersService";
import { superAdminFefundOrderPayment } from "../../../../../../services/superAdminOrdersService";
import {
  markAllRecurringBookingsAsPaid,
  unBookPlayerAdmin,
} from "../../../../services/Booking";

import { Button } from "../../../../../../components/Button";
import { Checkbox } from "../../../../../../components/Checkbox";
import { ConfirmationDialog } from "../../../../../../components/ConfirmationDialog";
import { Dialog } from "../../../../../../components/Dialog";
import { Menu, MenuItem } from "../../../../../../components/Menu";
import { ProgressSpinner } from "../../../../../../components/ProgressSpinner";

import { getPaymentMethodText } from "../../../../../../utils/getPaymentMethodText";
import { bookingTypeBorderLeftColor } from "../../utils";
import { useAdminCalendarContext } from "../AdminCalendarContext";
import { AdminCalendarBookingDetails } from "./AdminCalendarBookingDetails";
import { AdminCalendarEventDetails } from "./AdminCalendarEventDetails";
import { AdminCalendarSeriesDetails } from "./AdminCalendarSeriesDetails";

interface Props {
  bookingId: IBooking["id"];
  onSubmitted: (refreshView?: boolean, isEditDialogOpen?: boolean) => void;
}

export const AdminCalendarDetails = ({ bookingId, onSubmitted }: Props) => {
  const { formatMessage } = useIntl();

  const toaster = useToaster();
  const {
    state: { bookingBeingRescheduled },
    dispatch,
  } = useAdminCalendarContext();
  const isSuperAdmin = useIsSuperAdmin();

  const [cancelBookingConfirmation, setCancelBookingConfirmation] = useState<{
    show?: boolean;
    loading?: boolean;
  }>({});
  const [markAllAsPaidConfirmation, setMarkAllAsPaidConfirmation] = useState<{
    show?: boolean;
    loading?: boolean;
  }>({});
  const [showPaymentsModal, setShowPaymentsModal] = useState(false);

  const { booking, isLoading, mutate } = useBooking(
    bookingId,
    "participants,payments,individualprice",
    { refreshInterval: 60_000 },
  );

  // Only here to prime the cache for AdminCalendarBookingDetails.tsx
  const { isLoading: isOpenBookingLoading } = useOpenBooking(
    booking?.type === BookingType.Open ? booking.facilityId : undefined,
    booking?.externalServiceId,
  );

  const isRefundFeatureFlagEnabled = useIsFeatureFlagEnabled(
    "Refund.Booking.Admin",
  );

  if (isLoading || isOpenBookingLoading) {
    return (
      <div key="spinner-wrapper" className="py-px">
        <ProgressSpinner />
      </div>
    );
  }

  if (!booking) {
    return null;
  }

  const isBookingEditable = booking.startTime > DateTime.now();

  const isBookingReschedulable =
    isBookingEditable &&
    [
      BookingType.Regular,
      BookingType.Recurring,
      BookingType.Series,
      BookingType.NotBookable,
    ].includes(booking.type);

  const isBookingCancelable =
    isBookingEditable &&
    [
      BookingType.Regular,
      BookingType.NotBookable,
      BookingType.Recurring,
      BookingType.Open,
    ].includes(booking.type);

  const isPaymentsModalViewable =
    booking.participants.length > 0 &&
    [BookingType.Regular, BookingType.Recurring, BookingType.Open].includes(
      booking.type,
    ) &&
    (isRefundFeatureFlagEnabled || isSuperAdmin);

  const showMenu =
    isBookingReschedulable ||
    isBookingCancelable ||
    booking.type === BookingType.Recurring ||
    isPaymentsModalViewable;

  return (
    <>
      <div
        className={`rounded-lg border border-l-8 border-gray-150 p-8 text-sm ${bookingTypeBorderLeftColor(booking.type)}`}
      >
        <div className="flex h-px items-center justify-end gap-3">
          {showMenu && (
            <Dropdown>
              <MenuButton className="text-2xl">
                <FontAwesomeIcon icon={faEllipsis} />
              </MenuButton>

              <Menu className="z-40 mt-2 rounded-lg border border-gray-200 bg-white p-1.5 shadow-md">
                {isBookingReschedulable && (
                  <MenuItem
                    disabled={!!bookingBeingRescheduled}
                    onClick={() => {
                      dispatch({
                        type: "SET_BOOKING_BEING_RESCHEDULED",
                        payload: booking,
                      });
                      onSubmitted(false, false);
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faCalendarPen}
                      className="mr-2 inline-block"
                    />
                    <FormattedMessage id="common.reschedule" />
                    ...
                  </MenuItem>
                )}

                {isPaymentsModalViewable && (
                  <MenuItem onClick={() => setShowPaymentsModal(true)}>
                    <FontAwesomeIcon
                      icon={faMoneyBillTransfer}
                      className="mr-2 inline-block"
                    />
                    <FormattedMessage id="common.payments" />
                  </MenuItem>
                )}

                {booking.type === BookingType.Recurring && (
                  <MenuItem
                    onClick={() => setMarkAllAsPaidConfirmation({ show: true })}
                  >
                    <FontAwesomeIcon
                      icon={faMoneyBill}
                      className="mr-2 inline-block"
                    />
                    <FormattedMessage id="common.mark-all-as-paid" />
                    ...
                  </MenuItem>
                )}

                {isBookingCancelable && (
                  <MenuItem
                    className="text-red-600"
                    onClick={() => setCancelBookingConfirmation({ show: true })}
                  >
                    <FontAwesomeIcon
                      icon={faTrash}
                      className="mr-2 inline-block"
                    />
                    <FormattedMessage id="common.unbook" />
                    ...
                  </MenuItem>
                )}
              </Menu>
            </Dropdown>
          )}

          <button onClick={() => onSubmitted(false, false)}>
            <FontAwesomeIcon icon={faXmark} className="text-md" />
          </button>
        </div>

        {[
          BookingType.Regular,
          BookingType.NotBookable,
          BookingType.Recurring,
          BookingType.Open,
        ].includes(booking.type) ? (
          <AdminCalendarBookingDetails
            booking={booking}
            onSubmitted={onSubmitted}
            mutateBooking={mutate}
          />
        ) : booking.type === BookingType.Series ? (
          <AdminCalendarSeriesDetails
            booking={booking}
            onSubmitted={onSubmitted}
            mutateBooking={mutate}
          />
        ) : booking.type === BookingType.Event ? (
          <AdminCalendarEventDetails booking={booking} />
        ) : null}

        <Tooltip target=".admin-calendar-details" />
      </div>

      {cancelBookingConfirmation.show && (
        <ConfirmationDialog
          visible
          title={formatMessage({
            id: "common.cancel-the-booking?",
          })}
          loading={cancelBookingConfirmation.loading}
          confirmButtonType="danger"
          onHide={() => setCancelBookingConfirmation({})}
          onCancel={() => setCancelBookingConfirmation({})}
          onSubmit={async () => {
            setCancelBookingConfirmation(v => ({ ...v, loading: true }));

            try {
              if (
                booking.type === BookingType.Open &&
                booking.externalServiceId
              ) {
                await cancelOpenBooking(
                  booking.facilityId,
                  booking.externalServiceId,
                );
              } else {
                await unBookPlayerAdmin(bookingId, {
                  alwaysRefund: true,
                  userIds: [],
                  refundAll: false,
                });
              }

              toaster.toastSuccess.message("toast.success.cancel-done");
              mutate();
              onSubmitted(true, false);
            } catch {
              toaster.toastError.unknown();
              setCancelBookingConfirmation(v => ({ ...v, loading: false }));
            }
          }}
        />
      )}

      {markAllAsPaidConfirmation.show && (
        <ConfirmationDialog
          visible
          confirmButtonType="danger"
          loading={markAllAsPaidConfirmation.loading}
          title={formatMessage({
            id: "admin-edit-booking-form.mark-all-recurring-bookings-as-paid.confirmation.title",
          })}
          onCancel={() => setMarkAllAsPaidConfirmation({})}
          onHide={() => setMarkAllAsPaidConfirmation({})}
          onSubmit={async () => {
            setMarkAllAsPaidConfirmation(v => ({ ...v, loading: true }));

            try {
              await markAllRecurringBookingsAsPaid(booking.id);
              setTimeout(() => {
                // setTimeout? Really? yes. Backend replies when payment is done.
                // We need to wait for series status updates, and since backend dont wait we do.

                toaster.toastSuccess.message(
                  "admin-edit-booking-form.all-recurring-bookings-marked-as-paid.toast.success",
                );
                mutate();
                setMarkAllAsPaidConfirmation({});
                onSubmitted(true, true);
              }, 3000);
            } catch {
              toaster.toastError.unknown();
              setMarkAllAsPaidConfirmation(v => ({ ...v, loading: false }));
            }
          }}
        />
      )}

      {showPaymentsModal && (
        <Dialog
          onHide={() => setShowPaymentsModal(false)}
          visible
          className="!max-w-4xl"
        >
          <Payments booking={booking} />
        </Dialog>
      )}
    </>
  );
};

const Payments = ({ booking }: { booking: IBooking }) => {
  const { formatMessage } = useIntl();
  const toaster = useToaster();
  const isSuperAdmin = useIsSuperAdmin();

  const { data, isLoading, mutate } = useSWR(
    ["payments", booking.facilityId, booking.id],
    ([, facilityId, bookingId]) =>
      getCompletedOrdersByBooking(facilityId, bookingId),
  );

  const { df } = useDateFormat(booking.facilityId);
  const { cf } = useCurrencyFormat(booking.facility.localization?.currencyCode);
  const { cf: cfCoins } = useCurrencyFormat("Coins");

  const [refundConfirmation, setRefundConfirmation] = useState<{
    show?: boolean;
    loading?: boolean;
    orderId?: CompletedOrderByBooking["orderId"];
    paymentId?: CompletedOrderByBooking["payments"][number]["id"];
  }>({});

  const [refundFee, setRefundFee] = useState(false);

  useEffect(() => {
    setRefundFee(false);
  }, [refundConfirmation.show]);

  if (isLoading || !data) {
    return <ProgressSpinner className="m-10" />;
  }

  return (
    <>
      <h3>
        <FormattedMessage id="common.payments" />
      </h3>

      <div className="mt-8 divide-y *:py-4 first:*:pt-0 last:*:pb-0">
        {booking.participants.map(participant => {
          const completedOrder = data.find(
            completedOrder => completedOrder.userId === participant.id,
          );

          return (
            <div key={participant.id}>
              <h4 className={clsx(!isSuperAdmin && "mb-2")}>
                {participant.displayName}
              </h4>

              {isSuperAdmin && completedOrder && (
                <p className="mb-2">Order ID: {completedOrder?.orderId}</p>
              )}

              {!completedOrder || completedOrder?.payments.length === 0 ? (
                <FormattedMessage id="common.no-payments" />
              ) : (
                <table className="w-full table-fixed text-base">
                  <thead className="font-semibold">
                    <tr className="table-row *:px-2 first:*:pl-0 last:*:pr-0">
                      <td>
                        <FormattedMessage id="common.status" />
                      </td>
                      <td>
                        <FormattedMessage id="common.date-and-time" />
                      </td>
                      <td>
                        <FormattedMessage id="series.paid" />
                      </td>
                      <td>
                        <FormattedMessage id="receipt.refund" />
                      </td>
                      <td></td>
                    </tr>
                  </thead>

                  <tbody>
                    {completedOrder.payments.map(payment => {
                      const isRefundable = !(
                        (payment.status === "Refunded" && !isSuperAdmin) ||
                        (!!payment.refundedAmount &&
                          payment.paidAmount.valueInclTax <=
                            payment.refundedAmount?.valueInclTax)
                      );
                      return (
                        <tr
                          key={payment.id}
                          className="table-row *:px-2 *:py-1 first:*:pl-0 last:*:pr-0"
                        >
                          <td>
                            {payment.status === "Paid" ? (
                              <FormattedMessage id="receipts.translation.FullyCompleted.statuses" />
                            ) : payment.status === "Refunded" ? (
                              <FormattedMessage id="receipts.translation.Refunded.statuses" />
                            ) : payment.status === "RemainderDebtCollected" ? (
                              <FormattedMessage id="common.remaining-cost-paid" />
                            ) : null}
                          </td>
                          <td>{df(payment.created, DateTime.DATETIME_MED)}</td>
                          <td>
                            {payment.paymentProvider === "Coins"
                              ? `${cfCoins(payment.paidAmount.valueInclTax)}`
                              : cf(payment.paidAmount.valueInclTax)}{" "}
                            {getPaymentMethodText({
                              provider: payment.paymentProvider,
                              instrument: payment.paymentInstrument,
                            })}
                          </td>
                          {payment.refundedAmount ? (
                            <td className="text-nowrap">
                              {payment.paymentProvider === "Coins"
                                ? `${cfCoins(payment.refundedAmount.valueInclTax)} Coins`
                                : cf(payment.refundedAmount.valueInclTax)}
                              , {df(payment.updated, DateTime.DATETIME_MED)}
                            </td>
                          ) : (
                            <td></td>
                          )}
                          <td className="flex justify-end">
                            <Button
                              disabled={!isRefundable}
                              type="primary"
                              size="small"
                              translationName={
                                isRefundable
                                  ? "common.refund.verb"
                                  : "receipts.translation.Refunded.statuses"
                              }
                              onClick={() => {
                                setRefundConfirmation({
                                  show: true,
                                  orderId: completedOrder.orderId,
                                  paymentId: payment.id,
                                });
                              }}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </div>
          );
        })}
      </div>

      {refundConfirmation.show && (
        <ConfirmationDialog
          visible
          confirmButtonType="danger"
          loading={refundConfirmation.loading}
          title={formatMessage({
            id: "common.refund.verb",
          })}
          onHide={() => setRefundConfirmation({ show: false })}
          onCancel={() => setRefundConfirmation({ show: false })}
          onSubmit={async () => {
            setRefundConfirmation(v => ({ ...v, loading: true }));

            if (!refundConfirmation.orderId || !refundConfirmation.paymentId) {
              toaster.toastError.unknown();
              setRefundConfirmation(v => ({ ...v, loading: false }));
              return;
            }

            try {
              if (isSuperAdmin) {
                await superAdminFefundOrderPayment(
                  refundConfirmation.orderId,
                  refundConfirmation.paymentId,
                  refundFee,
                );
              } else {
                await refundOrderPayment(
                  booking.facilityId,
                  refundConfirmation.orderId,
                  refundConfirmation.paymentId,
                );
              }

              toaster.toastSuccess.message("receipt.refund");

              setRefundConfirmation({});
              mutate();
            } catch {
              toaster.toastError.unknown();

              setRefundConfirmation(v => ({ ...v, loading: false }));
            }
          }}
        >
          {isSuperAdmin && (
            <div className="flex justify-center">
              <Checkbox
                label={formatMessage({ id: "common.including-fee" })}
                checked={refundFee}
                onChange={e => setRefundFee(e.target.checked)}
              />
            </div>
          )}
        </ConfirmationDialog>
      )}
    </>
  );
};
