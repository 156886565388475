import { DateTime } from "luxon";

export const dateTimeToUtcIsoSanitizedToMinutes = (
  dateTime: DateTime,
): string => dateTime.startOf("minute").toUTC().toISO();

export const dateTimeToUtcIsoSanitizedToDay = (dateTime: DateTime): string =>
  dateTime.startOf("day").toUTC().toISO();

export const forceDateTimeToTimeZone = (
  dateTime: DateTime,
  timeZone: string,
): DateTime => dateTime.setZone(timeZone, { keepLocalTime: true });
