import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import clsx from "clsx";
import { InputSwitch } from "primereact/inputswitch";

import { User } from "../modules/player/models/User";

import { ConfirmationDialog } from "./ConfirmationDialog";

interface Props {
  user: User;
  markedAsPaid?: boolean;
  showEmailToggle?: boolean;
  onMarkAsPaid?: (u: User, sendEmail?: boolean) => void;
}

export const PaymentStatus: React.FC<Props> = ({
  user,
  markedAsPaid,
  showEmailToggle,
  onMarkAsPaid,
}) => {
  const intl = useIntl();
  const [dialogvisible, setDialogVisible] = useState<boolean>();
  const [sendEmail, setSendEmail] = useState<boolean>(true);
  const isPaid = user?.isPayed || markedAsPaid;

  const containerClasses = clsx("group flex items-center", {
    "cursor-pointer": !isPaid && !!onMarkAsPaid,
  });

  const dotClasses = clsx(
    "block h-[10px] w-[10px] shrink-0 rounded-full bg-green-700",
    {
      "bg-red-600": !isPaid,
      "group-hover:bg-green-700": !isPaid && !!onMarkAsPaid,
    },
  );

  useEffect(() => {
    if (!dialogvisible) {
      setSendEmail(true);
    }
  }, [dialogvisible]);

  return (
    <div className={containerClasses} onClick={e => e.stopPropagation()}>
      <div
        className="flex items-center gap-2"
        onClick={() => {
          if (!onMarkAsPaid) return;
          if (isPaid) return;

          setDialogVisible(true);
        }}
      >
        <div className={dotClasses}></div>
        <p>
          <FormattedMessage
            id={
              markedAsPaid
                ? "admin.marked-as-paid"
                : isPaid
                  ? "common.paid"
                  : "common.unpaid"
            }
          />
        </p>
      </div>
      {dialogvisible && (
        <ConfirmationDialog
          onCancel={() => {
            setDialogVisible(false);
          }}
          text={intl.formatMessage(
            { id: "admin.markAsPaid.confirm.description" },
            {
              Bold: chunk => <span className="font-bold">{chunk}</span>,
              name: user?.displayName,
            },
          )}
          title={intl.formatMessage({ id: "admin.markAsPaid.confirm.title" })}
          visible
          onSubmit={() => {
            onMarkAsPaid?.(user, sendEmail);
            setDialogVisible(false);
          }}
          onHide={() => {
            setDialogVisible(false);
          }}
        >
          {showEmailToggle && (
            <div className="mt-5 flex items-center justify-end gap-5 text-sm font-bold text-gray-700">
              <label htmlFor="sendEmail">
                <FormattedMessage id="admin.booking.send-confirmation-email" />
              </label>
              <InputSwitch
                inputId="sendEmail"
                checked={sendEmail}
                onChange={() => setSendEmail(!sendEmail)}
              />
            </div>
          )}
        </ConfirmationDialog>
      )}
    </div>
  );
};
