import { DateTime } from "luxon";

import { ApiSingleResponse } from "../../../models/common";
import { AdminStatisticsWithUtc } from "../../admin/models/Statistics";
import { AdminStatistics } from "../../checkout/models/api/Statistics";
import { AdminStatisticsDataRequestWithUtc } from "../models/AdminStatistics";

import { fetchApi } from "../../../services/legacyApiClient";

const apiVersion = "customer/v2";

export const getEarningsAndOccupancy = async (
  data: AdminStatisticsDataRequestWithUtc,
  signal?: AbortSignal,
): Promise<ApiSingleResponse<AdminStatisticsWithUtc>> => {
  try {
    const payload = {
      ...data,
      startDate: data.startDate.toISO(),
      endDate: data.endDate.toISO(),
    };

    const response = (await fetchApi({
      method: "POST",
      uri: `${apiVersion}/statistics/earningsandoccupancy`,
      payload,
      signal,
    })) as ApiSingleResponse<AdminStatistics>;

    return {
      ...response,
      data: {
        ...response.data,
        startDate: DateTime.fromISO(response.data.startDate, { setZone: true }),
        endDate: DateTime.fromISO(response.data.endDate, { setZone: true }),
      },
    };
  } catch (error) {
    console.log(error);
  }
};
