import { FormattedMessage } from "react-intl";
import { Link, useHistory } from "react-router-dom";

import { faCircleLeft } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  to?: string;
  onClick?: (e?: any) => void;
}

const BackButton = ({ to, onClick }: Props) => {
  const history = useHistory();

  const goToPath = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();

    if (onClick) {
      onClick();
      return;
    }

    if (to) {
      e.metaKey ? window.open(to) : history.push(to);
    } else {
      history.goBack();
    }
  };

  return (
    <Link
      to=""
      onClick={e => goToPath(e)}
      className="flex items-center font-medium no-underline"
    >
      <FontAwesomeIcon icon={faCircleLeft} />
      <span className="pl-2">
        <FormattedMessage id="common.back" />
      </span>
    </Link>
  );
};

export default BackButton;
