import { useParams } from "react-router-dom";

import { UserRescheduleCalendar } from "../../../modules/checkout/components/Calendar/UserRescheduleCalendar";

interface RescheduleParams {
  bookingId: string;
  facilityId: string;
}

export const AdminMobileReschedulePage: React.FC = () => {
  const { facilityId, bookingId } = useParams<RescheduleParams>();

  return (
    <div className="space-y-2 bg-purewhite p-2">
      <UserRescheduleCalendar
        noHeader={true}
        facilityId={facilityId}
        bookingId={bookingId}
      />
    </div>
  );
};
