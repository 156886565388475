import React, { useEffect, useState } from "react";

import clsx from "clsx";
import { DateTime } from "luxon";

import { FacilityWithUtc } from "../../../../../customer/models/Facility";
import { IScheduleSlot } from "../../../../models/Calendar";

import { useDateFormat } from "../../../../../../hooks/useDateFormat";

import { luxonTimeFormat } from "../../../../../../utils/dateFormats";
import { isSlotLateToBook } from "../../utils";

interface IProps {
  facilityId: FacilityWithUtc["id"];
  row: number;
  column: number;
  width: number;
  isBookable: boolean;
  isLateToBook: boolean;
  addSelectedClass: boolean;
  isSelected: boolean;
  startTime: DateTime;
  endTime: DateTime;
  slotsToPick: IScheduleSlot[];
  onBeginSelection: (row: number, index: number, noDrag?: boolean) => void;
  onEndSelection: () => void;
  onUpdateSelection: (
    event: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>,
    row: number,
    index: number,
  ) => void;
  onPickedSlot?: (slot: IScheduleSlot) => void;
}

const CustomerCalendarSlot: React.FC<IProps> = ({
  facilityId,
  row,
  column,
  width,
  isBookable,
  isLateToBook,
  addSelectedClass,
  isSelected,
  startTime,
  endTime,
  slotsToPick,
  onBeginSelection,
  onEndSelection,
  onUpdateSelection,
  onPickedSlot,
}) => {
  const [picked, setPicked] = useState<IScheduleSlot>();
  const handleTouchMove = (event: React.TouchEvent<HTMLDivElement>) => {
    const targetEl = document.elementFromPoint(
      event.touches[0].clientX,
      event.touches[0].clientY,
    );

    onUpdateSelection(
      event,
      parseInt(targetEl.getAttribute("data-row")),
      parseInt(targetEl.getAttribute("data-column")),
    );
  };
  const { df } = useDateFormat(facilityId);

  useEffect(() => {
    setPicked(null);
  }, [isSelected]);

  const showSlotPicker = Array.isArray(slotsToPick);

  return (
    <div
      className={`time relative ${
        isLateToBook || !isBookable ? "cursor-not-allowed" : ""
      }`}
      data-row={row}
      data-column={column}
      onMouseDown={() => {
        if (isLateToBook || !isBookable) {
          return;
        }

        onBeginSelection(row, column);
      }}
      onMouseUp={() => onEndSelection()}
      onMouseEnter={e => {
        if (isLateToBook || !isBookable) {
          return;
        }

        if (e.button !== 0) {
          return;
        }

        onUpdateSelection(e, row, column);
      }}
      onTouchStart={() => {
        if (isLateToBook || !isBookable) {
          return;
        }

        onBeginSelection(row, column);
      }}
      onTouchEnd={() => onEndSelection()}
      onTouchMove={e => handleTouchMove(e)}
    >
      {!isBookable && <div className="booking-time w-full bg-gray-700" />}
      {isLateToBook && <div className="disabled-time" />}
      {addSelectedClass && <div className="selected-time" />}
      {isSelected && isBookable && (
        <div
          className="selected-time"
          style={{
            width: `calc(100% * ${width})`,
          }}
        >
          {startTime && <p>{df(startTime, luxonTimeFormat)}</p>}
          {endTime && <p>{df(endTime, luxonTimeFormat)}</p>}
        </div>
      )}
      {isSelected && showSlotPicker && isBookable && (
        <div className="absolute -left-[80px] -top-[54px] z-10 flex divide-x overflow-hidden rounded-md bg-purewhite shadow-md">
          <div className="absolute left-[90px] top-[55px] h-0 w-0 rotate-180 border-x-4 border-b-[6px] border-purewhite border-x-transparent"></div>
          {slotsToPick?.map(s => (
            <div
              key={s.startTime.toISO()}
              className={clsx("p-4", {
                "bg-primary text-white": picked?.startTime === s.startTime,
                "hover:bg-blue-50": picked?.startTime !== s.startTime,
                "text-primary": !s.isBooked && !isSlotLateToBook(s),
                "cursor-default text-gray-300 hover:bg-transparent":
                  s.isBooked || isSlotLateToBook(s),
              })}
              onClick={e => {
                e.preventDefault();
                if (!s.isBooked && !isSlotLateToBook(s)) {
                  setPicked(s);
                  onPickedSlot(s);
                }
              }}
            >
              {df(s.startTime, luxonTimeFormat)}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomerCalendarSlot;
