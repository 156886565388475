import React, { useEffect, useMemo, useState } from "react";

import { CoinWallet } from "../../../../modules/player/models/Coins";
import { FacilityUserRelation } from "../../../../modules/player/models/User";

import { useCurrentUser } from "../../../../hooks/swr/useCurrentUser";

import { getUserCoins } from "../../../../modules/player/services/CoinsService";

import { FacilityItem, MyFacilityObject } from "./components/FacilityItem";

export const UserFacilities: React.FC = () => {
  const [wallets, setWallets] = useState<CoinWallet[]>([]);
  const { currentUser } = useCurrentUser();

  const joinObjects = (
    arr1: FacilityUserRelation[],
    arr2: CoinWallet[],
  ): MyFacilityObject[] => {
    const merged = [];

    for (const item of arr1) {
      merged.push({
        isFavorite: item.facilityId === currentUser?.favoriteFacilityId,
        ...item,
        ...arr2.find(itmInner => itmInner.facilityId === item.facilityId),
      });
    }

    return merged;
  };

  const items = useMemo<MyFacilityObject[]>(() => {
    return joinObjects(currentUser?.facilityUserRelations, wallets || []);
  }, [wallets, currentUser]);

  useEffect(() => {
    const abortController = new AbortController();
    const fetchCoins = async () => {
      try {
        const wallets = await getUserCoins(abortController.signal);
        if (abortController.signal.aborted) return;
        setWallets(wallets);
      } catch (e) {
        console.log(e);
      }
    };

    fetchCoins();

    return () => abortController.abort();
  }, []);

  return (
    <div className="flex flex-wrap gap-8">
      {items?.map(item => <FacilityItem item={item} key={item.facilityId} />)}
    </div>
  );
};
