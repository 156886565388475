import { useEffect, useMemo, useState } from "react";

import type { PendingSwedbankPayBackupPaymentMethod } from "../../../../../../../models/backupPaymentMethod";
import type { SwedbankPayCheckoutConfiguration } from "../../../../../../../models/swedbankPayCheckout";
import { SWEDBANK_PAY_PAYMENT_MENU_PATH } from "../../../../../../../models/swedbankPayCheckout";

import { useToaster } from "../../../../../../../hooks/common/useToaster";

import {
  completePendingSwedbankPayBackupPaymentMethod,
  createPendingSwedbankPayBackupPaymentMethod,
} from "../../../../../../../services/mySettingsService";

import { Dialog } from "../../../../../../../components/Dialog";
import { ProgressSpinner } from "../../../../../../../components/ProgressSpinner";
import { SwedbankPayCheckout } from "../../../../../../../components/SwedbankPayCheckout";

export const BackupPaymentMethodDialog = ({
  onComplete,
  onHide,
}: {
  onComplete: () => void;
  onHide?: () => void;
}) => {
  const { toastError } = useToaster();
  const [
    pendingSwedbankPayBackupPaymentMethod,
    setPendingSwedbankPayBackupPaymentMethod,
  ] = useState<PendingSwedbankPayBackupPaymentMethod>();

  useEffect(() => {
    const fetch = async () => {
      try {
        const response = await createPendingSwedbankPayBackupPaymentMethod(
          window.location.origin + SWEDBANK_PAY_PAYMENT_MENU_PATH,
        );

        setPendingSwedbankPayBackupPaymentMethod(response);
      } catch (error) {
        toastError.generalFailure();
      }
    };

    fetch();
  }, [toastError]);

  const swedbankPayCheckoutConfiguration: Partial<SwedbankPayCheckoutConfiguration> =
    useMemo(
      () => ({
        onPaid: async e => {
          try {
            await completePendingSwedbankPayBackupPaymentMethod(
              e.paymentOrder.id,
            );

            onComplete();
          } catch (error) {
            toastError.generalFailure();
          } finally {
            onHide?.();
          }
        },
      }),
      [onComplete, onHide, toastError],
    );

  return (
    <Dialog visible onHide={() => onHide?.()}>
      {pendingSwedbankPayBackupPaymentMethod ? (
        <SwedbankPayCheckout
          scriptUrl={pendingSwedbankPayBackupPaymentMethod.scriptUrl}
          configuration={swedbankPayCheckoutConfiguration}
        />
      ) : (
        <ProgressSpinner />
      )}
    </Dialog>
  );
};
