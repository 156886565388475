import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";

import { GenderType } from "../../../../../../../../models/common";

interface Props {
  gender: GenderType;
  selected: boolean;
  disabled: boolean;
  onChange: (g: GenderType) => void;
}

export const GenderOption: React.FC<Props> = ({
  gender,
  selected,
  disabled,
  onChange,
}) => {
  const getGenderIconName = (gender: GenderType) => {
    switch (gender) {
      case GenderType.Female:
        return "venus";
      case GenderType.Male:
        return "mars";
      case GenderType.Mix:
        return "venus-mars";
      default:
        return "venus-mars";
    }
  };

  const genderOptionClasses = clsx(
    "position-relative grid h-14 w-16 cursor-pointer content-center justify-center rounded border",
    {
      "text-blue-500": !disabled,
      "cursor-auto": disabled,
      "border-gray-500 text-gray-500": !selected && disabled,
      "border-2 border-gray-700": selected && disabled,
      "border-2 border-blue-500": selected && !disabled,
    },
  );

  return (
    <button
      type="button"
      className={genderOptionClasses}
      onClick={() => onChange(gender)}
      disabled={disabled}
    >
      <FontAwesomeIcon
        icon={["fal", getGenderIconName(gender)]}
        fontSize="1.5rem"
      />
    </button>
  );
};
