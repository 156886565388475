import { useToaster } from "../../../../../../hooks/common/useToaster";

import { sendMessageToDivisionPlayers } from "../../../../../../modules/game/services/Serie";

import { MessageEditor } from "../../../../../../components/MessageEditor";

interface Props {
  seriesId: string;
  divisionId: string;
  onComplete: () => void;
}

export const DivisionMessageEditor = ({
  seriesId,
  divisionId,
  onComplete,
}: Props) => {
  const { toastError, toastSuccess } = useToaster();
  const handleSubmit = async (data: { subject: string; content: string }) => {
    try {
      await sendMessageToDivisionPlayers(seriesId, {
        divisionId,
        ...data,
      });
      toastSuccess.message("toast.success.division.email.sent");
    } catch (error) {
      console.error(error);
      toastError.message("toast.error.update.generic");
    } finally {
      onComplete();
    }
  };

  return <MessageEditor onSubmit={handleSubmit} />;
};
