import { useState } from "react";
import { FormattedMessage } from "react-intl";

import { useToaster } from "../../../../../../hooks/common/useToaster";
import { useCurrentUserBackupPaymentMethod } from "../../../../../../hooks/swr/useCurrentUserBackupPaymentMethod";
import { useSavedPaymentMethods } from "../../../../../../hooks/swr/useSavedPaymentMethods";

import {
  deleteBackupPaymentMethod,
  deleteSavedPaymentMethod,
} from "../../../../../../services/mySettingsService";

import { CardDetails } from "../../../../../../components/CardDetails";
import { CreditCardCard } from "../../../../../../components/CreditCardCard";
import { BackupPaymentMethodDialog } from "./components/BackupPaymentMethodDialog";

export const PaymentMethods = () => {
  return (
    <>
      <SavedPaymentMethods />
      <BackupPaymentMethods />

      <div className="mt-10">
        <p className="mb-2">
          <strong>
            <FormattedMessage id="card-details.user.heading" />
          </strong>
        </p>
        <CardDetails />
      </div>
    </>
  );
};

const SavedPaymentMethods = () => {
  const { toastError } = useToaster();

  const { savedPaymentMethods, mutate, isLoading, error } =
    useSavedPaymentMethods({
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    });

  return (
    <>
      <p>
        <strong>
          <FormattedMessage id="common.saved-cards" />
        </strong>
      </p>
      <p className="mt-2 max-w-prose">
        <FormattedMessage id="profile-settings.payment-methods.saved-cards.description" />
      </p>

      <div className="mt-6 space-y-2">
        {!isLoading && error && (
          <p className="text-error">
            <FormattedMessage id="toast.error.update.generic" />
          </p>
        )}

        {!isLoading && savedPaymentMethods?.length === 0 && (
          <p>
            <FormattedMessage id="profile-settings.payment-methods.saved-cards.no-saved-cards-message" />
          </p>
        )}

        {savedPaymentMethods?.map(savedPaymentMethod => (
          <CreditCardCard
            key={savedPaymentMethod.id}
            cardBrand={savedPaymentMethod.cardBrand}
            pan={savedPaymentMethod.maskedPan}
            expiryDate={savedPaymentMethod.expiryDate}
            removeable
            onRemove={() => {
              mutate(
                async () => {
                  try {
                    await deleteSavedPaymentMethod(savedPaymentMethod.id);

                    return savedPaymentMethods.filter(
                      paymentMethod =>
                        paymentMethod.id !== savedPaymentMethod.id,
                    );
                  } catch (e) {
                    console.error(e);
                    toastError.generalFailure();
                    throw e;
                  }
                },
                {
                  revalidate: false,
                  throwOnError: false,
                },
              );
            }}
          />
        ))}
      </div>
    </>
  );
};

const BackupPaymentMethods = () => {
  const { toastError } = useToaster();

  const { backupPaymentMethod, isLoading, error, mutate } =
    useCurrentUserBackupPaymentMethod({
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    });
  const { mutate: mutateSavedPaymentMethods } = useSavedPaymentMethods({
    isPaused: () => true,
  });

  const [
    showAddBackupPaymentMethodDialog,
    setShowAddBackupPaymentMethodDialog,
  ] = useState(false);

  return (
    <>
      <p className="mt-10">
        <strong>
          <FormattedMessage id="common.backup-card" />
        </strong>
      </p>
      <p className="mt-2 max-w-prose">
        <FormattedMessage id="profile-settings.payment-methods.backup-card.description" />
      </p>

      <div className="mt-6 flex flex-col gap-1">
        {backupPaymentMethod && (
          <CreditCardCard
            cardBrand={backupPaymentMethod.cardBrand}
            pan={backupPaymentMethod.maskedPan}
            expiryDate={backupPaymentMethod.expiryDate}
            removeable
            onRemove={() => {
              mutate(
                async () => {
                  try {
                    await deleteBackupPaymentMethod();
                    return null;
                  } catch (e) {
                    console.error(e);
                    toastError.generalFailure();
                    throw e;
                  }
                },
                {
                  revalidate: false,
                  throwOnError: false,
                },
              );
            }}
          />
        )}

        {!isLoading && error && (
          <p className="text-error">
            <FormattedMessage id="toast.error.update.generic" />
          </p>
        )}

        {!isLoading && !error && (
          <button
            type="button"
            className="self-baseline text-primary underline transition-colors hover:text-blue-700"
            onClick={() => setShowAddBackupPaymentMethodDialog(true)}
          >
            {backupPaymentMethod ? (
              <FormattedMessage id="common.replace-card" />
            ) : (
              <FormattedMessage id="common.add-card" />
            )}
          </button>
        )}
      </div>

      {showAddBackupPaymentMethodDialog && (
        <BackupPaymentMethodDialog
          onComplete={() => {
            mutate();
            mutateSavedPaymentMethods();
            setShowAddBackupPaymentMethodDialog(false);
          }}
          onHide={() => setShowAddBackupPaymentMethodDialog(false)}
        />
      )}
    </>
  );
};
