import { useEffect, useMemo, useRef, useState } from "react";

import _ from "lodash";

import { DateOnly } from "../../../models/DateOnly";
import { DefaultVendorAccess } from "../models/Access";

import { useToaster } from "../../../hooks/common/useToaster";

import {
  getDefaultVendorAccessTimes,
  saveDefaultVendorAccessDetails,
} from "../services/AccessService";

import { Button } from "../../../components/Button";

import { DefaultFacilityAccessTimeDetailsRow } from "./DefaultFacilityAccessTimeDetailRow";
import { FacilitySubmitResetButtons } from "./FacilitySubmitResetButtons";

interface Props {
  facilityId: string;
}
export const DefaultFacilityAccessTimeDetails = ({ facilityId }: Props) => {
  const { toastSuccess } = useToaster();
  const accessAbortController = useRef<AbortController>();
  const [loading, setLoading] = useState<boolean>(null);
  const [showErrors, setShowErrors] = useState<boolean>(false);
  const [defaultVendorAccessTimes, setDefaultVendorAccessTimes] = useState<
    DefaultVendorAccess[]
  >([]);
  const [tempDefaultVendorAccessTimes, setTempDefaultVendorAccessTimes] =
    useState<DefaultVendorAccess[]>([]);
  const [itemIdsWithInvalidDuration, setItemIdsWithInvalidDuration] = useState<
    string[]
  >([]);

  useEffect(() => {
    const fetchDefaultVendorAccessTimes = async () => {
      try {
        const result = await getDefaultVendorAccessTimes(facilityId);
        sortAccessTimes(result?.data);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.log("error", e);
      }
    };

    if (facilityId) {
      setLoading(true);
      fetchDefaultVendorAccessTimes();
    }

    return () => accessAbortController.current?.abort();
  }, []);

  const areVendorAccessTimeInputsInvalid = useMemo(() => {
    return (
      defaultVendorAccessTimes?.some(
        item => (!item.pinCode || item.pinCode.length) !== 4 || !item.validFrom,
      ) === true
    );
  }, [defaultVendorAccessTimes]);

  useEffect(() => {
    defaultVendorAccessTimes.length && validateDateRanges();
  }, [defaultVendorAccessTimes]);

  const validateDateRanges = () => {
    const newErrorIdsWithInvalidDuration: string[] = [];

    defaultVendorAccessTimes.forEach((item, index) => {
      const currentItemStart = item.validFrom;
      const currentItemEnd = item.validTo ? item.validTo : null;

      for (let i = 0; i < defaultVendorAccessTimes.length; i++) {
        if (i !== index) {
          const otherItem = defaultVendorAccessTimes[i];
          const otherItemStart = otherItem.validFrom;
          const otherItemEnd = otherItem.validTo ? otherItem.validTo : null;

          if (
            (currentItemEnd === null && otherItemEnd === null) ||
            (currentItemEnd === null && currentItemStart <= otherItemStart) ||
            (otherItemEnd === null && currentItemStart >= otherItemStart) ||
            (currentItemStart >= otherItemStart &&
              currentItemStart <= otherItemEnd) ||
            (currentItemEnd >= otherItemStart && currentItemEnd <= otherItemEnd)
          ) {
            newErrorIdsWithInvalidDuration.push(item.id, otherItem.id);
          }
        }
      }
    });

    setItemIdsWithInvalidDuration(newErrorIdsWithInvalidDuration);
  };

  const onDeleteRow = (id: string) => {
    setItemIdsWithInvalidDuration([]);
    const filteredAccessTimes = defaultVendorAccessTimes.filter(
      item => item.id !== id,
    );
    setDefaultVendorAccessTimes(filteredAccessTimes);
    setShowErrors(false);
  };

  const updateDefaultAccessTimes = (
    id: string,
    updateFunction: (item: DefaultVendorAccess) => void,
  ) => {
    const tempDefaultAccessTimes: DefaultVendorAccess[] = [
      ...defaultVendorAccessTimes,
    ];
    const targetIndex = tempDefaultAccessTimes.findIndex(
      item => item.id === id,
    );

    if (targetIndex !== -1) {
      updateFunction(tempDefaultAccessTimes[targetIndex]);
      setDefaultVendorAccessTimes(tempDefaultAccessTimes);
    }
  };

  const onChangePinCode = (id: string, pinCode: string) => {
    updateDefaultAccessTimes(id, item => {
      item.pinCode = pinCode;
    });
  };

  const onChangeValidFrom = (id: string, validFrom: DateOnly) => {
    updateDefaultAccessTimes(id, item => {
      item.validFrom = validFrom;
    });
  };

  const onChangeValidTo = (id: string, validTo: DateOnly | null) => {
    updateDefaultAccessTimes(id, item => {
      item.validTo = validTo ? validTo : null;
    });
  };

  const validateAndSubmit = () => {
    validateDateRanges();
    if (areVendorAccessTimeInputsInvalid || itemIdsWithInvalidDuration.length) {
      setShowErrors(true);
      return Promise.reject();
    } else {
      setShowErrors(false);
      return onSubmit();
    }
  };

  const onSubmit = async () => {
    try {
      const result = await saveDefaultVendorAccessDetails(
        facilityId,
        defaultVendorAccessTimes,
      );
      sortAccessTimes(result?.data);
      toastSuccess.saveAccessTimesSuccess();
    } catch (e) {
      console.log("error", e);
    }
  };

  const sortAccessTimes = (data: DefaultVendorAccess[]) => {
    const sortedData = data?.sort(
      (r1, r2) =>
        r1.validFrom.toDateTime().valueOf() -
        r2.validFrom.toDateTime().valueOf(),
    );
    setDefaultVendorAccessTimes(sortedData || []);
    setTempDefaultVendorAccessTimes(_.cloneDeep(sortedData));
  };

  return (
    <>
      {defaultVendorAccessTimes?.length ? (
        <div className="space-y-6">
          <DefaultFacilityAccessTimeDetailsRow
            defaultVendorAccessData={defaultVendorAccessTimes}
            onDelete={id => onDeleteRow(id)}
            onChangePinCode={(id, pinCode) => {
              onChangePinCode(id, pinCode);
            }}
            onChangeValidFrom={(id, validFrom) => {
              onChangeValidFrom(id, validFrom);
            }}
            onChangeValidTo={(id, validTo) => {
              onChangeValidTo(id, validTo);
            }}
            showErrors={showErrors}
            itemIdsWithInvalidDuration={itemIdsWithInvalidDuration}
          />
        </div>
      ) : null}

      {!loading && (
        <div className="mt-6">
          <Button
            className="sm:w-45% px-4 py-2 font-bold text-blue-500"
            onClick={() => {
              const tempAccessTimes: DefaultVendorAccess[] =
                defaultVendorAccessTimes.concat({
                  id: (defaultVendorAccessTimes.length + 1).toString(),
                  pinCode: "",
                  validFrom: DateOnly.today(),
                  validTo: null,
                });
              setDefaultVendorAccessTimes(tempAccessTimes);
            }}
            text="Lägg till pinkod"
            translationName="facility-settings.doors.add-pin-code"
            type="add"
            buttonType="button"
          />
          <div className="mt-3">
            <FacilitySubmitResetButtons
              onSubmit={validateAndSubmit}
              onReset={() => {
                setDefaultVendorAccessTimes(
                  _.cloneDeep(tempDefaultVendorAccessTimes),
                );
                setShowErrors(false);
                setItemIdsWithInvalidDuration([]);
              }}
              disabled={_.isEqual(
                defaultVendorAccessTimes,
                tempDefaultVendorAccessTimes,
              )}
            />
          </div>
        </div>
      )}
    </>
  );
};
