import useSWR from "swr";

import { FacilityRoleWithUsers, UserRole } from "../../models/Permissions";

import { getFacilityUsersByRole } from "../../services/permissionService";

import { useSelectedFacilityId } from "../../recoil/selectedFacilityIdState";

export const useSelectedFacilityRoles = () => {
  const selectedFacilityId = useSelectedFacilityId();

  const roles = Object.keys(UserRole).filter(
    v => isNaN(+v) && v !== "SuperAdmin",
  );

  const { data, ...rest } = useSWR(
    selectedFacilityId ? ["facilityRoles", selectedFacilityId] : null,
    async ([, selectedFacilityId]) => {
      // Wait until promises are resolved for all calls to getFacilityUsersByrole
      const facilityRoles: FacilityRoleWithUsers[] = await Promise.all(
        roles.map(async r => {
          const users = await getFacilityUsersByRole(
            selectedFacilityId,
            UserRole[r],
          ).then(res => res);

          return { name: r, users };
        }),
      );

      return facilityRoles;
    },
  );

  return {
    ...rest,
    facilityRoles: data,
  };
};
