import React, { useEffect } from "react";

import { useRecoilState, useSetRecoilState } from "recoil";

import { useToaster } from "../../../hooks/common/useToaster";
import { useAppDispatch } from "../../../hooks/store/store";
import { useAdminFacilities } from "../../../hooks/swr/useAdminFacilities";
import { useFacility } from "../../../hooks/swr/useFacility";

import { getUserPermissions } from "../../player/services/UserService";

import { userPermissionsState } from "../../../recoil/Customer/userPermissionsState";
import { selectedFacilityIdState } from "../../../recoil/selectedFacilityIdState";
import { setSelectedFacility } from "../../../store/slices/facilities/facilitiesSlice";
import { FacilityHeaderSelect } from "./FacilityHeaderSelect";

const FacilitySelect: React.FC = () => {
  const dispatch = useAppDispatch();
  const { toastError } = useToaster();
  const [selectedFacilityId, setSelectedFacilityId] = useRecoilState(
    selectedFacilityIdState,
  );

  const { isLoading, facilities } = useAdminFacilities();

  useFacility(selectedFacilityId);

  // Set selected facility to first one if it's not set
  useEffect(() => {
    if (facilities?.length > 0 && !selectedFacilityId) {
      setSelectedFacilityId(facilities[0].id);
    }
  }, [facilities, selectedFacilityId]);

  useEffect(() => {
    if (!selectedFacilityId || !facilities) return;

    dispatch(
      setSelectedFacility({
        facility: facilities.find(
          facility => facility.id === selectedFacilityId,
        ),
      }),
    );
  }, [selectedFacilityId, facilities]);

  const setUserPermissions = useSetRecoilState(userPermissionsState);

  const fetchUserPermissions = async () => {
    try {
      const userPermissions = await getUserPermissions();
      setUserPermissions(userPermissions?.permissions);
    } catch (e) {
      toastError.fetchUserPermissionsFailed();
    }
  };

  const handleChangeFacility = (facilityId: string) => {
    fetchUserPermissions();
    setSelectedFacilityId(facilityId);
  };

  return (
    <FacilityHeaderSelect
      items={facilities || []}
      isLoading={isLoading}
      preselectedItem={selectedFacilityId}
      onItemClick={handleChangeFacility}
    />
  );
};

export default FacilitySelect;
