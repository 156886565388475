import React from "react";
import { FormattedMessage } from "react-intl";

import styled from "styled-components";

import { breakpoints } from "../appConstants/common";

interface StyleProps {
  $inlined?: boolean;
}

interface Props {
  title?: string;
  translationName?: string;
  withArrow?: boolean;
  isInlined?: boolean;
  prefix?: React.ReactElement;
}

const TitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;

  @media (min-width: ${breakpoints.MOBILE}) {
    padding: 1.5rem;
  }
`;

const TitleText = styled.div`
  font-size: var(--h2);
  color: var(--primary);
  font-weight: var(--bold);
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const TitleCard = styled.div`
  display: inline-block;
  margin: 1rem 0;
  position: relative;
  background-color: var(--light);
  width: auto;
  max-width: 100%;
  padding: 0;
  border-radius: var(--border-radius);
  box-shadow: var(--default-shadow);
  z-index: 10;
`;

const TitleCardWithArrow = styled(TitleCard)<StyleProps>`
  ${props =>
    props.$inlined &&
    `
margin-bottom: -0.5rem;
margin-left: 1rem;
margin-right: 1rem;
`};

  @media (min-width: ${breakpoints.LARGE}) {
    ${props =>
      props.$inlined &&
      `
margin-left: -1.5rem;
`};
  }

  &::after {
    content: "";
    width: 0rem;
    height: 0rem;
    position: absolute;
    left: 3rem;
    bottom: -2.2rem;
    border-right: 1.5rem solid transparent;
    border-top: 1.5rem solid var(--light);
    border-bottom: 1.5rem solid transparent;
    border-left: 1.5rem solid transparent;
  }
`;
export const Title: React.FC<Props> = ({
  title,
  translationName,
  withArrow = false,
  isInlined = false,
  prefix = null,
}) => {
  return (
    <>
      {withArrow && (
        <TitleCardWithArrow $inlined={isInlined}>
          <TitleWrapper>
            {prefix}
            <TitleText>
              {translationName && (
                <FormattedMessage id={translationName} defaultMessage={title} />
              )}
              {!translationName && title}
            </TitleText>
          </TitleWrapper>
        </TitleCardWithArrow>
      )}

      {!withArrow && (
        <TitleCard>
          <TitleWrapper>
            {prefix}
            <TitleText>
              {translationName && (
                <FormattedMessage id={translationName} defaultMessage={title} />
              )}
              {!translationName && title}
            </TitleText>
          </TitleWrapper>
        </TitleCard>
      )}
    </>
  );
};
