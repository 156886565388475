import { useState } from "react";
import { useIntl } from "react-intl";

import { faBell, faCheck } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormik } from "formik";
import { InputSwitch } from "primereact/inputswitch";
import useSWR from "swr";
import { boolean, object, string } from "yup";

import {
  type MaintenanceMessage,
  MaintenanceMessageDeviceType,
  MaintenanceMessageSeverity,
} from "../../../models/maintenanceMessage";

import { getMaintenanceMessageColor } from "../../../helpers/maintenanceMessage";

import { useToaster } from "../../../hooks/common/useToaster";
import { useMaintenanceMessage } from "../../../hooks/swr/useMaintenanceMessage";

import {
  deleteSuperAdminMaintenanceMessage,
  getSuperAdminMaintenanceMessage,
  upsertSuperAdminMaintenanceMessage,
} from "../../../services/superAdminMaintenanceService";

import { Button } from "../../../components/Button";
import { Label } from "../../../components/Label";
import { TextAreaInput } from "../../../components/TextAreaInput";

const severityOptions = [
  {
    color: getMaintenanceMessageColor(MaintenanceMessageSeverity.Critical),
    value: MaintenanceMessageSeverity.Critical,
  },
  {
    color: getMaintenanceMessageColor(MaintenanceMessageSeverity.High),
    value: MaintenanceMessageSeverity.High,
  },
  {
    color: getMaintenanceMessageColor(MaintenanceMessageSeverity.Low),
    value: MaintenanceMessageSeverity.Low,
  },
];

export const MaintenanceMessageTab = () => {
  const intl = useIntl();
  const toaster = useToaster();

  const { data, mutate } = useSWR(
    "superAdminMaintenanceMessage",
    getSuperAdminMaintenanceMessage,
    { revalidateOnFocus: false, revalidateOnReconnect: false },
  );
  const { mutate: mutateMaintenanceMessage } = useMaintenanceMessage();

  const [isDeletingMessage, setIsDeletingMessage] = useState(false);

  const formik = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: async values => {
      const deviceTypes: MaintenanceMessage["deviceTypes"] = [];

      if (values.platformApp) {
        deviceTypes.push(MaintenanceMessageDeviceType.App);
      }

      if (values.platformWeb) {
        deviceTypes.push(MaintenanceMessageDeviceType.Web);
      }

      try {
        const newMaintenanceMessage = await upsertSuperAdminMaintenanceMessage({
          message: values.message.trim(),
          severity: values.severity,
          deviceTypes,
        });

        mutate(newMaintenanceMessage, false);
        mutateMaintenanceMessage(newMaintenanceMessage, false);
        toaster.toastSuccess.message("Maintenance message updated");
      } catch (e) {
        console.error(e);
        toaster.toastError.unknown();
      }
    },
    initialValues: {
      message: data?.message || "",
      severity: data?.severity || MaintenanceMessageSeverity.Critical,
      platformWeb:
        data?.deviceTypes.includes(MaintenanceMessageDeviceType.Web) || false,
      platformApp:
        data?.deviceTypes.includes(MaintenanceMessageDeviceType.App) || false,
    },
    validationSchema: object().shape(
      {
        message: string().required(),
        severity: string().required(),
        platformApp: boolean().when("platformWeb", {
          is: false,
          then: schema => schema.isTrue(),
        }),
        platformWeb: boolean().when("platformApp", {
          is: false,
          then: schema => schema.isTrue(),
        }),
      },
      [["platformWeb", "platformApp"]],
    ),
  });

  const handleDeleteMessage = async () => {
    if (
      !window.confirm(
        "Are you sure you want to delete the maintenance message?",
      )
    ) {
      return;
    }

    setIsDeletingMessage(true);

    try {
      await deleteSuperAdminMaintenanceMessage();
      toaster.toastSuccess.message("Maintenance message deleted");
      mutate(undefined, false);
      mutateMaintenanceMessage(null, false);
    } catch {
      toaster.toastError.unknown();
    } finally {
      setIsDeletingMessage(false);
    }
  };

  return (
    <form className="grid gap-8 lg:grid-cols-2" onSubmit={formik.handleSubmit}>
      <TextAreaInput
        name="message"
        value={formik.values.message}
        onChange={formik.handleChange}
        label={intl.formatMessage({ id: "common.error-message" })}
        rows={3}
      />
      <div className="mt-4 grid grid-cols-2 gap-8 lg:grid-cols-1">
        <div>
          <Label>Choose severity</Label>
          <div className="mt-2 flex flex-wrap gap-x-2 text-2xl lg:gap-x-8">
            {severityOptions.map(option => (
              <div key={option.value}>
                <input
                  type="radio"
                  name="severity"
                  onChange={formik.handleChange}
                  value={option.value}
                  id={option.value}
                  className="hidden"
                  checked={formik.values.severity === option.value}
                />
                <label
                  htmlFor={option.value}
                  className="relative block cursor-pointer p-2"
                >
                  <FontAwesomeIcon icon={faBell} className={option.color} />
                  {formik.values.severity === option.value && (
                    <div className="absolute right-0 top-0 flex h-3 w-3 items-center justify-center rounded-full bg-pureblack">
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="text-[7px] text-white"
                      />
                    </div>
                  )}
                </label>
              </div>
            ))}
          </div>
        </div>

        <div>
          <Label>Choose platform for publishing</Label>
          <div className="mt-4 flex flex-col gap-4">
            <div className="grid grid-cols-[4rem_1fr] items-center">
              {MaintenanceMessageDeviceType.App}
              <InputSwitch
                name="platformApp"
                checked={formik.values.platformApp}
                onChange={formik.handleChange}
              />
            </div>
            <div className="grid grid-cols-[4rem_1fr] items-center">
              {MaintenanceMessageDeviceType.Web}
              <InputSwitch
                name="platformWeb"
                checked={formik.values.platformWeb}
                onChange={formik.handleChange}
              />
            </div>
          </div>
        </div>

        <div className="order-4 flex gap-4">
          <Button
            disabled={!data || formik.isSubmitting || isDeletingMessage}
            loading={isDeletingMessage}
            onClick={handleDeleteMessage}
          >
            {isDeletingMessage ? "Deleting..." : "Delete"}
          </Button>
          <Button
            buttonType="submit"
            type="primary"
            disabled={
              !formik.isValid ||
              !formik.dirty ||
              formik.isSubmitting ||
              isDeletingMessage
            }
            loading={formik.isSubmitting}
          >
            {formik.isSubmitting ? "Updating..." : "Update"}
          </Button>
        </div>
      </div>
    </form>
  );
};
