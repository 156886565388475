import { FormattedMessage, useIntl } from "react-intl";

import {
  faCoin,
  faShieldCheck,
  faShuffle,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";

import { SeriesPlayer } from "../../models/Series";

import { useDateFormatWithSelectedFacility } from "../../../../hooks/useDateFormat";

import { ProfileImageWithFallback } from "../../../../components/ProfileImageWithFallback";

import { luxonDateTimeFormat } from "../../../../utils/dateFormats";

interface IProps {
  player: SeriesPlayer;
  isLocked: boolean;
  onMarkAsPaid: () => void;
  onSwitchPlayer: () => void;
  onMakeTeamCaptain: () => void;
}

export const TeamMemberItem: React.FC<IProps> = ({
  player,
  isLocked,
  onMarkAsPaid,
  onSwitchPlayer,
  onMakeTeamCaptain,
}) => {
  const { formatMessage } = useIntl();
  const { df } = useDateFormatWithSelectedFacility();

  const baseIconClasses =
    "flex rounded-full w-8 h-8 items-center justify-center mr-1";
  const iconBoxClasses = clsx(
    baseIconClasses,
    "cursor-pointer text-primary hover:bg-blue-50 group-hover:bg-blue-50",
  );
  const lockedIconBoxClasses = clsx(baseIconClasses, {
    "cursor-pointer text-primary group-hover:bg-blue-50": !isLocked,
    "text-gray-500 hover:bg-gray-100": isLocked,
  });

  const baseWrapperClasses =
    "group inline-flex items-center text-primary cursor-pointer hover:underline hover:underline-offset-4 self-start";
  const wrapperClasses = clsx(baseWrapperClasses, {
    "text-primary hover:text-primary": !isLocked,
    "text-gray-500 hover:text-gray-500": isLocked,
  });

  return (
    <div className="p-2">
      <div className="flex items-center gap-2">
        <ProfileImageWithFallback
          src={player?.profileImage}
          firstName={player?.firstName}
          lastName={player?.lastName}
        />
        <div className="flex flex-col">
          {player?.isTeamCaptain && (
            <p className="font-bold">
              <FormattedMessage id="series.team.captain" />
            </p>
          )}
          <p>{player?.displayName}</p>
        </div>
      </div>
      <div className="mt-2 flex flex-col justify-start gap-1">
        {!player?.isPayed && (
          <button
            type="button"
            onClick={onMarkAsPaid}
            className={wrapperClasses}
          >
            <div className={iconBoxClasses}>
              <FontAwesomeIcon icon={faCoin} />
            </div>
            <FormattedMessage id="admin.markAsPaid.confirm.title" />
          </button>
        )}

        {player?.adminUser && player?.lastModified && (
          <div className="flex items-center">
            <div className={baseIconClasses}>
              <FontAwesomeIcon icon={faCoin} />
            </div>
            <span>{`${formatMessage({
              id: "admin.marked-as-paid-by",
            })} ${player?.adminUser.displayName.split(" ")[0]} - ${df(
              player?.lastModified,
              luxonDateTimeFormat,
            )}`}</span>
          </div>
        )}

        <button
          type="button"
          onClick={() => !isLocked && onSwitchPlayer?.()}
          className={wrapperClasses}
        >
          <div className={lockedIconBoxClasses}>
            <FontAwesomeIcon icon={faShuffle} />
          </div>
          <FormattedMessage id="common.replace" />
        </button>

        {!player.isTeamCaptain && (
          <button
            type="button"
            onClick={() => !isLocked && onMakeTeamCaptain?.()}
            className={wrapperClasses}
          >
            <div className={lockedIconBoxClasses}>
              <FontAwesomeIcon icon={faShieldCheck} />
            </div>
            <FormattedMessage id="series.make.team.captain" />
          </button>
        )}
      </div>
    </div>
  );
};
