import { DateTime } from "luxon";

import { DateOnly } from "../../../models/DateOnly";
import { TimeOnly } from "../../../models/TimeOnly";
import { ApiResponse, ApiSingleResponse } from "../../../models/common";
import { CreateGamePayload } from "../../checkout/models/Games";
import { Payment, PaymentInfo } from "../../checkout/models/Payment";
import { SerieMatch, SerieMatchApiResponse } from "../models/SerieMatch";
import {
  IAddTeamToDivisionFormValues,
  ICreateSerieFormValues,
  IEditSerieFormValues,
  JoinActivityFormValues,
  NextSeasonInfo,
  NextSeasonInfoApiResponse,
  Series,
  SeriesApiResponse,
  SeriesDivision,
  SeriesDivisionApiResponse,
  SeriesDivisionFormValues,
  SeriesMatch,
  SeriesMatchApiResponse,
  SeriesMatchCreationRules,
  SeriesMatchCreationRulesApiResponse,
  SeriesMatchRequest,
  SeriesScoreboard,
  SeriesScoreboardApiResponse,
  SeriesTeam,
  SeriesTeamApiResponse,
} from "../models/Series";

import { fetchApi } from "../../../services/legacyApiClient";

const apiVersion = "game/v4";

export const updateRegistrationStatus = (
  serieId: string,
  status: boolean,
): Promise<boolean> => {
  return fetchApi({
    method: "PUT",
    uri: `${apiVersion}/series/${serieId}/openforregistration`,
    payload: { openForRegistration: status },
  })
    .then((data: ApiSingleResponse<boolean>) => data.data)
    .catch(() => {
      return Promise.reject(null);
    });
};

export const signUp = (
  seriesId: string,
  data: JoinActivityFormValues,
): Promise<ApiSingleResponse<boolean>> => {
  return fetchApi({
    method: "POST",
    uri: `${apiVersion}/series/${seriesId}/signup`,
    payload: data,
  })
    .then((data: ApiSingleResponse<boolean>) => {
      return Promise.resolve(data);
    })
    .catch(() => {
      return Promise.reject(null);
    });
};

export const createSerieByWizard = (
  data: ICreateSerieFormValues,
  signal?: AbortSignal,
): Promise<ApiSingleResponse<Series>> => {
  const payload = {
    ...data,
    endTime: DateOnly.fromDateTime(data.endTime).toISO(),
    registrationOpenTo: DateOnly.fromDateTime(data.registrationOpenTo).toISO(),
    startTime: DateOnly.fromDateTime(data.startTime).toISO(),
  };
  return fetchApi({
    method: "POST",
    uri: `${apiVersion}/series/wizard`,
    payload: payload,
    signal,
  })
    .then((data: ApiSingleResponse<SeriesApiResponse>) => ({
      ...data,
      data: formatSeriesFromApi(data.data),
    }))
    .catch(() => {
      return Promise.reject(null);
    });
};

export const updateSerie = (
  serieId: string,
  data: IEditSerieFormValues,
): Promise<ApiSingleResponse<Series>> => {
  const payload = {
    ...data,
    startTime: DateOnly.fromDateTime(data.startTime).toISO(),
    endTime: data.endTime
      ? DateOnly.fromDateTime(data.endTime).toISO()
      : undefined,
    registrationOpenTo: data.registrationOpenTo
      ? DateOnly.fromDateTime(data.registrationOpenTo).toISO()
      : undefined,
  };

  return fetchApi({
    method: "PUT",
    uri: `${apiVersion}/series/${serieId}`,
    payload,
  })
    .then((data: ApiSingleResponse<SeriesApiResponse>) => ({
      ...data,
      data: formatSeriesFromApi(data.data),
    }))
    .catch(() => {
      return Promise.reject(null);
    });
};

export const addTeamToDivision = (
  data: IAddTeamToDivisionFormValues,
): Promise<ApiSingleResponse<Series>> => {
  return fetchApi({
    method: "POST",
    uri: `${apiVersion}/series/${data.serieId}/division/${data.divisionId}/team`,
    payload: data,
  })
    .then((data: ApiSingleResponse<SeriesApiResponse>) => ({
      ...data,
      data: formatSeriesFromApi(data.data),
    }))
    .catch(() => {
      return Promise.reject(null);
    });
};

export const createSerieDivision = (
  data: SeriesDivisionFormValues,
): Promise<ApiSingleResponse<Series>> => {
  return fetchApi({
    method: "POST",
    uri: `${apiVersion}/series/${data.serieId}/division`,
    payload: data,
  })
    .then((data: ApiSingleResponse<SeriesApiResponse>) => ({
      ...data,
      data: formatSeriesFromApi(data.data),
    }))
    .catch(() => {
      return Promise.reject(null);
    });
};

export const editSerieDivision = (
  divisionId: string,
  data: SeriesDivisionFormValues,
): Promise<ApiSingleResponse<Series>> => {
  return fetchApi({
    method: "PUT",
    uri: `${apiVersion}/series/${data.serieId}/division/${divisionId}`,
    payload: data,
  })
    .then((data: ApiSingleResponse<SeriesApiResponse>) => ({
      ...data,
      data: formatSeriesFromApi(data.data),
    }))
    .catch(() => {
      return Promise.reject(null);
    });
};

export const removeTeamFromDivision = (
  serieId: string,
  divisionId: string,
  teamId: string,
): Promise<ApiSingleResponse<Series>> => {
  return fetchApi({
    method: "DELETE",
    uri: `${apiVersion}/series/${serieId}/division/${divisionId}/team/${teamId}`,
  })
    .then((data: ApiSingleResponse<SeriesApiResponse>) => ({
      ...data,
      data: formatSeriesFromApi(data.data),
    }))
    .catch(() => {
      return Promise.reject(null);
    });
};

export const createGames = (data: CreateGamePayload): Promise<boolean> => {
  const payload = {
    ...data,
    firstStartDate: data.firstStartDate.toISO(),
    lastStartDate: data.lastStartDate.toISO(),
    firstStartTime: TimeOnly.fromDateTime(data.firstStartTime).toISO(),
    lastStartTime: TimeOnly.fromDateTime(data.lastStartTime).toISO(),
  };

  return fetchApi({
    method: "POST",
    uri: `${apiVersion}/series/${data.serieId}/creategames`,
    payload: payload,
  })
    .then(() => true)
    .catch(() => {
      return Promise.reject(null);
    });
};

export const deleteSerie = (
  serieId: string,
): Promise<ApiSingleResponse<boolean>> => {
  return fetchApi({ method: "DELETE", uri: `${apiVersion}/series/${serieId}` })
    .then((data: ApiSingleResponse<boolean>) => data)
    .catch(() => {
      return Promise.reject(null);
    });
};

export const getScoreboard = (
  serieId: string,
  signal?: AbortSignal,
): Promise<ApiResponse<SeriesScoreboard>> => {
  return fetchApi({ uri: `${apiVersion}/series/${serieId}/score`, signal })
    .then((data: ApiResponse<SeriesScoreboardApiResponse>) => {
      return Promise.resolve({
        ...data,
        data: data.data.map(scoreboard =>
          formatSerieDivisionScoreboardFromApi(scoreboard),
        ),
      });
    })
    .catch(() => {
      return Promise.reject(null);
    });
};

export const getDivisionScoreboard = (
  serieId: string,
  divisionId: string,
  signal?: AbortSignal,
): Promise<ApiResponse<SeriesScoreboard>> => {
  return fetchApi({
    uri: `${apiVersion}/series/${serieId}/${divisionId}/score`,
    signal,
  })
    .then((data: ApiResponse<SeriesScoreboardApiResponse>) => {
      return Promise.resolve({
        ...data,
        data: data.data.map(scoreboard =>
          formatSerieDivisionScoreboardFromApi(scoreboard),
        ),
      });
    })
    .catch(() => {
      return Promise.reject(null);
    });
};

export const payForSeries = async (
  seriesId: string,
  paymentMethod: string,
  data?: PaymentInfo,
  signal?: AbortSignal,
): Promise<Payment | null> => {
  try {
    const response = (await fetchApi<ApiSingleResponse<Payment>>({
      method: "POST",
      uri: `${apiVersion}/series/${seriesId}/pay/${paymentMethod}`,
      payload: {
        paymentInfo: data,
        memberUserIds:
          data.splitPayment?.splitUserIds?.length > 0
            ? data.splitPayment.splitUserIds
            : [],
      },
      signal,
    })) as ApiSingleResponse<Payment>;
    return response.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const createNewSeason = (
  data: IEditSerieFormValues,
  signal?: AbortSignal,
): Promise<ApiSingleResponse<Series>> => {
  const payload = {
    ...data,
    endTime: data.endTime ? DateOnly.fromDateTime(data.endTime).toISO() : null,
    registrationOpenTo: DateOnly.fromDateTime(data.registrationOpenTo).toISO(),
    startTime: DateOnly.fromDateTime(data.startTime).toISO(),
  };

  return fetchApi({
    method: "POST",
    uri: `${apiVersion}/series/season`,
    payload,
    signal,
  })
    .then((data: ApiSingleResponse<SeriesApiResponse>) => ({
      ...data,
      data: formatSeriesFromApi(data.data),
    }))
    .catch(() => {
      return Promise.reject(null);
    });
};

export const sendEmailToDivision = async (
  seriesId: string,
  divisionId: string,
  signal?: AbortSignal,
): Promise<null> => {
  try {
    await fetchApi({
      method: "POST",
      uri: `${apiVersion}/series/${seriesId}/${divisionId}/mail`,
      signal,
    });
    return Promise.resolve(null);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const removeDivision = async (
  seriesId: string,
  divisionId: string,
  signal?: AbortSignal,
): Promise<null> => {
  try {
    await fetchApi({
      method: "DELETE",
      uri: `${apiVersion}/series/${seriesId}/division/${divisionId}`,
      signal,
    });
    return Promise.resolve(null);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const removeDivisionGames = async (
  seriesId: string,
  divisionId: string,
  signal?: AbortSignal,
): Promise<null> => {
  try {
    await fetchApi({
      method: "DELETE",
      uri: `${apiVersion}/series/${seriesId}/division/${divisionId}/removegames`,
      signal,
    });
    return Promise.resolve(null);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getSeries = (
  serieId: string,
  include?: string,
  signal?: AbortSignal,
): Promise<ApiSingleResponse<Series>> =>
  fetchApi({
    uri: `${apiVersion}/series/${serieId}${
      include ? `?include=${include}` : ""
    }`,
    signal,
  })
    .then((data: ApiSingleResponse<SeriesApiResponse>) => ({
      ...data,
      data: formatSeriesFromApi(data.data),
    }))
    .catch(() => {
      return Promise.reject(null);
    });

const formatSeriesFromApi = (series: SeriesApiResponse): Series => {
  return {
    ...series,
    divisions: series.divisions?.map(formatSeriesDivisionFromApi),
    endTime: DateTime.fromISO(series.endTime, { setZone: true }),
    registeredTeams: series.registeredTeams?.map(formatSeriesTeamFromApi) ?? [],
    registrationOpenTo: DateTime.fromISO(series.registrationOpenTo, {
      setZone: true,
    }),
    startTime: DateTime.fromISO(series.startTime, { setZone: true }),
  };
};

const formatSeriesDivisionFromApi = (
  division: SeriesDivisionApiResponse,
): SeriesDivision => {
  return {
    ...division,
    gamesCreationRules: division.gamesCreationRules
      ? formatSeriesMatchCreationRulesFromApi(division.gamesCreationRules)
      : null,
    teams: division.teams.map(formatSeriesTeamFromApi),
    mostRecentScheduleEmailSent: division.mostRecentScheduleEmailSent
      ? DateTime.fromISO(division.mostRecentScheduleEmailSent, {
          setZone: true,
        })
      : null,
  };
};

const formatSeriesTeamFromApi = (team: SeriesTeamApiResponse): SeriesTeam => {
  return {
    ...team,
    registrationDate: DateTime.fromISO(team.registrationDate, {
      setZone: true,
    }),
    teamMembers: formatSeriesTeamMembersFromApi(team.teamMembers),
  };
};

const formatSeriesTeamMembersFromApi = (
  teamMembers: SeriesTeamApiResponse["teamMembers"],
): SeriesTeam["teamMembers"] => {
  return teamMembers.map(teamMember => {
    return {
      ...teamMember,
      lastModified: teamMember.lastModified
        ? DateTime.fromISO(teamMember.lastModified, { setZone: true })
        : null,
    };
  });
};

const formatSeriesMatchCreationRulesFromApi = (
  creationRules: SeriesMatchCreationRulesApiResponse,
): SeriesMatchCreationRules => {
  return {
    ...creationRules,
    firstStartDate: DateOnly.fromISODate(creationRules.firstStartDate),
    firstStartTime: TimeOnly.fromISOTime(creationRules.firstStartTime),
    lastStartDate: DateOnly.fromISODate(creationRules.lastStartDate),
    lastStartTime: TimeOnly.fromISOTime(creationRules.lastStartTime),
  };
};

export const getSeriesMatches = (
  serieId: string,
  data?: SeriesMatchRequest,
  signal?: AbortSignal,
): Promise<ApiResponse<SeriesMatch>> =>
  fetchApi({
    method: "POST",
    uri: `${apiVersion}/series/matchesInfo/${serieId}`,
    payload: data,
    signal,
  })
    .then((data: ApiResponse<SeriesMatchApiResponse>) => {
      return { ...data, data: data.data.map(formatSeriesMatchFromApi) };
    })
    .catch(err => {
      return Promise.reject(err);
    });

export const getSeriesMatch = (
  serieId: string,
  matchId: string,
  signal?: AbortSignal,
): Promise<ApiSingleResponse<SeriesMatch>> =>
  fetchApi({
    uri: `${apiVersion}/series/matchInfo/${serieId}/${matchId}`,
    signal,
  })
    .then((data: ApiSingleResponse<SeriesMatchApiResponse>) => {
      return { ...data, data: formatSeriesMatchFromApi(data.data) };
    })
    .catch(err => {
      return Promise.reject(err);
    });

export const hasUnfinishedGames = (
  seriesId: string,
  signal?: AbortSignal,
): Promise<ApiSingleResponse<boolean>> => {
  return fetchApi({
    uri: `${apiVersion}/series/${seriesId}/unfinishedgamesexist`,
    signal,
  })
    .then((data: ApiSingleResponse<boolean>) => {
      return Promise.resolve(data);
    })
    .catch(() => {
      return Promise.reject(null);
    });
};

// Same as getSeriesMatches with matchStatus Upcoming?
export const fetchUnfinishedGames = (
  seriesId: string,
  signal?: AbortSignal,
): Promise<ApiSingleResponse<SerieMatch[]>> => {
  return fetchApi({
    uri: `${apiVersion}/series/${seriesId}/unfinishedgames`,
    signal,
  })
    .then((data: ApiSingleResponse<SerieMatchApiResponse[]>) => ({
      ...data,
      data: data.data.map(match => formatSerieMatchFromApi(match)),
    }))
    .catch(() => {
      return Promise.reject(null);
    });
};

export const closeSeason = (
  seriesId: string,
  emailPlayers: boolean,
  signal?: AbortSignal,
): Promise<ApiSingleResponse<Series>> => {
  return fetchApi({
    method: "PUT",
    uri: `${apiVersion}/series/${seriesId}/closeseasonandmigrateteams?emailPlayers=${emailPlayers}`,
    signal,
  })
    .then((data: ApiSingleResponse<SeriesApiResponse>) => ({
      ...data,
      data: formatSeriesFromApi(data.data),
    }))
    .catch(() => {
      return Promise.reject(null);
    });
};

export const replacePartner = async (
  seriesId: string,
  facilityId: string,
  teamId: string,
  playerOut: string,
  playerIn: string,
  signal?: AbortSignal,
): Promise<boolean> => {
  try {
    const response = (await fetchApi({
      method: "POST",
      uri: `${apiVersion}/series/switchplayerforseries/${seriesId}/${facilityId}/${teamId}/${playerOut}/${playerIn}`,
      signal,
    })) as ApiSingleResponse<boolean>;
    return Promise.resolve(response?.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const switchTeamCaptain = async (
  seriesId: string,
  facilityId: string,
  teamId: string,
  signal?: AbortSignal,
): Promise<null> => {
  try {
    (await fetchApi({
      method: "POST",
      uri: `${apiVersion}/series/switchteamcaptain/${seriesId}/${facilityId}/${teamId}`,
      signal,
    })) as ApiSingleResponse<null>;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getNextSeason = (
  oldSeriesId: string,
  signal?: AbortSignal,
): Promise<ApiSingleResponse<NextSeasonInfo>> => {
  return fetchApi({
    uri: `${apiVersion}/series/${oldSeriesId}/nextseasoninfo`,
    signal,
  })
    .then((data: ApiSingleResponse<NextSeasonInfoApiResponse>) => {
      return {
        ...data,
        data: formatNextSeasonInfo(data.data),
      };
    })
    .catch(err => {
      return Promise.reject(err);
    });
};

const formatNextSeasonInfo = (
  data: NextSeasonInfoApiResponse,
): NextSeasonInfo => {
  return {
    ...data,
    startTime: DateTime.fromISO(data.startTime, { setZone: true }),
    endTime: DateTime.fromISO(data.endTime, { setZone: true }),
    registrationOpenTo: DateTime.fromISO(data.registrationOpenTo, {
      setZone: true,
    }),
  };
};

export const markSerieAsPaid = (
  seriesId: string,
  teamId: string,
  userId: string,
  signal?: AbortSignal,
): Promise<ApiSingleResponse<boolean>> => {
  return fetchApi({
    method: "POST",
    uri: `${apiVersion}/series/admin/markaspaid/${seriesId}/${teamId}/${userId}`,
    signal,
  })
    .then((data: ApiSingleResponse<boolean>) => {
      return Promise.resolve(data);
    })
    .catch(err => {
      return Promise.reject(err);
    });
};

export const sendMessageToSeriesPlayers = async (
  seriesId: Series["id"],
  data: { subject: string; content: string },
  signal?: AbortSignal,
): Promise<boolean> => {
  try {
    const response = (await fetchApi<ApiSingleResponse<boolean>>({
      method: "POST",
      uri: `${apiVersion}/series/sendemailstoseriesanddivision/${seriesId}`,
      signal,
      payload: data,
    })) as ApiSingleResponse<boolean>;
    return response.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const sendMessageToDivisionPlayers = async (
  seriesId: Series["id"],
  data: { divisionId: SeriesDivision["id"]; subject: string; content: string },
  signal?: AbortSignal,
): Promise<boolean> => {
  try {
    const response = (await fetchApi<ApiSingleResponse<boolean>>({
      method: "POST",
      uri: `${apiVersion}/series/sendemailstoseriesanddivision/${seriesId}`,
      signal,
      payload: data,
    })) as ApiSingleResponse<boolean>;
    return response.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

const formatSeriesMatchFromApi = (
  match: SeriesMatchApiResponse,
): SeriesMatch => {
  return {
    ...match,
    endTime: DateTime.fromISO(match.endTime, {
      setZone: true,
    }),
    startTime: DateTime.fromISO(match.startTime, {
      setZone: true,
    }),
  };
};

function formatSerieMatchFromApi(data: SerieMatchApiResponse): SerieMatch {
  return {
    ...data,
    startTime: DateTime.fromISO(data.startTime, { setZone: true }),
    endTime: DateTime.fromISO(data.endTime, { setZone: true }),
  };
}

const formatSerieDivisionScoreboardFromApi = (
  data: SeriesScoreboardApiResponse,
): SeriesScoreboard => {
  return {
    ...data,
    scoreboard: data.scoreboard.map(scoreboard => ({
      ...scoreboard,
      team: formatSeriesTeamFromApi(scoreboard.team),
      playedGames: scoreboard.playedGames.map(game => ({
        ...game,
        opponents: formatSeriesTeamFromApi(game.opponents),
      })),
    })),
  };
};
