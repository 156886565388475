import useSWR from "swr";

import { getGames } from "../../modules/game/services/Games";

export const useGames = (isUpcoming = false, page = 1, pageSize = 3) => {
  const { data, ...rest } = useSWR(
    ["games", isUpcoming, page, pageSize],
    ([, isUpcoming, page, pageSize]) =>
      getGames(isUpcoming, page, pageSize).then(res => res?.data),
  );

  return {
    ...rest,
    games: data,
  };
};
