import { atom, useRecoilValue } from "recoil";

import { localStoragePersistenceEffect } from "./effects/localStoragePersistenceEffect";

export const adminCalendarVisibleCourtFilterState = atom<
  "all" | "open" | "closed"
>({
  key: "adminCalendarVisibleCourtFilter",
  default: "all",
  effects: [localStoragePersistenceEffect("adminCalendarVisibleCourtFilter")],
});

export const useAdminCalendarVisibleCourtFilterState = () =>
  useRecoilValue(adminCalendarVisibleCourtFilterState);
