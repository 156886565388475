import React from "react";

import styled from "styled-components";

import { breakpoints } from "../appConstants/common";

import { Card } from "../components/Card";
import { UserOverviewCalendar } from "../modules/checkout/components/Calendar/UserOverviewCalendar";
import { FindFacilityTimeSlots } from "../modules/checkout/components/FacilitySearch/FindFacilityTimeSlots";

const OverviewLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  gap: 1rem;

  @media (min-width: ${breakpoints.XL}) {
    grid-template-columns: 1fr 1fr !important;
    gap: 2rem;
  }
`;

const OverflowingCard = styled.div`
  overflow: visible;
  justify-content: center;
`;

const SearchWrapper = styled.div`
  padding: 1rem;
  @media (min-width: ${breakpoints.XL}) {
    padding: 2rem;
  }
  background: var(--white);
`;

export const BookingPage: React.FC = () => {
  return (
    <OverviewLayout>
      <OverflowingCard>
        <SearchWrapper>
          <FindFacilityTimeSlots />
        </SearchWrapper>
      </OverflowingCard>

      <Card className="col-span-full overflow-hidden sm:overflow-visible">
        <UserOverviewCalendar hasMenu />
      </Card>
    </OverviewLayout>
  );
};
