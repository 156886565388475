import { Fragment, useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormik } from "formik";

import {
  BookableEntityType,
  FacilityUpdateBookableEntityTypes,
  FacilityWithUtc,
  SportType,
  SportTypeProperties,
} from "../../../../../../../modules/customer/models/Facility";

import { useToaster } from "../../../../../../../hooks/common/useToaster";
import { useAppSelector } from "../../../../../../../hooks/store/store";
import { useFormFieldValidator } from "../../../../../../../modules/customer/hooks/useFormFieldValidator";
import { useFormValidationSchema } from "../../../../../../../modules/customer/hooks/useFormValidationSchema";
import { useRefetchFacilityInformation } from "../../../../../../../modules/customer/hooks/useRefetchFacilityInformation";

import { updateBookableTypes } from "../../../../../../../modules/customer/services/FacilityService";

import { Button } from "../../../../../../../components/Button";
import { TextInput } from "../../../../../../../components/TextInput";
import { SelectInput } from "../../../../../../../components/inputs/SelectInput";
import { FacilitySubmitResetButtons } from "../../../../../../../modules/customer/components/FacilitySubmitResetButtons";

import { findById } from "../../../../../../../utils/helpers";

interface Props {
  faciityId?: string;
  sportTypeId: string;
  sportTypes: SportType[];
}

const initialData = {
  id: "",
  name: "",
  logo: "",
  description: "",
  openHours: [],
  bookableEntityTypes: [],
  address: {
    country: "",
    city: "",
    street: "",
    streetNumber: "",
    postalCode: "",
  },
  email: "",
  phone: "",
  website: "",
  paymentInformation: {
    invoiceEmail: "",
    plusgiro: "",
    bankgiro: "",
    registrationNumber: "",
    costCenter: "",
  },
  amenities: [],
  termsAndConditions: false,
  facilityBookingRules: {
    allowVacantSlotAroundOpenTime: true,
    allowVacantSlotAroundClosingTime: true,
    allowVacantSlotAroundCurrentTime: true,
    numberOfDaysBookingDaysAhead: 14,
    numberOfHoursBeforeBookingCancellation: 6,
    numberOfHoursBeforeSeriesGameReschedule: 6,
    numberOfHoursBeforeActivityCancellation: 6,
    playerCanCancelRecurring: true,
    numberOfHoursAfterBookingDuePaymentRuns: 2,
    onlyMemberships: [],
  },
  communicationSettings: {
    showShortMessageInBookingConfirmationEmail: false,
  },
} satisfies FacilityWithUtc;

export const FacilityTypes = ({ sportTypeId, sportTypes }: Props) => {
  const intl = useIntl();
  const { toastSuccess, toastError } = useToaster();
  const { bookableEntityTypesSchema } = useFormValidationSchema();
  const { facilityUpdatedCallback } = useRefetchFacilityInformation();
  const [resetClicked, setResetClicked] = useState(false);
  const [sportType, setSportType] = useState<SportType>({
    id: "",
    name: "",
    active: true,
    sportTypeProperties: [],
    translationName: "",
  });
  const [facility, setFacility] = useState<FacilityWithUtc>(initialData);
  const { selectedFacility } = useAppSelector(state => state.facilities);

  useEffect(() => {
    setFacility({
      ...selectedFacility,
      bookableEntityTypes: selectedFacility.bookableEntityTypes?.filter(
        ({ sportType }) => sportType?.id == sportTypeId,
      ),
    });
  }, [sportTypes, sportTypeId, selectedFacility]);

  useEffect(() => {
    if (sportTypes?.length) {
      setSportType(findById(sportTypes, sportTypeId));
    }
  }, [sportTypes, sportTypeId]);

  function numberOfCourts(bookableTypeCount: number) {
    const currentBookableTypes = [...(formik.values.bookableEntityTypes || [])];
    const newBookableTypes: BookableEntityType[] = [];

    for (let i = 0; i < bookableTypeCount; i++) {
      let bookableTypeToAdd: BookableEntityType;

      if (currentBookableTypes?.length > i) {
        bookableTypeToAdd = currentBookableTypes[i];
      } else {
        bookableTypeToAdd = {
          id: "",
          name: "",
          active: true,
          translationName: "",
          defaultNumberOfPlayers: 0,
        };
      }
      newBookableTypes.push(bookableTypeToAdd);
    }
    formik.setFieldValue("bookableEntityTypes", newBookableTypes, true);
  }

  function removeCourt(key: number): void {
    const bookableTypes = [...(formik.values.bookableEntityTypes || [])];
    bookableTypes.splice(key, 1);
    formik.setFieldValue("bookableEntityTypes", bookableTypes, true);
  }

  function setCourtValue(key: number, valueName: string, newValue: any) {
    const bookbaleEnityTypes = [...(formik.values.bookableEntityTypes || [])];
    const bEntityType = { ...bookbaleEnityTypes[key] };
    if (valueName == "name") {
      bEntityType.name = newValue;
    } else if (valueName == "sportType") {
      bEntityType.sportType = newValue;
    }

    bookbaleEnityTypes[key] = bEntityType;
    formik.setFieldValue("bookableEntityTypes", [...bookbaleEnityTypes], true);
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: facility,
    validationSchema: bookableEntityTypesSchema,

    onSubmit: async (facility: FacilityWithUtc) =>
      facilityUpdatedCallback(async () => {
        try {
          const facilityModel: FacilityUpdateBookableEntityTypes = {
            bookableTypes: facility.bookableEntityTypes,
          };

          const result = await updateBookableTypes(facility.id, facilityModel);
          if (result.data) {
            toastSuccess.infoSaved();
            setFacility({
              ...result.data,
              bookableEntityTypes: result.data.bookableEntityTypes?.filter(
                ({ sportType }) => sportType?.id == sportTypeId,
              ),
            });
            return true;
          }
        } catch (e) {
          typeof e === "object" &&
          e &&
          "hasValidationErrors" in e &&
          e.hasValidationErrors
            ? toastError.validation()
            : toastError.saveFailed();
        }
      }),
  });

  const { getFormErrorMessage } = useFormFieldValidator(formik);

  const entityTypes = formik.values?.bookableEntityTypes;

  return (
    <form
      className="col-span-3 grid grid-cols-[1fr_1fr_auto] gap-x-6 gap-y-8 lg:grid-cols-[3fr_3fr_2fr] lg:gap-x-8"
      onSubmit={formik.handleSubmit}
      key={resetClicked.toString()}
    >
      {entityTypes?.map((bookableType: BookableEntityType, key) => (
        <Fragment key={key}>
          <div>
            <TextInput
              key={bookableType.id}
              name={`bookableEntityTypes[${key}].name`}
              label={intl.formatMessage({ id: "common.name" })}
              value={formik.values?.bookableEntityTypes?.[key]?.name}
              onBlur={formik.handleBlur}
              onChange={e => {
                formik.handleChange(e);
                setCourtValue(key, "name", e.target.value);
              }}
            />
            {getFormErrorMessage("bookableEntityTypes", "name", key)}
          </div>

          <div>
            <SelectInput
              label="Egenskap"
              translationName="common.attribute"
              value={bookableType.sportType?.sportTypeProperties?.[0]?.id}
              onChange={e => {
                const sportTypeProperties: SportTypeProperties[] = [];
                const selectedSportType = findById(
                  sportType?.sportTypeProperties,
                  e.value,
                );

                if (selectedSportType) {
                  sportTypeProperties.push(selectedSportType);
                }

                const newSportType: SportType = {
                  id: sportType?.id,
                  name: sportType?.name,
                  active: sportType?.active,
                  sportTypeProperties: sportTypeProperties,
                  translationName: sportType?.translationName,
                };
                setCourtValue(key, "sportType", newSportType);
              }}
              options={sportType?.sportTypeProperties?.map(({ name, id }) => ({
                label: name,
                value: id,
              }))}
            />
            {getFormErrorMessage("bookableEntityTypes", "sportType", key)}
          </div>

          <FontAwesomeIcon
            className={`mt-8 text-error ${
              !bookableType.id ? "visible" : "invisible"
            }`}
            onClick={() => removeCourt(key)}
            icon={faXmark}
          />
        </Fragment>
      ))}
      <Button
        className="col-span-1"
        onClick={() =>
          numberOfCourts((formik.values.bookableEntityTypes?.length || 0) + 1)
        }
        translationName="pricings.price-group.create"
        type="add"
      />

      <div className="col-span-3">
        <FacilitySubmitResetButtons
          onReset={() => {
            formik.resetForm();
            setResetClicked(resetClicked => !resetClicked);
          }}
          disabled={!formik.dirty}
        />
      </div>
    </form>
  );
};
