import React from "react";
import { FormattedMessage } from "react-intl";

import { useFormik } from "formik";
import { FormikErrors } from "formik/dist/types";
import { Divider } from "primereact/divider";
import { InputNumber } from "primereact/inputnumber";
import { InputSwitch } from "primereact/inputswitch";
import { RadioButton } from "primereact/radiobutton";

import { ICreateSerieFormValues } from "../../../../models/Series";

interface IProps {
  errors: FormikErrors<ICreateSerieFormValues>;
  values: ICreateSerieFormValues;
  setFieldValue: ReturnType<typeof useFormik>["setFieldValue"];
}

const AdminCreateSerieFormDivisionsStep: React.FC<IProps> = ({
  errors,
  values,
  setFieldValue,
}) => {
  return (
    <div style={{ paddingTop: ".5rem" }}>
      <Divider />

      <h3 style={{ marginTop: "3rem", marginBottom: "1rem" }}>
        <FormattedMessage
          id="series.steps.guide.title.divisions"
          defaultMessage="Divisioner och lag"
        />
      </h3>

      <div
        className="p-field"
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          marginTop: "1.5rem",
          gap: "3rem",
        }}
      >
        <div className="p-field" style={{ gap: "1rem" }}>
          <label>
            <FormattedMessage
              id="series.steps.guide.number-of-divisions"
              defaultMessage="Antal divisioner"
            />
          </label>

          <div style={{ paddingTop: "0.5rem" }}>
            <InputNumber
              name="numberOfDivisions"
              value={values.numberOfDivisions}
              onChange={e => setFieldValue("numberOfDivisions", e.value)}
              useGrouping={false}
            />
            {errors.numberOfDivisions && (
              <small className="p-error p-block">
                {errors.numberOfDivisions}
              </small>
            )}
          </div>
        </div>
        <div className="p-field" style={{ gap: "1rem" }}>
          <label>
            <FormattedMessage
              id="series.steps.guide.number-of-teams-in-division"
              defaultMessage="Antal lag per division"
            />
          </label>
          <div style={{ paddingTop: "0.5rem" }}>
            <InputNumber
              name="numberOfTeamsInDivision"
              value={values.numberOfTeamsInDivision}
              onChange={e => setFieldValue("numberOfTeamsInDivision", e.value)}
              useGrouping={false}
            />
            {errors.numberOfTeamsInDivision && (
              <small className="p-error p-block">
                {errors.numberOfTeamsInDivision}
              </small>
            )}
          </div>
        </div>
      </div>

      <div
        className="p-field"
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          marginTop: "1.5rem",
          gap: "3rem",
        }}
      >
        <div className="p-field" style={{ gap: "1rem" }}>
          <label>
            <FormattedMessage
              id="series.steps.guide.type-of-serie"
              defaultMessage="Typ av serie"
            />
          </label>

          <div style={{ display: "flex", gap: "1rem", paddingTop: "0.5rem" }}>
            <div
              style={{ display: "flex", alignItems: "center", gap: ".5rem" }}
            >
              <label htmlFor="straight">
                <FormattedMessage
                  id="series.steps.guide.straight"
                  defaultMessage="Rak"
                />
              </label>
              <RadioButton
                inputId="straight"
                value="straight"
                name="straight"
                onChange={changeParams =>
                  setFieldValue("typeOfSerie", changeParams.value)
                }
                checked={values.typeOfSerie === "straight"}
              />
            </div>
            <div
              style={{ display: "flex", alignItems: "center", gap: ".5rem" }}
            >
              <label htmlFor="pyramid">
                <FormattedMessage
                  id="series.steps.guide.pyramid"
                  defaultMessage="Pyramid"
                />
              </label>
              <RadioButton
                inputId="pyramid"
                value="pyramid"
                name="pyramid"
                onChange={changeParams =>
                  setFieldValue("typeOfSerie", changeParams.value)
                }
                checked={values.typeOfSerie === "pyramid"}
              />
            </div>
          </div>
        </div>

        <div className="p-field" style={{ gap: "1rem" }}>
          <label>
            <FormattedMessage
              id="series.steps.guide.number-of-teams-moving-up-down"
              defaultMessage="Antal lag som flyttar upp / ner"
            />
          </label>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: "1rem",
              paddingTop: "0.5rem",
            }}
          >
            <InputNumber
              name="numberOfTeamsMovingUp"
              value={values.numberOfTeamsMovingUp}
              onChange={e => setFieldValue("numberOfTeamsMovingUp", e.value)}
              useGrouping={false}
            />

            <InputNumber
              name="numberOfTeamsMovingDown"
              value={values.numberOfTeamsMovingDown}
              onChange={e => setFieldValue("numberOfTeamsMovingDown", e.value)}
              useGrouping={false}
            />
          </div>
          {(errors.numberOfTeamsMovingDown || errors.numberOfTeamsMovingUp) && (
            <small
              className="p-error p-block"
              style={{ alignSelf: "flex-end" }}
            >
              {errors.numberOfTeamsMovingDown
                ? errors.numberOfTeamsMovingDown
                : errors.numberOfTeamsMovingUp}
            </small>
          )}
        </div>
      </div>

      <div
        className="p-field"
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          marginTop: "1.5rem",
          gap: "3rem",
        }}
      >
        <div className="p-field">
          <label>
            <FormattedMessage
              id="series.steps.guide.fee"
              defaultMessage="Kostar det att anmäla sig till serien?"
            />
          </label>

          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              gap: "1rem",
              paddingTop: "0.5rem",
              alignItems: "center",
            }}
          >
            <div>
              <FormattedMessage id="common.no" defaultMessage="Nej" />
            </div>
            <div style={{ minWidth: "3rem" }}>
              <InputSwitch
                key={values.hasPrice.toString()}
                onChange={changeParams =>
                  setFieldValue("hasPrice", changeParams.value)
                }
                name="hasPrice"
                checked={values.hasPrice}
              />
            </div>
            <div>
              <FormattedMessage id="common.yes" defaultMessage="Ja" />
            </div>
          </div>
        </div>
        {values.hasPrice && (
          <>
            <div className="p-field" style={{ gap: "1rem" }}>
              <div style={{ paddingBottom: "0.5rem" }}>
                <FormattedMessage
                  id="series.steps.guide.fee-per-person"
                  defaultMessage="Kostnad per person"
                />
              </div>
              <InputNumber
                name="price"
                mode="decimal"
                minFractionDigits={2}
                maxFractionDigits={2}
                onChange={e => setFieldValue("price", e.value || 0)}
                useGrouping={false}
                value={values.price || 0}
              />
              {errors.price && (
                <small className="p-error p-block">{errors.price}</small>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AdminCreateSerieFormDivisionsStep;
