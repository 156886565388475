import { FormattedMessage } from "react-intl";

export const CalendarRulesReminder = () => (
  <div className="flex justify-end">
    <div className="w-96 bg-gray-100 p-4">
      <strong className="block text-sm">
        <FormattedMessage id="calendar.reminder.title" />
      </strong>
      <p className="text-xs">
        <FormattedMessage id="calendar.reminder.text" />
      </p>
    </div>
  </div>
);
