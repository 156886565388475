import { PolicyPageContent } from "../../../models/content/PolicyPage";

import { anonymousFetchApi } from "../../../services/legacyApiClient";

const apiVersion = "common/v1";

export const getPrivacyPageContent = async (
  lang: string,
): Promise<PolicyPageContent> =>
  (await anonymousFetchApi({
    uri: `${apiVersion}/content/privacypage?lang=${lang}`,
  })) as PolicyPageContent;

export const getUserAgreementPageContent = async (
  lang: string,
): Promise<PolicyPageContent> =>
  (await anonymousFetchApi({
    uri: `${apiVersion}/content/useragreementpage?lang=${lang}`,
  })) as PolicyPageContent;

export const getTermsAndConditionsPageContent = async (
  lang: string,
): Promise<PolicyPageContent> =>
  (await anonymousFetchApi({
    uri: `${apiVersion}/content/termsandconditionspage?lang=${lang}`,
  })) as PolicyPageContent;
