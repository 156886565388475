import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";

import { Membership } from "../../../../../modules/player/models/Membership";
import { User } from "../../../../../modules/player/models/User";

import { adminGetPlayerPath } from "../../../../../helpers/pathHelpers";

import { useDateFormat } from "../../../../../hooks/useDateFormat";

import { getMembership } from "../../../../../modules/player/services/MembershipService";
import { getUsers } from "../../../../../modules/player/services/UserService";

import { AppLoadingSpinner } from "../../../../../components/AppLoadingSpinner";
import { AvatarNameWrapper } from "../../../../../components/AvatarNameWrapper";
import { DataTable } from "../../../../../components/DataTable/DataTable";
import { MembershipStatus } from "../../../../../components/MembershipStatus";

import { luxonDateFormat } from "../../../../../utils/dateFormats";

interface Props {
  membershipId: string;
}

export const MembershipList: React.FC<Props> = ({ membershipId }) => {
  const history = useHistory();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [membership, setMembership] = useState<Membership>(null);
  const [users, setUsers] = useState<User[]>([]);
  const { df } = useDateFormat(membership?.facilityId);

  useEffect(() => {
    const fetchMembership = async () => {
      setLoading(true);
      const m = await getMembership(membershipId);
      setMembership(m);
      const userIds = m?.users?.map(u => u?.userId) || [];
      const u = await getUsers(userIds);
      setUsers(u);
      setLoading(false);
    };

    if (membershipId) {
      fetchMembership();
    }
  }, [membershipId]);

  return (
    <>
      {isLoading && (
        <div className="flex justify-center">
          <AppLoadingSpinner hasContainer={false} />
        </div>
      )}
      {!isLoading && (
        <>
          <h3>
            <FormattedMessage
              id="membership.subscriptions.name-with-count"
              defaultMessage="Prenumeranter {name} ({userCount})"
              values={{
                name: membership?.name,
                userCount: membership?.userCount,
              }}
            />
          </h3>
          <DataTable
            selectionMode="single"
            data={users ?? []}
            onRowClick={e =>
              e.originalEvent.metaKey
                ? window.open(`/#${adminGetPlayerPath(e?.data?.id)}`)
                : history.push(adminGetPlayerPath(e?.data?.id))
            }
            columns={[
              {
                id: "common.name",
                body: (u: User) => (
                  <AvatarNameWrapper
                    image={u?.profileImage}
                    name={u?.displayName}
                    firstName={u?.firstName}
                    lastName={u?.lastName}
                    title={u?.displayName}
                  />
                ),
              },
              {
                id: "common.phone",
                body: (u: User) => <p>{u?.phoneNumber}</p>,
              },
              {
                id: "common.email",
                body: (u: User) => <p>{u?.emailAddress}</p>,
              },
              {
                id: "membership.overview.status",
                body: (u: User) => {
                  const status = membership?.users?.find(
                    m => m?.userId === u?.id,
                  )?.status;

                  return <MembershipStatus status={status} />;
                },
              },
              {
                id: "membership.overview.subscription.date",
                body: (u: User) => {
                  const subscriptionDate = membership?.users?.find(
                    m => m?.userId === u?.id,
                  )?.subscriptionDate;

                  return (
                    <p className="whitespace-nowrap">
                      {df(subscriptionDate, luxonDateFormat)}
                    </p>
                  );
                },
              },
              {
                id: "membership.overview.valid.through",
                body: (u: User) => {
                  const expiryDate = membership?.users?.find(
                    m => m?.userId === u?.id,
                  )?.expiryDate;

                  return (
                    <p className="whitespace-nowrap">
                      {df(expiryDate, luxonDateFormat)}
                    </p>
                  );
                },
              },
              {
                id: "membership.overview.next.payment",
                body: (u: User) => {
                  const nextPaymentDate = membership?.users?.find(
                    m => m?.userId === u?.id,
                  )?.nextPaymentDate;

                  return (
                    <p className="whitespace-nowrap">
                      {df(nextPaymentDate, luxonDateFormat)}
                    </p>
                  );
                },
              },
            ]}
          />
        </>
      )}
    </>
  );
};
