import { DateTime } from "luxon";

import { BuyableType } from "../modules/checkout/models/Payment";
import { User } from "../modules/player/models/User";

import { DateOnly } from "./DateOnly";

export interface TransactionLogs {
  coinsTransactions: CoinTransactionLog[];
  filteredCount: number;
  totalCount: number;
}

export interface TransactionLogsApiResponse
  extends Omit<TransactionLogs, "coinsTransactions"> {
  coinsTransactions: CoinTransactionLogApiResponse[];
}

export interface CoinTransactionLog {
  adminChangesToWallet: boolean;
  buyableType: BuyableType;
  buyableTypeDetails: BuyableTypeDetails;
  createdDate: DateTime;
  creatorUser: Pick<User, "firstName" | "lastName">;
  currentAmount: number;
  currentExpires: DateTime;
  id: string;
  isAmountChanged: boolean;
  isCurrentExpirationDateChanged: boolean;
  logComment: string;
  paymentTransactionType: PaymentTransactionType;
  previousAmount: number;
  previousExpires: DateTime;
  priceIncTax: number;
  walletCurrencyType: WalletCurrencyType;
}

export interface BuyableTypeDetails {
  name: string;
  startDate: DateOnly;
}

export interface CoinTransactionLogApiResponse
  extends Omit<
    CoinTransactionLog,
    "createdDate" | "previousExpires" | "currentExpires" | "buyableTypeDetails"
  > {
  createdDate: string;
  currentExpires: string;
  previousExpires: string;
  buyableTypeDetails: BuyableTypeDetailsApiResponse;
}

export interface BuyableTypeDetailsApiResponse
  extends Omit<BuyableTypeDetails, "startDate"> {
  startDate: string;
}

export enum PaymentTransactionType {
  None = 0,
  Purchase = 1,
  Refund = 2,
  DuePayment = 3,
}

enum WalletCurrencyType {
  NotUsed = -1,
  Coins = 0,
  LockedCoins = 1,
  PunchCard = 2,
}
