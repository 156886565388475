import { useEffect } from "react";

import { getPayment } from "../modules/checkout/services/Payment";

interface Props {
  paymentId: string;
}

export const PendingPayment: React.FC<Props> = ({ paymentId }) => {
  useEffect(() => {
    const init = async () => {
      try {
        const payment = await getPayment(paymentId);
        await renderIframe(payment?.paymentUrl);
      } catch (e) {
        console.log(e);
      }
    };

    if (paymentId) {
      init();
    }
  }, [paymentId]);

  const renderIframe = async (iframeUrl: string) => {
    const wrapperDiv = document.getElementById(
      "swedbank-pay-seamless-view-page-pending",
    );

    // make sure wrapper is empty before rendering the iframe
    if (wrapperDiv.innerHTML !== "") wrapperDiv.innerHTML = "";

    const paymentScript = document.createElement("script");
    paymentScript.src = iframeUrl;
    paymentScript.onload = function () {
      (window as any)?.payex?.hostedView
        .creditCard({ container: "swedbank-pay-seamless-view-page-pending" })
        .open();
    };
    wrapperDiv.appendChild(paymentScript);
  };

  return (
    <div
      id="swedbank-pay-seamless-view-page-pending"
      style={{ minWidth: "300px", minHeight: "320px" }}
    ></div>
  );
};
