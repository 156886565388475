import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router";
import "react-toastify/dist/ReactToastify.css";

import { InteractionStatus } from "@azure/msal-browser";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { useSWRConfig } from "swr";
import { useScript } from "usehooks-ts";

import { useToaster } from "./hooks/common/useToaster";

import {
  getCurrentUser,
  getUserPermissions,
} from "./modules/player/services/UserService";

import { AppLoadingSpinner } from "./components/AppLoadingSpinner";
import { Button } from "./components/Button";
import { Dialog } from "./components/Dialog";
import { Layout } from "./components/Layout";

import { msalInstance } from ".";
import { applicationInsights } from "./applicationInsights";
import { userPermissionsState } from "./recoil/Customer/userPermissionsState";
import { currentUserIdState } from "./recoil/currentUserIdState";
import { isAdminModeState } from "./recoil/isAdminModeState";
import { Routes } from "./routes";

export const AuthedApp = () => {
  const { toastError } = useToaster();
  const [appLoaded, setAppLoaded] = useState(false);
  const isAuthenticated = useIsAuthenticated();
  const { inProgress, accounts } = useMsal();
  const history = useHistory();
  const setUserPermissions = useSetRecoilState(userPermissionsState);
  const { mutate } = useSWRConfig();
  const [currentUserId, setCurrentUserId] = useRecoilState(currentUserIdState);
  const [isAdminMode, setIsAdminMode] = useRecoilState(isAdminModeState);

  useScript(
    "https://app.talkie.se/chat-widget/4XbL7RJTVyP8135247782155PlZ2XUb0.js",
    {
      shouldPreventLoad: !isAdminMode,
    },
  );

  useEffect(() => {
    if (
      inProgress === InteractionStatus.None &&
      isAuthenticated &&
      accounts.length > 0
    ) {
      const fetchUser = async () => {
        try {
          // We have to fetch the user first to import it from B2C in case of a newly created user account.
          const user = await getCurrentUser();
          const userPermissions = await getUserPermissions();

          setUserPermissions(userPermissions?.permissions);

          mutate(["user", user.id], user, false);
          setCurrentUserId(user.id);

          if (!user.isFacilityAdmin && !user.isSuperAdmin) {
            setIsAdminMode(false);
          }

          applicationInsights.setAuthenticatedUserContext(
            user.id,
            undefined,
            true,
          );
        } catch (e) {
          console.error(e);
          toastError.fetchUserFailed();
        } finally {
          setAppLoaded(true);
        }
      };

      fetchUser();
    }
  }, [inProgress, isAuthenticated, history]);

  useEffect(() => {
    const cb = (event: PageTransitionEvent) => {
      if (event.persisted) {
        window.location.reload();
      }
    };

    window.addEventListener("pageshow", cb);

    return () => window.removeEventListener("pageshow", cb);
  }, []);

  if (
    inProgress !== InteractionStatus.None ||
    (accounts.length && !appLoaded)
  ) {
    return (
      <div className="absolute left-0 top-0">
        <AppLoadingSpinner />
      </div>
    );
  }

  // If permissions or user was not fetchable but the app loaded,
  // we should show a message to the user and NOT render the app.
  // accounts.length is only true if the user is authenticated in b2c
  if (accounts.length && appLoaded && !currentUserId) {
    return (
      <Layout>
        <Dialog
          visible
          onHide={() => null}
          closable={false}
          contentClassName="text-center flex justify-center flex-col items-center"
        >
          <h3 className="mb-4">
            <FormattedMessage id="error.login.dialog.title" />
          </h3>
          <Button
            type="primary"
            onClick={() => {
              msalInstance.logoutRedirect({
                postLogoutRedirectUri: "/#/signin",
              });
            }}
          >
            <FormattedMessage id="common.sign-in" />
          </Button>
        </Dialog>
      </Layout>
    );
  }

  return (
    <Layout>
      <Routes isAuthenticated={isAuthenticated} />
    </Layout>
  );
};
