interface Props {
  text?: string;
  className?: string;
}

export const LogoSmall = ({ text = "Court22", className = "" }: Props) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 168"
      aria-label={text}
    >
      <g clipPath="url(#clip0_11430_34849)">
        <path
          d="M138.09 134.93H73.88C68.12 134.93 62.73 133.86 57.71 131.72C52.69 129.58 48.27 126.65 44.47 122.94C40.66 119.22 37.68 114.81 35.55 109.7C33.41 104.59 32.34 99.06 32.34 93.11V73.87C32.34 68.66 33.27 63.55 35.13 58.54C36.99 53.52 39.68 49.06 43.21 45.16C46.74 41.26 51.06 38.14 56.17 35.82C61.28 33.49 67.18 32.34 73.87 32.34H161.32L193.55 0H62.45C53.9 0 45.81 1.63 38.2 4.88C30.58 8.13 23.93 12.59 18.27 18.26C12.6 23.93 8.14 30.57 4.89 38.19C1.63 45.81 0 53.9 0 62.45V104.82C0 113.55 1.63 121.69 4.88 129.21C8.13 136.74 12.59 143.33 18.26 149C23.93 154.67 30.57 159.13 38.19 162.38C45.81 165.63 53.89 167.26 62.44 167.26H138.04L138.09 134.92V134.93Z"
          className="fill-primary-dark"
        />
        <path
          d="M401.97 0H235.49L179.07 56.42V167.26H401.97C427.02 167.26 447.32 146.95 447.32 121.91V45.35C447.32 20.31 427.02 0 401.97 0ZM302.08 116.04C302.24 116.04 302.35 116.08 302.44 116.15L285.47 133.12H249.86C249.39 133.12 249.16 132.89 249.16 132.42V116.6C249.16 116.41 249.25 116.23 249.44 116.04L284.02 68.58C284.67 67.74 285.21 66.64 285.63 65.29C286.05 63.94 286.26 62.75 286.26 61.72C286.26 59.01 285.28 56.52 283.32 54.23C281.36 51.94 278.89 50.8 275.9 50.8C272.91 50.8 270.56 51.9 268.55 54.09C266.54 56.28 265.54 59.06 265.54 62.42V69.28C265.54 69.75 265.31 69.98 264.84 69.98H249.16C248.79 69.98 248.6 69.75 248.6 69.28V62.42C248.6 57.19 249.88 52.43 252.45 48.14C255.02 43.85 258.38 40.44 262.53 37.92C266.68 35.4 271.14 34.14 275.9 34.14C280.94 34.14 285.51 35.42 289.62 37.99C293.73 40.56 297.02 43.94 299.49 48.14C301.96 52.34 303.2 56.87 303.2 61.72C303.2 64.8 302.66 67.93 301.59 71.1C300.52 74.27 299.05 77.07 297.18 79.5L270.58 116.04H302.08ZM376.68 116.04C376.84 116.04 376.95 116.08 377.04 116.15L360.07 133.12H324.46C323.99 133.12 323.76 132.89 323.76 132.42V116.6C323.76 116.41 323.85 116.23 324.04 116.04L358.62 68.58C359.27 67.74 359.81 66.64 360.23 65.29C360.65 63.94 360.86 62.75 360.86 61.72C360.86 59.01 359.88 56.52 357.92 54.23C355.96 51.94 353.49 50.8 350.5 50.8C347.51 50.8 345.16 51.9 343.15 54.09C341.14 56.28 340.14 59.06 340.14 62.42V69.28C340.14 69.75 339.91 69.98 339.44 69.98H323.76C323.39 69.98 323.2 69.75 323.2 69.28V62.42C323.2 57.19 324.48 52.43 327.05 48.14C329.62 43.85 332.98 40.44 337.13 37.92C341.28 35.4 345.74 34.14 350.5 34.14C355.54 34.14 360.11 35.42 364.22 37.99C368.33 40.56 371.62 43.94 374.09 48.14C376.56 52.34 377.8 56.87 377.8 61.72C377.8 64.8 377.26 67.93 376.19 71.1C375.12 74.27 373.65 77.07 371.78 79.5L345.18 116.04H376.68Z"
          className="fill-primary-dark"
        />
      </g>
      <defs>
        <clipPath id="clip0_11430_34849">
          <rect
            width="447.32"
            height="167.27"
            fill="white"
            className="fill-purewhite"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
