import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { faCircleXmark } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";

import type { DateOnly } from "../models/DateOnly";
import type { CardBrand } from "../models/backupPaymentMethod";

import { creditCardIconHelper } from "../helpers/creditCardIconHelper";

import { ConfirmationDialog } from "./ConfirmationDialog";

type Props = {
  cardBrand: keyof typeof CardBrand;
  pan: string;
  expiryDate: DateOnly;
} & (
  | {
      removeable: true;
      onRemove: () => void;
    }
  | { removeable?: false }
);

export const CreditCardCard = ({
  cardBrand,
  pan,
  expiryDate,
  ...props
}: Props) => {
  const intl = useIntl();

  const [showRemoveConfirmationDialog, setShowRemoveConfirmationDialog] =
    useState(false);

  return (
    <div
      className={clsx(
        "relative flex max-w-[300px] flex-1 items-center gap-2 rounded border border-gray-50 bg-gradient-to-b from-purewhite to-white p-4 shadow",
        props.removeable && "pr-9",
      )}
    >
      <FontAwesomeIcon
        className="flex-shrink-0 text-5xl"
        icon={creditCardIconHelper(cardBrand)}
        fixedWidth
      />
      <div className="flex min-w-0 flex-col">
        <p className="truncate text-sm">{pan}</p>
        <p className="truncate text-xs text-gray-700">
          <FormattedMessage
            id="card-details.card-valid-thru"
            values={{
              expiryDate: expiryDate.toDateTime().toFormat("MM/yy"),
            }}
          />
        </p>
      </div>

      {props.removeable && (
        <>
          <button
            className="absolute right-3 top-3"
            type="button"
            onClick={() => {
              setShowRemoveConfirmationDialog(true);
            }}
          >
            <FontAwesomeIcon icon={faCircleXmark} />
          </button>

          {showRemoveConfirmationDialog && (
            <ConfirmationDialog
              visible
              onHide={() => setShowRemoveConfirmationDialog(false)}
              onCancel={() => setShowRemoveConfirmationDialog(false)}
              confirmButtonType="danger"
              title={intl.formatMessage({
                id: "common.are-you-sure-remove-card",
              })}
              onSubmit={() => {
                props.onRemove();
                setShowRemoveConfirmationDialog(false);
              }}
            >
              <div className="flex grow justify-center text-start">
                <CreditCardCard
                  cardBrand={cardBrand}
                  pan={pan}
                  expiryDate={expiryDate}
                />
              </div>
            </ConfirmationDialog>
          )}
        </>
      )}
    </div>
  );
};
