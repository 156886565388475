import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { DateTime } from "luxon";

import {
  Membership,
  RenewalType,
} from "../../../../../modules/player/models/Membership";

import { useDateFormat } from "../../../../../hooks/useDateFormat";

import { ConfirmationDialog } from "../../../../../components/ConfirmationDialog";
import { Dialog } from "../../../../../components/Dialog";
import { AlternativeBody } from "./components/AlternativeBody";
import { AlternativeBox } from "./components/AlternativeBox";
import { PaymentForm } from "./components/PaymentForm";

import { luxonDateFormat } from "../../../../../utils/dateFormats";

interface Props {
  membership: Membership;
  membershipsNeeded: Membership[];
  currentUserId: string;
  handleUnsubscribe: any;
}

export const MembershipOption: React.FC<Props> = ({
  membership,
  membershipsNeeded,
  currentUserId,
  handleUnsubscribe,
}) => {
  const intl = useIntl();
  const { df } = useDateFormat(membership.facilityId);
  const currentUser = membership?.users?.find(u => u?.userId === currentUserId);
  const activePriceId = currentUser?.membershipPrice?.id;
  const yearlyPriceId = membership?.membershipPrices?.find(
    p => p?.renewal?.renewalType === RenewalType.Annually,
  )?.id;
  const monthlyPriceId = membership?.membershipPrices?.find(
    p => p?.renewal?.renewalType === RenewalType.Monthly,
  )?.id;
  const [priceId, setPriceId] = useState<string>(
    activePriceId ?? yearlyPriceId ?? monthlyPriceId,
  );
  const [showPayment, setShowPayment] = useState<boolean>(false);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const active = currentUser?.expiryDate > DateTime.utc();

  return (
    <div className="flex w-[230px] flex-col">
      <AlternativeBox
        active={active}
        onSubmit={() => setShowPayment(true)}
        membership={membership}
      >
        <AlternativeBody
          membershipsNeeded={membershipsNeeded}
          active={active}
          priceId={priceId}
          membership={membership}
          setPriceId={setPriceId}
        />
        <Dialog visible={showPayment} onHide={() => setShowPayment(false)}>
          {showPayment && (
            <PaymentForm
              setVisible={setShowPayment}
              priceId={priceId}
              membership={membership}
            />
          )}
        </Dialog>
        {currentUser?.isSubscribed && (
          <div className="mt-8 flex justify-center">
            <div
              className="cursor-pointer text-red-500"
              onClick={() => setShowConfirmation(true)}
            >
              <FormattedMessage id="common.cancel" />
            </div>
          </div>
        )}
        {active && currentUser && (
          <small className="block text-center">
            <FormattedMessage
              id="membership.expiry.info"
              values={{
                expiryDate: df(currentUser?.expiryDate, luxonDateFormat),
              }}
            />
          </small>
        )}
      </AlternativeBox>

      {currentUser?.isSubscribed && showConfirmation && (
        <ConfirmationDialog
          visible={true}
          onHide={() => setShowConfirmation(false)}
          confirmButtonType="danger"
          onCancel={() => setShowConfirmation(false)}
          onSubmit={() => handleUnsubscribe(membership?.id, activePriceId)}
          title={intl.formatMessage({
            id: "membership.cancellation.title",
          })}
          text={intl.formatMessage(
            {
              id: "membership.cancellation.description",
            },
            {
              br: <br />,
              expiryDate: df(currentUser?.expiryDate, luxonDateFormat),
            },
          )}
        />
      )}
    </div>
  );
};
