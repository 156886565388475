import { DateTime } from "luxon";

import { CoinsWallet, CoinsWalletDTO, CoinsWalletEntry } from "../models/coins";

import { get, post } from "./apiClient";

const API_VERSION = "v1";
const API_SERVICE = "/coins";
const API_ENDPOINT = `/${API_VERSION}${API_SERVICE}`;

export const getOrderPaymentCoinsWallet = async (
  orderPaymentId: string,
): Promise<CoinsWallet> => {
  const response = await get<CoinsWalletDTO>({
    endpoint: `${API_ENDPOINT}/payment/${orderPaymentId}`,
  });

  return {
    ...response,
    entries: response.entries.map(entry => ({
      ...entry,
      expiresOn: DateTime.fromISO(entry.expiresOn, { setZone: true }),
    })),
  };
};

export const payOrderPaymentWithCoinsWalletEntry = async (
  orderPaymentId: string,
  entryId: CoinsWalletEntry["id"],
) => {
  return await post({
    endpoint: `${API_ENDPOINT}/payment/${orderPaymentId}/pay/${entryId}`,
    body: {},
    parseDataAs: "none",
  });
};
