import { FormattedMessage } from "react-intl";

import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

import { breakpoints } from "../appConstants/common";

import { Tabs } from "../models/Tabs";

interface Props {
  tabs: Tabs[];
  active: number;
  onClick: (id: number) => void;
}

const TabsContainer = styled.div`
  background: var(--gray-200);
  border-radius: var(--border-radius);
  overflow: auto;
  padding: 5px;
`;

const TabWrapper = styled.div`
  gap: 0.5rem;
  height: 100%;
  width: 100%;
  border-radius: var(--border-radius);
  transition: all 150ms ease-in-out;
`;

const Tab = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  padding: 0.3125rem;
  border-radius: var(--border-radius);
  transition: all 150ms ease-in-out;

  @media (min-width: ${breakpoints.XL}) {
    padding: 0.3125rem 1.375rem;
  }

  &:hover,
  &:active {
    color: var(--primary-dark);
    background: var(--gray-150);
    transition: all 150ms ease-in-out;
  }

  div {
    width: auto;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

const ActiveTab = styled(Tab)`
  background: var(--light) !important;
  box-shadow: var(--default-shadow);
  padding: 0.3125rem;

  > div {
    color: black;
  }

  @media (min-width: ${breakpoints.XL}) {
    padding: 0.3125rem 1.375rem;
  }
`;

const TabName = styled.div`
  //display: none;
  color: var(--gray-500);
  @media (min-width: ${breakpoints.XL}) {
    display: block;
    color: var(--gray-500);
  }
`;

const TabIcon = styled(FontAwesomeIcon)`
  display: block;
  @media (min-width: ${breakpoints.XL}) {
    display: none;
  }
`;

export const BlockTabs = ({ tabs, active, onClick }: Props) => (
  <TabsContainer className="flex flex-col items-start sm:flex-row sm:items-center">
    {tabs.map(({ id, translationId, name, icon }, key) => {
      const Component = active === id ? ActiveTab : Tab;

      return (
        <TabWrapper key={key} id={`tab-${id}`} className="flex items-start">
          <Component onClick={() => onClick(id)}>
            <TabIcon icon={icon as IconProp} />
            <TabName>
              {translationId ? (
                <FormattedMessage id={translationId} defaultMessage={name} />
              ) : (
                name
              )}
            </TabName>
          </Component>
        </TabWrapper>
      );
    })}
  </TabsContainer>
);
