import { useIntl } from "react-intl";
import Select, { components } from "react-select";

import { faCheck, faGlobe } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { FormikErrors, FormikValues } from "formik";

import { useCountryCodes } from "../../../../../../hooks/swr/useCountryCodes";

interface Props {
  value: string;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean,
  ) => Promise<void> | Promise<FormikErrors<FormikValues>>;
}

export const InfoIconCountryDropdown = ({ value, setFieldValue }: Props) => {
  const intl = useIntl();

  const { countriesData } = useCountryCodes();

  const countryOptions = countriesData?.map(({ countryCode }) => ({
    value: countryCode,
    label: intl.formatMessage({
      id: `common.country.${countryCode.toLowerCase()}`,
    }),
  }));

  return (
    <div className={clsx("flex items-center", value === "" && "text-red-600")}>
      <div className="mr-4 flex text-3xl">
        <FontAwesomeIcon
          icon={faGlobe}
          className={value === "" ? "text-red-600" : "text-primary"}
        />
      </div>

      <div className="flex flex-col">
        <h5 className={clsx(value === "" && "text-red-600")}>
          {intl.formatMessage({ id: "common.address.country" })}
        </h5>

        <Select<{ value: string; label: string }>
          unstyled
          options={countryOptions}
          onChange={option => {
            setFieldValue("country", option?.value);
          }}
          classNames={{
            menu: () =>
              clsx(
                "right-0 mt-1 w-36 rounded border border-white bg-purewhite shadow-md",
              ),
            control: props =>
              clsx(
                "min-h-0 cursor-pointer",
                props.selectProps.menuIsOpen
                  ? "text-primary"
                  : "text-pureblack",
                value === "" && "text-red-600",
                "hover:text-primary",
              ),
            singleValue: () => clsx("min-h-0 cursor-pointer"),
            valueContainer: props =>
              clsx(
                "pr-2",
                !props.selectProps.inputValue && "caret-transparent",
              ),
          }}
          value={
            value !== ""
              ? countryOptions?.find(object => object.value === value)
              : {
                  label: intl.formatMessage({
                    id: "validation.required.country",
                  }),
                  value: "",
                }
          }
          components={{
            Option: props => (
              <components.Option
                {...props}
                className={clsx(
                  "flex cursor-pointer items-center justify-between gap-4 border-b border-white p-3 hover:bg-blue-50 hover:text-primary",
                  props.isSelected && "!bg-blue-50 !text-primary",
                  props.selectProps.menuIsOpen && "text-pureblack",
                )}
              >
                {props.data.label}
                {props.isSelected && (
                  <FontAwesomeIcon icon={faCheck} className="text-primary" />
                )}
              </components.Option>
            ),
          }}
        />
      </div>
    </div>
  );
};
