import { useCallback, useEffect, useMemo, useState } from "react";

import { DateTime } from "luxon";

import { DateOnly } from "../../models/DateOnly";
import { IScheduleResponse } from "../../modules/checkout/models/Calendar";

const MINUTES_IN_HOUR = 60;
export const HOURS_TO_DISPLAY = 7;

const now = DateTime.utc();

export const useCalendarDateChanger = (
  selectedDate: DateOnly,
  schedule: IScheduleResponse,
) => {
  const [startIndex, setStartIndex] = useState(0);

  const isToday = useMemo(
    () => now.hasSame(selectedDate.toDateTime(), "day"),
    [selectedDate],
  );

  const slotsPerHour = useMemo(
    () => MINUTES_IN_HOUR / schedule?.objectType.defaultSlotLength,
    [schedule?.objectType.defaultSlotLength],
  );

  const numberOfSlotsToDisplay = HOURS_TO_DISPLAY * slotsPerHour;

  const currentHourSlotIndex = useMemo(() => {
    const index = schedule?.slots?.findIndex(
      slot => slot.startTime.hour === now.hour,
    );

    if (index === -1) {
      return 0;
    }

    return index;
  }, [schedule?.slots]);

  const prevEnabled = isToday
    ? startIndex > currentHourSlotIndex
    : startIndex > 0;

  const nextEnabled =
    schedule?.slots?.length > startIndex + numberOfSlotsToDisplay;

  const handleDecreaseHours = useCallback(
    (hours: number) => {
      if (!prevEnabled) {
        return;
      }

      // Check if we have enough slots to display, otherwise decrease the amount of hours
      // This can happen if we have an odd amount of slots, increase the slots til the end and then try to decrease by 2 hours every time.
      while (startIndex - slotsPerHour * hours < 0) {
        hours -= 1;
      }

      setStartIndex(startIndex => startIndex - slotsPerHour * hours);
    },
    [prevEnabled, slotsPerHour, startIndex],
  );

  const handleIncreaseHours = useCallback(
    (hours: number) => {
      if (!nextEnabled) {
        return;
      }

      // Check if we have enough slots to display, otherwise decrease the amount of hours
      // This can happen if we have an odd amount of slots and try to increase by 2 hours every time.
      while (
        startIndex + numberOfSlotsToDisplay + slotsPerHour * hours >
        schedule?.slots?.length
      ) {
        hours -= 1;
      }

      setStartIndex(startIndex => startIndex + slotsPerHour * hours);
    },
    [nextEnabled, schedule?.slots?.length, slotsPerHour, startIndex],
  );

  useEffect(() => {
    if (!schedule?.slots) {
      return;
    }

    if (!isToday) {
      return;
    }

    // If we dont have enough slots to display from the current hour slot,
    // then we need to decrease the start index to display the last 7 slots
    if (
      currentHourSlotIndex + numberOfSlotsToDisplay >=
      schedule?.slots?.length
    ) {
      setStartIndex(schedule?.slots?.length - numberOfSlotsToDisplay);
    } else {
      setStartIndex(currentHourSlotIndex);
    }
  }, [schedule, isToday, currentHourSlotIndex, slotsPerHour]);

  // Reset the start index when the date changes
  useEffect(() => {
    if (isToday) {
      return;
    }

    setStartIndex(0);
  }, [currentHourSlotIndex, isToday, selectedDate]);

  return {
    handleDecreaseHours,
    handleIncreaseHours,
    startIndex,
    prevEnabled,
    nextEnabled,
  };
};
