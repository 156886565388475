import { useIntl } from "react-intl";

import {
  faAddressCard,
  faEnvelope,
  faKeyboard,
  faVenusMars,
} from "@fortawesome/pro-solid-svg-icons";
import { FormikErrors, FormikValues } from "formik";

import { User } from "../../../../../modules/player/models/User";

import { IconInfoBox } from "../../../../../components/IconInfoBox";
import { InfoIconCountryDropdown } from "./components/InfoIconCountryDropdown";

interface Props {
  user: User;
  isMember: boolean;
  country: string;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean,
  ) => Promise<void> | Promise<FormikErrors<FormikValues>>;
}

const GENDER_LABELS = {
  0: "profile-settings.gender.male",
  1: "profile-settings.gender.female",
  2: "profile-settings.gender.none",
};

export const AdminPlayerInfoIcons: React.FC<Props> = ({
  user,
  isMember,
  country,
  setFieldValue,
}) => {
  const { formatMessage } = useIntl();

  return (
    <div className="flex flex-wrap gap-x-16 gap-y-8 pt-4">
      <IconInfoBox
        label={formatMessage({ id: "common.email" })}
        value={user?.emailAddress || ""}
        icon={faEnvelope}
      />
      <IconInfoBox
        label={formatMessage({ id: "common.pincode" })}
        value={user?.pinCode || ""}
        icon={faKeyboard}
      />

      <IconInfoBox
        label={formatMessage({ id: "common.member" })}
        value={formatMessage({
          id: isMember ? "common.yes" : "common.no",
        })}
        icon={faAddressCard}
      />

      <IconInfoBox
        label={formatMessage({ id: "common.gender" })}
        value={formatMessage({
          id: `${GENDER_LABELS[user?.gender ?? 2]}`,
        })}
        icon={faVenusMars}
      />

      <InfoIconCountryDropdown value={country} setFieldValue={setFieldValue} />
    </div>
  );
};
