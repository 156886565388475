import { atom } from "recoil";

import { IBooking } from "../../modules/checkout/models/Booking";
import { FacilityWithUtc } from "../../modules/customer/models/Facility";

export const facilityDataState = atom<FacilityWithUtc>({
  key: "facilityDataState",
  default: null,
});

export const bookingDataState = atom<IBooking>({
  key: "bookingDataState",
  default: null,
});

export const isRescheduleState = atom<boolean>({
  key: "isRescheduleState",
  default: false,
});
