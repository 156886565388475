import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

import { BuyableType } from "../../../checkout/models/Payment";
import { User } from "../../../player/models/User";
import {
  SeasonStatus,
  Series,
  SeriesPlayer,
  SeriesTeam,
} from "../../models/Series";

import { useToaster } from "../../../../hooks/common/useToaster";

import { cancelActivity } from "../../services/Activities";
import { switchTeamCaptain } from "../../services/Serie";

import { Button } from "../../../../components/Button";
import { Dialog } from "../../../../components/Dialog";
import { ProgressSpinner } from "../../../../components/ProgressSpinner";

import { ReplaceTeamMember } from "./ReplaceTeamMember";
import { TeamMemberItem } from "./TeamMemberItem";

interface IProps {
  visible: boolean;
  series: Series;
  team: SeriesTeam;
  onHide: () => void;
  refresh: () => void;
  handleMarkAsPaid: (user: User) => Promise<void>;
}

export const HandleSerieTeamDialog: React.FC<IProps> = ({
  visible,
  series,
  team,
  onHide,
  refresh,
  handleMarkAsPaid,
}) => {
  const toaster = useToaster();

  const [showConfirmRemove, setShowConfirmRemove] = useState<boolean>();
  const [showConfirmMarkAsPaid, setShowConfirmMarkAsPaid] = useState<boolean>();
  const [showReplace, setShowReplace] = useState<boolean>();
  const [selectedTeamMember, setSelectedTeamMember] = useState<SeriesPlayer>();
  const [loadingChange, setLoadingChange] = useState<boolean>(false);

  const handleRemoveAndRefundUser = async () => {
    try {
      setLoadingChange(true);
      await cancelActivity(
        series?.id,
        BuyableType.Series,
        team?.teamMembers?.[0]?.id,
      );
    } catch {
      toaster.toastError.userRemovedFromActivityFailed();
    } finally {
      setLoadingChange(false);
      onHide?.();
      refresh?.();
    }
  };

  const handleCaptainSwitch = async () => {
    try {
      setLoadingChange(true);
      await switchTeamCaptain(series?.id, series?.facilityId, team?.id);
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingChange(false);
      refresh?.();
      onHide?.();
    }
  };

  const markAsPaidClicked = async (player: SeriesPlayer) => {
    try {
      setLoadingChange(true);
      await handleMarkAsPaid(player);
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingChange(false);
      refresh?.();
      onHide?.();
    }
  };

  const onCompleteReplaced = () => {
    refresh?.();
    onHide?.();
  };

  useEffect(() => {
    if (!visible) {
      setShowConfirmRemove(false);
      setShowConfirmMarkAsPaid(false);
      setShowReplace(false);
      setSelectedTeamMember(null);
    }
  }, [visible]);

  const showMenu = !showConfirmRemove && !showReplace && !showConfirmMarkAsPaid;
  const isClosed = series?.seasonStatus === SeasonStatus.Closed;

  if (!team) return null;
  if (loadingChange)
    return (
      <Dialog visible={visible} onHide={onHide}>
        <ProgressSpinner />
      </Dialog>
    );
  return (
    <Dialog visible={visible} onHide={onHide}>
      {showConfirmRemove && (
        <>
          <h1 className="mb-4 text-2xl">
            <FormattedMessage id="common.remove" />
          </h1>
          <p className="mb-4 text-md">
            <FormattedMessage id="series.division.team.remove-confirmation-message" />
          </p>
          <div className="flex justify-end gap-2">
            <Button type="danger" onClick={handleRemoveAndRefundUser}>
              <FormattedMessage id="common.yes" />
            </Button>
            <Button onClick={() => setShowConfirmRemove(false)}>
              <FormattedMessage id="common.no" />
            </Button>
          </div>
        </>
      )}

      {showConfirmMarkAsPaid && (
        <>
          <h1 className="mb-4 text-2xl">
            <FormattedMessage id="admin.markAsPaid.confirm.title" />
          </h1>
          <p className="mb-4 text-md">
            <FormattedMessage
              id="admin.markAsPaid.confirm.description"
              values={{
                Bold: chunk => <span className="font-bold">{chunk}</span>,
                name: selectedTeamMember?.displayName,
              }}
            />
          </p>
          <div className="flex justify-end gap-2">
            <Button
              type="danger"
              onClick={() => markAsPaidClicked(selectedTeamMember)}
            >
              <FormattedMessage id="common.yes" />
            </Button>
            <Button onClick={() => setShowConfirmMarkAsPaid(false)}>
              <FormattedMessage id="common.no" />
            </Button>
          </div>
        </>
      )}

      {showReplace && (
        <ReplaceTeamMember
          seriesId={series?.id}
          facilityId={series?.facilityId}
          teamId={team?.id}
          playerOut={selectedTeamMember}
          onCancel={() => {
            setSelectedTeamMember(null);
            setShowReplace(false);
          }}
          onComplete={onCompleteReplaced}
        />
      )}

      {showMenu && (
        <div className="flex flex-col gap-4">
          <h1 className="text-2xl">
            <FormattedMessage id="series.manage.team" />
          </h1>

          <div className="flex flex-col gap-2 divide-y-2">
            {team?.teamMembers?.map(m => (
              <TeamMemberItem
                key={m?.id}
                isLocked={isClosed}
                player={m}
                onMakeTeamCaptain={handleCaptainSwitch}
                onSwitchPlayer={() => {
                  setSelectedTeamMember(m);
                  setShowReplace(true);
                }}
                onMarkAsPaid={() => {
                  setSelectedTeamMember(m);
                  setShowConfirmMarkAsPaid(true);
                }}
              />
            ))}
          </div>

          <Button
            disabled={isClosed}
            onClick={() => setShowConfirmRemove(true)}
          >
            <FormattedMessage id="common.remove" />
          </Button>
        </div>
      )}
    </Dialog>
  );
};
