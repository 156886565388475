import svgIconSrc from "../assets/graphs/app-loading.svg";

interface Props {
  hasContainer?: boolean;
}

export const AppLoadingSpinner = ({ hasContainer = true }: Props) => {
  if (!hasContainer) {
    return (
      <img
        className="size-12 sm:size-16"
        src={svgIconSrc}
        alt="App loader icon"
      />
    );
  }

  return (
    <div className="flex h-screen w-screen items-center justify-center">
      <img
        className="size-12 sm:size-16"
        src={svgIconSrc}
        alt="App loader icon"
      />
    </div>
  );
};
