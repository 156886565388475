import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import {
  faCalendarDay,
  faCoins,
  faPenAlt,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormik } from "formik";
import styled from "styled-components";

import { BuyableType } from "../../../checkout/models/Payment";
import { User } from "../../../player/models/User";
import { IActivity } from "../../models/Activity";
import { JoinActivityFormValues } from "../../models/Series";

import { activityIsFull } from "../../../../helpers/activityHelper";

import { useToaster } from "../../../../hooks/common/useToaster";
import { useCurrentUser } from "../../../../hooks/swr/useCurrentUser";
import { useCurrencyFormat } from "../../../../hooks/useCurrencyFormat";
import { useDateFormat } from "../../../../hooks/useDateFormat";
import { useJoinActivity } from "../../hooks/useJoinActivity";

import { registerToGeneralActivity } from "../../services/GeneralActivities";
import { signUp } from "../../services/Serie";

import { Button } from "../../../../components/Button";
import { LogoWithFallback } from "../../../../components/LogoWithFallback";
import { ProfileImageWithFallback } from "../../../../components/ProfileImageWithFallback";
import { UserSearch } from "../../../../components/UserSearch";

import {
  luxonDateFormat,
  luxonDateTimeFormat,
} from "../../../../utils/dateFormats";

interface IProps {
  activity: IActivity;
}

const ActivityHeading = styled.div`
  margin: 0 0 1rem 0;
`;

const ProfileImage = styled.div`
  margin: 0 0 1rem 0;
`;

const ActivityInformation = styled.div`
  display: grid;
  width: 24ch;
`;

const ActivityInfoTitle = styled.span`
  font-weight: var(--bold);
`;

const DescriptionInfo = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  margin: 1rem 0 1rem 0;
`;

const JoinActivityForm = ({ activity }: IProps) => {
  const intl = useIntl();
  const { toastError } = useToaster();
  const { currentUser } = useCurrentUser();
  const { df } = useDateFormat(activity.facilityId);
  const [participants, setParticipants] = useState<User[]>([currentUser]);
  const isFull = activityIsFull(activity);
  const { cf } = useCurrencyFormat(activity?.price?.currencyCode);
  const { handlePayActivity } = useJoinActivity();

  const dateDisplayFormat =
    activity?.buyableType === BuyableType.Series
      ? luxonDateFormat
      : luxonDateTimeFormat;

  const formik = useFormik<JoinActivityFormValues>({
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      activityId: activity.id,
      team: participants.map(u => u.id),
      teamCaptainId: currentUser.id,
    },
    onSubmit: async data => {
      try {
        if (activity.buyableType === BuyableType.Series) {
          await signUp(data.activityId, data);
        } else {
          await registerToGeneralActivity(data.activityId, currentUser?.id);
        }

        handlePayActivity(activity);
      } catch (err) {
        console.error(err);
        toastError.joinSerieFailed();
      }
    },
  });

  const isAlreadyParticipant = activity?.participants?.some(
    user => user.id === currentUser.id,
  );

  const handleUserSearchChange = (users: User[]) => {
    setParticipants(users);
    formik.setFieldValue(
      "team",
      users.map(u => u.id),
    );
  };

  const getErrorMessage = <T,>(data: T | T[]) => {
    if (typeof data === "string") {
      return data;
    }

    return data[0];
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <ActivityHeading>
        {isFull ? (
          <>
            <h3>{activity.name}</h3>

            <FormattedMessage id="activity.join.activity-full" />
          </>
        ) : (
          <div>
            <h3>
              <FormattedMessage
                id="activity.join.title"
                values={{
                  activity: activity.name,
                  br: <br />,
                  displayName: currentUser.displayName,
                }}
              />
            </h3>
          </div>
        )}
      </ActivityHeading>

      <div>
        {!isFull && activity.buyableType == BuyableType.Series && (
          <UserSearch
            facilityId={null}
            removeFromInitial
            name="team"
            title={intl.formatMessage({
              id: "activity.join.user-search-title",
            })}
            onChange={handleUserSearchChange}
            onBlur={formik.handleBlur}
            isDisabled={isAlreadyParticipant}
            isSearchDropdownDisabled={formik.values.team.length > 1}
            errorText={
              formik.touched?.team && formik.errors?.team
                ? getErrorMessage(formik.errors?.team)
                : null
            }
            defaultValues={[currentUser]}
          />
        )}

        {!isFull && activity.buyableType === BuyableType.Event && (
          <ProfileImage>
            <ProfileImageWithFallback
              src={currentUser?.profileImage}
              firstName={currentUser?.firstName}
              lastName={currentUser?.lastName}
            />
          </ProfileImage>
        )}

        <div
          style={{
            display: "flex",
            gap: ".5rem",
            alignItems: "center",
            marginBottom: ".5rem",
          }}
        >
          <div style={{ display: "flex", gap: ".3rem" }}>
            <LogoWithFallback
              width="30px"
              height="30px"
              src={activity?.facilityLogo}
            />
          </div>
          <h4>{activity.facilityName}</h4>
        </div>

        {activity?.description && (
          <DescriptionInfo>
            <div>
              <FontAwesomeIcon icon={faPenAlt} />
              <ActivityInfoTitle>
                <FormattedMessage id="common.description" />
              </ActivityInfoTitle>
              <div>{activity?.description}</div>
            </div>
          </DescriptionInfo>
        )}

        <ActivityInformation>
          <div
            style={{
              display: "flex",
              gap: ".5rem",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", gap: ".3rem" }}>
              <FontAwesomeIcon icon={faCalendarDay} />
              <ActivityInfoTitle>
                <FormattedMessage id="common.start-time" />:
              </ActivityInfoTitle>
            </div>

            {df(activity.startTime, dateDisplayFormat)}
          </div>
          {activity.endTime != null && (
            <div
              style={{
                display: "flex",
                gap: ".5rem",
                justifyContent: "space-between",
              }}
            >
              <div style={{ display: "flex", gap: ".3rem" }}>
                <FontAwesomeIcon icon={faCalendarDay} />
                <ActivityInfoTitle>
                  <FormattedMessage id="common.end-time" />:
                </ActivityInfoTitle>
              </div>
              {df(activity.endTime, dateDisplayFormat)}
            </div>
          )}

          <div
            style={{
              display: "flex",
              gap: ".5rem",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", gap: ".3rem" }}>
              <FontAwesomeIcon icon={faCoins} />

              <ActivityInfoTitle style={{ marginRight: ".5rem" }}>
                <FormattedMessage id="common.registration-fee.label" />
              </ActivityInfoTitle>
            </div>

            <FormattedMessage
              id="common.amount-total"
              values={{
                amount: cf(
                  activity.discountPrice
                    ? activity.discountPrice.valueInclTax *
                        formik.initialValues.team.length ?? 0
                    : activity.price.valueInclTax *
                        formik.initialValues.team.length ?? 0,
                ),
              }}
            />
          </div>

          {!!activity.registrationOpenTo && activity.openForRegistration ? (
            <div
              style={{
                display: "flex",
                gap: ".5rem",
                justifyContent: "space-between",
                whiteSpace: "nowrap",
              }}
            >
              <div style={{ display: "flex", gap: ".3rem" }}>
                <FontAwesomeIcon icon={faCalendarDay} />
                <ActivityInfoTitle>
                  <FormattedMessage id="common.registration-close.label" />
                </ActivityInfoTitle>
              </div>
              {df(activity.registrationOpenTo, dateDisplayFormat)}
            </div>
          ) : null}
        </ActivityInformation>
      </div>

      {!isFull ? (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          {activity.buyableType === BuyableType.Series && (
            <Button
              buttonType="submit"
              type="primary"
              translationName="activity.join.button.pay"
              disabled={
                isFull ||
                formik.values.team.length < 2 || // must register with partner
                formik.values.team[0] === formik.values.team[1] || // not allowed to set one self as partner
                formik.isSubmitting ||
                !formik.isValid ||
                isAlreadyParticipant
              }
            />
          )}
          {activity.buyableType === BuyableType.Event && (
            <Button
              buttonType="submit"
              type="primary"
              translationName="activity.join.button.pay"
              disabled={
                formik.isSubmitting || !formik.isValid || isAlreadyParticipant
              }
            />
          )}
        </div>
      ) : null}
    </form>
  );
};

export default JoinActivityForm;
