import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";

import { BuyableType } from "../../../../../../../../../modules/checkout/models/Payment";
import { GeneralActivities } from "../../../../../../../../../modules/game/models/GeneralActivities";
import { User } from "../../../../../../../../../modules/player/models/User";

import { adminGetPlayerPath } from "../../../../../../../../../helpers/pathHelpers";

import { useToaster } from "../../../../../../../../../hooks/common/useToaster";
import { useDateFormatWithSelectedFacility } from "../../../../../../../../../hooks/useDateFormat";

import { cancelActivity } from "../../../../../../../../../modules/game/services/Activities";
import { markGeneralActivityAsPaid } from "../../../../../../../../../modules/game/services/GeneralActivities";

import { ConfirmationDialog } from "../../../../../../../../../components/ConfirmationDialog";
import { PaymentStatus } from "../../../../../../../../../components/PaymentStatus";
import { ProfileImageWithFallback } from "../../../../../../../../../components/ProfileImageWithFallback";

import { luxonDateTimeFormat } from "../../../../../../../../../utils/dateFormats";

interface Props {
  users: GeneralActivities["registratedUsers"];
  activityId: GeneralActivities["id"];
  refresh: () => void;
  onSelect: (users: GeneralActivities["registratedUsers"]) => void;
}

export const ParticipantsTable = ({
  users,
  activityId,
  refresh,
  onSelect,
}: Props) => {
  const { df } = useDateFormatWithSelectedFacility();
  const intl = useIntl();
  const history = useHistory();
  const toaster = useToaster();
  const [showRemoveDialog, setShowRemoveDialog] = useState<boolean>(false);
  const [userIdToRemove, setUserIdToRemove] = useState<string | null>(null);
  const [selectedUsers, setSelectedUsers] = useState<
    GeneralActivities["registratedUsers"]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleRemoveAndRefundUser = async () => {
    if (!userIdToRemove) return;
    setIsLoading(true);
    try {
      const result = await cancelActivity(
        activityId,
        BuyableType.Event,
        userIdToRemove,
      );
      if (result) toaster.toastSuccess.userRemovedFromActivity();
    } catch {
      toaster.toastError.userRemovedFromActivityFailed();
    } finally {
      setIsLoading(false);
      setShowRemoveDialog(false);
      setUserIdToRemove(null);
      refresh();
    }
  };

  const handleMarkAsPaid = async (userId: User["id"]) => {
    try {
      const res = await markGeneralActivityAsPaid(activityId, userId);
      if (res) {
        setTimeout(() => {
          // setTimeout? Really? yes. Backend replies when payment is done.
          // We need to wait for series status updates, and since backend dont wait we do.
          // We guess 1s is enough. It should really really really be enough.
          refresh();
        }, 1000);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    onSelect(selectedUsers);
  }, [selectedUsers, onSelect]);

  const headerClasses = "h-full p-0 pl-4 bg-purewhite border-0";
  const bodyClasses = "border-0";

  return (
    <>
      <DataTable
        dataKey="id"
        value={users ?? []}
        selection={selectedUsers}
        stripedRows
        showGridlines={false}
        responsiveLayout="scroll"
        selectionMode="multiple"
        onSelectionChange={e => setSelectedUsers(e.value)}
        onRowUnselect={e => e.originalEvent.stopPropagation()}
        onRowClick={e =>
          e.originalEvent.metaKey
            ? window.open(adminGetPlayerPath(e?.data?.id))
            : history.push(adminGetPlayerPath(e?.data?.id))
        }
      >
        <Column selectionMode="multiple" />
        <Column
          headerClassName={headerClasses}
          bodyClassName="h-full p-0 m-0 border-0"
          body={(user: GeneralActivities["registratedUsers"][number]) => (
            <ProfileImageWithFallback
              firstName={user?.firstName}
              lastName={user?.lastName}
              src={user?.profileImage}
            />
          )}
        />
        <Column
          sortable
          headerClassName={headerClasses}
          bodyClassName={bodyClasses}
          field="displayName"
          header={intl.formatMessage({
            id: "common.name",
          })}
        />
        <Column
          field="skillLevel"
          sortable
          header={<FormattedMessage id="common.skill" />}
          body={(user: GeneralActivities["registratedUsers"][number]) =>
            user?.skillLevel
          }
        />
        <Column
          headerClassName={headerClasses}
          bodyClassName={bodyClasses}
          field="phoneNumber"
          header={intl.formatMessage({
            id: "common.phone",
          })}
        />
        <Column
          headerClassName={headerClasses}
          bodyClassName={bodyClasses}
          field="emailAddress"
          header={intl.formatMessage({
            id: "common.email",
          })}
        />
        <Column
          field="isPayed"
          sortable
          headerClassName={headerClasses}
          bodyClassName={bodyClasses}
          body={(user: GeneralActivities["registratedUsers"][number]) => (
            <div className="flex flex-col">
              {user?.adminUser && user?.lastModified && (
                <>
                  <span>
                    {`${intl.formatMessage({
                      id: "admin.marked-as-paid-by",
                    })}:`}
                  </span>
                  <span>{`${user?.adminUser.displayName.split(" ")[0]} - ${df(
                    user?.lastModified,
                    luxonDateTimeFormat,
                  )}`}</span>
                </>
              )}
              <PaymentStatus
                user={user}
                onMarkAsPaid={u => handleMarkAsPaid(u.id)}
              />
            </div>
          )}
          header={intl.formatMessage({
            id: "common.payment.status",
          })}
        />
        <Column
          headerClassName={headerClasses}
          bodyClassName={bodyClasses}
          align="center"
          body={(user: GeneralActivities["registratedUsers"][number]) => (
            <div
              className="cursor-pointer rounded-sm border border-gray-200 bg-gray-100 px-2 py-1"
              onClick={e => {
                e.stopPropagation();
                setShowRemoveDialog(true);
                setUserIdToRemove(user.id);
              }}
            >
              <FormattedMessage id="common.remove" />
            </div>
          )}
        />
      </DataTable>
      <ConfirmationDialog
        onCancel={() => {
          setShowRemoveDialog(false);
          setUserIdToRemove(null);
        }}
        text={intl.formatMessage({
          id: "activity.admin.remove.participant.are-you-sure",
        })}
        denyText={intl.formatMessage({
          id: "common.no",
        })}
        title={intl.formatMessage({
          id: "activity.admin.remove.participant",
        })}
        visible={showRemoveDialog}
        onSubmit={() => handleRemoveAndRefundUser()}
        onHide={() => {
          setShowRemoveDialog(false);
          setUserIdToRemove(null);
        }}
        loading={isLoading}
      />
    </>
  );
};
