import useSWR from "swr";

import { MaintenanceMessageDeviceType } from "../../models/maintenanceMessage";

import { getMaintenanceMessage } from "../../services/maintenanceMessageService";

export const useMaintenanceMessage = () => {
  const { data, ...rest } = useSWR(
    "useMaintenanceMessage",
    () => getMaintenanceMessage(),
    { refreshInterval: 1_000 * 60 * 5 },
  );

  return {
    ...rest,
    maintenanceMessage: data?.deviceTypes.includes(
      MaintenanceMessageDeviceType.Web,
    )
      ? data
      : null,
  };
};
