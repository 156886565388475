import { FormattedMessage } from "react-intl";

import { Formik } from "formik";
import { InputSwitch } from "primereact/inputswitch";
import { type InferType, array, boolean, number, object, string } from "yup";

import { useToaster } from "../../../../hooks/common/useToaster";
import { useFacilityMemberships } from "../../../../hooks/swr/useFacilityMemberships";
import { useSelectedFacility } from "../../../../hooks/swr/useSelectedFacility";

import { updateFacilityBookingRules } from "../../../../modules/customer/services/FacilityService";

import { NumberInput } from "../../../../components/NumberInput";
import { SubmitResetButtons } from "../../../../components/SubmitResetButtons";
import MultipleSelectInput from "../../../../components/inputs/MultipleSelectInput";

const validationSchema = object({
  numberOfDaysBookingDaysAhead: number().required().min(1),
  allowVacantSlotAroundOpenTime: boolean().required(),
  allowVacantSlotAroundClosingTime: boolean().required(),
  allowVacantSlotAroundCurrentTime: boolean().required(),
  numberOfHoursBeforeBookingCancellation: number().required().min(1),
  numberOfHoursBeforeSeriesGameReschedule: number().required().min(1),
  numberOfHoursBeforeActivityCancellation: number().required().min(1),
  playerCanCancelRecurring: boolean().required(),
  numberOfHoursAfterBookingDuePaymentRuns: number().required().min(1),
  allowOnlyWithMembership: boolean().required(),
  memberships: array()
    .required()
    .of(string().required())
    .when("allowOnlyWithMembership", {
      is: true,
      then: schema => schema.min(1),
    }),
});

export const FacilityBusinessRulesSettings = () => {
  const toaster = useToaster();
  const { selectedFacility, mutate } = useSelectedFacility();
  const { memberships } = useFacilityMemberships(selectedFacility?.id);

  if (!selectedFacility) {
    return null;
  }

  const onSubmit = async (values: InferType<typeof validationSchema>) => {
    try {
      const { data: facility } = await updateFacilityBookingRules(
        selectedFacility.id,
        {
          numberOfDaysBookingDaysAhead: values.numberOfDaysBookingDaysAhead,
          allowVacantSlotAroundOpenTime: values.allowVacantSlotAroundOpenTime,
          allowVacantSlotAroundClosingTime:
            values.allowVacantSlotAroundClosingTime,
          allowVacantSlotAroundCurrentTime:
            values.allowVacantSlotAroundCurrentTime,
          numberOfHoursBeforeBookingCancellation:
            values.numberOfHoursBeforeBookingCancellation,
          numberOfHoursBeforeSeriesGameReschedule:
            values.numberOfHoursBeforeSeriesGameReschedule,
          numberOfHoursBeforeActivityCancellation:
            values.numberOfHoursBeforeActivityCancellation,
          playerCanCancelRecurring: values.playerCanCancelRecurring,
          numberOfHoursAfterBookingDuePaymentRuns:
            values.numberOfHoursAfterBookingDuePaymentRuns,
          onlyMemberships: values.allowOnlyWithMembership
            ? values.memberships
            : [],
        },
      );

      mutate(facility, false);

      toaster.toastSuccess.changesSaved();
    } catch (error) {
      toaster.toastError.unknown();
    }
  };

  return (
    <>
      <h3>
        <FormattedMessage id="common.settings" />
      </h3>

      <Formik
        initialValues={{
          ...selectedFacility.facilityBookingRules,
          allowOnlyWithMembership:
            selectedFacility.facilityBookingRules.onlyMemberships.length > 0,
          memberships: selectedFacility.facilityBookingRules.onlyMemberships,
        }}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {props => (
          <form onSubmit={props.handleSubmit}>
            <div className="mb-10 mt-10 divide-y *:py-8 first:*:pt-0 last:*:pb-0 md:mt-16">
              <Row
                headingTranslationId="admin.facility-settings.settings.numberOfDaysBookingDaysAhead.heading"
                descriptionTranslationId="admin.facility-settings.settings.numberOfDaysBookingDaysAhead.description"
              >
                <NumberInput
                  name="numberOfDaysBookingDaysAhead"
                  onBlur={props.handleBlur}
                  onChange={props.handleChange}
                  value={props.values.numberOfDaysBookingDaysAhead}
                  error={props.errors.numberOfDaysBookingDaysAhead}
                  min={1}
                />
              </Row>
              <Row
                headingTranslationId="admin.facility-settings.settings.30-minute-rule.heading"
                descriptionTranslationId="admin.facility-settings.settings.30-minute-rule.description"
              >
                <div className="grid grid-cols-[1fr_auto] gap-4">
                  <InputSwitch
                    name="allowVacantSlotAroundOpenTime"
                    onBlur={props.handleBlur}
                    onChange={props.handleChange}
                    checked={props.values.allowVacantSlotAroundOpenTime}
                  />
                  <FormattedMessage id="admin.facility-settings.settings.30-minute-rule.allowVacantSlotAroundOpenTime.label" />
                  <InputSwitch
                    name="allowVacantSlotAroundClosingTime"
                    onBlur={props.handleBlur}
                    onChange={props.handleChange}
                    checked={props.values.allowVacantSlotAroundClosingTime}
                  />
                  <FormattedMessage id="admin.facility-settings.settings.30-minute-rule.allowVacantSlotAroundClosingTime.label" />
                  <InputSwitch
                    name="allowVacantSlotAroundCurrentTime"
                    onBlur={props.handleBlur}
                    onChange={props.handleChange}
                    checked={props.values.allowVacantSlotAroundCurrentTime}
                  />
                  <FormattedMessage id="admin.facility-settings.settings.30-minute-rule.allowVacantSlotAroundCurrentTime.label" />
                </div>
              </Row>
              <Row
                headingTranslationId="admin.facility-settings.settings.numberOfHoursBeforeBookingCancellation.heading"
                descriptionTranslationId="admin.facility-settings.settings.numberOfHoursBeforeBookingCancellation.description"
              >
                <NumberInput
                  name="numberOfHoursBeforeBookingCancellation"
                  onBlur={props.handleBlur}
                  onChange={props.handleChange}
                  value={props.values.numberOfHoursBeforeBookingCancellation}
                  error={props.errors.numberOfHoursBeforeBookingCancellation}
                  min={1}
                />
              </Row>
              <Row
                headingTranslationId="admin.facility-settings.settings.numberOfHoursBeforeSeriesGameReschedule.heading"
                descriptionTranslationId="admin.facility-settings.settings.numberOfHoursBeforeSeriesGameReschedule.description"
              >
                <NumberInput
                  name="numberOfHoursBeforeSeriesGameReschedule"
                  onBlur={props.handleBlur}
                  onChange={props.handleChange}
                  value={props.values.numberOfHoursBeforeSeriesGameReschedule}
                  error={props.errors.numberOfHoursBeforeSeriesGameReschedule}
                  min={1}
                />
              </Row>
              <Row
                headingTranslationId="admin.facility-settings.settings.numberOfHoursBeforeActivityCancellation.heading"
                descriptionTranslationId="admin.facility-settings.settings.numberOfHoursBeforeActivityCancellation.description"
              >
                <NumberInput
                  name="numberOfHoursBeforeActivityCancellation"
                  onBlur={props.handleBlur}
                  onChange={props.handleChange}
                  value={props.values.numberOfHoursBeforeActivityCancellation}
                  error={props.errors.numberOfHoursBeforeActivityCancellation}
                  min={1}
                />
              </Row>
              <Row
                headingTranslationId="admin.facility-settings.settings.playerCanCancelRecurring.heading"
                descriptionTranslationId="admin.facility-settings.settings.playerCanCancelRecurring.description"
              >
                <InputSwitch
                  name="playerCanCancelRecurring"
                  onBlur={props.handleBlur}
                  onChange={props.handleChange}
                  checked={props.values.playerCanCancelRecurring}
                />
              </Row>
              <Row
                headingTranslationId="admin.facility-settings.settings.numberOfHoursAfterBookingDuePaymentRuns.heading"
                descriptionTranslationId="admin.facility-settings.settings.numberOfHoursAfterBookingDuePaymentRuns.description"
              >
                <NumberInput
                  name="numberOfHoursAfterBookingDuePaymentRuns"
                  onBlur={props.handleBlur}
                  onChange={props.handleChange}
                  value={props.values.numberOfHoursAfterBookingDuePaymentRuns}
                  error={props.errors.numberOfHoursAfterBookingDuePaymentRuns}
                  min={1}
                />
              </Row>
              <Row
                headingTranslationId="admin.facility-settings.settings.allowOnlyWithMembership.heading"
                descriptionTranslationId="admin.facility-settings.settings.allowOnlyWithMembership.description"
              >
                <div className="text-right">
                  <InputSwitch
                    className="mb-2"
                    name="allowOnlyWithMembership"
                    onBlur={props.handleBlur}
                    onChange={props.handleChange}
                    checked={props.values.allowOnlyWithMembership}
                  />
                  {props.values.allowOnlyWithMembership && (
                    <MultipleSelectInput
                      name="memberships"
                      value={props.values.memberships}
                      error={props.errors.memberships as string}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      options={memberships?.map(membership => ({
                        label: membership.name,
                        value: membership.id,
                      }))}
                    />
                  )}
                </div>
              </Row>
            </div>

            <SubmitResetButtons
              onSubmit={props.handleSubmit}
              disabled={!props.dirty || !props.isValid || props.isSubmitting}
              onReset={props.handleReset}
            />
          </form>
        )}
      </Formik>
    </>
  );
};

const Row = ({
  headingTranslationId,
  descriptionTranslationId,
  children,
}: React.PropsWithChildren<{
  headingTranslationId: string;
  descriptionTranslationId?: string;
}>) => {
  return (
    <div className="flex flex-col justify-between gap-4 md:flex-row">
      <div>
        <h3 className="text-lg [overflow-wrap:anywhere]">
          <FormattedMessage id={headingTranslationId} />
        </h3>

        {descriptionTranslationId && (
          <p className="mt-2 text-pretty text-gray-700 sm:max-w-sm">
            <FormattedMessage id={descriptionTranslationId} />
          </p>
        )}
      </div>

      <div>{children}</div>
    </div>
  );
};
