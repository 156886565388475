import { FormattedMessage } from "react-intl";

import { VendorType } from "../../../../modules/customer/models/Access";

import { useVendorType } from "../../../../hooks/swr/useVendorType";

import { DefaultFacilityAccessTimeDetails } from "../../../../modules/customer/components/DefaultFacilityAccessTimeDetails";
import { QTFacilityAccessTimeDetails } from "../../../../modules/customer/components/QTFacilityAccessTimeDetails";

import { useSelectedFacilityId } from "../../../../recoil/selectedFacilityIdState";

export const FacilityManagementSettings = () => {
  const selectedFacilityId = useSelectedFacilityId();

  const { vendorType, isLoading } = useVendorType(selectedFacilityId);

  if (isLoading) {
    return null;
  }

  return (
    <>
      <h3>
        {vendorType === VendorType.Default ? (
          <FormattedMessage
            id="facility-settings.doors.default-vendor.title"
            defaultMessage="Administrera pinkoder"
          />
        ) : vendorType === VendorType.QT ? (
          <FormattedMessage
            id="facility-settings.doors.qt-vendor.title"
            defaultMessage="Styr anläggningens dörrar"
          />
        ) : null}
      </h3>
      {vendorType === VendorType.Default ? (
        <p>
          <FormattedMessage
            id="facility-settings.doors.default-vendor.temporary.info"
            defaultMessage="Administrera pinkoder för access till hallen. Observera att enbart en pinkod kan vara aktiv åt gången och behöver bestå av 4 siffror."
          />
        </p>
      ) : vendorType === VendorType.QT ? (
        <>
          <h5 className="mt-3">
            <FormattedMessage
              id="facility-settings.doors.qt-vendor.temporary"
              defaultMessage="Entrédörr - tillfällig"
            />
          </h5>
          <p>
            <FormattedMessage
              id="facility-settings.doors.qt-vendor.temporary.info"
              defaultMessage="Låt ytterdörren vara öppen under en begränsad tid."
            />
          </p>
        </>
      ) : null}

      <div className="mt-6">
        {!isLoading && vendorType === VendorType.Default ? (
          <DefaultFacilityAccessTimeDetails facilityId={selectedFacilityId} />
        ) : vendorType === VendorType.QT ? (
          <QTFacilityAccessTimeDetails facilityId={selectedFacilityId} />
        ) : null}
      </div>
    </>
  );
};
