import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Redirect, useParams } from "react-router-dom";

import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { DateTime } from "luxon";

import defaultImage from "../assets/images/backgrounds/background3.jpg";

import { BuyableType } from "../modules/checkout/models/Payment";

import { useGeneralActivity } from "../hooks/swr/useGeneralActivity";
import { useIsAuthenticated } from "../hooks/swr/useIsAuthenticated";
import { useCurrencyFormat } from "../hooks/useCurrencyFormat";
import { useDateFormat } from "../hooks/useDateFormat";
import { useGenderTypes } from "../hooks/useGenderTypes";
import { useJoinActivity } from "../modules/game/hooks/useJoinActivity";

import { Button } from "../components/Button";
import { Card } from "../components/Card";
import { Dialog } from "../components/Dialog";
import { ProgressSpinner } from "../components/ProgressSpinner";
import ActivityParticipantsList from "../modules/game/components/activities/ActivityParticipantsList";

import { useCurrentUserId } from "../recoil/currentUserIdState";
import { SignInRedirectPage } from "./SignInRedirect";

const utcNow = DateTime.utc();

export const EventPage = () => {
  const { formatMessage } = useIntl();
  const { eventId } = useParams<{ eventId: string }>();
  const isAuthenticated = useIsAuthenticated();
  const currentUserId = useCurrentUserId();
  const { getGenderNameById } = useGenderTypes();
  const { activity, isLoading } = useGeneralActivity(
    isAuthenticated && eventId ? eventId : null,
    "payment",
  );
  const { handlePayActivity, isLoading: joinActivityIsLoading } =
    useJoinActivity();

  const { df } = useDateFormat(activity?.facilityId);
  const { cf } = useCurrencyFormat(activity?.price.currencyCode);

  const [showParticipantsList, setShowParticipantsList] = useState(false);

  useEffect(() => {
    if (eventId) {
      window.location.href = `court22://activity/${eventId}`;
    }
  }, [eventId]);

  if (!isAuthenticated) {
    return <SignInRedirectPage />;
  }

  if (isLoading) {
    return <ProgressSpinner />;
  }

  if (!activity) {
    return <Redirect to="/not-found" />;
  }

  const payOrJoinActivity = () => {
    handlePayActivity({
      id: activity.id,
      facilityId: activity.facilityId,
      buyableType: BuyableType.Event,
    });
  };

  const currentUserParticipant = activity.registratedUsers.find(
    user => user.id === currentUserId,
  );
  const isCurrentUserParticipant = !!currentUserParticipant;
  const hasCurrentUserPaid = !!currentUserParticipant?.isPayed;
  const isEventFull =
    !!activity.numberOfParticipants &&
    activity.registratedUsers.length >= activity.numberOfParticipants;
  const isEventFree = activity.price.valueExclTax === 0;
  const hasRegistrationClosed = activity.registrationOpenTo < utcNow;

  if (!activity.openForRegistration && !isCurrentUserParticipant) {
    return (
      <Card>
        <h1 className="xl:text-[48px] xl:leading-[1.1]">
          <FormattedMessage id="common.the-event-is-no-longer-available" />
        </h1>
      </Card>
    );
  }

  return (
    <Card>
      <div className="grid gap-4 md:grid-cols-2 md:gap-8 lg:gap-16 lg:p-8">
        <div className="place-self-center">
          <img
            className="max-h-[40vh] rounded-lg"
            src={activity.imageUrl ?? defaultImage}
            alt={activity.name}
          />
        </div>
        <div className="xl:max-w-[486px]">
          <div className="divide-y *:py-4 first:*:pt-0 last:*:pb-0 lg:*:py-6">
            <div>
              <p className="text-gray-500 lg:text-lg">
                <FormattedMessage id="common.event" />
              </p>
              <h1 className="xl:text-[48px] xl:leading-[1.1]">
                {activity.name}
              </h1>
            </div>

            {activity.description && (
              <p className="lg:text-lg">{activity.description}</p>
            )}

            <div className="grid grid-cols-2 gap-3">
              <DetailsCell title={formatMessage({ id: "common.venue" })}>
                {activity.facilityName}
              </DetailsCell>

              <DetailsCell
                title={formatMessage({ id: "common.date-and-time" })}
              >
                {df(activity.startTime, { month: "numeric", day: "numeric" })}{" "}
                {df(activity.startTime, DateTime.TIME_SIMPLE)}
                {" - "}
                {df(activity.endTime, DateTime.TIME_SIMPLE)}
              </DetailsCell>

              <DetailsCell title={formatMessage({ id: "common.price" })}>
                <span
                  className={clsx(
                    activity.discountPrice && "font-medium line-through",
                  )}
                >
                  {cf(activity.price.valueInclTax)}
                </span>

                {activity.discountPrice && (
                  <b className="ml-1">
                    {cf(activity.discountPrice.valueInclTax)}
                  </b>
                )}
              </DetailsCell>

              <DetailsCell title={formatMessage({ id: "common.class" })}>
                {getGenderNameById(activity.gender.toString())}
              </DetailsCell>
            </div>

            {activity.numberOfParticipants && (
              <>
                <DetailsCell
                  title={formatMessage({ id: "common.participants" })}
                >
                  <button
                    className="flex w-full items-center gap-3"
                    onClick={() => setShowParticipantsList(true)}
                  >
                    <span className="flex-none">
                      {activity.registratedUsers.length}/
                      {activity.numberOfParticipants}
                    </span>
                    <div className="relative h-[3px] flex-auto rounded-full bg-gray-50">
                      {activity.registratedUsers.length > 0 && (
                        <div
                          className="absolute left-0 h-full rounded-full bg-pureblack"
                          style={{
                            width: `${(activity.registratedUsers.length / activity.numberOfParticipants) * 100}%`,
                          }}
                        />
                      )}
                    </div>
                  </button>
                </DetailsCell>

                {showParticipantsList && (
                  <Dialog visible onHide={() => setShowParticipantsList(false)}>
                    <ActivityParticipantsList
                      activity={{
                        name: activity.name,
                        participants: activity.registratedUsers,
                        maxParticipantsCount: activity.numberOfParticipants,
                      }}
                    />
                  </Dialog>
                )}
              </>
            )}
          </div>

          <div className="mt-12">
            {isCurrentUserParticipant ? (
              hasCurrentUserPaid || isEventFree ? (
                <Button className="w-full border-green-700 bg-green-700 text-purewhite">
                  <div className="flex items-center gap-2">
                    <FormattedMessage id="activities.participates" />
                    <FontAwesomeIcon icon={faCheck} />
                  </div>
                </Button>
              ) : (
                <Button
                  className="w-full"
                  type="primary"
                  disabled={hasCurrentUserPaid || joinActivityIsLoading}
                  onClick={() => payOrJoinActivity()}
                >
                  <FormattedMessage id="common.pay" />
                </Button>
              )
            ) : (
              <Button
                className="w-full"
                type="primary"
                disabled={
                  isEventFull || joinActivityIsLoading || hasRegistrationClosed
                }
                loading={joinActivityIsLoading}
                onClick={() => payOrJoinActivity()}
              >
                {hasRegistrationClosed ? (
                  <FormattedMessage id="common.registration-is-closed" />
                ) : isEventFull ? (
                  <FormattedMessage id="activity.join.activity-full" />
                ) : (
                  <FormattedMessage id="common.participate-in-event" />
                )}
              </Button>
            )}
          </div>
        </div>
      </div>
    </Card>
  );
};

const DetailsCell = ({
  title,
  children,
}: React.PropsWithChildren<{ title: string }>) => (
  <div className="">
    <p className="text-gray-500">{title}</p>
    <p className="font-bold lg:text-lg">{children}</p>
  </div>
);
