import { useCallback } from "react";

import { SWEDBANK_PAY_PAYMENT_MENU_PATH } from "../../../../models/swedbankPayCheckout";

import { createOrderPaymentWithSwedbankPayApplePay } from "../../../../services/myOrdersService";

import { useCheckoutState } from "../../context/CheckoutContext";
import { SwedbankPayPayment } from "./SwedbankPay";

export const SwedbankPayApplePay = () => {
  const { order, isSplitPayment, coPayingUsers } = useCheckoutState();

  const createOrderPayment = useCallback(
    async () =>
      createOrderPaymentWithSwedbankPayApplePay(order.id, {
        paymentMenuUrl: window.location.origin + SWEDBANK_PAY_PAYMENT_MENU_PATH,
        splitPayment: isSplitPayment,
        coPayingUserIds: isSplitPayment
          ? coPayingUsers.map(user => user.id)
          : [],
      }),
    [coPayingUsers, isSplitPayment, order.id],
  );

  return <SwedbankPayPayment createOrderPayment={createOrderPayment} />;
};
