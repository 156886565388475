import { useMemo } from "react";

import { faCircleInfo } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { uniqueId } from "lodash";
import { Tooltip } from "primereact/tooltip";

import { Label } from "../Label";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  error?: string;
  inputId?: string;
  label?: string;
  tooltip?: string;
  type?: "text" | "email" | "tel";
}

export const TextInput = ({
  className,
  label = "",
  tooltip,
  error,
  type = "text",
  ...rest
}: Props) => {
  const wrapperClasses = clsx(
    "relative flex flex-col gap-1.5 text-sm",
    className,
  );

  const inputClasses = clsx(
    "rounded-10 border bg-purewhite px-3 py-2.5 text-base text-pureblack",
    "focus-visible:border-transparent focus-visible:shadow-input focus-visible:outline-2 focus-visible:outline-primary",
    "disabled:cursor-not-allowed disabled:bg-white disabled:text-gray-700",
    "placeholder:text-gray-700",
    error ? "border-error" : "border-gray-50",
  );

  const tooltipId = useMemo(
    () => (tooltip ? uniqueId("tooltip") : ""),
    [tooltip],
  );

  const inputId = "id-" + (rest.inputId ?? rest.name ?? uniqueId());

  return (
    <div id={rest.id} className={wrapperClasses}>
      {label && (
        <Label htmlFor={inputId} className="flex items-center gap-1">
          {label}
          {rest.required && <span className="text-error"> *</span>}

          {tooltip && (
            <>
              <FontAwesomeIcon
                id={tooltipId}
                icon={faCircleInfo}
                className="text-gray-700"
                size="xs"
              />
              <Tooltip
                position="top"
                target={`#${tooltipId}`}
                content={tooltip}
              />
            </>
          )}
        </Label>
      )}

      <input id={inputId} type={type} className={inputClasses} {...rest} />

      {error && <div className="text-error">{error}</div>}
    </div>
  );
};
