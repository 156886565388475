import { useState } from "react";
import { FormattedMessage } from "react-intl";

import { faCheckCircle, faSave } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

import type { FacilityWithUtc } from "../../customer/models/Facility";
import { User } from "../../player/models/User";
import { JoinActivityFormValues, type Series } from "../models/Series";

import { useToaster } from "../../../hooks/common/useToaster";

import { signUp } from "../services/Serie";

import { Button } from "../../../components/Button";
import { UserSearch } from "../../../components/UserSearch";

const H3 = styled.h3`
  small {
    font-size: 1rem !important;
  }
`;

interface Props {
  seriesId: Series["id"];
  facilityId: FacilityWithUtc["id"];
  onSubmit: () => void;
}

export const AdminAddTeam = ({ seriesId, facilityId, onSubmit }: Props) => {
  const { toastError, toastSuccess } = useToaster();
  const [userList, setUserList] = useState<User[]>([]);

  const handleAddUser = async (r: User[]) => {
    if (r.length != 2) return;

    //send request to seriesService to add..
    const request: JoinActivityFormValues = {
      activityId: seriesId,
      team: [r[0].id, r[1].id],
      teamCaptainId: userList[0]?.id,
    };
    try {
      await signUp(seriesId, request);
      toastSuccess.teamAddedToSerie();
      onSubmit();
    } catch {
      toastError.addTeamToSerieFailed();
    }
  };

  return (
    <div>
      <H3 className="flex">
        <FormattedMessage id="series.add-team" />
        <br />
      </H3>
      <p className="mb-5 flex items-center">
        <FontAwesomeIcon className="mr-1" icon={faCheckCircle} />
        <FormattedMessage id="series.choose-players" />
      </p>

      <div className="my-2">
        <UserSearch
          facilityId={facilityId}
          title="Sök efter spelare"
          translationId="search.profile.placeholder"
          errorText=""
          isDisabled={false}
          onChange={r => {
            setUserList(r);
          }}
        />
      </div>

      <div className="mt-6 flex justify-end">
        <Button
          buttonType="button"
          type="primary"
          disabled={userList.length != 2}
          className="button-medium"
          text="Spara"
          onClick={() => handleAddUser(userList)}
          translationName="button.save"
          icon={faSave}
        />
      </div>
    </div>
  );
};
