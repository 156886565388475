import React from "react";

import { faTrophy } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";

import { SeriesMatchPlayer } from "../../../../../../../../../../../modules/game/models/Series";

import { ProfileImageWithFallback } from "../../../../../../../../../../../components/ProfileImageWithFallback";

interface Props {
  className?: string;
  score?: number;
  team?: { id: string; players: SeriesMatchPlayer[] };
  type?: "left" | "right";
  winningTeamId?: string;
}

export const TeamCard: React.FC<Props> = ({
  className,
  score,
  team,
  type = "left",
  winningTeamId,
}) => {
  const wrapperClasses = clsx(
    "flex w-[400px] rounded-full bg-white",
    {
      "pr-8": type === "left",
      "flex-row-reverse pl-8": type === "right",
    },
    className,
  );

  const iconClasses = clsx(
    "mx-5 self-center",
    winningTeamId === team?.id ? "" : "invisible",
  );

  const teamNameClasses = clsx("whitespace-nowrap text-sm font-bold", {
    "text-green-700": winningTeamId && winningTeamId === team?.id,
    "text-red-500": winningTeamId && winningTeamId !== team?.id,
    "text-inherit": !winningTeamId,
  });

  // Styled component, regular CSS
  const avatarClasses = clsx("border: 1px solid black;", {
    "&:not(:first-child) { margin-left: -13px};": type === "left",
    "&:first-child { margin-right: -13px};": type === "right",
  });

  return (
    <div className={wrapperClasses}>
      <div className="flex">
        {team?.players?.map((player, index) => {
          return (
            <ProfileImageWithFallback
              key={player.userId}
              css={
                avatarClasses +
                `z-index: ${
                  type === "left" ? index : team?.players.length - index
                }`
              }
              src={player?.imageUrl}
              firstName={player.firstName}
              lastName={player.lastName}
              height="55px"
              width="55px"
            />
          );
        })}
      </div>

      <FontAwesomeIcon className={iconClasses} icon={faTrophy} />
      <div className="flex grow flex-col items-center justify-center">
        {team?.players?.map(player => {
          return (
            <span className={teamNameClasses} key={player.userId}>
              {`${player.firstName} ${player.lastName}`}
            </span>
          );
        })}
      </div>
      <span className="mx-3 self-center">{score ?? "N/A"}</span>
    </div>
  );
};
