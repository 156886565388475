import { useCallback, useMemo } from "react";

import { useAppLocale } from "../recoil/i18nConfigState";

export const useCurrencyFormat = (
  // eslint-disable-next-line @typescript-eslint/ban-types
  currency?: "Coins" | ({} & string) | null,
  options?: { minimumFractionDigits: 0 | 2 },
) => {
  const locale = useAppLocale();

  options = options || { minimumFractionDigits: 0 };

  const numberFormatter = useMemo(
    () =>
      currency
        ? new Intl.NumberFormat(locale, {
            style: currency === "Coins" ? "decimal" : "currency",
            currency: currency === "Coins" ? undefined : currency,
            ...options,
          })
        : null,
    [currency, locale, options],
  );

  const cf = useCallback(
    (amount: number) => {
      if (!currency || !numberFormatter) {
        return "";
      }

      return numberFormatter.format(amount);
    },
    [currency, numberFormatter],
  );

  return { cf };
};
