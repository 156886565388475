import { useIntl } from "react-intl";

import clsx from "clsx";
import { FormikErrors, FormikValues } from "formik";

import { DateOnly } from "../../../../../../models/DateOnly";

import { CalendarInput } from "../../../../../../components/CalendarInput";
import { NumberInput } from "../../../../../../components/NumberInput";
import { TextInput } from "../../../../../../components/TextInput";

interface Props {
  errors: FormikErrors<FormikValues>;
  values: FormikValues;
  handleChange: (e: React.ChangeEvent) => void;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean,
  ) => Promise<void> | Promise<FormikErrors<FormikValues>>;
}

const ERROR_MESSAGE_CLASSES = "text-start self-start text-error text-sm";

export const CustomerInfoInputFields = ({
  errors,
  values,
  handleChange,
  setFieldValue,
}: Props) => {
  const { formatMessage } = useIntl();

  const showNationalIdentificationNumber =
    values.country === "SE" || values.country === "FI";

  return (
    <div className="grid grid-cols-1 items-end gap-4 sm:grid-cols-2 xl:grid-cols-4">
      <TextInput
        required
        className="order-first"
        name="firstName"
        label={formatMessage({ id: "common.firstname" })}
        value={values.firstName}
        onChange={handleChange}
      />
      <div className={clsx(ERROR_MESSAGE_CLASSES, "sm:order-3 xl:order-5")}>
        <>{errors.firstName}</>
      </div>

      <TextInput
        required
        className="sm:order-2"
        name="lastName"
        label={formatMessage({ id: "common.lastname" })}
        value={values.lastName}
        onChange={handleChange}
      />
      <div className={clsx(ERROR_MESSAGE_CLASSES, "sm:order-4 xl:order-6")}>
        <>{errors.lastName}</>
      </div>

      <TextInput
        className="sm:order-5 xl:order-3"
        name="phoneNumber"
        label={formatMessage({ id: "common.phone" })}
        value={values.phoneNumber}
        onChange={handleChange}
      />
      <div className={clsx(ERROR_MESSAGE_CLASSES, "sm:order-7 xl:order-7")}>
        <>{errors.phoneNumber}</>
      </div>

      <CalendarInput
        className="label:font-bold max-w-none sm:order-6 xl:order-4 [&>label]:font-bold"
        name="birthDate"
        label={formatMessage({ id: "common.birth-date" })}
        value={values.birthDate?.toDateTime()}
        onChange={e => {
          setFieldValue(
            "birthDate",
            e.value ? DateOnly.fromDateTime(e.value) : null,
          );
        }}
      />
      <div className={clsx(ERROR_MESSAGE_CLASSES, "sm:order-8")}>
        <>{errors.birthDate}</>
      </div>

      <div
        className={clsx(
          !showNationalIdentificationNumber && "order-last",
          showNationalIdentificationNumber ? "sm:order-9" : "sm:order-10",
          "xl:order-[12]",
        )}
      >
        <TextInput
          className={clsx(!showNationalIdentificationNumber && "hidden")}
          name="nationalIdentificationNumber"
          placeholder={values.country === "SE" ? "YYMMDD-XXXX" : "DDMMYYCZZZQ"}
          label={
            values.country === "SE"
              ? formatMessage({
                  id: "profile.settings.national-identification-number.se",
                })
              : formatMessage({
                  id: "profile.settings.national-identification-number.fi",
                })
          }
          value={values.nationalIdentificationNumber}
          onChange={handleChange}
        />
      </div>

      <div
        className={clsx(
          "xl:order-[16]",
          ERROR_MESSAGE_CLASSES,
          !showNationalIdentificationNumber && "order-last",
          showNationalIdentificationNumber ? "sm:order-11" : "sm:order-12",
        )}
      >
        {showNationalIdentificationNumber && (
          <>{errors.nationalIdentificationNumber}</>
        )}
      </div>

      <TextInput
        className={clsx(
          "xl:order-9",
          showNationalIdentificationNumber ? "sm:order-10" : "sm:order-9",
        )}
        name="city"
        label={formatMessage({ id: "common.city" })}
        value={values.city}
        onChange={handleChange}
      />

      <div
        className={clsx(
          "xl:order-[13]",
          ERROR_MESSAGE_CLASSES,
          showNationalIdentificationNumber ? "sm:order-12" : "sm:order-11",
        )}
      >
        <>{errors.city}</>
      </div>

      <TextInput
        className="sm:order-[13] xl:order-10"
        name="street"
        label={formatMessage({ id: "common.streetaddress" })}
        value={values.street}
        onChange={handleChange}
      />
      <div
        className={clsx(ERROR_MESSAGE_CLASSES, "sm:order-[15] xl:order-[14]")}
      />

      <TextInput
        className="sm:order-[14] xl:order-11"
        name="zipCode"
        label={formatMessage({ id: "common.zipcode" })}
        value={values.zipCode}
        onChange={handleChange}
      />
      <div
        className={clsx(ERROR_MESSAGE_CLASSES, "sm:order-[16] xl:order-[15]")}
      >
        <>{errors.zipCode}</>
      </div>

      <div className="order-last">
        <NumberInput
          className="order-last"
          name="skillLevel"
          label={formatMessage({ id: "common.level" })}
          required
          value={values.skillLevel}
          onChange={handleChange}
          min={1}
          max={10}
        />
        <div className={ERROR_MESSAGE_CLASSES}>
          <>{errors.skillLevel}</>
        </div>
      </div>
    </div>
  );
};
