import { ApiResponse, ApiSingleResponse } from "../../../models/common";
import { Friend } from "../models/Friend";
import { User } from "../models/User";

import { fetchApi } from "../../../services/legacyApiClient";

const apiVersion = "player/v2";

export const getFriends = async (signal?: AbortSignal): Promise<User[]> =>
  (
    (await fetchApi({
      uri: `${apiVersion}/friends`,
      signal,
    })) as ApiResponse<User>
  )?.data;

export const deleteFriend = async (friendId?: string): Promise<Friend[]> =>
  (
    (await fetchApi({
      method: "DELETE",
      uri: `${apiVersion}/friends/${friendId}`,
    })) as ApiResponse<Friend>
  )?.data;

export const updateFriend = async (friendId: string): Promise<Friend[]> =>
  (
    (await fetchApi({
      method: "PUT",
      uri: `${apiVersion}/friends/${friendId}`,
    })) as ApiResponse<Friend>
  )?.data;

export const getInvitationLink = async (): Promise<ApiSingleResponse<string>> =>
  (await fetchApi({
    method: "POST",
    uri: `${apiVersion}/friends/invitation`,
  })) as ApiSingleResponse<string>;

export const accepetFriendRequest = async (
  invitationLink: string,
  isAccepted: boolean,
): Promise<boolean> =>
  (
    (await fetchApi({
      method: "POST",
      uri: `${apiVersion}/friends/invitation/accept/${invitationLink}`,
      payload: isAccepted,
    })) as ApiSingleResponse<boolean>
  )?.data;

export const getUserInfoByInvitationId = async (
  friendInvitationId: string,
): Promise<Friend> =>
  (
    (await fetchApi({
      uri: `${apiVersion}/friends/userinfo/${friendInvitationId}`,
    })) as ApiSingleResponse<Friend>
  )?.data;

export const removeFriendFromList = async (
  friendId: string,
  signal?: AbortSignal,
): Promise<Friend> =>
  (
    (await fetchApi({
      method: "DELETE",
      uri: `${apiVersion}/friends/${friendId}`,
      signal,
    })) as ApiSingleResponse<Friend>
  )?.data;

export const sendFriendRequest = async (friendId: string): Promise<string> =>
  (
    (await fetchApi({
      method: "POST",
      uri: `${apiVersion}/friends/invitation/send`,
      payload: friendId,
    })) as ApiSingleResponse<string>
  )?.data;

export const getFriendById = async (
  friendId: string,
  signal?: AbortSignal,
): Promise<Friend> =>
  (
    (await fetchApi({
      uri: `${apiVersion}/friends/${friendId}`,
      signal,
    })) as ApiSingleResponse<Friend>
  )?.data;
