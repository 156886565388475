import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

import { InputSwitch } from "primereact/inputswitch";

import { TimeOnly } from "../../../../../../models/TimeOnly";
import { OpenHours as OpenHoursInterface } from "../../../../../../modules/customer/models/Facility";

import { useToaster } from "../../../../../../hooks/common/useToaster";
import { useAppDispatch } from "../../../../../../hooks/store/store";
import { useSelectedFacility } from "../../../../../../hooks/swr/useSelectedFacility";

import { updateOpenHours } from "../../../../../../modules/customer/services/FacilityService";

import { SelectInput } from "../../../../../../components/inputs/SelectInput";
import { FacilitySubmitResetButtons } from "../../../../../../modules/customer/components/FacilitySubmitResetButtons";
import { OpenHoursDays } from "./components/OpenHoursDays";

import { DayOfWeek } from "../../../../../../enums/DayOfWeek";
import { setSelectedFacility } from "../../../../../../store/slices/facilities/facilitiesSlice";

const hours = Array.from(Array(25).keys()).map(hour => {
  const time = hour < 10 ? "0" + hour : hour;
  return {
    label: hour === 24 ? "00:00" : time + ":00",
    value: hour === 24 ? "00:00:00" : time + ":00:00",
  };
});

export const OpenHours: React.FC = () => {
  const { selectedFacility, mutate } = useSelectedFacility();
  const { toastError, toastSuccess } = useToaster();

  const dispatch = useAppDispatch();

  const [isSameOpenHoursAllDays, setIsSameOpenHoursAllDays] = useState<boolean>(
    selectedFacility?.isSameOpenHoursAllDays,
  );
  const [isValid, setIsValid] = useState<boolean>(true);
  const [startTime, setStartTime] = useState<TimeOnly>();
  const [endTime, setEndTime] = useState<TimeOnly>();

  useEffect(() => {
    if (selectedFacility?.openHours.length < 1) {
      return;
    }

    // To avoid resetting the values on re-render
    if (startTime || endTime) {
      return;
    }

    setStartTime(selectedFacility?.openHours[0].startTime);
    setEndTime(selectedFacility?.openHours[0].endTime);
  }, [selectedFacility]);

  useEffect(() => {
    if (!startTime || !endTime) {
      return;
    }

    if (startTime.toDateTime().hour === 0 || endTime.toDateTime().hour === 0) {
      setIsValid(true);
      return;
    }

    if (startTime >= endTime) {
      setIsValid(false);
    } else {
      setIsValid(true);
    }
  }, [startTime, endTime]);

  const onSubmit = async () => {
    if (!isValid) {
      toastError.validation();
      return;
    }

    const openHours: OpenHoursInterface[] = [
      DayOfWeek.Monday,
      DayOfWeek.Tuesday,
      DayOfWeek.Wednesday,
      DayOfWeek.Thursday,
      DayOfWeek.Friday,
      DayOfWeek.Saturday,
      DayOfWeek.Sunday,
    ].map((day, i) => ({
      id: selectedFacility?.openHours[i]?.id || "",
      dayOfWeek: day,
      startTime,
      endTime,
    }));

    try {
      const response = await updateOpenHours(selectedFacility?.id, {
        openHours,
        isSameOpenHoursAllDays,
      });

      if (response) {
        dispatch(setSelectedFacility({ facility: response.data }));
        mutate(response.data, false);

        setStartTime(response.data.openHours[0].startTime);
        setEndTime(response.data.openHours[0].endTime);

        toastSuccess.infoSaved();
      }
    } catch (e) {
      e.hasValidationErrors ? toastError.validation() : toastError.saveFailed();
    }
  };

  return (
    <div>
      <h3 className="mb-10 text-xl">
        <FormattedMessage id="common.opening-hours" />
      </h3>

      <div className="flex items-center gap-2">
        <FormattedMessage
          id="facility-settings.label.same-opening-hours-every-day"
          defaultMessage="Samma öppettider varje dag"
        />

        <InputSwitch
          checked={isSameOpenHoursAllDays}
          onChange={() => setIsSameOpenHoursAllDays(!isSameOpenHoursAllDays)}
        />
      </div>

      <div className="mt-10">
        {isSameOpenHoursAllDays ? (
          <div className="w-full">
            <div className="flex max-w-2xl flex-col gap-4 sm:flex-row sm:items-center">
              <div className="whitespace-nowrap">
                <FormattedMessage id="week-day.monday" /> -{" "}
                <FormattedMessage id="week-day.sunday" />
              </div>
              <SelectInput
                value={startTime?.toISO()}
                options={hours.slice(0, -1)}
                onChange={e => setStartTime(TimeOnly.fromISOTime(e.value))}
              />
              <div>
                <FormattedMessage id="common.to" defaultMessage="till" />
              </div>
              <SelectInput
                value={endTime?.toISO()}
                options={hours.slice(1)}
                onChange={e => setEndTime(TimeOnly.fromISOTime(e.value))}
              />
              <div>
                {!isValid && (
                  <div className="text-error">
                    <FormattedMessage
                      id="facility-settings.error.opening-hours-greater-than-closing-hours"
                      defaultMessage="Öppningstiden är senare än stängningstiden"
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="mt-5">
              <FacilitySubmitResetButtons
                onSubmit={onSubmit}
                onReset={() => {
                  setStartTime(selectedFacility?.openHours[0].startTime);
                  setEndTime(selectedFacility?.openHours[0].endTime);
                }}
                disabled={
                  !isValid ||
                  (selectedFacility?.openHours[0]?.startTime?.valueOf() ===
                    startTime?.valueOf() &&
                    selectedFacility?.openHours[0]?.endTime?.valueOf() ===
                      endTime?.valueOf())
                }
              />
            </div>
          </div>
        ) : (
          <OpenHoursDays isAllDays={isSameOpenHoursAllDays} />
        )}
      </div>
    </div>
  );
};
