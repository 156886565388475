import { DateTime } from "luxon";

import { DateOnly } from "../../../models/DateOnly";
import { PaymentMethodName } from "../../checkout/models/Payment";
import { FacilityWithUtc } from "../../customer/models/Facility";

import { Membership } from "./Membership";

export interface User {
  age?: string;
  birthDate?: DateOnly | null;
  city?: string;
  consentEmailNewsMarketingCourt22?: boolean;
  consentEmailNewsMarketingFacility?: boolean;
  consentPublicProfile?: boolean;
  country?: string;
  displayName?: string;
  emailAddress?: string;
  facilityUserRelations?: FacilityUserRelation[];
  favoriteFacilityId?: FacilityWithUtc["id"] | null;
  firstName?: string;
  gender?: Gender;
  id: string;
  isFacilityAdmin?: boolean;
  isFavorite?: boolean;
  isFriend?: boolean;
  isPayed?: boolean;
  isSuperAdmin?: boolean;
  lastActivity?: DateTime | null;
  lastName?: string;
  locale?: string | null;
  paymentMethod?: PaymentMethodName;
  payments?: any[];
  phoneNumber?: string;
  pinCode?: string;
  profileImage?: string;
  skillLevel?: number;
  socialSecurityNumber?: string;
  streetAddress?: string;
  totalPayedIncTax?: number;
  zipCode?: string;
}

export type UpdateUserRequest = Pick<User, "id" | "firstName" | "lastName"> & {
  birthDate?: User["birthDate"] | null;
  gender?: User["gender"] | null;
  phoneNumber?: User["phoneNumber"] | null;
  nationalIdentificationNumber?: User["socialSecurityNumber"] | null;
  street?: User["streetAddress"] | null;
  zipCode?: User["zipCode"] | null;
  skillLevel?: User["skillLevel"] | null;
};

export interface UserApiResponse
  extends Omit<
    User,
    | "lastActivity"
    | "consentEmailNewsMarketingFacility"
    | "consentEmailNewsMarketingCourt22"
    | "consentPublicProfile"
    | "country"
    | "birthDate"
    | "socialSecurityNumber"
  > {
  lastActivity?: string | null;
  consentEmailNewsMarketingFacility: boolean | null;
  consentEmailNewsMarketingCourt22: boolean | null;
  consentPublicProfile: boolean | null;
  country: string | null;
  birthDate: string | null;
  socialSecurityNumber: string | null;
  streetAddress: string | null;
  zipCode: string | null;
  city: string | null;
}

export interface FacilityUserRelation {
  consentEmail: boolean;
  facilityId: string;
  facilityName: string;
  isFollowing: boolean;
}

export interface FacilityUserRelationRequest {
  consentEmail?: boolean;
  facilityId: string;
  isFavorite?: boolean;
  isFollowing?: boolean;
}

export enum Gender {
  Male = 0,
  Female = 1,
  None = 2,
}

export interface CardInfo {
  maskedPan: string;
  expiryDate: DateTime;
  cardBrand: string;
  cardType: string;
  hasPaymentToken: boolean;
  hasRecurrenceToken: boolean;
  isDeletable: boolean;
}

export interface CardInfoResponse extends Omit<CardInfo, "expiryDate"> {
  expiryDate: string;
}

export type AdminOverviewUser = Pick<
  User,
  "id" | "displayName" | "emailAddress" | "lastActivity" | "phoneNumber"
>;

export interface AdminOverviewUserApiResponse
  extends Omit<AdminOverviewUser, "lastActivity"> {
  lastActivity: string;
}
export interface AdminOverviewUserInfo<
  T extends AdminOverviewUser | AdminOverviewUserApiResponse,
> {
  users: T[];
  lastPage: number;
}

export interface AdminOverviewUsersFilters {
  gender?: [Gender];
  hasLastActivity?: boolean;
  membershipList?: Membership["id"][];
}

export interface AdminOverviewUsersSorting {
  sortBy: string;
  orderType: "asc" | "desc";
}

export interface AdminOverviewUsersCount {
  totalCount: number;
  filteredCount: number;
  verifiedCount: number;
}
