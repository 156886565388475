import React, { useState } from "react";
import { FormattedMessage } from "react-intl";

import { faShuffle } from "@fortawesome/pro-light-svg-icons";
import { faShield } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";

import { SeriesPlayer } from "../../../../modules/game/models/Series";

import { switchTeamCaptain } from "../../../../modules/game/services/Serie";

import { Dialog } from "../../../../components/Dialog";
import { ProfileImageWithFallback } from "../../../../components/ProfileImageWithFallback";

import { ReplacePlayer } from "./ReplacePlayer";

interface UserCardProps {
  seriesId?: string;
  facilityId?: string;
  teamId?: string;
  player?: SeriesPlayer;
  isUserCaptain?: boolean;
  disabled?: boolean;
  onChange?: () => void;
}

export const UserCard: React.FC<UserCardProps> = ({
  seriesId,
  facilityId,
  teamId,
  player,
  isUserCaptain,
  disabled,
  onChange,
}) => {
  const [showReplacePlayer, setShowReplacePlayer] = useState<boolean>(false);

  const handleCaptainSwitch = async () => {
    try {
      await switchTeamCaptain(seriesId, facilityId, teamId);
      onChange?.();
    } catch (err) {
      console.log(err);
    }
  };

  const replaceButtonClasses = clsx("flex flex-col justify-center", {
    "cursor-pointer text-red-500": !disabled,
    "text-gray-500": disabled,
  });

  return (
    <div
      className="relative mb-5 flex w-full items-center rounded-full bg-white pl-px"
      key={player?.id}
    >
      <ProfileImageWithFallback
        src={player.profileImage}
        firstName={player.firstName}
        lastName={player.lastName}
        css="border: 1px solid #000000"
        width="5rem"
        height="5rem"
      />
      {player?.isTeamCaptain ? (
        <FontAwesomeIcon
          className="absolute bottom-0 ml-16 h-7 text-blue-700"
          icon={faShield}
        />
      ) : null}
      <div className="ml-4">
        {player?.isTeamCaptain ? (
          <p className="text-xs text-gray-700">
            <FormattedMessage id="series.team.captain" />
          </p>
        ) : null}
        <p>{player?.displayName}</p>
        <p className="text-xs text-gray-700">
          <FormattedMessage
            id="activities.level"
            values={{ skillLevelSpan: player?.skillLevel }}
          />
        </p>
      </div>
      {isUserCaptain && !player?.isTeamCaptain ? (
        <div className="ml-auto mr-2 flex sm:mr-5">
          <div
            className="mr-2 flex cursor-pointer flex-col justify-center sm:mr-6"
            onClick={handleCaptainSwitch}
          >
            <FontAwesomeIcon className="text-blue-500" icon={faShield} />
            <p className="text-center text-xs text-blue-500">
              <FormattedMessage id="series.make.team.captain" />
            </p>
          </div>
          <div
            className={replaceButtonClasses}
            onClick={() => !disabled && setShowReplacePlayer(true)}
          >
            <FontAwesomeIcon icon={faShuffle} />
            <p className="text-center text-xs">
              <FormattedMessage id="series.replace.partner" />
            </p>
          </div>
        </div>
      ) : null}
      <Dialog
        visible={showReplacePlayer}
        onHide={() => setShowReplacePlayer(false)}
      >
        <ReplacePlayer
          seriesId={seriesId}
          facilityId={facilityId}
          teamId={teamId}
          setShowReplacePlayer={setShowReplacePlayer}
          playerOut={player}
          onChange={onChange}
        />
      </Dialog>
    </div>
  );
};
