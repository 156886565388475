import {
  type IconDefinition,
  faBatteryThreeQuarters,
  faBriefcase,
  faBus,
  faCoffee,
  faDumbbell,
  faGolfBall,
  faHeartbeat,
  faHotTub,
  faLock,
  faParking,
  faPersonSwimming,
  faShoppingBasket,
  faSnowflake,
  faSpa,
  faTableTennis,
  faTshirt,
  faUserMd,
  faUserNinja,
  faWheelchair,
} from "@fortawesome/pro-light-svg-icons";

export const amenitiesIcons: Record<string, IconDefinition> = {
  parking: faParking,
  "shopping-basket": faShoppingBasket,
  lock: faLock,
  wheelchair: faWheelchair,
  coffee: faCoffee,
  bus: faBus,
  tshirt: faTshirt,
  "golf-ball": faGolfBall,
  dumbbell: faDumbbell,
  briefcase: faBriefcase,
  "table-tennis": faTableTennis,
  "battery-three-quarters": faBatteryThreeQuarters,
  heartbeat: faHeartbeat,
  "hot-tub": faHotTub,
  "user-md": faUserMd,
  "user-ninja": faUserNinja,
  spa: faSpa,
  snowflake: faSnowflake,
  "person-swimming": faPersonSwimming,
};
