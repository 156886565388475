import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import {
  faAngleDown,
  faAngleUp,
  faChartSimple,
  faInfoCircle,
  faSackDollar,
} from "@fortawesome/pro-regular-svg-icons";
import { faCalendar } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateTime } from "luxon";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { Tooltip } from "primereact/tooltip";
import { atom, useRecoilState } from "recoil";

import { DateOnly } from "../../../models/DateOnly";

import { useHasFacilityOwnerPermission } from "../../../hooks/permissions";
import { useSelectedFacility } from "../../../hooks/swr/useSelectedFacility";
import { useCurrencyFormat } from "../../../hooks/useCurrencyFormat";
import { useDateFormatWithSelectedFacility } from "../../../hooks/useDateFormat";

import { CalendarDateTimeWrapper } from "../../../components/CalendarDateTimeWrapper";

import { useVenueStatistics } from "../../../pages/Admin/AdminBookingPage/useVenueStatistics";
import { localStoragePersistenceEffect } from "../../../recoil/effects/localStoragePersistenceEffect";

const isExpandedState = atom({
  key: "adminStatisticsIsExpanded",
  default: true,
  effects: [localStoragePersistenceEffect("adminStatisticsIsExpanded")],
});

export const VenueStatistics = () => {
  const intl = useIntl();
  const { selectedFacility: facility } = useSelectedFacility();

  const {
    timeDurationOptions,
    adminStatistics,
    isStatsLoading,
    sportTypes,
    courtTypes,
    courts,
    option,
    startDate,
    endDate,
    selectedSportTypes,
    selectedCourtTypes,
    selectedCourts,
    setIsCalendarSelection,
    setOption,
    setStartDate,
    setEndDate,
    setSelectedSportTypes,
    setSelectedCourtTypes,
    setSelectedCourts,
  } = useVenueStatistics();

  const { dfInterval } = useDateFormatWithSelectedFacility();
  const { hasFacilityOwnerPermission } = useHasFacilityOwnerPermission();
  const [specificDates, setSpecificDates] = useState<
    [DateTime | null, DateTime | null] | undefined
  >(undefined);

  const { cf } = useCurrencyFormat(facility?.localization?.currencyCode);
  const { cf: coinsFormatter } = useCurrencyFormat("Coins");

  const [isExpanded, setIsExpanded] = useRecoilState(isExpandedState);

  const showCalendar = option?.value === "specificTimePeriod";

  useEffect(() => {
    if (!specificDates) {
      return;
    }

    const [start, end] = specificDates;

    if (start && end) {
      setStartDate(DateOnly.fromDateTime(start));
      setEndDate(DateOnly.fromDateTime(end));
    }
  }, [setEndDate, setStartDate, specificDates]);

  if (!hasFacilityOwnerPermission) return null;

  return (
    <div className="rounded-lg bg-purewhite px-6 py-4">
      <button
        type="button"
        className="flex w-full items-center justify-between"
        onClick={() => setIsExpanded(v => !v)}
      >
        <span className="flex items-center gap-3 text-sm">
          <p className="font-semibold">
            <FontAwesomeIcon
              icon={faChartSimple}
              className="mr-2 inline-block"
            />
            <FormattedMessage id="common.statistics" />
          </p>
          {isExpanded && (
            <p className="text-gray-700">
              {dfInterval(startDate, endDate, DateTime.DATE_SHORT)}
            </p>
          )}
        </span>

        <span className="text-sm">
          <FontAwesomeIcon icon={isExpanded ? faAngleUp : faAngleDown} />
        </span>
      </button>

      {isExpanded && (
        <>
          <div className="mt-2 flex flex-wrap items-start justify-between gap-4 text-sm">
            <div className="flex items-center gap-12 pt-5">
              <div className="flex gap-1.5" id="statistics-tooltip">
                <div className="flex size-9 flex-none items-center justify-center rounded-md bg-primary/10 text-primary">
                  <FontAwesomeIcon icon={faSackDollar} size="lg" />
                </div>
                <div className="overflow-hidden leading-tight">
                  <p>
                    <FormattedMessage id="admin.statistics.earnings.title" />{" "}
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      size="sm"
                      className="inline-block"
                    />
                  </p>
                  <p className="truncate text-sm font-semibold">
                    {typeof adminStatistics?.acumulatedEarningsExTax !==
                      "undefined" && !isStatsLoading
                      ? cf(Math.ceil(adminStatistics.acumulatedEarningsExTax))
                      : "..."}
                  </p>
                </div>
                <Tooltip
                  target="#statistics-tooltip"
                  content={intl.formatMessage({
                    id: "admin.statistics.earnings.subtitle",
                  })}
                />
              </div>
              <div className="flex gap-1.5">
                <div className="flex size-9 flex-none items-center justify-center rounded-md bg-primary/10 text-primary">
                  <FontAwesomeIcon icon={faCalendar} size="lg" />
                </div>
                <div className="overflow-hidden leading-tight">
                  <p>
                    <FormattedMessage id="admin.statistics.occupancy.title" />
                  </p>
                  <p className="truncate text-sm font-semibold">
                    {typeof adminStatistics?.occupancyRate !== "undefined" &&
                    !isStatsLoading
                      ? `${coinsFormatter(Math.ceil(adminStatistics.occupancyRate))}%`
                      : "..."}
                  </p>
                </div>
              </div>
            </div>

            <div className="flex flex-wrap gap-3 text-sm">
              <div>
                <label className="text-gray-700" htmlFor="timeDuration">
                  <FormattedMessage id="admin.statistics.filter.duration" />
                </label>
                <Dropdown
                  inputId="timeDuration"
                  className="bg-transparent"
                  options={timeDurationOptions.map(
                    ({ label, value, disable }) => ({
                      label: label,
                      value: value,
                      disable: disable,
                    }),
                  )}
                  value={option?.value}
                  onChange={e => {
                    setIsCalendarSelection(false);

                    const newOption = timeDurationOptions?.find(
                      i => i?.value === e.value,
                    );

                    if (newOption) {
                      setOption(newOption);
                    }
                  }}
                />
                {showCalendar && (
                  <CalendarDateTimeWrapper
                    readOnlyInput
                    className="mt-1 w-full"
                    inputClassName="text-[inherit] font-inherit bg-transparent"
                    style={{
                      fontSize: "inherit",
                    }}
                    inputStyle={{
                      fontSize: "inherit",
                    }}
                    placeholder={intl.formatMessage({
                      id: "common.filter.date",
                    })}
                    hideOnDateTimeSelect
                    selectionMode="range"
                    value={specificDates as unknown as DateTime} // fuck ts with primereact
                    onChange={e => {
                      console.log(e.value);
                      // fuck ts with primereact
                      setSpecificDates(
                        e.value as unknown as [DateTime, DateTime],
                      );
                    }}
                    maxDate={DateTime.now()}
                    showButtonBar={false}
                    // isEndDateSelected={isEndDateSelected}
                  />
                )}
              </div>
              <div>
                <label className="text-gray-700" htmlFor="sportType">
                  <FormattedMessage id="admin.statistics.filter.sport-types" />
                </label>
                <MultiSelect
                  inputId="sportType"
                  className="bg-transparent"
                  panelHeaderTemplate={() => null}
                  value={selectedSportTypes?.map(i => i.id) ?? []}
                  placeholder={intl.formatMessage({
                    id: "admin.statistics.filter.show-all",
                  })}
                  onChange={e => {
                    if (e.value === null) {
                      setSelectedSportTypes([]);
                      setSelectedCourtTypes([]);
                      setSelectedCourts([]);
                    } else {
                      const arr = sportTypes?.filter(i =>
                        e.value?.includes(i.id),
                      );
                      setSelectedSportTypes(arr);
                    }
                  }}
                  options={sportTypes?.map(i => ({
                    label: i.name,
                    value: i.id,
                  }))}
                  showClear
                  maxSelectedLabels={1}
                />
              </div>
              <div>
                <label className="text-gray-700" htmlFor="courtType">
                  <FormattedMessage id="admin.statistics.filter.court-types" />
                </label>
                <MultiSelect
                  inputId="courtType"
                  className="bg-transparent"
                  panelHeaderTemplate={() => null}
                  value={selectedCourtTypes?.map(i => i.id) ?? []}
                  placeholder={intl.formatMessage({
                    id: "admin.statistics.filter.show-all",
                  })}
                  onChange={e => {
                    setSelectedCourtTypes(
                      courtTypes?.filter(ct => e.value?.includes(ct.id)) ?? [],
                    );
                  }}
                  options={courtTypes?.map(i => ({
                    label: i.name,
                    value: i.id,
                  }))}
                  showClear
                  maxSelectedLabels={1}
                />
              </div>
              <div>
                <label className="text-gray-700" htmlFor="courts">
                  <FormattedMessage id="admin.statistics.filter.courts" />
                </label>
                <MultiSelect
                  inputId="courts"
                  className="bg-transparent"
                  panelHeaderTemplate={() => null}
                  value={selectedCourts?.map(i => i.id) ?? []}
                  placeholder={intl.formatMessage({
                    id: "admin.statistics.filter.show-all",
                  })}
                  onChange={e => {
                    setSelectedCourts(
                      courts?.filter(ct => e.value?.includes(ct.id)) ?? [],
                    );
                  }}
                  options={courts?.map(i => ({
                    label: i.name,
                    value: i.id,
                  }))}
                  showClear
                  maxSelectedLabels={1}
                />
              </div>
            </div>
          </div>
          <button
            type="button"
            className="ml-auto mt-1 block text-sm text-gray-700"
            onClick={() => {
              setOption(timeDurationOptions[0]);
              setSelectedCourtTypes([]);
              setSelectedCourts([]);
              setSelectedSportTypes([]);
            }}
          >
            <FormattedMessage id="common.filter.clear" />
          </button>
        </>
      )}
    </div>
  );
};
