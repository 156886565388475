import React from "react";
import { FormattedMessage } from "react-intl";

import styled from "styled-components";

import { breakpoints } from "../appConstants/common";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  text-align: center;
  align-items: center;

  @media (min-width: ${breakpoints.XL}) {
    gap: 2rem;
  }
`;

const InnerContainer = styled(Container)`
  gap: 1rem;
`;

interface Props {
  translationId?: React.ComponentProps<typeof FormattedMessage>["id"];
  defaultMessage?: React.ComponentProps<
    typeof FormattedMessage
  >["defaultMessage"];
  values?: React.ComponentProps<typeof FormattedMessage>["values"];
}

export const NothingToShow = ({
  translationId,
  defaultMessage,
  values,
  children,
}: React.PropsWithChildren<Props>) => {
  return (
    <Container>
      <InnerContainer>
        {translationId && (
          <FormattedMessage
            id={translationId}
            defaultMessage={defaultMessage}
            values={values}
          />
        )}
        {children}
      </InnerContainer>
    </Container>
  );
};
