import { get } from "./apiClient";

const API_VERSION = "v1";
const API_SERVICE = "/public/constants";
const API_ENDPOINT = `/${API_VERSION}${API_SERVICE}`;

export const getCountries = async () => {
  return await get<{ countryCode: string }[]>({
    endpoint: `${API_ENDPOINT}/countries`,
  });
};
