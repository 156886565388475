import { FormattedMessage } from "react-intl";

import type { PaymentMethod } from "../models/payment";

export const getPaymentMethodText = (paymentMethod: {
  provider: PaymentMethod["provider"];
  instrument: PaymentMethod["instrument"];
}) => {
  switch (paymentMethod.instrument) {
    case "Card": {
      return <FormattedMessage id="checkout.payment-method.card" />;
    }

    case "Swish": {
      return <FormattedMessage id="checkout.payment-method.swish" />;
    }

    case "Credit": {
      return <FormattedMessage id="checkout.payment-method.coins" />;
    }

    case "Allowance": {
      return <FormattedMessage id="checkout.payment-method.allowance" />;
    }

    case "GooglePay": {
      return <FormattedMessage id="checkout.payment-method.google-pay" />;
    }

    case "ApplePay": {
      return <FormattedMessage id="checkout.payment-method.apple-pay" />;
    }

    case "MobilePay": {
      return <FormattedMessage id="checkout.payment-method.mobile-pay" />;
    }
  }
};
