import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { faTrash } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

import { Series, SeriesDivision, SeriesTeam } from "../../../../models/Series";

import { useToaster } from "../../../../../../hooks/common/useToaster";

import { removeTeamFromDivision } from "../../../../services/Serie";

import { ConfirmationDialog } from "../../../../../../components/ConfirmationDialog";

const Table = styled.table`
  table-layout: fixed;
  width: 100%;
  thead,
  tbody {
    tr {
      th {
        font-weight: var(--bold);
        font-size: 1rem;
        text-align: center;
        border-bottom: 1px solid var(--gray-200);
        padding-bottom: 0.5rem;
      }
      td {
        overflow-x: auto;
        text-align: center;
      }
    }
    tr:nth-child(2n) {
      background: var(--gray-100);
    }
  }
`;

interface IProps {
  serie: Series;
  division: SeriesDivision;
  reload: () => void;
}

const DivisionsEditorDivisionDetailsBody: React.FC<IProps> = ({
  serie,
  division,
  reload,
}) => {
  const intl = useIntl();
  const toaster = useToaster();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [selectedTeam, setSelectedTeam] = useState<SeriesTeam>(null);
  const [teamToShow, setTeamToShow] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleRemoveButtonClick = (team: SeriesTeam) => {
    setSelectedTeam(team);

    setDeleteDialogOpen(true);
  };

  const handleRemoveFormSubmitted = async () => {
    try {
      setIsLoading(true);
      const response = await removeTeamFromDivision(
        serie.id,
        division.id,
        selectedTeam.id,
      );

      if (response.data) {
        reload();
        setDeleteDialogOpen(false);
        setSelectedTeam(null);
        toaster.toastSuccess.message("toast.success.division.removed");
      }
    } catch (err) {
      toaster.toastError.removeTeamFromDivisionFailed();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Table cellSpacing={0}>
        <thead>
          <tr>
            <th>
              <FormattedMessage
                id="common.position"
                defaultMessage="Position"
              />
            </th>
            <th>
              <FormattedMessage id="common.team" defaultMessage="Lag" />
            </th>
            <th>
              <FormattedMessage
                id="common.contact-info"
                defaultMessage="Contact Info"
              />
            </th>
            <th>
              <FormattedMessage id="common.remove" defaultMessage="Ta bort" />
            </th>
          </tr>
        </thead>

        <tbody>
          {division?.teams?.map((team, idx) => (
            <React.Fragment key={team.id}>
              <tr>
                <td>{idx + 1}</td>
                <td>
                  {team?.teamMembers
                    ?.map(member => member.displayName)
                    .join(" & ")}
                </td>
                <td>
                  <button
                    className="bold mx-5"
                    onClick={() =>
                      setTeamToShow(prev =>
                        prev === team?.id ? null : team?.id,
                      )
                    }
                  >
                    {intl.formatMessage({
                      id:
                        teamToShow === team?.id
                          ? "common.show.less"
                          : "common.show.more",
                    })}
                  </button>
                </td>
                <td>
                  <button
                    type="button"
                    onClick={() => handleRemoveButtonClick(team)}
                  >
                    <FontAwesomeIcon className="text-red-500" icon={faTrash} />
                  </button>
                </td>
              </tr>
              {teamToShow === team?.id && (
                <tr>
                  <td className="text-left" colSpan={4}>
                    <div
                      className="grid grid-flow-row grid-cols-[auto_auto_auto] gap-x-5 gap-y-2 overflow-x-auto p-5"
                      onClick={() =>
                        setTeamToShow(prev =>
                          prev === team?.id ? null : team?.id,
                        )
                      }
                    >
                      <span className="mb-2 font-bold">
                        {intl.formatMessage({
                          id: "common.name",
                        })}
                      </span>
                      <span className="mb-2 font-bold">
                        {intl.formatMessage({
                          id: "common.phone",
                        })}
                      </span>
                      <span className="mb-2 font-bold">
                        {intl.formatMessage({
                          id: "common.email",
                        })}
                      </span>
                      {team?.teamMembers?.map(member => (
                        <>
                          <span className="whitespace-nowrap">
                            {member.displayName}
                          </span>
                          <span className="whitespace-nowrap">
                            {member.phoneNumber}
                          </span>
                          <span className="whitespace-nowrap">
                            {member.emailAddress}
                          </span>
                        </>
                      ))}
                    </div>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </Table>

      {deleteDialogOpen && (
        <ConfirmationDialog
          visible
          loading={isLoading}
          confirmButtonType="danger"
          title={intl.formatMessage({
            id: "series.division.team.remove-confirmation-message",
          })}
          text={intl.formatMessage({
            id: "divisions.remove.are-you-sure",
          })}
          denyText={intl.formatMessage({
            id: "divisions.remove.reject",
          })}
          confirmText={intl.formatMessage({
            id: "common.confirm.remove",
          })}
          onHide={() => setDeleteDialogOpen(false)}
          onCancel={() => setDeleteDialogOpen(false)}
          onSubmit={handleRemoveFormSubmitted}
        />
      )}
    </>
  );
};

export default DivisionsEditorDivisionDetailsBody;
