import { forwardRef } from "react";

import { Menu as BaseMenu, type MenuProps } from "@mui/base/Menu";
import {
  MenuItem as BaseMenuItem,
  type MenuItemProps,
} from "@mui/base/MenuItem";
import clsx from "clsx";

/**
 * Styled version of the MUI Menu component
 *
 * @see https://mui.com/base-ui/react-menu/
 */
export const Menu = forwardRef<HTMLDivElement, MenuProps>(
  ({ className, ...props }, ref) => {
    return (
      <BaseMenu
        className={clsx(
          "z-40 mt-2 rounded-lg border border-gray-200 bg-white p-1.5 shadow-md",
          className,
        )}
        {...props}
        ref={ref}
      />
    );
  },
);
Menu.displayName = "Menu";

/**
 * Styled version of the MUI MenuItem component. Used together with the Menu component
 *
 * @see https://mui.com/base-ui/react-menu/
 */
export const MenuItem = forwardRef<HTMLLIElement, MenuItemProps>(
  ({ className, ...props }, ref) => {
    return (
      <BaseMenuItem
        className={clsx(
          "rounded-lg border-transparent px-3 py-2 text-sm transition-colors",
          props.disabled
            ? "cursor-not-allowed select-none opacity-25"
            : "cursor-pointer hover:bg-gray-150",
          className,
        )}
        {...props}
        ref={ref}
      />
    );
  },
);
MenuItem.displayName = "MenuItem";
