import React, { useEffect, useState } from "react";

import {
  PriceListResponseWithUTC,
  PricingCalendarPayload,
  WeekDays,
} from "../../../models/Pricing";

import PriceCalendarContent from "./PriceCalendarContent";

interface IProps {
  selectedCategory: string;
  selectedCategoryColor: string;
  priceList: PriceListResponseWithUTC;
  schedules: WeekDays[];
  onChange: (data: PricingCalendarPayload) => void;
}

const PriceCalendar: React.FC<IProps> = ({
  selectedCategory,
  selectedCategoryColor,
  schedules,
  priceList,
  onChange,
}) => {
  const [calendarPayload, setCalendarPayload] =
    useState<PricingCalendarPayload>(null);

  useEffect(() => {
    if (calendarPayload) onChange(calendarPayload);
  }, [calendarPayload]);

  return (
    <div className="overflow-x-auto">
      <PriceCalendarContent
        selectedCategory={selectedCategory}
        selectedCategoryColor={selectedCategoryColor}
        priceList={priceList}
        schedules={schedules}
        onSelect={setCalendarPayload}
      />
    </div>
  );
};

export default PriceCalendar;
