import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory, useParams } from "react-router-dom";

import { faCheck } from "@fortawesome/pro-light-svg-icons";
import { DateTime } from "luxon";

import { useCurrentUser } from "../../../hooks/swr/useCurrentUser";
import { useIsAuthenticated } from "../../../hooks/swr/useIsAuthenticated";
import { useSeriesNextSeasonInfo } from "../../../hooks/swr/useSeriesNextSeasonInfo";
import { useDateFormat } from "../../../hooks/useDateFormat";

import { Button } from "../../../components/Button";
import { ProgressSpinner } from "../../../components/ProgressSpinner";
import { LeavingSeriesConfirmation } from "./components/LeavingSeriesConfirmation";
import { RegistrationClosed } from "./components/RegistrationClosed";
import { SeasonInfo } from "./components/SeasonInfo";
import { TeamCard } from "./components/TeamCard";
import { UserCard } from "./components/UserCard";

import { luxonDateFormat } from "../../../utils/dateFormats";
import { SignInRedirectPage } from "../../SignInRedirect";

interface NewSeasonPageParams {
  id: string;
}

const utc = DateTime.utc();

export const NewSeasonPage: React.FC = () => {
  const { id } = useParams<NewSeasonPageParams>();
  const history = useHistory();
  const [showCancelledConfirmation, setShowCancelledConfirmation] =
    useState<boolean>(false);
  const { currentUser } = useCurrentUser();
  const { seasonInfo, mutate, isLoading } = useSeriesNextSeasonInfo(id);
  const { df } = useDateFormat(seasonInfo?.facilityId);
  const isAuthenticated = useIsAuthenticated();

  const myTeam = seasonInfo?.team;
  const currentTeamUser = myTeam?.teamMembers?.find(
    p => p.id === currentUser?.id,
  );
  const captain = myTeam?.teamMembers?.find(
    teamMember => teamMember.isTeamCaptain,
  )?.firstName;
  const canRegister = utc < seasonInfo?.registrationOpenTo;
  const isCaptainPayed = myTeam?.teamMembers?.find(
    teamMember => teamMember.isTeamCaptain,
  )?.isPayed;

  useEffect(() => {
    if (id) {
      window.location.href = `court22://series/${id}/new-season`;
    }
  }, [id]);

  if (!isAuthenticated) {
    return <SignInRedirectPage />;
  }

  return (
    <>
      {isLoading ? (
        <ProgressSpinner />
      ) : !canRegister ? (
        <RegistrationClosed facilityName={seasonInfo?.facilityName} />
      ) : !showCancelledConfirmation && myTeam ? (
        <div>
          <div className="flex flex-col bg-purewhite px-14 py-14 md:px-20 md:py-20 xl:px-40 xl:py-40">
            <h2 className="text-5xl">
              <FormattedMessage
                id="series.new.season.welcome"
                values={{ br: <br />, seriesName: seasonInfo?.name }}
              />
            </h2>
            <p className="text-lg font-medium">
              <FormattedMessage
                id={
                  currentTeamUser?.isTeamCaptain
                    ? "series.new.season.captain.info"
                    : "series.new.season.partner.info"
                }
                values={{
                  br: <br />,
                  seriesName: seasonInfo?.name,
                  registrationClosing: df(
                    seasonInfo.registrationOpenTo,
                    luxonDateFormat,
                  ),
                  captain: captain,
                }}
              />
            </p>
            <h3 className="mb-4 mt-10 text-xl">
              <FormattedMessage id="common.my.team" />
            </h3>

            {myTeam?.teamMembers?.map(player => (
              <UserCard
                key={player.id}
                seriesId={seasonInfo.id}
                facilityId={seasonInfo?.facilityId}
                teamId={myTeam.id}
                player={player}
                isUserCaptain={currentTeamUser?.isTeamCaptain}
                disabled={currentTeamUser?.isPayed}
                onChange={() => mutate()}
              />
            ))}

            {seasonInfo.previousTeam && (
              <>
                <h3 className="mb-4 mt-10 text-xl">
                  <FormattedMessage id="series.last.season.results" />
                </h3>
                <TeamCard team={seasonInfo.previousTeam} />
              </>
            )}

            {currentTeamUser ? (
              <SeasonInfo
                seasonInfo={seasonInfo}
                currentUser={currentUser}
                isPayed={currentTeamUser?.isPayed}
                setShowCancelledConfirmation={setShowCancelledConfirmation}
                isTeamCaptain={currentTeamUser?.isTeamCaptain}
                isCaptainPayed={isCaptainPayed}
              />
            ) : (
              <Button
                className="mx-auto my-8 w-32 bg-gradient-to-r from-blue-500 to-blue-700 py-4 pl-5 pr-4 text-sm text-purewhite shadow-m"
                type="default"
                icon={faCheck}
                translationName="common.close"
                onClick={() => history.push("/")}
              />
            )}
          </div>
        </div>
      ) : (
        <LeavingSeriesConfirmation
          seriesName={seasonInfo?.name}
          facilityName={seasonInfo?.facilityName}
        />
      )}
    </>
  );
};
