import { DateOnly } from "../models/DateOnly";
import type {
  BackupPaymentMethod,
  BackupPaymentMethodDTO,
  PendingSwedbankPayBackupPaymentMethod,
} from "../models/backupPaymentMethod";
import type {
  SavedPaymentMethod,
  SavedPaymentMethodDTO,
} from "../models/savedPaymentMethod";

import { deletee, get, post } from "./apiClient";

const API_VERSION = "v1";
const API_SERVICE = "/settings";
const API_ENDPOINT = `/${API_VERSION}${API_SERVICE}`;

export const createPendingSwedbankPayBackupPaymentMethod = async (
  paymentMenuUrl: string,
) =>
  post<PendingSwedbankPayBackupPaymentMethod>({
    endpoint: `${API_ENDPOINT}/payment/swedbankpay/backup-payment-method`,
    body: { paymentMenuUrl },
  });

export const completePendingSwedbankPayBackupPaymentMethod = async (
  paymentOrderId: PendingSwedbankPayBackupPaymentMethod["paymentOrderId"],
) =>
  post({
    endpoint: `${API_ENDPOINT}/payment/swedbankpay/backup-payment-method/complete`,
    body: { paymentOrderId },
    parseDataAs: "none",
  });

export const getBackupPaymentMethod =
  async (): Promise<BackupPaymentMethod | null> =>
    get<BackupPaymentMethodDTO>({
      endpoint: `${API_ENDPOINT}/payment/backup-payment-method`,
    })
      .then(data => ({
        ...data,
        expiryDate: DateOnly.fromISODate(data.expiryDate),
      }))
      .catch(error => {
        if (error instanceof Response && error.status === 404) {
          return null;
        } else {
          throw error;
        }
      });

export const deleteBackupPaymentMethod = async () =>
  deletee({
    endpoint: `${API_ENDPOINT}/payment/backup-payment-method`,
  });

export const getSavedPaymentMethods = async (): Promise<SavedPaymentMethod[]> =>
  get<SavedPaymentMethodDTO[]>({
    endpoint: `${API_ENDPOINT}/payment/saved-payment-methods`,
  }).then(data =>
    data.map(savedPaymentMethod => ({
      ...savedPaymentMethod,
      expiryDate: DateOnly.fromISODate(savedPaymentMethod.expiryDate),
    })),
  );

export const deleteSavedPaymentMethod = async (id: SavedPaymentMethod["id"]) =>
  deletee({
    endpoint: `${API_ENDPOINT}/payment/saved-payment-methods/${id}`,
  });

export const getFeatureFlags = async () =>
  get<string[]>({
    endpoint: `${API_ENDPOINT}/features`,
  });
