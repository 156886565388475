import { ApiResponse, ApiSingleResponse } from "../../../models/common";
import type { IBooking } from "../../checkout/models/Booking";
import type { FacilityWithUtc } from "../../customer/models/Facility";
import {
  Group,
  GroupTypeStrings,
  UpdateGroupNameRequest,
} from "../models/Group";
import type { User } from "../models/User";

import { fetchApi } from "../../../services/legacyApiClient";

const apiVersion = "player/v2";

export const getGroupsByFacilityId = async (
  facilityId?: string,
  type?: GroupTypeStrings,
  signal?: AbortSignal,
): Promise<Group[]> =>
  (
    (await fetchApi({
      uri: `${apiVersion}/groups/find/${facilityId}/${type}`,
      signal,
    })) as ApiResponse<Group>
  )?.data;

export const createGroup = async (data: Group): Promise<Group> =>
  (
    (await fetchApi({
      method: "POST",
      uri: `${apiVersion}/groups`,
      payload: data,
    })) as ApiSingleResponse<Group>
  )?.data;

export const updateGroup = async (
  data: UpdateGroupNameRequest,
): Promise<Group> =>
  (
    (await fetchApi({
      method: "PUT",
      uri: `${apiVersion}/groups`,
      payload: data,
    })) as ApiSingleResponse<Group>
  )?.data;

export const addMember = async (
  groupId: string,
  userId: string,
): Promise<Group> =>
  (
    (await fetchApi({
      method: "POST",
      uri: `${apiVersion}/groups/${groupId}/members/${userId}`,
    })) as ApiSingleResponse<Group>
  )?.data;

export const removeMember = async (
  groupId: string,
  userId: string,
): Promise<Group> =>
  (
    (await fetchApi({
      method: "DELETE",
      uri: `${apiVersion}/groups/${groupId}/members/${userId}`,
    })) as ApiSingleResponse<Group>
  )?.data;

export const addtoMemberList = async (
  facilityId: string,
  userId: string,
): Promise<void> => {
  await fetchApi({
    method: "POST",
    uri: `${apiVersion}/groups/${facilityId}/membershiplist/${userId}`,
  });
};

export const getGroupById = async (
  groupId?: string,
  signal?: AbortSignal,
): Promise<Group> =>
  (
    (await fetchApi({
      uri: `${apiVersion}/groups/${groupId}`,
      signal,
    })) as ApiSingleResponse<Group>
  )?.data;

export const adminAddPlayerToBooking = async (
  facilityId: FacilityWithUtc["id"],
  bookingId: IBooking["id"],
  groupId: Group["id"],
  userId: User["id"],
) =>
  await fetchApi({
    method: "POST",
    uri: `${apiVersion}/groups/admin/addplayertobooking`,
    payload: {
      facilityId,
      bookingId,
      groupId,
      userId,
    },
  });
