import React, { useState } from "react";
import { useIntl } from "react-intl";
import { toast } from "react-toastify";

import { faCalendarXmark } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

import { IAdminCancelBookingRequest } from "../../../../models/Booking";

import { unBookPlayerAdmin } from "../../../../services/Booking";

import { Button } from "../../../../../../components/Button";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  color: black;
  h4,
  p {
    display: flex;
    justify-content: center;
    padding: 0 0.5rem 0 0.5rem;
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  button {
    min-width: 12rem;
    width: 50%;
  }
`;

const IconWrapper = styled.div`
  color: var(--primary);
  display: flex;
  justify-content: center;
`;

interface IProps {
  bookingId: string;
  request: IAdminCancelBookingRequest;
  onCancel: () => void;
  onSubmit: () => void;
}

const AdminConfirmRemovePlayerForm: React.FC<IProps> = ({
  bookingId,
  request,
  onSubmit,
  onCancel,
}) => {
  const intl = useIntl();

  const [submitting, setSubmitting] = useState<boolean>(false);

  const handleCancelBooking = async () => {
    try {
      setSubmitting(true);

      request.alwaysRefund = true;
      const succeed = await unBookPlayerAdmin(bookingId, request);

      if (succeed) {
        toast.success(
          intl.formatMessage({
            id: "toast.success.cancel-done",
          }),
        );

        onSubmit();
      }
    } catch (err) {
      if (err.message) {
        toast.error(err.message);
      } else {
        toast.error(
          intl.formatMessage({
            id: "toast.error.cancel-fail",
          }),
        );
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Wrapper>
      <IconWrapper>
        <FontAwesomeIcon size="3x" icon={faCalendarXmark} />
      </IconWrapper>

      <h4>
        {intl.formatMessage({
          id: "admin.booking.cancel.confirmation",
        })}
      </h4>

      <p>
        {intl.formatMessage({
          id: "admin.booking.cancel-player.prompt",
        })}
      </p>
      {/* <Checkbox
        name="showPriceInput"
        checked={allwaysRefund}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          setAllwaysRefund(e.target.checked)
        }
        label={intl.formatMessage({
          id: "admin.booking.cancel.always-refund",
        })}
      /> */}

      <ButtonWrapper>
        <Button
          onClick={() => onCancel()}
          buttonType="button"
          translationName="admin.booking.cancel-player.abort"
          disabled={submitting}
        />
        <Button
          onClick={handleCancelBooking}
          buttonType="button"
          type="primary"
          translationName="admin.booking.cancel-player.confirm"
          disabled={submitting}
        />
      </ButtonWrapper>
    </Wrapper>
  );
};

export default AdminConfirmRemovePlayerForm;
