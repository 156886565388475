import { FormattedMessage } from "react-intl";

import { faTableTennis } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

import { breakpoints } from "../../../../appConstants/common";

import { Button } from "../../../../components/Button";
import { Dialog } from "../../../../components/Dialog";

interface Props {
  visible: boolean;
  onHide: () => void;
  onCancelClick: () => void;
  onAbortClick: () => void;
}

export const UnsavedChangesDialog: React.FC<Props> = ({
  visible,
  onHide,
  onCancelClick,
  onAbortClick,
}) => {
  return (
    <Dialog onHide={onHide} visible={visible}>
      <Centered>
        <Icon>
          <FontAwesomeIcon icon={faTableTennis} size="5x" />
        </Icon>
        <Title>
          <FormattedMessage
            id="score.unsaved-title"
            defaultMessage="Du har osparad information"
          />
        </Title>
        <Subtitle>
          <FormattedMessage
            id="score.unsaved-subtitle"
            defaultMessage="Om du fortsätter så kommer den information du angett gå förlorad. Vill du fortsätta?"
          />
        </Subtitle>
      </Centered>
      <ButtonRow>
        <Button
          type="default"
          translationName="common.yes"
          text="Ja"
          style={{ justifyContent: "center" }}
          onClick={() => onAbortClick()}
        />
        <Button
          type="primary"
          translationName="common.no"
          text="Nej"
          style={{ justifyContent: "center" }}
          onClick={() => onCancelClick()}
        />
      </ButtonRow>
    </Dialog>
  );
};

const ButtonRow = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  padding: 1rem;
`;
const Icon = styled.div`
  color: var(--primary);
  margin-bottom: 1.5rem;
`;
const Title = styled.h3`
  color: black;
`;
const Subtitle = styled.p`
  color: var(--gray-400);
  font-weight: var(--medium);
  font-size: var(--b3);
`;
const Centered = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 0 5rem;

  @media (max-width: ${breakpoints.MOBILE}) {
    margin: 0 2rem;
  }
`;
