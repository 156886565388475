import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import { faLocationDot } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

import { breakpoints } from "../appConstants/common";

import { getFacilityAddress } from "../helpers/facilityHelpers";

import { useIsMobile } from "../hooks/common/useIsMobile";
import { usePaginatedFacilities } from "../hooks/swr/usePaginatedFacilities";

import { Button } from "../components/Button";
import { Card } from "../components/Card";
import { LogoWithFallback } from "../components/LogoWithFallback";
import { ProgressSpinner } from "../components/ProgressSpinner";
import { VenueSearch } from "../components/inputs/VenueSearch";

import { hasValues } from "../utils/helpers";

const LoadingContainer = styled.div`
  min-height: 60vh;
  display: grid;
  place-items: center;
`;

const Container = styled.div`
  min-height: 55vh;
  display: flex;
  gap: 1rem;
  flex-direction: column;

  @media (min-width: ${breakpoints.XL}) {
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }
`;

const Venues = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media (min-width: ${breakpoints.XL}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;

const SearchResult = styled(Link)`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;
  padding: 1rem;
  border-radius: var(--border-radius);
  max-height: 10rem;
  border: 1px solid var(--gray-200);
  cursor: pointer;

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 0.5rem;
  }

  @media (min-width: ${breakpoints.LARGE}) {
    padding: 1rem;
  }
`;

const Description = styled.div`
  font-weight: var(--medium);
  line-height: 1.2rem;
  font-size: 0.8rem;
  color: var(--gray-600);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  @media (min-width: ${breakpoints.LARGE}) {
    font-size: 0.9rem;
  }
`;

const H4 = styled.h4`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 90%;
`;

const Address = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.2rem;
  color: var(--gray-600);
  font-size: 0.7rem;

  > span {
    font-weight: var(--medium);
  }

  @media (min-width: ${breakpoints.LARGE}) {
    font-size: 0.9rem;
    gap: 0.5rem;
    margin-bottom: 0.5rem;

    > span {
      font-weight: var(--medium);
    }
  }
`;

const ContentContainer = styled.div`
  min-width: 0;
  padding: 0;
  max-height: 100%;
  flex: 1;

  @media (min-width: ${breakpoints.LARGE}) {
    padding: 0.5rem 0 0 0;
  }
`;

export const VenuesOverview: React.FC = () => {
  const isMobile = useIsMobile();
  const [searched, setSearched] = useState(false);
  const [searching, setSearching] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const { facilities, isLoading, isLoadingMore, nextCursor, error, loadMore } =
    usePaginatedFacilities();

  return (
    <Card>
      {isLoading ? (
        <LoadingContainer>
          <ProgressSpinner />
        </LoadingContainer>
      ) : (
        <Container>
          <VenueSearch
            showResults={false}
            onResultChange={setSearchResults}
            onInputChange={value => setSearched(!!value)}
            onSearchingChange={setSearching}
          />

          {searching ? (
            <LoadingContainer style={{ minHeight: "auto", flex: 1 }}>
              <ProgressSpinner />
            </LoadingContainer>
          ) : (
            <Venues>
              {(searched ? searchResults : facilities)?.map(
                ({ id, logo, name, address, description }, i) => (
                  <SearchResult key={i} to={`/venues/${id}`}>
                    <LogoWithFallback
                      src={logo}
                      {...(isMobile
                        ? { width: "4rem", height: "4rem" }
                        : { width: "8rem", height: "8rem" })}
                    />
                    <ContentContainer>
                      <H4>{name}</H4>
                      {hasValues(address) && (
                        <Address>
                          <FontAwesomeIcon icon={faLocationDot} />
                          <span>{getFacilityAddress(address)}</span>
                        </Address>
                      )}
                      <Description>{description}</Description>
                    </ContentContainer>
                  </SearchResult>
                ),
              )}

              {error && !searched && (
                <span className="mx-auto my-8">
                  <FormattedMessage id="facilities.no-facilities-to-show" />
                </span>
              )}

              {searched && !searchResults.length && (
                <span className="mx-auto my-8">
                  <FormattedMessage id="search.no-results" />
                </span>
              )}
            </Venues>
          )}

          {facilities?.length > 0 && !searched && nextCursor && (
            <div className="my-8 flex justify-center">
              <Button
                className="flex items-center gap-5"
                onClick={() => loadMore()}
                disabled={!nextCursor || isLoadingMore}
              >
                <span>
                  <FormattedMessage id="common.show-more" />
                </span>
                {isLoadingMore && <ProgressSpinner fontSize="1rem" />}
              </Button>
            </div>
          )}
        </Container>
      )}
    </Card>
  );
};
