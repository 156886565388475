import { useCurrentUserId } from "../../recoil/currentUserIdState";
import { useUser } from "./useUser";

export const useCurrentUser = () => {
  const currentUserId = useCurrentUserId();

  const { user: currentUser, ...rest } = useUser(currentUserId);

  return {
    ...rest,
    currentUser,
  };
};
