import { useCallback, useRef, useState } from "react";
import { useIntl } from "react-intl";

import { AnimatePresence, motion } from "framer-motion";
import { useSetRecoilState } from "recoil";
import { useOnClickOutside } from "usehooks-ts";

import {
  I18N_CONFIGS,
  I18nConfig,
  LOCALE_TO_FLAG_MAP,
} from "../../appConstants/i18n";

import { useCurrentUser } from "../../hooks/swr/useCurrentUser";

import { updateUserLocale } from "../../modules/player/services/UserService";

import { i18nConfigState, useAppLocale } from "../../recoil/i18nConfigState";

export const ToolbarLanguagePickerItem = () => {
  const intl = useIntl();
  const { currentUser, mutate } = useCurrentUser();
  const appLocale = useAppLocale();
  const setI18nConfig = useSetRecoilState(i18nConfigState);

  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const toggleOpen = useCallback(() => {
    setIsOpen(prev => !prev);
  }, []);

  useOnClickOutside(wrapperRef, () => setIsOpen(false));

  const handleChangeLocale = (locale: I18nConfig["locale"]) => {
    const newI18nConfig = I18N_CONFIGS.find(config => config.locale === locale);

    setI18nConfig(newI18nConfig);

    if (currentUser) {
      mutate(async () => {
        await updateUserLocale(locale);

        return { ...currentUser, locale };
      }, false);
    }
  };

  const flagClasses = "rounded-full w-5 h-5 fib fis";

  return (
    <div ref={wrapperRef} className="relative flex">
      <button
        type="button"
        onClick={toggleOpen}
        title={intl.formatMessage({
          id: "navigation.menuitem.select-language",
        })}
      >
        <div className={`${flagClasses} fi-${LOCALE_TO_FLAG_MAP[appLocale]}`} />
      </button>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            className="absolute right-1/2 top-full z-10 mt-1 rounded-md bg-purewhite shadow-md ring-1 ring-black ring-opacity-5"
            initial="closed"
            animate="open"
            exit="closed"
            variants={{
              open: {
                opacity: 1,
                transition: { staggerChildren: 0.05, duration: 0.2 },
              },
              closed: { opacity: 0, transition: { duration: 0.2 } },
            }}
            style={{ x: "50%" }}
          >
            <motion.div
              className="py-1"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="options-menu"
            >
              {I18N_CONFIGS.map((config, i) => (
                <motion.button
                  key={config.locale}
                  className="flex items-center space-x-2 px-4 py-2 hover:bg-gray-100"
                  role="menuitem"
                  type="button"
                  title={config.label}
                  onClick={() => {
                    if (config.locale === appLocale) {
                      toggleOpen();
                      return;
                    }
                    handleChangeLocale(config.locale);
                    toggleOpen();
                  }}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.2, delay: i * 0.05 }}
                >
                  <div className={`${flagClasses} fi-${config.countryCode}`} />
                  <p className="text-md font-bold text-primary-dark">
                    {config.label}
                  </p>
                </motion.button>
              ))}
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
