import { AtomEffect } from "recoil";

export const localStoragePersistenceEffect =
  <T>(key: string): AtomEffect<T> =>
  ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem(key);

    if (savedValue != null) {
      // try catch to handle old values that were not JSON
      try {
        const parsedValue = JSON.parse(savedValue);
        setSelf(parsedValue);
      } catch (error) {
        setSelf(savedValue as T);
      }
    }

    onSet((newValue, _, isReset) => {
      isReset
        ? localStorage.removeItem(key)
        : localStorage.setItem(key, JSON.stringify(newValue));
    });
  };
