import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import { FacilityWithUtc } from "../../../../modules/customer/models/Facility";
import { User } from "../../../../modules/player/models/User";

import { useToaster } from "../../../../hooks/common/useToaster";

import { adminRemoveUserFromVenue } from "../../../../modules/player/services/UserService";

import { Button } from "../../../../components/Button";
import { ConfirmationDialog } from "../../../../components/ConfirmationDialog";

import { useSelectedFacilityId } from "../../../../recoil/selectedFacilityIdState";

interface Props {
  userId: User["id"];
}

export const AdminPlayerManageSection = ({ userId }: Props) => {
  const history = useHistory();
  const { formatMessage } = useIntl();
  const selectedFacilityId = useSelectedFacilityId();
  const { toastError } = useToaster();
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  const handleRemoveFromVenue = async (
    facilityId: FacilityWithUtc["id"],
    userId: User["id"],
  ) => {
    try {
      await adminRemoveUserFromVenue(facilityId, userId);
      history.replace("/admin/players");
    } catch {
      toastError.generalFailure();
    } finally {
      setShowConfirmationDialog(false);
    }
  };

  if (!userId || !selectedFacilityId) return null;

  return (
    <div>
      <h3>
        <FormattedMessage id="common.manage" />
      </h3>
      <Button
        className="mt-4 w-1/4 min-w-min sm:w-1/3"
        size="small"
        type="danger"
        text={formatMessage({
          id: "admin.player.remove-from-facility",
        })}
        onClick={() => setShowConfirmationDialog(true)}
      />

      {showConfirmationDialog && (
        <ConfirmationDialog
          visible
          title={`${formatMessage({
            id: "admin.remove-player",
          })}?`}
          text={formatMessage({
            id: "admin.player.remove-from-facility.text",
          })}
          denyText={formatMessage({
            id: "common.cancel",
          })}
          confirmText={formatMessage({
            id: "common.remove",
          })}
          onHide={() => setShowConfirmationDialog(false)}
          onCancel={() => setShowConfirmationDialog(false)}
          onSubmit={() => handleRemoveFromVenue(selectedFacilityId, userId)}
          buttonSize="small"
          confirmButtonType="danger"
        />
      )}
    </div>
  );
};
