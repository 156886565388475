import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import {
  SeriesDivision,
  SeriesScoreboard,
} from "../../../../modules/game/models/Series";

import { getDivisionScoreboard } from "../../../../modules/game/services/Serie";

import { Scoreboard } from "../../../../components/Scoreboard";
import { SelectInput } from "../../../../components/inputs/SelectInput";

interface Props {
  serieId: string;
  divisions: SeriesDivision[];
  divisionId?: string;
}

export const ScoreboardTab: React.FC<Props> = ({
  serieId,
  divisions,
  divisionId,
}) => {
  const abortController = useRef<AbortController>();
  const history = useHistory();
  const [score, setScore] = useState<SeriesScoreboard>(null);
  const [selectedDivisionId, setSelectedDivisionId] =
    useState<string>(divisionId);

  useEffect(() => {
    abortController.current = new AbortController();
    const fetchScore = async () => {
      const res = await getDivisionScoreboard(
        serieId,
        selectedDivisionId,
        abortController.current?.signal,
      );
      setScore(res?.data?.[0]);
    };

    if (serieId && selectedDivisionId) {
      fetchScore();
    }
  }, [serieId, selectedDivisionId]);

  return (
    <>
      <div className="flex justify-between">
        <h4>{score?.divisionName}</h4>
        <div className="flex w-40">
          <SelectInput
            value={divisions?.find(d => d.id === selectedDivisionId)}
            options={divisions?.map(d => ({
              label: d.name,
              value: d,
            }))}
            onChange={e => {
              setSelectedDivisionId(e.value?.id);
              history.replace(`/series/${serieId}/scoreboard/${e.value?.id}`);
            }}
          />
        </div>
      </div>
      <Scoreboard scoreboard={score?.scoreboard} />
    </>
  );
};
