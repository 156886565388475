import { useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { faClose } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateTime } from "luxon";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import styled from "styled-components";

import { breakpoints } from "../../../appConstants/common";

import { Access } from "../models/Access";

import { useIsMobile } from "../../../hooks/common/useIsMobile";
import { useToaster } from "../../../hooks/common/useToaster";
import { useForceDateTimeToSelectedFacilityTimeZone } from "../../../hooks/datetime/useForceDateTimeToSelectedFacilityTimeZone";
import { useDateFormatWithSelectedFacility } from "../../../hooks/useDateFormat";

import {
  addAccessTime,
  getAccessTimes,
  removeAccessTime,
} from "../services/AccessService";

import { Button } from "../../../components/Button";

import { luxonDateTimeFormat } from "../../../utils/dateFormats";

const Box = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: end;

  @media (min-width: ${breakpoints.MOBILE}) {
    flex-direction: row;
  }
`;

const InputContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: start;
  align-items: center;
  gap: 1rem;

  @media (min-width: ${breakpoints.MOBILE}) {
    justify-content: space-between;
    width: unset;
  }
`;

const Icon = styled(FontAwesomeIcon)`
  color: var(--danger);
  cursor: pointer;
`;

interface Props {
  facilityId: string;
}

export const QTFacilityAccessTimeDetails = ({ facilityId }: Props) => {
  const isMobile = useIsMobile();
  const intl = useIntl();
  const { df } = useDateFormatWithSelectedFacility();
  const { toastError, toastSuccess } = useToaster();
  const [validStartTime, setValidStartTime] = useState<DateTime>(
    DateTime.now().plus({ minutes: 5 }),
  );
  const [validEndTime, setValidEndTime] = useState<DateTime>(
    DateTime.now().plus({ hours: 1 }),
  );
  const [accessTimes, setAccessTimes] = useState<Access[]>(null);

  const accessAbortController = useRef<AbortController>();
  const { forceDateTimeToSelectedFacilityTimeZone } =
    useForceDateTimeToSelectedFacilityTimeZone();

  const sortAndSetAccessTimes = (data: Access[]) => {
    data?.sort((r1, r2) => r1.time.from.valueOf() - r2.time.from.valueOf());
    setAccessTimes(data || []);
  };

  useEffect(() => {
    const fetchTimes = async () => {
      try {
        const result = await getAccessTimes(facilityId);
        sortAndSetAccessTimes(result?.data);
      } catch {
        setAccessTimes([]);
      }
    };

    if (facilityId) {
      fetchTimes();
    }

    return () => accessAbortController.current?.abort();
  }, []);

  const onSubmit = async () => {
    if (validStartTime < DateTime.now()) {
      setValidStartTime(DateTime.now().plus({ minutes: 5 }));
      return toastError.facilityDoorOpenTimeAddFailed();
    }

    const startTimeDateTime =
      forceDateTimeToSelectedFacilityTimeZone(validStartTime);
    const endTimeDateTime =
      forceDateTimeToSelectedFacilityTimeZone(validEndTime);

    try {
      const result = await addAccessTime(
        facilityId,
        startTimeDateTime,
        endTimeDateTime,
      );
      sortAndSetAccessTimes(result?.data);
      toastSuccess.facilityDoorOpenTimeAdded();
    } catch {
      toastError.facilityDoorOpenTimeAddFailed();
    }
  };

  const onDelete = async (access: Access) => {
    if (access?.locked) return;

    try {
      const result = await removeAccessTime(facilityId, access?.id);
      sortAndSetAccessTimes(result?.data);
    } catch (e) {
      setAccessTimes([]);
    }
  };

  return (
    <>
      <div className="row flex items-center gap-x-5">
        <Box>
          <InputContainer>
            <label>
              <FormattedMessage id="common.from" defaultMessage="Från" />
            </label>
            <Calendar
              selectOtherMonths
              onChange={e =>
                setValidStartTime(DateTime.fromJSDate(e.target.value as Date))
              }
              value={validStartTime.toJSDate()}
              minDate={new Date()}
              showTime
              touchUI={isMobile}
            />
          </InputContainer>
          <InputContainer>
            <label>
              <FormattedMessage id="common.to" defaultMessage="till" />
            </label>
            <Calendar
              selectOtherMonths
              onChange={e =>
                setValidEndTime(DateTime.fromJSDate(e.target.value as Date))
              }
              value={validEndTime.toJSDate()}
              minDate={validStartTime.plus({ minutes: 15 }).toJSDate()}
              showTime
              touchUI={isMobile}
            />
          </InputContainer>
          <Button
            type="primary"
            text="Spara"
            translationName="common.save"
            onClick={onSubmit}
            disabled={!validStartTime || !validEndTime}
          />
        </Box>
      </div>

      {!!accessTimes?.length && (
        <>
          <h4>
            <FormattedMessage id="common.upcoming" defaultMessage="Kommande" />
          </h4>
          <DataTable dataKey="id" value={accessTimes || []}>
            <Column
              body={(access: Access) =>
                df(access?.time?.from, luxonDateTimeFormat)
              }
              header={intl.formatMessage({
                id: "common.from",
                defaultMessage: "Från",
              })}
            />
            <Column
              body={(access: Access) =>
                df(access?.time?.to, luxonDateTimeFormat)
              }
              header={intl.formatMessage({
                id: "common.to",
                defaultMessage: "Till",
              })}
            />
            <Column
              body={(access: Access) =>
                !access?.locked ? (
                  <Icon onClick={() => onDelete(access)} icon={faClose} />
                ) : null
              }
            />
          </DataTable>
        </>
      )}
    </>
  );
};
