export const PadelIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.53809 10.3842L4.33836 9.63689C5.90111 8.07414 4.94542 4.36724 7.18203 2.13063C8.75252 0.560109 10.5392 0.569066 12.4851 2.51489C14.4309 4.46072 14.4399 6.24745 12.8694 7.81797C10.6328 10.0546 6.92586 9.0989 5.36311 10.6616L2.24554 14L1 12.7544L1.80016 12.0072"
        fill="white"
      />
      <path
        d="M3.53809 10.3842L4.33836 9.63689C5.90111 8.07414 4.94542 4.36724 7.18203 2.13063C8.75252 0.560109 10.5392 0.569066 12.4851 2.51489C14.4309 4.46072 14.4399 6.24745 12.8694 7.81797C10.6328 10.0546 6.92586 9.0989 5.36311 10.6616L2.24554 14L1 12.7544L1.80016 12.0072"
        stroke="black"
        strokeWidth="0.5"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.92407 9.07614C6.58612 8.80993 7.3226 8.67362 8.06855 8.55115C7.75274 8.34581 7.45245 8.1042 7.17424 7.82597C6.89601 7.54774 6.6544 7.24745 6.44907 6.93164C6.32657 7.67758 6.19028 8.41406 5.92407 9.07614Z"
        fill="white"
        stroke="black"
        strokeWidth="0.5"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.41577 9.71484L5.28508 10.5842"
        stroke="black"
        strokeWidth="0.5"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.99561 2.64062L8.99732 2.64234"
        stroke="black"
        strokeWidth="0.5"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.15503 3.48242L8.15675 3.48415"
        stroke="black"
        strokeWidth="0.5"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.31421 4.32227L7.31593 4.32398"
        stroke="black"
        strokeWidth="0.5"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.6772 2.64062L10.6787 2.64234"
        stroke="black"
        strokeWidth="0.5"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.83667 3.48242L9.83838 3.48415"
        stroke="black"
        strokeWidth="0.5"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.99561 4.32227L8.99732 4.32369"
        stroke="black"
        strokeWidth="0.5"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.15503 5.16211L8.15646 5.16382"
        stroke="black"
        strokeWidth="0.5"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.31421 6.00391L7.31593 6.00563"
        stroke="black"
        strokeWidth="0.5"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5181 3.48242L11.5198 3.48415"
        stroke="black"
        strokeWidth="0.5"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.6772 4.32227L10.679 4.32398"
        stroke="black"
        strokeWidth="0.5"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.83667 5.16211L9.83809 5.16382"
        stroke="black"
        strokeWidth="0.5"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.99561 6.00391L8.99732 6.00534"
        stroke="black"
        strokeWidth="0.5"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.15503 6.84375L8.15675 6.84547"
        stroke="black"
        strokeWidth="0.5"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.3589 4.32227L12.3606 4.32398"
        stroke="black"
        strokeWidth="0.5"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5181 5.16211L11.5195 5.16382"
        stroke="black"
        strokeWidth="0.5"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.6772 6.00391L10.679 6.00563"
        stroke="black"
        strokeWidth="0.5"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.83667 6.84375L9.83838 6.84547"
        stroke="black"
        strokeWidth="0.5"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.99561 7.68555L8.99732 7.68698"
        stroke="black"
        strokeWidth="0.5"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.3589 6.00391L12.3606 6.00563"
        stroke="black"
        strokeWidth="0.5"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5181 6.84375L11.5198 6.84547"
        stroke="black"
        strokeWidth="0.5"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.6772 7.68555L10.679 7.68727"
        stroke="black"
        strokeWidth="0.5"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.66895 11.1953H2.67036"
        stroke="black"
        strokeWidth="0.5"
        strokeMiterlimit="2.6131"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
