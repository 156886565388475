import { FormattedMessage } from "react-intl";

import { DateTime } from "luxon";
import styled from "styled-components";

import { FacilityWithUtc } from "../../../../../customer/models/Facility";

import { useDateFormat } from "../../../../../../hooks/useDateFormat";

import { luxonDateTimeFormat } from "../../../../../../utils/dateFormats";

interface ContainerProps {
  isOldTime: boolean;
}

const Container = styled.div<ContainerProps>`
  padding: 2rem;
  background: var(--gray-100);

  ${props =>
    props?.isOldTime &&
    `
    opacity: 0.7;
  `}
`;

const TimeWrapper = styled.div`
  display: inline-block;
  text-align: left;

  &::first-letter {
    text-transform: capitalize;
  }
`;

interface Props {
  facilityId: FacilityWithUtc["id"];
  startTime: DateTime;
  endTime: DateTime;
  courtName: string;
  isOldTime?: boolean;
}

export const RescheduleBox: React.FC<Props> = ({
  facilityId,
  startTime,
  endTime,
  courtName,
  isOldTime,
}) => {
  const { df } = useDateFormat(facilityId);
  return (
    <Container isOldTime={isOldTime}>
      {isOldTime && (
        <h5>
          <FormattedMessage
            id="reschedule.confirm.old-time.header"
            defaultMessage="Nuvarande"
          />
        </h5>
      )}
      {!isOldTime && (
        <h5>
          <FormattedMessage
            id="reschedule.confirm.new-time.header"
            defaultMessage="Ny tid"
          />
        </h5>
      )}

      <TimeWrapper>
        <p>
          <FormattedMessage
            id="reschedule.confirm.court-name"
            defaultMessage="Bana: {courtName}"
            values={{ courtName: courtName }}
          />
        </p>
        <p>
          <FormattedMessage
            id="reschedule.confirm.start-time"
            defaultMessage="Starttid: {startTime}"
            values={{
              startTime: df(startTime, luxonDateTimeFormat),
            }}
          />
        </p>
        <p>
          <FormattedMessage
            id="reschedule.confirm.end-time"
            defaultMessage="Sluttid: {endTime}"
            values={{
              endTime: df(endTime, luxonDateTimeFormat),
            }}
          />
        </p>
      </TimeWrapper>
    </Container>
  );
};
