import { Link } from "react-router-dom";

import { faClock } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";

import { SeriesMatch } from "../../../../../../../../../modules/game/models/Series";

import { useDateFormat } from "../../../../../../../../../hooks/useDateFormat";

import { TeamCard } from "./components/TeamCard";

import { luxonTimeFormat } from "../../../../../../../../../utils/dateFormats";

interface Props {
  className?: string;
  match?: SeriesMatch;
  link: string;
}

export const MatchListItem = ({ className, match, link }: Props) => {
  const { dfInterval } = useDateFormat(match?.facilityId);

  const wrapperClasses = clsx(
    "flex w-full cursor-pointer items-center overflow-x-auto rounded border border-gray-500 py-5 font-inherit text-inherit hover:outline hover:outline-1",
    className,
  );

  const setsGridClasses = clsx(
    "whitespace-nowrap border-gray-500 px-2 font-bold [&:nth-child(3n-1)]:border-y",
    {
      "[&:nth-child(-n+3)]:border-r": match?.sets?.length > 3,
    },
  );

  if (!match) {
    return null;
  }

  return (
    <li>
      <Link to={link} className={wrapperClasses}>
        {/*Booking info*/}
        <div className="ml-10 flex flex-col gap-y-1 border-r border-gray-500 pr-10">
          <span className="font-bold">{match?.courtName}</span>
          <span className="text-sm font-bold text-gray-500">
            {match?.division?.name}
          </span>
          <span className="flex items-center gap-x-1 whitespace-nowrap font-bold">
            <FontAwesomeIcon icon={faClock} />
            {dfInterval(match?.startTime, match?.endTime, luxonTimeFormat)}
          </span>
        </div>

        {/*Team cards*/}
        <div className="flex grow items-center justify-center gap-x-5">
          <TeamCard
            className="ml-5"
            score={match?.homeTeam?.score}
            team={match?.homeTeam?.team}
            type="left"
            winningTeamId={match?.winningTeamId}
          />
          <span className="font-bold">VS.</span>
          <TeamCard
            className="mr-5"
            score={match?.opponentTeam?.score}
            team={match?.opponentTeam?.team}
            type="right"
            winningTeamId={match?.winningTeamId}
          />
        </div>

        {/*Set results*/}
        <div className="mr-10 grid grid-flow-col grid-rows-3 border-l border-gray-500 pl-10">
          {match?.sets?.map((set, index) => {
            return (
              <span className={setsGridClasses} key={index}>
                {set.homeGames} - {set.opponentGames}
              </span>
            );
          })}
        </div>
      </Link>
    </li>
  );
};
