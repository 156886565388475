import { useCallback } from "react";

import { createOrderPaymentWithSwedbankPaySwish } from "../../../../services/myOrdersService";

import { createUrlSearchParams } from "../../../../utils/createUrlSearchParams";
import { useCheckoutState } from "../../context/CheckoutContext";
import { SwedbankPayPayment } from "./SwedbankPay";

export const SwedbankPaySwish = () => {
  const { order, isSplitPayment, coPayingUsers } = useCheckoutState();

  const createOrderPayment = useCallback(async () => {
    const url = new URL(window.location.href);
    const searchParams = createUrlSearchParams({
      action: "swishRedirectCallback",
    });

    url.hash = url.hash + "?" + searchParams.toString();

    return createOrderPaymentWithSwedbankPaySwish(order.id, {
      paymentMenuUrl: url.toString(),
      splitPayment: isSplitPayment,
      coPayingUserIds: isSplitPayment ? coPayingUsers.map(user => user.id) : [],
    });
  }, [coPayingUsers, isSplitPayment, order.id]);

  return <SwedbankPayPayment createOrderPayment={createOrderPayment} />;
};
