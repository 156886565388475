import { DateOnly } from "../../../../../models/DateOnly";
import { FacilityWithUtc } from "../../../../customer/models/Facility";

import AdminCalendarContent from "./AdminCalendarContent";
import {
  AdminCalendarProvider,
  useAdminCalendarContextState,
} from "./AdminCalendarContext";
import AdminCalendarMenu from "./AdminCalendarMenu";

interface Props {
  facilityId: FacilityWithUtc["id"];
}

export const AdminCalendar = ({ facilityId }: Props) => {
  return (
    <AdminCalendarProvider
      facilityId={facilityId}
      selectedDate={DateOnly.today()}
      selectedBookingTypes={[]}
      isExpanded={false}
      key={facilityId}
    >
      <Calendar />
    </AdminCalendarProvider>
  );
};

const Calendar = () => {
  const { isExpanded } = useAdminCalendarContextState();

  return (
    <div
      className={isExpanded ? "ml-[calc(-50vw+50%)] w-screen px-4" : undefined}
    >
      <AdminCalendarMenu />

      <AdminCalendarContent />
    </div>
  );
};
