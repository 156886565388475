export const commonRegex = {
  url: /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/,
  phoneNumber: /^[+]?[0-9]+$/,
  giro: /^[0-9]+(-[0-9]+)+$/,
  postalCode: /^[0-9]+$/,
  organizationsNumber: /^[\d-]+$/,
  name: /^[^±!@£$%^&*_+§¡€#¢§¶•ªº«\\/<>?:;|=.,\d{}()^¨~]{0,50}$/,
  personalIdentificationNumber: {
    SE: /^\d{6}[-+]\d{4}$/,
    FI: /^\d{6}[-A]\d{3}[A-Z0-9]$/,
  },
};
