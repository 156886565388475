import React, { useState } from "react";
import { useDrop } from "react-dnd";

import clsx from "clsx";

import {
  SeasonStatus,
  Series,
  SeriesDivision,
  SeriesTeam,
} from "../../../models/Series";

import { DndTypes } from "../../../helpers/DndTypes";

import { useToaster } from "../../../../../hooks/common/useToaster";

import { Dialog } from "../../../../../components/Dialog";

import DivisionsEditorDivisionDetails from "./DivisionsEditorDivisionDetails";
import AdminEditDivisionForm from "./details/form/AdminEditDivisionForm";

interface IProps {
  serie: Series;
  division: SeriesDivision;
  isActive: boolean;
  reload: () => void;
  onDrop: (division: SeriesDivision, team: SeriesTeam) => void;
  onActiveDivisionIdChange: (divisionId: SeriesDivision["id"]) => void;
}

const DivisionsEditorDivision: React.FC<IProps> = ({
  serie,
  division,
  isActive,
  reload,
  onDrop,
  onActiveDivisionIdChange,
}) => {
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const { toastError } = useToaster();

  const validSeasonStatus = ![
    SeasonStatus.OpenForRegistrationPlacementLocked,
    SeasonStatus.Closed,
  ].includes(serie?.seasonStatus);

  const validDrop =
    validSeasonStatus && division.teams.length < division.numberOfTeams;

  const handleDrop = (division: SeriesDivision, team: SeriesTeam) => {
    if (
      serie.seasonStatus === SeasonStatus.OpenForRegistrationPlacementLocked
    ) {
      toastError.addTeamToDivisionFailedDueToLockedSeason();
      return;
    }

    if (serie.seasonStatus === SeasonStatus.Closed) {
      toastError.updateFailed();
      return;
    }

    onDrop(division, team);
  };

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: DndTypes.DivisionTeam,
    drop: (item: SeriesTeam) => handleDrop(division, item),
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop() && validDrop,
    }),
  });

  return (
    <>
      <div
        ref={drop}
        role="Dustbin"
        className={clsx("divisions-editor-division", {
          "divisions-editor-division__active": isActive,
          "divisions-editor-division__canDrop": canDrop,
          "divisions-editor-division__isOver": isOver,
        })}
        onClick={() => onActiveDivisionIdChange(!isActive ? division.id : "")}
      >
        <div>{division.name}</div>
        <div
          className={clsx({
            "text-danger": division.teams?.length > division?.numberOfTeams,
            "text-success": division.teams?.length <= division?.numberOfTeams,
          })}
        >
          {division.teams?.length} / {division?.numberOfTeams}
        </div>
      </div>
      {isActive && (
        <DivisionsEditorDivisionDetails
          reload={reload}
          serie={serie}
          division={division}
          onActiveDivisionIdChange={onActiveDivisionIdChange}
          onEditDialogStateChange={setEditDialogOpen}
        />
      )}

      {editDialogOpen && (
        <Dialog visible={true} onHide={() => setEditDialogOpen(false)}>
          <AdminEditDivisionForm
            seriesId={serie?.id}
            facilityId={serie?.facilityId}
            division={division}
            onSubmitted={() => setEditDialogOpen(false)}
          />
        </Dialog>
      )}
    </>
  );
};

export default DivisionsEditorDivision;
