import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import {
  faTableTennis,
  faUserMinus,
  faUserPlus,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

import { breakpoints } from "../../../appConstants/common";

import { Friend } from "../models/Friend";

import { useToaster } from "../../../hooks/common/useToaster";
import { useCurrentUser } from "../../../hooks/swr/useCurrentUser";

import { accepetFriendRequest } from "../services/FriendsService";

import { Button } from "../../../components/Button";
import { ProfileImageWithFallback } from "../../../components/ProfileImageWithFallback";
import { ProgressSpinner } from "../../../components/ProgressSpinner";

interface IProps {
  friend: Friend;
  invitationLink: string;
  isLoggedIn: boolean;
}

const Details = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin-top: 3rem;
  padding: 4rem 1rem 1rem;
  font-size: var(--h6);

  @media (min-width: ${breakpoints.XL}) {
    padding: 5rem 4rem;
  }
`;

const DetailsItem = styled.div`
  padding: 0.6rem 0.5rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  gap: 0.2rem;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  color: black;

  svg {
    color: var(--primary);
  }
`;

const DetailsButtonWrapper = styled.div`
  gap: 2rem;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
`;

const FriendsInvitation: React.FC<IProps> = ({
  friend,
  invitationLink,
  isLoggedIn,
}) => {
  const { formatMessage } = useIntl();
  const { toastError } = useToaster();
  const [responsed, setResponse] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [accpted, setAccepted] = useState<boolean>(false);
  const { currentUser } = useCurrentUser();

  const Player = styled.div`
    display: flex;
    justify-content: center;

    align-items: center;
    gap: 0.3rem;
    margin-top: 0.4rem;
    margin-right: 1.5rem;
    margin-left: 2rem;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    }
  `;

  const DetailsSubtitle = styled.div`
    margin-bottom: 0.5rem;
    margin-top: 2rem;
    padding: 0.5rem 1rem;
    font-size: var(--h4);
  `;

  const Message = styled.div`
    display: flex;

    justify-content: center;
    font-size: var(--b);
    gap: 1rem;
  `;
  const UseName = styled.div`
    font-size: var(--b);
    color: var(--primary);
  `;

  const handleAcceptClick = async (isAccepted: boolean) => {
    try {
      setLoading(true);
      const succeed = await accepetFriendRequest(invitationLink, isAccepted);
      if (succeed) {
        setResponse(true);
        setAccepted(isAccepted);
      }
    } catch (err) {
      toastError.acceptFriendRequestFailed();
    } finally {
      setLoading(false);
    }
  };

  const responseMessage = () => {
    if (responsed && accpted) {
      return (
        <Message>
          <FormattedMessage
            id="friend-invitation.accepted-message"
            defaultMessage="Du är nu vän med"
          />
          <UseName>{friend?.displayName} </UseName>
        </Message>
      );
    } else if (responsed && !accpted) {
      return (
        <Message>
          <FormattedMessage
            id="friend-invitation.rejected-message"
            defaultMessage="Du har nekat vänförfrågan av"
          />
          <UseName>{friend?.displayName} </UseName>
        </Message>
      );
    } else if (!responsed) {
      return (
        <Message>
          {`${friend?.displayName} ${formatMessage({
            id: "friend-invitation.invite-message",
          })}`}
        </Message>
      );
    }
  };

  return (
    <div>
      {!isLoggedIn && (
        <h3 className="friends-invitation__subtitle">
          <FormattedMessage
            id="friends-invitation.not-logged-in-message"
            defaultMessage="Du kommer att skickas till ett registreringsformulär alldeles. Registrera dig för att fortsätta med inbjudan"
          />
        </h3>
      )}

      {isLoggedIn && !friend && <ProgressSpinner />}

      {loading && <ProgressSpinner />}

      {isLoggedIn && friend && !loading && (
        <Details>
          <Player>
            <ProfileImageWithFallback
              width="9rem"
              height="9rem"
              src={friend?.profileImage}
              firstName={friend?.firstName}
              lastName={friend?.lastName}
              fontSize="3.5rem"
            />
          </Player>

          <DetailsItem>
            <FontAwesomeIcon icon={faTableTennis} />
            <FormattedMessage
              id="user-profile.friends.skill-level"
              defaultMessage="Skicklighet: {skillLevel}"
              values={{ skillLevel: friend?.skillLevel }}
            />
          </DetailsItem>

          {friend?.isFriend || friend?.id === currentUser?.id ? (
            <DetailsSubtitle>
              <Message>
                <FormattedMessage
                  id="friend-invitation.already_friend"
                  defaultMessage="Du är redan vän med"
                />
                <UseName>{friend?.displayName} </UseName>
              </Message>
            </DetailsSubtitle>
          ) : (
            <DetailsSubtitle>{responseMessage()}</DetailsSubtitle>
          )}

          <DetailsItem>
            <DetailsButtonWrapper>
              {!responsed &&
                !friend?.isFriend &&
                friend.id !== currentUser?.id && (
                  <Button
                    icon={faUserMinus}
                    text="Inte nu"
                    buttonType="button"
                    disabled={loading}
                    translationName="friends-invitation.button.reject"
                    onClick={() => handleAcceptClick(false)}
                  />
                )}

              {!responsed &&
                !friend?.isFriend &&
                friend?.id !== currentUser?.id && (
                  <Button
                    icon={faUserPlus}
                    text="Acceptera"
                    buttonType="button"
                    disabled={loading}
                    type="primary"
                    translationName="friends-invitation.button.confirm"
                    onClick={() => handleAcceptClick(true)}
                  />
                )}
            </DetailsButtonWrapper>
          </DetailsItem>
        </Details>
      )}
    </div>
  );
};

export default FriendsInvitation;
