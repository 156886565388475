import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

import { getMyProfilePath } from "../../../helpers/pathHelpers";

import { useCurrentUser } from "../../../hooks/swr/useCurrentUser";

import { cardVerify } from "../../../modules/player/services/UserService";

interface Props {
  operation: string;
}

export const CardVerify: React.FC<Props> = ({ operation }) => {
  const { currentUser } = useCurrentUser();
  const [renderOtherCardButton, setRenderOtherCardButton] =
    useState<boolean>(false);

  const renderIframe = async (iframeUrl: string) => {
    const wrapperDiv = document.getElementById(
      "swedbank-pay-seamless-view-page",
    );

    // make sure wrapper is empty before rendering the iframe
    if (wrapperDiv && wrapperDiv.innerHTML !== "") wrapperDiv.innerHTML = "";

    const paymentScript = document.createElement("script");
    paymentScript.src = iframeUrl;
    paymentScript.onload = function () {
      (window as any)?.payex?.hostedView
        .creditCard({ container: "swedbank-pay-seamless-view-page" })
        .open();
    };
    wrapperDiv?.appendChild(paymentScript);
  };

  const initVerify = async (prefilledInformationIsUsed?: boolean) => {
    const verify = await cardVerify({
      paymentCompleteUrl: `${
        window.location.origin
      }/#${getMyProfilePath()}/3?verifyCompleted=true&paymentId={2}`,
      cardPaymentUrl: `${
        window.location.origin
      }/#${getMyProfilePath()}/3?verifyPending=true&paymentId={2}`,
      prefilledInformationIsUsed:
        prefilledInformationIsUsed ?? operation !== "change",
    });
    setRenderOtherCardButton(
      verify?.userPaymentInfoExists && operation !== "change",
    );
    await renderIframe(verify?.paymentUrl);
  };

  useEffect(() => {
    if (currentUser) {
      initVerify();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  return (
    <div>
      <h3 className="text-pureblack">
        <FormattedMessage id="card-details.verify-card-dialog.header" />
      </h3>
      <p>
        <FormattedMessage id="card-details.verify-card-dialog.details" />
      </p>
      <div id="swedbank-pay-seamless-view-page"></div>
      {renderOtherCardButton && (
        <div className="mt-1 flex justify-center" id="card-forget">
          <div className="cursor-pointer" onClick={() => initVerify(false)}>
            <FormattedMessage id="payment.card.forget-payment-data" />
          </div>
        </div>
      )}
    </div>
  );
};
