import { BookingType } from "../../../../modules/checkout/models/Booking";

import { useBookingTypes } from "../../../../modules/checkout/hooks/calendar/useBookingTypes";

interface Props {
  bookingType: BookingType;
}

export const BookingTypeLabel: React.FC<Props> = ({ bookingType }) => {
  const [message, color, textColor] = useBookingTypes(bookingType);

  return (
    <div
      className="flex items-center space-x-2 rounded-t-md p-1 pl-2 text-sm"
      style={{ background: color, color: textColor }}
    >
      {message}
    </div>
  );
};
