import { DateTime } from "luxon";

interface DateDurations {
  years?: number | undefined;
  quarters?: number | undefined;
  months?: number | undefined;
  weeks?: number | undefined;
  days?: number | undefined;
}

export class DateOnly {
  private _dateTime: DateTime;

  public static fromDateTime(dateTime: DateTime): DateOnly {
    const dateOnly = new DateOnly();
    dateOnly._dateTime = dateTime
      .toUTC(0, { keepLocalTime: true })
      .startOf("day");

    return dateOnly;
  }

  public static fromISODate(isoDate: string): DateOnly {
    return DateOnly.fromDateTime(DateTime.fromISO(isoDate, { zone: "utc" }));
  }

  public static fromDateParts(
    year: number,
    month: number,
    day: number,
  ): DateOnly {
    return DateOnly.fromDateTime(DateTime.local(year, month, day));
  }

  public static today(): DateOnly {
    return DateOnly.fromDateTime(DateTime.now());
  }

  public plus(dateParts: DateDurations): DateOnly {
    return DateOnly.fromDateTime(this._dateTime.plus(dateParts));
  }

  public minus(dateParts: DateDurations): DateOnly {
    return DateOnly.fromDateTime(this._dateTime.minus(dateParts));
  }

  public compare(other: DateOnly): number {
    return Math.sign(this._dateTime.toMillis() - other._dateTime.toMillis());
  }

  public toDateTime(): DateTime {
    return this._dateTime.toUTC();
  }

  public toDateTimeZoned(zone: string): DateTime {
    return this._dateTime.setZone(zone);
  }

  public valueOf(): string {
    return this.toISO();
  }

  public toISO(): string {
    return this._dateTime.toISODate();
  }

  public toJSON(): string {
    return this.toISO();
  }
}
