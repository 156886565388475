interface Props {
  icon: React.ReactElement;
  title: string | React.ReactElement;
  text: string | number | React.ReactElement;
  info: string | React.ReactElement;
}

export const MembershipInfoBox: React.FC<Props> = ({
  icon,
  title,
  text,
  info,
}) => {
  return (
    <div className="flex basis-2/4 bg-white p-2 text-pureblack">
      <div className="p-4">{icon}</div>
      <div className="flex flex-col justify-center">
        <h5>{title}</h5>
        <h4>{text}</h4>
        <p className="text-xs text-gray-500"> {info}</p>
      </div>
    </div>
  );
};
