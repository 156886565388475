import { FormattedMessage } from "react-intl";

import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faHorizontalRule, faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

import { Games } from "../models/Games";

import { useDateFormat } from "../../../hooks/useDateFormat";

import { Dialog } from "../../../components/Dialog";
import { UsersList } from "../../../components/UsersList";

import { luxonTimeFormat } from "../../../utils/dateFormats";

const StyledDialog = styled(Dialog)`
  margin: 0 !important;
`;

const Divider = styled.div`
  border-bottom: 1px solid var(--gray-200);
`;

const IconAndTextWrapper = styled.div<StyleProps>`
  display: flex;
  gap: 1rem;
  font-weight: var(--medium);
  align-items: center;
  color: black;
  cursor: pointer;

  ${props => props.$disabled && "color: var(--gray-200); cursor: not-allowed;"}

  svg {
    color: var(--gray-400);
    ${props =>
      props.$disabled && "color: var(--gray-200); cursor: not-allowed;"}
  }
`;

const IconWrapper = styled.div`
  width: 1.3rem;
`;

interface StyleProps {
  $disabled?: boolean;
}
interface BookingActionsDialogProps {
  game: Games;
  visible: boolean;

  options: Option[];

  onHide?: () => void;
}

interface Option {
  icon: IconDefinition;
  disabled?: boolean;
  hidden?: boolean;
  translationId: string;
  defaultMessage: string;
  values?: any;
  onClick?: (e?: any) => any;
}

export default function BookingActionsDialog({
  game,
  visible,
  options,
  onHide,
}: BookingActionsDialogProps): any {
  const { df, dfInterval } = useDateFormat(game?.facility?.id);

  return (
    <StyledDialog
      visible={visible}
      onHide={onHide}
      baseZIndex={10000000}
      style={{
        width: "100vw",
        margin: "0 !important",
      }}
      position="bottom"
      modal
      showHeader={false}
      contentStyle={{
        width: "100%",
        height: "100%",
        margin: "0 !important",
        borderTopLeftRadius: "8px",
        borderTopRightRadius: "8px",
        overflow: "hidden",
      }}
    >
      <FontAwesomeIcon
        onClick={onHide}
        icon={faTimes}
        style={{
          position: "absolute",
          right: "1rem",
          top: "1rem",
          color: "black",
        }}
        size="lg"
      />

      <div
        style={{
          display: "flex",
          gap: "0.2rem",
          paddingTop: "1rem",
        }}
      >
        <div
          style={{
            fontSize: "1.1rem",
            fontFamily: "var(--bold)",
            color: "black",
          }}
        >
          {df(game.startTime, {
            weekday: "long",
            day: "numeric",
            month: "long",
          })}
        </div>
        <FontAwesomeIcon
          rotation={90}
          icon={faHorizontalRule}
          color="var(--gray-400)"
        />
        <div
          style={{
            fontSize: "0.7rem",
            alignSelf: "center",
            fontFamily: "var(--medium)",
            color: "black",
          }}
        >
          {dfInterval(game.startTime, game.endTime, luxonTimeFormat)}
        </div>
      </div>
      <div
        style={{
          marginTop: "2rem",
          gap: "1.5rem",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <UsersList
          users={game.participants}
          isDisabled
          includeContact
          organizerId={game.organizerId}
        />
        <Divider />
        {options.map(
          (
            {
              icon,
              translationId,
              defaultMessage,
              disabled,
              hidden,
              values,
              onClick,
            },
            i,
          ) =>
            !hidden && (
              <IconAndTextWrapper
                key={i}
                onClick={() => {
                  !disabled && onClick();
                }}
                $disabled={disabled}
              >
                <IconWrapper>
                  <FontAwesomeIcon
                    icon={icon}
                    size="lg"
                    color="var(--gray-400)"
                  />
                </IconWrapper>
                <FormattedMessage
                  id={translationId}
                  defaultMessage={defaultMessage}
                  values={values}
                />
              </IconAndTextWrapper>
            ),
        )}
      </div>
    </StyledDialog>
  );
}
