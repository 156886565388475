import { FormattedMessage, useIntl } from "react-intl";

import { DateTime } from "luxon";

import { useFacility } from "../../../hooks/swr/useFacility";
import { useDateFormat } from "../../../hooks/useDateFormat";

import { CheckoutViewHeading } from "../CheckoutView";
import { useCheckoutState } from "../context/CheckoutContext";
import { OrderDetails } from "./OrderDetails";

export const SeriesOrderDetails = () => {
  const intl = useIntl();

  const state = useCheckoutState();

  const { facility } = useFacility(state.order.facilityId);
  const { df } = useDateFormat(state.order.facilityId);

  if (state.orderType !== "series") {
    return null;
  }

  const { series } = state;

  const orderDetails = [
    {
      heading: intl.formatMessage({
        id: "common.serie",
      }),
      text: series.name,
    },
    {
      heading: intl.formatMessage({
        id: "common.date-and-time",
      }),
      text: df(series.startTime, DateTime.DATETIME_MED),
    },
    {
      heading: intl.formatMessage({
        id: "common.venue",
      }),
      text: series.facilityName,
    },
  ];

  if (facility?.shortMessage) {
    orderDetails.push({
      heading: intl.formatMessage({
        id: "common.facility.info",
      }),
      text: facility?.shortMessage ?? "",
    });
  }

  return (
    <div>
      <CheckoutViewHeading>
        <FormattedMessage id="checkout.section.order-details.heading" />
      </CheckoutViewHeading>

      <OrderDetails orderDetails={orderDetails} firstWide />
    </div>
  );
};
