import { ChangeEvent, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

import clsx from "clsx";
import { FormikErrors } from "formik";

import { GeneralActivityRequest } from "../../../../../../modules/game/models/GeneralActivities";

import { useFacility } from "../../../../../../hooks/swr/useFacility";

import { Checkbox } from "../../../../../../components/Checkbox";
import { ProgressSpinner } from "../../../../../../components/ProgressSpinner";

interface Props {
  facilityId: string;
  defaultCourts: string[];
  disabled?: boolean;
  error?: string | string[];
  // TODO: Make this general? Currently only accepts formik.setFieldValue.
  onChange?: (
    field: string,
    value: any,
    shouldValidate?: boolean,
  ) => Promise<void> | Promise<FormikErrors<GeneralActivityRequest>>;
}

export const CourtPicker: React.FC<Props> = ({
  facilityId,
  defaultCourts,
  disabled = false,
  error,
  onChange,
}) => {
  const [selected, setSelected] = useState<string[]>(defaultCourts ?? []);
  const { facility, isLoading } = useFacility(facilityId);

  let twiceCount = 0;
  const twice = () => {
    /* make 0,1 4,5 etc have the same background
       and 2,3 6,7 etc have the same.
       Gives true, true, false, false etc. etc.
       use with ${twice() ? "bg-white" : "bg-truewhite"}
    */
    twiceCount = (twiceCount + 1) % 4;
    return twiceCount === 1 || twiceCount === 2;
  };

  const onCourtChange = (courtId: string, isSelected: boolean) => {
    setSelected(prevState => {
      if (isSelected && prevState.includes(courtId)) return prevState;
      if (!isSelected) return prevState.filter(c => c !== courtId);
      return [...prevState, courtId];
    });
  };

  useEffect(() => {
    onChange?.("courtIdsToPlay", selected, true);
  }, [selected]);

  if (isLoading) {
    return <ProgressSpinner />;
  }

  return (
    <>
      <h3 className="mb-4 text-3xl font-bold">
        <FormattedMessage
          id="activity.admin.edit.courts.title"
          values={{
            Thin: chunk => <span className="font-normal">{chunk}</span>,
            selectedCount: selected?.length,
            courtsCount: facility.bookableEntities?.length,
          }}
        />
      </h3>
      <ol className="flex flex-col gap-x-4 lg:grid lg:grid-cols-2">
        {facility.bookableEntities?.map(c => (
          <li
            key={c.id}
            className={`p-4 max-lg:odd:bg-white ${twice() ? "lg:bg-white" : ""}`}
          >
            <div className="flex items-center justify-between">
              <p className={clsx({ "font-bold": selected.includes(c.id) })}>
                {c.name}
              </p>
              <Checkbox
                disabled={disabled}
                checked={selected.includes(c.id)}
                name={c.name}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  onCourtChange(c.id, e.target.checked)
                }
              />
            </div>
          </li>
        ))}
      </ol>
      {!!error && <p className="mt-2 text-error">{error}</p>}
    </>
  );
};
