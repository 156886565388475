import { forwardRef, useEffect, useRef, useState } from "react";

import { faChevronDown, faChevronUp } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Calendar as PrimeReactCalendar } from "primereact/calendar";
import styled from "styled-components";

import { breakpoints } from "../../appConstants/common";

import {
  CalendarDateTimeWrapper,
  CalendarDateTimeWrapperProps,
} from "../CalendarDateTimeWrapper";

interface Props extends CalendarDateTimeWrapperProps {
  inputLabel?: string;
  showButtonBar?: boolean;
  isEndDateSelected?: boolean;
  resetDates?: boolean;
}

interface StyleProps {
  $hasValue?: string;
  $inputLabel?: string;
  $disabled?: boolean;
}

const Calendar = styled(CalendarDateTimeWrapper)<StyleProps>`
  width: 100%;
  position: relative;
  cursor: pointer;

  &:before {
    font-weight: var(--medium);
    content: ${props => (props.$hasValue ? props.$inputLabel : "")};
    position: absolute;
    color: var(--gray-400);
    pointer-events: none;
    top: 1.6rem;
    left: 0;
    margin-left: 1.1rem;
    line-height: 1;
    transition: all 250ms ease-in-out;
    font-weight: 400;
    font-size: 0.75rem !important;
    margin-top: -1rem;
  }
`;

const IconContainer = styled.div<StyleProps>`
  position: absolute;
  top: 25%;
  right: 0.8rem;
`;

const Label = styled.span`
  position: absolute;
  z-index: 1000;
  top: 0;
  font-size: 0.75rem !important;
  padding: 0.7rem 1rem 0.5rem 1rem;
  -webkit-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
  font-weight: var(--medium);
  color: var(--gray-400);

  @media (min-width: ${breakpoints.XL}) {
    padding: 0.6rem 1rem 0.5rem 1rem;
  }
`;

// eslint-disable-next-line react/display-name
export const CalendarInput = forwardRef<PrimeReactCalendar, Props>(
  (
    {
      value,
      disabled,
      showIcon = false,
      placeholder,
      onChange,
      showButtonBar = true,
      isEndDateSelected,
      resetDates,
      ...props
    },
    ref,
  ) => {
    const [selectedDate, setSelectedDate] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const calenderContainerRef = useRef<HTMLDivElement>();

    const preventDefault = e => {
      e?.preventDefault?.();
      e?.stopPropagation?.();
    };

    useEffect(() => void setSelectedDate(value), [value]);

    useEffect(() => {
      isEndDateSelected && setIsOpen(false);
      resetDates && setIsOpen(false);
    }, [isEndDateSelected, resetDates]);

    return (
      <div
        ref={calenderContainerRef}
        style={{ position: "relative", cursor: "pointer" }}
        onClick={e => {
          preventDefault(e);
          if (
            calenderContainerRef?.current?.contains(e.target as HTMLDivElement)
          ) {
            !disabled && setIsOpen(open => !open);
          }
        }}
      >
        {value && placeholder && <Label>{placeholder}</Label>}
        <Calendar
          inputClassName="pr-10"
          selectOtherMonths
          showButtonBar={showButtonBar}
          readOnlyInput
          $hasValue={selectedDate}
          $inputLabel={props.inputLabel || placeholder || ""}
          showIcon={showIcon}
          onFocus={preventDefault}
          onBlur={preventDefault}
          iconPos="left"
          onVisibleChange={preventDefault}
          value={selectedDate}
          visible={isOpen}
          disabled={disabled}
          $disabled={disabled}
          onChange={e => {
            setSelectedDate(e?.value);
            onChange?.(e);
          }}
          {...(!value && { placeholder })}
          {...props}
          ref={ref}
        />
        <IconContainer $disabled={disabled}>
          <FontAwesomeIcon
            className="icon pb-1"
            icon={isOpen ? faChevronUp : faChevronDown}
            size="lg"
          />
        </IconContainer>
      </div>
    );
  },
);
