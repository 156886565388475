import { useCallback, useMemo } from "react";

import { useAppLocale } from "../recoil/i18nConfigState";

export const useNumberFormat = (
  options?: Pick<Intl.NumberFormatOptions, "maximumFractionDigits">,
) => {
  const locale = useAppLocale();

  const numberFormatter = useMemo(
    () => new Intl.NumberFormat(locale, options),
    [locale, options],
  );

  const nf = useCallback(
    (number: number) => numberFormatter.format(number),
    [numberFormatter],
  );

  return { nf };
};
