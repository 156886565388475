import { useIntl } from "react-intl";

import { DateTime } from "luxon";
import { useRecoilValue } from "recoil";
import { DateSchema, array, date, number, object, string } from "yup";

import { isPinCodeEnabledState } from "../../../recoil/Admin/activitiy/activityCreationState";

interface Props {
  minNumberOfTeams?: number;
}

export const useFormValidationSchema = (props?: Props) => {
  const intl = useIntl();
  const getTranslation = (id: string, defaultMessage?: string) =>
    intl.formatMessage({ id, defaultMessage });
  const enablePinCode = useRecoilValue(isPinCodeEnabledState);

  const createSeriesSchema = object().shape({
    name: string().required(),
    startTime: object().required(),
    endTime: object().required(),
    registrationOpenTo: object().required(),
    gender: string().required(),
    numberOfDivisions: number().min(1),
    numberOfTeamsInDivision: number().min(2),
    numberOfTeamsMovingUp: number().min(0),
    numberOfTeamsMovingDown: number().min(0),
    fee: number().min(0),
    rescheduleRules: object().shape({
      maxAllowedBookingReschedules: number().min(0).required(),
    }),
  });

  const editSeriesSchema = object().shape({
    name: string().required(),
    startTime: object().required(),
    endTime: object().required(),
    registrationOpenTo: object().required(),
    gender: string().required(),
    numberOfDivisions: number().min(1),
    numberOfTeamsInDivision: number().min(2),
    numberOfTeamsMovingUp: number().min(0),
    numberOfTeamsMovingDown: number().min(0),
    fee: number().min(0),
  });

  const createDivisionSchema = object().shape({
    name: string().required(),
    level: number().min(0),
    numberOfTeams: number().min(2),
    teamsMovingUp: number().min(0),
    teamsMovingDown: number().min(0),
    rescheduleRules: object().shape({
      maxAllowedBookingReschedules: number().min(0).required(),
    }),
  });

  const editDivisionSchema = object().shape({
    name: string().required(),
    level: number().min(0),
    numberOfTeams: number().moreThan(
      Math.max(
        1,
        props?.minNumberOfTeams && isFinite(props?.minNumberOfTeams)
          ? props?.minNumberOfTeams - 1
          : 0,
      ),
      intl.formatMessage({
        id:
          props?.minNumberOfTeams && props?.minNumberOfTeams > 1
            ? "validation.division.min-number-of-teams"
            : "validation.required.number-of-teams",
      }),
    ),
    teamsMovingUp: number().min(0),
    teamsMovingDown: number().min(0),
    rescheduleRules: object().shape({
      maxAllowedBookingReschedules: number().min(0).required(),
    }),
  });

  const createGameSchema = object().shape({
    lengthOfGame: number().oneOf(
      [90, 60],
      getTranslation(
        "validation.required.game-length",
        "Matchlängd kan bara vara 60 eller 90 minuter.",
      ),
    ),
    courtsToBePlayed: array().min(
      1,
      getTranslation(
        "validation.required.game.courts-to-be-played",
        "Minst en bana måste vara vald.",
      ),
    ),
    weekDays: array().min(
      1,
      getTranslation(
        "validation.required.game.weekdays-to-be-played",
        "Minst en matchdag måste vara vald.",
      ),
    ),
    rescheduleRules: object().shape({
      maxAllowedBookingReschedules: number().min(0).required(),
    }),
  });

  const createGeneralActivitySchema = object().shape({
    name: string().required(),
    startTime: string().required(),
    endTime: date()
      .when("startTime", (startTime, schema) => {
        if (startTime) {
          return date()
            .min(
              startTime,
              getTranslation(
                "validation.required.end-time_start-time",
                "Slutdatum måste vara större än startdatum.",
              ),
            )
            .typeError(
              getTranslation(
                "validation.required.end-time",
                "Fältet är obligatoriskt.",
              ),
            );
        }

        return schema;
      })
      .required(),
    registrationOpenTo: date()
      .when("startTime", (startTime, schema: DateSchema) =>
        startTime[0] && typeof startTime[0] === "string"
          ? schema.max(
              DateTime.fromISO(startTime[0]).plus({ minutes: 1 }).toJSDate(),
              getTranslation(
                "validation.required.registration-open-to_start-time",
                "Sista registreringsdatum får inte vara senare än startdatum.",
              ),
            )
          : schema,
      )
      .required(),
    gender: string().required(),
    courtIdsToPlay: array().min(
      1,
      getTranslation(
        "validation.required.game.courts-to-be-played",
        "Minst en bana måste vara vald.",
      ),
    ),
    fee: number().min(0),
    price: number().min(0),
    numberOfParticipants: number().min(1),
    pinCode: string()
      .nullable()
      .when("isPinCodeEnable", (isPinCodeEnable, schema) => {
        return enablePinCode
          ? schema
              .matches(
                /^[0-9]{4}$/,
                getTranslation(
                  "validation.required.pinCode",
                  "Ogiltigt pinkod",
                ),
              )
              .required()
          : schema;
      }),
    discountPrices: array().of(
      object({
        discountAmount: number().min(0),
        membershipIds: array().of(string()),
      }),
    ),
  });

  return {
    createSeriesSchema,
    createDivisionSchema,
    editDivisionSchema,
    createGameSchema,
    createGeneralActivitySchema,
    editSeriesSchema,
  };
};
