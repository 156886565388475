import { memo, useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import {
  faArrowsRotate,
  faBan,
  faIdBadge,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { DateTime } from "luxon";

import { BookingType } from "../../../../models/Booking";
import { IScheduleSlot } from "../../../../models/Calendar";

import { useDateFormatWithSelectedFacility } from "../../../../../../hooks/useDateFormat";

import { bookingTypeBorderColor } from "../../utils";

interface IProps {
  slot: IScheduleSlot;
  row: number;
  column: number;
  widthCoefficient: number;
  isBooked: boolean;
  isLateToBook: boolean;
  isSelected: boolean;
  startTime: DateTime;
  endTime: DateTime;
  isBlurred?: boolean;
  onBookedSlotClick: (bookingId: string) => void;
  onBeginSelection: (row: number, index: number) => void;
  onEndSelection: (allowOverrideMinNumberOfSlots?: boolean) => Promise<void>;
  onUpdateSelection: (
    event: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>,
    row: number,
    index: number,
  ) => void;
  lastBookedSlot?: IScheduleSlot;
  isBeingRescheduled?: boolean;
}

const AdminCalendarSlot = ({
  slot,
  row,
  column,
  widthCoefficient,
  isBooked,
  isLateToBook,
  isSelected,
  startTime,
  endTime,
  isBlurred = false,
  onBookedSlotClick,
  onBeginSelection,
  onEndSelection,
  onUpdateSelection,
  lastBookedSlot,
  isBeingRescheduled,
}: IProps) => {
  const { formatMessage } = useIntl();
  const { df } = useDateFormatWithSelectedFacility();

  const handleTouchMove = useCallback(
    (event: React.TouchEvent<HTMLDivElement>) => {
      const targetEl = document.elementFromPoint(
        event.touches[0].clientX,
        event.touches[0].clientY,
      );

      if (!targetEl) {
        return;
      }

      const column = targetEl.getAttribute("data-column");
      const row = targetEl.getAttribute("data-row");

      if (!column || !row) {
        return;
      }

      onUpdateSelection(event, parseInt(row), parseInt(column));
    },
    [onUpdateSelection],
  );

  return (
    <div
      className={clsx(
        "relative flex-1 border-gray-50 py-1 last:border-none even:border-r",
        isLateToBook ? "cursor-not-allowed" : "cursor-pointer",
      )}
      data-row={row}
      data-column={column}
      onMouseDown={
        !isBooked
          ? e => e.button === 0 && onBeginSelection(row, column)
          : undefined
      }
      onMouseUp={
        !isBooked
          ? e => {
              onEndSelection(e.altKey);
            }
          : undefined
      }
      onMouseEnter={
        !isBooked ? e => onUpdateSelection(e, row, column) : undefined
      }
      onTouchStart={!isBooked ? () => onBeginSelection(row, column) : undefined}
      onTouchEnd={!isBooked ? () => onEndSelection() : undefined}
      onTouchMove={!isBooked ? e => handleTouchMove(e) : undefined}
    >
      {isBooked && (
        <button
          data-booking
          type="button"
          title={
            slot.title ||
            (slot.bookingType === BookingType.NotBookable
              ? formatMessage({ id: "calendar.slot.not-bookable" })
              : `${slot.bookedBy?.firstName} ${slot.bookedBy?.lastName}`)
          }
          className={clsx(
            "absolute z-10 flex h-[calc(100%-0.5rem)] select-none flex-col overflow-hidden rounded border border-l-[6px] bg-gray-50 p-1 text-left text-xs leading-tight outline-1 outline-black hover:outline focus-visible:outline",
            slot.startTime.minute === 0 && "ml-1",
            isBlurred && "opacity-20",
            slot.bookingType !== null &&
              bookingTypeBorderColor(slot.bookingType),
            isBeingRescheduled &&
              "pointer-events-none select-none !border-2 border-dashed",
          )}
          style={{
            width: `calc((100% * ${widthCoefficient}) - ${slot.startTime.minute === 0 ? 4 : 0}px - ${lastBookedSlot?.endTime.minute === 0 ? 4 : 0}px)`,
          }}
          onClick={() => slot.bookingId && onBookedSlotClick(slot.bookingId)}
        >
          {slot.title ? (
            <span
              className={clsx(
                "max-w-full break-words",
                slot.bookingType === null ||
                  [BookingType.Event, BookingType.Series].includes(
                    slot.bookingType,
                  )
                  ? "line-clamp-4"
                  : slot.bookingType === BookingType.NotBookable
                    ? "line-clamp-3"
                    : "line-clamp-2",
              )}
            >
              {slot.title}
            </span>
          ) : slot.bookingType === BookingType.NotBookable ? (
            <span className="line-clamp-3 max-w-full break-words">
              <FormattedMessage id="calendar.slot.not-bookable" />
            </span>
          ) : (
            <>
              <div className="max-w-full truncate leading-tight">
                {slot.bookedBy?.firstName}
              </div>
              <div className="max-w-full truncate leading-tight">
                {slot.bookedBy?.lastName}
              </div>
            </>
          )}

          <span className="mt-1 flex items-center gap-[3px]">
            {slot.bookingType === BookingType.NotBookable ? (
              <FontAwesomeIcon icon={faBan} />
            ) : slot.isAdmin &&
              slot.bookingType !== BookingType.Series &&
              slot.bookingType !== BookingType.Event ? (
              <FontAwesomeIcon icon={faIdBadge} />
            ) : null}
            {slot.bookingType === BookingType.Recurring && (
              <FontAwesomeIcon icon={faArrowsRotate} />
            )}
          </span>

          {slot.bookingType !== null &&
            [
              BookingType.Regular,
              BookingType.Recurring,
              BookingType.Open,
            ].includes(slot.bookingType) &&
            !slot.isPaid && (
              <span className="mt-auto max-w-full self-start truncate rounded-sm bg-red-600/30 px-1 text-[10px] font-semibold leading-normal text-red-600">
                <FormattedMessage id="bookings.not-payed" />
              </span>
            )}
        </button>
      )}

      {isSelected && (
        <div
          className={clsx(
            "pointer-events-none absolute z-10 flex h-[calc(100%-0.5rem)] select-none flex-col items-center justify-center rounded border border-primary bg-primary/20 p-1 text-left text-sm font-bold text-primary",
            slot.startTime.minute === 0 && "ml-1",
          )}
          style={{
            width: `calc((100% * ${widthCoefficient}) - ${startTime.minute === 0 ? 4 : 0}px - ${endTime.minute === 0 ? 4 : 0}px)`,
          }}
        >
          <span className="max-w-full self-start truncate">
            {df(startTime, DateTime.TIME_SIMPLE)}
          </span>
          {/* <span className="absolute bottom-full left-1/2 z-10 -translate-x-1/2 whitespace-nowrap">
            {dfInterval(startTime, endTime, DateTime.TIME_SIMPLE)}
          </span> */}
          <span
            className="mt-auto max-w-full self-end truncate"
            style={{ direction: "rtl" }}
          >
            {df(endTime, DateTime.TIME_SIMPLE)}
          </span>
        </div>
      )}
    </div>
  );
};

export default memo(AdminCalendarSlot);
