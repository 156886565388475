import { useMemo } from "react";
import { useIntl } from "react-intl";

import { OpenBookingClassCategoryEnum } from "../models/OpenBookings";

export const useOpenBookingClassCategoryName = (
  classCategory?: OpenBookingClassCategoryEnum,
) => {
  const { formatMessage } = useIntl();

  return useMemo(() => {
    if (!classCategory) {
      return "";
    }

    switch (classCategory) {
      case OpenBookingClassCategoryEnum.Men: {
        return formatMessage({
          id: "gender-types.male",
        });
      }

      case OpenBookingClassCategoryEnum.Women: {
        return formatMessage({
          id: "gender-types.female",
        });
      }

      case OpenBookingClassCategoryEnum.Mixed: {
        return formatMessage({
          id: "gender-types.mix",
        });
      }
    }
  }, [classCategory, formatMessage]);
};
