import { atom, useRecoilValue } from "recoil";

import { localStoragePersistenceEffect } from "./effects/localStoragePersistenceEffect";

export const isAdminModeState = atom<boolean>({
  key: "isAdminMode",
  default: false,
  effects: [localStoragePersistenceEffect("isAdminMode")],
});

export const useIsAdminMode = () => useRecoilValue(isAdminModeState);
