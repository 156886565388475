import { useRef } from "react";
import { FormattedMessage } from "react-intl";

import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faStar as falStar } from "@fortawesome/pro-light-svg-icons";
import { faStar as fasStar } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Lottie, { LottieRefCurrentProps } from "lottie-react";

import { Coin, CoinType } from "../../../../../modules/player/models/Coins";

import { useCurrentUser } from "../../../../../hooks/swr/useCurrentUser";
import { useDateFormat } from "../../../../../hooks/useDateFormat";
import { useNumberFormat } from "../../../../../hooks/useNumberFormat";

import { updateFacilityUserRelation } from "../../../../../modules/player/services/UserService";

import { luxonDateFormat } from "../../../../../utils/dateFormats";
import { EmailItem } from "./EmailItem";
import Favorited from "./Favorited.json";

export interface MyFacilityObject {
  consentEmail: boolean;
  facilityId: string;
  facilityName: string;
  isFavorite: boolean;
  walletCurrencies: Coin[];
}

interface Props {
  item: MyFacilityObject;
}

export const FacilityItem: React.FC<Props> = ({ item }) => {
  const lottieRef = useRef<LottieRefCurrentProps>(null);
  const { df } = useDateFormat(item.facilityId);
  const { mutate } = useCurrentUser();
  const { nf } = useNumberFormat();

  const icon = item?.isFavorite
    ? (fasStar satisfies IconProp)
    : (falStar satisfies IconProp);

  const toggleFavorite = async () => {
    const newState = !item?.isFavorite;
    if (newState) {
      lottieRef?.current?.animationContainerRef?.current?.setAttribute(
        "style",
        "display: block",
      );
      lottieRef?.current?.goToAndPlay(100);
    }

    const payload = {
      facilityId: item?.facilityId,
      isFavorite: newState,
    };
    try {
      mutate(async () => {
        return await updateFacilityUserRelation(payload);
      }, false);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="flex flex-col divide-y border border-gray-200">
      <div className="flex grow items-center justify-between">
        <h3 className="p-4 text-3xl">{item?.facilityName}</h3>
        <div
          className="flex cursor-pointer flex-col items-center justify-center gap-1 p-2"
          onClick={toggleFavorite}
        >
          <div className="relative inline">
            <FontAwesomeIcon className="text-primary" icon={icon} />
            <Lottie
              className="absolute -left-[50px] -top-[50px] w-[120px]"
              animationData={Favorited}
              loop={false}
              autoplay={false}
              lottieRef={lottieRef}
              onComplete={() =>
                lottieRef?.current?.animationContainerRef?.current?.setAttribute(
                  "style",
                  "display: none",
                )
              }
            />
          </div>
          <p className="select-none text-sm text-pureblack">
            <FormattedMessage id="user.facilities.favorite" />
          </p>
        </div>
      </div>
      <ul className="flex flex-wrap gap-4 divide-x divide-primary p-4">
        {item.walletCurrencies?.map((coin, i) => (
          <li key={i} className="p-2">
            <p className="text-5xl font-bold">{nf(coin.amount)}</p>
            <div>
              <p className="text-sm text-gray-600">
                <FormattedMessage
                  id={`coin.type.${CoinType[coin.type]?.toLowerCase()}`}
                />
              </p>
              <p>
                <FormattedMessage id="coin.valid.thru" />{" "}
                {df(coin.expires, luxonDateFormat)}
              </p>
            </div>
          </li>
        ))}
      </ul>
      <EmailItem
        facilityId={item?.facilityId}
        consentEmail={item?.consentEmail}
      />
    </div>
  );
};
