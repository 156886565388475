import { Order } from "../models/Order";
import { OrderItemRequest } from "../models/OrderItem";
import {
  CoinsOrderPaymentResponse,
  type CreateCoinsOrderPaymentRequest,
  type CreateSwedbankPayOrderPaymentRequest,
  type CurrentOrderPayment,
  type OrderPaymentResponse,
  PaymentMethod,
  SwedbankPayOrderPaymentResponse,
} from "../models/payment";
import { FacilityWithUtc } from "../modules/customer/models/Facility";

import { get, post } from "./apiClient";

const API_VERSION = "v1";
const API_SERVICE = "/orders";
const API_ENDPOINT = `/${API_VERSION}${API_SERVICE}`;

export const getOrder = async (orderId: Order["id"]) => {
  return await get<Order>({ endpoint: `${API_ENDPOINT}/${orderId}` });
};

export const createOrder = async (
  facilityId: FacilityWithUtc["id"],
  item?: OrderItemRequest,
) => {
  return await post<Order>({
    endpoint: API_ENDPOINT,
    body: {
      facilityId,
      item,
    },
  });
};

export const createOrderItem = async (
  orderId: Order["id"],
  item: OrderItemRequest,
) => {
  return await post<Order>({
    endpoint: `${API_ENDPOINT}/${orderId}/items`,
    body: {
      orderItem: item,
    },
  });
};

export const createOrderPaymentWithSwedbankPayCard = async (
  orderId: Order["id"],
  createOrderPaymentRequest: CreateSwedbankPayOrderPaymentRequest,
) => {
  return await post<SwedbankPayOrderPaymentResponse>({
    endpoint: `${API_ENDPOINT}/${orderId}/payment/swedbankpay/card`,
    body: createOrderPaymentRequest,
  });
};

export const createOrderPaymentWithSwedbankPaySwish = async (
  orderId: Order["id"],
  createOrderPaymentRequest: CreateSwedbankPayOrderPaymentRequest,
) => {
  return await post<SwedbankPayOrderPaymentResponse>({
    endpoint: `${API_ENDPOINT}/${orderId}/payment/swedbankpay/swish`,
    body: createOrderPaymentRequest,
  });
};

export const createOrderPaymentWithSwedbankPayMobilePay = async (
  orderId: Order["id"],
  createOrderPaymentRequest: CreateSwedbankPayOrderPaymentRequest,
) => {
  return await post<SwedbankPayOrderPaymentResponse>({
    endpoint: `${API_ENDPOINT}/${orderId}/payment/swedbankpay/mobilepay`,
    body: createOrderPaymentRequest,
  });
};

export const createOrderPaymentWithSwedbankPayApplePay = async (
  orderId: Order["id"],
  createOrderPaymentRequest: CreateSwedbankPayOrderPaymentRequest,
) => {
  return await post<SwedbankPayOrderPaymentResponse>({
    endpoint: `${API_ENDPOINT}/${orderId}/payment/swedbankpay/applepay`,
    body: createOrderPaymentRequest,
  });
};

export const createOrderPaymentWithSwedbankPayGooglePay = async (
  orderId: Order["id"],
  createOrderPaymentRequest: CreateSwedbankPayOrderPaymentRequest,
) => {
  return await post<SwedbankPayOrderPaymentResponse>({
    endpoint: `${API_ENDPOINT}/${orderId}/payment/swedbankpay/googlepay`,
    body: createOrderPaymentRequest,
  });
};

export const createOrderPaymentWithCoinsCredit = async (
  orderId: Order["id"],
  createOrderPaymentRequest: CreateCoinsOrderPaymentRequest,
) => {
  return await post<CoinsOrderPaymentResponse>({
    endpoint: `${API_ENDPOINT}/${orderId}/payment/coins/credit`,
    body: createOrderPaymentRequest,
  });
};

export const createOrderPaymentWithCoinsAllowance = async (
  orderId: Order["id"],
  createOrderPaymentRequest: CreateCoinsOrderPaymentRequest,
) => {
  return await post<CoinsOrderPaymentResponse>({
    endpoint: `${API_ENDPOINT}/${orderId}/payment/coins/allowance`,
    body: createOrderPaymentRequest,
  });
};

export const completeOrderPayment = async (
  orderId: Order["id"],
  orderPaymentId: OrderPaymentResponse["orderPaymentId"],
) => {
  await post({
    endpoint: `${API_ENDPOINT}/${orderId}/complete-payment`,
    body: {
      orderPaymentId,
    },
    parseDataAs: "none",
  });
};

export const getOrderPaymentMethods = async (
  orderId: Order["id"],
  splitPayment: boolean,
) => {
  return await get<PaymentMethod[]>({
    endpoint: `${API_ENDPOINT}/${orderId}/payment-methods`,
    urlSearchParams: {
      splitPayment,
    },
  });
};

export const getCurrentOrderPayment = async (orderId: Order["id"]) => {
  return await get<CurrentOrderPayment>({
    endpoint: `${API_ENDPOINT}/${orderId}/payment`,
  });
};

export const getOrderPaymentForSwedbankPaySwish = async (
  orderId: Order["id"],
  orderPaymentId: OrderPaymentResponse["orderPaymentId"],
) => {
  return await get<SwedbankPayOrderPaymentResponse>({
    endpoint: `${API_ENDPOINT}/${orderId}/payment/swedbankpay/swish/${orderPaymentId}`,
  });
};
