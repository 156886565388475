import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { Dropdown as _Dropdown } from "primereact/dropdown";
import { ProgressSpinner } from "primereact/progressspinner";
import styled from "styled-components";

import { FacilityWithUtc } from "../models/Facility";

interface Props {
  items: FacilityWithUtc[];
  isLoading: boolean;
  preselectedItem?: string;
  onItemClick?: (id: string) => void;
}

const Dropdown = styled(_Dropdown)`
  background: transparent;
  min-width: 14rem;
  border: 0;

  &.p-focus {
    outline: none !important;
    box-shadow: none !important;
    border: none !important;
  }

  .p-dropdown-label.p-inputtext {
    padding: 0;
  }

  .p-dropdown-trigger-icon.pi-chevron-down {
    color: var(--primary) !important;
  }

  .p-dropdown-label {
    font-weight: var(--bold);
    color: var(--primary) !important;
    padding: 0 0.6rem;
    font-size: var(--h3);
  }

  .p-dropdown-item {
    font-weight: var(--medium) !important;
  }
`;

const ItemTemplate = styled.div`
  color: var(--gray-600);
  font-weight: var(--medium);
`;
export const FacilityHeaderSelect: React.FC<Props> = ({
  items,
  isLoading,
  preselectedItem,
  onItemClick,
}) => {
  const intl = useIntl();
  const [selectedItem, setSelectedItem] = useState<number | string>(1);
  const options = items.map(({ name, id }) => ({ label: name, value: id }));

  useEffect(() => {
    if (preselectedItem) {
      setSelectedItem(preselectedItem);
    }
  }, [preselectedItem]);

  const onHandleChange = value => {
    setSelectedItem(value);
    setSelectedItem(value);
    onItemClick(value);
  };

  return (
    <div>
      {isLoading ? (
        <ProgressSpinner />
      ) : (
        <Dropdown
          id="type"
          optionLabel="label"
          value={selectedItem}
          options={options}
          itemTemplate={({ label }) => <ItemTemplate>{label}</ItemTemplate>}
          emptyMessage={intl.formatMessage({
            id: "customer.facility.select.empty-message",
            defaultMessage: "Inga anläggningar",
          })}
          onChange={e => onHandleChange(e.value)}
          filter={options.length > 10}
        />
      )}
    </div>
  );
};
