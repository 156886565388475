import React, { useState } from "react";
import { FormattedMessage } from "react-intl";

import clsx from "clsx";

import { FacilityWithUtc } from "../modules/customer/models/Facility";

import { useFacility } from "../hooks/swr/useFacility";

import { Dialog } from "./Dialog";

interface Props {
  skipRescheduleInfo?: boolean;
  facilityId: FacilityWithUtc["id"];
  className?: string;
}

export const PaymentTerms: React.FC<Props> = ({
  skipRescheduleInfo,
  facilityId,
  className,
}) => {
  const [showPaymentTerms, setShowPaymentTerms] = useState<boolean>();
  const { facility } = useFacility(
    showPaymentTerms || !skipRescheduleInfo ? facilityId : undefined,
  );

  const textId = skipRescheduleInfo
    ? "terms-and-condition.payment.short"
    : "terms-and-condition.payment";

  const i18TextValues = {
    numberOfHoursBeforeBookingCancellation:
      facility?.facilityBookingRules.numberOfHoursBeforeBookingCancellation,
  };

  return (
    <>
      <div className={clsx("leading-none text-gray-700", className)}>
        <FormattedMessage
          id={textId}
          values={{
            clickable: (
              <a onClick={() => setShowPaymentTerms(true)}>
                <FormattedMessage id="terms-and-conditions.link.text" />
              </a>
            ),
            numberOfHoursBeforeBookingCancellation:
              facility?.facilityBookingRules
                .numberOfHoursBeforeBookingCancellation,
          }}
        />
      </div>

      {showPaymentTerms && (
        <Dialog visible onHide={() => setShowPaymentTerms(false)}>
          <div className="flex max-w-prose flex-col gap-2">
            <h3>
              <FormattedMessage id="terms-and-conditions.title.purchase-conditions" />
            </h3>
            <h4>
              <FormattedMessage id="terms-and-conditions.title.general" />
            </h4>
            <p>
              <FormattedMessage
                id="terms-and-conditions.text.general"
                values={i18TextValues}
              />
            </p>
            <h4>
              <FormattedMessage id="terms-and-conditions.title.payment" />
            </h4>
            <p>
              <FormattedMessage
                id="terms-and-conditions.text.payment"
                values={i18TextValues}
              />
            </p>
            <h4>
              <FormattedMessage id="terms-and-conditions.title.prices" />
            </h4>
            <p>
              <FormattedMessage
                id="terms-and-conditions.text.prices"
                values={i18TextValues}
              />
            </p>
            <h4>
              <FormattedMessage id="terms-and-conditions.title.purchase-violations" />
            </h4>
            <p>
              <FormattedMessage
                id="terms-and-conditions.text.purchase-violations"
                values={i18TextValues}
              />
            </p>
            <br />
            <h3>
              <FormattedMessage id="terms-and-conditions.title.bookings" />
            </h3>
            <h4>
              <FormattedMessage id="terms-and-conditions.title.cancellation-and-refund" />
            </h4>
            <p>
              <FormattedMessage
                id="terms-and-conditions.text.cancellation-and-refund"
                values={i18TextValues}
              />
            </p>
            <h4>
              <FormattedMessage id="terms-and-conditions.title.right-of-withdrawal" />
            </h4>
            <p>
              <FormattedMessage
                id="terms-and-conditions.text.right-of-withdrawal"
                values={i18TextValues}
              />
            </p>
            <h4>
              <FormattedMessage id="terms-and-conditions.title.court22-commitment" />
            </h4>
            <p>
              <FormattedMessage
                id="terms-and-conditions.text.court22-commitment"
                values={i18TextValues}
              />
            </p>
            <h4>
              <FormattedMessage id="terms-and-conditions.title.customer-commitment" />
            </h4>
            <p>
              <FormattedMessage
                id="terms-and-conditions.text.customer-commitment.part-1"
                values={i18TextValues}
              />
            </p>
            <p>
              <FormattedMessage
                id="terms-and-conditions.text.customer-commitment.part-2"
                values={i18TextValues}
              />
            </p>
            <h4>
              <FormattedMessage id="terms-and-conditions.title.termination-or-limitation" />
            </h4>
            <p>
              <FormattedMessage
                id="terms-and-conditions.text.termination-or-limitation"
                values={i18TextValues}
              />
            </p>
            <h4>
              <FormattedMessage id="terms-and-conditions.title.disclaimer" />
            </h4>
            <p>
              <FormattedMessage
                id="terms-and-conditions.text.disclaimer"
                values={i18TextValues}
              />
            </p>
            <h4>
              <FormattedMessage id="terms-and-conditions.title.GDPR" />
            </h4>
            <p>
              <FormattedMessage
                id="terms-and-conditions.text.GDPR"
                values={i18TextValues}
              />
            </p>
            <h4>
              <FormattedMessage id="terms-and-conditions.title.cookies" />
            </h4>
            <p>
              <FormattedMessage
                id="terms-and-conditions.text.cookies"
                values={i18TextValues}
              />
            </p>
            <h4>
              <FormattedMessage id="terms-and-conditions.title.change-of-purchase-conditions" />
            </h4>
            <p>
              <FormattedMessage
                id="terms-and-conditions.text.change-of-purchase-conditions"
                values={i18TextValues}
              />
            </p>
            <h4>
              <FormattedMessage id="terms-and-conditions.title.contact" />
            </h4>
            <p>
              <FormattedMessage
                id="terms-and-conditions.text.contact"
                values={i18TextValues}
              />
            </p>
            <p>
              <FormattedMessage
                id="terms-and-conditions.text.contact.org-nr"
                values={i18TextValues}
              />
            </p>
          </div>
        </Dialog>
      )}
    </>
  );
};
