import { FormattedMessage, useIntl } from "react-intl";

import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";

import { DivisionScoreboard } from "../modules/game/models/Series";

interface Props {
  scoreboard: DivisionScoreboard[];
}

export const Scoreboard: React.FC<Props> = ({ scoreboard }) => {
  const intl = useIntl();

  if (!scoreboard?.length) {
    return (
      <p>
        <FormattedMessage
          id="series.scoreboard.no-results"
          defaultMessage="Inga resultat finns rapporterade än"
        />
      </p>
    );
  }

  return (
    <>
      <DataTable
        dataKey="id"
        value={scoreboard}
        stripedRows
        responsiveLayout="scroll"
      >
        <Column field="position" header="#" />
        <Column
          style={{ width: "18rem" }}
          body={(sb: DivisionScoreboard) =>
            sb.team.teamMembers.map(member => member.lastName).join(" & ")
          }
          header={intl.formatMessage({
            id: "common.team",
            defaultMessage: "Lag",
          })}
        />
        <Column
          body={(sb: DivisionScoreboard) =>
            `${sb.numberOfPlayedMatches} / ${sb.numberOfTotalMatches}`
          }
          header={intl.formatMessage({
            id: "serie.scoreboard.header.numberOfMatches",
            defaultMessage: "Spelade matcher",
          })}
        />
        <Column
          field="pointsSetsWon"
          header={intl.formatMessage({
            id: "serie.scoreboard.header.pointsSetsWon",
            defaultMessage: "Vunna set",
          })}
        />
        <Column
          field="pointsSetsWonOvertime"
          header={intl.formatMessage({
            id: "serie.scoreboard.header.pointsSetsWonOvertime",
            defaultMessage: "Vinst övertid",
          })}
        />
        <Column
          field="pointsSetsDrawOvertime"
          header={intl.formatMessage({
            id: "serie.scoreboard.header.pointsSetsDrawOvertime",
            defaultMessage: "Oavgjort",
          })}
        />
        <Column
          field="pointsSetsLostOvertime"
          header={intl.formatMessage({
            id: "serie.scoreboard.header.pointsSetsLostOvertime",
            defaultMessage: "Förlust övertid",
          })}
        />
        <Column
          field="pointsSetsLost"
          header={intl.formatMessage({
            id: "serie.scoreboard.header.pointsSetsLost",
            defaultMessage: "Förlorade set",
          })}
        />
        <Column
          body={(sb: DivisionScoreboard) => `${sb.gameWins} / ${sb.gameLost}`}
          header={intl.formatMessage({
            id: "serie.scoreboard.header.gameWonLost",
            defaultMessage: "Gem V/F",
          })}
        />
        <Column
          field="plusMinusGame"
          header={intl.formatMessage({
            id: "serie.scoreboard.header.plusMinusGame",
            defaultMessage: "Gem +/-",
          })}
        />
        <Column
          field="totalPoints"
          header={intl.formatMessage({
            id: "serie.scoreboard.header.totalPoints",
            defaultMessage: "Poäng",
          })}
        />
      </DataTable>
    </>
  );
};
