import { FormattedMessage } from "react-intl";

import { useFormik } from "formik";
import { DateTime } from "luxon";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Skeleton } from "primereact/skeleton";
import styled from "styled-components";

import { IEditSerieFormValues } from "../../../models/Series";

import { useSeries } from "../../../../../hooks/swr/useSeries";
import { useGenderTypes } from "../../../../../hooks/useGenderTypes";
import { useFormValidationSchema } from "../../../hooks/useFormValidationSchema";

import { updateSerie } from "../../../services/Serie";

import { Button } from "../../../../../components/Button";
import { CalendarDateTimeWrapper } from "../../../../../components/CalendarDateTimeWrapper";

const InputContainer = styled.div`
  display: grid;
  margin: 1rem 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

interface Props {
  seriesId: string;
  onSubmit: () => void;
}

const AdminEditSerieForm = ({ seriesId: activityId, onSubmit }: Props) => {
  const { genderTypes } = useGenderTypes();
  const { editSeriesSchema } = useFormValidationSchema();

  const { series, isLoading: loading } = useSeries(activityId, "participants");

  const formik = useFormik<IEditSerieFormValues>({
    initialValues: {
      name: series?.name,
      gender: series?.gender,
      facilityId: series?.facilityId,
      openForRegistration: series?.openForRegistration,
      startTime: series ? series?.startTime : DateTime.now(),
      endTime: series ? series?.endTime : DateTime.now(),
      description: series?.description ?? "",
      registrationOpenTo: series ? series.registrationOpenTo : DateTime.now(),
      rescheduleRules: series?.rescheduleRules,
      hasPrice: series?.hasPrice || !!series?.price?.valueInclTax,
      price: series?.price?.valueInclTax,
    },
    onSubmit: async (data: IEditSerieFormValues) => {
      try {
        const body = {
          ...data,
          gender: parseInt(data?.gender.toString()),
          price: data.hasPrice ? data.price : 0,
        } satisfies IEditSerieFormValues;

        await updateSerie(activityId, body);

        onSubmit();
      } catch (err) {
        // TODO: process if known errors
        console.log(err);
      }
    },
    validationSchema: editSeriesSchema,
    enableReinitialize: true,
    validateOnMount: false,
    initialTouched: {
      startTime: true,
      registrationOpenTo: true,
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div>
        <h3>{series?.name}</h3>
        <InputContainer>
          <label htmlFor="name">
            <FormattedMessage id="common.name" defaultMessage="Namn" />
          </label>

          {loading ? (
            <Skeleton width="100%" height="3rem" />
          ) : (
            <>
              <InputText
                id="name"
                name="name"
                defaultValue={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.name && formik.errors.name && (
                <small className="p-error p-block">{formik.errors.name}</small>
              )}
            </>
          )}
        </InputContainer>

        <InputContainer>
          <label htmlFor="startTime">
            <FormattedMessage id="common.start-time" />
          </label>

          {loading ? (
            <Skeleton width="100%" height="3rem" />
          ) : (
            <div>
              <CalendarDateTimeWrapper
                className="w-full"
                selectOtherMonths
                id="startTime"
                name="startTime"
                value={formik.values.startTime}
                onChange={e => formik.setFieldValue("startTime", e.value, true)}
                onBlur={formik.handleBlur}
                readOnlyInput
                showIcon
                disabled={series?.startTime < DateTime.utc()}
              />
              {formik.touched.startTime && formik.errors.startTime && (
                <small className="p-error p-block">
                  <>{formik.errors.startTime}</>
                </small>
              )}
            </div>
          )}
        </InputContainer>

        <InputContainer>
          <label htmlFor="endTime">
            <FormattedMessage id="common.end-time" />
          </label>

          {loading ? (
            <Skeleton width="100%" height="3rem" />
          ) : (
            <div>
              <CalendarDateTimeWrapper
                className="w-full"
                selectOtherMonths
                id="endTime"
                name="endTime"
                minDate={formik.values.startTime.plus({ days: 1 })}
                value={formik.values.endTime}
                onChange={e => formik.setFieldValue("endTime", e.value, true)}
                onBlur={formik.handleBlur}
                readOnlyInput
                showIcon
              />
              {formik.touched.endTime && formik.errors.endTime && (
                <small className="p-error p-block">
                  <>{formik.errors.endTime}</>
                </small>
              )}
            </div>
          )}
        </InputContainer>

        <InputContainer>
          <label htmlFor="registrationOpenTo">
            <FormattedMessage
              id="series.input.registration-open-to"
              defaultMessage="Registrering öppen till"
            />
          </label>

          {loading ? (
            <Skeleton width="100%" height="3rem" />
          ) : (
            <div>
              <CalendarDateTimeWrapper
                className="w-full"
                selectOtherMonths
                id="registrationOpenTo"
                name="registrationOpenTo"
                stepMinute={30}
                maxDate={formik.values.startTime.minus({ days: 1 })}
                value={formik.values.registrationOpenTo}
                onChange={e =>
                  formik.setFieldValue("registrationOpenTo", e.value)
                }
                onBlur={formik.handleBlur}
                readOnlyInput
                showIcon
              />
              {formik.touched.registrationOpenTo &&
                formik.errors.registrationOpenTo && (
                  <small className="p-error p-block">
                    <>{formik.errors.registrationOpenTo}</>
                  </small>
                )}
            </div>
          )}
        </InputContainer>

        <InputContainer>
          <label htmlFor="description">
            <FormattedMessage
              id="common.description"
              defaultMessage="Beskrivning"
            />
          </label>

          {loading ? (
            <Skeleton width="100%" height="3rem" />
          ) : (
            <div>
              <InputTextarea
                id="description"
                name="description"
                value={formik.values.description}
                onChange={e =>
                  formik.setFieldValue("description", e.target.value, true)
                }
                onBlur={formik.handleBlur}
                style={{ width: "100%" }}
                rows={3}
              />
            </div>
          )}
        </InputContainer>

        <InputContainer>
          <label htmlFor="gender">
            <FormattedMessage id="common.gender" defaultMessage="Kön" />
          </label>
          <div>
            {loading ? (
              <Skeleton width="100%" height="3rem" />
            ) : (
              <>
                <Dropdown
                  id="gender"
                  name="gender"
                  optionValue="id"
                  optionLabel="name"
                  options={genderTypes}
                  value={formik.values.gender?.toString()}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.gender && formik.errors.gender && (
                  <small className="p-error p-block">
                    {formik.errors.gender}
                  </small>
                )}
              </>
            )}
          </div>
        </InputContainer>

        <InputContainer>
          <label htmlFor="hasPrice">
            <FormattedMessage id="series.steps.guide.fee" />
          </label>
          <div className="pt-2">
            <InputSwitch
              name="hasPrice"
              onChange={state =>
                formik.setFieldValue("hasPrice", state.value, true)
              }
              checked={formik.values.hasPrice}
            />
            {formik.errors.hasPrice && (
              <small className="p-error p-block">
                {formik.errors.hasPrice}
              </small>
            )}
          </div>
        </InputContainer>

        {formik.values.hasPrice && (
          <InputContainer>
            <label htmlFor="price">
              <FormattedMessage id="series.steps.guide.fee-per-person" />
            </label>
            <div>
              <InputNumber
                name="price"
                mode="decimal"
                minFractionDigits={2}
                maxFractionDigits={2}
                onChange={e => formik.setFieldValue("price", e.value || 0)}
                useGrouping={false}
                value={formik.values.price}
              />
              {formik.errors.price && (
                <small className="p-error p-block">{formik.errors.price}</small>
              )}
            </div>
          </InputContainer>
        )}

        <InputContainer>
          <label htmlFor="openForRegistration" className="m-0">
            <FormattedMessage id="admin-series-table.registration-status-column" />
          </label>
          <div className="pt-2">
            <InputSwitch
              name="openForRegistration"
              onChange={state =>
                formik.setFieldValue("openForRegistration", state.value, true)
              }
              checked={formik.values.openForRegistration}
            />
            {formik.errors.openForRegistration && (
              <small className="p-error p-block">
                {formik.errors.openForRegistration}
              </small>
            )}
          </div>
        </InputContainer>

        <ButtonContainer>
          <Button
            buttonType="submit"
            type="primary"
            translationName="button.save"
            text="Spara"
            disabled={
              loading || !formik.isValid || formik.isSubmitting || !formik.dirty
            }
          />
        </ButtonContainer>
      </div>
    </form>
  );
};

export default AdminEditSerieForm;
