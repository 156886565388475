import React from "react";

import styled from "styled-components";

import { breakpoints } from "../appConstants/common";

import { Card } from "../components/Card";
import { UserOverviewCalendar } from "../modules/checkout/components/Calendar/UserOverviewCalendar";
import { FindFacilityTimeSlots } from "../modules/checkout/components/FacilitySearch/FindFacilityTimeSlots";
import { UserActivities } from "../modules/game/components/activities/UserActivities";
import { GamesCard } from "../modules/game/components/userOverview/GamesCard";

const OverviewLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  gap: 1rem;

  @media (min-width: ${breakpoints.XL}) {
    grid-template-columns: 1fr 1fr !important;
    gap: 2rem;
  }
`;

const OverflowingCard = styled.div`
  overflow: visible;
  justify-content: center;
`;

const SearchWrapper = styled.div`
  padding: 1rem;
  @media (min-width: ${breakpoints.XL}) {
    padding: 2rem;
  }
  background: var(--white);
`;

const FullCard = styled(Card)`
  display: flex;
  flex-direction: column;
  border-radius: 0.25rem;
  padding: 0;
  grid-column: 1 / -1;
  gap: 0;
  @media (min-width: ${breakpoints.XXL}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;

const ActivityContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background: var(--gray-100);
  padding: 1rem;

  @media (min-width: ${breakpoints.XL}) {
    gap: 2rem;
    padding: 2rem;
  }
`;

const FullGamesCard = styled(Card)`
  display: flex;
  flex-direction: column;
  border-radius: 0.25rem;
  padding: 0;
  grid-column: 1 / -1;
  gap: 0;
`;

export const UserOverview: React.FC = () => {
  return (
    <OverviewLayout>
      <OverflowingCard>
        <SearchWrapper>
          <FindFacilityTimeSlots />
        </SearchWrapper>
      </OverflowingCard>

      <Card className="col-span-full overflow-hidden sm:overflow-visible">
        <UserOverviewCalendar hasMenu />
      </Card>

      <FullCard>
        <GamesCard isUpcoming />
        <ActivityContainer>
          <UserActivities />
        </ActivityContainer>
      </FullCard>

      <FullGamesCard>
        <GamesCard nrOfGames={3} />
      </FullGamesCard>
    </OverviewLayout>
  );
};
