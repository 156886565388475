import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import {
  faPlus,
  faPlusCircle,
  faQuestionCircle,
  faTrashAlt,
  faUserGroup,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "primereact/tooltip";
import styled from "styled-components";

import {
  PriceCategory,
  PriceGroup,
  PriceListResponseWithUTC,
} from "../../../../../modules/checkout/models/Pricing";
import { GroupTypeStrings } from "../../../../../modules/player/models/Group";

import { useToaster } from "../../../../../hooks/common/useToaster";
import { useStandardGroup } from "../../../../../modules/customer/hooks/useStandardGroup";

import {
  bulkUpdatePrice,
  deletePriceListGroup,
  updatePriceCategory,
} from "../../../../../modules/checkout/services/Pricing";

import { Button } from "../../../../../components/Button";
import { ConfirmationDialog } from "../../../../../components/ConfirmationDialog";
import { Dialog } from "../../../../../components/Dialog";
import { TextInput } from "../../../../../components/TextInput";
import { SelectInput } from "../../../../../components/inputs/SelectInput";

import { EditPriceCategory } from "./EditPriceCategory";

interface Props {
  discountGroups?: any;
  currentPriceList?: PriceListResponseWithUTC;
  fetchPriceLists?: any;
  fetchGroups?: any;
  onAddGroup?: any;
}

const Container = styled.div`
  margin-top: -1rem;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  width: 100%;
  overflow: auto;
`;

const TableHeader = styled.thead`
  td,
  a {
    white-space: nowrap;
    font-weight: var(--bold);
  }
`;

const TableBody = styled.tbody`
  position: relative;
  font-size: 1rem;
`;

const Table = styled.table`
  margin-bottom: 1rem;
  border-collapse: collapse;
  width: 80%;
  th {
    text-align: left;
    padding: 1rem;
  }
  td {
    padding: 1rem;
  }

  tbody tr:nth-child(odd) {
    background-color: var(--gray-100);
  }
  display: flex-column;
  align-content: flex-end;
  align-items: center;
`;

const InputContainer = styled.div`
  margin: 1rem 0;

  label {
    font-weight: var(--bold);
    font-size: 1.1rem;
  }
`;

const NameColumn = styled.td`
  width: 10rem;
  text-align: left !important;
  white-space: nowrap;
  font-weight: var(--bold);

  input {
    font-weight: var(--medium);
  }
`;

const PriceColumn = styled.td`
  text-align: left !important;
  input {
    width: 5rem;
    font-weight: var(--medium);
  }
`;

const DiscountGroupHeader = styled.div`
  display: flex;
  white-space: nowrap;
  font-weight: var(--bold);
  gap: 0.5rem;
`;

const ClickableIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
`;

const AddDiscountGroupContainer = styled.td`
  justify-content: flex-end;
  align-items: flex-end;
  display: flex;

  button:hover {
    box-shadow: none;
  }
`;

export const PriceCategoryTable: React.FC<Props> = ({
  discountGroups,
  currentPriceList,
  fetchPriceLists,
  fetchGroups,
  onAddGroup,
}) => {
  const intl = useIntl();
  const { toastSuccess, toastError } = useToaster();
  const standardGroup = useStandardGroup();
  const [editingRow, setEditingRow] = useState<PriceCategory>();
  const [showNewDiscountGroupDialog, setShowNewDiscountGroupDialog] =
    useState(false);

  const [createNewGroup, setCreateNewGroup] = useState(false);
  const [group, setGroup] = useState<PriceGroup>({ id: "", name: "" });
  const [groupToBeRemoved, setGroupToBeRemoved] = useState<PriceGroup>({
    id: "",
    name: "",
  });
  const [priceList, setPriceList] =
    useState<PriceListResponseWithUTC>(currentPriceList);
  const [showRemoveDialog, setShowRemoveDialog] = useState<boolean>(false);

  const discountGroupOptions = discountGroups
    ?.filter(
      discountGroup =>
        !priceList.categories.find(({ groups }) =>
          groups.find(({ id }) => id === discountGroup.id),
        ),
    )
    .map(({ name, id }) => ({ label: name ?? id, value: id }));

  useEffect(() => {
    setPriceList(currentPriceList);
  }, [currentPriceList]);

  return (
    <Container>
      <Table>
        <TableHeader>
          <tr>
            <th />
            <NameColumn>
              <FormattedMessage id="common.name" defaultMessage="Namn" />
            </NameColumn>
            <PriceColumn key="priceListCategory">
              <div className="flex items-center">
                <FormattedMessage
                  id="common.reschedule.priority"
                  defaultMessage="Ombokningsprioritet"
                />
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  id="reschedule-prio-tooltip"
                  className="ml-2 text-base"
                />
              </div>
              <Tooltip
                className="max-w-prose"
                target="#reschedule-prio-tooltip"
                content={intl.formatMessage({
                  id: "common.reschedule.priority.tooltip",
                })}
              />
            </PriceColumn>
            <PriceColumn key={GroupTypeStrings.STANDARD}>
              {standardGroup.name}
            </PriceColumn>
            {[...priceList.categories.flatMap(x => x.groups)]
              .reduce(
                (acc, cur) =>
                  acc.find(({ id }) => id === cur.id) ? acc : [...acc, cur],
                [],
              )
              .map((group, index) => {
                return (
                  <PriceColumn key={`${index}_${group.id}`}>
                    <DiscountGroupHeader>
                      <ClickableIcon
                        onClick={async () => {
                          setGroupToBeRemoved(group);
                          setShowRemoveDialog(true);
                        }}
                        icon={faTrashAlt}
                        color="var(--primary)"
                      />
                      {group.name}
                    </DiscountGroupHeader>
                  </PriceColumn>
                );
              })}
            <AddDiscountGroupContainer>
              <Button
                text={intl.formatMessage({
                  id: "pricings.discount-group",
                })}
                type="add"
                style={{ border: "none", padding: 0, gap: "0.5rem" }}
                icon={faPlus}
                onClick={() => setShowNewDiscountGroupDialog(true)}
              />
            </AddDiscountGroupContainer>
          </tr>
        </TableHeader>
        <TableBody>
          {priceList?.categories.map((category, index) => (
            <EditPriceCategory
              key={category.id}
              readOnly={category.id !== editingRow?.id}
              category={category}
              index={index}
              fetchPriceLists={fetchPriceLists}
              fetchGroups={fetchGroups}
              priceList={priceList}
              onEditCategory={() => setEditingRow(category)}
              onCancelEdit={() => setEditingRow(undefined)}
              onPriceChanged={async (category, prices) => {
                try {
                  await updatePriceCategory(priceList?.id, {
                    id: category.id,
                    name: category?.name,
                    priority: category?.priority,
                  });

                  await bulkUpdatePrice(priceList?.id, prices);
                  toastSuccess.priceSaved();
                  setEditingRow(undefined);

                  await fetchPriceLists();
                  await fetchGroups();
                } catch (e) {
                  toastError.updatePricingCategoryFailed();
                }
              }}
            />
          ))}
        </TableBody>
      </Table>

      <ConfirmationDialog
        icon={faUserGroup}
        visible={showRemoveDialog}
        title={intl.formatMessage({
          id: "facility.pricelist.group.remove",
        })}
        text={intl.formatMessage(
          {
            id: "facility.pricelist.group.remove.are-you-sure",
          },
          { discountGroupName: groupToBeRemoved.name },
        )}
        denyText={intl.formatMessage({
          id: "facility.pricelist.group.remove.reject",
        })}
        confirmText={intl.formatMessage({
          id: "common.confirm.remove",
        })}
        loading={false}
        onHide={() => setShowRemoveDialog(false)}
        onCancel={() => setShowRemoveDialog(false)}
        onSubmit={async () => {
          try {
            const result = await deletePriceListGroup(
              priceList.id,
              groupToBeRemoved.id,
            );

            setShowRemoveDialog(false);
            setGroupToBeRemoved({ id: "", name: "" });
            setPriceList(result?.data);
            toastSuccess.discountGroupDeleted();
          } catch (e) {
            toastError.deleteDiscountGroupFailed();
          }
        }}
      />

      <Dialog
        blockScroll
        contentStyle={{
          overflowY: "visible",
          display: "flex",
          gap: "1rem",
          flexDirection: "column",
        }}
        visible={showNewDiscountGroupDialog}
        onHide={() => {
          setShowNewDiscountGroupDialog(false);
          setCreateNewGroup(false);
          setGroup({ id: "", name: "" });
        }}
      >
        <h3>
          <FontAwesomeIcon
            icon={faPlusCircle}
            className="text-primary"
            style={{ marginRight: "1rem" }}
          />
          <FormattedMessage
            id="pricings.discount-group.add"
            defaultMessage="Lägg till ny rabattgrupp"
          />
        </h3>
        <small style={{ fontSize: "1rem", lineHeight: "1.4" }}>
          <FormattedMessage
            id="pricings.discount-group.add.description"
            defaultMessage="Lägg till en grupp via listan nedan eller skapa en ny grupp."
          />
        </small>

        <InputContainer>
          <label htmlFor="name" className="p-block">
            {createNewGroup ? (
              <FormattedMessage
                id="common.group-name"
                defaultMessage="Namn på gruppen"
              />
            ) : (
              <FormattedMessage id="common.name" defaultMessage="Namn" />
            )}
          </label>

          {!createNewGroup && (
            <SelectInput
              value={group.id}
              options={discountGroupOptions}
              onChange={e => {
                const { label, value } = discountGroupOptions.find(
                  ({ value }) => value === e.value,
                );

                setGroup({ id: value, name: label });
              }}
            />
          )}
          {createNewGroup && (
            <div style={{ marginTop: "-.5rem" }}>
              <TextInput
                name="name"
                required
                type="text"
                onChange={e => setGroup({ ...group, name: e.target.value })}
              />
            </div>
          )}
        </InputContainer>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flex: "1 1 auto",
            alignItems: "flex-end",
          }}
        >
          <Button
            text="Spara"
            translationName="common.save"
            disabled={!group?.name}
            buttonType="button"
            type="primary"
            onClick={() => {
              setShowNewDiscountGroupDialog(false);
              setCreateNewGroup(false);
              onAddGroup(group);
            }}
          />
        </div>
      </Dialog>
    </Container>
  );
};
