import { useMemo } from "react";

import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faBackward,
  faForward,
  faPlay,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import {
  PaginatorFirstPageLinkType,
  PaginatorLastPageLinkType,
  PaginatorNextPageLinkType,
  PaginatorPageLinksType,
  PaginatorPrevPageLinkType,
  PaginatorTemplate,
} from "primereact/paginator";
import { useRecoilState } from "recoil";

import { pageNumberState } from "../../recoil/Admin/settings/settlementReportsState";

interface PaginatorButtonProps {
  totalNumberOfRecords: number;
  pageNumber?: number;
  rowsPerPage: number;
  icon: IconProp;
  className?: string;
  disabled?: boolean;
  handlePageChange?: () => void;
}

const PaginatorButton = ({
  className,
  totalNumberOfRecords,
  pageNumber,
  rowsPerPage,
  icon,
  handlePageChange,
  disabled,
}: PaginatorButtonProps) => {
  const isFirstPage = pageNumber === 1;
  const isLastPage =
    pageNumber === Math.ceil(totalNumberOfRecords / rowsPerPage);

  return (
    <button
      type="button"
      className={clsx(className, {
        disabled,
        isFirstPage,
        isLastPage,
      })}
      onClick={handlePageChange}
      disabled={disabled}
    >
      <FontAwesomeIcon
        color={disabled ? "var(--secondary)" : "var(--primary)"}
        icon={icon}
        size="xs"
      />
    </button>
  );
};

export default function useBackEndPagination(
  totalNumberOfRecords: number,
  rowsPerPage = 10,
): PaginatorTemplate {
  const totalPages = useMemo(() => {
    return Math.ceil(totalNumberOfRecords / rowsPerPage);
  }, [totalNumberOfRecords, rowsPerPage]);
  const [pageNumber, setPageNumber] = useRecoilState<number>(pageNumberState);

  const FirstPageLink = (): PaginatorFirstPageLinkType => (
    <PaginatorButton
      totalNumberOfRecords={totalNumberOfRecords}
      rowsPerPage={rowsPerPage}
      icon={faBackward}
      disabled={pageNumber === 1}
      className="px-3"
      handlePageChange={() => setPageNumber(1)}
    />
  );
  const LastPageLink = (): PaginatorLastPageLinkType => {
    const totalPages = Math.ceil(totalNumberOfRecords / rowsPerPage);
    return (
      <PaginatorButton
        totalNumberOfRecords={totalNumberOfRecords}
        rowsPerPage={rowsPerPage}
        icon={faForward}
        disabled={pageNumber === totalPages}
        className="px-3"
        handlePageChange={() => setPageNumber(totalPages)}
      />
    );
  };
  const PrevPageLink = (): PaginatorPrevPageLinkType => {
    const prevPage = pageNumber - 1;
    return (
      <PaginatorButton
        totalNumberOfRecords={totalNumberOfRecords}
        rowsPerPage={rowsPerPage}
        icon={faPlay}
        className="fa-rotate-180 px-3"
        disabled={pageNumber === 1}
        handlePageChange={() => setPageNumber(prevPage)}
      />
    );
  };
  const NextPageLink = (): PaginatorNextPageLinkType => {
    const nextPage = pageNumber + 1;
    return (
      <PaginatorButton
        totalNumberOfRecords={totalNumberOfRecords}
        rowsPerPage={rowsPerPage}
        icon={faPlay}
        disabled={pageNumber === totalPages}
        className="px-3"
        handlePageChange={() => setPageNumber(nextPage)}
      />
    );
  };

  const PageLinks = (options): PaginatorPageLinksType => {
    return (
      <button
        type="button"
        className={clsx("p-paginator-page", "active")}
        onClick={options.onClick}
      >
        {pageNumber}
      </button>
    );
  };

  const defaultTemplate = {
    layout: "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink",
    FirstPageLink,
    PrevPageLink,
    NextPageLink,
    PageLinks,
    LastPageLink,
  };

  return defaultTemplate as PaginatorTemplate;
}
