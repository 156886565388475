import { useState } from "react";
import { useHistory } from "react-router-dom";

import type { Order } from "../../../models/Order";
import { BuyableType } from "../../checkout/models/Payment";
import { IActivity } from "../models/Activity";

import { getCheckoutPath } from "../../../helpers/pathHelpers";

import { useToaster } from "../../../hooks/common/useToaster";

import { createOrder } from "../../../services/myOrdersService";

type ActivityData = Pick<IActivity, "buyableType" | "id" | "facilityId">;

export const useJoinActivity = () => {
  const history = useHistory();
  const { toastError } = useToaster();

  const [isLoading, setIsLoading] = useState(false);

  const handlePayActivity = async (activity: ActivityData) => {
    setIsLoading(true);

    let order: Order;

    try {
      if (activity.buyableType === BuyableType.Series) {
        order = await createOrder(activity.facilityId, {
          $type: "series",
          seriesId: activity.id,
        });
      } else {
        order = await createOrder(activity.facilityId, {
          $type: "event",
          eventId: activity.id,
        });
      }

      history.push(getCheckoutPath(order.id));
    } catch (err) {
      console.error(err);
      toastError.joinSerieFailed();
      setIsLoading(false);
    }
  };

  return {
    handlePayActivity,
    isLoading,
  };
};
