import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

import { User } from "../modules/player/models/User";

import { getFriends } from "../modules/player/services/FriendsService";

import { ProgressSpinner } from "./ProgressSpinner";
import { UsersList } from "./UsersList";

interface Props {
  onClick: (u: User) => void;
  selectedUsers: User[];
}

export const UserSearchFriendsList: React.FC<Props> = ({
  onClick,
  selectedUsers,
}) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [friends, setFriends] = useState<User[]>();

  useEffect(() => {
    const abortController = new AbortController();
    const fetchFriends = async () => {
      const friendList = await getFriends(abortController.signal);
      const selectedUserIds = selectedUsers.map(u => u.id);
      const notSelectedFriends = friendList?.filter(
        u => !selectedUserIds.includes(u.id),
      );

      if (!abortController.signal.aborted) {
        setFriends(notSelectedFriends);
        setLoading(false);
      }
    };

    fetchFriends();

    return () => abortController.abort();
  }, []);

  return (
    <div>
      {loading && <ProgressSpinner />}
      {!loading && (
        <div className="flex flex-col">
          <h5 className="p-4">
            <FormattedMessage
              id="common.my-friends"
              defaultMessage="Mina vänner"
            />
          </h5>
          {!!friends?.length && (
            <UsersList users={friends} enableRemove={false} onClick={onClick} />
          )}
          {!friends?.length && (
            <p className="p-4 text-center text-gray-700">
              <FormattedMessage
                id="search.user.no-more-friends"
                defaultMessage="Inga fler vänner att lägga till."
              />
            </p>
          )}
        </div>
      )}
    </div>
  );
};
