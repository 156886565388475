import { Link } from "react-router-dom";

import { motion } from "framer-motion";
import { useSetRecoilState } from "recoil";

import { User } from "../../../modules/player/models/User";

import { getProfilePath } from "../../../helpers/pathHelpers";

import { useFacility } from "../../../hooks/swr/useFacility";

import { ProfileImageWithFallback } from "../../ProfileImageWithFallback";
import { isMobileMenuOpenState } from "../Header";

const SearchResultItem = ({ user }: { user: User }) => {
  const setIsMobileMenuOpen = useSetRecoilState(isMobileMenuOpenState);
  const { facility } = useFacility(user?.favoriteFacilityId, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  return (
    <motion.li
      variants={{
        enter: {
          opacity: 1,
        },
        initial: {
          opacity: 0,
        },
      }}
    >
      <Link
        onClick={() => setIsMobileMenuOpen(false)}
        to={getProfilePath(user.id)}
        className="flex items-center gap-4 rounded bg-purewhite p-4 font-medium text-gray-700 outline-none transition-colors hover:bg-white hover:text-primary focus-visible:border-primary focus-visible:bg-white focus-visible:text-primary focus-visible:ring-1 focus-visible:ring-primary"
      >
        <ProfileImageWithFallback src={user?.profileImage} />
        <div>
          <p>{user.displayName}</p>
          {facility?.name && <small>{facility.name}</small>}
        </div>
      </Link>
    </motion.li>
  );
};
export const SearchResult = ({ searchResult }: { searchResult: User[] }) => {
  return (
    <motion.ul
      className="absolute top-full mt-1 flex w-full flex-col gap-1 overflow-y-auto bg-white p-1 shadow-md backdrop-blur-xl backdrop-filter"
      variants={{
        enter: {
          opacity: 1,
          maxHeight: "21rem",
          transition: {
            staggerChildren: 0.05,
            delay: 0.4,
          },
        },
        initial: {
          opacity: 0,
          maxHeight: 0,
        },
      }}
      initial="initial"
      animate="enter"
    >
      {searchResult.map(user => (
        <SearchResultItem key={user.id} user={user} />
      ))}
    </motion.ul>
  );
};
