import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { useIsSuperAdmin } from "../../../../hooks/permissions";
import { usePriceLists } from "../../../../hooks/swr/usePriceLists";
import { useSelectedFacility } from "../../../../hooks/swr/useSelectedFacility";
import { useQuery } from "../../../../hooks/useQuery";

import { PriceListManagement } from "./components/PriceListManagement";
import { PriceLists } from "./components/PriceLists";

export const FacilityPriceManagementSettings: React.FC = () => {
  const [showAddPriceList, setShowAddPriceList] = useState(false);
  const { selectedFacility } = useSelectedFacility();
  const isSuperAdmin = useIsSuperAdmin();
  const history = useHistory();
  const query = useQuery();
  const { priceLists, isLoading, mutate } = usePriceLists(selectedFacility?.id);

  const currentPriceListId = query.get("priceListId");
  const currentPriceList =
    currentPriceListId &&
    priceLists?.find(priceList => priceList.id === currentPriceListId);

  const openPriceList = (priceListId: string) => {
    history.push(`?priceListId=${priceListId}`);
  };

  const onGoBack = (shouldRefetch?: boolean) => {
    shouldRefetch && mutate();
    history.goBack();
  };

  if (selectedFacility?.dynamicPricing && !isSuperAdmin) {
    return <h3>This facility is using dynamic pricing</h3>;
  }

  return (
    <div style={{ position: "relative" }}>
      {currentPriceList && selectedFacility?.id ? (
        <PriceListManagement
          onGoBack={onGoBack}
          currentPriceList={currentPriceList}
          fetchPriceLists={() => mutate()}
          selectedFacilityId={selectedFacility?.id}
        />
      ) : (
        <PriceLists
          loading={isLoading}
          priceLists={priceLists}
          openPriceList={openPriceList}
          setShowAddPriceList={setShowAddPriceList}
          showAddPriceList={showAddPriceList}
          selectedFacilityId={selectedFacility?.id}
          fetchPriceLists={() => mutate()}
        />
      )}
    </div>
  );
};
