import { useMemo } from "react";

import { useRecoilValue } from "recoil";

import { UserRole } from "../models/Permissions";

import { userPermissionsState } from "../recoil/Customer/userPermissionsState";
import { useSelectedFacilityId } from "../recoil/selectedFacilityIdState";

export const useIsFacilityOwner = () => {
  const userPermissions = useRecoilValue(userPermissionsState);
  const selectedFacilityId = useSelectedFacilityId();

  return useMemo(
    () =>
      userPermissions?.some(
        p =>
          p.role === UserRole.Owner &&
          p.facilities?.includes(selectedFacilityId),
      ),
    [selectedFacilityId, userPermissions],
  );
};

export const useHasAnyFacilityAdminPermission = () => {
  const userPermissions = useRecoilValue(userPermissionsState);
  return {
    hasAnyFacilityAdminPermission: useMemo(
      () =>
        userPermissions?.some(
          p =>
            p.role === UserRole.FacilityAdmin ||
            p.role === UserRole.Owner ||
            p.role === UserRole.SuperAdmin,
        ),
      [userPermissions],
    ),
  };
};

export const useHasFacilityOwnerPermission = () => {
  const isFacilityOwner = useIsFacilityOwner();
  const isSuperAdmin = useIsSuperAdmin();
  return {
    hasFacilityOwnerPermission: isFacilityOwner || isSuperAdmin,
  };
};

export const useIsFacilityAdmin = () => {
  const userPermissions = useRecoilValue(userPermissionsState);
  const selectedFacilityId = useSelectedFacilityId();

  return useMemo(
    () =>
      userPermissions?.some(
        p =>
          p.role === UserRole.FacilityAdmin &&
          p.facilities?.includes(selectedFacilityId),
      ),
    [selectedFacilityId, userPermissions],
  );
};

export const useHasFacilityAdminPermission = () => {
  const isFacilityAdmin = useIsFacilityAdmin();
  const isFacilityOwner = useIsFacilityOwner();
  const isSuperAdmin = useIsSuperAdmin();
  return {
    hasFacilityAdminPermission:
      isFacilityAdmin || isFacilityOwner || isSuperAdmin,
  };
};

export const useIsSuperAdmin = () => {
  const userPermissions = useRecoilValue(userPermissionsState);

  return useMemo(
    () => userPermissions?.some(p => p.role === UserRole.SuperAdmin),
    [userPermissions],
  );
};
