import useSWR, { SWRConfiguration } from "swr";

import { IBooking } from "../../modules/checkout/models/Booking";

import { getBooking } from "../../modules/checkout/services/Booking";

export const useBooking = (
  bookingId?: IBooking["id"],
  include = "",
  options?: SWRConfiguration,
) => {
  const { data, ...rest } = useSWR(
    bookingId ? ["booking", bookingId, include] : null,
    ([, bookingId, include]) =>
      getBooking(bookingId, include).then(data => data?.data),
    options,
  );

  return {
    ...rest,
    booking: data,
  };
};
