import { toast } from "react-toastify";

import {
  InteractionRequiredAuthError,
  PublicClientApplication,
} from "@azure/msal-browser";

import { loginRequest } from "../authConfig";
import { msalInstance } from "../index";

type HttpMethod = "GET" | "PUT" | "POST" | "DELETE";

if (!process.env.REACT_APP_B2C_AUTHORITY_BASE) {
  throw new Error("REACT_APP_B2C_AUTHORITY_BASE is not set");
}

export async function upload<T>(
  uri: string,
  payload = null,
  absoluteUri = false,
): Promise<T | boolean> {
  const account = msalInstance.getActiveAccount();

  if (!account) {
    throw Error("User not logged in yet, API calls are not allowed yet.");
  }

  const tokenResponse = await tryAcquireToken(msalInstance);

  if (!tokenResponse) {
    throw Error("Unable to get token!");
  }

  const headers = new Headers();
  const bearer = `Bearer ${tokenResponse.accessToken}`;
  headers.append("Authorization", bearer);
  const subscriptionKey = process.env.REACT_APP_SUBSCRIPTION_KEY;
  headers.append("Ocp-Apim-Subscription-Key", subscriptionKey);

  const options = {
    method: "POST",
    headers: headers,
    body: payload,
  };
  const response = await fetch(
    absoluteUri ? uri : process.env.REACT_APP_API_URI + uri,
    options,
  ).catch(err => {
    toast.error("Kunde inte hämta data Fel: " + err.message);
  });
  if (!(response instanceof Object)) {
    throw Error("An error occurred in API");
  }
  if (response.status === 204) return true;
  if (response.status === 200) {
    const data = await response.json();
    return data;
  }
  return Promise.reject(false);
}

async function handleResponse<T>(
  response: Response | Record<string, any>,
  parseDataAs = "json",
): Promise<T | boolean> {
  if (!(response instanceof Object)) {
    if (response === "AbortError") return;
    throw Error("An error occurred in API");
  }

  if (response.status === 204) return true;

  let data = null;

  try {
    switch (parseDataAs) {
      case "json":
        data = await response.json();
        break;
      case "blob":
        data = await response.blob();
        break;
      case "text":
        data = await response.text();
        break;
    }
  } catch (ex) {
    data = null;
  }

  if (response.status >= 200 && response.status < 300) {
    return data;
  } else {
    // TODO: Centralize error response object
    if (
      data &&
      (data.hasValidationErrors || data.message || data.errors || data.error)
    )
      return Promise.reject<T>(data);

    if ("name" in response && response?.name === "AbortError") return;
    throw Error("An error occurred in API");
  }
}

interface fetchApiProps {
  method?: HttpMethod;
  uri: string;
  payload?: unknown;
  absoluteUri?: boolean;
  signal?: AbortSignal;
  parseDataAs?: "json" | "blob" | "text";
}

async function tryAcquireToken(instance: PublicClientApplication) {
  const account =
    instance.getActiveAccount() ||
    instance
      .getAllAccounts()
      .find(a => a.environment == process.env.REACT_APP_B2C_AUTHDOMAIN);

  if (!process.env.REACT_APP_B2C_AUTHORITY_BASE) {
    throw new Error("REACT_APP_B2C_AUTHORITY_BASE is not set");
  }

  if (!account?.idTokenClaims?.tfp) {
    throw new Error("No account found");
  }

  try {
    return await instance.acquireTokenSilent({
      authority:
        process.env.REACT_APP_B2C_AUTHORITY_BASE + account?.idTokenClaims?.tfp,
      ...loginRequest,
      account: account,
    });
  } catch (e) {
    console.error(e);

    // if it is an InteractionRequired error, send the same request in an acquireToken call
    if (e instanceof InteractionRequiredAuthError) {
      await msalInstance.acquireTokenRedirect({
        ...loginRequest,
        account: account,
      });
    }

    return null;
  }
}

export async function fetchApi<T>({
  method = "GET",
  uri,
  payload = null,
  absoluteUri = false,
  signal,
  parseDataAs = "json",
}: fetchApiProps): Promise<T | boolean> {
  const account = msalInstance.getActiveAccount();

  if (!account) {
    throw Error("User not logged in yet, API calls are not allowed yet.");
  }

  const tokenResponse = await tryAcquireToken(msalInstance);

  if (!tokenResponse) {
    throw Error("Unable to get token!");
  }

  const headers = new Headers();
  const bearer = `Bearer ${tokenResponse.accessToken}`;
  headers.append("Authorization", bearer);
  headers.append("Content-Type", "application/json");
  const subscriptionKey = process.env.REACT_APP_SUBSCRIPTION_KEY;

  headers.append("Ocp-Apim-Subscription-Key", subscriptionKey);

  const options = {
    method: method,
    headers: headers,
    body:
      method === "POST" || method === "PUT" || method === "DELETE"
        ? JSON.stringify(payload)
        : null,
    signal: signal,
  };

  const response = await fetch(
    absoluteUri ? uri : process.env.REACT_APP_API_URI + uri,
    options,
  ).catch(async err => {
    return await handleResponse(err?.name);
  });

  if (response) {
    return await handleResponse(response, parseDataAs);
  }
}

export async function anonymousFetchApi<T>({
  method = "GET",
  uri,
  payload = null,
  absoluteUri = false,
  signal,
}: fetchApiProps): Promise<T | boolean> {
  try {
    const options = {
      method: method,
      headers: {
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": process.env.REACT_APP_SUBSCRIPTION_KEY,
      },
      body:
        method === "POST" || method === "PUT" ? JSON.stringify(payload) : null,
      signal: signal,
    };

    const response = await fetch(
      absoluteUri ? uri : process.env.REACT_APP_API_URI + uri,
      options,
    );

    return await handleResponse(response);
  } catch (e) {
    if (e.status === 404) {
      throw Error("404 - Not found");
    }

    throw Error("An error occurred in API");
  }
}
