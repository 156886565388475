import React, { useState } from "react";
import { FormattedMessage } from "react-intl";

import { useFormik } from "formik";
import { sortBy } from "lodash";

import { Facility } from "../../../../../../../modules/checkout/models/api/booking";

import { formatOpenHours } from "../../../../../../../modules/checkout/helpers/typesMapping";

import { useToaster } from "../../../../../../../hooks/common/useToaster";
import { useSelectedFacility } from "../../../../../../../hooks/swr/useSelectedFacility";
import { useRefetchFacilityInformation } from "../../../../../../../modules/customer/hooks/useRefetchFacilityInformation";

import { updateOpenHours } from "../../../../../../../modules/customer/services/FacilityService";

import { SelectInput } from "../../../../../../../components/inputs/SelectInput";
import { FacilitySubmitResetButtons } from "../../../../../../../modules/customer/components/FacilitySubmitResetButtons";

import { DayOfWeek } from "../../../../../../../enums/DayOfWeek";

const weekday = [
  { id: "week-day.sunday", defaultMessage: "Söndag" },
  { id: "week-day.monday", defaultMessage: "Måndag" },
  { id: "week-day.tuesday", defaultMessage: "Tisdag" },
  { id: "week-day.wednesday", defaultMessage: "Onsdag" },
  { id: "week-day.thursday", defaultMessage: "Torsdag" },
  { id: "week-day.friday", defaultMessage: "Fredag" },
  { id: "week-day.saturday", defaultMessage: "Lördag" },
];

const weekdays = [
  DayOfWeek.Monday,
  DayOfWeek.Tuesday,
  DayOfWeek.Wednesday,
  DayOfWeek.Thursday,
  DayOfWeek.Friday,
  DayOfWeek.Saturday,
  DayOfWeek.Sunday,
];

const hours = Array.from(Array(25).keys()).map(hour => {
  const time = hour < 10 ? "0" + hour : hour;
  return {
    label: hour === 24 ? "00:00" : time + ":00",
    value: hour === 24 ? "00:00:00" : time + ":00:00",
  };
});

interface IProps {
  isAllDays?: boolean;
}

export const OpenHoursDays: React.FC<IProps> = ({ isAllDays }) => {
  const { selectedFacility } = useSelectedFacility();

  const { toastError, toastSuccess } = useToaster();

  // Sort the open hours by day of week
  // and move Sunday to the end of the array
  const initialOpenHours = sortBy(selectedFacility?.openHours, "dayOfWeek");
  initialOpenHours.push(initialOpenHours.splice(0, 1)[0]);

  const initialData = {
    ...selectedFacility,
    openHours: initialOpenHours?.map(item => {
      return {
        ...item,
        startTime: item.startTime.toISO(),
        endTime: item.endTime.toISO(),
      };
    }),
  };

  const handleChange = (value: string, key: string | number, field: string) => {
    if (field === "startTime") {
      if (
        formik.values?.openHours[key].endTime == "00:00:00" ||
        value == "00:00:00"
      ) {
        setIsValid(true);
      } else {
        setIsValid(
          parseInt(formik.values?.openHours[key].endTime) > parseInt(value),
        );
      }
    } else {
      if (
        formik.values?.openHours[key].startTime == "00:00:00" ||
        value == "00:00:00"
      ) {
        setIsValid(true);
      } else {
        setIsValid(
          parseInt(formik.values?.openHours[key].startTime) < parseInt(value),
        );
      }
    }
    setValidateKey(+key);
  };

  const [isValid, setIsValid] = useState<boolean>(true);
  const [validateKey, setValidateKey] = useState<number>();

  const { facilityUpdatedCallback } = useRefetchFacilityInformation();

  function setOpenHoursValue(
    key: string | number,
    valueName: string,
    newValue: string,
  ) {
    const openHours = [...(formik.values.openHours || [])];
    const day = { ...openHours[key] };

    if (valueName == "startTime") day.startTime = newValue;
    if (valueName == "endTime") day.endTime = newValue;
    formik.setFieldValue("openHours", [...openHours], true);
    openHours[key] = day;
    formik.setFieldValue("openHours", [...openHours], true);
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialData,
    onSubmit: async (facility: Facility) => {
      facilityUpdatedCallback(async () => {
        try {
          const result = await updateOpenHours(selectedFacility?.id, {
            openHours: formatOpenHours(facility.openHours),
            isSameOpenHoursAllDays: isAllDays,
          });

          if (result) {
            toastSuccess.infoSaved();
          }
          return true;
        } catch (e) {
          e.hasValidationErrors
            ? toastError.validation()
            : toastError.saveFailed();
        }
      });
    },
  });

  return (
    <div className="w-full">
      <form onSubmit={formik.handleSubmit} className="max-w-2xl">
        {weekdays?.map((dayOfWeek, i) => {
          const openHours = formik.values?.openHours?.find(
            item => item.dayOfWeek === dayOfWeek,
          );

          return (
            <div
              className="mt-2 flex flex-col items-center gap-4 sm:flex-row"
              key={i}
            >
              <div className="w-full">
                <FormattedMessage {...weekday[openHours?.dayOfWeek]} />
              </div>
              <div className="w-full">
                <SelectInput
                  value={openHours?.startTime}
                  options={hours.slice(0, -1)}
                  onChange={e => {
                    formik.handleChange(e.value);
                    setOpenHoursValue(i, "startTime", e.value);
                    handleChange(e.value, i, "startTime");
                  }}
                />
              </div>
              <div className="w-full sm:w-auto">
                <FormattedMessage id="common.to" defaultMessage="till" />
              </div>
              <div className="w-full">
                <SelectInput
                  value={openHours?.endTime}
                  options={hours.slice(1)}
                  onChange={e => {
                    formik.handleChange(e.value);
                    setOpenHoursValue(i, "endTime", e.value);
                    handleChange(e.value, i, "endTime");
                  }}
                />
              </div>
              <div className="w-full">
                {!isValid && validateKey == i && (
                  <div className="text-error">
                    <FormattedMessage
                      id="facility-settings.error.opening-hours-greater-than-closing-hours"
                      defaultMessage="Öppningstiden är senare än stängningstiden"
                    />
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </form>
      <div className="mt-5">
        <FacilitySubmitResetButtons
          onSubmit={formik.submitForm}
          onReset={() => formik.resetForm()}
          disabled={!isValid || !formik.dirty}
        />
      </div>
    </div>
  );
};
