import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { faQuestionCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { type FormikErrors, useFormik } from "formik";
import { Divider } from "primereact/divider";
import { InputSwitch } from "primereact/inputswitch";
import { Tooltip } from "primereact/tooltip";

import { ICreateSerieFormValues } from "../../../../models/Series";

import { useIsAdmin } from "../../../../../../hooks/swr/useIsAdmin";

import { NumberInput } from "../../../../../../components/NumberInput";

interface IProps {
  values: ICreateSerieFormValues;
  setFieldValue: ReturnType<typeof useFormik>["setFieldValue"];
  errors: FormikErrors<ICreateSerieFormValues>;
}

const AdminCreateSerieFormSettingsStep: React.FC<IProps> = ({
  values,
  setFieldValue,
  errors,
}) => {
  const intl = useIntl();
  const isAdmin = useIsAdmin();

  return (
    <div style={{ paddingTop: ".5rem" }}>
      <Divider />

      <h3 style={{ marginTop: "3rem", marginBottom: "1rem" }}>
        <FormattedMessage
          id="series.steps.guide.title.settings"
          defaultMessage="Inställningar"
        />
      </h3>

      <div className="mt-6 flex flex-col gap-12">
        <fieldset>
          <label>
            <FormattedMessage
              id="admin-series-table.registration-status-column"
              defaultMessage="Öppen för registrering"
            />
          </label>

          <div className="mt-4 flex items-center justify-start gap-4">
            <FormattedMessage id="common.no" />
            <InputSwitch
              onChange={changeParams =>
                setFieldValue("openForRegistration", changeParams.value)
              }
              name="openForRegistration"
              checked={values.openForRegistration}
            />
            <FormattedMessage id="common.yes" />
          </div>
        </fieldset>

        {isAdmin && (
          <div className="flex flex-col gap-6">
            <div>
              <h4>
                <FormattedMessage id="series.steps.guide.title.reschedule-rules.title" />
              </h4>
              <p>
                <FormattedMessage id="series.steps.guide.title.reschedule-rules.description" />
              </p>
            </div>

            <div className="grid grid-cols-2 gap-x-12 gap-y-8">
              <fieldset>
                <label htmlFor="limitToSelectedCourts">
                  <FormattedMessage id="series.creating-series.limitToSelectedCourts" />
                </label>

                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  id="limitToSelectedCourts-tooltip"
                  className="ml-2 inline-block text-base"
                />
                <Tooltip
                  className="max-w-prose"
                  target="#limitToSelectedCourts-tooltip"
                  content={intl.formatMessage({
                    id: "series.creating-series.limitToSelectedCourts.tooltip",
                  })}
                />

                <div className="mt-4 flex items-center justify-start gap-4">
                  <FormattedMessage id="common.no" />
                  <InputSwitch
                    inputId="limitToSelectedCourts"
                    onChange={changeParams => {
                      setFieldValue(
                        "rescheduleRules.limitToSelectedCourts",
                        changeParams.value,
                      );
                    }}
                    name="rescheduleRules.limitToSelectedCourts"
                    checked={values.rescheduleRules.limitToSelectedCourts}
                  />
                  <FormattedMessage id="common.yes" />
                </div>
              </fieldset>

              <fieldset>
                <label htmlFor="limitToSelectedTime">
                  <FormattedMessage id="series.creating-series.limitToSelectedTime" />
                </label>

                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  id="limitToSelectedTime-tooltip"
                  className="ml-2 inline-block text-base"
                />
                <Tooltip
                  className="max-w-prose"
                  target="#limitToSelectedTime-tooltip"
                  content={intl.formatMessage({
                    id: "series.creating-series.limitToSelectedTime.tooltip",
                  })}
                />

                <div className="mt-4 flex items-center justify-start gap-4">
                  <FormattedMessage id="common.no" />
                  <InputSwitch
                    inputId="limitToSelectedTime"
                    onChange={changeParams => {
                      setFieldValue(
                        "rescheduleRules.limitToSelectedTime",
                        changeParams.value,
                      );
                    }}
                    name="rescheduleRules.limitToSelectedTime"
                    checked={values.rescheduleRules.limitToSelectedTime}
                  />
                  <FormattedMessage id="common.yes" />
                </div>
              </fieldset>
              <fieldset>
                <label htmlFor="blockPrimetime">
                  <FormattedMessage id="series.creating-series.blockPrimetime" />
                </label>

                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  id="blockPrimetime-tooltip"
                  className="ml-2 inline-block text-base"
                />
                <Tooltip
                  className="max-w-prose"
                  target="#blockPrimetime-tooltip"
                  content={intl.formatMessage({
                    id: "series.creating-series.blockPrimetime.tooltip",
                  })}
                />

                <div className="mt-4 flex items-center gap-4">
                  <FormattedMessage id="common.no" />
                  <InputSwitch
                    inputId="blockPrimetime"
                    onChange={changeParams => {
                      setFieldValue(
                        "rescheduleRules.limitToLesserPrioPriceCategories",
                        changeParams.value,
                      );
                    }}
                    name="rescheduleRules.limitToLesserPrioPriceCategories"
                    checked={
                      values.rescheduleRules.limitToLesserPrioPriceCategories
                    }
                  />
                  <FormattedMessage id="common.yes" />
                </div>
              </fieldset>

              <fieldset>
                <label htmlFor="limitToSelectedWeekDays">
                  <FormattedMessage id="series.creating-series.limitToSelectedWeekDays" />
                </label>

                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  id="limitToSelectedWeekDays-tooltip"
                  className="ml-2 inline-block text-base"
                />
                <Tooltip
                  className="max-w-prose"
                  target="#limitToSelectedWeekDays-tooltip"
                  content={intl.formatMessage({
                    id: "series.creating-series.limitToSelectedWeekDays.tooltip",
                  })}
                />

                <div className="mt-4 flex items-center gap-4">
                  <FormattedMessage id="common.no" />
                  <InputSwitch
                    inputId="limitToSelectedWeekDays"
                    onChange={changeParams => {
                      setFieldValue(
                        "rescheduleRules.limitToSelectedWeekDays",
                        changeParams.value,
                      );
                    }}
                    name="rescheduleRules.limitToSelectedWeekDays"
                    checked={values.rescheduleRules.limitToSelectedWeekDays}
                  />
                  <FormattedMessage id="common.yes" />
                </div>
              </fieldset>

              <fieldset>
                <label htmlFor="maxAllowedBookingReschedules">
                  <FormattedMessage id="series.reschedule-rules.maxAllowedBookingReschedules" />
                </label>
                <NumberInput
                  name="rescheduleRules.maxAllowedBookingReschedules"
                  defaultValue={
                    values.rescheduleRules.maxAllowedBookingReschedules
                  }
                  onChange={e =>
                    setFieldValue(
                      "rescheduleRules.maxAllowedBookingReschedules",
                      e.target.value,
                    )
                  }
                  error={errors?.rescheduleRules?.maxAllowedBookingReschedules}
                />
              </fieldset>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminCreateSerieFormSettingsStep;
