import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import { adminGetActivitesEventPath } from "../../../../helpers/pathHelpers";

import { usePaginatedFacilityActivities } from "../../../../hooks/swr/usePaginatedFacilityActivities";
import { useSelectedFacility } from "../../../../hooks/swr/useSelectedFacility";

import { Button } from "../../../../components/Button";
import { Card } from "../../../../components/Card";
import { Dialog } from "../../../../components/Dialog";
import AdminCreateSerieForm from "../../../../modules/game/components/series/forms/AdminCreateSerieForm";
import { AdminActivitiesList } from "./components/AdminActivitiesList";

import { useSelectedFacilityId } from "../../../../recoil/selectedFacilityIdState";

export const AdminActivitiesListPage = () => {
  const [isUpcoming, setUpcoming] = useState(true);
  const [showSerieFormDialog, setShowSerieFormDialog] = useState(false);

  const selectedFacilityId = useSelectedFacilityId();

  const { selectedFacility } = useSelectedFacility();
  const { reset } = usePaginatedFacilityActivities(
    selectedFacilityId,
    isUpcoming,
  );

  return (
    <Card>
      <div className="flex flex-wrap justify-between sm:flex-nowrap">
        <div className="order-3 mt-5 flex items-center space-x-4 sm:order-none sm:mt-0 lg:text-lg">
          <p
            className={`cursor-pointer underline-offset-8 hover:underline ${
              isUpcoming && "underline"
            }`}
            onClick={() => setUpcoming(true)}
          >
            <FormattedMessage id="admin.activities.list.upcoming" />
          </p>
          <p
            className={`cursor-pointer underline-offset-8 hover:underline ${
              !isUpcoming && "underline"
            }`}
            onClick={() => setUpcoming(false)}
          >
            <FormattedMessage id="common.history" />
          </p>
        </div>

        <Button
          className="ml-auto mr-5"
          translationName="series.creating-serie"
          type="default"
          onClick={() => setShowSerieFormDialog(true)}
        />
        <Link to={adminGetActivitesEventPath("new")}>
          <Button
            translationName="admin.activities.event.create"
            type="primary"
          />
        </Link>
      </div>

      {selectedFacility && (
        <AdminActivitiesList
          facilityId={selectedFacility.id}
          isUpcoming={isUpcoming}
        />
      )}

      {showSerieFormDialog && selectedFacility && (
        <Dialog visible onHide={() => setShowSerieFormDialog(false)}>
          <AdminCreateSerieForm
            facilityId={selectedFacility?.id}
            onSubmit={() => {
              setShowSerieFormDialog(false);
              reset();
            }}
          />
        </Dialog>
      )}
    </Card>
  );
};
