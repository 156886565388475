import { useDropzone } from "react-dropzone";

import { faSquareDashedCirclePlus } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";

import { Dialog } from "../../Dialog";

interface Props {
  visible: boolean;
  setVisible: (b: boolean) => void;
  onFileSelect: (file: File[]) => void;
  onImageSelect: (url: string) => void;
  title?: string;
  description?: string;
  selectableImages: string[];
}

export const ImagePicker: React.FC<Props> = ({
  onFileSelect,
  onImageSelect,
  visible,
  setVisible,
  title,
  description,
  selectableImages,
}) => {
  const handleFileSelect = async (acceptedFiles: File[]) => {
    const selectedImage = acceptedFiles?.[0];
    if (!selectedImage) return;
    setVisible(false);
    onFileSelect(acceptedFiles);
  };

  const handleImageSelect = async (url: string) => {
    setVisible(false);
    onImageSelect(url);
  };

  const { getRootProps, getInputProps, isDragAccept, isDragReject } =
    useDropzone({
      multiple: false,
      onDrop: handleFileSelect,
      accept: {
        "image/*": [".png", ".jpg", ".jpeg"],
        "image/heic": [".heic"],
      },
    });

  const dropZoneClasses = clsx(
    "pointer-cursor flex h-[150px] w-[150px] items-center justify-center justify-self-center rounded-[4px] text-4xl text-primary hover:bg-blue-50 md:h-[200px] md:w-[200px] md:text-8xl",
    {
      "bg-blue-50": isDragAccept,
      "bg-gray-300": isDragReject,
    },
  );

  return (
    <Dialog
      onHide={() => setVisible(false)}
      visible={visible}
      className="max-w-auto"
    >
      <h2 className="text-xl">{title}</h2>
      <p className="mb-4">{description}</p>
      <div className="grid grid-cols-2 grid-rows-3 gap-2 md:grid-cols-3 md:grid-rows-2">
        {selectableImages?.map(url => (
          <div
            key={url}
            className="h-[150px] w-[150px] cursor-pointer justify-self-center rounded-[4px] hover:opacity-80 md:h-[200px] md:w-[200px]"
            onClick={() => handleImageSelect(url)}
          >
            <img
              src={url}
              className="h-full w-full rounded-[4px] object-cover"
            />
          </div>
        ))}
        <div className={dropZoneClasses} {...getRootProps({ role: "button" })}>
          <input {...getInputProps()} />
          <FontAwesomeIcon icon={faSquareDashedCirclePlus} />
        </div>
      </div>
    </Dialog>
  );
};
