import React, { useEffect, useState } from "react";

import { DateTime } from "luxon";

import { BookingType } from "../../../../models/Booking";
import {
  ICalendarPayload,
  IScheduleResponseGrouped,
  IScheduleSlot,
} from "../../../../models/Calendar";

import { useSelectableCalendar } from "../../../../hooks/calendar/useSelectableCalendar";

import { isSlotBookable, isSlotLateToBook } from "../../utils";
import CustomerCalendarSlot from "./CustomerCalendarSlot";

interface IProps {
  schedules: IScheduleResponseGrouped[];
  courtId?: string;
  startTime?: DateTime;
  endTime?: DateTime;
  nrOfSlots?: number;
  onSelect: (payload: ICalendarPayload) => void;
  bookingType: BookingType;
}

const CustomerCalendarBody: React.FC<IProps> = ({
  schedules,
  courtId,
  startTime,
  endTime,
  nrOfSlots,
  onSelect,
  bookingType,
}) => {
  const {
    state: { row, selecting },
    beginSelection,
    updateSelection,
    endSelection,
    selectedSlots,
  } = useSelectableCalendar({
    schedules,
    courtId,
    startTime,
    endTime,
    bookingType,
    nrOfSlotsToReschedule: nrOfSlots,
  });

  const [handPickedSlot, setHandPickedSlot] = useState<IScheduleSlot>();

  useEffect(() => {
    if (selectedSlots?.[0]?.startTime !== handPickedSlot?.startTime) {
      setHandPickedSlot(null);
    }
  }, [selectedSlots]);

  useEffect(() => {
    if (
      !selecting &&
      selectedSlots[0]?.startTime !== handPickedSlot?.startTime
    ) {
      let payload: ICalendarPayload = null;
      if (
        row !== -1 &&
        (selectedSlots[0]?.startTime || handPickedSlot?.startTime)
      ) {
        payload = {
          facilityId: schedules[row].facilityId,
          courtId: schedules[row].objectId,
          startTime:
            handPickedSlot?.startTime ??
            selectedSlots[0].startTime ??
            selectedSlots[0]?.[0]?.startTime,
          endTime:
            handPickedSlot?.endTime ??
            selectedSlots[selectedSlots.length - 1].endTime ??
            selectedSlots[0]?.[0]?.endTime,
        };
      }
      onSelect(payload);
    }
  }, [selecting, handPickedSlot]);

  useEffect(() => {
    if (selectedSlots.length < 1) {
      onSelect(null);
    }
  }, [onSelect, selectedSlots]);

  const isSlotSelected = (
    rowIndex: number,
    slot: IScheduleSlot | IScheduleSlot[],
  ) => {
    if (Array.isArray(selectedSlots[0])) {
      const slotArr = slot as IScheduleSlot[];
      return (
        row === rowIndex &&
        selectedSlots.length > 0 &&
        selectedSlots[0][0].startTime === slotArr[0]?.startTime
      );
    }

    const slotObj = slot as IScheduleSlot;
    return (
      row === rowIndex &&
      selectedSlots.length > 0 &&
      selectedSlots[0].startTime === slotObj.startTime &&
      selectedSlots[0].endTime === slotObj.endTime
    );
  };

  return (
    <>
      {schedules.map((court, idx) => (
        <div className="calendar-row" key={court.objectId}>
          <div className="court-name flex-col pl-2">
            <span className="text-sm">{court?.objectName}</span>
            <p className="text-xs font-medium">{court?.objectType?.name}</p>
          </div>
          {court.slots.map(
            (slot: IScheduleSlot | IScheduleSlot[], subIdx: number) => (
              <CustomerCalendarSlot
                facilityId={court.facilityId}
                key={`${idx}-${subIdx}`}
                row={idx}
                column={subIdx}
                width={selectedSlots.length}
                isBookable={isSlotBookable(slot)}
                isLateToBook={isSlotLateToBook(slot)}
                isSelected={isSlotSelected(idx, slot)}
                addSelectedClass={
                  !!selectedSlots?.find(s =>
                    Array.isArray(slot)
                      ? slot.every(sl => sl.startTime === s.startTime)
                      : s.startTime === slot.startTime,
                  )
                }
                startTime={
                  selectedSlots[0]?.startTime ||
                  selectedSlots[0]?.[0]?.startTime
                }
                endTime={
                  selectedSlots[selectedSlots.length - 1]?.endTime ||
                  selectedSlots[0]?.[selectedSlots[0]?.length - 1]?.endTime
                }
                slotsToPick={selectedSlots[0]}
                onPickedSlot={(slot: IScheduleSlot) => setHandPickedSlot(slot)}
                onBeginSelection={beginSelection}
                onEndSelection={endSelection}
                onUpdateSelection={updateSelection}
              />
            ),
          )}
        </div>
      ))}
    </>
  );
};

export default CustomerCalendarBody;
