import { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";

import { faMagnifyingGlass } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AnimatePresence, motion } from "framer-motion";
import { ProgressSpinner } from "primereact/progressspinner";
import { useDebounce } from "usehooks-ts";

import { User } from "../../../modules/player/models/User";

import { queryUsers } from "../../../modules/player/services/UserService";

import { SearchResult } from "./SearchResult";

export const MobileSearch = () => {
  const searchInputRef = useRef<HTMLInputElement>(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [searchResult, setSearchResult] = useState<User[]>([]);
  const abortController = useRef<AbortController | null>(null);
  const searchWrapperRef = useRef<HTMLDivElement>(null);
  const intl = useIntl();

  const debouncedSearchTerm = useDebounce(searchTerm, 300);

  useEffect(() => {
    if (!debouncedSearchTerm) {
      setIsLoading(false);
      return;
    }

    if (abortController.current) {
      abortController.current.abort();
    }

    abortController.current = new AbortController();

    setIsLoading(true);
    setSearchResult([]);

    queryUsers(debouncedSearchTerm, null, true, abortController.current.signal)
      .then(response => {
        setSearchResult(response.data);
        setIsLoading(false);
      })
      .catch(e => {
        console.error(e);
      });
  }, [debouncedSearchTerm]);

  return (
    <AnimatePresence>
      <motion.div
        ref={searchWrapperRef}
        className="relative flex items-center"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.2, delay: 0.1 }}
      >
        <FontAwesomeIcon
          icon={faMagnifyingGlass}
          className="pointer-events-none absolute left-3 text-pureblack"
        />

        <input
          ref={searchInputRef}
          onChange={e => setSearchTerm(e.target.value)}
          value={searchTerm}
          type="text"
          onClick={e => e.stopPropagation()}
          placeholder={intl.formatMessage({ id: "search.profile" })}
          className="h-10 w-full rounded border border-gray-50 bg-white pl-10 pr-8 text-base text-gray-700 focus:border-purewhite focus:bg-purewhite focus:text-inherit focus:outline focus:outline-blue-500"
        />

        {isLoading && <ProgressSpinner className="absolute right-1 h-5 w-5" />}

        {searchResult.length > 0 && (
          <SearchResult searchResult={searchResult} />
        )}
      </motion.div>
    </AnimatePresence>
  );
};
