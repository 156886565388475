const buttonTheme = { PRIMARY: 1, LIGHT: 2 };

export const buttonThemeClassName = {
  [buttonTheme.PRIMARY]: "button-primary",
  [buttonTheme.LIGHT]: "button-light",
};

export const buttonTypes = {
  REGISTRATION_BUTTON: 1,
  BOOK_DEMO_BUTTON: 2,
  CUSTOM_URL_BUTTON: 3,
};

export const breakpoints = {
  XXL: "90rem",
  XL: "75rem",
  LARGE: "50rem",
  TABLET: "56.25rem",
  MOBILE: "37.5rem",
} as const;

export const MIN_AGE = 1;
export const MAX_AGE = 100;
