import { faUser } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

interface Props {
  firstName?: string;
  lastName?: string;
  css?: string;
  src?: string;
  width?: string;
  height?: string;
  fontSize?: string;
  hasImage?: boolean;
  onClick?: (e?: any) => any;
  title?: string;
  disabled?: boolean;
  className?: string;
}

const ImageContainer = styled.div<Props>`
  display: flex;
  position: relative;
  justify-content: center !important;
  align-items: center !important;
  width: ${props => props.width || "2.5rem"};
  height: auto;
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  background-color: ${props =>
    props.hasImage ? "transparent" : "var(--primary)"};
  color: var(--light);
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.1);

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  ${props => props.css}
`;

const DisabledOverlay = styled.div`
  position: absolute;
  background-color: #ffffffbb;
  width: 100%;
  height: 100%;
  border-radius: 50%;
`;

const Initials = styled.div<Props>`
  font-weight: var(--bold) !important;
  color: var(--light);
  font-size: ${props => props.fontSize || "1.125rem"};
  user-select: none;
`;

export const ProfileImageWithFallback = ({
  firstName,
  lastName,
  css,
  width,
  height,
  fontSize,
  src,
  onClick,
  title,
  disabled = false,
  className,
}: Props) => {
  const initials =
    firstName && lastName
      ? `${firstName?.charAt(0)}${lastName?.charAt(0)}`
      : null;
  title = title ?? `${firstName} ${lastName}`;

  return (
    <ImageContainer
      css={css}
      width={width}
      height={height}
      onClick={() => onClick?.()}
      hasImage={!!src}
      className={className}
    >
      {src ? (
        <img src={src} title={title} />
      ) : (
        <Initials fontSize={fontSize} title={title}>
          {initials || <FontAwesomeIcon icon={faUser} />}
        </Initials>
      )}
      {disabled ? <DisabledOverlay /> : <></>}
    </ImageContainer>
  );
};
