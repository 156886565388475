import React from "react";
import { useParams } from "react-router-dom";

import { useSeries } from "../../../../../hooks/swr/useSeries";

import { ProgressSpinner } from "../../../../../components/ProgressSpinner";
import SerieTeamsTable from "./components/SeriesTeamsTable";

interface SerieTeamsPageParams {
  serieId: string;
}

export const AdminSeriesTeamsPage: React.FC = () => {
  const { serieId } = useParams<SerieTeamsPageParams>();

  const { series, isLoading, mutate } = useSeries(
    serieId,
    "payment,participants",
  );

  return (
    <>
      {isLoading ? (
        <div
          style={{
            height: "100vh",
            display: "grid",
            placeItems: "center",
            marginTop: "-15rem",
          }}
        >
          <ProgressSpinner />
        </div>
      ) : (
        <SerieTeamsTable refresh={mutate} serie={series} />
      )}
    </>
  );
};
