import React from "react";
import { FormattedMessage } from "react-intl";

import styled from "styled-components";

import { useSeriesScoreboard } from "../../../../../../hooks/swr/useSeriesScoreboard";

import { ProgressSpinner } from "../../../../../../components/ProgressSpinner";
import { Scoreboard } from "../../../../../../components/Scoreboard";

interface IProps {
  serieId: string;
}

const Container = styled.div`
  display: grid;
  gap: 2rem;
`;

export const SerieScoreboard: React.FC<IProps> = ({ serieId }) => {
  const { scoreboard, isLoading } = useSeriesScoreboard(serieId);

  if (isLoading) {
    return <ProgressSpinner />;
  }

  if (scoreboard.length === 0) {
    return (
      <div className="p-mt-6">
        <h5>
          <FormattedMessage
            id="common.input.no-options"
            defaultMessage="Inga alternativ"
          />
        </h5>
      </div>
    );
  }

  return (
    <Container>
      {scoreboard.map(serieScoreboard => (
        <div key={serieScoreboard.divisionId} className="card scoreboard">
          <h4 style={{ color: "var(--primary)" }}>
            {serieScoreboard.divisionName}
          </h4>
          <Scoreboard scoreboard={serieScoreboard?.scoreboard} />
        </div>
      ))}
    </Container>
  );
};
