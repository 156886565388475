import useSWRImmutable from "swr/immutable";

import { getCountries } from "../../services/publicConstantsService";

export const useCountryCodes = () => {
  const { data: countriesData, ...rest } = useSWRImmutable("countries", () =>
    getCountries(),
  );

  return {
    ...rest,
    countriesData,
  };
};
