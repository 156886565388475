import React from "react";
import { FormattedMessage, MessageDescriptor } from "react-intl";

import styled from "styled-components";

import { breakpoints } from "../../../../appConstants/common";

interface IClickableBoxProps {
  label: MessageDescriptor;
  onClick: () => void;
}

const Box = styled.div`
  display: flex;
  flex: 0.5;
  justify-content: center;
  align-items: center;
  border: 0.1rem dashed var(--primary);
  border-radius: 0.3rem;
  cursor: pointer;

  &:hover {
    border: 0.1rem solid var(--primary);
  }

  &:active {
    box-shadow: 0 0 0 0.2rem var(--primary-light);
    transition: box-shadow 0.05s;
  }
`;
const Label = styled.p`
  padding: 0.2rem 0;
  color: var(--primary);
  font-weight: var(--bold);
  font-size: var(--b5);
  pointer-events: none;
  user-select: none;

  @media (max-width: ${breakpoints.MOBILE}) {
    font-size: var(--b4);
  }
`;

export const ClickableBox: React.FC<IClickableBoxProps> = ({
  label,
  onClick,
}) => {
  return (
    <Box onClick={() => onClick()}>
      <Label>
        <FormattedMessage
          id={label.id}
          description={label.description}
          defaultMessage={label.defaultMessage}
        />
      </Label>
    </Box>
  );
};
