import type { Order } from "../models/Order";

import { post } from "./apiClient";

const API_VERSION = "v1";

const getEndpointBase = (orderId: Order["id"]) =>
  `/${API_VERSION}/superadmin/orders/${orderId}`;

export const superAdminRefundOrder = async (
  orderId: Order["id"],
  includeFee: boolean,
) =>
  post({
    endpoint: `${getEndpointBase(orderId)}/payment/refund`,
    body: {},
    urlSearchParams: { includeFee },
    parseDataAs: "none",
  });

export const superAdminFefundOrderPayment = async (
  orderId: Order["id"],
  paymentId: string,
  includeFee: boolean,
) =>
  post({
    endpoint: `${getEndpointBase(orderId)}/payment/${paymentId}/refund`,
    body: {},
    urlSearchParams: { includeFee },
    parseDataAs: "none",
  });
