import { DateTime } from "luxon";

import { BookingType } from "../../models/Booking";
import { IScheduleSlot } from "../../models/Calendar";

export const isSlotLateToBook = (
  slot: IScheduleSlot | IScheduleSlot[],
): boolean => {
  const now = DateTime.now();

  if (Array.isArray(slot)) {
    return slot.every(s => s.startTime < now);
  }

  return slot.startTime < now;
};

export const isSlotBooked = (
  slot: IScheduleSlot | IScheduleSlot[],
  selectedBookingId?: string,
): boolean => {
  if (Array.isArray(slot)) {
    const slots = slot;
    return !slots?.find(s => !s.isBooked && s.bookingId !== selectedBookingId);
  } else {
    return slot.isBooked && slot.bookingId !== selectedBookingId;
  }
};

export const isSlotBookable = (
  slot: IScheduleSlot | IScheduleSlot[],
  selectedBookingId?: string,
): boolean => {
  if (Array.isArray(slot)) {
    return !slot.some(s => s.bookingType === BookingType.NotBookable);
  }

  if (slot.bookingId === selectedBookingId) {
    return true;
  }

  if (isSlotBooked(slot)) {
    return false;
  }

  return slot.bookingType !== BookingType.NotBookable;
};

export const bookingTypeBorderColor = (bookingType: BookingType): string => {
  switch (bookingType) {
    case BookingType.NotBookable: {
      return "border-gray-700";
    }
    case BookingType.Recurring: {
      return "border-[#E5AFD6]";
    }
    case BookingType.Regular: {
      return "border-green-700";
    }
    case BookingType.Series: {
      return "border-[#17a2b8]";
    }
    case BookingType.Event: {
      return "border-[#7C45BC]";
    }
    case BookingType.Open: {
      return "border-orange-300";
    }
  }

  return "";
};

export const bookingTypeBorderLeftColor = (
  bookingType: BookingType,
): string => {
  switch (bookingType) {
    case BookingType.NotBookable: {
      return "border-l-gray-700";
    }
    case BookingType.Recurring: {
      return "border-l-[#E5AFD6]";
    }
    case BookingType.Regular: {
      return "border-l-green-700";
    }
    case BookingType.Series: {
      return "border-l-[#17a2b8]";
    }
    case BookingType.Event: {
      return "border-l-[#7C45BC]";
    }
    case BookingType.Open: {
      return "border-l-orange-300";
    }
  }

  return "";
};

export const bookingTypeBackgroundColor = (
  bookingType: BookingType,
): string => {
  switch (bookingType) {
    case BookingType.NotBookable: {
      return "bg-gray-700";
    }
    case BookingType.Recurring: {
      return "bg-[#E5AFD6]";
    }
    case BookingType.Regular: {
      return "bg-green-700";
    }
    case BookingType.Series: {
      return "bg-[#17a2b8]";
    }
    case BookingType.Event: {
      return "bg-[#7C45BC]";
    }
    case BookingType.Open: {
      return "bg-orange-300";
    }
  }

  return "";
};
