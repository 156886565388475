import { DateTime } from "luxon";

import { ApiSingleResponse } from "../../../models/common";
import { Payment } from "../../../modules/checkout/models/Payment";
import {
  Membership,
  MembershipApiResponse,
  MembershipPrice,
  MembershipPriceApiResponse,
  MembershipPurchaseRequest,
  MembershipUser,
  MembershipUserApiResponse,
  PaymentReferenceApiResponse,
} from "../models/Membership";

import { fetchApi } from "../../../services/legacyApiClient";

const apiVersion = "player/v2";

export const getMembership = async (
  membershipId: string,
  signal?: AbortSignal,
): Promise<Membership> => {
  const response = (await fetchApi({
    uri: `${apiVersion}/memberships/${membershipId}`,
    signal,
  })) as ApiSingleResponse<MembershipApiResponse>;

  return formatMembershipFromApi(response.data);
};

export const getMembershipsForFacility = async (
  facilityId: string,
  signal?: AbortSignal,
): Promise<Membership[]> => {
  const response = (await fetchApi({
    uri: `${apiVersion}/memberships/list/${facilityId}`,
    signal,
  })) as ApiSingleResponse<MembershipApiResponse[]>;

  return response.data.map(formatMembershipFromApi);
};

export const payForMembership = async (
  data: MembershipPurchaseRequest,
  signal?: AbortSignal,
): Promise<Payment> => {
  const response = (await fetchApi({
    method: "POST",
    payload: data,
    uri: `${apiVersion}/memberships/pay`,
    signal,
  })) as ApiSingleResponse<Payment>;

  return response.data;
};

export const unsubscribeMembership = async (
  membershipId: string,
  membershipPriceId: string,
  signal?: AbortSignal,
): Promise<MembershipUser> => {
  const response = (await fetchApi({
    method: "PUT",
    uri: `${apiVersion}/memberships/${membershipId}/unsubscribe/${membershipPriceId}`,
    signal,
  })) as ApiSingleResponse<MembershipUserApiResponse>;

  return formatMembershipUserFromApi(response.data);
};

function formatMembershipFromApi(
  membership: MembershipApiResponse,
): Membership {
  return {
    ...membership,
    users: membership.users.map(formatMembershipUserFromApi),
    membershipPrices: membership.membershipPrices.map(
      formatMembershipPriceFromApi,
    ),
  };
}

const formatMembershipUserFromApi = (
  user: MembershipUserApiResponse,
): MembershipUser => {
  return {
    ...user,
    expiryDate: DateTime.fromISO(user.expiryDate, { setZone: true }),
    lastSubscriptionExpirationDate: DateTime.fromISO(
      user.lastSubscriptionExpirationDate,
    ),
    membershipPrice: formatMembershipPriceFromApi(user.membershipPrice),
    nextPaymentDate: DateTime.fromISO(user.nextPaymentDate, { setZone: true }),
    paymentReferences: user.paymentReferences.map(
      formatMembershipUserPaymentReferenceFromApi,
    ),
    subscriptionDate: DateTime.fromISO(user.subscriptionDate, {
      setZone: true,
    }),
  };
};

const formatMembershipUserPaymentReferenceFromApi = (
  paymentReference: PaymentReferenceApiResponse,
) => {
  return {
    ...paymentReference,
    dueDate: DateTime.fromISO(paymentReference.dueDate),
    lastPaymentRetry: DateTime.fromISO(paymentReference.lastPaymentRetry),
  };
};

const formatMembershipPriceFromApi = (
  membershipPrice: MembershipPriceApiResponse,
): MembershipPrice => {
  return {
    ...membershipPrice,
    expiryDate: DateTime.fromISO(membershipPrice.expiryDate, { setZone: true }),
    nextRenewalDate: DateTime.fromISO(membershipPrice.nextRenewalDate, {
      setZone: true,
    }),
  };
};
