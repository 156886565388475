import React, { useState } from "react";

import styled from "styled-components";

import { FacilityWithUtc } from "../../../../../../customer/models/Facility";
import { BookingType } from "../../../../../models/Booking";
import { IScheduleSlot } from "../../../../../models/Calendar";

import { isSlotBookable, isSlotLateToBook } from "../../../utils";
import { SlotSelector } from "./SlotSelector";
import { SlotsSelector } from "./SlotsSelector";

interface Props {
  slot: IScheduleSlot | IScheduleSlot[];
  courtId: string;
  facilityId: FacilityWithUtc["id"];
  row: number;
  column: number;
  bookingType: BookingType;
  nrOfSlots?: number;
  bookingId?: string;
}

interface StyleProps {
  $isStart?: boolean;
  $isBookable?: boolean;
  $havePassed?: boolean;
}

const Slot = styled.div<StyleProps>`
  position: relative;
  height: 100%;
  border-color: var(--gray-200);
  border-style: solid;
  border-width: 0.5px;
  ${props =>
    props.$isStart
      ? "border-right-width: 0;"
      : "border-left-width: 0; border-right-width: 0;"}

  &:last-of-type {
    border-right-width: 0.5px;
    border-radius: 0 var(--border-radius) var(--border-radius) 0;
  }

  &:first-of-type {
    border-radius: var(--border-radius) 0 0 var(--border-radius);
  }

  ${props => !props.$isBookable && "border: none; background: var(--gray-400);"}
  ${props => props.$havePassed && "background: var(--gray-200);"}
`;

const CustomerCalendarSlotMobile: React.FC<Props> = ({
  slot,
  courtId,
  facilityId,
  row,
  column,
  bookingType,
  nrOfSlots,
  bookingId,
}) => {
  const startTime = Array.isArray(slot) ? slot[0]?.startTime : slot.startTime;
  const isBookable = isSlotBookable(slot, bookingId);
  const isLateToBook = isSlotLateToBook(slot);
  const isSlotArray = Array.isArray(slot);

  const [showSelector, setShowSelector] = useState(false);

  return (
    <>
      <Slot
        $isStart={startTime && startTime.minute === 0}
        $isBookable={isBookable}
        $havePassed={isLateToBook}
        data-row={row}
        data-column={column}
        onClick={() => {
          if (!isBookable || isLateToBook) {
            return;
          }

          setShowSelector(true);
        }}
      />

      {showSelector ? (
        isSlotArray ? (
          <SlotSelector
            courtId={courtId}
            facilityId={facilityId}
            onHide={() => setShowSelector(false)}
            slots={slot}
          />
        ) : (
          <SlotsSelector
            courtId={courtId}
            facilityId={facilityId}
            onHide={() => setShowSelector(false)}
            baseSlot={slot}
            bookingType={bookingType}
            nrOfSlots={nrOfSlots}
            bookingId={bookingId}
            setShowSlotsSelector={setShowSelector}
          />
        )
      ) : null}
    </>
  );
};

export default CustomerCalendarSlotMobile;
