import { FormattedMessage } from "react-intl";

import { faCheck, faClose } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { InputSwitch } from "primereact/inputswitch";

import { useCurrentUser } from "../../../hooks/swr/useCurrentUser";

import { ProfileImageWithFallback } from "../../../components/ProfileImageWithFallback";

import { CheckoutViewHeading } from "../CheckoutView";
import { useCheckout } from "../context/CheckoutContext";
import { UserSelect } from "./UserSelect";

export const SplitPayment = () => {
  const { currentUser } = useCurrentUser();
  const { state, dispatch } = useCheckout();
  const { order, isSplitPayment, coPayingUsers, checkoutStatus, orderType } =
    state;

  if (!order.isSplittable) {
    return null;
  }

  if (!currentUser) {
    return null;
  }

  const isCoPayingUsersRemovable = orderType !== "series";
  const isUserSelectVisible = isSplitPayment && orderType !== "series";
  const switchDisabled = checkoutStatus === "paymentPending";

  return (
    <div>
      <div className="flex items-center justify-between">
        <CheckoutViewHeading>
          <FormattedMessage id="checkout.section.split-payment.heading" />
        </CheckoutViewHeading>

        <InputSwitch
          className={clsx(
            "scale-90 lg:scale-100",
            switchDisabled && "opacity-50",
          )}
          disabled={switchDisabled}
          onChange={event =>
            dispatch({
              type: "SET_IS_SPLIT_PAYMENT",
              isSplitPayment: event.value,
              coPayingUsers,
            })
          }
          checked={isSplitPayment}
        />
      </div>

      <p className="mt-4 max-w-prose text-gray-700 lg:text-md">
        <FormattedMessage id="checkout.section.split-payment.preamble" />
      </p>

      {isSplitPayment && (
        <div
          className="mt-6 flex flex-col gap-4 md:grid lg:mt-12"
          style={{
            gridTemplateColumns: `repeat(${
              // +1 because we need another column for the paying users count
              order.maxPayingUsers + 1
            }, minmax(0, 1fr))`,
          }}
        >
          {[currentUser, ...coPayingUsers].map(user => (
            <div
              key={user.id}
              className="flex items-center gap-4 text-center md:flex-col md:gap-0"
            >
              <div className="relative">
                <ProfileImageWithFallback
                  hasImage={!!user.profileImage}
                  src={user.profileImage}
                  firstName={user.firstName}
                  lastName={user.lastName}
                  className="h-12 w-12 rounded-full border-none md:h-16 md:w-16"
                  fontSize="2rem"
                />

                {user.id !== currentUser.id && (
                  <button
                    type="button"
                    className={clsx(
                      "group absolute inset-0 flex items-center justify-center rounded-full bg-primary bg-opacity-60 text-purewhite transition-colors",
                      isCoPayingUsersRemovable &&
                        checkoutStatus !== "paymentPending" &&
                        "hover:bg-opacity-90",
                    )}
                    disabled={
                      checkoutStatus === "paymentPending" ||
                      !isCoPayingUsersRemovable
                    }
                    onClick={() =>
                      dispatch({
                        type: "SET_CO_PAYING_USERS",
                        coPayingUsers: coPayingUsers.filter(
                          u => u.id !== user.id,
                        ),
                      })
                    }
                  >
                    <FontAwesomeIcon icon={faCheck} />

                    {isCoPayingUsersRemovable && (
                      <div className="absolute -right-1 -top-1 flex h-5 w-5 items-center justify-center rounded-full bg-red-500 transition-colors group-hover:bg-red-600 md:h-6 md:w-6">
                        <FontAwesomeIcon icon={faClose} size="2xs" />
                      </div>
                    )}
                  </button>
                )}
              </div>
              <p className="mt-1 flex max-w-full lg:text-md">
                <span className="truncate font-semibold">
                  {user.firstName} {user.lastName?.charAt(0)}
                </span>
                {user.id === currentUser.id && (
                  <span className="font-normal">
                    &nbsp;
                    <FormattedMessage id="checkout.section.split-payment.you" />
                  </span>
                )}
              </p>
            </div>
          ))}

          {Array.from({
            // -1 because we already have the current user
            length: order.maxCoPayingUsers - coPayingUsers.length,
          }).map((_, index) => (
            <div key={index} className="flex flex-col md:items-center">
              <div className="h-12 w-12 rounded-full border border-dashed border-gray-500 bg-white px-2 first:pl-0 md:h-16 md:w-16"></div>
            </div>
          ))}

          <div className="flex flex-col">
            <div className="flex flex-1 items-center text-center font-bold text-gray-700 lg:text-md">
              <FormattedMessage
                id="checkout.section.split-payment.x-of-x-players"
                values={{
                  // +1 because we already have the current user
                  playerCount: coPayingUsers.length + 1,
                  maxPlayerCount: order.maxPayingUsers,
                }}
              />
            </div>
            <p className="mt-1 hidden select-none md:block">&nbsp;</p>
          </div>
        </div>
      )}

      {isUserSelectVisible && (
        <div className="mt-4 md:mt-8">
          <UserSelect />
        </div>
      )}
    </div>
  );
};
