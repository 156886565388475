import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { toast } from "react-toastify";

import {
  faCheck,
  faClock,
  faFlag,
  faWarehouse,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ProgressSpinner } from "primereact/progressspinner";
import styled from "styled-components";

import InvitationBackground from "../../../../assets/images/backgrounds/background5.jpg";

import { IBooking } from "../../models/Booking";

import { useCurrentUser } from "../../../../hooks/swr/useCurrentUser";
import { useUser } from "../../../../hooks/swr/useUser";
import { useDateFormat } from "../../../../hooks/useDateFormat";

import {
  addMember,
  addtoMemberList,
} from "../../../player/services/GroupService";

import { Button } from "../../../../components/Button";
import { Card } from "../../../../components/Card";
import { ProfileImageWithFallback } from "../../../../components/ProfileImageWithFallback";

import {
  luxonDateTimeFormat,
  luxonTimeFormat,
} from "../../../../utils/dateFormats";

const CardWrapper = styled(Card)`
  padding: 0;
  display: grid;
  grid-template-columns: 1fr;

  @media (min-width: 960px) {
    grid-template-columns: minmax(24rem, 1fr) 1fr;
  }
`;

const ItemWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
`;

const StyledSuccessText = styled.div`
  padding: 1rem;

  svg {
    padding-left: 0.5rem;
  }
`;

interface IProps {
  booking: IBooking;
  isLoggedIn: boolean;
  onSubmit: () => void;
}

const BookingInvitation: React.FC<IProps> = ({
  booking,
  isLoggedIn,
  onSubmit,
}) => {
  const { currentUser } = useCurrentUser();
  const [accepted, setAccepted] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const { user } = useUser(booking?.organizerId);

  const { df } = useDateFormat(booking?.facility.id);

  const handleAcceptClick = async () => {
    try {
      setLoading(true);

      await addMember(booking.groupId, currentUser.id);
      await addtoMemberList(booking.facility.id, currentUser.id);

      setAccepted(true);
      onSubmit();
    } catch (err) {
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!currentUser || !booking) {
      return;
    }

    if (booking.participants.some(player => player.id === currentUser.id)) {
      setAccepted(true);
    }
  }, [booking, currentUser]);

  return (
    <CardWrapper>
      {!isLoggedIn && (
        <h3 className="text-center text-lg font-medium">
          <FormattedMessage id="friends-invitation.not-logged-in-message" />
        </h3>
      )}

      {isLoggedIn && !booking && (
        <div className="booking-invitation__content__spinner">
          <ProgressSpinner
            style={{ width: "5rem", height: "5rem" }}
            animationDuration="1s"
          />
        </div>
      )}

      {isLoggedIn && booking && (
        <div className="flex flex-col items-center gap-8 p-4">
          <ItemWrapper className="mt-8">
            {booking?.participants?.map(player => (
              <div
                key={player.id}
                className="flex h-28 w-28 items-center justify-center overflow-hidden rounded-full"
              >
                <ProfileImageWithFallback
                  firstName={player?.firstName}
                  lastName={player?.lastName}
                  src={player?.profileImage}
                />
              </div>
            ))}
          </ItemWrapper>

          <h3 className="text-center text-lg font-medium">
            <strong>{user?.displayName} </strong>
            <FormattedMessage
              id="booking-invitation.greetings-message"
              values={{ br: <br /> }}
            />
          </h3>

          <div className="grid gap-2">
            <ItemWrapper>
              <FontAwesomeIcon icon={faWarehouse} />
              <span>{booking?.facility?.name}</span>
            </ItemWrapper>
            <ItemWrapper>
              <FontAwesomeIcon icon={faClock} />
              <span>
                {df(booking?.startTime, luxonDateTimeFormat)} -{" "}
                {df(booking?.endTime, luxonTimeFormat)}
              </span>
            </ItemWrapper>
            <ItemWrapper>
              <FontAwesomeIcon icon={faFlag} />
              <span>{booking?.court?.name}</span>
            </ItemWrapper>
          </div>

          {accepted ? (
            <StyledSuccessText>
              <FormattedMessage id="booking-invitation.accepted-message" />
              <FontAwesomeIcon icon={faCheck} color="green" />
            </StyledSuccessText>
          ) : (
            <Button
              translationName="common.play"
              buttonType="button"
              type="primary"
              disabled={loading}
              onClick={handleAcceptClick}
            />
          )}
        </div>
      )}

      <div style={{ overflow: "hidden" }}>
        <img
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
          src={InvitationBackground}
        />
      </div>
    </CardWrapper>
  );
};

export default BookingInvitation;
