import { useIntl } from "react-intl";

import { object, string } from "yup";

import { commonRegex } from "../../../helpers/commonRegex";

import { useCountryCodes } from "../../../hooks/swr/useCountryCodes";

export const useFormValidationSchema = () => {
  const intl = useIntl();
  const { countriesData } = useCountryCodes();

  const getTranslation = (id, defaultMessage = "") =>
    intl.formatMessage({ id, defaultMessage });

  const editProfileSchema = object().shape({
    firstName: string()
      .required(
        getTranslation(
          "validation.required.firstname",
          "Förnamn är obligatoriskt",
        ),
      )
      .matches(
        commonRegex.name,
        getTranslation(
          "validation.invalid.name",
          "Ogiltigt namn. Får enbart innehålla bokstäver.",
        ),
      ),
    lastName: string()
      .required(
        getTranslation(
          "validation.required.lastname",
          "Efternamn är obligatoriskt",
        ),
      )
      .matches(
        commonRegex.name,
        getTranslation(
          "validation.invalid.name",
          "Ogiltigt namn. Får enbart innehålla bokstäver.",
        ),
      ),
    phoneNumber: string().matches(
      commonRegex.phoneNumber,
      getTranslation(
        "validation.invalid.phone",
        "Ogiltigt telefonnummer. Får enbart innehålla siffror.",
      ),
    ),
    country: string()
      .required(getTranslation("validation.required.country"))
      .oneOf(countriesData?.map(country => country.countryCode) || []),
    socialSecurityNumber: string()
      .notRequired()
      .when("country", {
        is: "SE",
        then: schema =>
          schema.matches(
            commonRegex.personalIdentificationNumber.SE,
            getTranslation("validation.national-identification-number.invalid"),
          ),
      })
      .when("country", {
        is: "FI",
        then: schema =>
          schema.matches(
            commonRegex.personalIdentificationNumber.FI,
            getTranslation("validation.national-identification-number.invalid"),
          ),
      }),
    zipCode: string().matches(
      commonRegex.postalCode,
      getTranslation("validation.invalid.postalCode"),
    ),
    city: string().matches(
      /^[a-öA-Ö]+$/,
      getTranslation("validation.invalid.city"),
    ),
  });

  return { editProfileSchema };
};
