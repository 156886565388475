import useSWR, { SWRConfiguration } from "swr";

import { FacilityWithUtc } from "../../modules/customer/models/Facility";
import type { GroupTypeStrings } from "../../modules/player/models/Group";

import { getGroupsByFacilityId } from "../../modules/player/services/GroupService";

export const useFacilityGroups = (
  facilityId: FacilityWithUtc["id"] | null | undefined,
  groupType: GroupTypeStrings,
  options?: SWRConfiguration,
) => {
  const { data, ...rest } = useSWR(
    facilityId ? ["facilityGroups", facilityId, groupType] : null,
    ([, facilityId, groupType]) => getGroupsByFacilityId(facilityId, groupType),
    options,
  );

  return {
    ...rest,
    groups: data,
  };
};
