import {
  faCcAmex,
  faCcMastercard,
  faCcVisa,
} from "@fortawesome/free-brands-svg-icons";
import { faCreditCardFront } from "@fortawesome/pro-regular-svg-icons";

import { CardBrand } from "../models/backupPaymentMethod";

export const creditCardIconHelper = (cardBrand: keyof typeof CardBrand) => {
  switch (cardBrand) {
    case CardBrand.Visa: {
      return faCcVisa;
    }
    case CardBrand.Maestro:
    case CardBrand.MasterCard: {
      return faCcMastercard;
    }
    case CardBrand.AmericanExpress: {
      return faCcAmex;
    }
    default: {
      return faCreditCardFront;
    }
  }
};
