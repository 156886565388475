import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";

import { useFormik } from "formik";
import { ProgressBar } from "primereact/progressbar";
import { ProgressSpinner } from "primereact/progressspinner";

import { ICreateSerieFormValues } from "../../../models/Series";

import { useForceDateTimeToSelectedFacilityTimeZone } from "../../../../../hooks/datetime/useForceDateTimeToSelectedFacilityTimeZone";
import { useFormValidationSchema } from "../../../hooks/useFormValidationSchema";

import { createSerieByWizard } from "../../../services/Serie";

import { Button } from "../../../../../components/Button";

import AdminCreateSerieFormDivisionsStep from "./createSteps/AdminCreateSerieFormDivisionsStep";
import AdminCreateSerieFormMainStep from "./createSteps/AdminCreateSerieFormMainStep";
import AdminCreateSerieFormSettingsStep from "./createSteps/AdminCreateSerieFormSettingsStep";

interface Props {
  facilityId: string;
  onSubmit: () => void;
}

const AdminCreateSerieForm: React.FC<Props> = ({ facilityId, onSubmit }) => {
  const [step, setStep] = useState<number>(1);
  const { createSeriesSchema } = useFormValidationSchema();
  const createSerieAbortController = useRef<AbortController>();
  const { forceDateTimeToSelectedFacilityTimeZone } =
    useForceDateTimeToSelectedFacilityTimeZone();

  const formik = useFormik<ICreateSerieFormValues>({
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema: createSeriesSchema,
    initialValues: {
      name: "",
      facilityId: facilityId,
      openForRegistration: false,
      registrationOpenTo: undefined,
      startTime: undefined,
      endTime: undefined,
      gender: undefined,
      numberOfDivisions: 1,
      numberOfTeamsInDivision: 7,
      numberOfTeamsMovingDown: 2,
      numberOfTeamsMovingUp: 2,
      typeOfSerie: "straight",
      hasPrice: true,
      price: 700,
      membersOnly: false,
      rescheduleRules: {
        limitToSelectedCourts: false,
        limitToSelectedTime: false,
        limitToLesserPrioPriceCategories: false,
        limitToSelectedWeekDays: false,
        maxAllowedBookingReschedules: 10,
      },
    },
    onSubmit: async data => {
      try {
        createSerieAbortController.current = new AbortController();

        //go to step "creating serie with loader"
        setStep(4);

        const body = {
          ...data,
          price: data.hasPrice && data?.price ? data.price : 0,
          gender: parseInt(data?.gender.toString()),
          startTime: forceDateTimeToSelectedFacilityTimeZone(data.startTime),
          endTime: forceDateTimeToSelectedFacilityTimeZone(data.endTime),
          registrationOpenTo: forceDateTimeToSelectedFacilityTimeZone(
            data.registrationOpenTo,
          ),
        };

        await createSerieByWizard(
          body,
          createSerieAbortController.current?.signal,
        );

        onSubmit();
      } catch (err) {
        if (!createSerieAbortController.current?.signal.aborted)
          console.log(err.data);
      }
    },
  });

  useEffect(() => () => createSerieAbortController.current?.abort(), []);

  const nextStep = (step: number) => {
    setStep(step + 1);
  };

  const prevStep = (step: number) => {
    setStep(step - 1);
  };

  const MAX_STEPS = 3;

  return (
    <div>
      {step <= MAX_STEPS && (
        <ProgressBar
          value={Math.round((step / MAX_STEPS) * 100)}
          className="p-mt-4 p-mb-5"
        />
      )}

      <form onSubmit={formik.handleSubmit} className="p-fluid">
        {step === 1 && (
          <AdminCreateSerieFormMainStep
            errors={formik.errors}
            values={formik.values}
            touched={formik.touched}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            setFieldValue={formik.setFieldValue}
          />
        )}
        {step === 2 && (
          <AdminCreateSerieFormDivisionsStep
            errors={formik.errors}
            values={formik.values}
            setFieldValue={formik.setFieldValue}
          />
        )}
        {step === 3 && (
          <AdminCreateSerieFormSettingsStep
            values={formik.values}
            setFieldValue={formik.setFieldValue}
            errors={formik.errors}
          />
        )}

        {step === 4 && (
          <div
            style={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            <div>
              <FormattedMessage
                id="series.creating-series"
                defaultMessage="Skapar din serie"
              />
            </div>
            <ProgressSpinner style={{ width: "5rem", height: "5rem" }} />
          </div>
        )}

        {step !== 4 && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "3rem",
            }}
          >
            <div>
              {step !== 1 && (
                <Button
                  onClick={() => prevStep(step)}
                  text="Tillbaka"
                  translationName="common.back"
                  buttonType="button"
                  type="default"
                  disabled={formik.isSubmitting}
                />
              )}
            </div>

            <div>
              {/* TODO: validate before going to next... */}

              {step !== 3 && (
                <Button
                  onClick={() => nextStep(step)}
                  text="Nästa"
                  translationName="button.next"
                  buttonType="button"
                  type="primary"
                  disabled={!formik.isValid}
                />
              )}

              {step === 3 && (
                <Button
                  text="Save"
                  translationName="button.save"
                  buttonType="submit"
                  type="primary"
                  disabled={formik.isSubmitting || !formik.isValid}
                />
              )}
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

export default AdminCreateSerieForm;
