import React from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";

import { getMyProfilePath } from "../../../../helpers/pathHelpers";

import { Button } from "../../../../components/Button";

interface Props {
  facilityName: string;
}

export const RegistrationClosed: React.FC<Props> = ({ facilityName }) => {
  const history = useHistory();

  return (
    <div className="flex flex-col bg-purewhite px-10 py-40 text-center md:px-20 xl:px-40 xl:py-40">
      <h2 className="mb-5 text-5xl">
        <FormattedMessage
          id="series.season.already.begun"
          defaultMessage="The season has already begun"
        />
      </h2>
      <p className="text-lg">
        <FormattedMessage
          id="series.season.already.begun.description"
          defaultMessage="It seems as if the season you are trying to reach has already started. If you participated in the last season, and have not yet left notice that you don't want to participate in the coming season, you are automatically participating in the coming season and obliged to pay. You do this easiest by going to you upcoming activities under My Profile. Please contact {facilityName} in case of any questions or uncertainties."
          values={{
            facilityName,
          }}
        />
      </p>
      <Button
        className="mx-auto my-10 w-max bg-gradient-to-r from-blue-500 to-blue-700 py-4 pl-5 pr-4 text-sm text-purewhite shadow-m"
        type="default"
        translationName="activities.upcoming-activities"
        onClick={() => history.push(`${getMyProfilePath()}/8`)}
      />
    </div>
  );
};
