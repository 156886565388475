import React from "react";
// Device detector
import { isMobile } from "react-device-detect";
// React drag and drop
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import { createRoot } from "react-dom/client";
// Redux Store
import { Provider as StoreProvider } from "react-redux";

// Microsoft auth library
import {
  AuthenticationResult,
  EventMessage,
  EventType,
  PublicClientApplication,
} from "@azure/msal-browser";
import {
  faMars,
  faMoneyBill,
  faPeopleRoof,
  faTennisBall,
  faTrophy,
  faUser,
  faUsers,
  faVenus,
  faVenusMars,
  faWandSparkles,
} from "@fortawesome/pro-light-svg-icons";
// Flag icons
import "flag-icons/css/flag-icons.min.css";
import "primeicons/primeicons.css";
import "primereact/resources/primereact.min.css";
// PrimeReact styles
import "primereact/resources/themes/saga-blue/theme.css";

// Custom styles
import "./assets/scss/app.scss";

import App from "./App";
// Custom components and files
import { msalConfig } from "./authConfig";
import store from "./store/store";

// Font awesome
import("@fortawesome/fontawesome-svg-core").then(lib => {
  lib.library.add(
    // These icons needs to be added here to be available on the landing page
    faUsers,
    faTrophy,
    faMoneyBill,
    faTennisBall,
    faPeopleRoof,
    faWandSparkles,
    faUser,

    // These icons are used in src/pages/Admin/activities/components/form/GenderPicker/components/GenderOption/index.tsx
    faVenus,
    faMars,
    faVenusMars,
  );
});

export const msalInstance = new PublicClientApplication(msalConfig);

const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  const newAccount = accounts.find(
    a => a.environment == process.env.REACT_APP_B2C_AUTHDOMAIN,
  );

  if (newAccount) {
    msalInstance.setActiveAccount(newAccount);
  }
}

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
    msalInstance.logoutRedirect({
      postLogoutRedirectUri: "/#/signin",
    });
  }

  if (
    (event.eventType === EventType.LOGIN_SUCCESS ||
      event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
      event.eventType === EventType.SSO_SILENT_SUCCESS) &&
    event.payload
  ) {
    const payload = event.payload as AuthenticationResult;
    msalInstance.setActiveAccount(payload.account);
  }
});

const container = document.getElementById("root");
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <StoreProvider store={store}>
      <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
        <App pca={msalInstance} />
      </DndProvider>
    </StoreProvider>
  </React.StrictMode>,
);
