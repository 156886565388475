import { useCallback } from "react";

import { DateTime } from "luxon";

import { forceDateTimeToTimeZone } from "../../helpers/dateTime";

import { useFacilityLocalization } from "../store/useFacilityLocalization";

export const useForceDateTimeToSelectedFacilityTimeZone = () => {
  const localization = useFacilityLocalization();

  const forceDateTimeToSelectedFacilityTimeZone = useCallback(
    (dateTime: DateTime) => {
      if (!localization?.timeZone) {
        console.error("no localization.timeZone available");
        return dateTime;
      }

      return forceDateTimeToTimeZone(dateTime, localization.timeZone);
    },
    [localization],
  );

  return { forceDateTimeToSelectedFacilityTimeZone };
};
