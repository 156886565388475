import { FormattedMessage } from "react-intl";

import { BuyableType } from "../../../../modules/checkout/models/Payment";

const buyableTypeColors = {
  [BuyableType.Series]: "#17a2b8",
  [BuyableType.Event]: "#94dfeb",
};

const buyableTypeMessageIds = {
  [BuyableType.Series]: "common.serie",
  [BuyableType.Event]: "common.event",
};

interface Props {
  buyableType: BuyableType;
}

export const BuyableTypeLabel: React.FC<Props> = ({ buyableType }) => {
  return (
    <div
      className="flex items-center space-x-2 rounded-t-md p-1 pl-2 text-sm text-black"
      style={{ background: buyableTypeColors[buyableType] }}
    >
      <FormattedMessage id={buyableTypeMessageIds[buyableType]} />
    </div>
  );
};
