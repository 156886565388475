import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import {
  adminGetBookingPath,
  adminGetHomePath,
  adminGetMobileActivitiesPath,
  adminGetMobileBookingEditPath,
  adminGetMobileBookingFormPath,
  adminGetMobileBookingPath,
  adminGetMobileBookingReschedulePath,
  adminGetMobileBookingsPath,
  adminGetMobilePath,
  adminGetPlayersPath,
} from "./helpers/pathHelpers";

import { useIsMobile } from "./hooks/common/useIsMobile";

import PrivateRoute from "./PrivateRoute";
import { AdminBookingPage } from "./pages/Admin/AdminBookingPage";
import { AdminFacilitySettingsPage } from "./pages/Admin/AdminFacilitySettingsPage";
import { AdminMobileActivitiesPage } from "./pages/Admin/AdminMobileActivitiesPage";
import { AdminMobileBookingFormPage } from "./pages/Admin/AdminMobileBookingFormPage";
import { AdminMobileBookingPage } from "./pages/Admin/AdminMobileBookingPage";
import { AdminMobileBookingsPage } from "./pages/Admin/AdminMobileBookingsPage";
import { AdminMobileDashboardPage } from "./pages/Admin/AdminMobileDashboardPage";
import { AdminMobileEditBookingPage } from "./pages/Admin/AdminMobileEditBookingPage";
import { AdminMobilePage } from "./pages/Admin/AdminMobilePage";
import { AdminMobileReschedulePage } from "./pages/Admin/AdminMobileReschedulePage";
import { AdminPlayerPage } from "./pages/Admin/AdminPlayerPage";
import { AdminPlayersOverviewPage } from "./pages/Admin/AdminPlayersOverviewPage";
import { AdminActivitiesListPage } from "./pages/Admin/activities/AdminActivitiesListPage";
import { GeneralActivityPage } from "./pages/Admin/activities/general/GeneralActivityPage";
import { AdminMatchOverviewPage } from "./pages/Admin/activities/series/AdminMatchOverviewPage";
import { AdminMatchPage } from "./pages/Admin/activities/series/AdminMatchPage";
import { AdminSeriesDetailsPage } from "./pages/Admin/activities/series/AdminSeriesDetailsPage";
import { AdminSeriesScoreboardPage } from "./pages/Admin/activities/series/AdminSeriesScoreboardPage";
import { AdminSeriesTeamsPage } from "./pages/Admin/activities/series/AdminSeriesTeamsPage";
import { BookingInvitationPage } from "./pages/BookingInvitationPage";
import { BookingPage } from "./pages/BookingPage";
import { CheckoutPage } from "./pages/CheckoutPage/CheckoutPage";
import { CreateAccountPage } from "./pages/CreateAccount";
import { EventPage } from "./pages/EventPage";
import FriendInvitationPage from "./pages/FriendInvitationPage";
import { PolicyPage } from "./pages/PolicyPage";
import { ReschedulePage } from "./pages/ReschedulePage";
import { NewSeasonPage } from "./pages/Series/NewSeasonPage";
import { SeriePage } from "./pages/Series/SeriePage";
import { SignInRedirectPage } from "./pages/SignInRedirect";
import { SignUpUnverifiedPage } from "./pages/SignUpUnverified";
import { SuperAdmin } from "./pages/SuperAdmin/SuperAdmin";
import { UnsubscribePage } from "./pages/Unsubscribe";
import { UserOverview } from "./pages/UserOverview";
import { UserProfilePage } from "./pages/UserProfilePage";
import { VenuePage } from "./pages/VenuePage";
import { VenuesOverview } from "./pages/VenuesOverview";
import { useIsAdminMode } from "./recoil/isAdminModeState";

interface Props {
  isAuthenticated: boolean;
}

export const Routes: React.FC<Props> = ({ isAuthenticated }) => {
  const isAdminMode = useIsAdminMode();
  const isMobile = useIsMobile();
  const adminHome = isMobile ? adminGetMobilePath() : adminGetHomePath();
  return (
    <Switch>
      <Route path="/" exact>
        {isAuthenticated ? (
          isAdminMode ? (
            <Redirect to={adminHome} push={false} />
          ) : (
            <UserOverview />
          )
        ) : (
          <Route component={SignInRedirectPage} />
        )}
      </Route>

      <PrivateRoute path="/booking" exact component={BookingPage} />

      <PrivateRoute
        path="/reschedule/:facilityId/:bookingId"
        exact
        component={ReschedulePage}
      />

      <PrivateRoute
        path="/me/:userProfileTabId?"
        exact
        component={UserProfilePage}
      />

      <PrivateRoute
        path="/profile/:userId?/:userProfileTabId?"
        exact
        component={UserProfilePage}
      />

      <Route path="/privacyPolicy" exact component={PolicyPage} />

      <Route path="/userAgreement" exact component={PolicyPage} />

      <Route path="/termsAndConditions" exact component={PolicyPage} />

      <Route path="/signin" exact component={SignInRedirectPage} />
      <Route path="/signup" exact component={SignUpUnverifiedPage} />
      <Route path="/create-account" exact component={CreateAccountPage} />

      <Route path="/unsubscribe/:link" exact component={UnsubscribePage} />

      <PrivateRoute
        path="/admin/settings/:tab?"
        exact
        admin
        component={AdminFacilitySettingsPage}
      />

      <PrivateRoute path="/venues/" exact component={VenuesOverview} />
      <PrivateRoute path="/venues/:id" exact component={VenuePage} />

      <Route path="/series/:id/new-season" exact component={NewSeasonPage} />

      <PrivateRoute
        path="/series/:id/:tab?/:divisionId?"
        exact
        component={SeriePage}
      />

      <Route
        path="/booking/invitation/:bookingIdHash"
        exact
        component={BookingInvitationPage}
      />

      <PrivateRoute
        path={adminGetHomePath()}
        exact
        admin
        component={AdminBookingPage}
      />

      <PrivateRoute
        path={adminGetMobilePath()}
        exact
        admin
        component={AdminMobilePage}
      />

      <PrivateRoute
        path={adminGetMobileBookingPath()}
        exact
        admin
        component={AdminMobileBookingPage}
      />

      <PrivateRoute
        path={adminGetMobileBookingFormPath()}
        exact
        admin
        component={AdminMobileBookingFormPage}
      />

      <PrivateRoute
        path={adminGetMobileBookingsPath()}
        exact
        admin
        component={AdminMobileBookingsPage}
      />

      <PrivateRoute
        path={adminGetMobileBookingEditPath()}
        exact
        admin
        component={AdminMobileEditBookingPage}
      />

      <PrivateRoute
        path={adminGetMobileActivitiesPath()}
        exact
        admin
        component={AdminMobileActivitiesPage}
      />

      <PrivateRoute
        path={adminGetMobileBookingReschedulePath(":facilityId", ":bookingId")}
        exact
        admin
        component={AdminMobileReschedulePage}
      />

      <PrivateRoute
        path="/admin/mobile/dashboard"
        exact
        admin
        component={AdminMobileDashboardPage}
      />

      <PrivateRoute
        path={adminGetBookingPath()}
        exact
        admin
        component={AdminBookingPage}
      />

      <PrivateRoute
        path="/admin/activities"
        exact
        admin
        component={AdminActivitiesListPage}
      />

      <PrivateRoute
        path="/admin/activities/serie/:serieId"
        exact
        admin
        component={AdminSeriesDetailsPage}
      />

      <PrivateRoute
        path="/admin/activities/serie/:serieId/matches"
        exact
        admin
        component={AdminMatchOverviewPage}
      />

      <PrivateRoute
        path="/admin/activities/serie/:serieId/match/:matchId"
        exact
        admin
        component={AdminMatchPage}
      />

      <PrivateRoute
        path="/admin/activities/serie/:serieId/teams"
        exact
        admin
        component={AdminSeriesTeamsPage}
      />

      <PrivateRoute
        path="/admin/activities/serie/:serieId/scoreboard"
        exact
        admin
        component={AdminSeriesScoreboardPage}
      />

      <PrivateRoute
        path="/admin/activities/event/:activityId"
        exact
        admin
        component={GeneralActivityPage}
      />

      <PrivateRoute path="/superadmin" exact admin component={SuperAdmin} />

      <PrivateRoute
        path={adminGetPlayersPath()}
        exact
        admin
        component={AdminPlayersOverviewPage}
      />

      <PrivateRoute
        path={`${adminGetPlayersPath()}/:userId`}
        exact
        admin
        component={AdminPlayerPage}
      />

      <Route
        path="/friends/invitation/:friendInvitationId?"
        exact
        component={FriendInvitationPage}
      />

      <Route path="/event/:eventId" exact component={EventPage} />

      <PrivateRoute path="/checkout/:orderId" exact component={CheckoutPage} />

      <Route component={SignInRedirectPage} />
    </Switch>
  );
};
