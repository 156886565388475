import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { faLineChart, faRocket } from "@fortawesome/pro-light-svg-icons";
import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";

import { UserRole } from "../../../../models/Permissions";
import { User } from "../../../../modules/player/models/User";

import { useToaster } from "../../../../hooks/common/useToaster";
import { useSelectedFacilityRoles } from "../../../../hooks/swr/useSelectedFacilityRoles";

import {
  addFacilityUserPermission,
  removeFacilityUserPermission,
} from "../../../../services/permissionService";

import { Button } from "../../../../components/Button";
import { Dialog } from "../../../../components/Dialog";
import { UserSearch } from "../../../../components/UserSearch";

import { useCurrentUserId } from "../../../../recoil/currentUserIdState";
import { useSelectedFacilityId } from "../../../../recoil/selectedFacilityIdState";

const roleIcons = {
  FacilityAdmin: faLineChart,
  Owner: faRocket,
};

export const FacilityRoleSettings: React.FC = () => {
  const intl = useIntl();
  const currentUserId = useCurrentUserId();
  const selectedFacilityId = useSelectedFacilityId();
  const { facilityRoles, mutate } = useSelectedFacilityRoles();
  const { toastError } = useToaster();
  const [selectedUser, setSelectedUser] = useState<User>(null);
  const [dialogOptions, setDialogOptions] = useState({
    action: "",
    open: false,
    role: "",
  });

  const resetStateValues = () => {
    setDialogOptions({ action: "", open: false, role: "" });
    setSelectedUser(null);
  };

  const assignUserRole = async (userId: User["id"], role: UserRole) => {
    const payload = {
      facilityId: selectedFacilityId,
      userId,
      role,
    };

    try {
      await addFacilityUserPermission(payload);
      mutate();
    } catch (e) {
      toastError.assignUserRoleFailed();
    } finally {
      resetStateValues();
    }
  };

  const removeUserRole = async (userId: User["id"], role: UserRole) => {
    try {
      await removeFacilityUserPermission(selectedFacilityId, userId, role);
      mutate();
    } catch (e) {
      toastError.removeUserRoleFailed();
    } finally {
      resetStateValues();
    }
  };

  const columnClasses = clsx("w-72 whitespace-nowrap");

  const headerClasses = "bg-purewhite border-0";

  return (
    <>
      <div className="h-100 flex flex-col gap-y-10 xl:flex-row xl:flex-wrap">
        {facilityRoles &&
          facilityRoles.map(r => {
            return (
              <div key={r.name} className="flex flex-col px-0 xl:w-1/2 xl:px-5">
                <div className="mb-4 flex items-center text-blue-500">
                  <FontAwesomeIcon icon={roleIcons[r.name]} />
                  <h2 className="pl-2 text-xl font-bold">
                    {intl.formatMessage({
                      id: `admin.roles.${r.name}`,
                    })}
                  </h2>
                </div>

                <DataTable
                  className="flex-1 font-semibold [&_td]:border-0 odd:[&_tr]:bg-white"
                  dataKey="id"
                  value={r.users}
                  responsiveLayout="scroll"
                >
                  <Column
                    className={columnClasses}
                    body={user => user?.displayName}
                    header={intl.formatMessage({
                      id: "common.name",
                    })}
                    headerClassName={headerClasses}
                  />

                  <Column
                    className={columnClasses}
                    body={user => user?.emailAddress}
                    header={intl.formatMessage({
                      id: "common.email",
                    })}
                    headerClassName={headerClasses}
                  />

                  <Column
                    className={columnClasses}
                    body={user =>
                      user.id !== currentUserId ? (
                        <FontAwesomeIcon
                          icon={faXmark}
                          className="cursor-pointer text-red-500"
                          onClick={() => {
                            setDialogOptions({
                              action: "remove",
                              open: true,
                              role: r.name,
                            });
                            setSelectedUser(user);
                          }}
                        />
                      ) : null
                    }
                    header={intl.formatMessage({
                      id: "common.delete",
                    })}
                    headerClassName={headerClasses}
                  />
                </DataTable>

                <Button
                  type="add"
                  onClick={() => {
                    setDialogOptions({
                      action: "assign",
                      open: true,
                      role: r.name,
                    });
                  }}
                  className="mt-10 w-full text-blue-500"
                >
                  <FormattedMessage id="admin.roles.assign" />
                </Button>
              </div>
            );
          })}
      </div>

      {/* ASSIGN-DIALOG */}
      {dialogOptions.open && dialogOptions.action === "assign" && (
        <Dialog onHide={() => resetStateValues()} visible={true}>
          <h2 className="mb-10 text-lg">
            {`${intl.formatMessage({
              id: "admin.roles.assign",
            })}: ${intl.formatMessage({
              id: `admin.roles.${dialogOptions.role}`,
            })}`}
          </h2>

          <UserSearch
            facilityId={selectedFacilityId}
            searchActiveUsers
            multiSelect={false}
            onChange={users => setSelectedUser(users[0] ?? null)}
            translationId="common.search-user"
          />

          <div className="mt-10 flex justify-center gap-5">
            <Button
              type="small"
              onClick={() => resetStateValues()}
              className="text-blue-500"
            >
              <FormattedMessage id="common.cancel" />
            </Button>

            <Button
              disabled={!selectedUser}
              type="small"
              className="enabled:bg-blue-500 enabled:text-white"
              onClick={() =>
                assignUserRole(selectedUser?.id, UserRole[dialogOptions.role])
              }
            >
              <FormattedMessage id="common.save-and-close" />
            </Button>
          </div>
        </Dialog>
      )}

      {/* REMOVE-DIALOG */}
      {dialogOptions.open && dialogOptions.action === "remove" && (
        <Dialog onHide={() => resetStateValues()} visible={true}>
          <h2 className="mb-10 text-lg">
            {intl.formatMessage({
              id: "admin.roles.remove",
            })}
          </h2>

          <p>
            <FormattedMessage
              id="admin.roles.remove.description"
              values={{
                userName: selectedUser?.displayName,
                roleName: intl.formatMessage({
                  id: `admin.roles.${dialogOptions.role}`,
                }),
              }}
            />
          </p>

          <div className="mt-10 flex justify-center gap-5">
            <Button
              type="small"
              onClick={() => resetStateValues()}
              className="text-blue-500"
            >
              <FormattedMessage id="common.cancel" />
            </Button>

            <Button
              type="small"
              className="bg-blue-500 text-white"
              onClick={() =>
                removeUserRole(selectedUser?.id, UserRole[dialogOptions.role])
              }
            >
              <FormattedMessage id="common.save-and-close" />
            </Button>
          </div>
        </Dialog>
      )}
    </>
  );
};
