import type { DateTime } from "luxon";

export interface MaintenanceMessage {
  message: string | null;
  severity: MaintenanceMessageSeverity;
  deviceTypes: MaintenanceMessageDeviceType[];
  created: DateTime;
}

export interface MaintenanceMessageDTO
  extends Omit<MaintenanceMessage, "created"> {
  created: string;
}

export type MaintenanceMessageRequest = Omit<MaintenanceMessage, "created">;

export const MaintenanceMessageSeverity = {
  Critical: "Critical",
  High: "High",
  Low: "Low",
} as const;

export type MaintenanceMessageSeverity =
  (typeof MaintenanceMessageSeverity)[keyof typeof MaintenanceMessageSeverity];

export const MaintenanceMessageDeviceType = {
  Web: "Web",
  App: "App",
} as const;

export type MaintenanceMessageDeviceType =
  (typeof MaintenanceMessageDeviceType)[keyof typeof MaintenanceMessageDeviceType];
