import React from "react";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router-dom";

import { faExclamationCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

import { useSeries } from "../../../../../hooks/swr/useSeries";

import { Card as defaultCard } from "../../../../../components/Card";
import { ProgressSpinner } from "../../../../../components/ProgressSpinner";
import { DivisionsEditor } from "./components/DivisionsEditor";

const Card = styled(defaultCard)`
  margin-top: 2rem;
  width: 100%;
  max-width: 24rem;
`;

interface AdminSeriesDetailsPageParams {
  serieId: string;
}

export const AdminSeriesDetailsPage: React.FC = () => {
  const { serieId } = useParams<AdminSeriesDetailsPageParams>();

  const { isLoading } = useSeries(serieId, "participants");

  return (
    <>
      {isLoading ? (
        <ProgressSpinner />
      ) : (
        <>
          <DivisionsEditor seriesId={serieId} />

          <Card>
            <h3
              style={{
                display: "flex",
                alignItems: "center",
                gap: ".5rem",
                color: "var(--primary)",
              }}
            >
              <FontAwesomeIcon icon={faExclamationCircle} />{" "}
              <FormattedMessage id="series.tips" defaultMessage="Tips" />
            </h3>
            <p style={{ marginLeft: "2.25rem" }}>
              <FormattedMessage
                id="series.tips.add-team"
                defaultMessage="Lägg till ett lag genom att dra och släppa laget över en division."
              />
            </p>
          </Card>
        </>
      )}
    </>
  );
};
