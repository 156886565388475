import { IsPaidBadge } from "./IsPaidBadge";
import { ProfileImageWithFallback } from "./ProfileImageWithFallback";
import { TeamCaptainBadge } from "./TeamCaptainBadge";

interface Props {
  image?: string;
  name?: string;
  firstName?: string;
  lastName?: string;
  title?: string;
  width?: string;
  height?: string;
  fontSize?: string;
  hideName?: boolean;
  phoneNumber?: string;
  emailAddress?: string;
  includeContact?: boolean;
  isPayed?: boolean;
  isOrganizer?: boolean;
  additionalInfo?: string;
}

export const AvatarNameWrapper = ({
  image,
  name,
  firstName,
  lastName,
  title,
  width,
  height,
  fontSize,
  hideName,
  phoneNumber,
  emailAddress,
  includeContact,
  isPayed,
  isOrganizer,
  additionalInfo,
}: Props) => (
  <div className="flex items-center gap-4">
    <div className="relative">
      <ProfileImageWithFallback
        firstName={firstName}
        lastName={lastName}
        src={image}
        title={title}
        width={width}
        height={height}
        fontSize={fontSize}
      />
      {isOrganizer && (
        <TeamCaptainBadge className="absolute bottom-[-0.3rem] right-[-0.3rem] text-xs" />
      )}
      {isPayed && (
        <IsPaidBadge className="absolute bottom-[-0.3rem] left-0 text-xs" />
      )}
    </div>
    <div className="flex flex-col">
      {hideName ?? (name && <div>{name}</div>)}
      {includeContact && phoneNumber && <p>{phoneNumber}</p>}
      {includeContact && emailAddress && <p>{emailAddress}</p>}
      {!includeContact && additionalInfo && <small>{additionalInfo}</small>}
    </div>
  </div>
);
