import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import {
  faChevronDown,
  faChevronUp,
  faClock,
  faCoins,
  faTennisBall,
  faWarehouse,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

import { PaymentStatus } from "../../models/Payment";
import { ReceiptItem, Receipt as ReceiptType } from "../../models/Receipts";

import { useCurrencyFormat } from "../../../../hooks/useCurrencyFormat";
import { useDateFormat } from "../../../../hooks/useDateFormat";

import { luxonDateFormat } from "../../../../utils/dateFormats";

enum ReceiptTypes {
  Fee = "fee",
}
interface Props {
  receipt: ReceiptType;
  facilityName: string;
}

interface StyleProps {
  $showFull?: boolean;
}

const ReceiptContainer = styled.div<StyleProps>`
  overflow: hidden;
  border: 2px solid var(--gray-200);
  box-sizing: border-box;
  width: 100%;
  gap: 1rem;
  flex: 0;
  min-width: 0;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  padding: 1rem;
  height: fit-content;
  font-weight: var(--medium);
  cursor: pointer;

  ${props =>
    props.$showFull
      ? `
    border: "2px solid var(--primary)";
    minHeight: auto;
  `
      : `
  minHeight: 11rem`}

  * {
    font-weight: var(--medium) !important;
  }

  svg {
    color: var(--primary);
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  font-size: 0.9rem;

  * {
    font-weight: var(--medium);
  }
  svg {
    width: 1rem;
  }
`;

const ExpandedView = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-top: 1px solid var(--gray-200);
  padding-top: 1rem;
  color: var(--gray-700);
`;

const Items = styled.div`
  color: var(--gray-400);
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const Item = styled.div`
  display: flex;
  gap: 0.5rem;
  font-size: 0.9rem;
`;

const Expander = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  cursor: pointer;
`;

const ItemType = styled.div`
  width: 6rem;
`;

const MainContent = styled.div`
  display: flex;
  gap: 0.6rem;
  flex-direction: column;
`;

export const Receipt: React.FC<Props> = ({ receipt, facilityName }) => {
  const intl = useIntl();
  const [showFull, setShowFull] = useState<boolean>(false);
  const [fee, setFee] = useState<number>(0);
  const { df } = useDateFormat(receipt.facilityId);
  const { cf } = useCurrencyFormat(receipt.currency);

  useEffect(() => {
    const fee = receipt.items.find(({ type }) => type === ReceiptTypes.Fee);
    if (fee) {
      setFee(fee.priceIncTax);
    }
  }, [receipt]);

  return (
    <ReceiptContainer
      $showFull={showFull}
      onClick={() => setShowFull(showFull => !showFull)}
    >
      <MainContent>
        <ContentContainer>
          <FontAwesomeIcon icon={faTennisBall} />
          <span>
            <FormattedMessage id="receipt.reference" />:{" "}
            {receipt.externalVisualId}
          </span>
        </ContentContainer>
        <ContentContainer>
          <FontAwesomeIcon icon={faWarehouse} />
          <span>{facilityName}</span>
        </ContentContainer>
        <ContentContainer>
          <FontAwesomeIcon icon={faClock} />
          <span>{df(receipt.created, luxonDateFormat)}</span>
        </ContentContainer>
        {receipt.paymentStatus !== PaymentStatus.Refunded && (
          <ContentContainer>
            <FontAwesomeIcon icon={faCoins} />
            <ContentContainer style={{ flexDirection: "column" }}>
              <ContentContainer>
                <FormattedMessage
                  id={"receipt.translation." + receipt.paymentMethod}
                  defaultMessage={receipt.paymentMethod}
                />
                <span>{cf(receipt.totalPriceIncTax)}</span>
              </ContentContainer>
              {!!fee && (
                <Item style={{ flexWrap: "wrap" }}>
                  {`(${intl.formatMessage({
                    id: "receipts.bookingfee.message",
                  })} ${cf(fee)})`}
                </Item>
              )}
            </ContentContainer>
          </ContentContainer>
        )}
        {receipt.paymentStatus === PaymentStatus.Refunded && (
          <ContentContainer>
            <FontAwesomeIcon icon={faCoins} />
            <FormattedMessage id="receipt.refund" />
            <span>{cf(receipt.refundedAmount)}</span>
          </ContentContainer>
        )}
      </MainContent>
      {showFull ? (
        <ExpandedView>
          <Items>
            {receipt.items?.map((item: ReceiptItem, i: number) => {
              if (item.type === "generalActivity") {
                item.type = "event";
              }
              return (
                <Item key={i}>
                  <ItemType>
                    <FormattedMessage
                      id={"receipt.item.type." + item.type}
                      defaultMessage={item.type}
                    />
                  </ItemType>
                  <ContentContainer style={{ flexWrap: "wrap" }}>
                    <span>{cf(item.priceIncTax)}</span>
                    <Item style={{ flexWrap: "wrap" }}>
                      {`(${intl.formatMessage({
                        id: "receipts.tax.message",
                      })} ${cf(item.tax)})`}
                    </Item>
                  </ContentContainer>
                </Item>
              );
            })}
          </Items>
          <Expander>
            <FontAwesomeIcon icon={faChevronUp} />
          </Expander>
        </ExpandedView>
      ) : (
        <Expander>
          <FontAwesomeIcon icon={faChevronDown} />
        </Expander>
      )}
    </ReceiptContainer>
  );
};
