import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";

import styled from "styled-components";

import { breakpoints } from "../appConstants/common";

import signupBackground from "../assets/images/backgrounds/background14.jpg";

import { Friend } from "../modules/player/models/Friend";

import { useCurrentUser } from "../hooks/swr/useCurrentUser";
import { useIsAuthenticated } from "../hooks/swr/useIsAuthenticated";

import { getUserInfoByInvitationId } from "../modules/player/services/FriendsService";

import FriendsInvitation from "../modules/player/components/FriendsInvitation";

import { SignInRedirectPage } from "./SignInRedirect";

interface FriendInvitationParam {
  friendInvitationId: string;
}

const Container = styled.div`
  padding: 0;
  background: var(--light);
  margin-top: 1em;

  img {
    width: 100%;
    max-height: 32rem;
    object-fit: cover;
    margin-bottom: -3px;
  }

  @media (min-width: ${breakpoints.XL}) {
    display: flex;
    min-height: 40rem;

    > div {
      flex: 0 0 50%;
    }

    img {
      width: 50%;
      max-height: 100%;
    }
  }
`;

const FriendInvitationPage: React.FC = () => {
  const intl = useIntl();
  const history = useHistory();
  const { friendInvitationId } = useParams<FriendInvitationParam>();
  const { currentUser } = useCurrentUser();
  const isAuthenticated = useIsAuthenticated();

  const [inviteUser, setInviteUser] = useState<Friend>(null);

  useEffect(() => {
    if (currentUser && friendInvitationId) {
      fetchInivtation();
    }
  }, [currentUser, friendInvitationId]);

  useEffect(() => {
    if (friendInvitationId) {
      window.location.href = `court22://profile/friend-invite/${friendInvitationId}`;
    }
  }, [friendInvitationId]);

  const fetchInivtation = async () => {
    try {
      setInviteUser(null);
      const response = await getUserInfoByInvitationId(friendInvitationId);
      setInviteUser(response);
      if (response == null) {
        history.push("/not-found");
      }
    } catch (err) {
      intl.formatMessage({
        id: "toast.error.fetch.user",
        defaultMessage: "Kunde inte hämta användarinformation",
      });
    }
  };

  if (!isAuthenticated) {
    return <SignInRedirectPage />;
  }

  return (
    <>
      <Container>
        <FriendsInvitation
          friend={inviteUser}
          invitationLink={friendInvitationId}
          isLoggedIn={!!currentUser}
        />
        {currentUser && <img alt="signup" src={signupBackground} />}
      </Container>
    </>
  );
};

export default FriendInvitationPage;
