import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link, useHistory } from "react-router-dom";

import {
  faCalendarDay,
  faChevronRight,
  faClock,
  faCoins,
  faCreditCard,
  faInfoCircle,
  faUser,
  faUserGroup,
  faUsers,
  faWarehouse,
} from "@fortawesome/pro-light-svg-icons";
import { faCheckCircle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { Skeleton } from "primereact/skeleton";
import styled from "styled-components";

import defaultCardImage from "../../../../assets/images/backgrounds/background3.jpg";

import { BuyableType } from "../../../../modules/checkout/models/Payment";
import {
  Membership,
  MembershipStatus,
} from "../../../player/models/Membership";
import { IActivity } from "../../models/Activity";

import { getVenuePath } from "../../../../helpers/pathHelpers";

import { useFacilityMemberships } from "../../../../hooks/swr/useFacilityMemberships";
import { useCurrencyFormat } from "../../../../hooks/useCurrencyFormat";
import { useDateFormat } from "../../../../hooks/useDateFormat";
import { useGenderTypes } from "../../../../hooks/useGenderTypes";
import { useJoinActivity } from "../../hooks/useJoinActivity";

import { Button } from "../../../../components/Button";
import { Card } from "../../../../components/Card";
import { Checkbox } from "../../../../components/Checkbox";
import { ConfirmationDialog } from "../../../../components/ConfirmationDialog";
import { Dialog } from "../../../../components/Dialog";

import { useCurrentUserId } from "../../../../recoil/currentUserIdState";
import {
  luxonDateFormat,
  luxonTimeFormat,
} from "../../../../utils/dateFormats";
import ActivityParticipantsList from "./ActivityParticipantsList";
import JoinActivityForm from "./JoinActivityForm";
import SerieParticipantsList from "./SerieParticipantsList";

const Content = styled(Card)`
  display: grid;
  grid-template-columns: 1fr;

  border: 2px solid var(--gray-150);
  padding: 0;

  box-shadow: none;
  gap: 0;

  @media (min-width: 800px) {
    grid-template-columns: 12rem 1fr;
  }
`;

const Background = styled.div`
  width: 100%;
  overflow: hidden;
  object-fit: cover;
  position: relative;
  display: flex;
  justify-content: center;
  border-right: 2px solid var(--gray-150);
`;

interface HeaderActionsProps {
  clickable: boolean;
}

const HeaderActions = styled.div<HeaderActionsProps>`
  position: absolute;
  bottom: 0.5rem;
  left: 0.5rem;
  z-index: 9;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  ${props => props?.clickable && "cursor: pointer;"}

  ::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    background: transparent
      linear-gradient(180deg, transparent 50%, rgba($primary-d-color, 1) 100%)
      0% 0% no-repeat padding-box;
  }
`;

const HeaderImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
`;

const ActivityInfo = styled.div`
  background-color: var(--light);

  padding: 1rem;
  color: $primary-color;
  display: flex;
  flex-direction: column;
`;

const InfoItemContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;
const InfoItem = styled.div`
  display: flex;
  align-items: center;

  min-height: 3rem;

  font-weight: 500;
  color: var(--dark);
  font-size: 0.8rem;

  border-top: 2px solid var(--gray-150);
  padding: 0.5em;

  :nth-child(2n) > svg {
    border-left: 2px solid var(--gray-150);
  }
`;

const Badge = styled.div`
  display: flex;
  align-items: center;
  background: var(--light);
  font-size: 0.7rem;
  font-weight: var(--bold);
  padding: 0.3em;
  border-radius: 4px;
`;

const NotPayedBadge = styled(Badge)`
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
`;

const IconWrapper = styled(Badge)`
  margin-right: 0.3rem;
`;

interface Props {
  activity: IActivity;
  showParticipants?: boolean;
  showLogo?: boolean;
  showDescription?: boolean;
  className?: string;
}

const ActivityInfoCard = ({ activity }: Props) => {
  const intl = useIntl();
  const { cf } = useCurrencyFormat(activity?.price?.currencyCode || "");
  const { getGenderNameById } = useGenderTypes();
  const { df } = useDateFormat(activity.facilityId);
  const [showParticipantsList, setShowParticipantsList] = useState(false);
  const [showJoinActivityDialog, setShowJoinActivityDialog] = useState(false);
  const [showMembershipPriceInfoDialog, setShowMembershipPriceInfoDialog] =
    useState(false);
  const { handlePayActivity, isLoading } = useJoinActivity();
  const activityMembershipInfo = useActivityMembershipPrices(activity);
  const history = useHistory();

  const isSerie = BuyableType.Series === activity?.buyableType;
  const hasParticipants =
    !!activity?.teams?.length || !!activity?.participants?.length;

  const iconClasses = "text-lg mr-2 p-2";

  return (
    <>
      <Content>
        <Background>
          <HeaderActions
            clickable={hasParticipants}
            onClick={() => hasParticipants && setShowParticipantsList(true)}
          >
            <Badge>
              <IconWrapper>
                {isSerie ? (
                  <FontAwesomeIcon icon={faUserGroup} />
                ) : (
                  <FontAwesomeIcon icon={faUsers} />
                )}
              </IconWrapper>
              {isSerie
                ? `${activity?.teams?.length} / ${activity.maxParticipantsCount}`
                : `${activity?.participants?.length} / ${activity.maxParticipantsCount}`}
            </Badge>
          </HeaderActions>

          <Dialog
            visible={showParticipantsList}
            onHide={() => setShowParticipantsList(false)}
          >
            {isSerie ? (
              <SerieParticipantsList activity={activity} />
            ) : (
              <ActivityParticipantsList activity={activity} />
            )}
          </Dialog>

          <HeaderImage
            src={activity?.imageUrl ? activity?.imageUrl : defaultCardImage}
          />

          {activity?.isCurrentUserParticipating &&
            !activity?.isPayedForCurrentUser &&
            activity?.price?.valueExclTax &&
            activity?.price.valueExclTax !== 0 && (
              <NotPayedBadge>
                <FormattedMessage
                  id="common.notpayed"
                  defaultMessage="Ej betald"
                />
              </NotPayedBadge>
            )}
        </Background>

        <ActivityInfo>
          <div className="mb-4 flex items-start gap-4">
            {!activity ? (
              <Skeleton
                height="2.5rem"
                className="promoted-activity__skeleton"
              />
            ) : (
              <h3 className="m-0 flex-1 [overflow-wrap:anywhere]">
                {activity?.name}
              </h3>
            )}

            <div className="hidden sm:block">
              {activity.buyableType === BuyableType.Event ? (
                <Link
                  to={`/event/${activity.id}`}
                  className="flex items-center gap-2 text-sm font-inherit"
                >
                  <FormattedMessage id="common.show.more" />
                  <FontAwesomeIcon icon={faChevronRight} />
                </Link>
              ) : (
                <ActionButtons
                  activity={activity}
                  isLoading={isLoading}
                  showJoinActivityDialog={setShowJoinActivityDialog}
                  handlePay={handlePayActivity}
                />
              )}
            </div>
          </div>

          <div>
            <InfoItem>
              <FontAwesomeIcon icon={faWarehouse} className={iconClasses} />
              {!activity ? (
                <Skeleton
                  height="2rem"
                  className="promoted-activity__skeleton"
                />
              ) : (
                <span>{activity?.facilityName}</span>
              )}
            </InfoItem>
          </div>

          <div className="flex grow flex-col justify-between">
            <InfoItemContainer>
              {BuyableType.Event === activity?.buyableType && (
                <InfoItem>
                  <FontAwesomeIcon icon={faClock} className={iconClasses} />
                  {!activity ? (
                    <Skeleton
                      height="2rem"
                      className="promoted-activity__skeleton"
                    />
                  ) : (
                    <span>
                      {activity?.startTime &&
                        df(activity?.startTime, luxonTimeFormat)}
                      {" - "}
                      {activity?.endTime &&
                        df(activity?.endTime, luxonTimeFormat)}
                    </span>
                  )}
                </InfoItem>
              )}

              <InfoItem>
                <FontAwesomeIcon icon={faUser} className={iconClasses} />
                {!activity ? (
                  <Skeleton
                    height="2rem"
                    className="promoted-activity__skeleton"
                  />
                ) : (
                  <span>{getGenderNameById(activity?.gender.toString())}</span>
                )}
              </InfoItem>

              <InfoItem>
                <FontAwesomeIcon icon={faCalendarDay} className={iconClasses} />
                {!activity ? (
                  <Skeleton
                    height="2rem"
                    className="promoted-activity__skeleton"
                  />
                ) : (
                  <span>
                    {activity?.startTime != null &&
                      df(activity.startTime, luxonDateFormat)}
                  </span>
                )}
              </InfoItem>

              <InfoItem>
                <FontAwesomeIcon icon={faCoins} className={iconClasses} />
                {!activity ? (
                  <Skeleton
                    height="2rem"
                    className="promoted-activity__skeleton"
                  />
                ) : (
                  <div className="flex items-center gap-1 text-sm">
                    {activity.price?.valueInclTax && (
                      <span
                        className={clsx(
                          activity.discountPrice && "line-through",
                        )}
                      >
                        {cf(activity.price.valueInclTax)}
                      </span>
                    )}
                    {!!activityMembershipInfo.membershipDiscounts.length && (
                      <>
                        {activity.discountPrice && (
                          <b>{cf(activity.discountPrice.valueInclTax)}</b>
                        )}

                        <button
                          type="button"
                          className="p-1"
                          onClick={() => setShowMembershipPriceInfoDialog(true)}
                        >
                          <FontAwesomeIcon
                            icon={faInfoCircle}
                            className="text-primary"
                          />
                        </button>
                      </>
                    )}
                  </div>
                )}
              </InfoItem>
            </InfoItemContainer>
          </div>

          <div className="my-6 flex justify-center sm:hidden">
            {activity.buyableType === BuyableType.Event ? (
              <Link
                to={`/event/${activity.id}`}
                className="flex items-center gap-2 text-sm font-inherit"
              >
                <Button type="primary" size="small">
                  <FormattedMessage id="common.show.more" />
                </Button>
              </Link>
            ) : (
              <ActionButtons
                activity={activity}
                isLoading={isLoading}
                showJoinActivityDialog={setShowJoinActivityDialog}
                handlePay={handlePayActivity}
              />
            )}
          </div>
        </ActivityInfo>
      </Content>

      {showJoinActivityDialog && (
        <Dialog visible onHide={() => setShowJoinActivityDialog(false)}>
          <JoinActivityForm activity={activity} />
        </Dialog>
      )}

      {showMembershipPriceInfoDialog && activity.discountPrice && (
        <ConfirmationDialog
          visible
          buttonSize="small"
          title={intl.formatMessage({
            id: "membership.discount-eligable",
          })}
          denyText={intl.formatMessage({ id: "common.close" })}
          confirmText={intl.formatMessage({ id: "membership.show-membership" })}
          onSubmit={() =>
            history.push(getVenuePath(activity.facilityId, "membership"))
          }
          onCancel={() => setShowMembershipPriceInfoDialog(false)}
          onHide={() => setShowMembershipPriceInfoDialog(false)}
        >
          <>
            <p className="py-6">
              <FormattedMessage id="membership.discount-applies-to-your-memberships" />
            </p>
            <div className="mb-24 flex flex-col gap-4">
              {!!activityMembershipInfo.membershipDiscounts.length &&
                activityMembershipInfo.membershipDiscounts.map(m => (
                  <div
                    key={m.membershipId}
                    className={clsx(
                      "flex items-center gap-3 rounded-[10px] border p-4 text-left",
                      m.currentUserIsActiveMember &&
                        m.discountedPriceInclTax ===
                          activity.discountPrice?.valueInclTax
                        ? "order-first border-primary bg-blue-50"
                        : "order-last border-gray-700 bg-white",
                    )}
                  >
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className={clsx(
                        m.currentUserIsActiveMember &&
                          m.discountedPriceInclTax ===
                            activity.discountPrice?.valueInclTax
                          ? "text-primary"
                          : "text-gray-500",
                        !m.currentUserIsActiveMember && "hidden",
                      )}
                    />
                    <span className="flex-1 font-bold">{m.membershipName}</span>

                    <span className="text-gray-700 line-through">
                      {cf(activity.price?.valueInclTax || 0)}
                    </span>
                    <span
                      className={clsx(
                        "font-bold",
                        m.currentUserIsActiveMember &&
                          m.discountedPriceInclTax ===
                            activity.discountPrice?.valueInclTax
                          ? "text-primary"
                          : "text-gray-500",
                      )}
                    >
                      {cf(m.discountedPriceInclTax)}
                    </span>
                  </div>
                ))}
            </div>
          </>
        </ConfirmationDialog>
      )}

      {showMembershipPriceInfoDialog && !activity.discountPrice && (
        <ConfirmationDialog
          visible
          buttonSize="small"
          title={intl.formatMessage({
            id: "membership.discount-available",
          })}
          denyText={intl.formatMessage({ id: "common.close" })}
          confirmText={intl.formatMessage({ id: "membership.purchase.title" })}
          onSubmit={() =>
            history.push(getVenuePath(activity.facilityId, "membership"))
          }
          onCancel={() => setShowMembershipPriceInfoDialog(false)}
          onHide={() => setShowMembershipPriceInfoDialog(false)}
        >
          <>
            <p className="py-6">
              <FormattedMessage id="membership.discount-applies-to-these-memberships" />
            </p>
            <div className="mb-24 flex flex-col gap-4">
              {!!activityMembershipInfo.membershipDiscounts.length &&
                activityMembershipInfo.membershipDiscounts.map(m => (
                  <div
                    key={m.membershipId}
                    className="order-first flex items-center gap-3 rounded-[10px] border border-primary bg-blue-50 p-4 text-left"
                  >
                    <span className="flex-1 font-bold">{m.membershipName}</span>

                    <span className="text-gray-700 line-through">
                      {cf(activity.price?.valueInclTax || 0)}
                    </span>
                    <span className="font-bold text-primary">
                      {cf(m.discountedPriceInclTax)}
                    </span>
                  </div>
                ))}
            </div>
          </>
        </ConfirmationDialog>
      )}
    </>
  );
};

interface ActionButtonProps {
  activity: IActivity;
  isLoading: boolean;
  showJoinActivityDialog: (value: boolean) => void;
  handlePay: (activity: IActivity) => void;
}

const ActionButtons = ({
  activity,
  isLoading,
  showJoinActivityDialog,
  handlePay,
}: ActionButtonProps) => {
  const intl = useIntl();
  const activityIsFree =
    !activity.price?.valueExclTax || activity.price?.valueExclTax === 0;

  return (
    <div className="flex justify-center">
      {!activity.isCurrentUserParticipating && (
        <>
          {/* For small screen */}
          <Button
            type="primary"
            size="small"
            className="sm:hidden"
            onClick={() => showJoinActivityDialog(true)}
          >
            <FormattedMessage id="common.show.more" />
          </Button>

          {/* For larger screens */}
          <button
            type="button"
            className="hidden text-primary hover:text-blue-700 sm:block"
            onClick={() => showJoinActivityDialog(true)}
          >
            <span className="flex items-center gap-2 text-sm">
              <FormattedMessage id="common.show.more" />
              <FontAwesomeIcon icon={faChevronRight} />
            </span>
          </button>
        </>
      )}

      {activity.isCurrentUserParticipating && (
        <>
          {!activity.isPayedForCurrentUser && !activityIsFree && (
            <Button
              type="primary"
              size="small"
              disabled={isLoading}
              icon={faCreditCard}
              onClick={() => handlePay(activity)}
              translationName="activities.pay"
            />
          )}

          {(activity.isPayedForCurrentUser || activityIsFree) && (
            <Checkbox
              label={intl.formatMessage({
                id: "activities.participates",
              })}
              checked
              disabled
            />
          )}
        </>
      )}
    </div>
  );
};

interface ActivityDiscountInfo {
  activityId: string;
  membershipDiscounts: {
    membershipId: Membership["id"];
    membershipName: Membership["name"];
    discountedPriceInclTax: IActivity["discountPrices"][number]["price"]["valueInclTax"];
    currentUserIsActiveMember: boolean;
  }[];
}

const useActivityMembershipPrices = (
  activity: IActivity,
): ActivityDiscountInfo => {
  const { memberships } = useFacilityMemberships(activity.facilityId);
  const currentUserId = useCurrentUserId();

  const filteredMemberships = memberships?.filter(m =>
    activity.discountPrices.flatMap(dp => dp.membershipIds).includes(m.id),
  );

  if (!filteredMemberships)
    return {
      activityId: activity.id,
      membershipDiscounts: [],
    };

  const membershipDiscounts = filteredMemberships.map(m => {
    const membershipDiscountPrice = activity.discountPrices.find(dp =>
      dp.membershipIds.includes(m.id),
    );

    return {
      membershipId: m.id,
      membershipName: m.name,
      discountedPriceInclTax: membershipDiscountPrice?.price.valueInclTax || 0,
      currentUserIsActiveMember: m.users.some(
        u => u.userId === currentUserId && u.status === MembershipStatus.Active,
      ),
    };
  });

  const activityMembershipPrices = {
    activityId: activity.id,
    membershipDiscounts,
  };

  return activityMembershipPrices;
};

export default ActivityInfoCard;
