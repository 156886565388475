import { useMemo } from "react";
import { useIntl } from "react-intl";

import { GenderType, IDirectory } from "../models/common";

interface GenderTypesHandler {
  genderTypes: IDirectory[];
  getGenderNameById: (genderId: string) => string;
}

export const useGenderTypes = (): GenderTypesHandler => {
  const intl = useIntl();

  const genderTypes: IDirectory[] = useMemo(
    () => [
      {
        id: GenderType.Mix.toString(),
        name: intl.formatMessage({
          id: "gender-types.mix",
          defaultMessage: "Mix",
        }),
      },
      {
        id: GenderType.Male.toString(),
        name: intl.formatMessage({
          id: "gender-types.male",
          defaultMessage: "Herr",
        }),
      },
      {
        id: GenderType.Female.toString(),
        name: intl.formatMessage({
          id: "gender-types.female",
          defaultMessage: "Dam",
        }),
      },
    ],
    [intl],
  );

  const getGenderNameById = (genderId: string): string =>
    genderTypes.find(({ id }) => id === genderId)?.name;

  return { genderTypes, getGenderNameById };
};
