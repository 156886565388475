import clsx from "clsx";

export const Label = ({
  className,
  children,
  ...props
}: React.LabelHTMLAttributes<HTMLLabelElement>) => {
  return (
    <label className={clsx("text-sm font-bold", className)} {...props}>
      {children}
    </label>
  );
};
