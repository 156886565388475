import { FormattedMessage, useIntl } from "react-intl";

import MDEditor, { commands } from "@uiw/react-md-editor";
import { useFormik } from "formik";
import { object, string } from "yup";

import { Button } from "./Button";
import { ProgressSpinner } from "./ProgressSpinner";
import { TextInput } from "./TextInput";

interface Props {
  onSubmit: (data: { subject: string; content: string }) => void;
}

export const MessageEditor = ({ onSubmit }: Props) => {
  const intl = useIntl();

  const validationSchema = object().shape({
    content: string().required(
      intl.formatMessage({ id: "validation.required.content" }),
    ),
    subject: string().required(
      intl.formatMessage({ id: "validation.required.subject" }),
    ),
  });

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      subject: "",
      content: intl.formatMessage({
        id: "admin.send.customer.email.initial-values.content",
      }),
    },
    onSubmit: data => onSubmit(data),
    validateOnMount: true,
  });

  return (
    <form
      className="flex h-full flex-col space-y-4"
      onSubmit={formik.handleSubmit}
    >
      <TextInput
        name="subject"
        label={intl.formatMessage({ id: "common.email.subject" })}
        required
        autoFocus
        onChange={formik.handleChange}
      />

      <div className="flex grow" data-color-mode="light">
        <MDEditor
          value={formik.values.content}
          onChange={value => formik.setFieldValue("content", value)}
          visibleDragbar={false}
          highlightEnable={false}
          commands={[
            commands.bold,
            commands.italic,
            commands.strikethrough,
            commands.title,
            commands.divider,
            commands.link,
            commands.hr,
            commands.divider,
            commands.orderedListCommand,
            commands.unorderedListCommand,
          ]}
          className="h-full min-h-[400px] w-full"
        />
      </div>
      <p>
        <FormattedMessage
          id="admin.send.customer.email.name.info"
          values={{
            var: "{$receiver_name}",
            Code: chunk => <code>{chunk}</code>,
          }}
        />
      </p>

      <div>
        <Button
          className="flex items-center gap-5"
          type="primary"
          size="small"
          onClick={() => formik.submitForm()}
          disabled={!formik.isValid || formik.isSubmitting}
        >
          <span>
            <FormattedMessage id="common.send" />
          </span>
          {formik.isSubmitting && <ProgressSpinner fontSize="1rem" />}
        </Button>
      </div>
    </form>
  );
};
