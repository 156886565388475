import { useEffect, useReducer } from "react";
import { FormattedMessage } from "react-intl";
import { useLocation, useParams } from "react-router-dom";

import {
  SeriesMatchRequest,
  SeriesMatchRequestFilter,
} from "../../../../../modules/game/models/Series";

import {
  getSeries,
  getSeriesMatches,
} from "../../../../../modules/game/services/Serie";

import { Card } from "../../../../../components/Card";
import { ProgressSpinner } from "../../../../../components/ProgressSpinner";
import FacilitySelect from "../../../../../modules/customer/components/FacilitySelect";
import { MatchFilter } from "./components/MatchFilter";
import { MatchList } from "./components/MatchList";

import { useSelectedFacilityId } from "../../../../../recoil/selectedFacilityIdState";
import { MatchOverviewPageState, pageReducer } from "./pageReducer";

interface AdminMatchOverviewPageParams {
  serieId: string;
}

const initialFilterState: SeriesMatchRequestFilter = {
  divisionIds: [],
  matchStatus: null,
  teamIds: [],
};

const initialPageState: MatchOverviewPageState = {
  errorId: null,
  filter: initialFilterState,
  filtered: false,
  loading: false,
  matches: [],
  series: null,
};

const fetchSeries = async (serieId: string, signal: AbortSignal) => {
  const res = await getSeries(serieId, "", signal);
  if (!signal.aborted && res) {
    return res.data;
  }
};

const fetchSeriesMatches = async (
  seriesId: string,
  payload: SeriesMatchRequest,
  signal: AbortSignal,
) => {
  const res = await getSeriesMatches(seriesId, payload, signal);
  if (!signal.aborted) {
    return res.data;
  }
};

export const AdminMatchOverviewPage = () => {
  const selectedFacilityId = useSelectedFacilityId();
  const { serieId } = useParams<AdminMatchOverviewPageParams>();
  const [state, dispatch] = useReducer(pageReducer, initialPageState);

  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    const newFilter: SeriesMatchRequestFilter = {
      divisionIds: searchParams.getAll("divisionIds"),
      matchStatus: searchParams.has("matchStatus")
        ? Number(searchParams.get("matchStatus"))
        : null,
      teamIds: searchParams.getAll("teamIds"),
    };

    dispatch({ type: "SET_FILTER", payload: newFilter });
  }, [location.search]);

  useEffect(() => {
    if (!serieId) {
      return;
    }

    dispatch({ type: "SET.LOADING" });

    const abortController = new AbortController();

    fetchSeries(serieId, abortController.signal)
      .then(series => {
        if (!series) {
          return;
        }

        dispatch({
          type: "SET.SERIES",
          payload: { series },
        });

        return series;
      })
      .catch(e => {
        dispatch({
          type: "ERROR",
          payload: { errorId: "series.error.fetch" },
        });

        console.log(e);
      });
  }, [serieId]);

  // Fetch matches when filter is set
  useEffect(() => {
    if (!state.series) {
      return;
    }

    const abortController = new AbortController();
    dispatch({ type: "SET.LOADING" });

    fetchSeriesMatches(
      state.series.id,
      { filter: state.filter },
      abortController.signal,
    )
      .then(data =>
        dispatch({ type: "SET.MATCHES", payload: { matches: data || [] } }),
      )
      .catch(e => {
        dispatch({
          type: "ERROR",
          payload: { errorId: e.id ?? "general.error.contact.support" },
        });
        console.log(e);
      });

    return () => abortController.abort();
  }, [state.filter, state.series]);

  if (state.series && state.series?.facilityId !== selectedFacilityId) {
    // we need to find a proper solution for this.
    // SelectedFacilityId can be another facility than linked serie (if open link)
    // facilityId should probably be in urls.
    return <FacilitySelect />;
  }

  return (
    <Card className="overflow-visible">
      <MatchFilter filter={state.filter} series={state.series || undefined} />

      {state.errorId && !state.loading && (
        <FormattedMessage id={state.errorId} />
      )}

      {state.loading && <ProgressSpinner />}

      {!state.errorId && !state.loading && state.series && (
        <MatchList
          serieId={state.series?.id}
          matches={state.matches}
          filtered={state.filtered}
        />
      )}
    </Card>
  );
};
