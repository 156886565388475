import React from "react";
import { useIntl } from "react-intl";
import { Redirect, useParams } from "react-router-dom";

import { SeasonStatus } from "../../../../../modules/game/models/Series";

import { useSeries } from "../../../../../hooks/swr/useSeries";

import { ProgressSpinner } from "../../../../../components/ProgressSpinner";
import { Title } from "../../../../../components/Title";
import { SerieScoreboard } from "./components/SeriesScoreboards";

interface SerieStandingsPageParams {
  serieId: string;
}

export const AdminSeriesScoreboardPage: React.FC = () => {
  const { serieId } = useParams<SerieStandingsPageParams>();

  const intl = useIntl();

  const { series, isLoading } = useSeries(serieId, "participants");

  if (isLoading && !series) {
    return <ProgressSpinner />;
  }

  // Scoreboard is only available for active and closed series
  if (
    [SeasonStatus.Active, SeasonStatus.Closed].includes(series.seasonStatus) ===
    false
  ) {
    return <Redirect to={`/admin/activities/serie/${serieId}`} push={false} />;
  }

  return (
    <div className="serie-standings-page" style={{ paddingRight: "2rem" }}>
      <Title
        title={intl.formatMessage(
          {
            id: "series.standings-page.title",
            defaultMessage: "{serieName} - Tabeller och matcher",
          },
          { serieName: series?.name },
        )}
      />

      <SerieScoreboard serieId={serieId} />
    </div>
  );
};
