import React, { useState } from "react";
import { FormattedMessage } from "react-intl";

import clsx from "clsx";

import { FacilityWithUtc } from "../../../../modules/customer/models/Facility";
import { SeriesPlayer } from "../../../../modules/game/models/Series";
import { User } from "../../../../modules/player/models/User";

import { useToaster } from "../../../../hooks/common/useToaster";

import { replacePartner } from "../../../../modules/game/services/Serie";

import { Button } from "../../../../components/Button";
import { ProfileImageWithFallback } from "../../../../components/ProfileImageWithFallback";
import { UserSearch } from "../../../../components/UserSearch";

interface ReplacePlayerProps {
  seriesId: string;
  facilityId: FacilityWithUtc["id"];
  teamId: string;
  playerOut?: SeriesPlayer;
  setShowReplacePlayer: React.Dispatch<React.SetStateAction<boolean>>;
  onChange: () => void;
}

export const ReplacePlayer: React.FC<ReplacePlayerProps> = ({
  seriesId,
  facilityId,
  teamId,
  playerOut,
  setShowReplacePlayer,
  onChange,
}) => {
  const { toastError } = useToaster();
  const [playerIn, setPlayerIn] = useState<User>(null);

  const handlePartner = async (p: User[]) => {
    setPlayerIn(p[0]);
  };

  const handleReplace = async () => {
    try {
      const response = await replacePartner(
        seriesId,
        facilityId,
        teamId,
        playerOut.id,
        playerIn.id,
      );
      if (response) {
        onChange?.();
      } else {
        toastError.seriesReplacePlayerAlreadyExists();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const cardWrapper = clsx(
    "mb-10 flex w-full items-center rounded border py-2.5 pl-4",
  );

  const currentCardWrapper = clsx(cardWrapper, {
    "border-pureblack": !playerIn,
    "border-dashed border-gray-500": playerIn,
  });

  const inCardWrapper = clsx(cardWrapper, {
    "border-primary": playerIn,
    "border-dashed border-gray-500": !playerIn,
  });

  return (
    <div className="flex flex-col items-start bg-purewhite p-4">
      <p className="mb-8 ml-0 text-3xl font-bold">
        <FormattedMessage id="series.replace.partner" />
      </p>

      <p className="mb-2.5 text-base font-bold text-blue-500">
        <FormattedMessage id="series.current.partner" />
      </p>
      <div className={currentCardWrapper}>
        <ProfileImageWithFallback
          css={`
            ${playerIn ? "opacity: 50%" : "border: 1px solid #000000"};
          `}
          src={playerOut?.profileImage}
          firstName={playerOut.firstName}
          lastName={playerOut.lastName}
          width="5rem"
          height="5rem"
        />
        <div className="ml-4 flex flex-col items-start">
          <p
            className={`text-md ${
              playerIn ? "text-gray-500" : "text-pureblack"
            }`}
          >
            {playerOut?.displayName}
          </p>
          <p
            className={`text-base ${
              playerIn ? "text-gray-500" : "text-pureblack"
            }`}
          >
            <FormattedMessage
              id="activities.level"
              values={{ skillLevelSpan: playerOut?.skillLevel }}
            />
          </p>
        </div>
      </div>
      <p className="mb-2.5 text-base font-bold text-blue-500">
        <FormattedMessage id="series.replace.partner.with" />
      </p>
      <div className={inCardWrapper}>
        {playerIn ? (
          <ProfileImageWithFallback
            src={playerIn.profileImage}
            firstName={playerIn.firstName}
            lastName={playerIn.lastName}
            css="border: 1px solid #000000"
            width="5rem"
            height="5rem"
          />
        ) : (
          <div className="h-20 w-20 rounded-full border border-dashed border-gray-500 bg-purewhite" />
        )}
        <div className="ml-4 flex flex-col items-start">
          <p className={`text-md ${playerIn ? "" : "text-gray-500"}`}>
            {playerIn ? (
              playerIn.displayName
            ) : (
              <FormattedMessage id="common.player" />
            )}
          </p>
          <p
            className={`text-base ${
              playerIn ? "text-gray-700" : "text-gray-500"
            } `}
          >
            {playerIn ? (
              <FormattedMessage
                id="activities.level"
                values={{ skillLevelSpan: playerIn.skillLevel }}
              />
            ) : (
              <FormattedMessage
                id="activities.level"
                values={{ skillLevelSpan: null }}
              />
            )}
          </p>
        </div>
      </div>
      <div className="w-full">
        <UserSearch
          facilityId={facilityId}
          onChange={p => {
            handlePartner(p);
          }}
          title="Sök användare"
          translationId="common.search-user"
          displaySelection={false}
          multiSelect={false}
        />
      </div>
      <div className="mt-4 flex w-full justify-evenly">
        <Button
          className="text-blue-500"
          translationName="common.cancel"
          type="default"
          onClick={() => setShowReplacePlayer(false)}
        />
        <Button
          className="bg-gradient-to-r from-blue-500 to-blue-700 py-4 pl-5 pr-4 text-sm text-purewhite"
          translationName="series.replace.partner"
          type="default"
          disabled={!playerIn}
          onClick={handleReplace}
        />
      </div>
    </div>
  );
};
