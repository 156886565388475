import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import { Series } from "../../../modules/game/models/Series";

import { getSeries } from "../../../modules/game/services/Serie";

import { Card } from "../../../components/Card";
import { ScoreboardTab } from "./components/ScoreboardTab";

interface Params {
  id?: string;
  tab?: string;
  divisionId?: string;
}

export const SeriePage: React.FC = () => {
  const { id, tab, divisionId } = useParams<Params>();
  const [serie, setSerie] = useState<Series>(null);

  const serieAbortController = useRef<AbortController>();

  useEffect(() => {
    serieAbortController.current = new AbortController();
    const fetchSerie = async () => {
      const res = await getSeries(
        id,
        null,
        serieAbortController.current?.signal,
      );
      setSerie(res?.data);
    };

    if (id) {
      fetchSerie();
    }
  }, [id]);

  return (
    <Card>
      {tab === "scoreboard" && (
        <ScoreboardTab
          serieId={id}
          divisions={serie?.divisions}
          divisionId={divisionId}
        />
      )}
    </Card>
  );
};
