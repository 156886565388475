import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";

import { faChevronRight } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { DateOnly } from "../../../models/DateOnly";
import { BookingType } from "../../../modules/checkout/models/Booking";
import { IScheduleResponse } from "../../../modules/checkout/models/Calendar";
import { AdminScheduleSlot } from "../../../modules/checkout/models/schedule";

import { adminGetMobileBookingEditPath } from "../../../helpers/pathHelpers";

import { useSelectedFacility } from "../../../hooks/swr/useSelectedFacility";
import { useDateFormat } from "../../../hooks/useDateFormat";
import { useQuery } from "../../../hooks/useQuery";
import { useSchedules } from "../../../modules/checkout/hooks/calendar/useSchedules";

import { AppLoadingSpinner } from "../../../components/AppLoadingSpinner";
import { ProfileImageWithFallback } from "../../../components/ProfileImageWithFallback";
import CustomerCalendarMenu from "../../../modules/checkout/components/Calendar/customer/CustomerCalendarMenu";
import { BookingTypeLabel } from "./components/BookingTypeLabel";

import { luxonTimeFormat } from "../../../utils/dateFormats";

const filterEvents = (bookings: AdminScheduleSlot[]) => {
  const seenTitles = new Set();
  return bookings.filter(slot => {
    if (slot.bookingType === BookingType.Event) {
      if (seenTitles.has(slot.title)) {
        return false;
      } else {
        seenTitles.add(slot.title);
        return true;
      }
    }
    return true;
  });
};

const getBookingsFromSlots = (
  groupedSlots: Record<string, AdminScheduleSlot[]>,
) => {
  return Object.values(groupedSlots).map((slots: AdminScheduleSlot[]) => {
    const minStartSlot = slots.reduce((minSlot, currentSlot) =>
      currentSlot.startTime.valueOf() < minSlot.startTime.valueOf()
        ? currentSlot
        : minSlot,
    );
    const maxEndSlot = slots.reduce((maxSlot, currentSlot) =>
      currentSlot.endTime.valueOf() > maxSlot.endTime.valueOf()
        ? currentSlot
        : maxSlot,
    );

    return {
      ...slots[0],
      startTime: minStartSlot.startTime,
      endTime: maxEndSlot.endTime,
    };
  });
};

export const AdminMobileBookingsPage: React.FC = () => {
  const history = useHistory();
  const query = useQuery();
  const { selectedFacility } = useSelectedFacility();
  const [selectedDate, setSelectedDate] = useState<DateOnly>(
    query?.get("date")
      ? DateOnly.fromISODate(query?.get("date") as string)
      : DateOnly.today(),
  );
  const [bookings, setBookings] = useState<AdminScheduleSlot[]>([]);
  const { dfInterval } = useDateFormat(selectedFacility?.id);

  const { schedules, isLoading } = useSchedules(
    selectedDate,
    selectedFacility?.id,
    undefined,
    true,
  );

  const fetchBookings = (date: DateOnly) => {
    setSelectedDate(date);
    const searchParams = new URLSearchParams({ date: date.toISO() });
    history.replace({
      pathname: history.location.pathname,
      search: searchParams.toString(),
    });
  };

  const getAllBookingsFromScheduleSlots = (schedules: IScheduleResponse[]) => {
    const allSlots = schedules.flatMap(schedule =>
      schedule.slots.map(slot => ({
        ...slot,
        courtName: schedule.objectName,
        facilityId: schedule.facilityId,
      })),
    );

    const groupedSlots = allSlots.reduce(
      (
        groups: Record<string, AdminScheduleSlot[]>,
        slot: AdminScheduleSlot,
      ) => {
        if (slot.bookingId) {
          const key = slot.bookingId;
          if (!groups[key]) {
            groups[key] = [];
          }
          groups[key].push(slot);
        }
        return groups;
      },
      {},
    );

    const slotsBookings = getBookingsFromSlots(groupedSlots);

    /*
      we filter the events to only show one entry per event in the list.
      we show the event name instead of court later
    */
    const filteredBookings = filterEvents(slotsBookings);

    return filteredBookings.sort(
      (a, b) => a.startTime.valueOf() - b.startTime.valueOf(),
    );
  };

  useEffect(() => {
    if (!schedules?.length) return;
    setBookings(getAllBookingsFromScheduleSlots(schedules));
  }, [schedules]);

  const onBookingClick = (booking: AdminScheduleSlot) => {
    history.push({ pathname: adminGetMobileBookingEditPath(), state: booking });
  };

  const hasCheckedForBookings = !isLoading && !!schedules?.length;

  return (
    <div className="space-y-2 p-2">
      <CustomerCalendarMenu
        selectedDate={selectedDate}
        maxDate={selectedDate.plus({ days: 90 })}
        minDate={selectedDate.minus({ days: 90 })}
        onChangeDate={fetchBookings}
      />

      {!hasCheckedForBookings && (
        <div className="flex justify-center">
          <AppLoadingSpinner hasContainer={false} />
        </div>
      )}

      {!isLoading && !bookings?.length && hasCheckedForBookings && (
        <div className="flex justify-center">
          <FormattedMessage id="admin.booking.no-bookings" />
        </div>
      )}

      {hasCheckedForBookings &&
        bookings.map(booking => (
          <div key={booking.bookingId}>
            <BookingTypeLabel bookingType={booking.bookingType} />
            <div className="rounded-b-md border border-gray-50 bg-gradient-to-b from-purewhite to-white p-2">
              <div
                onClick={() => onBookingClick(booking)}
                className="flex items-center justify-between"
              >
                <div className="flex items-center space-x-2">
                  <ProfileImageWithFallback
                    firstName={booking.bookedBy?.firstName}
                    lastName={booking.bookedBy?.lastName}
                    src={booking.bookedBy?.profileImage}
                    title={booking.bookedBy?.displayName}
                  />
                  <div>
                    <div className="flex space-x-2 text-black">
                      <p>
                        {dfInterval(
                          booking.startTime,
                          booking.endTime,
                          luxonTimeFormat,
                        )}
                      </p>
                      <p>
                        {booking.bookedBy?.firstName?.charAt(0)}.{" "}
                        {booking.bookedBy?.lastName}
                      </p>
                    </div>
                    <div className="text-gray-700">
                      <p>
                        {booking.bookingType === BookingType.Event
                          ? booking.title
                          : booking.courtName}
                      </p>
                    </div>
                  </div>
                </div>
                <FontAwesomeIcon
                  icon={faChevronRight}
                  className="text-gray-700"
                />
              </div>
              {booking.title && booking.bookingType !== BookingType.Event && (
                <p className="pt-2 text-sm">{booking.title}</p>
              )}
            </div>
          </div>
        ))}
    </div>
  );
};
