import { useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";

import {
  faEye,
  faEyeSlash,
  faFileImport,
  faMoneyBill,
  faUserCrown,
} from "@fortawesome/pro-light-svg-icons";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { FacilityWithUtc } from "../../../../modules/customer/models/Facility";

import { useAdminFacilities } from "../../../../hooks/swr/useAdminFacilities";

import {
  getAllFacilitiesForSuperadmin,
  toggleFacilityIsActive,
} from "../../../../modules/customer/services/FacilityService";
import { importUsersToFacility } from "../../../../modules/player/services/UserService";

import { Button } from "../../../../components/Button";
import { DataTable } from "../../../../components/DataTable/DataTable";
import { Dialog } from "../../../../components/Dialog";
import { ProgressSpinner } from "../../../../components/ProgressSpinner";
import { RadioButton } from "../../../../components/RadioButton";

import { NewFacilityForm } from "./NewFacilityForm";

const ImportSystems = ["matchi", "court22"];

export const SuperAdminFacilityOverview = () => {
  const [facilities, setFacilities] = useState<FacilityWithUtc[]>();
  const [loading, setLoading] = useState(false);
  const [uploadingFile, setUploadingFile] = useState(false);
  const [showNewFacilityDialog, setShowNewFacilityDialog] =
    useState<boolean>(false);
  const [handleImportUsersDialog, setHandleImportUsersDialog] =
    useState<boolean>(false);
  const [selectedFacilityId, setSelectedFacilityId] = useState<string>();
  const facilitiesOverviewAbortController = useRef<AbortController>();
  const [selectedSystem, setSelectedSystem] = useState<string>("matchi");

  const { mutate } = useAdminFacilities();

  useEffect(() => {
    facilitiesOverviewAbortController.current = new AbortController();

    const fetchFacilities = async () => {
      setLoading(true);

      try {
        const result = await getAllFacilitiesForSuperadmin(
          0,
          50,
          facilitiesOverviewAbortController.current?.signal,
        );
        setFacilities(result.data);
      } catch (e) {
        if (!facilitiesOverviewAbortController.current?.signal.aborted)
          console.log(e);
      } finally {
        setLoading(false);
      }
    };

    fetchFacilities();
    return () => facilitiesOverviewAbortController.current?.abort();
  }, []);

  const toggleIsActive = async (facilityId: string) => {
    setLoading(true);
    await toggleFacilityIsActive(facilityId);

    const fetchFacilities = async () => {
      setLoading(true);
      const result = await getAllFacilitiesForSuperadmin(0, 50);

      try {
        setFacilities(result.data);
      } finally {
        setLoading(false);
      }
    };

    fetchFacilities();
  };

  const handleAddFacilitySuccess = () => {
    setShowNewFacilityDialog(false);

    const fetchFacilities = async () => {
      setLoading(true);
      const result = await getAllFacilitiesForSuperadmin(0, 50);

      try {
        setFacilities(result.data);
      } finally {
        setLoading(false);
      }
    };

    fetchFacilities();
    mutate();
  };

  const columns = [
    {
      field: "name",
      body: (row: FacilityWithUtc) => row.name,
      id: "common.name",
    },
    {
      field: "courts",
      body: (row: FacilityWithUtc) => (
        <div style={{ display: "flex", gap: ".5rem" }}>
          {row?.bookableEntities?.map((x, key) => (
            <div key={key}>{x.name}</div>
          ))}
        </div>
      ),
      id: "common.courts",
    },
    {
      field: "bababa",
      body: (row: FacilityWithUtc) => (
        <div style={{ display: "flex", gap: ".5rem" }}>
          {row.isActive ? (
            <FontAwesomeIcon
              style={{ cursor: "pointer" }}
              icon={faEye}
              title="visas för slutanvändare"
              color="darkseagreen"
              onClick={() => toggleIsActive(row.id)}
            />
          ) : (
            <FontAwesomeIcon
              style={{ cursor: "pointer" }}
              icon={faEyeSlash}
              title="döljs för slutanvändare"
              color="gray"
              onClick={() => toggleIsActive(row.id)}
            />
          )}
          <FontAwesomeIcon
            icon={faMoneyBill}
            title="Ekonomi"
            style={{ cursor: "pointer" }}
          />
          <FontAwesomeIcon
            icon={faFileImport}
            title="importera användare"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setHandleImportUsersDialog(true);
              setSelectedFacilityId(row.id);
            }}
          />
          <FontAwesomeIcon
            icon={faUserCrown}
            title="handle admin"
            style={{ cursor: "pointer" }}
          />
        </div>
      ),
      id: "common.manage",
    },
  ];

  const handleImportUsers = (data: File) => {
    if (!selectedFacilityId) {
      return;
    }

    setUploadingFile(true);

    //TODO: FIX THIS!!!
    const upload = async (data: File) => {
      try {
        await importUsersToFacility(selectedFacilityId, data, selectedSystem);
      } catch (e) {
        console.log("error!!!!", e);
      } finally {
        setUploadingFile(false);
      }
    };

    upload(data);
  };

  return (
    <>
      <div style={{ width: "100%", display: "flex", justifyContent: "end" }}>
        <Button
          onClick={() => setShowNewFacilityDialog(true)}
          translationName="facilities.add-new-venue"
          type="primary"
          icon={faPlus}
        />
      </div>

      {loading ? (
        <ProgressSpinner />
      ) : (
        <DataTable
          rowsPerPage={10}
          pagination={facilities && facilities?.length > 10}
          useColumnBorder
          data={facilities?.sort(function (a, b) {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          })}
          columns={columns}
          emptyMessage={
            <FormattedMessage id="facilities.no-facilities-to-show" />
          }
        />
      )}

      {showNewFacilityDialog && (
        <Dialog
          header=""
          visible={true}
          onHide={() => setShowNewFacilityDialog(false)}
        >
          <NewFacilityForm onSuccess={handleAddFacilitySuccess} />
        </Dialog>
      )}

      {handleImportUsersDialog && (
        <Dialog
          header=""
          visible={true}
          onHide={() => {
            setHandleImportUsersDialog(false);
            setSelectedFacilityId(undefined);
          }}
        >
          <h3>
            <FormattedMessage
              id="super-admin.header.import-users"
              defaultMessage="Importera användare"
            />
          </h3>
          <p>
            <FormattedMessage
              id="super-admin.text.import-users-p-1"
              defaultMessage="Här kan du importera användare från Matchi genom att ladda upp en excel-fil."
            />
            <br />
            <FormattedMessage
              id="super-admin.text.import-users-p-2"
              defaultMessage="Användarna kommer att läggas till på hallen som kunder."
            />
          </p>
          <p>
            <FormattedMessage
              id="super-admin.text.import-users-p-3"
              defaultMessage="Ett konto kommer också att skapas till användaren och ett mail kommer
            att skickas till användaren om att detta har skett."
            />
          </p>

          <div className="mt-4">
            {ImportSystems.map((system: string) => (
              <RadioButton
                key={system}
                name={system}
                onChange={() => setSelectedSystem(system)}
                checked={selectedSystem === system}
                label={system.toUpperCase()}
              />
            ))}
          </div>

          <input
            className="mt-4"
            type="file"
            name="userImportFile"
            onChange={e =>
              e.target.files && handleImportUsers(e.target.files[0])
            }
          />

          {uploadingFile && <ProgressSpinner />}
        </Dialog>
      )}
    </>
  );
};
