import type { SWRConfiguration } from "swr";
import useSWR from "swr";

import { getBackupPaymentMethod } from "../../services/mySettingsService";

import { useCurrentUserId } from "../../recoil/currentUserIdState";

export const useCurrentUserBackupPaymentMethod = (
  options?: SWRConfiguration,
) => {
  const currentUserId = useCurrentUserId();

  const { data, ...rest } = useSWR(
    ["backupPaymentMethod", currentUserId],
    () => getBackupPaymentMethod(),
    options,
  );

  return {
    ...rest,
    backupPaymentMethod: data,
  };
};
