import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { User } from "../models/User";

import { useToaster } from "../../../hooks/common/useToaster";
import { useFriends } from "../../../hooks/swr/useFriends";

import { sendFriendRequest } from "../services/FriendsService";

import { Button } from "../../../components/Button";
import { Dialog } from "../../../components/Dialog";
import { ProgressSpinner } from "../../../components/ProgressSpinner";
import { UserSearch } from "../../../components/UserSearch";

import { FriendCard } from "./FriendCard";

export const FriendsTab: React.FC = () => {
  const intl = useIntl();
  const [showAddFriend, setShowAddFriend] = useState<boolean>();
  const [selectedNewFriend, setSelectedNewFriend] = useState<User>();
  const { toastInfo } = useToaster();

  const { friends, isLoading, mutate } = useFriends();

  const handleSendRequest = async (userId: string) => {
    await sendFriendRequest(userId);
    setShowAddFriend(false);
    toastInfo.friendRequestSent();
  };

  return (
    <>
      <div className="mb-8 flex flex-col items-center justify-between gap-8 sm:flex-row">
        <Button
          text={intl.formatMessage({ id: "profile.button.add-friend" })}
          onClick={() => setShowAddFriend(true)}
        />
      </div>
      {isLoading ? (
        <ProgressSpinner />
      ) : (
        <>
          <div className="grid grid-cols-[repeat(auto-fill,minmax(min(10rem),1fr))] gap-4">
            {friends?.map((friend, key) => (
              <FriendCard key={key} friend={friend} onUpdate={mutate} />
            ))}
          </div>

          {!friends?.length && (
            <FormattedMessage
              id="user-profile.no-friends"
              defaultMessage="Tyvärr har du inga vänner ännu."
            />
          )}
        </>
      )}

      {showAddFriend && (
        <Dialog visible onHide={() => setShowAddFriend(false)}>
          <UserSearch
            facilityId={null}
            title={intl.formatMessage({ id: "common.search-user" })}
            multiSelect={false}
            hideFriends
            filterUsers={friends}
            onChange={u => setSelectedNewFriend(u?.[0])}
          />
          {selectedNewFriend && (
            <div className="mt-4 flex grow justify-end">
              <Button
                type="primary"
                text={intl.formatMessage({ id: "friend.request.cta" })}
                onClick={() => handleSendRequest(selectedNewFriend?.id)}
              />
            </div>
          )}
        </Dialog>
      )}
    </>
  );
};
