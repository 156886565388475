import { FormattedMessage } from "react-intl";

import { FormikValues } from "formik";

export const useFormFieldValidator = (formik: FormikValues) => {
  const isFormFieldInvalid = (
    fieldName: string,
    fieldName2?: string,
    key?: string | number,
  ): boolean => {
    if (!formik) return false;

    const touched = formik.touched[fieldName];
    const errors = formik.errors[fieldName];

    if (touched && errors) {
      if (key && fieldName2) {
        return !!(touched[key] && errors[key] && errors[key][fieldName2]);
      } else if (fieldName2) {
        return !!(touched[fieldName2] && errors[fieldName2]);
      }

      return true;
    }
    return false;
  };

  const getFormErrorMessage = (
    fieldName: string,
    fieldName2?: string,
    key?: string | number,
  ) => {
    const errors = formik?.errors[fieldName];

    if (key && fieldName2) {
      return isFormFieldInvalid(fieldName, fieldName2, key) ? (
        <Component>
          <FormattedMessage id={errors[key][fieldName2]} />
        </Component>
      ) : null;
    } else if (fieldName2) {
      return isFormFieldInvalid(fieldName, fieldName2) ? (
        <Component>
          <FormattedMessage id={errors[fieldName2]} />
        </Component>
      ) : null;
    }
    return isFormFieldInvalid(fieldName, fieldName2) ? (
      <Component>
        <FormattedMessage id={errors} />
      </Component>
    ) : null;
  };

  return { isFormFieldInvalid, getFormErrorMessage };
};

const Component = ({ children }: React.PropsWithChildren<unknown>) => (
  <small className="text-sm text-error">{children}</small>
);
