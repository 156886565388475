import styled from "styled-components";

import { breakpoints } from "../appConstants/common";

interface Props {
  className?: string;
}

const CardComponent = styled.div`
  display: flex;
  flex-direction: column;
  background: var(--light);
  box-shadow: var(--default-shadow);
  border-radius: 0.25rem;
  padding: 1rem;
  opacity: 1;
  gap: 1rem;

  @media (min-width: ${breakpoints.XL}) {
    padding: 2rem;
    gap: 2rem;
  }
`;

export const Card = ({
  children,
  className,
}: React.PropsWithChildren<Props>) => {
  return <CardComponent className={className}>{children}</CardComponent>;
};
