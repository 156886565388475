import { DateTime } from "luxon";

import { ApiResponse, ApiSingleResponse } from "../../../models/common";
import { BuyableType } from "../../checkout/models/Payment";
import { FacilityWithUtc } from "../../customer/models/Facility";
import { IActivity, IActivityApiResponse } from "../models/Activity";

import { fetchApi, upload } from "../../../services/legacyApiClient";

const apiVersion = "game/v4";

export const getActivities = (
  page?: number,
  pageSize?: number,
  signal?: AbortSignal,
): Promise<ApiResponse<IActivity>> => {
  return fetchApi({
    uri: `${apiVersion}/activities?page=${page}&pageSize=${pageSize}`,
    signal,
  })
    .then((data: ApiResponse<IActivityApiResponse>) => ({
      ...data,
      data: data.data.map(activity => formatActivityFromApi(activity)),
    }))
    .catch(() => {
      return Promise.reject(null);
    });
};

export const getFacilityActivitiesOverview = (
  page?: number,
  pageSize?: number,
  facilityId?: string,
  signal?: AbortSignal,
): Promise<ApiResponse<IActivity>> => {
  return fetchApi({
    uri: `${apiVersion}/activities?page=${page}&pageSize=${pageSize}&facilityId=${facilityId}`,
    signal,
  })
    .then((data: ApiResponse<IActivityApiResponse>) => ({
      ...data,
      data: data.data.map(activity => formatActivityFromApi(activity)),
    }))
    .catch(() => {
      return Promise.reject(null);
    });
};

export const getMyActivities = (
  page?: number,
  pageSize?: number,
  isUpcoming?: boolean,
  signal?: AbortSignal,
): Promise<ApiResponse<IActivity>> => {
  return fetchApi({
    uri: `${apiVersion}/activities/registered?isUpcoming=${isUpcoming}&page=${page}&pageSize=${pageSize}`,
    signal,
  })
    .then((data: ApiResponse<IActivityApiResponse>) => ({
      ...data,
      data: data.data.map(activity => formatActivityFromApi(activity)),
    }))
    .catch(() => {
      return Promise.reject(null);
    });
};

export const getFacilityActivities = (
  facilityId: FacilityWithUtc["id"],
  params: {
    isUpcoming?: boolean;
    cursor?: IActivity["id"];
    pageSize?: number;
  },
  signal?: AbortSignal,
): Promise<{ data: IActivity[]; nextCursor: IActivity["id"] }> => {
  const urlSearchParams = new URLSearchParams();

  for (const key in params) {
    if (params[key] !== undefined && params[key] !== null) {
      urlSearchParams.append(key, params[key]);
    }
  }

  return fetchApi({
    uri: `${apiVersion}/activities/admin/${facilityId}?${urlSearchParams.toString()}`,
    signal,
  })
    .then(
      (response: {
        data: IActivityApiResponse[];
        nextCursor: IActivity["id"];
      }) => ({
        ...response,
        data: response.data.map(activity => formatActivityFromApi(activity)),
      }),
    )
    .catch(() => {
      return Promise.reject(null);
    });
};

export const uploadActivityImage = async (
  activityId: string,
  file: File,
  activityType: BuyableType,
): Promise<string | null> => {
  const data = new FormData();
  data.append("formFiles", file, file.name);
  const response = (await upload(
    `${apiVersion}/activities/${activityId}/${activityType}/image/cover`,
    data,
  )) as ApiSingleResponse<string>;

  return response.data;
};

export const updateActivityImageUrl = async (
  activityId: string,
  url: string,
  activityType: BuyableType,
  signal?: AbortSignal,
): Promise<string | null> => {
  const response = (await fetchApi({
    method: "POST",
    uri: `${apiVersion}/activities/${activityId}/${activityType}/image/cover/url`,
    payload: url,
    signal,
  })) as ApiSingleResponse<string>;

  return response.data;
};

export const cancelActivity = async (
  activityId: string,
  activityType: BuyableType,
  userId?: string,
): Promise<boolean> => {
  const response = (await fetchApi({
    method: "POST",
    uri: `${apiVersion}/activities/${activityId}/${activityType}/unregister/${userId}`,
  })) as ApiSingleResponse<boolean>;

  return response.data;
};

function formatActivityFromApi(activity: IActivityApiResponse): IActivity {
  return {
    ...activity,
    startTime: DateTime.fromISO(activity.startTime, { setZone: true }),
    endTime: DateTime.fromISO(activity.endTime, { setZone: true }),
    registrationOpenTo: DateTime.fromISO(activity.registrationOpenTo, {
      setZone: true,
    }),
  };
}
