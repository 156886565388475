import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";

import { faArrowAltCircleRight } from "@fortawesome/pro-light-svg-icons";

import { FacilityWithUtc } from "../../../../../../customer/models/Facility";
import {
  BookingType,
  ICreateBookingFormValues,
  PaymentType,
  PlayersFilterType,
} from "../../../../../models/Booking";
import { IScheduleSlot } from "../../../../../models/Calendar";
import { SlotPrice } from "../../../../../models/Pricing";

import { getCheckoutPath } from "../../../../../../../helpers/pathHelpers";

import { useToaster } from "../../../../../../../hooks/common/useToaster";
import { useCurrentUser } from "../../../../../../../hooks/swr/useCurrentUser";
import { useCurrencyFormat } from "../../../../../../../hooks/useCurrencyFormat";
import { useDateFormat } from "../../../../../../../hooks/useDateFormat";

import { createOrder } from "../../../../../../../services/myOrdersService";
import { createBooking } from "../../../../../services/Booking";
import { getSlotPrice } from "../../../../../services/Pricing";

import { Button } from "../../../../../../../components/Button";
import { Dialog } from "../../../../../../../components/Dialog";
import { SlotTimeItem } from "./components/SlotTimeItem";

import { luxonTimeFormat } from "../../../../../../../utils/dateFormats";
import { isSlotLateToBook } from "../../../utils";

interface Props {
  slots: IScheduleSlot | IScheduleSlot[];
  facilityId: FacilityWithUtc["id"];
  courtId: string;
  onHide: () => void;
}
export const SlotSelector: React.FC<Props> = ({
  slots,
  facilityId,
  courtId,
  onHide,
}) => {
  const history = useHistory();
  const { toastError } = useToaster();
  const [selected, setSelected] = useState<IScheduleSlot>();
  const [price, setPrice] = useState<SlotPrice>();
  const slotsToPick = slots as IScheduleSlot[];
  const { currentUser } = useCurrentUser();
  const { df } = useDateFormat(facilityId);
  const { cf } = useCurrencyFormat(price?.price.currencyCode);

  const handleGoToPayment = async () => {
    const data: ICreateBookingFormValues = {
      facilityId: facilityId,
      courtId: courtId,
      startTime: selected.startTime,
      endTime: selected.endTime,
      type: BookingType.Regular,
      organizerId: currentUser?.id,
      isOrganizerParticipant: true,
      participants: [],
      paymentType: PaymentType.Solid,
      playersFilterType: PlayersFilterType.Specific,
      gender: null,
      ageGroup: null,
      skillLevelLower: null,
      skillLevelUpper: null,
      price: null,
    };
    try {
      const { data: booking } = await createBooking(data, facilityId);

      const order = await createOrder(facilityId, {
        $type: "booking",
        bookingId: booking.id,
      });
      history.push(getCheckoutPath(order.id));
    } catch {
      toastError.createBookingFailed();
    }
  };
  useEffect(() => {
    const abortController = new AbortController();
    const fetchPrice = async () => {
      const response = (
        await getSlotPrice(
          facilityId,
          courtId,
          selected?.startTime,
          selected?.endTime,
          abortController.signal,
        )
      )?.data;

      if (!abortController.signal.aborted) {
        setPrice(response);
      }
    };

    if (facilityId && courtId && selected?.startTime) {
      fetchPrice();
    }

    return () => abortController.abort();
  }, [selected, facilityId, courtId]);

  return (
    <Dialog
      maximized={false}
      key={courtId}
      position="bottom"
      dismissableMask
      visible
      onHide={onHide}
      blockScroll
    >
      <div className="space-between flex grow flex-col">
        <FormattedMessage id="common.start-time" defaultMessage="Starttid" />
        <div className="flex space-x-4">
          {slotsToPick?.map(s => (
            <SlotTimeItem
              key={s.startTime.toString()}
              isSelected={selected?.startTime === s?.startTime}
              isAvailable={!s.isBooked && !isSlotLateToBook(s)}
              timeString={df(s.startTime, luxonTimeFormat)}
              onClick={() => {
                if (!s.isBooked && !isSlotLateToBook(s)) {
                  setSelected(s);
                }
              }}
            />
          ))}
        </div>
        <div className="my-4 flex">
          {price && (
            <p className="fond-bold text-base">
              <FormattedMessage
                id="payment.total.price.whereof.booking"
                values={{
                  price: cf(
                    price?.individualPrice.valueInclTax +
                      price?.individualFee.valueInclTax,
                  ),
                  bookingFee: cf(price?.individualFee.valueInclTax),
                }}
              />
            </p>
          )}
        </div>
      </div>
      <Button
        disabled={!selected}
        className="button-primary w-full"
        translationName="common.continue-to-payment"
        icon={faArrowAltCircleRight}
        onClick={handleGoToPayment}
      />
    </Dialog>
  );
};
