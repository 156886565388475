import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";

import { SportType } from "../../../../../../modules/customer/models/Facility";

import { useToaster } from "../../../../../../hooks/common/useToaster";

import { getSportTypes } from "../../../../../../modules/customer/services/FacilityService";

import { ProgressSpinner } from "../../../../../../components/ProgressSpinner";
import { SelectInput } from "../../../../../../components/inputs/SelectInput";
import { FacilityTypes } from "./components/FacilityTypes";

export const FacilityPriceGroups: React.FC = () => {
  const { toastError } = useToaster();
  const [sportTypeId, setSportTypeId] = useState<string>();
  const [sportTypes, setSportTypes] = useState<SportType[]>([]);
  const [loading, setLoading] = useState(true);
  const sportTypeAbortController = useRef<AbortController>();

  useEffect(() => {
    sportTypeAbortController.current = new AbortController();

    const getSportTypesAsync = async (page: number, pageSize: number) => {
      try {
        const sportTypes = await getSportTypes(
          page,
          pageSize,
          sportTypeAbortController.current?.signal,
        );
        if (sportTypes?.data) {
          setSportTypes(sportTypes.data);
          setSportTypeId(sportTypes.data[0]?.id);
        }
      } catch {
        if (!sportTypeAbortController.current?.signal.aborted)
          toastError.fetchSportTypesFailed();
      } finally {
        setLoading(false);
      }
    };

    getSportTypesAsync(0, 100);
    return () => sportTypeAbortController.current?.abort();
  }, []);

  return (
    <div className="grid grid-cols-[1fr_1fr_auto] gap-8 lg:grid-cols-[3fr_3fr_2fr]">
      <h3 className="col-span-3">
        <FormattedMessage id="facility-settings.my-court-types" />
      </h3>
      {loading ? (
        <ProgressSpinner />
      ) : (
        <>
          <div className="col-span-1">
            <SelectInput
              label="Aktivitet"
              value={sportTypeId}
              translationName="common.activity"
              options={sportTypes?.map?.(({ id, name }) => ({
                label: name,
                value: id,
              }))}
              onChange={e => setSportTypeId(e.value)}
            />
          </div>

          {sportTypeId && (
            <FacilityTypes sportTypeId={sportTypeId} sportTypes={sportTypes} />
          )}
        </>
      )}
    </div>
  );
};
