import { useSelectedFacilityId } from "../../recoil/selectedFacilityIdState";
import { useFacility } from "./useFacility";

export const useSelectedFacility = () => {
  const selectedFacilityId = useSelectedFacilityId();

  const { facility: selectedFacility, ...rest } =
    useFacility(selectedFacilityId);

  return {
    ...rest,
    selectedFacility,
  };
};
