import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { faXmarkLarge } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { InputNumber } from "primereact/inputnumber";
import { InputSwitch } from "primereact/inputswitch";

import { GeneralActivityRequest } from "../../../../../../modules/game/models/GeneralActivities";

import { useFacilityMemberships } from "../../../../../../hooks/swr/useFacilityMemberships";

import { Button } from "../../../../../../components/Button";
import { Label } from "../../../../../../components/Label";
import MultipleSelectInput from "../../../../../../components/inputs/MultipleSelectInput";

interface Props {
  values: GeneralActivityRequest;
  onChange: (dicountPrices: GeneralActivityRequest["discountPrices"]) => void;
}

const DEFAULT_DISCOUNT_PRICES = [{ discountAmount: 0, membershipIds: [] }];

export const MembershipPrices = ({ values, onChange }: Props) => {
  const intl = useIntl();
  const [showInputs, setShowInputs] = useState(
    values.discountPrices?.some(discount => discount.membershipIds.length > 0),
  );
  const { memberships } = useFacilityMemberships(
    showInputs ? values.facilityId : undefined,
  );
  const [discountPrices, setDiscountPrices] = useState(
    values.discountPrices || DEFAULT_DISCOUNT_PRICES,
  );

  const handleDiscountAmountChange = (index: number, value: number) => {
    setDiscountPrices(
      discountPrices.map((item, i) =>
        i === index ? { ...item, discountAmount: value } : item,
      ),
    );
  };

  const handleMembershipIdsChange = (index: number, value: string[]) => {
    setDiscountPrices(
      discountPrices.map((item, i) =>
        i === index ? { ...item, membershipIds: value } : item,
      ),
    );
  };

  useEffect(() => {
    onChange(discountPrices);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discountPrices]);

  return (
    <div className="flex flex-col gap-8 sm:gap-4 lg:max-w-2xl">
      <div className="flex items-center gap-4">
        <h3 className="text-3xl font-bold">
          <FormattedMessage id="admin.membership-discounts" />
        </h3>

        <InputSwitch
          size={1}
          name="showPriceInput"
          checked={showInputs}
          onChange={() => {
            onChange(showInputs ? DEFAULT_DISCOUNT_PRICES : discountPrices);

            setShowInputs(!showInputs);
          }}
        />
      </div>

      {showInputs && (
        <div>
          <div className="hidden gap-4 sm:flex">
            <Label className="sm:w-[150px]">
              <FormattedMessage id="common.price" />
            </Label>
            <Label>
              <FormattedMessage id="admin.player.overview.filter.membership.placeholder" />
            </Label>
          </div>
          {discountPrices.map((discount, index) => {
            return (
              <div
                key={index}
                className="flex items-center gap-4 border-b py-8 first:!pt-0 sm:border-none sm:pt-4"
              >
                <div className="flex min-w-0 grow flex-col gap-4 sm:flex-row">
                  <InputNumber
                    className="sm:w-[150px]"
                    inputClassName="leading-[36px] min-w-0"
                    maxFractionDigits={2}
                    minFractionDigits={2}
                    value={discount.discountAmount}
                    onChange={e =>
                      handleDiscountAmountChange(index, e.value ?? 0)
                    }
                  />

                  <MultipleSelectInput
                    placeholder={intl.formatMessage({
                      id: "admin.player.overview.filter.membership.placeholder",
                    })}
                    className="min-w-0 flex-1 leading-[36px]"
                    value={discount.membershipIds.map(id => id)}
                    options={memberships?.map(membership => ({
                      label: membership.name,
                      value: membership.id,
                      disabled: discountPrices
                        .toSpliced(index, 1)
                        .some(discount =>
                          discount.membershipIds.includes(membership.id),
                        ),
                    }))}
                    onChange={e =>
                      handleMembershipIdsChange(index, e.target.value)
                    }
                  />
                </div>

                <button
                  type="button"
                  className={clsx(
                    "flex-none p-1 text-right",
                    discountPrices.length > 1 ? "visible" : "invisible",
                  )}
                  onClick={() =>
                    setDiscountPrices(discountPrices.toSpliced(index, 1))
                  }
                >
                  <FontAwesomeIcon
                    icon={faXmarkLarge}
                    className="text-lg sm:text-base"
                  />
                </button>
              </div>
            );
          })}

          <Button
            className="min-w-[151px]"
            size="small"
            type="primary"
            disabled={discountPrices.at(-1)?.membershipIds.length === 0}
            onClick={() =>
              setDiscountPrices([...discountPrices, ...DEFAULT_DISCOUNT_PRICES])
            }
          >
            <FormattedMessage id="button.add" />
          </Button>
        </div>
      )}
    </div>
  );
};
