import { useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import {
  faAngleDown,
  faAngleUp,
  faCheck,
  faChevronLeft,
  faChevronRight,
  faLightbulbOn,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown } from "@mui/base/Dropdown";
import { MenuButton } from "@mui/base/MenuButton";
import clsx from "clsx";
import { useRecoilState } from "recoil";

import { DateOnly } from "../../../../../models/DateOnly";
import { BookingType } from "../../../models/Booking";

import { CalendarDateTimeWrapper } from "../../../../../components/CalendarDateTimeWrapper";
import { Menu, MenuItem } from "../../../../../components/Menu";

import { adminCalendarVisibleCourtFilterState } from "../../../../../recoil/adminCalendarVisibleCourtFilterState";
import { bookingTypeBackgroundColor } from "../utils";
import { useAdminCalendarContext } from "./AdminCalendarContext";

const AdminCalendarMenu = () => {
  const {
    state: { selectedDate, bookingBeingRescheduled },
    dispatch,
  } = useAdminCalendarContext();

  const calendarInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (calendarInputRef.current) {
      calendarInputRef.current.size = calendarInputRef.current.value.length + 1;
    }
  }, [selectedDate]);

  return (
    <div className="flex flex-wrap gap-4 text-sm font-normal">
      <div className="text-nowrap rounded-lg bg-purewhite px-5 py-3.5">
        <button
          type="button"
          className="p-1"
          onClick={() =>
            dispatch({
              type: "SET_SELECTED_DATE",
              payload: selectedDate.minus({ days: 1 }),
            })
          }
        >
          <FontAwesomeIcon className="inline-block" icon={faChevronLeft} />
        </button>
        <CalendarDateTimeWrapper
          showButtonBar
          clearButtonClassName="hidden"
          selectOtherMonths
          showWeek
          className="mx-3 cursor-pointer"
          inputClassName="bg-transparent text-center border-0 text-sm cursor-pointer p-0"
          inputRef={calendarInputRef}
          dateFormat="DD, d MM"
          value={selectedDate.toDateTime()}
          onChange={e => {
            dispatch({
              type: "SET_SELECTED_DATE",
              payload: DateOnly.fromDateTime(e.value),
            });
          }}
        />
        <button
          type="button"
          className="p-1"
          onClick={() => {
            dispatch({
              type: "SET_SELECTED_DATE",
              payload: selectedDate.plus({ days: 1 }),
            });
          }}
        >
          <FontAwesomeIcon className="inline-block" icon={faChevronRight} />
        </button>
      </div>

      <div className="mr-auto flex-auto rounded-lg bg-purewhite px-2 py-2">
        {bookingBeingRescheduled ? (
          <div className="flex h-full items-center px-3">
            <span className="text-xl font-bold">
              <FormattedMessage id="admin.calendar.reschedule.title" />
            </span>
            <button
              type="button"
              className="ml-auto underline"
              onClick={() => {
                dispatch({
                  type: "SET_BOOKING_BEING_RESCHEDULED",
                  payload: undefined,
                });
              }}
            >
              <FormattedMessage id="common.cancel-rescheduling" />
            </button>
          </div>
        ) : (
          <Legends />
        )}
      </div>

      <div className="flex items-center rounded-lg bg-purewhite px-5 py-4">
        <CourtsFilterDropdown />
      </div>

      <div className="flex items-center rounded-lg bg-purewhite px-5 py-4">
        <div>
          <FontAwesomeIcon icon={faLightbulbOn} className="mr-2 inline-block" />
          <FormattedMessage
            id="admin.calendar.tips"
            values={{
              kbd: chunk => (
                <kbd className="inline-block text-nowrap rounded border border-gray-150 bg-gray-100 px-1 text-pureblack shadow">
                  {chunk}
                </kbd>
              ),
            }}
          />
        </div>
      </div>
    </div>
  );
};

const CourtsFilterDropdown = () => {
  const [isOpen, setIsOpen] = useState(false);

  const [visibleCourts, setVisibleCourts] = useRecoilState(
    adminCalendarVisibleCourtFilterState,
  );

  return (
    <Dropdown open={isOpen} onOpenChange={(e, value) => setIsOpen(value)}>
      <MenuButton className="flex items-center gap-3">
        {visibleCourts === "open" && (
          <FormattedMessage id="admin.calendar.court-filter.show-open-courts" />
        )}
        {visibleCourts === "closed" && (
          <FormattedMessage id="admin.calendar.court-filter.show-closed-courts" />
        )}
        {visibleCourts === "all" && (
          <FormattedMessage id="admin.calendar.court-filter.show-all-courts" />
        )}
        <FontAwesomeIcon icon={isOpen ? faAngleUp : faAngleDown} />
      </MenuButton>
      <Menu className="font-normal">
        <MenuItem
          onClick={() => setVisibleCourts("open")}
          className={clsx(
            "flex items-center justify-between gap-2",
            visibleCourts === "open" &&
              "bg-primary/10 text-primary hover:bg-primary/10",
          )}
        >
          <FormattedMessage id="admin.calendar.court-filter.show-open-courts" />
          <FontAwesomeIcon
            icon={faCheck}
            visibility={visibleCourts === "open" ? "inherit" : "hidden"}
          />
        </MenuItem>

        <MenuItem
          onClick={() => setVisibleCourts("closed")}
          className={clsx(
            "flex items-center justify-between gap-2",
            visibleCourts === "closed" &&
              "bg-primary/10 text-primary hover:bg-primary/10",
          )}
        >
          <FormattedMessage id="admin.calendar.court-filter.show-closed-courts" />
          <FontAwesomeIcon
            icon={faCheck}
            visibility={visibleCourts === "closed" ? "inherit" : "hidden"}
          />
        </MenuItem>

        <MenuItem
          onClick={() => setVisibleCourts("all")}
          className={clsx(
            "flex items-center justify-between gap-2",
            visibleCourts === "all" &&
              "bg-primary/10 text-primary hover:bg-primary/10",
          )}
        >
          <FormattedMessage id="admin.calendar.court-filter.show-all-courts" />
          <FontAwesomeIcon
            icon={faCheck}
            visibility={visibleCourts === "all" ? "inherit" : "hidden"}
          />
        </MenuItem>
      </Menu>
    </Dropdown>
  );
};

const Legends = () => {
  const { formatMessage } = useIntl();

  const {
    state: { selectedBookingTypes, filterUnPaid },
    dispatch,
  } = useAdminCalendarContext();

  const bookingTypes = useMemo(() => {
    const types = [
      {
        bookingType: [BookingType.Event],
        label: formatMessage({
          id: "common.event",
        }),
        colorClassName: bookingTypeBackgroundColor(BookingType.Event),
      },
      {
        bookingType: [BookingType.Series],
        label: formatMessage({
          id: "common.serie",
        }),
        colorClassName: bookingTypeBackgroundColor(BookingType.Series),
      },
      {
        bookingType: [BookingType.NotBookable],
        label: formatMessage({
          id: "calendar.slot.not-bookable",
        }),
        colorClassName: bookingTypeBackgroundColor(BookingType.NotBookable),
      },
      {
        bookingType: [BookingType.Regular],
        label: formatMessage({
          id: "common.booking",
        }),
        colorClassName: bookingTypeBackgroundColor(BookingType.Regular),
      },
      {
        bookingType: [BookingType.Open],
        label: formatMessage({
          id: "common.open-booking",
        }),
        colorClassName: bookingTypeBackgroundColor(BookingType.Open),
      },
      {
        bookingType: [BookingType.Recurring],
        label: formatMessage({
          id: "common.recurring",
        }),
        colorClassName: bookingTypeBackgroundColor(BookingType.Recurring),
      },
    ];

    return types;
  }, [formatMessage]);

  return (
    <div className="flex h-full">
      {bookingTypes.map(({ bookingType, label, colorClassName }) => (
        <button
          type="button"
          title={label}
          key={bookingType[0]}
          className={clsx(
            "flex cursor-pointer items-center truncate rounded-md border pl-2 pr-2 text-left",
            selectedBookingTypes?.includes(bookingType[0])
              ? "border-primary bg-primary/10"
              : "border-transparent hover:text-primary",
          )}
          onClick={() =>
            dispatch({
              type: "SET_SELECTED_BOOKING_TYPES",
              payload: selectedBookingTypes?.includes(bookingType[0])
                ? []
                : bookingType,
            })
          }
        >
          <div className={clsx("mr-3 h-4 w-1 rounded", colorClassName)} />
          {label}
        </button>
      ))}

      <button
        type="button"
        title={formatMessage({ id: "bookings.not-payed" })}
        className={clsx(
          "flex cursor-pointer items-center truncate rounded-md border pl-2 pr-2 text-left",
          filterUnPaid
            ? "border-primary bg-primary/10"
            : "border-transparent hover:text-primary",
        )}
        onClick={() =>
          dispatch({
            type: "SET_FILTER_UNPAID",
            payload: !filterUnPaid,
          })
        }
      >
        <div className={clsx("mr-3 h-4 w-1 rounded bg-red-600")} />
        <FormattedMessage id="bookings.not-payed" />
      </button>
    </div>
  );
};

export default AdminCalendarMenu;
