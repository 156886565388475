import { createContext, useContext, useEffect, useReducer } from "react";
import { FormattedMessage } from "react-intl";
import { toast } from "react-toastify";

import type { DateOnly } from "../../../../../models/DateOnly";
import type { FacilityWithUtc } from "../../../../customer/models/Facility";
import type { BookingType, IBooking } from "../../../models/Booking";

interface State {
  facilityId: FacilityWithUtc["id"];
  selectedDate: DateOnly;
  selectedBookingTypes: BookingType[];
  isExpanded: boolean;
  bookingBeingRescheduled?: IBooking;
  filterUnPaid?: boolean;
}

type Action =
  | { type: "SET_SELECTED_DATE"; payload: DateOnly }
  | { type: "SET_SELECTED_BOOKING_TYPES"; payload: BookingType[] }
  | { type: "SET_IS_EXPANDED"; payload: boolean }
  | { type: "SET_BOOKING_BEING_RESCHEDULED"; payload: IBooking | undefined }
  | { type: "SET_FILTER_UNPAID"; payload: boolean };

type Dispatch = (action: Action) => void;

const AdminCalendarContext = createContext<
  | {
      state: State;
      dispatch: Dispatch;
    }
  | undefined
>(undefined);

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "SET_SELECTED_DATE":
      return {
        ...state,
        selectedDate: action.payload,
      };
    case "SET_SELECTED_BOOKING_TYPES":
      return {
        ...state,
        filterUnPaid: false,
        selectedBookingTypes: action.payload,
      };
    case "SET_IS_EXPANDED":
      return {
        ...state,
        isExpanded: action.payload,
      };
    case "SET_BOOKING_BEING_RESCHEDULED":
      return {
        ...state,
        bookingBeingRescheduled: action.payload,
      };
    case "SET_FILTER_UNPAID":
      return {
        ...state,
        selectedBookingTypes: [],
        filterUnPaid: action.payload,
      };
    default:
      return state;
  }
};

export const AdminCalendarProvider = ({
  children,
  ...initialState
}: React.PropsWithChildren<State>) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (!state.bookingBeingRescheduled) {
      toast.dismiss("reschedule-message");
    }

    if (state.bookingBeingRescheduled) {
      toast(
        <>
          <FormattedMessage id="admin.calendar.reschedule.title" />
          <br />
          <button
            className="text-sm underline"
            type="button"
            onClick={() => {
              dispatch({
                type: "SET_BOOKING_BEING_RESCHEDULED",
                payload: undefined,
              });
            }}
          >
            <FormattedMessage id="common.cancel-rescheduling" />
          </button>
        </>,
        {
          closeOnClick: false,
          autoClose: false,
          toastId: "reschedule-message",
        },
      );
    }
  }, [state.bookingBeingRescheduled]);

  return (
    <AdminCalendarContext.Provider value={{ state, dispatch }}>
      {children}
    </AdminCalendarContext.Provider>
  );
};

export const useAdminCalendarContext = () => {
  const context = useContext(AdminCalendarContext);

  if (context === undefined) {
    throw new Error(
      "useAdminCalendarContext must be used within a AdminCalendarProvider",
    );
  }

  return context;
};

export const useAdminCalendarContextState = () => {
  const { state } = useAdminCalendarContext();

  return state;
};
