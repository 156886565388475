import { useEffect, useState } from "react";
import { useIntl } from "react-intl";

import {
  faCheck,
  faEdit,
  faTimes,
  faTrash,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import styled from "styled-components";

import {
  PriceCategory,
  PriceGroup,
  PriceListResponseWithUTC,
} from "../../../../../modules/checkout/models/Pricing";
import { GroupTypeStrings } from "../../../../../modules/player/models/Group";

import { useToaster } from "../../../../../hooks/common/useToaster";
import { useAppSelector } from "../../../../../hooks/store/store";
import { useFacilityLocalization } from "../../../../../hooks/store/useFacilityLocalization";
import { useCurrencyFormat } from "../../../../../hooks/useCurrencyFormat";
import { useStandardGroup } from "../../../../../modules/customer/hooks/useStandardGroup";

import { deletePriceCategory } from "../../../../../modules/checkout/services/Pricing";

import { ConfirmationDialog } from "../../../../../components/ConfirmationDialog";

interface Props {
  fetchPriceLists: any;
  fetchGroups: any;
  readOnly: boolean;
  index: number;
  category: PriceCategory;
  priceList: PriceListResponseWithUTC;
  onEditCategory: (category: PriceCategory) => void;
  onCancelEdit: () => void;
  onPriceChanged: (category: PriceCategory, prices: any[]) => void;
}

const ColorColumn = styled.td`
  width: 1rem;
`;

const NameColumn = styled.td`
  width: 10rem;
  text-align: left !important;
  white-space: nowrap;
  font-weight: var(--bold);

  input {
    font-weight: var(--medium);
  }
`;

const PriceColumn = styled.td`
  text-align: left !important;
  input {
    width: 5rem;
    font-weight: var(--medium);
  }
`;

const ActionIcon = styled.td`
  display: flex;
  justify-content: flex-end;
  height: 68px;
  align-items: center;
  gap: 1rem;

  svg {
    cursor: pointer;
    font-size: 1rem;
  }
`;

export const EditPriceCategory: React.FC<Props> = ({
  readOnly,
  index,
  category,
  priceList,
  fetchPriceLists,
  fetchGroups,
  onEditCategory,
  onCancelEdit,
  onPriceChanged,
}) => {
  const intl = useIntl();
  const { toastError, toastSuccess } = useToaster();
  const localization = useFacilityLocalization();
  const { cf } = useCurrencyFormat(localization?.currencyCode);
  const [categoryCopy, setCategoryCopy] = useState(category);
  const [prices, setPrices] = useState<PriceListResponseWithUTC["prices"]>([]);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const standardGroup = useStandardGroup();
  const {
    facilities: { selectedFacility },
  } = useAppSelector(state => state);

  const onDeleteCategory = async () => {
    try {
      await deletePriceCategory(priceList.id, category.id);
      toastSuccess.categoryRemoved();
    } catch {
      toastError.removePricingFailed();
    } finally {
      await fetchPriceLists();
      await fetchGroups();
    }

    setShowConfirmationDialog(false);
  };

  const getNumberOfPlayersForCourt = () => {
    if (
      selectedFacility?.bookableEntityTypes?.find(
        x => x.id == priceList?.bookableEntityType,
      ).defaultNumberOfPlayers != 0
    )
      return selectedFacility?.bookableEntityTypes?.find(
        x => x.id == priceList?.bookableEntityType,
      ).defaultNumberOfPlayers;

    return 1;
  };

  useEffect(() => {
    if (!category) return;

    const priceDetails: PriceListResponseWithUTC["prices"] = [];

    const groups = priceList.categories.reduce(
      (acc, { groups }) => {
        groups.forEach(group => {
          if (!acc.find(({ id }) => id === group.id)) {
            acc.push({ id: group.id, name: group.name });
          }
        });

        return acc;
      },
      [standardGroup as PriceGroup],
    );

    priceList.categories.forEach((category, categoryIdx) => {
      groups.forEach((group, groupIdx) => {
        let price = priceList.prices.find(
          priceList =>
            priceList.categoryId === category.id &&
            ((group.id === GroupTypeStrings.STANDARD && !priceList.groupId) ||
              group.id === priceList.groupId),
        );

        if (!price) {
          price = {
            id: "not-set-yet_" + categoryIdx + "_" + groupIdx,
            price: 0,
            categoryId: category.id,
            groupId: group.id,
            name: group.name,
            priceExclVat: 0,
            vat: 0,
            vatPercentage: 6,
            isDefault: false,
          };
        }

        priceDetails.push(price);
      });
    });

    setPrices(priceDetails);
  }, [category]);

  return (
    <tr>
      <ColorColumn>
        <div
          style={{
            background:
              index < 5
                ? `hsl(218, 100%, ${89 - (index % 5) * 15}%)`
                : `repeating-linear-gradient( 180deg,
            hsl(218, 100%, ${89 - (index % 4) * 15}%),
            hsl(218, 100%, ${89 - (index % 4) * 15}%) 5px,
            hsl(218, 100%, ${89 - (Math.ceil(index / 2) % 5) * 15}%) 5px,
            hsl(218, 100%, ${89 - (Math.ceil(index / 2) % 5) * 15}%) 11px )`,
            borderRadius: "0.175rem",
            width: "1.5rem",
            height: "1.5rem",
          }}
        />
      </ColorColumn>
      <NameColumn>
        {readOnly ? (
          category.name
        ) : (
          <InputText
            key={category.id + "-name"}
            onChange={e =>
              setCategoryCopy({ ...categoryCopy, name: e.target.value })
            }
            defaultValue={categoryCopy?.name}
          />
        )}
      </NameColumn>
      <NameColumn>
        {readOnly ? (
          category.priority
        ) : (
          <InputText
            key={category.id + "-priority"}
            onChange={e => {
              setCategoryCopy({
                ...categoryCopy,
                priority: Number(e.target.value),
              });
            }}
            defaultValue={categoryCopy?.priority || 0}
          />
        )}
      </NameColumn>

      {prices
        .filter(x => x.categoryId === category.id)
        .map(price => (
          <PriceColumn key={price.id}>
            {readOnly ? (
              <span>
                {cf(price?.price / getNumberOfPlayersForCourt() ?? 0)}
              </span>
            ) : (
              <>
                {/* TOOD: check courtType and add * amout from courtType. Default fallback to 1 */}

                <InputNumber
                  mode="decimal"
                  minFractionDigits={2}
                  maxFractionDigits={2}
                  useGrouping={false}
                  value={price?.price / getNumberOfPlayersForCourt()}
                  onChange={e => {
                    const newPrices = [...prices];
                    const currentPrice = newPrices.find(x => x.id === price.id);

                    currentPrice.price = e.value * getNumberOfPlayersForCourt();
                    setPrices(newPrices);
                  }}
                />
              </>
            )}
          </PriceColumn>
        ))}
      <ActionIcon>
        {readOnly ? (
          <>
            <div style={{ width: "1rem" }}>
              <FontAwesomeIcon
                icon={faEdit}
                onClick={() => onEditCategory(category)}
              />
            </div>
            <div style={{ width: "1rem" }}>
              {!!index && (
                <FontAwesomeIcon
                  icon={faTrash}
                  onClick={() => setShowConfirmationDialog(true)}
                />
              )}
            </div>
          </>
        ) : (
          <>
            <div style={{ width: "1rem" }}>
              <FontAwesomeIcon
                icon={faCheck}
                onClick={async () => {
                  onPriceChanged(categoryCopy, prices);
                }}
              />
            </div>
            <div style={{ width: "1rem" }}>
              <FontAwesomeIcon
                icon={faTimes}
                onClick={() => {
                  onCancelEdit();
                }}
              />
            </div>
          </>
        )}
      </ActionIcon>
      <ConfirmationDialog
        visible={showConfirmationDialog}
        title={intl.formatMessage({
          id: "facility-settings.pricing.delete-category",
          defaultMessage: "Radera kategori",
        })}
        onCancel={() => setShowConfirmationDialog(false)}
        onSubmit={onDeleteCategory}
        text={intl.formatMessage({
          id: "facility-settings.pricing.delete-category.text",
          defaultMessage: "Är du säker att du vill radera kategorin?",
        })}
        loading={false}
        onHide={() => setShowConfirmationDialog(false)}
      />
    </tr>
  );
};
