import React, { useEffect, useMemo, useState } from "react";
import { FormattedMessage, MessageDescriptor } from "react-intl";

import { faClose } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

import { breakpoints } from "../../../../appConstants/common";

import { User } from "../../../player/models/User";

import { ClickableBox } from "./ClickableBox";
import { ScoreInput } from "./ScoreInput";
import { ScoreManagerRules } from "./ScoreManager";
import { Player } from "./teams/Player";

interface IMatchBoxProperties {
  label: MessageDescriptor;
  rules: ScoreManagerRules;
  readonly: boolean;
  initialMatch: Match;
  onValueChange: (match: Match) => void;
  onRemoveClick?: () => void;
  matchNumber: number;
}

interface ICellProps {
  column: string | number;
  row: string | number;
  justifySelf?: string;
  alignSelf?: string;
}

const BoxHeader = styled.div`
  display: flex;
  justify-content: space-between;
  height: 1rem;
`;
const Box = styled.div`
  flex-basis: calc(50% - (0.5rem / 2));
  flex-shrink: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;
const BoxLabel = styled.p`
  color: var(--gray-400);
  font-weight: var(--medium);
  font-size: var(--b5);
  pointer-events: none;
  user-select: none;

  @media (max-width: ${breakpoints.MOBILE}) {
    font-size: var(--b4);
  }
`;
const BoxLabelButton = styled.p`
  color: var(--danger);
  font-weight: var(--bold);
  font-size: var(--b5);
  user-select: none;
  cursor: pointer;

  @media (max-width: ${breakpoints.MOBILE}) {
    font-size: var(--b4);
  }
`;
const BoxContent = styled.div`
  flex: 1;
  border: 0.1rem solid var(--gray-200);
  border-radius: 0.3rem;
  padding: 1rem;

  @media (max-width: ${breakpoints.MOBILE}) {
    padding: 1rem 0 1rem 0;
  }
`;
const VersusField = styled.div`
  color: var(--gray-400);
  font-weight: var(--medium);
  font-size: var(--b4);
  user-select: none;

  @media (max-width: ${breakpoints.MOBILE}) {
    font-size: var(--b3);
  }
`;
const IconStyle = styled.div`
  color: var(--danger);
  cursor: pointer;
`;
const Grid = styled.div`
  display: grid;
  row-gap: 0.5rem;
  column-gap: 0.5rem;
  grid-template-columns:
    [gutter-l] 0.25fr repeat(2, [match-1-l] 1fr [match-1-r])
    [gutter-c] 0.5fr repeat(2, [match-2-l] 1fr [match-2-r])
    [gutter-r] 0.25fr;
`;

const Cell = ({
  column,
  row,
  children,
  justifySelf,
  alignSelf,
}: React.PropsWithChildren<ICellProps>) => (
  <div
    style={{
      gridColumn: column,
      gridRow: row,
      justifySelf: justifySelf ?? "center",
      alignSelf: alignSelf ?? "center",
    }}
  >
    {children}
  </div>
);

export interface Match {
  matchId: string;
  teamIds: string[];
  sets: MatchSet[];
  players: User[];
}
export const initMatch = (players: User[]): Match => ({
  matchId: null,
  teamIds: [],
  sets: [initSet()],
  players,
});

interface MatchSet {
  id: string;
  opponentId: string;
  score: number;
  opponentScore: number;
}
const initSet = (): MatchSet => ({
  id: null,
  opponentId: null,
  score: 0,
  opponentScore: 0,
});

export const MatchBox: React.FC<IMatchBoxProperties> = ({
  label,
  rules,
  readonly,
  initialMatch,
  matchNumber,
  onValueChange,
  onRemoveClick,
}) => {
  const scoreRowOffset = 4;
  const [sets, setSets] = useState<MatchSet[]>(initialMatch.sets);
  const [players, setPlayers] = useState<User[]>(initialMatch.players);
  const [isSelectingPlayer, setIsSelectingPlayer] = useState<boolean>(false);

  const canAddSet = useMemo(() => {
    return rules.maxSets == null || sets.length < rules.maxSets;
  }, [sets]);

  const canRemoveSets = useMemo(() => {
    return rules.minSets == null || sets.length > rules.minSets;
  }, [sets]);

  useEffect(() => {
    setSets(initialMatch.sets);
    setPlayers(initialMatch.players);
  }, [initialMatch]);

  const updateSet = (index: number, set: MatchSet) => {
    setSets(
      sets.reduce((acc, curr, ix) => {
        if (index == ix) {
          return [...acc, set];
        }
        return [...acc, curr];
      }, []),
    );
  };

  const addSet = () => {
    if (!canAddSet) {
      return;
    }
    setSets([...sets, initSet()]);
  };

  const removeSet = () => {
    if (!canRemoveSets) {
      return;
    }
    setSets(sets.slice(0, -1));
  };

  const swapPlayers = (player1: User, player2: User) => {
    if (rules.lockedTeams) {
      return;
    }

    setPlayers(
      players.reduce((acc, curr) => {
        if (curr == player1) {
          return [...acc, player2];
        }
        if (curr == player2) {
          return [...acc, player1];
        }
        return [...acc, curr];
      }, []),
    );
  };

  useEffect(() => {
    onValueChange({ ...initialMatch, sets, players });
  }, [sets, players]);

  const homeTeam = useMemo(
    () => players.slice(0, players.length > 2 ? 2 : 1),
    [players],
  );

  const awayTeam = useMemo(
    () => players.slice(players.length > 2 ? 2 : 1, players.length > 2 ? 4 : 3),
    [players],
  );

  return (
    <Box>
      <BoxHeader>
        <BoxLabel>
          <>
            <FormattedMessage
              id={label.id}
              description={label.description}
              defaultMessage={label.defaultMessage}
              values={{
                matchNumber,
              }}
            />
          </>
        </BoxLabel>
        {onRemoveClick ? (
          <BoxLabelButton onClick={onRemoveClick}>
            <FormattedMessage
              id="score.match.remove"
              defaultMessage="Ta bort match"
            />
          </BoxLabelButton>
        ) : (
          <></>
        )}
      </BoxHeader>
      <BoxContent>
        <Grid>
          {homeTeam.map((player, index) => (
            <PlayerEntry
              key={"player-entry-" + index}
              player={player}
              otherPlayers={players}
              teammates={homeTeam}
              index={index}
              teamIndex={1}
              onIsSelecting={isSelecting => setIsSelectingPlayer(isSelecting)}
              preventSelection={
                readonly ||
                rules.lockedTeams ||
                isSelectingPlayer ||
                players.length == 1
              }
              onSelection={selection => swapPlayers(player, selection)}
            />
          ))}
          <Cell column="gutter-c / gutter-c" row="1">
            <VersusField>
              <FormattedMessage id="common.versus.short" defaultMessage="vs" />
            </VersusField>
          </Cell>
          {awayTeam.map((player, index) => (
            <PlayerEntry
              key={"player-entry-opponent-" + index}
              player={player}
              otherPlayers={players}
              teammates={awayTeam}
              index={index}
              teamIndex={2}
              onIsSelecting={isSelecting => setIsSelectingPlayer(isSelecting)}
              preventSelection={
                readonly ||
                rules.lockedTeams ||
                isSelectingPlayer ||
                players.length == 1
              }
              onSelection={selection => swapPlayers(player, selection)}
            />
          ))}
          {awayTeam.length == 0 && (
            <Cell column="1 match-2-l / 2 match-2-r" row="1">
              <Player
                shadow
                size="3rem"
                user={{
                  id: null,
                  firstName: "?",
                }}
              ></Player>
            </Cell>
          )}

          {sets.map((set, index) => (
            <ScoreEntry
              key={"score-entry-" + index}
              canRemoveSets={canRemoveSets}
              index={index}
              offset={scoreRowOffset}
              readonly={readonly}
              set={set}
              setCount={sets.length}
              updateSet={updateSet}
              removeSet={removeSet}
            />
          ))}

          {canAddSet && (
            <Cell
              column="1 match-1-l / 2 match-2-r"
              row={scoreRowOffset + sets.length}
              justifySelf="stretch"
              alignSelf="stretch"
            >
              <ClickableBox
                label={{
                  id: "score.new-set",
                  defaultMessage: "Nytt set",
                  description: "Addng a new set",
                }}
                onClick={() => addSet()}
              />
            </Cell>
          )}
        </Grid>
      </BoxContent>
    </Box>
  );
};

interface IScoreEntryProperties {
  index: number;
  offset: number;
  readonly?: boolean;
  canRemoveSets: boolean;
  set: MatchSet;
  setCount: number;
  updateSet: (index: number, set: MatchSet) => void;
  removeSet: () => void;
}
const ScoreEntry: React.FC<IScoreEntryProperties> = ({
  index,
  offset,
  readonly = false,
  canRemoveSets,
  set,
  setCount,
  updateSet,
  removeSet,
}) => {
  return (
    <>
      <Cell
        key={"score" + index}
        column="1 match-1-l / 2 match-1-r"
        row={offset + index}
        justifySelf="stretch"
        alignSelf="stretch"
      >
        <ScoreInput
          readonly={readonly}
          value={set.score}
          onValueChange={value => updateSet(index, { ...set, score: value })}
        />
      </Cell>

      <Cell
        key={"opponentScore" + index}
        column="1 match-2-l / 2 match-2-r"
        row={offset + index}
        justifySelf="stretch"
        alignSelf="stretch"
      >
        <ScoreInput
          readonly={readonly}
          value={set.opponentScore}
          onValueChange={value =>
            updateSet(index, { ...set, opponentScore: value })
          }
        />
      </Cell>
      {!readonly && canRemoveSets && index != 0 && index == setCount - 1 ? (
        <Cell
          key={"removeSet" + index}
          column="gutter-r / gutter-r"
          row={offset + index}
          justifySelf="stretch"
          alignSelf="center"
        >
          <IconStyle>
            <FontAwesomeIcon
              size="sm"
              icon={faClose}
              onClick={() => removeSet()}
            />
          </IconStyle>
        </Cell>
      ) : (
        <></>
      )}
    </>
  );
};

const PlayerSelector = styled.div.attrs(
  (props: { x: number; y: number }) => props,
)`
  position: absolute;

  &.active {
    transform: translate(${props => props.x}rem, ${props => props.y}rem);
    opacity: 1;
  }

  &.inactive {
    transform: translate(0, 0);
    opacity: 0;
    pointer-events: none;
  }

  transition: all 0.25s linear;
`;

const Backdrop = styled.div`
  position: absolute;
  background: #ffffffdd;
  width: 100%;
  height: calc(100% - 25px);
  top: 25px;
  left: 0;
`;

interface IPlayerEntryProperties {
  index: number;
  teamIndex: 1 | 2;
  player: User;
  otherPlayers: User[];
  teammates: User[];
  preventSelection: boolean;
  onIsSelecting: (isSelecting: boolean) => void;
  onSelection: (selection: User) => void;
}
const PlayerEntry: React.FC<IPlayerEntryProperties> = ({
  index,
  teamIndex,
  player,
  otherPlayers,
  teammates,
  preventSelection,
  onIsSelecting,
  onSelection,
}) => {
  const [isSelecting, setSelecting] = useState<boolean>();
  const showSelect = () => {
    if (!isSelecting && preventSelection) return;

    setSelecting(!isSelecting);
  };

  useEffect(() => {
    onIsSelecting(isSelecting);
  }, [isSelecting]);

  const soloPlayer = useMemo(() => teammates.length == 1, [teammates]);

  const _polarToCartesian = (radius, angle) => ({
    x: radius * Math.cos(angle),
    y: radius * Math.sin(angle),
  });
  const _position = (index, radius, baseAngle, angleIndexOffset) =>
    _polarToCartesian(radius, baseAngle * (index + angleIndexOffset));
  const position = index => _position(index, 3.5, Math.PI / 4, -1.5);

  const isInCurrentTeam = (user: User) => {
    return teammates.some(teammate => teammate?.id == user?.id);
  };

  return (
    <>
      {isSelecting ? <Backdrop onClick={() => setSelecting(false)} /> : <></>}
      <Cell
        column={
          1 +
          index +
          " match-" +
          teamIndex +
          "-l / " +
          (soloPlayer ? 2 : 1 + index) +
          " match-" +
          teamIndex +
          "-r"
        }
        row="1"
      >
        <div
          style={{ position: isSelecting ? "relative" : "static" }}
          onClick={() => {
            showSelect();
          }}
        >
          <Player shadow size="3rem" user={player}></Player>
        </div>
        <div style={{ position: "relative", top: "-2.5rem", left: "0.5rem" }}>
          {otherPlayers?.map((playerSelector, index) => (
            <PlayerSelector
              key={"player-selector-" + playerSelector?.id}
              x={position(index).x}
              y={position(index).y}
              className={[isSelecting ? "active" : "inactive"].join(" ")}
              onClick={() => {
                if (isInCurrentTeam(playerSelector)) return;

                onSelection(playerSelector);
                setSelecting(false);
              }}
            >
              <Player
                size="2rem"
                disabled={isInCurrentTeam(playerSelector)}
                user={playerSelector}
              ></Player>
            </PlayerSelector>
          ))}
        </div>
      </Cell>
    </>
  );
};
