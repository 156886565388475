import { useCallback, useEffect, useState } from "react";

import useSWR from "swr";

import { FacilityWithUtc } from "../../modules/customer/models/Facility";
import { IActivity } from "../../modules/game/models/Activity";

import { getFacilityActivities } from "../../modules/game/services/Activities";

export const usePaginatedFacilityActivities = (
  facilityId?: FacilityWithUtc["id"] | null,
  isUpcoming?: boolean,
  pageSize = 20,
) => {
  const [activities, setActivities] = useState<IActivity[]>([]);
  const [nextCursor, setNextCursor] = useState<IActivity["id"]>("");
  const [isLoading, setIsLoading] = useState(false);

  const { data, ...swr } = useSWR(
    facilityId
      ? [
          "facilityActivities",
          {
            facilityId,
            isUpcoming: !!isUpcoming,
            pageSize,
          },
        ]
      : null,
    ([, params]) =>
      getFacilityActivities(params.facilityId, {
        isUpcoming: !!params.isUpcoming,
        pageSize: params.pageSize,
      }),
  );

  useEffect(() => {
    if (!data) {
      return;
    }

    // Only set next cursor when on the first "page"
    if (activities.length > 0) {
      return;
    }

    setNextCursor(data.nextCursor);
  }, [activities.length, data, nextCursor, pageSize]);

  const loadMore = useCallback(() => {
    if (!nextCursor || !facilityId) {
      return;
    }
    setIsLoading(true);
    getFacilityActivities(facilityId, {
      isUpcoming: !!isUpcoming,
      pageSize,
      cursor: nextCursor,
    })
      .then(res => {
        setActivities(prev => [...prev, ...res.data]);
        setNextCursor(res.nextCursor);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [facilityId, isUpcoming, nextCursor, pageSize]);

  useEffect(() => {
    setActivities([]);
  }, [facilityId, isUpcoming]);

  const reset = useCallback(() => {
    setNextCursor("");
    setActivities([]);
    swr.mutate();
  }, [swr]);

  return {
    isLoading: swr.isLoading,
    isLoadingMore: isLoading,
    nextCursor,
    activities:
      typeof data === "undefined" ? undefined : data.data.concat(activities),
    loadMore,
    reset,
  };
};
