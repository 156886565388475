import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";

import { faChevronRight, faCopy } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { getMyProfilePath } from "../../../../helpers/pathHelpers";

import { useInvitationLink } from "../../../../hooks/booking/useInvitationLink";
import { useToaster } from "../../../../hooks/common/useToaster";
import { useGames } from "../../../../hooks/swr/useGames";

import { Button } from "../../../../components/Button";
import { Card } from "../../../../components/Card";
import { Dialog } from "../../../../components/Dialog";
import { NothingToShow } from "../../../../components/NothingToShow";
import { ProgressSpinner } from "../../../../components/ProgressSpinner";
import { ScoreManager } from "../../../checkout/components/Games/ScoreManager";
import { UnsavedChangesDialog } from "../../../checkout/components/Games/UnsavedChangesDialog";

import { userProfileTabs } from "../../../player/constants";
import { GameContent } from "./GameContent";

interface Props {
  isUpcoming?: boolean;
  nrOfGames?: number;
}

export const GamesCard = ({ isUpcoming = false, nrOfGames = 2 }: Props) => {
  const { toastError, toastSuccess } = useToaster();
  const [matchId, setMatchid] = useState("");
  const [hasScoresUnsaved, setScoresUnsaved] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [externalServiceId, setExternalServiceId] = useState<string>();
  const [showInviteDialog, setShowInviteDialog] = useState(false);
  const invitationLink = useInvitationLink(externalServiceId ?? "");
  const history = useHistory();

  const { games, isLoading, mutate } = useGames(isUpcoming, 0, nrOfGames + 1);

  useEffect(() => {
    if (!showDialog) setShowConfirmationDialog(false);
  }, [showDialog]);

  const handleEditOpen = async (matchId: string) => {
    setMatchid(matchId);
    setShowDialog(true);
  };

  const handleInviteFriendsClick = (
    isShow: boolean,
    externalServiceId: string,
  ) => {
    setShowInviteDialog(isShow);
    setExternalServiceId(externalServiceId);
  };

  const handleGoSetting = () => {
    history.push(`${getMyProfilePath()}/${userProfileTabs.UPCOMPING_BOOKINGS}`);
  };

  const handleCopyInviteLink = async () => {
    try {
      if (invitationLink) {
        const link = window.location.origin + "/#" + invitationLink;

        await navigator.clipboard.writeText(link);
        toastSuccess.linkCopied();
      }
    } catch (err) {
      toastError.generateInvitationLinkFailed();
    }
  };

  return (
    <Card>
      {isUpcoming ? (
        <h3>
          <FormattedMessage id="profile.upcomingmatches.label" />
        </h3>
      ) : (
        <h3>
          <FormattedMessage id="profile.palyedmatches.label" />
        </h3>
      )}

      {isLoading ? (
        <ProgressSpinner />
      ) : (
        <>
          {!games?.length ? (
            <NothingToShow
              translationId={
                isUpcoming
                  ? "profile-settings.no-upcoming-bookings"
                  : "profile-settings.no-player-games"
              }
            />
          ) : (
            <>
              <div className="space-y-4">
                {games.slice(0, nrOfGames).map((game, key) => (
                  <GameContent
                    showUpcoming={isUpcoming}
                    game={game}
                    key={key}
                    handleInviteFriend={handleInviteFriendsClick}
                    handleTeams={gameId => {
                      setShowDialog(true);
                      handleEditOpen(gameId);
                    }}
                    handleReschedule={(facilityId, bookingId) => {
                      history?.push(`/reschedule/${facilityId}/${bookingId}`);
                    }}
                    refreshData={() => mutate()}
                  />
                ))}
              </div>

              {games?.length > nrOfGames && isUpcoming && (
                <button
                  className="flex items-center justify-end gap-1 font-bold text-primary hover:text-primary-light"
                  onClick={handleGoSetting}
                >
                  <FormattedMessage id="bookings.show-all" />
                  <FontAwesomeIcon size="sm" icon={faChevronRight} />
                </button>
              )}

              {showInviteDialog && (
                <Dialog onHide={() => setShowInviteDialog(false)} visible>
                  <h4 className="mb-4">
                    <FormattedMessage id="booking-info.invitation-label" />
                  </h4>
                  <div className="flex">
                    {invitationLink && (
                      <input
                        className="w-full max-w-80 rounded-l border border-gray-400 p-3 opacity-50"
                        type="text"
                        value={window.location.origin + "/#" + invitationLink}
                      />
                    )}
                    <Button
                      className="cursor-copy rounded-l-none"
                      icon={faCopy}
                      buttonType="button"
                      type="primary"
                      onClick={handleCopyInviteLink}
                    />
                  </div>
                </Dialog>
              )}

              <Dialog
                onWillHide={() => {
                  if (hasScoresUnsaved) {
                    setShowConfirmationDialog(true);
                  }
                  return !hasScoresUnsaved;
                }}
                onHide={() => {
                  setShowDialog(false);
                }}
                visible={showDialog}
              >
                <ScoreManager
                  matchId={matchId}
                  onHasUnsavedChanges={hasUnsavedChanges =>
                    setScoresUnsaved(hasUnsavedChanges)
                  }
                  onLocked={() => mutate()}
                />
              </Dialog>

              {showConfirmationDialog && (
                <UnsavedChangesDialog
                  onHide={() => setShowConfirmationDialog(false)}
                  visible
                  onAbortClick={() => setShowDialog(false)}
                  onCancelClick={() => setShowConfirmationDialog(false)}
                />
              )}
            </>
          )}
        </>
      )}
    </Card>
  );
};
