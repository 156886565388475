import React from "react";

import styled from "styled-components";

import { Teams } from "../modules/checkout/models/Games";

import { ProfileImageWithFallback } from "./ProfileImageWithFallback";

const StyledAvatarNameWrapper = styled.div`
  display: flex;
  align-items: center;

  & > div {
    border: 1px solid rgba(0, 0, 0, 1);
  }

  & > div:not(:first-child) {
    margin-left: -15px;
  }

  img {
    width: 3rem !important;
    height: 3rem !important;
    border-radius: 50%;
  }
`;

interface Props {
  team: Teams;
}

export const TeamAvatar: React.FC<Props> = ({ team }) => (
  <StyledAvatarNameWrapper>
    {team?.teamMembers?.map(user => (
      <ProfileImageWithFallback
        key={user?.id}
        firstName={user?.firstName}
        lastName={user?.lastName}
        src={user?.profileImage}
      />
    ))}
  </StyledAvatarNameWrapper>
);
