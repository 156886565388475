import useSWR, { type SWRConfiguration } from "swr";

import type { Series, SeriesMatch } from "../../modules/game/models/Series";

import { getSeriesMatch } from "../../modules/game/services/Serie";

export const useSeriesMatchInfo = (
  seriesId?: Series["id"],
  matchId?: SeriesMatch["id"],
  options?: SWRConfiguration,
) => {
  const { data, ...rest } = useSWR(
    seriesId && matchId ? ["seriesMatchInfo", seriesId, matchId] : null,
    ([, seriesId, matchId]) =>
      getSeriesMatch(seriesId, matchId).then(res => res?.data),
    options,
  );

  return {
    ...rest,
    matchInfo: data,
  };
};
