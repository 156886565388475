import {
  type ComponentProps,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { FormattedMessage } from "react-intl";

import type { SwedbankPayOrderPaymentResponse } from "../../../../models/payment";

import { useToaster } from "../../../../hooks/common/useToaster";

import { completeOrderPayment } from "../../../../services/myOrdersService";

import { ProgressSpinner } from "../../../../components/ProgressSpinner";
import { SwedbankPayCheckout } from "../../../../components/SwedbankPayCheckout";

import { useCheckout } from "../../context/CheckoutContext";

export const SwedbankPayPayment = ({
  createOrderPayment,
}: {
  createOrderPayment: () => Promise<SwedbankPayOrderPaymentResponse>;
}) => {
  const {
    state: { order, selectedPaymentMethod },
    dispatch,
  } = useCheckout();
  const { toastError } = useToaster();

  const [isLoading, setIsLoading] = useState(true);
  const [orderPaymentResponse, setOrderPaymentResponse] =
    useState<SwedbankPayOrderPaymentResponse>();

  useEffect(() => {
    createOrderPayment()
      .then(data => {
        setOrderPaymentResponse(data);
      })
      .catch(error => {
        console.log("createOrderPayment error");
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [createOrderPayment]);

  const onPaid = useCallback(async () => {
    if (!orderPaymentResponse?.orderPaymentId) {
      return;
    }

    dispatch({
      type: "SET_CHECKOUT_STATUS",
      status: "paymentPending",
    });

    try {
      await completeOrderPayment(
        order.id,
        orderPaymentResponse?.orderPaymentId,
      );

      dispatch({
        type: "SET_CHECKOUT_STATUS",
        status: "paymentCompleted",
      });
    } catch (error) {
      console.error(error);
      toastError.unknown();
    }
  }, [dispatch, order.id, orderPaymentResponse?.orderPaymentId, toastError]);

  const checkoutConfiguration = useMemo(
    () =>
      ({
        onPaid,
        onAborted: () => {
          dispatch({
            type: "SET_SELECTED_PAYMENT_METHOD",
            paymentMethod: null,
          });
        },
        onError: (event: any) => {
          console.error(event);

          dispatch({
            type: "SET_CHECKOUT_STATUS",
            status: "idle",
          });
        },
      }) satisfies ComponentProps<typeof SwedbankPayCheckout>["configuration"],
    [dispatch, onPaid],
  );

  const onScriptLoad = useCallback(() => {
    setIsLoading(false);
  }, []);

  if (!isLoading && !orderPaymentResponse) {
    return (
      <p className="text-center text-error">
        <FormattedMessage id="toast.error.update.generic" />
      </p>
    );
  }

  if (isLoading) {
    return <ProgressSpinner />;
  }

  if (!orderPaymentResponse) {
    return null;
  }

  return (
    <SwedbankPayCheckout
      scriptUrl={orderPaymentResponse.scriptUrl}
      onScriptLoad={onScriptLoad}
      configuration={checkoutConfiguration}
      paymentMethod={selectedPaymentMethod?.instrument}
    />
  );
};
