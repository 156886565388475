import { useIntl } from "react-intl";

import { array, object, string } from "yup";

export const useFormValidationSchema = () => {
  const intl = useIntl();

  const customerEmailSchema = object().shape({
    usersList: array().of(
      string().required(
        intl.formatMessage({ id: "validation.required.usersList" }),
      ),
    ),
    content: string().required(
      intl.formatMessage({ id: "validation.required.content" }),
    ),
    subject: string().required(
      intl.formatMessage({ id: "validation.required.subject" }),
    ),
  });

  return {
    customerEmailSchema,
  };
};
