import { FormattedMessage, useIntl } from "react-intl";

import { useFacility } from "../../../hooks/swr/useFacility";

import { CheckoutViewHeading } from "../CheckoutView";
import { useCheckoutState } from "../context/CheckoutContext";
import { OrderDetails } from "./OrderDetails";

export const MembershipOrderDetails = () => {
  const intl = useIntl();

  const state = useCheckoutState();

  const { facility } = useFacility(state.order.facilityId);

  if (state.orderType !== "membership") {
    return null;
  }

  const { membership } = state;

  const orderDetails = [
    {
      heading: intl.formatMessage({
        id: "common.serie",
      }),
      text: membership.name,
    },
    {
      heading: intl.formatMessage({
        id: "common.venue",
      }),
      text: facility?.name ?? "",
    },
  ];

  if (facility?.shortMessage) {
    orderDetails.push({
      heading: intl.formatMessage({
        id: "common.facility.info",
      }),
      text: facility?.shortMessage ?? "",
    });
  }

  return (
    <div>
      <CheckoutViewHeading>
        <FormattedMessage id="checkout.section.order-details.heading" />
      </CheckoutViewHeading>

      <OrderDetails orderDetails={orderDetails} />
    </div>
  );
};
