import { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";

import _, { debounce } from "lodash";
import { DateTime } from "luxon";
import { useRecoilValue } from "recoil";

import { DateOnly } from "../../../models/DateOnly";
import { UserRole } from "../../../models/Permissions";
import {
  AdminStatisticsWithUtc,
  FilterOptions,
  selectedOption,
} from "../../../modules/admin/models/Statistics";
import { StatisticsType } from "../../../modules/customer/models/AdminStatistics";

import { useSelectedFacility } from "../../../hooks/swr/useSelectedFacility";

import { getEarningsAndOccupancy } from "../../../modules/customer/services/CustomerService";

import { userPermissionsState } from "../../../recoil/Customer/userPermissionsState";

const currentDate = DateOnly.today();

const thisWeekStartDate = DateOnly.fromDateTime(
  DateTime.now().startOf("week").startOf("day"),
);

const thisWeekEndDate = DateOnly.fromDateTime(
  thisWeekStartDate.toDateTime().endOf("week"),
);

const lastWeekStartDate = DateOnly.fromDateTime(
  currentDate.minus({ weeks: 1 }).toDateTime().startOf("week"),
);

const lastWeekEndDate = DateOnly.fromDateTime(
  lastWeekStartDate.toDateTime().endOf("week"),
);

const thisMonthStartDate = DateOnly.fromDateTime(
  currentDate.toDateTime().startOf("month"),
);

const thisMonthEndDate = DateOnly.fromDateTime(
  currentDate.toDateTime().endOf("month"),
);

const lastMonthStartDate = DateOnly.fromDateTime(
  currentDate.minus({ months: 1 }).toDateTime().startOf("month"),
);

const lastMonthEndDate = DateOnly.fromDateTime(
  lastMonthStartDate.toDateTime().endOf("month"),
);

interface UseVenueStatistics {
  timeDurationOptions: selectedOption[];
  isStatsLoading: boolean;
  sportTypes: FilterOptions[];
  courtTypes: FilterOptions[];
  courts: FilterOptions[];
  setSportTypes: (arg: FilterOptions[]) => void;
  setCourtTypes: (arg: FilterOptions[]) => void;
  setCourts: (arg: FilterOptions[]) => void;
  option: selectedOption;
  setOption: (arg: selectedOption) => void;
  setIsCalendarSelection: (arg: boolean) => void;
  startDate: DateOnly;
  endDate: DateOnly;
  setStartDate: (date: DateOnly) => void;
  setEndDate: (date: DateOnly) => void;
  selectedSportTypes: FilterOptions[];
  selectedCourtTypes: FilterOptions[];
  selectedCourts: FilterOptions[];
  setSelectedSportTypes: (arg: FilterOptions[]) => void;
  setSelectedCourtTypes: (arg: FilterOptions[]) => void;
  setSelectedCourts: (arg: FilterOptions[]) => void;
  adminStatistics: AdminStatisticsWithUtc;
}

export const useVenueStatistics = (): UseVenueStatistics => {
  const intl = useIntl();
  const { selectedFacility: facility } = useSelectedFacility();
  const timeDurationOptions: selectedOption[] = useMemo(() => {
    const thisWeekStartsToday = thisWeekStartDate
      .toDateTime()
      .hasSame(currentDate.toDateTime(), "day");
    const thisMonthStartToday = thisMonthStartDate
      .toDateTime()
      .hasSame(currentDate.toDateTime(), "day");

    return [
      {
        label: intl.formatMessage({
          id: "admin.statistics.timeDurationOptions.lastWeek",
        }),
        value: "lastWeek",
        id: 1,
        disable: false,
      },
      {
        label: intl.formatMessage({
          id: "admin.statistics.timeDurationOptions.thisWeek",
        }),
        value: "thisWeek",
        id: 2,
        disable: thisWeekStartsToday,
      },
      {
        label: intl.formatMessage({
          id: "admin.statistics.timeDurationOptions.thisMonth",
        }),
        value: "thisMonth",
        id: 3,
        disable: thisMonthStartToday,
      },
      {
        label: intl.formatMessage({
          id: "admin.statistics.timeDurationOptions.lastMonth",
        }),
        value: "lastMonth",
        id: 4,
        disable: false,
      },
      {
        label: intl.formatMessage({
          id: "admin.statistics.timeDurationOptions.specificTimePeriod",
        }),
        value: "specificTimePeriod",
        id: 5,
        disable: false,
      },
    ];
  }, [intl]);

  const [adminStatistics, setAdminStatistics] =
    useState<AdminStatisticsWithUtc>();
  const [isStatsLoading, setIsStatsLoading] = useState<boolean>(false);
  const [sportTypes, setSportTypes] = useState<FilterOptions[]>([]);
  const [courtTypes, setCourtTypes] = useState<FilterOptions[]>([]);
  const [courts, setCourts] = useState<FilterOptions[]>([]);
  const [option, setOption] = useState<selectedOption>(timeDurationOptions[0]);
  const [isCalendarSelection, setIsCalendarSelection] =
    useState<boolean>(false);
  const [selectedSportTypes, setSelectedSportTypes] = useState<FilterOptions[]>(
    [],
  );
  const [selectedCourtTypes, setSelectedCourtTypes] = useState<FilterOptions[]>(
    [],
  );
  const [selectedCourts, setSelectedCourts] = useState<FilterOptions[]>([]);
  const userPermissions = useRecoilValue(userPermissionsState);

  const [startDate, setStartDate] = useState<DateOnly>(lastWeekStartDate);
  const [endDate, setEndDate] = useState<DateOnly>(lastWeekEndDate);
  const [statisticsType, setStatisticsType] = useState<StatisticsType>(
    StatisticsType.Day,
  );

  useEffect(() => {
    const obj = facility?.bookableEntityTypes?.reduce((acc, curr) => {
      const { sportType, id } = curr;
      return {
        ...acc,
        [sportType.id]: acc[sportType.id]
          ? {
              ...acc[sportType.id],
              bookableEntityTypeIds: [
                ...acc[sportType.id].bookableEntityTypeIds,
                id,
              ],
            }
          : { ...sportType, bookableEntityTypeIds: [id] },
      };
    }, {});

    const result = _.values(obj);

    setSportTypes(result);
  }, [facility, intl]);

  useEffect(() => {
    const allCourtTypes = facility?.bookableEntityTypes ?? [];

    const courtTypes = allCourtTypes
      .filter(ct =>
        selectedSportTypes?.length > 0
          ? selectedSportTypes?.some(st =>
              st.bookableEntityTypeIds.includes(ct.id),
            )
          : true,
      )
      .map(i => ({
        id: i.id,
        name: i.name,
      }));

    setCourtTypes(courtTypes);

    setSelectedCourtTypes(
      courtTypes.filter(ct => selectedCourtTypes.find(sct => sct.id === ct.id)),
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSportTypes, facility, intl]);

  useEffect(() => {
    const allCourts = facility?.bookableEntities ?? [];

    const courts = allCourts
      .filter(c =>
        courtTypes?.length > 0
          ? courtTypes.some(ct => ct.id === c.bookableType)
          : true,
      )
      .filter(c =>
        selectedCourtTypes?.length > 0
          ? selectedCourtTypes?.some(ct => ct.id === c.bookableType)
          : true,
      )
      .map(i => ({
        id: i.id,
        name: i.name,
      }));

    setCourts(courts);

    setSelectedCourts(
      courts.filter(c => selectedCourts.find(sc => sc.id === c.id)),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCourtTypes, courtTypes, facility, intl]);

  const isFacilityOwner = useMemo(() => {
    if (userPermissions?.find(p => p.role === UserRole.Owner) === undefined)
      return false;
    return userPermissions
      ?.find(p => p.role === UserRole.Owner)
      .facilities?.includes(facility?.id);
  }, [facility, userPermissions]);

  const isSuperAdmin = useMemo(() => {
    return Boolean(userPermissions?.find(p => p.role === UserRole.SuperAdmin));
  }, [userPermissions, facility]);

  useEffect(() => {
    if (!isFacilityOwner && !isSuperAdmin) return;
    !isCalendarSelection && setDateDurations();
  }, [option]);

  const startDateIso = startDate.toISO();
  const endDateIso = endDate.toISO();

  useEffect(() => {
    setIsStatsLoading(true);
    debounced(() => getAdminStatisticsData());
  }, [
    facility?.id,
    startDateIso,
    endDateIso,
    selectedSportTypes,
    selectedCourtTypes,
    selectedCourts,
  ]);

  const setDateDurations = () => {
    switch (option?.value) {
      case "thisWeek": {
        setStartDate(thisWeekStartDate);
        setEndDate(thisWeekEndDate);
        setStatisticsType(StatisticsType.Week);
        break;
      }
      case "lastWeek": {
        setStartDate(lastWeekStartDate);
        setEndDate(lastWeekEndDate);
        setStatisticsType(StatisticsType.Week);
        break;
      }
      case "thisMonth": {
        setStartDate(thisMonthStartDate);
        setEndDate(thisMonthEndDate);
        setStatisticsType(StatisticsType.Month);
        break;
      }
      case "lastMonth": {
        setStartDate(lastMonthStartDate);
        setEndDate(lastMonthEndDate);
        setStatisticsType(StatisticsType.Month);
        break;
      }
      default: {
        setStatisticsType(StatisticsType.Day);
        break;
      }
    }
  };

  const sportTypeIds = useMemo(() => {
    return selectedSportTypes?.map(i => i.id);
  }, [selectedSportTypes]);

  const getAdminStatisticsData = async () => {
    const abortController = new AbortController();

    try {
      setIsStatsLoading(true);

      const res = await getEarningsAndOccupancy({
        facilityId: facility?.id,
        startDate,
        endDate,
        sportTypeIds: sportTypeIds?.length ? sportTypeIds : null,
        entityTypeIds: selectedCourtTypes?.length
          ? selectedCourtTypes.map(st => st.id)
          : null,
        entityIds: selectedCourts?.length
          ? selectedCourts.map(c => c.id)
          : null,
        statisticsType,
      });

      if (abortController.signal.aborted) return;

      setIsStatsLoading(false);
      setAdminStatistics(res?.data);
    } catch (e) {
      if (abortController.signal.aborted) return;

      console.log(e);
    }
  };
  const debounced = useMemo(() => debounce(cb => cb(), 500), []);

  return {
    timeDurationOptions,
    adminStatistics,
    isStatsLoading,
    sportTypes,
    courtTypes,
    courts,
    option,
    startDate,
    endDate,
    selectedSportTypes,
    selectedCourtTypes,
    selectedCourts,
    setIsCalendarSelection,
    setOption,
    setSportTypes,
    setCourtTypes,
    setCourts,
    setStartDate,
    setEndDate,
    setSelectedSportTypes,
    setSelectedCourtTypes,
    setSelectedCourts,
  };
};
