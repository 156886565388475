import React, { useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { useFormik } from "formik";
import styled from "styled-components";

import { breakpoints } from "../../../../appConstants/common";

import {
  FacilityCreatePaymentInfoRequest,
  PaymentInformation,
} from "../../../../modules/customer/models/Facility";

import { useToaster } from "../../../../hooks/common/useToaster";
import { useSelectedFacility } from "../../../../hooks/swr/useSelectedFacility";
import { useFormValidationSchema } from "../../../../modules/customer/hooks/useFormValidationSchema";
import { useRefetchFacilityInformation } from "../../../../modules/customer/hooks/useRefetchFacilityInformation";

import { updatePaymentInfo } from "../../../../modules/customer/services/FacilityService";

import { TextInput } from "../../../../components/TextInput";
import { BlockContainer } from "../../../../modules/customer/components/BlockContainer";
import { FacilitySubmitResetButtons } from "../../../../modules/customer/components/FacilitySubmitResetButtons";
import { FacilitySettlementReports } from "./components/FacilitySettlementReports";

import { useSelectedFacilityId } from "../../../../recoil/selectedFacilityIdState";

const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  input {
    width: 100%;
  }

  @media (min-width: ${breakpoints.XL}) {
    width: 33%;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const FacilityPayment: React.FC = () => {
  const intl = useIntl();
  const { toastSuccess, toastError } = useToaster();
  const { facilityPaymentInfoSchema } = useFormValidationSchema();
  const { facilityUpdatedCallback } = useRefetchFacilityInformation();
  const [resetClicked, setResetClicked] = useState(false);
  const selectedFacilityId = useSelectedFacilityId();
  const { selectedFacility } = useSelectedFacility();
  const updatePaymentAbortController = useRef<AbortController>();

  const formik = useFormik<PaymentInformation>({
    enableReinitialize: true,
    initialValues: {
      invoiceEmail: selectedFacility?.paymentInformation?.invoiceEmail ?? "",
      registrationNumber:
        selectedFacility?.paymentInformation?.registrationNumber ?? "",
      plusgiro: selectedFacility?.paymentInformation?.plusgiro ?? "",
      bankgiro: selectedFacility?.paymentInformation?.bankgiro ?? "",
      costCenter: selectedFacility?.paymentInformation?.costCenter ?? "",
    },
    validationSchema: facilityPaymentInfoSchema,

    onSubmit: async (data: FacilityCreatePaymentInfoRequest) => {
      return facilityUpdatedCallback(async () => {
        updatePaymentAbortController.current = new AbortController();

        try {
          await updatePaymentInfo(
            selectedFacilityId,
            data,
            updatePaymentAbortController.current?.signal,
          );
          toastSuccess.infoSaved();
          return true;
        } catch (e) {
          if (!updatePaymentAbortController.current?.signal.aborted)
            e.hasValidationErrors
              ? toastError.validation()
              : toastError.saveFailed();
        }
      });
    },
  });

  return (
    <BlockContainer $isFirst>
      <h3>
        <FormattedMessage
          id="facility-settings.invoice-details"
          defaultMessage="Fakturauppgifter"
        />
      </h3>
      <Form onSubmit={formik.handleSubmit}>
        <InputsContainer key={resetClicked.toString()}>
          <div>
            <TextInput
              required
              name="invoiceEmail"
              label={intl.formatMessage({
                id: "facility-settings.bookable.invoice-email",
              })}
              value={formik.values.invoiceEmail ?? ""}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              error={formik.errors.invoiceEmail}
            />
          </div>

          <div>
            <TextInput
              required
              name="registrationNumber"
              label={intl.formatMessage({ id: "common.org-nr" })}
              value={formik.values.registrationNumber ?? ""}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              error={formik.errors.registrationNumber}
            />
          </div>

          {/* TODO: These fields will be added later*/}
          {/*<SplitRow>*/}
          {/*  <div>*/}
          {/*    <TextInput*/}
          {/*      max={9}*/}
          {/*      name="plusgiro"*/}
          {/*      label={intl.formatMessage({ id: "common.pg" })}*/}
          {/*      defaultValue={formik.values.plusgiro ?? ""}*/}
          {/*      onBlur={formik.handleBlur}*/}
          {/*      onChange={formik.handleChange}*/}
          {/*      className={clsx({*/}
          {/*        "p-invalid": isFormFieldInvalid("plusgiro"),*/}
          {/*      })}*/}
          {/*    />*/}
          {/*    {getFormErrorMessage("plusgiro")}*/}
          {/*  </div>*/}
          {/*  <div>*/}
          {/*    <TextInput*/}
          {/*      max={9}*/}
          {/*      name="bankgiro"*/}
          {/*      label={intl.formatMessage({ id: "common.bg" })}*/}
          {/*      defaultValue={formik.values.bankgiro ?? ""}*/}
          {/*      onBlur={formik.handleBlur}*/}
          {/*      onChange={formik.handleChange}*/}
          {/*      className={clsx({*/}
          {/*        "p-invalid": isFormFieldInvalid("bankgiro"),*/}
          {/*      })}*/}
          {/*    />*/}
          {/*    {getFormErrorMessage("bankgiro")}*/}
          {/*  </div>*/}
          {/*</SplitRow>*/}
        </InputsContainer>

        <FacilitySubmitResetButtons
          onReset={() => {
            formik.resetForm();
            setResetClicked(resetClicked => !resetClicked);
          }}
          disabled={!formik.dirty}
        />

        <FacilitySettlementReports />
      </Form>
    </BlockContainer>
  );
};
