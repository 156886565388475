import { faMoneyBillWave } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";

export const IsPaidBadge = ({ className }: { className?: string }) => (
  <div
    className={clsx(
      "rounded-full bg-gradient-to-b from-purewhite to-purewhite/50 px-[2px] pt-px text-green-700",
      className,
    )}
  >
    <FontAwesomeIcon icon={faMoneyBillWave} />
  </div>
);
