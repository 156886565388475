import React, { useEffect, useState } from "react";
import { Redirect, useParams } from "react-router-dom";

import SimpleCrypto from "simple-crypto-js";

import { useBooking } from "../hooks/swr/useBooking";
import { useIsAuthenticated } from "../hooks/swr/useIsAuthenticated";

import BookingInvitation from "../modules/checkout/components/Games/BookingInvitation";

import { SignInRedirectPage } from "./SignInRedirect";

interface InvitationParams {
  bookingIdHash: string;
}

export const BookingInvitationPage: React.FC = () => {
  const { bookingIdHash } = useParams<InvitationParams>();
  const [bookingId, setBookingId] = useState("");
  const isAuthenticated = useIsAuthenticated();

  const { booking, isLoading, mutate } = useBooking(
    bookingId,
    "participants,facility,court",
  );

  useEffect(() => {
    window.location.href = `court22://booking/invitation/${bookingIdHash}`;
    const simpleCrypto = new SimpleCrypto(
      process.env.REACT_APP_SIMPLE_CRYPTO_KEY,
    );

    const bookingId = simpleCrypto
      .decrypt(decodeURIComponent(bookingIdHash))
      .toString();

    setBookingId(bookingId);
  }, [bookingIdHash]);

  if (!isAuthenticated) {
    return <SignInRedirectPage />;
  }

  if (bookingId && !isLoading && !booking) {
    return <Redirect to="/not-found" />;
  }

  return (
    <>
      {booking && (
        <BookingInvitation booking={booking} isLoggedIn onSubmit={mutate} />
      )}
    </>
  );
};
