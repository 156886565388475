import { DateOnly } from "../../../models/DateOnly";

export interface AdminStatisticsDataRequestWithUtc {
  facilityId: string;
  startDate: DateOnly;
  endDate: DateOnly;
  sportTypeIds?: string[];
  entityTypeIds?: string[];
  entityIds?: string[];
  paymentMethods?: string;
  bookingTypes?: string;
  statisticsType?: StatisticsType;
}

export enum StatisticsType {
  Day = 1,
  DayOfWeek = 2,
  Week = 3,
  Month = 4,
  Year = 5,
}
