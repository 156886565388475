import { useEffect } from "react";
import { Link } from "react-router-dom";

import { faBars, faClose } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AnimatePresence } from "framer-motion";
import { atom, useRecoilState, useSetRecoilState } from "recoil";

import { Logo } from "../Logo";
import { LogoSmall } from "../LogoSmall";
import { Menu } from "./Menu/Menu";
import { MobileMenu } from "./Menu/MobileMenu";
import { Player } from "./Player";
import { Search } from "./Search/Search";
import { Toolbar } from "./Toolbar";

export const isHeaderSearchOpenState = atom({
  key: "isHeaderSearchOpenState",
  default: false,
});

export const isMobileMenuOpenState = atom({
  key: "isMobileMenuOpenState",
  default: false,
});

export const isMobileLanguagePickerOpenState = atom({
  key: "isMobileLanguagePickerOpenState",
  default: false,
});

export const Header = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useRecoilState(
    isMobileMenuOpenState,
  );
  const setMobileLanguagePickerOpen = useSetRecoilState(
    isMobileLanguagePickerOpenState,
  );

  useEffect(() => {
    if (isMobileMenuOpen) {
      setMobileLanguagePickerOpen(false);
    }
  }, [setMobileLanguagePickerOpen, isMobileMenuOpen]);

  return (
    <header className="container fixed top-0 z-[999] flex h-16 w-full justify-between rounded-b border-b bg-purewhite min-[850px]:gap-4 lg:h-[110px]">
      <div className="flex flex-1 min-[850px]:divide-x">
        <Link
          to="/"
          className="group flex flex-shrink justify-center px-4 lg:basis-56"
          onClick={() => setMobileMenuOpen(false)}
        >
          <Logo className="hidden w-28 transition-colors group-hover:fill-primary min-[850px]:block lg:w-36" />
          <LogoSmall className="block w-10 min-[850px]:hidden" />
        </Link>
        <div className="relative flex min-[850px]:flex-1">
          <Menu />
          <Search />
        </div>
      </div>

      <div className="flex items-center pr-4">
        <div className="hidden items-center gap-4 py-1 min-[850px]:flex lg:gap-10 lg:py-0 lg:pr-10">
          <Toolbar />
          <Player />
        </div>
        <div
          className="min-[850px]:hidden"
          onClick={() => setMobileMenuOpen(o => !o)}
        >
          <FontAwesomeIcon
            icon={isMobileMenuOpen ? faClose : faBars}
            className="h-6 text-primary-dark"
          />
        </div>
        <AnimatePresence>{isMobileMenuOpen && <MobileMenu />}</AnimatePresence>
      </div>
    </header>
  );
};
