import type { PaymentMethod } from "./payment";

export interface SwedbankPayCheckoutConfiguration {
  container: string;
  onPaid?: (event: SwedbankPayCheckoutOnPaidEvent) => void;
  onAborted?: (event: SwedbankPayCheckoutOnAbortedEvent) => void;
  onOutOfViewRedirect?: (
    event: SwedbankPayCheckoutOnOutOfViewRedirectEvent,
  ) => void;
  onPaymentAttemptAborted?: (
    event: SwedbankPayCheckoutOnPaymentAttemptAbortedEvent,
  ) => void;
  onPaymentAttemptFailed?: (
    event: SwedbankPayCheckoutOnPaymentAttemptFailedEvent,
  ) => void;
  onPaymentAttemptStarted?: (
    event: SwedbankPayCheckoutOnPaymentAttemptStartedEvent,
  ) => void;
  onError?: (event: unknown) => void;
  culture?: string;
}

export interface SwedbankPayCheckoutInstance {
  open: () => void;
  close: () => void;
  update: (config: SwedbankPayCheckoutConfiguration) => void;
  refresh: () => void;
  cancel: () => void;
}

interface SwedbankPayCheckoutEvent<T extends string> {
  event: T;
  paymentOrder: { id: string };
}

interface SwedbankPayCheckoutOnPaidEvent
  extends SwedbankPayCheckoutEvent<"OnPaid"> {
  redirectUrl: string;
}

interface SwedbankPayCheckoutOnAbortedEvent
  extends SwedbankPayCheckoutEvent<"OnAborted"> {
  redirectUrl: string;
}

interface SwedbankPayCheckoutOnOutOfViewRedirectEvent
  extends SwedbankPayCheckoutEvent<"OnOutOfViewRedirect"> {
  redirectUrl: string;
}

interface SwedbankPayCheckoutOnPaymentAttemptStartedEvent
  extends SwedbankPayCheckoutEvent<"OnPaymentAttemptStarted"> {
  instrument: PaymentMethod["instrument"];
}

interface SwedbankPayCheckoutOnPaymentAttemptAbortedEvent
  extends SwedbankPayCheckoutEvent<"OnPaymentAttemptAborted"> {
  redirectUrl: string;
}

interface SwedbankPayCheckoutOnPaymentAttemptFailedEvent
  extends SwedbankPayCheckoutEvent<"OnPaymentAttemptFailed"> {
  details: string;
}

export type SwedbankPayEligbleCheckInstrument =
  | "ClickToPay"
  | "GooglePay"
  | "ApplePay";

export const SWEDBANK_PAY_PAYMENT_MENU_PATH = "/3ds-complete.html";
