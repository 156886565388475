import { Redirect, useHistory } from "react-router-dom";

import { ICreateBookingFormValues } from "../../../modules/checkout/models/Booking";

import {
  adminGetMobileBookingPath,
  adminGetMobilePath,
} from "../../../helpers/pathHelpers";

import { useToaster } from "../../../hooks/common/useToaster";

import AdminCreateBookingForm from "../../../modules/checkout/components/Calendar/admin/dialog-content/AdminCreateBookingForm";

export const AdminMobileBookingFormPage: React.FC = () => {
  const history = useHistory();
  const toaster = useToaster();
  const data = history?.location?.state as ICreateBookingFormValues;

  const onSuccessfulBooking = () => {
    toaster.toastSuccess.bookingCreated();
    history.push(adminGetMobilePath());
  };

  if (!data) {
    return <Redirect to={adminGetMobileBookingPath()} />;
  }

  return (
    <div className="bg-purewhite p-2">
      <AdminCreateBookingForm
        initialBookingData={data}
        onSubmitted={onSuccessfulBooking}
      />
    </div>
  );
};
