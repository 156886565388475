import { Redirect } from "react-router-dom";

import useSWR from "swr";
import { useScript } from "usehooks-ts";

import { adminGetSettingsPath } from "../../../../helpers/pathHelpers";

import { useHasFacilityOwnerPermission } from "../../../../hooks/permissions";
import { useIsFeatureFlagEnabled } from "../../../../hooks/swr/useFeatureFlags";
import { useSelectedFacility } from "../../../../hooks/swr/useSelectedFacility";

import { getFacilityDashboard } from "../../../../services/facilityDashboardService";

import { ProgressSpinner } from "../../../../components/ProgressSpinner";

import { useCurrentUserId } from "../../../../recoil/currentUserIdState";

declare global {
  interface Window {
    iFrameResize: any;
  }
}

export const AdminFacilitySettingsStatistics = () => {
  const currentUserId = useCurrentUserId();
  const { selectedFacility } = useSelectedFacility();
  const { hasFacilityOwnerPermission } = useHasFacilityOwnerPermission();

  const statisticsDashboardEnabled =
    useIsFeatureFlagEnabled("Dashboard.Facility");

  const { data } = useSWR(
    statisticsDashboardEnabled && currentUserId && selectedFacility
      ? ["facilityDashboard", currentUserId, selectedFacility.id]
      : undefined,
    ([, , facilityId]) => getFacilityDashboard(facilityId),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );

  const scriptStatus = useScript(
    process.env.REACT_APP_METABASE_URI + "/app/iframeResizer.js",
  );

  if (!selectedFacility) {
    return null;
  }

  if (!statisticsDashboardEnabled || !hasFacilityOwnerPermission) {
    return <Redirect to={adminGetSettingsPath()} />;
  }

  if (scriptStatus !== "ready" || !data?.url) {
    return <ProgressSpinner />;
  }

  return (
    <iframe
      className="w-full"
      src={data.url}
      onLoad={() => window.iFrameResize({}, this)}
    />
  );
};
