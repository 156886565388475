import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { faCreditCardFront } from "@fortawesome/pro-light-svg-icons";

import { useToaster } from "../../../hooks/common/useToaster";

import { forgetPaymentInfo } from "../../../modules/checkout/services/Payment";

import { ConfirmationDialog } from "../../ConfirmationDialog";

interface Props {
  onCardRemoved: () => void;
}

export const RemoveCard = ({ onCardRemoved }: Props) => {
  const intl = useIntl();
  const { toastError } = useToaster();

  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  const removeCard = async () => {
    setIsLoading(true);

    try {
      const removed = await forgetPaymentInfo();

      if (removed) {
        onCardRemoved?.();
      } else {
        toastError.generalFailure();
      }
    } catch (e) {
      console.log(e);
      toastError.generalFailure();
    } finally {
      setShowConfirm(false);
      setIsLoading(false);
    }
  };

  return (
    <>
      <button
        type="button"
        className="mt-1"
        onClick={() => setShowConfirm(true)}
      >
        <FormattedMessage id="card-details.remove-card" />
      </button>

      {showConfirm && (
        <ConfirmationDialog
          loading={isLoading}
          icon={faCreditCardFront}
          confirmButtonType="danger"
          visible
          title={intl.formatMessage({
            id: "card-details.remove-card.confirm-title",
          })}
          text={intl.formatMessage({
            id: "card-details.remove-card.confirm-are-you-sure",
          })}
          onHide={() => setShowConfirm(false)}
          onCancel={() => setShowConfirm(false)}
          onSubmit={() => removeCard()}
        />
      )}
    </>
  );
};
