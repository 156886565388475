import { useCallback } from "react";

import { createOrderPaymentWithCoinsAllowance } from "../../../../services/myOrdersService";

import { useCheckoutState } from "../../context/CheckoutContext";
import { Coins } from "./Coins";

export const CoinsAllowance = () => {
  const { order, isSplitPayment, coPayingUsers } = useCheckoutState();

  const createOrderPayment = useCallback(
    async () =>
      createOrderPaymentWithCoinsAllowance(order.id, {
        splitPayment: isSplitPayment,
        coPayingUserIds: isSplitPayment
          ? coPayingUsers.map(user => user.id)
          : [],
      }),
    [coPayingUsers, isSplitPayment, order.id],
  );

  return <Coins createOrderPayment={createOrderPayment} />;
};
