import { PropsWithChildren, useEffect } from "react";
import { FormattedMessage, IntlProvider } from "react-intl";

import { locale as primeReactSetLocale } from "primereact/api";
import { useSetRecoilState } from "recoil";
import { setLocale } from "yup";

import {
  I18N_CONFIGS,
  LOCAL_STORAGE_LOCALE_KEY,
  intlMessages,
} from "../appConstants/i18n";

import { useCurrentUser } from "../hooks/swr/useCurrentUser";

import { i18nConfigState, useAppI18nConfig } from "../recoil/i18nConfigState";

setLocale({
  mixed: {
    notNull: () => <FormattedMessage id="validation.required.field" />,
    required: () => <FormattedMessage id="validation.required.field" />,
  },
  string: {
    email: () => <FormattedMessage id="validation.invalid.email" />,
    matches: ({ regex }) => (
      <FormattedMessage
        id="common.validation.string.match"
        values={{ regex: String(regex) }}
      />
    ),
  },
  number: {
    min: ({ min }) => (
      <FormattedMessage id="common.validation.number.min" values={{ min }} />
    ),
    max: ({ max }) => (
      <FormattedMessage id="common.validation.number.max" values={{ max }} />
    ),
  },
  array: {
    min: ({ min }) => (
      <FormattedMessage id="common.validation.array.min" values={{ min }} />
    ),
    max: ({ max }) => (
      <FormattedMessage id="common.validation.array.max" values={{ max }} />
    ),
  },
});

const LanguageWrapper = ({ children }: PropsWithChildren<unknown>) => {
  const { i18nConfig } = useAppI18nConfig();
  const { currentUser } = useCurrentUser();
  const setI18nConfig = useSetRecoilState(i18nConfigState);

  useEffect(() => {
    primeReactSetLocale(i18nConfig.locale);
  }, [i18nConfig]);

  useEffect(() => {
    // Default to current user's locale
    let locale = currentUser?.locale;

    // If no user is set or user has no locale, try to get locale from local storage
    if (!locale) {
      locale = window.localStorage.getItem(LOCAL_STORAGE_LOCALE_KEY);
    }

    // If no locale is set in local storage,
    // try to get locale from browser and match it to one of the supported locales
    if (!locale) {
      const browserLocale = navigator.language.toLocaleLowerCase();

      locale = I18N_CONFIGS.find(
        l =>
          l.locale.toLowerCase() === browserLocale ||
          l.languageCode === browserLocale,
      )?.locale;
    }

    if (!locale) {
      return;
    }

    const newI18nConfig = I18N_CONFIGS.find(l => l.locale === locale);

    if (!newI18nConfig) {
      return;
    }

    setI18nConfig(newI18nConfig);
  }, [currentUser]);

  return (
    <IntlProvider
      messages={intlMessages[i18nConfig.locale]}
      locale={i18nConfig.locale}
      onError={e => {
        if (process.env.REACT_APP_NODE_ENV === "dev") {
          console.log(e?.message);
        }
      }}
    >
      {children}
    </IntlProvider>
  );
};

export default LanguageWrapper;
