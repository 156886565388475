import React, { useCallback, useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router-dom";

import {
  faSortAmountAsc,
  faSortAmountDesc,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { DateTime } from "luxon";
import { Calendar } from "primereact/calendar";

import { DateOnly } from "../../../../models/DateOnly";
import { CoinTransactionLog } from "../../../../models/TransactionLogs";

import { useCurrencyFormat } from "../../../../hooks/useCurrencyFormat";
import { useDateFormat } from "../../../../hooks/useDateFormat";

import { getCoinsLogs } from "../../../../modules/player/services/UserService";

import { Button } from "../../../../components/Button";
import { BackEndPaginatedDataTable } from "../../../../components/DataTable/BackEndPaginatedDataTable";
import { ProgressSpinner } from "../../../../components/ProgressSpinner";
import { CalendarInput } from "../../../../components/inputs/CalendarInput";

import { luxonDateFormat } from "../../../../utils/dateFormats";
import { CoinLogComment } from "./CoinLogComment";

interface Props {
  isChangeInCoins: boolean;
  pageSize?: number;
  facilityId: string;
}

interface ProfileParams {
  userId?: string;
}

export const EventLogView: React.FC<Props> = ({
  isChangeInCoins,
  pageSize,
  facilityId,
}) => {
  const intl = useIntl();
  const { userId } = useParams<ProfileParams>();

  const [loading, setLoading] = useState<boolean>(true);
  const [userLogs, setUserLogs] = useState<CoinTransactionLog[]>([]);
  const [fromDate, setFromDate] = useState<DateOnly>(null);
  const [toDate, setToDate] = useState<DateOnly>(null);
  const [isEndDateSelected, setIsEndDateSelected] = useState<boolean>(false);
  const [lastPageItemId, setLastPageItemId] = useState<string>("");
  const [resetDates, setResetDates] = useState<boolean>(false);
  const ref = useRef<Calendar>();
  const prevLastPageItemIdRef = useRef(null);
  const [isDescending, setIsDescending] = useState<boolean>(true);
  const [logCount, setLogCount] = useState<number>(0);
  const [filteredCount, setFilteredCount] = useState<number>(0);
  const { cf } = useCurrencyFormat("Coins", { minimumFractionDigits: 2 });
  const { df } = useDateFormat(facilityId);

  const fetchTransactionLogs = useCallback(async () => {
    try {
      const logs = await getCoinsLogs(
        userId,
        facilityId,
        lastPageItemId,
        pageSize,
        fromDate,
        toDate,
        isDescending,
      );
      setLoading(false);
      if (lastPageItemId) {
        if (logs.data) {
          setUserLogs(prevLogs => [
            ...prevLogs,
            ...logs.data.coinsTransactions,
          ]);
          setLogCount(logs.data.totalCount);
          setFilteredCount(logs.data.filteredCount);
        }
      } else {
        setUserLogs(logs.data.coinsTransactions);
        setLogCount(logs.data.totalCount);
        setFilteredCount(logs.data.filteredCount);
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  }, [
    userId,
    facilityId,
    lastPageItemId,
    pageSize,
    fromDate,
    toDate,
    isDescending,
  ]);

  useEffect(() => {
    if (
      isEndDateSelected ||
      (!fromDate && !toDate) ||
      (lastPageItemId && lastPageItemId !== prevLastPageItemIdRef.current)
    ) {
      fetchTransactionLogs();
    }
    prevLastPageItemIdRef.current = lastPageItemId;
  }, [
    isEndDateSelected,
    fromDate,
    toDate,
    isChangeInCoins,
    lastPageItemId,
    fetchTransactionLogs,
  ]);

  const columns = [
    {
      field: "currentAmount",
      sortable: false,
      header: (
        <span className="font-semibold text-blue-500">
          <FormattedMessage id="admin.player-overview.event-log.amount" />
        </span>
      ),
      body: ({ currentAmount, previousAmount, priceIncTax }) => (
        <span
          className={clsx(
            "flex justify-end font-bold",
            currentAmount > previousAmount ? "text-green-700" : "text-red-600",
          )}
        >
          {currentAmount > previousAmount && "+"}
          {priceIncTax !== 0
            ? cf(priceIncTax.toFixed(2) * -1)
            : cf(currentAmount - previousAmount)}
        </span>
      ),
      headerStyle: { width: "10%", padding: "0.85rem 0.5rem !important" },
      useInMobile: true,
    },
    {
      field: "previousAmount",
      header: (
        <span className="font-semibold text-blue-500">
          <FormattedMessage id="admin.player-overview.event-log.total" />
        </span>
      ),

      body: ({ currentAmount }) => (
        <span className="flex justify-center">{cf(currentAmount)}</span>
      ),
      sortable: false,
      useInMobile: true,
      headerStyle: { width: "10%" },
    },
    {
      field: "createdDate",
      header: (
        <div className="flex flex-row items-center">
          <span className="font-semibold text-blue-500">
            <FormattedMessage id="admin.player-overview.event-log.date" />
          </span>
          <FontAwesomeIcon
            icon={!isDescending ? faSortAmountDesc : faSortAmountAsc}
            className="ml-5 text-blue-500"
            onClick={() => {
              setIsDescending(!isDescending);
              setLastPageItemId("");
            }}
          />
        </div>
      ),

      body: ({ createdDate }) => df(createdDate, luxonDateFormat),
      sortable: false,
      headerStyle: { width: "15%" },
    },
    {
      field: "logComment",
      header: (
        <span className="font-semibold text-blue-500">
          <FormattedMessage id="admin.player-overview.event-log.comment" />
        </span>
      ),
      body: ({
        logComment,
        buyableType,
        buyableTypeDetails,
        paymentTransactionType,
      }) => (
        <CoinLogComment
          logComment={logComment}
          buyableType={buyableType}
          buyableTypeDetails={buyableTypeDetails}
          paymentTransactionType={paymentTransactionType}
          facilityId={facilityId}
        />
      ),
      sortable: false,
      headerStyle: { width: "50%" },
    },
    {
      field: "creatorUser",
      header: (
        <span className="font-semibold text-blue-500">
          <FormattedMessage id="admin.player-overview.event-log.changedBy" />
        </span>
      ),
      body: ({ creatorUser }) =>
        creatorUser ? `${creatorUser.firstName} ${creatorUser.lastName}` : null,
      sortable: false,
      headerStyle: { width: "15%" },
    },
  ];

  return (
    <div className="space-y-8">
      <div className="flex flex-wrap justify-between gap-4">
        <h3>
          <FormattedMessage id="admin.eventLog.title" />
          <span className="pl-1 font-medium">({logCount})</span>
        </h3>
        <div className="w-[11.5rem] self-end">
          <CalendarInput
            placeholder={intl.formatMessage({
              id: "common.filter.date",
            })}
            ref={ref}
            selectionMode="range"
            onChange={e => {
              if (!e.value) {
                setFromDate(null);
                setToDate(null);
                setIsEndDateSelected(false);
                setResetDates(true);
              } else {
                const [start, end] = e.value as unknown as DateTime[];
                if (start && end) {
                  setFromDate(DateOnly.fromDateTime(start));
                  setToDate(DateOnly.fromDateTime(end));
                  setIsEndDateSelected(true);
                  setLastPageItemId("");
                }
                if (start && !end) {
                  setIsEndDateSelected(false);
                }
              }
            }}
            maxDate={DateTime.local()}
            isEndDateSelected={isEndDateSelected}
            resetDates={resetDates}
          />
        </div>
      </div>

      {loading ? (
        <div className="flex justify-center">
          <ProgressSpinner />
        </div>
      ) : (
        <BackEndPaginatedDataTable
          rowsPerPage={10}
          columns={columns}
          data={userLogs}
          useColumnBorder
        />
      )}
      <div className="flex justify-center">
        <Button
          type="primary"
          size="small"
          className="align-center justify-center"
          text={intl.formatMessage({
            id: "admin.eventLog.show_more",
          })}
          onClick={() => {
            setLastPageItemId(userLogs.at(-1).id);
          }}
          disabled={
            loading ||
            userLogs?.length === logCount ||
            userLogs?.length === filteredCount
          }
        />
      </div>
    </div>
  );
};
