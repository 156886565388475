import { DateTime } from "luxon";

import type {
  MaintenanceMessage,
  MaintenanceMessageDTO,
} from "../models/maintenanceMessage";

export const getMaintenanceMessage =
  async (): Promise<MaintenanceMessage | null> => {
    if (!process.env.REACT_APP_MAINTENANCE_MESSAGE_JSON_URL) {
      throw new Error("REACT_APP_MAINTENANCE_MESSAGE_JSON_URL is not set");
    }

    const response = await fetch(
      process.env.REACT_APP_MAINTENANCE_MESSAGE_JSON_URL,
    );

    if (!response.ok) {
      return null;
    }

    const message = await response.json();
    return formatMaintenanceMessage(message);
  };

export const formatMaintenanceMessage = (
  message: MaintenanceMessageDTO,
): MaintenanceMessage => {
  return {
    ...message,
    created: DateTime.fromISO(message.created),
  };
};
