import { MaintenanceMessageSeverity } from "../models/maintenanceMessage";

export const getMaintenanceMessageColor = (
  severity: MaintenanceMessageSeverity,
) => {
  switch (severity) {
    case MaintenanceMessageSeverity.Critical: {
      return "text-red-500";
    }

    case MaintenanceMessageSeverity.High: {
      return "text-orange-300";
    }

    case MaintenanceMessageSeverity.Low: {
      return "text-blue-500";
    }

    default: {
      const exhaustiveCheck: never = severity;
      return exhaustiveCheck;
    }
  }
};
