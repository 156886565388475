import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

import { faCheck, faClose, faEdit } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InputSwitch } from "primereact/inputswitch";

import {
  IBooking,
  IUpdateBookingCommentFormValues,
} from "../../../../models/Booking";

import { updateBookingComment } from "../../../../services/Booking";

import { TextAreaInput } from "../../../../../../components/TextAreaInput";

interface Props {
  booking: IBooking;
  facilityId?: string;
  fetch?: () => void;
  onSubmitted: (
    isReschedule?: boolean,
    refreshView?: boolean,
    isEditDialogOpen?: boolean,
  ) => void;
}

const AdminBookingComment = ({
  booking,
  facilityId,
  fetch,
  onSubmitted,
}: Props) => {
  const [isEditEnableAdminComment, setEditEnableAdminComment] = useState(false);
  const [adminComment, setAdminComment] = useState<string>();
  const [isShownInCalender, setIsShownInCalender] = useState(
    booking?.isShownInCalendar,
  );

  useEffect(() => {
    setAdminComment(booking?.comment);
  }, [booking]);

  useEffect(() => {
    if (adminComment) {
      handleUpdateAdminComment();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShownInCalender]);

  const handleUpdateAdminComment = async () => {
    if (!adminComment?.length || !facilityId) return;

    const data: IUpdateBookingCommentFormValues = {
      id: booking?.id,
      comment: adminComment,
      isShownInCalendar: adminComment ? isShownInCalender : false,
    };
    const response = await updateBookingComment(facilityId, data);
    if (response) {
      fetch?.();
      setEditEnableAdminComment(false);
      onSubmitted(false, true, true);
    }
  };

  const handleChangeAdminComment = (e: any) => {
    setAdminComment(e.target.value);
  };

  const handleOnSelectingIsShowInCalander = async (value: boolean) => {
    if (!adminComment) setIsShownInCalender(false);
    else {
      setIsShownInCalender(value);
    }
  };

  return (
    <>
      {!isEditEnableAdminComment ? (
        <div className="mb-2 flex justify-between text-sm font-bold text-gray-700">
          <FormattedMessage
            id="admin-edit-booking-form-comment"
            defaultMessage="Övrig info"
          />
          <button
            className="text-primary"
            onClick={() => setEditEnableAdminComment(true)}
          >
            <FontAwesomeIcon icon={faEdit} />
          </button>
        </div>
      ) : (
        <div className="mb-2 flex justify-between text-sm font-bold text-gray-700">
          <FormattedMessage
            id="admin-edit-booking-form-comment"
            defaultMessage="Övrig info"
          />
          <div className="flex gap-4">
            <button
              type="button"
              className="text-primary"
              onClick={() => handleUpdateAdminComment()}
            >
              <FontAwesomeIcon icon={faCheck} />
            </button>

            <button
              type="button"
              className="text-primary"
              onClick={() => setEditEnableAdminComment(false)}
            >
              <FontAwesomeIcon icon={faClose} />
            </button>
          </div>
        </div>
      )}

      {isEditEnableAdminComment ? (
        <TextAreaInput
          name="comment"
          defaultValue={booking?.comment ?? ""}
          rows={3}
          onChange={handleChangeAdminComment}
        />
      ) : (
        <span>{booking?.comment || ""}</span>
      )}
      <div className="mt-2 flex items-center justify-end gap-4 text-sm font-bold text-gray-700">
        <FormattedMessage id="admin-booking-comment-enable" />
        <InputSwitch
          checked={isShownInCalender}
          disabled={adminComment && !isEditEnableAdminComment ? false : true}
          onChange={e => handleOnSelectingIsShowInCalander(e.value)}
        />
      </div>
    </>
  );
};

export default AdminBookingComment;
