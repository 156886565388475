import { Link } from "react-router-dom";

import { faRocket, faSitemap } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { BuyableType } from "../../../../checkout/models/Payment";
import { IActivity } from "../../../models/Activity";

interface Props {
  activity: IActivity;
}

const AdminSeriesTableNameColumn = ({ activity }: Props) => {
  return (
    <Link
      to={
        activity.buyableType === BuyableType.Series
          ? `/admin/activities/serie/${activity.id}`
          : `/admin/activities/event/${activity.id}`
      }
      className="flex min-w-8 max-w-prose cursor-pointer items-center justify-start gap-2 font-inherit text-inherit"
    >
      <div className="flex size-10 flex-none items-center justify-center rounded-sm bg-primary text-purewhite">
        {activity?.imageUrl ? (
          <img className="size-10 object-cover" src={activity.imageUrl} />
        ) : (
          <FontAwesomeIcon size="1x" icon={faRocket} />
        )}
      </div>

      {activity.name}

      {activity.buyableType === BuyableType.Series && (
        <div className="ml-auto grid size-8 flex-none place-items-center rounded-full bg-primary text-purewhite">
          <FontAwesomeIcon size="xs" icon={faSitemap} />
        </div>
      )}
    </Link>
  );
};

export default AdminSeriesTableNameColumn;
