import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";

import { faCopy } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormik } from "formik";
import { DateTime } from "luxon";
import { InputNumber } from "primereact/inputnumber";
import { InputSwitch } from "primereact/inputswitch";
import { InputTextarea } from "primereact/inputtextarea";
import styled from "styled-components";

import { INewSeasonSerieFormValues, type Series } from "../../../models/Series";

import { useSeries } from "../../../../../hooks/swr/useSeries";
import { useFormValidationSchema } from "../../../hooks/useFormValidationSchema";

import { createNewSeason } from "../../../services/Serie";

import { Button } from "../../../../../components/Button";
import { CalendarDateTimeWrapper } from "../../../../../components/CalendarDateTimeWrapper";
import { TextInput } from "../../../../../components/TextInput";

const InputContainer = styled.div`
  margin: 1rem 0;

  label {
    font-weight: var(--bold);
    font-size: 1.1rem;
  }
`;

interface IProps {
  serieId: string;
  onSubmit: (series: Series) => void;
}

const initialStartTime = DateTime.now().plus({ days: 30 });
const initialRegistrationOpenTo = DateTime.now().plus({ days: 20 });

const AdminCopySerieForm: React.FC<IProps> = ({ serieId, onSubmit }) => {
  const { series } = useSeries(serieId);

  const { createSeriesSchema } = useFormValidationSchema();

  const formik = useFormik<INewSeasonSerieFormValues>({
    initialValues: {
      serieId: serieId,
      name: series?.name,
      gender: series?.gender,
      facilityId: series?.facilityId,
      openForRegistration: series?.openForRegistration,
      startTime: initialStartTime,
      registrationOpenTo: initialRegistrationOpenTo,
      price: series?.price?.valueInclTax,
      description: series?.description,
      rescheduleRules: series?.rescheduleRules,
    },
    onSubmit: async (data: INewSeasonSerieFormValues) => {
      try {
        const body = {
          ...data,
          gender: parseInt(data?.gender.toString()),
        };

        const { data: series } = await createNewSeason(body);

        onSubmit(series);
      } catch (err) {
        // TODO: process if known errors
        console.log(err);
      }
    },
    validationSchema: createSeriesSchema,
    enableReinitialize: true,
    validateOnMount: false,
    initialTouched: {
      startTime: true,
      registrationOpenTo: true,
    },
  });

  useEffect(() => {
    if (
      formik.values.startTime.toMillis() ===
      formik.initialValues.startTime.toMillis()
    ) {
      return;
    }

    if (formik.values.startTime <= formik.values.registrationOpenTo) {
      formik.setFieldValue(
        "registrationOpenTo",
        formik.values.startTime.minus({ days: 1 }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.startTime]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <div>
        <h3 className="flex items-center">
          <FontAwesomeIcon icon={faCopy} className="mr-4 text-primary" />
          <FormattedMessage
            id="series.create-next-season"
            defaultMessage="Skapa nästa säsong"
          />
        </h3>
        <p className="max-w-prose">
          <FormattedMessage id="series.create-next-season.description" />
        </p>

        <h4 className="mt-6 text-3xl text-primary">{series?.name}</h4>
        <InputContainer>
          <label htmlFor="name">
            <FormattedMessage id="common.name" defaultMessage="Namn" />
            <br />
            <small>
              <FormattedMessage
                id="series.change-name"
                defaultMessage="Vill du byta namn på serien till nästa säsong eller behålla det gamla?"
              />
            </small>
          </label>
          <div>
            <TextInput
              id="name"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.name && formik.errors.name && (
              <small className="p-error">{formik.errors.name}</small>
            )}
          </div>
        </InputContainer>
        <InputContainer>
          <label htmlFor="startTime">
            <FormattedMessage id="common.startDate" defaultMessage="Startdag" />
            <br />
            <small>
              <FormattedMessage
                id="series.start-day.description"
                defaultMessage="Den dag då serien skall börja spelas"
              />
            </small>
          </label>
          <div style={{ marginTop: ".5rem" }}>
            <CalendarDateTimeWrapper
              selectOtherMonths
              id="startTime"
              name="startTime"
              value={formik.values.startTime}
              maxDate={formik.values.endTime}
              onChange={e => formik.setFieldValue("startTime", e.value, true)}
              onBlur={formik.handleBlur}
              style={{ width: "100%" }}
              readOnlyInput
              showIcon
            />
            {formik.touched.startTime && formik.errors.startTime && (
              <small className="p-error">
                <>{formik.errors.startTime}</>
              </small>
            )}
          </div>
        </InputContainer>
        <InputContainer>
          <label htmlFor="endTime">
            <FormattedMessage id="common.endDate" />
            <br />
            <small>
              <FormattedMessage id="series.end.day.description" />
            </small>
          </label>
          <div style={{ marginTop: ".5rem" }}>
            <CalendarDateTimeWrapper
              selectOtherMonths
              id="endTime"
              name="endTime"
              value={formik.values.endTime}
              minDate={formik.values.startTime.plus({ days: 1 })}
              onChange={e => formik.setFieldValue("endTime", e.value, true)}
              onBlur={formik.handleBlur}
              style={{ width: "100%" }}
              readOnlyInput
              showIcon
            />
            {formik.errors.endTime && (
              <small className="p-error">
                <>{formik.errors.endTime}</>
              </small>
            )}
          </div>
        </InputContainer>
        <InputContainer>
          <label htmlFor="registrationOpenTo" className="p-mt-2">
            <FormattedMessage
              id="series.input.registration-open-to"
              defaultMessage="Registrering öppen till"
            />
            <br />
            <small>
              <FormattedMessage
                id="series.input.registration-open-to.description"
                defaultMessage="Till detta datum kan lagen registrera sig till serien (om lediga platser finns)."
              />
            </small>
          </label>

          <div style={{ marginTop: ".5rem" }}>
            <CalendarDateTimeWrapper
              selectOtherMonths
              id="registrationOpenTo"
              name="registrationOpenTo"
              maxDate={formik.values.startTime.minus({ days: 1 })}
              value={formik.values.registrationOpenTo}
              onChange={e =>
                formik.setFieldValue("registrationOpenTo", e.value, true)
              }
              onBlur={formik.handleBlur}
              style={{ width: "100%" }}
              readOnlyInput
              showIcon
            />
            {formik.touched.registrationOpenTo &&
              formik.errors.registrationOpenTo && (
                <small className="p-error">
                  <>{formik.errors.registrationOpenTo}</>
                </small>
              )}
          </div>
        </InputContainer>

        <div className="p-field" style={{ gap: "1rem" }}>
          <div
            style={{
              paddingBottom: "0.5rem",
              fontWeight: "var(--bold)",
              fontSize: "1.1rem",
            }}
          >
            <FormattedMessage id="series.steps.guide.fee-per-person" />
          </div>
          <InputNumber
            name="price"
            mode="decimal"
            minFractionDigits={2}
            maxFractionDigits={2}
            value={formik.values.price}
            onBlur={formik.handleBlur}
            onChange={e => formik.setFieldValue("price", e.value)}
            useGrouping={false}
          />
          {formik.errors.price && (
            <small className="p-error">{formik.errors.price}</small>
          )}
        </div>

        <div className="mt-4 flex flex-col">
          <label
            style={{
              fontWeight: "var(--bold)",
              fontSize: "1.1rem",
              marginBottom: "0.5rem ",
            }}
          >
            <FormattedMessage id="series.admin.description" />
          </label>
          <InputTextarea
            name="description"
            placeholder={formik?.values?.description}
            value={formik?.values?.description || ""}
            onChange={formik.handleChange}
            rows={5}
          />
        </div>

        <div className="p-field p-d-flex p-ai-center mt-4 font-bold">
          <label htmlFor="openForRegistration" className="p-mt-2">
            <FormattedMessage
              id="admin-series-table.registration-status-column"
              defaultMessage="Öppen för registrering"
            />
          </label>
          <div className="p-ml-4" style={{ paddingTop: "0.5rem" }}>
            <InputSwitch
              name="openForRegistration"
              onChange={state =>
                formik.setFieldValue("openForRegistration", state.value, true)
              }
              checked={formik.values.openForRegistration}
            />
            {formik.errors.openForRegistration && (
              <small className="p-error">
                {formik.errors.openForRegistration}
              </small>
            )}
          </div>
        </div>

        <div
          style={{ marginTop: "1rem", display: "flex", justifyContent: "end" }}
        >
          <Button
            buttonType="submit"
            type="primary"
            translationName="common.create"
            text="Skapa"
            disabled={!formik.isValid || formik.isSubmitting}
          />
        </div>
      </div>
    </form>
  );
};

export default AdminCopySerieForm;
