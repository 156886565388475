import { useCallback, useMemo } from "react";
import { MessageDescriptor, useIntl } from "react-intl";
import { toast } from "react-toastify";

type MessageId = MessageDescriptor["id"];
type MessageValues = Record<
  string,
  string | number | boolean | null | undefined | Date
>;

export const useToaster = () => {
  const intl = useIntl();

  const getToastSuccess = useCallback(
    (id: MessageId, values?: MessageValues) =>
      toast.success(intl.formatMessage({ id }, values)),
    [intl],
  );

  const getToastError = useCallback(
    (id: MessageId, values?: MessageValues) =>
      toast.error(intl.formatMessage({ id }, values)),
    [intl],
  );

  const getToastWarn = useCallback(
    (id: MessageId, values?: MessageValues) =>
      toast.warn(intl.formatMessage({ id }, values)),
    [intl],
  );

  const getToastInfo = useCallback(
    (id: MessageId, values?: MessageValues) =>
      toast.info(intl.formatMessage({ id }, values)),
    [intl],
  );

  const toastSuccess = useMemo(
    () => ({
      message: (id: MessageId, values?: MessageValues) =>
        getToastSuccess(id, values),
      infoSaved: () => getToastSuccess("toast.success.save.info"),
      changesSaved: () => getToastSuccess("toast.success.save.changes"),
      userSaved: () => getToastSuccess("toast.success.save.user"),
      friendDeleted: () =>
        getToastSuccess(
          "profile.friendlist.friendlistbody.sccess-remove-friend-message",
        ),
      favoriteAdded: name =>
        getToastSuccess("toast.success.add.favourite", { name }),
      favoriteRemoved: name =>
        getToastSuccess("toast.success.remove.favourite", { name }),
      invitationLinkCreated: () =>
        getToastSuccess("friend-invitation.getinvitelink-success-message"),
      facilityLogoRemoved: () =>
        getToastSuccess("toast.success.remove.facility.logo"),
      pricingCreated: () => getToastSuccess("toast.success.create.pricing"),
      pricingActivated: () =>
        getToastSuccess("toast.success.pricing.activated"),
      pricingResetted: () => getToastSuccess("toast.success.pricing.reset"),
      pricingCategoryUpdated: () =>
        getToastSuccess("toast.success.update.pricing"),
      discountGroupCreated: () =>
        getToastSuccess("toast.success.create.discount-group"),
      discountGroupUpdated: () =>
        getToastSuccess("toast.success.update.discount-group"),
      discountGroupDeleted: () =>
        getToastSuccess("toast.success.delete.discount-group"),
      discountGroupMemberDeleted: () =>
        getToastSuccess("toast.success.delete.discount-group-member"),
      discountGroupMemberAdded: () =>
        getToastSuccess("toast.success.add.discount-group-member"),
      logoChanged: () => getToastSuccess("toast.success.add.logo"),
      profileImageChanged: () =>
        getToastSuccess("toast.success.add.profile-image"),
      activityImageChanged: () =>
        getToastSuccess("toast.success.activity.image"),
      bookingCancelled: () => getToastSuccess("toast.success.remove.booking"),
      bookingCreated: () => getToastSuccess("toast.success.create.booking"),
      linkCopied: () => getToastSuccess("toast.success.link-copied"),
      resultUpdated: () => getToastSuccess("toast.success.update.result"),
      gameRemoved: () => getToastSuccess("toast.success.remove.game"),
      setRemoved: () => getToastSuccess("toast.success.remove.set"),
      gameCreated: () => getToastSuccess("toast.success.create.game"),
      priceListCreated: () =>
        getToastSuccess("toast.success.create.price-list"),
      pricingCategoryCreated: () =>
        getToastSuccess("toast.success.create.pricing-category"),
      nameSaved: () => getToastSuccess("toast.success.create.name"),
      priceSaved: () => getToastSuccess("toast.success.create.price"),
      categoryRemoved: () => getToastSuccess("toast.success.remove.category"),
      divisionSaved: () => getToastSuccess("toast.success.save.division"),
      divisionEmailSent: () =>
        getToastSuccess("toast.success.division.email.sent"),
      divisionRemoved: () => getToastSuccess("toast.success.division.remove"),
      divisionGamesRemoved: () =>
        getToastSuccess("toast.success.division-games.remove"),
      gamesForSerieCreated: () =>
        getToastSuccess("toast.success.create.games-for-serie"),
      teamAddedToSerie: () =>
        getToastSuccess("toast.success.add.team-to-serie"),
      priceListDeleted: () =>
        getToastSuccess("toast.success.delete.price-list"),
      priceListUpdated: () =>
        getToastSuccess("toast.success.update.price-list"),
      addPlayerToGeneralActivitiy: () =>
        getToastSuccess("toast.success.addPlayerToGeneralActivity"),
      saveCoins: () => getToastSuccess("toast.success.saveCoins"),
      userRemovedFromActivity: () =>
        getToastSuccess("toast.success.userRemovedFromActivity"),
      facilityDoorOpenTimeAdded: () =>
        getToastSuccess("toast.success.facilityDoorOpenTimeAdded"),
      bookingRescheduleSuccess: () =>
        getToastSuccess("toast.success.rescheduleBooking"),
      saveAccessTimesSuccess: () =>
        getToastSuccess("toast.success.defaultVendorAccessTimes"),
    }),
    [getToastSuccess],
  );

  const toastError = useMemo(
    () => ({
      message: (id: MessageId, values?: MessageValues) =>
        getToastError(id, values),
      generalFailure: () => getToastError("general.error.contact.support"),
      validation: () => getToastError("toast.error.validation"),
      saveFailed: (error = "") =>
        getToastError("toast.error.save.info", { error }),
      uploadLogoFailed: () => getToastError("toast.error.upload.logo"),
      removeLogoFailed: () => getToastError("toast.error.remove.facility.logo"),
      fetchFacilityFailed: () => getToastError("toast.error.fetch.venue"),
      fetchFriendsFailed: () =>
        getToastError(
          "profile.friendlist.friendlistbody.error-loading-friend-message",
        ),
      fetchBookableTypesFailed: () =>
        getToastError("toast.error.fetch.bookable-types"),
      fetchSportTypesFailed: () =>
        getToastError("toast.error.fetch.sport-types"),
      fetchAmenitiesFailed: () => getToastError("toast.error.fetch.amenities"),
      updateFailed: () => getToastError("toast.error.update.generic"),
      someChangesUpdateFailed: () => getToastError("toast.error.update"),
      updateUserFailed: () => getToastError("toast.error.update.user"),
      fetchUserFailed: () => getToastError("toast.error.fetch.user"),
      fetchGamesFailed: error =>
        getToastError("toast.error.fetch.games", { error }),
      profileImageUploadFailed: () =>
        getToastError("toast.error.upload.profile-picture"),
      activityImageFailed: () => getToastError("toast.error.activity.image"),
      deleteFriendFailed: () =>
        getToastError(
          "profile.friendlist.friendlistbody.error-remove-friend-message",
        ),
      addFavoriteFailed: () => getToastError("toast.success.add.favorite"),
      removeFavoriteFailed: () => getToastError("toast.error.delete.favorite"),
      generateInvitationLinkFailed: () =>
        getToastError("friend-invitation.getinvitelink-error-message"),
      fetchPricingsFailed: () => getToastError("toast.error.fetch.pricings"),
      fetchPricingFailed: () => getToastError("toast.error.fetch.pricing"),
      activatePricingFailed: () => getToastError("toast.error.active.pricing"),
      createPricingFailed: () => getToastError("toast.error.create.pricing"),

      resetPricingFailed: () => getToastError("toast.error.reset.pricing"),
      createPricingCategoryFailed: () =>
        getToastError("toast.error.create.pricing-category"),
      updatePricingCategoryFailed: () =>
        getToastError("toast.error.create.pricing-category"),
      removePricingFailed: () =>
        getToastError("toast.error.remove.pricing-category"),
      saveDiscountGroupFailed: () =>
        getToastError("toast.error.update.discount-group"),
      createDiscountGroupFailed: () =>
        getToastError("toast.error.create.discount-group"),
      fetchDiscountGroupMembersFailed: () =>
        getToastError("toast.error.fetch.discount-group-members"),
      deleteDiscountGroupFailed: () =>
        getToastError("toast.error.delete.discount-group"),
      deleteDiscountGroupMemberFailed: () =>
        getToastError("toast.error.delete.discount-group-member"),
      addDiscountGroupMemberFailed: () =>
        getToastError("toast.error.add.discount-group-member"),
      fetchPlayersFailed: () => getToastError("toast.error.fetch.players"),
      createBookingFailed: () => getToastError("toast.error.create.booking"),
      fetchReceiptsFailed: () => getToastError("toast.error.fetch.receipts"),
      fetchFacilitiesFailed: () =>
        getToastError("toast.error.fetch.facilities"),
      cancelBookingFailed: () => getToastError("toast.error.cancel.booking"),
      updateResultFailed: () => getToastError("toast.error.update.result"),
      removeGameFailed: () => getToastError("toast.error.remove.game"),
      removeSetFailed: () => getToastError("toast.error.remove.set"),
      cannotReplaceSamePlayer: () =>
        getToastError("toast.error.validation.replace-player"),
      playerAlreadySelected: () =>
        getToastError("toast.error.validation.player-already-selected"),
      createPriceListFailed: () =>
        getToastError("toast.error.create.price-list"),
      saveNameFailed: () => getToastError("toast.error.save.name"),
      fetchSerieFailed: () => getToastError("toast.error.fetch.serie"),
      joinSerieFailed: () => getToastError("toast.error.join.serie"),
      saveDivisionFailed: () => getToastError("toast.error.save.division"),
      createGamesFailed: () =>
        getToastError("toast.error.create.game-for-serie"),
      removeTeamFromDivisionFailed: () =>
        getToastError("toast.error.remove.team-from-division"),
      addTeamToDivisionFailed: () =>
        getToastError("toast.error.add.team-to-division"),
      removeSerieFailed: () => getToastError("toast.error.remove.serie"),

      addTeamToSerieFailed: () =>
        getToastError("toast.error.add.team-to-serie"),
      acceptFriendRequestFailed: () =>
        getToastError("friend-invitation.accept-error-message"),
      deletePriceListFailed: () =>
        getToastError("toast.error.delete.price-list"),
      updatePriceListFailed: () =>
        getToastError("toast.error.update.price-list"),
      createActivityFailed: () =>
        getToastError("toast.error.create.activities"),
      deleteActivityFailed: () => getToastError("activity.admin.delete.error"),
      addPlayerToGeneralActivitiyFailed: () =>
        getToastError("toast.error.addPlayerToGeneralActivitiy"),
      fetchCoinsFailed: () => getToastError("toast.error.fetchCoins"),
      saveCoinsFailed: () => getToastError("toast.error.saveCoins"),
      userRemovedFromActivityFailed: () =>
        getToastError("toast.error.userRemovedFromActivity"),
      facilityDoorOpenTimeAddFailed: () =>
        getToastError("toast.success.facilityDoorOpenTimeAddFailed"),
      finalizeScoreFailed: () => getToastError("toast.score.finalizeFailed"),
      seriesReplacePlayerAlreadyExists: () =>
        getToastError("toast.series.replace.already.exists"),
      bookingRescheduleFailed: () =>
        getToastError("toast.failed.rescheduleBooking"),
      fetchUserPermissionsFailed: () =>
        getToastError("toast.failed.fetchUserPermissions"),
      addTeamToDivisionFailedDueToLockedSeason: () =>
        getToastError("toast.error.add.team-to-division.locked-season"),
      assignUserRoleFailed: () => getToastError("toast.error.assign.role"),
      removeUserRoleFailed: () => getToastError("toast.error.remove.role"),
      unknown: () => getToastError("error.unknown"),
    }),
    [getToastError],
  );

  const toastWarning = useMemo(
    () => ({
      message: (id: MessageId, values?: MessageValues) =>
        getToastWarn(id, values),
      invalidImageFormat: () =>
        getToastWarn("toast.warn.upload.invalid-format"),
      selectionFailed: () => getToastWarn("toast.warn.select.time-slot"),
      minimumSetsValidation: () =>
        getToastWarn("toast.warn.validation.minimum-sets"),
      nameTypeValidation: () => getToastWarn("toast.warn.validation.name-type"),
    }),
    [getToastWarn],
  );

  const toastInfo = useMemo(
    () => ({
      message: (id: MessageId, values?: MessageValues) =>
        getToastInfo(id, values),
      friendRequestSent: () => getToastInfo("toast.info.friend-request-sent"),
    }),
    [getToastInfo],
  );

  return { toastSuccess, toastError, toastWarning, toastInfo };
};
