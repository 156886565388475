import { useCallback } from "react";
import { createPortal } from "react-dom";
import { FormattedMessage, useIntl } from "react-intl";

import { faChevronDown } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { motion } from "framer-motion";
import { useRecoilState, useSetRecoilState } from "recoil";

import {
  I18N_CONFIGS,
  I18nConfig,
  LOCALE_TO_FLAG_MAP,
} from "../../appConstants/i18n";

import { useCurrentUser } from "../../hooks/swr/useCurrentUser";

import { updateUserLocale } from "../../modules/player/services/UserService";

import { i18nConfigState, useAppLocale } from "../../recoil/i18nConfigState";
import { isMobileLanguagePickerOpenState } from "./Header";

export const MobileLanguagePicker = () => {
  const intl = useIntl();
  const appLocale = useAppLocale();
  const { currentUser, mutate } = useCurrentUser();
  const setI18nConfig = useSetRecoilState(i18nConfigState);
  const [isMobileLanguagePickerOpen, setMobileLanguagePickerOpen] =
    useRecoilState(isMobileLanguagePickerOpenState);

  const toggleOpen = useCallback(() => {
    setMobileLanguagePickerOpen(prev => !prev);
  }, []);

  const handleChangeLocale = (locale: I18nConfig["locale"]) => {
    const newI18nConfig = I18N_CONFIGS.find(config => config.locale === locale);

    setI18nConfig(newI18nConfig);

    if (currentUser) {
      mutate(async () => {
        await updateUserLocale(locale);

        return { ...currentUser, locale };
      }, false);
    }
  };

  const flagClasses = "rounded-full w-6 h-6 fib fis";

  if (!isMobileLanguagePickerOpen) {
    return (
      <div
        className="flex items-center"
        onClick={() => setMobileLanguagePickerOpen(true)}
        title={intl.formatMessage({
          id: "navigation.menuitem.select-language",
        })}
      >
        <div className={`${flagClasses} fi-${LOCALE_TO_FLAG_MAP[appLocale]}`} />
        <FontAwesomeIcon icon={faChevronDown} className="ml-2 w-3" />
      </div>
    );
  }

  return createPortal(
    <motion.div
      className={clsx(
        "fixed top-0 z-[998] flex h-full w-screen bg-purewhite pt-16",
      )}
      initial="closed"
      animate="open"
      exit="closed"
      variants={{
        open: {
          opacity: 1,
          transition: { staggerChildren: 0.05, duration: 0.4 },
        },
        closed: { opacity: 0, transition: { duration: 0.2 } },
      }}
    >
      <div className="flex w-full flex-col justify-between border-t border-gray-500 p-4">
        <div>
          <nav className="flex flex-col items-start space-y-4">
            <div className="py-2 text-md text-primary-dark">
              <FormattedMessage id="navigation.menuitem.select-language" />
            </div>
            {I18N_CONFIGS.map((config, i) => (
              <motion.div
                key={config.locale}
                className="flex gap-2 hover:bg-gray-100"
                title={config.label}
                onClick={() => {
                  if (config.locale === appLocale) {
                    toggleOpen();
                    return;
                  }
                  handleChangeLocale(config.locale);
                  toggleOpen();
                }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.2, delay: i * 0.05 }}
              >
                <div>
                  <div className={`${flagClasses} fi-${config.countryCode}`} />
                </div>
                <p className="text-md font-bold text-primary-dark">
                  {config.label}
                </p>
              </motion.div>
            ))}
          </nav>
        </div>
      </div>
    </motion.div>,
    document.body,
  );
};
