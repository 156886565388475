import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faBackward,
  faForward,
  faPlay,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import {
  PaginatorFirstPageLinkType,
  PaginatorLastPageLinkType,
  PaginatorNextPageLinkType,
  PaginatorPageLinksType,
  PaginatorPrevPageLinkType,
  PaginatorTemplate,
} from "primereact/paginator";

interface PaginatorButtonProps {
  options: any;
  icon: IconProp;
  className?: string;
}

const PaginatorButton = ({
  className,
  options,
  icon,
}: PaginatorButtonProps) => (
  <button
    type="button"
    className={clsx(options.className, className)}
    onClick={options.onClick}
    disabled={options.disabled}
  >
    <FontAwesomeIcon
      color={options.disabled ? "var(--secondary)" : "var(--primary)"}
      icon={icon}
      size="xs"
    />
  </button>
);

export default function usePagination(): PaginatorTemplate {
  const FirstPageLink = (options): PaginatorFirstPageLinkType => (
    <PaginatorButton options={options} icon={faBackward} />
  );
  const LastPageLink = (options): PaginatorLastPageLinkType => (
    <PaginatorButton options={options} icon={faForward} />
  );
  const PrevPageLink = (options): PaginatorPrevPageLinkType => (
    <PaginatorButton
      options={options}
      icon={faPlay}
      className="fa-rotate-180"
    />
  );
  const NextPageLink = (options): PaginatorNextPageLinkType => (
    <PaginatorButton options={options} icon={faPlay} />
  );
  const PageLinks = (options): PaginatorPageLinksType => {
    if (options.page === options.currentPage) {
      return (
        <button
          type="button"
          className={clsx("p-paginator-page", "active")}
          onClick={options.onClick}
        >
          {options.currentPage + 1}
        </button>
      );
    }
    return null;
  };

  const defaultTemplate = {
    layout: "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink",
    FirstPageLink,
    PrevPageLink,
    NextPageLink,
    PageLinks,
    LastPageLink,
  };

  return defaultTemplate as PaginatorTemplate;
}
