import {
  AddUserPermissionRequest,
  FacilityAdminUser,
  FacilityRoleUser,
  UserRole,
} from "../models/Permissions";
import { ApiResponse, ApiSingleResponse } from "../models/common";
import { FacilityWithUtc } from "../modules/customer/models/Facility";
import { User } from "../modules/player/models/User";

import { fetchApi } from "./legacyApiClient";

const apiVersion = "customer/v2";

export const getFacilityUsersByRole = async (
  facilityId: FacilityWithUtc["id"],
  role: UserRole,
): Promise<FacilityRoleUser[]> => {
  if (role === UserRole.SuperAdmin) {
    throw new Error("Unauthorized");
  }

  try {
    const response = (await fetchApi({
      method: "GET",
      uri: `${apiVersion}/facility/${facilityId}/usersbyrole/${role}`,
    })) as ApiResponse<FacilityRoleUser>;

    return response.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getFacilityAdminUsers = async (
  facilityId: FacilityWithUtc["id"],
): Promise<FacilityAdminUser[]> => {
  try {
    const response = (await fetchApi({
      method: "GET",
      uri: `${apiVersion}/facility/${facilityId}/adminusers`,
    })) as ApiResponse<FacilityAdminUser>;

    return response.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const addFacilityUserPermission = async (
  payload: AddUserPermissionRequest,
): Promise<FacilityRoleUser> => {
  if (payload.role === UserRole.SuperAdmin) {
    throw new Error("Unauthorized");
  }
  try {
    const response = (await fetchApi({
      method: "POST",
      uri: `${apiVersion}/facility/addpermission`,
      payload,
    })) as ApiSingleResponse<FacilityRoleUser>;

    return response.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const removeFacilityUserPermission = async (
  facilityId: FacilityWithUtc["id"],
  userId: User["id"],
  role: UserRole,
): Promise<boolean> => {
  if (role === UserRole.SuperAdmin) {
    throw new Error("Unauthorized");
  }
  try {
    const response = (await fetchApi({
      method: "DELETE",
      uri: `${apiVersion}/facility/${facilityId}/deletepermission/${userId}/${role}`,
    })) as ApiSingleResponse<boolean>;

    return response.data;
  } catch (e) {
    return Promise.reject(e);
  }
};
