import { useState } from "react";
import { FormattedMessage } from "react-intl";

import { faArrowLeft } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { FacilityWithUtc } from "../../../customer/models/Facility";
import { User } from "../../../player/models/User";
import { SeriesPlayer } from "../../models/Series";

import { useToaster } from "../../../../hooks/common/useToaster";

import { replacePartner } from "../../services/Serie";

import { Button } from "../../../../components/Button";
import { ProfileImageWithFallback } from "../../../../components/ProfileImageWithFallback";
import { ProgressSpinner } from "../../../../components/ProgressSpinner";
import { UserSearch } from "../../../../components/UserSearch";

interface IProps {
  playerOut: SeriesPlayer;
  seriesId: string;
  teamId: string;
  facilityId: FacilityWithUtc["id"];
  onComplete: () => void;
  onCancel: () => void;
}

export const ReplaceTeamMember: React.FC<IProps> = ({
  playerOut,
  seriesId,
  teamId,
  facilityId,
  onComplete,
  onCancel,
}) => {
  const { toastError } = useToaster();
  const [playerIn, setPlayerIn] = useState<User>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleReplace = async () => {
    try {
      setIsLoading(true);
      const response = await replacePartner(
        seriesId,
        facilityId,
        teamId,
        playerOut.id,
        playerIn.id,
      );
      if (response) {
        onComplete?.();
      } else {
        toastError.seriesReplacePlayerAlreadyExists();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <ProgressSpinner />;
  }

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center gap-2">
        <div
          className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full text-primary hover:bg-blue-50"
          onClick={onCancel}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </div>
        <h1 className="text-2xl">
          <FormattedMessage id="series.replace.partner" />
        </h1>
      </div>
      <div className="flex items-center gap-2 opacity-75">
        <ProfileImageWithFallback
          src={playerOut?.profileImage}
          firstName={playerOut?.firstName}
          lastName={playerOut?.lastName}
        />
        {playerOut?.displayName}
      </div>
      <div className="w-full">
        <UserSearch
          facilityId={facilityId}
          onChange={p => setPlayerIn(p?.[0])}
          title="Sök användare"
          translationId="common.search-user"
          displaySelection
          multiSelect={false}
        />
      </div>
      {playerIn && (
        <Button
          className="bg-gradient-to-r from-blue-500 to-blue-700 py-4 pl-5 pr-4 text-sm text-purewhite"
          translationName="series.replace.partner"
          type="default"
          disabled={!playerIn}
          onClick={handleReplace}
        />
      )}
    </div>
  );
};
