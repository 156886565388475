import { useIntl } from "react-intl";

import { faClose } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { DateTime } from "luxon";

import { DateOnly } from "../../../models/DateOnly";
import { DefaultVendorAccess } from "../models/Access";

import { CalendarInput } from "../../../components/CalendarInput";
import { NumberInput } from "../../../components/NumberInput";

interface Props {
  defaultVendorAccessData: DefaultVendorAccess[];
  onDelete: (id: string) => void;
  onChangePinCode: (id: string, e: string) => void;
  onChangeValidFrom: (id: string, validFrom: DateOnly) => void;
  onChangeValidTo: (id: string, validTo: DateOnly | null) => void;
  showErrors?: boolean;
  itemIdsWithInvalidDuration?: string[];
}

export const DefaultFacilityAccessTimeDetailsRow = ({
  onDelete,
  defaultVendorAccessData,
  onChangePinCode,
  onChangeValidFrom,
  onChangeValidTo,
  showErrors,
  itemIdsWithInvalidDuration,
}: Props) => {
  const intl = useIntl();

  return (
    <>
      {defaultVendorAccessData?.map(item => (
        <div className="flex gap-x-5" key={item.id}>
          <div className="flex-auto items-baseline gap-x-5 space-y-2 sm:flex sm:w-3/4">
            <NumberInput
              className="max-w-xl sm:w-1/3"
              name="pinCode"
              label={intl.formatMessage({
                id: "generalActivities.createActivity.pinCode",
              })}
              value={item.pinCode}
              onChange={e => onChangePinCode(item.id, e.target.value)}
              tooltip={intl.formatMessage({
                id: "generalActivities.createActivity.pinCode.tooltip",
              })}
              limitInputDigits
              maxLength={4}
              required
              error={
                showErrors &&
                item.pinCode.length !== 4 &&
                intl.formatMessage({
                  id: "validation.required.pinCode",
                })
              }
            />
            <CalendarInput
              className="max-w-xl sm:w-1/3"
              label={intl.formatMessage({
                id: "facility-settings.doors.pin-code.valid-from-date",
              })}
              required
              selectOtherMonths
              onChange={e =>
                onChangeValidFrom(
                  item.id,
                  DateOnly.fromDateTime(e.target.value),
                )
              }
              value={item.validFrom.toDateTime()}
              minDate={DateTime.now()}
              maxDate={item.validTo?.toDateTime()}
              error={
                showErrors &&
                itemIdsWithInvalidDuration.includes(item.id) &&
                intl.formatMessage({
                  id: "facility-settings.doors.invalid-date-range",
                })
              }
            />
            <CalendarInput
              className="max-w-xl sm:w-1/3"
              label={intl.formatMessage({
                id: "facility-settings.doors.pin-code.valid-to-date",
              })}
              selectOtherMonths
              onChange={e =>
                onChangeValidTo(item.id, DateOnly.fromDateTime(e.target.value))
              }
              value={!item.validTo ? null : item.validTo.toDateTime()}
              minDate={item.validFrom.toDateTime()}
              error={
                showErrors &&
                itemIdsWithInvalidDuration.includes(item.id) &&
                intl.formatMessage({
                  id: "facility-settings.doors.invalid-date-range",
                })
              }
            />
          </div>

          <div
            className={clsx(
              "flex items-center justify-end pt-8 sm:w-1/4 sm:justify-start",
            )}
          >
            <FontAwesomeIcon
              className="cursor-pointer text-error"
              onClick={() => onDelete(item.id)}
              icon={faClose}
            />
          </div>
        </div>
      ))}
    </>
  );
};
