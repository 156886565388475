import useSWR, { SWRConfiguration } from "swr";

import { Series } from "../../modules/game/models/Series";

import { getSeries } from "../../modules/game/services/Serie";

export const useSeries = (
  seriesId?: Series["id"],
  include?: string,
  options?: SWRConfiguration,
) => {
  const { data, ...rest } = useSWR(
    seriesId ? ["series", seriesId, include || ""] : null,
    ([, seriesId, include]) =>
      getSeries(seriesId, include).then(res => res.data),
    options,
  );

  return {
    ...rest,
    series: data,
  };
};
