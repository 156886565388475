import React, { useEffect, useRef } from "react";
import { useIntl } from "react-intl";

import { faSearch } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { debounce } from "lodash";
import { InputText } from "primereact/inputtext";
import styled from "styled-components";

import { Filters } from "./ActivitiesList";

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0.2rem;

  background: var(--gray-200);
  border-radius: var(--border-radius);
`;

const SearchContent = styled.div`
  position: relative;

  svg {
    color: var(--gray-300);
    position: absolute;
    right: 0.5rem;
    top: 0;
    bottom: 0;
    margin: auto 0;
  }
`;

interface IProps {
  filters: Filters;
  setFilters: React.Dispatch<React.SetStateAction<Filters>>;
  abortControllerRef: React.MutableRefObject<AbortController>;
}

const ActivitiesFilters: React.FC<IProps> = ({
  filters,
  setFilters,
  abortControllerRef,
}) => {
  const intl = useIntl();

  // Memory usage optimization
  const debouncedSearch = useRef(
    debounce(searchText => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }

      abortControllerRef.current = new AbortController();

      handleSearchTextChange(searchText);
    }, 500),
  ).current;

  // Clean up
  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  const handleSearchTextChange = (query: string) => {
    setFilters(prevState => ({
      ...prevState,
      query,
    }));
  };

  return (
    <Wrapper>
      <SearchContent>
        <InputText
          defaultValue={filters.query}
          style={{ width: "18rem", maxWidth: "100%" }}
          placeholder={intl.formatMessage({
            id: "activities.search",
            defaultMessage: "Sök efter aktivitet",
          })}
          onChange={e => debouncedSearch(e.target.value)}
        />
        <FontAwesomeIcon icon={faSearch} />
      </SearchContent>
    </Wrapper>
  );
};

export default ActivitiesFilters;
