export const userProfileTabs = {
  UPCOMPING_BOOKINGS: 0,
  PLAYED_GAMES: 1,
  FRIENDS: 2,
  SETTINGS: 3,
  ALL_FRIENDS: 4,
  FAVORITES: 5,
  RECEIPTS: 6,
  FACILITIES: 7,
  ACTIVITIES: 8,
} as const;

export const facilityTabs = {
  GENERAL: 0,
  COURTS: 1,
  ECONOMY: 2,
  GROUPS: 3,
  PRICES: 4,
  FACILITY: 5,
  ROLES: 6,
  SETTINGS: 7,
  COINS: 8,
  STATISTICS: 9,
} as const;

export const skillLevelItems = [
  {
    defaultMessage: "Det är första gången du håller i ett racket.",
    id: "profile-settings.skill-level.1",
    value: 1,
  },
  {
    defaultMessage: "Du är nybörjare men börjar lära dig Padel.",
    id: "profile-settings.skill-level.2",
    value: 2,
  },
  {
    defaultMessage:
      "Du börjar få självförtroende och kan grunderna men tycker det är knepigt med väggstuds och markslag.",
    id: "profile-settings.skill-level.3",
    value: 3,
  },
  {
    defaultMessage:
      "Du har kontroll och kan hålla ett bra tempo. Har du tidigare erfarenhet av racket-sporter hamnar du ofta i denna kategori.",
    id: "profile-settings.skill-level.4",
    value: 4,
  },
  {
    defaultMessage:
      "Du är genomsnittlig. Lagom. Du har spelat ett tag och slår inte bollen i nät allt för ofta. Du är fortfarande lite rostig i serven.",
    id: "profile-settings.skill-level.5",
    value: 5,
  },
  {
    defaultMessage:
      "Du är en flitig fritidsspelare. Bra bandeja och en stark smash. Du har deltagit i en padelturnering eller två.",
    id: "profile-settings.skill-level.6",
    value: 6,
  },
  {
    defaultMessage:
      "Du spelar ganska mycket padel. Du har bra koll på försvar och vet hur man vänder spelet mot motståndaren. Du spelar regelbundet i turneringar och gör specifika padelövningar för att förbättra ditt spel.",
    id: "profile-settings.skill-level.7",
    value: 7,
  },
  {
    defaultMessage:
      "Du är en duktig padelspelare som behärskar taktik och teknik. Du läser spelet korrekt och kan anpassa dig efter motståndaren. Troligtvis är du en av de vassaste spelarna i klubben.",
    id: "profile-settings.skill-level.8",
    value: 8,
  },
  {
    defaultMessage: "Du är riktigt duktig. Tävlar på nationell nivå.",
    id: "profile-settings.skill-level.9",
    value: 9,
  },
  {
    defaultMessage: "Du är grym. Topprankad i världen.",
    id: "profile-settings.skill-level.10",
    value: 10,
  },
];
