export interface Group {
  id: string;
  name?: string;
  type?: GroupType;
  userId?: string;
  externalServiceId?: string;
  createdBy?: string;
  members: string[];
}

export enum GroupType {
  MembershipList = 0,
  Team = 1,
  Booking = 2,
  Discount = 3,
}

export enum GroupTypeStrings {
  STANDARD = "standard",
  DISCOUNT = "discount",
  BOOKING = "booking",
  TEAM = "team",
  MEMBERS = "members",
}

export interface UpdateGroupNameRequest {
  id: string;
  name?: string;
}
