import { FormattedMessage } from "react-intl";

import { InputSwitch } from "primereact/inputswitch";

import { FacilityUserRelationRequest } from "../../../../../modules/player/models/User";

import { useCurrentUser } from "../../../../../hooks/swr/useCurrentUser";

import { updateFacilityUserRelation } from "../../../../../modules/player/services/UserService";

interface Props {
  consentEmail: boolean;
  facilityId: string;
}

export const EmailItem: React.FC<Props> = ({ facilityId, consentEmail }) => {
  const { mutate } = useCurrentUser();

  const handleToggle = async (consentEmail: boolean) => {
    const payload = {
      facilityId,
      consentEmail,
    } as FacilityUserRelationRequest;
    try {
      mutate(async () => {
        return await updateFacilityUserRelation(payload);
      }, false);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="flex flex-wrap gap-4 p-4">
      <InputSwitch
        checked={consentEmail}
        onChange={v => handleToggle(v?.target?.value)}
      />
      <div className="flex flex-col gap-4 sm:flex-row">
        <p className="font-bold">
          <FormattedMessage id="user.facilities.email.consent.title" />
        </p>
        <p className="text-sm text-gray-700">
          <FormattedMessage id="user.facilities.email.consent.description" />
        </p>
      </div>
    </div>
  );
};
