import { saveAs } from "file-saver";

import { DateOnly } from "../../../models/DateOnly";
import { ApiSingleResponse } from "../../../models/common";
import {
  SettlementReport,
  SettlementReportApiResponse,
  SettlementReportResponse,
  SettlementReportResponseApiResponse,
} from "../models/SettlementReport";

import { fetchApi } from "../../../services/legacyApiClient";

const apiVersion = "customer/v2";

export const getSettlementReports = async (
  facilityId: string,
  dateFrom: DateOnly | null,
  dateTo: DateOnly | null,
  pageNumber: number,
  pageSize: number,
): Promise<SettlementReportResponse | null> => {
  try {
    const dateRange =
      dateFrom && dateTo
        ? `&dateFrom=${dateFrom.toJSON()}&dateTo=${dateTo.toJSON()}`
        : "";

    const response = (await fetchApi({
      uri: `${apiVersion}/settlement/${facilityId}?pageNumber=${pageNumber}&pageSize=${pageSize}${dateRange}`,
    })) as ApiSingleResponse<SettlementReportResponseApiResponse>;

    return {
      ...response.data,
      items: response.data.items.map(item =>
        formatSettlementReportFromApi(item),
      ),
    };
  } catch {
    return null;
  }
};

export const downloadSettlementReportPDF = async (
  fortnoxDocumentId: SettlementReport["fortnoxDocumentNumber"],
  fileName: string,
): Promise<void> => {
  try {
    const response = (await fetchApi({
      uri: `${apiVersion}/settlement/settlementPdf/${fortnoxDocumentId}`,
    })) as ApiSingleResponse<string>;

    // decode base64 string, remove space for IE compatibility
    const binary = atob(response.data.replace(/\s/g, ""));
    const len = binary.length;
    const buffer = new ArrayBuffer(len);
    const view = new Uint8Array(buffer);
    for (let i = 0; i < len; i++) {
      view[i] = binary.charCodeAt(i);
    }

    saveAs(new Blob([view], { type: "application/pdf" }), `${fileName}.pdf`);

    return;
  } catch {
    return null;
  }
};

const formatSettlementReportFromApi = (
  report: SettlementReportApiResponse,
): SettlementReport => ({
  ...report,
  fromDate: report.fromDate ? DateOnly.fromISODate(report.fromDate) : undefined,
  toDate: report.toDate ? DateOnly.fromISODate(report.toDate) : undefined,
});
