import { useIntl } from "react-intl";

import { faXmark } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

interface ContainerProps {
  selected: boolean;
}

const Container = styled.div<ContainerProps>`
  padding: 0.5rem;
  font-weight: var(--bold);
  border-radius: 4px;
  border: 2px solid
    ${props => (props?.selected ? "var(--primary)" : "var(--gray-300)")};
  position: relative;
  cursor: pointer;
  user-select: none;
`;

const Remove = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -5px;
  right: -5px;
  width: 10px;
  height: 10px;
  background: var(--primary);
  padding: 0;
  border-radius: 50%;
`;

const RemoveIcon = styled(FontAwesomeIcon)`
  padding: 0;
  margin: 0;
  color: var(--light);
  font-size: 5pt;
`;

interface Props {
  day: number;
  selected: boolean;
  setSelected: any;
  setMouseDown: any;
  mouseDown?: string;
}

export const Weekday: React.FC<Props> = ({
  day,
  selected,
  setSelected,
  setMouseDown,
  mouseDown,
}) => {
  const intl = useIntl();
  const weekdays = [
    { id: "week-day.sunday", defaultMessage: "Söndag" },
    { id: "week-day.monday", defaultMessage: "Måndag" },
    { id: "week-day.tuesday", defaultMessage: "Tisdag" },
    { id: "week-day.wednesday", defaultMessage: "Onsdag" },
    { id: "week-day.thursday", defaultMessage: "Torsdag" },
    { id: "week-day.friday", defaultMessage: "Fredag" },
    { id: "week-day.saturday", defaultMessage: "Lördag" },
  ];

  const weekday = intl.formatMessage({ ...weekdays[day] })?.substring(0, 3);

  const handleMouseDown = () => {
    setMouseDown(selected ? "deselect" : "select");
    setSelected((s: number[]) =>
      s.includes(day) ? s.filter(i => i !== day) : [...s, day],
    );
  };

  const handleMouseOver = () => {
    if (!mouseDown) return;
    setSelected((s: number[]) =>
      mouseDown === "deselect" ? s.filter(i => i !== day) : [...s, day],
    );
  };

  return (
    <Container
      selected={selected}
      onMouseDown={handleMouseDown}
      onMouseOver={handleMouseOver}
      onMouseUp={() => setMouseDown(null)}
    >
      {weekday}
      {selected && (
        <Remove>
          <RemoveIcon icon={faXmark} />
        </Remove>
      )}
    </Container>
  );
};
