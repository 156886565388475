import useSWR, { type SWRConfiguration } from "swr";

import type { FacilityWithUtc } from "../../modules/customer/models/Facility";
import type { User } from "../../modules/player/models/User";

import { getBackupPaymentMethod } from "../../services/facilityUsersService";

export const useBackupPaymentMethod = (
  facilityId: FacilityWithUtc["id"] | null | undefined,
  userId: User["id"] | null | undefined,
  options?: SWRConfiguration,
) => {
  const { data, ...rest } = useSWR(
    facilityId && userId ? ["backupPaymentMethod", facilityId, userId] : null,
    ([, facilityId, userId]) => getBackupPaymentMethod(facilityId, userId),
    options,
  );

  return {
    ...rest,
    backupPaymentMethod: data,
  };
};
