import React from "react";
import { FormattedMessage } from "react-intl";

import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faSpinnerThird } from "@fortawesome/pro-regular-svg-icons";
import {
  FontAwesomeIcon,
  type FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";
import styled from "styled-components";

interface StyleProps {
  hasIcon?: boolean;
  size?: string;
}

const ButtonObject = styled.button<StyleProps>`
  height: ${props => (props.size == "small" ? "0rem" : "3.125rem")};
  cursor: pointer;
  display: flex;
  text-align: center;
  justify-content: ${props => (props.hasIcon ? "space-between" : "center")};
  gap: 1rem;
  font-weight: var(--bold);
  font-size: ${props => (props.size == "small" ? ".9rem" : "1rem")};
  align-items: center;
  padding: ${props => (props.size == "small" ? "1.2em 1.2em" : "1em 1.2em")};
  border-radius: 0.25rem;

  background-color: var(--light);
  border: 2px solid var(--primary);

  &:focus-visible {
    outline: none !important;
  }

  &:hover {
    box-shadow: var(--default-shadow);
  }

  &:disabled {
    cursor: not-allowed;
    background: #f9f9f9;
    border: 2px solid var(--gray-200);
    color: var(--gray-700);

    &:hover {
      box-shadow: none;
    }
  }
`;

const AddButton = styled(ButtonObject)`
  border: 2px dashed var(--primary);
  box-shadow: none;
`;

const PrimaryButton = styled(ButtonObject)`
  color: var(--light) !important;
  background: var(--primary) !important;

  svg {
    color: var(--light) !important;
  }
  &:focus,
  &:hover {
    background: var(--primary-light) 0% 0% no-repeat padding-box !important;
    box-shadow: 1.25em 1.25em 2.5em var(--shadow-primary-color) !important;
    border-color: var(--primary-light) !important;
    svg {
      color: var(--primary-dark) !important;
    }
  }

  &:active {
    background: var(--primary) 0% 0% no-repeat padding-box !important;
    box-shadow: 1.25em 1.25em 2.5em var(--shadow-primary-color) !important;
    svg {
      color: var(--primary-light) !important;
    }
  }
  &:disabled {
    background: var(--gray-300) !important;
    border: 2px solid var(--gray-300) !important;
    color: var(--gray-100) !important;

    &:hover {
      box-shadow: none !important;
      svg {
        color: var(--gray-100) !important;
      }
    }
  }
`;

const DangerButton = styled(ButtonObject)`
  color: var(--danger) !important;
  background: var(--light);
  border: 2px solid var(--danger) !important;

  svg {
    color: var(--danger) !important;
  }
  &:focus,
  &:hover {
    background: var(--danger) 0% 0% no-repeat padding-box !important;
    border: 2px solid var(--danger) !important;
    color: var(--light) !important;
    box-shadow: 1.25em 1.25em 2.5em var(--shadow-primary-color) !important;
    svg {
      color: var(--light) !important;
    }
  }

  &:active {
    background: var(--danger) 0% 0% no-repeat padding-box !important;
    box-shadow: 1.25em 1.25em 2.5em var(--shadow-primary-color) !important;
    svg {
      color: var(--light) !important;
    }
  }
  &:disabled {
    background: var(--gray-300) !important;
    border: 2px solid var(--gray-300) !important;
    color: var(--gray-100) !important;

    &:hover {
      box-shadow: none !important;
      svg {
        color: var(--gray-100) !important;
      }
    }
  }
`;

const SmallButton = styled(ButtonObject)`
  height: 2rem;
  padding: 1rem;
  width: auto;
  box-shadow: none;
  &:hover {
    box-shadow: none;
  }
`;

interface Props {
  children?: React.ReactNode;
  text?: string;
  icon?: IconProp;
  type?: "default" | "primary" | "danger" | "add" | "small";
  className?: string;
  size?: "large" | "small";
  style?: React.CSSProperties;
  disabled?: boolean;
  translationName?: string;
  buttonType?: "button" | "submit" | "reset";
  onClick?: (e: any) => void;
  iconProps?: Omit<FontAwesomeIconProps, "icon">;
  loading?: boolean;
}

const ButtonComponents = {
  default: ButtonObject,
  primary: PrimaryButton,
  danger: DangerButton,
  add: AddButton,
  small: SmallButton,
};

export const Button = ({
  children,
  text,
  type = "default",
  translationName,
  buttonType = "button",
  size = "large",
  style,
  disabled = false,
  className,
  icon,
  onClick,
  iconProps,
  loading,
}: Props) => {
  const Component = ButtonComponents[type];

  if (loading) {
    icon = faSpinnerThird;
    iconProps = { ...iconProps, spinPulse: true };
  }

  return (
    <Component
      size={size}
      hasIcon={!!icon}
      type={buttonType}
      style={style}
      className={className}
      disabled={disabled}
      onClick={e => onClick?.(e)}
    >
      {(text || translationName) && (
        <p className="truncate font-bold">
          {translationName ? (
            <FormattedMessage id={translationName} defaultMessage={text} />
          ) : (
            text
          )}
        </p>
      )}
      {children}
      {icon && <FontAwesomeIcon icon={icon} {...iconProps} />}
    </Component>
  );
};
