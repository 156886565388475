import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

import { FormikErrors, FormikTouched, useFormik } from "formik";
import { DateTime } from "luxon";
import { Divider } from "primereact/divider";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";

import { ICreateSerieFormValues } from "../../../../models/Series";

import { useGenderTypes } from "../../../../../../hooks/useGenderTypes";

import { CalendarDateTimeWrapper } from "../../../../../../components/CalendarDateTimeWrapper";

interface IProps {
  values: ICreateSerieFormValues;
  touched: FormikTouched<ICreateSerieFormValues>;
  errors: FormikErrors<ICreateSerieFormValues>;
  onChange: ReturnType<typeof useFormik>["handleChange"];
  onBlur: (event: React.FocusEvent<any>) => void;
  setFieldValue: ReturnType<typeof useFormik>["setFieldValue"];
}

const AdminCreateSerieFormMainStep: React.FC<IProps> = ({
  values,
  errors,
  touched,
  onChange,
  onBlur,
  setFieldValue,
}) => {
  const { genderTypes } = useGenderTypes();
  const [dropDownTouched, setDropDownTouched] = useState(false);
  const [dropDownClosed, setDropDownClosed] = useState(true);

  useEffect(() => {
    if (!values.startTime) {
      return;
    }

    if (
      values.registrationOpenTo &&
      values.startTime <= values.registrationOpenTo
    ) {
      setFieldValue("registrationOpenTo", values.startTime.minus({ days: 1 }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.startTime]);

  return (
    <div style={{ paddingTop: ".5rem" }}>
      <Divider />

      <h3 style={{ marginTop: "3rem", marginBottom: "1rem" }}>
        <FormattedMessage
          id="series.steps.guide.title.create-series"
          defaultMessage="Skapa serie"
        />
      </h3>

      <div
        className="p-field"
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          marginTop: "1.5rem",
          gap: "3rem",
        }}
      >
        <div className="p-field" style={{ gap: "1rem" }}>
          <label htmlFor="name" className="p-block">
            <FormattedMessage id="common.name" defaultMessage="Name" />
          </label>
          <div>
            <InputText
              id="name"
              name="name"
              defaultValue={values?.name}
              onChange={onChange}
              onBlur={onBlur}
            />
            {touched.name && errors.name && (
              <small className="p-error p-block">{errors.name}</small>
            )}
          </div>
        </div>
      </div>
      <div
        className="p-field"
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          marginTop: "1.5rem",
          gap: "3rem",
        }}
      >
        <div className="p-field" style={{ gap: "1rem" }}>
          <label htmlFor="startTime" className="p-block">
            <FormattedMessage
              id="common.startDate"
              defaultMessage="Startdatum"
            />
          </label>
          <div>
            <CalendarDateTimeWrapper
              selectOtherMonths
              id="startTime"
              name="startTime"
              minDate={DateTime.now()}
              maxDate={values.endTime}
              value={values.startTime}
              onBlur={onBlur}
              onChange={onChange}
              style={{ width: "100%" }}
              readOnlyInput
              showIcon
            />
            {touched.startTime && errors.startTime && (
              <small className="p-error p-block">
                <>{errors.startTime}</>
              </small>
            )}
          </div>
        </div>
        <div className="p-field" style={{ gap: "1rem" }}>
          <label htmlFor="endTime" className="p-block">
            <FormattedMessage id="common.endDate" defaultMessage="Slutdatum" />
          </label>
          <div>
            <CalendarDateTimeWrapper
              selectOtherMonths
              id="endTime"
              name="endTime"
              minDate={values.startTime}
              value={values.endTime}
              onBlur={onBlur}
              onChange={onChange}
              style={{ width: "100%" }}
              readOnlyInput
              showIcon
            />
            {touched.endTime && errors.endTime && (
              <small className="p-error p-block">
                <>{errors.endTime}</>
              </small>
            )}
          </div>
        </div>
      </div>

      <div
        className="p-field"
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          marginTop: "1.5rem",
          gap: "3rem",
        }}
      >
        <div className="p-field" style={{ gap: "1rem" }}>
          <label htmlFor="registrationOpenTo" className="p-block">
            <FormattedMessage
              id="series.input.registration-open-to"
              defaultMessage="Registrering öppen till"
            />
          </label>
          <div>
            <CalendarDateTimeWrapper
              selectOtherMonths
              id="registrationOpenTo"
              name="registrationOpenTo"
              disabled={!values.startTime}
              maxDate={values.startTime?.minus({ days: 1 })}
              value={values.registrationOpenTo}
              onChange={onChange}
              onBlur={onBlur}
              style={{ width: "100%" }}
              readOnlyInput
              showIcon
            />
            {touched.registrationOpenTo && errors.registrationOpenTo && (
              <small className="p-error p-block">
                <>{errors.registrationOpenTo}</>
              </small>
            )}
          </div>
        </div>

        <div className="p-field" style={{ gap: "1rem" }}>
          <label>
            <FormattedMessage id="common.gender" defaultMessage="Kön" />
          </label>
          <div>
            <Dropdown
              id="gender"
              name="gender"
              optionValue="id"
              optionLabel="name"
              options={genderTypes}
              value={values.gender}
              onChange={onChange}
              onBlur={e => {
                onBlur(e);
                setDropDownClosed(true);
              }}
              onFocus={() => {
                setDropDownTouched(true);
                setDropDownClosed(false);
              }}
            />
            {dropDownTouched && dropDownClosed && errors.gender && (
              <small className="p-error p-block">{errors.gender}</small>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminCreateSerieFormMainStep;
