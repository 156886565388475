import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { faQuestionCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormik } from "formik";
import { InputSwitch } from "primereact/inputswitch";
import { Tooltip } from "primereact/tooltip";

import { SeriesDivisionFormValues } from "../../../../../models/Series";

import { useToaster } from "../../../../../../../hooks/common/useToaster";
import { useIsAdmin } from "../../../../../../../hooks/swr/useIsAdmin";
import { useSeries } from "../../../../../../../hooks/swr/useSeries";
import { useFormValidationSchema } from "../../../../../hooks/useFormValidationSchema";

import { createSerieDivision } from "../../../../../services/Serie";

import { Button } from "../../../../../../../components/Button";
import { NumberInput } from "../../../../../../../components/NumberInput";
import { TextInput } from "../../../../../../../components/TextInput";

interface Props {
  seriesId: string;
  facilityId: string;
  onSubmitted?: () => void;
}

const AdminCreateDivisionForm: React.FC<Props> = ({
  seriesId,
  facilityId,
  onSubmitted,
}) => {
  const intl = useIntl();
  const { toastError, toastSuccess } = useToaster();
  const { createDivisionSchema } = useFormValidationSchema();
  const { series, mutate } = useSeries(seriesId, "participants");
  const isAdmin = useIsAdmin();

  const { values, setFieldValue, ...formik } =
    useFormik<SeriesDivisionFormValues>({
      enableReinitialize: true,
      validationSchema: createDivisionSchema,
      initialValues: {
        serieId: seriesId,
        facilityId: facilityId,
        name: "",
        level: 1,
        numberOfTeams: 2,
        teamsMovingUp: 0,
        teamsMovingDown: 0,
        rescheduleRules: series?.rescheduleRules,
      },
      onSubmit: async data => {
        createSerieDivision(data)
          .then(response => {
            if (response.data) {
              toastSuccess.divisionSaved();
              mutate(response.data);
            }
          })
          .catch(toastError.saveDivisionFailed)
          .finally(() => {
            onSubmitted?.();
          });
      },
    });

  return (
    <form onSubmit={formik.handleSubmit} className="flex flex-col gap-4">
      <h3 className="text-primary">
        <FormattedMessage id="series.add-division" />
      </h3>

      <fieldset>
        <label htmlFor="name">
          <FormattedMessage id="common.name" />
        </label>
        <TextInput
          type="text"
          name="name"
          defaultValue={values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors.name}
        />
      </fieldset>

      <fieldset>
        <Tooltip
          target=".level-tooltip"
          content={intl.formatMessage({
            id: "series.input.level-tooltip",
          })}
        />
        <label htmlFor="level">
          <FormattedMessage id="common.level" />
          <i className="pi pi-question-circle level-tooltip ml-2"></i>
        </label>
        <NumberInput
          name="level"
          defaultValue={values.level}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors.level}
        />
      </fieldset>
      <fieldset>
        <Tooltip
          target=".numberOfTeams-tooltip"
          content={intl.formatMessage({
            id: "series.divisions.number-of-teams.tooltip",
          })}
        />
        <label htmlFor="numberOfTeams">
          <FormattedMessage id="series.divisions.number-of-teams" />
          <i className="pi pi-question-circle numberOfTeams-tooltip ml-2"></i>
        </label>
        <NumberInput
          name="numberOfTeams"
          defaultValue={values.numberOfTeams}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors.numberOfTeams}
        />
      </fieldset>

      <fieldset>
        <label htmlFor="teamsMovingUp">
          <FormattedMessage id="series.divisions.teams-moving-up" />
        </label>
        <NumberInput
          name="teamsMovingUp"
          defaultValue={values.teamsMovingUp}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors.teamsMovingUp}
        />
      </fieldset>
      <fieldset>
        <label htmlFor="teamsMovingDown">
          <FormattedMessage id="series.divisions.teams-moving-down" />
        </label>
        <NumberInput
          name="teamsMovingDown"
          defaultValue={values.teamsMovingDown}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors.teamsMovingDown}
        />
      </fieldset>

      {isAdmin && (
        <div className="mt-4 flex flex-col gap-6">
          <div>
            <h4>
              <FormattedMessage id="series.steps.guide.title.reschedule-rules.title" />
            </h4>
            <p>
              <FormattedMessage id="series.steps.guide.title.reschedule-rules.description" />
            </p>
          </div>

          <div className="grid grid-cols-2 gap-x-12 gap-y-8">
            <fieldset>
              <label htmlFor="limitToSelectedCourts">
                <FormattedMessage id="series.creating-series.limitToSelectedCourts" />
              </label>

              <FontAwesomeIcon
                icon={faQuestionCircle}
                id="limitToSelectedCourts-tooltip"
                className="ml-2 inline-block text-base"
              />
              <Tooltip
                className="max-w-prose"
                target="#limitToSelectedCourts-tooltip"
                content={intl.formatMessage({
                  id: "series.creating-series.limitToSelectedCourts.tooltip",
                })}
              />

              <div className="mt-4 flex items-center justify-start gap-4">
                <FormattedMessage id="common.no" />
                <InputSwitch
                  inputId="limitToSelectedCourts"
                  onChange={changeParams => {
                    setFieldValue(
                      "rescheduleRules.limitToSelectedCourts",
                      changeParams.value,
                    );
                  }}
                  name="rescheduleRules.limitToSelectedCourts"
                  checked={values.rescheduleRules.limitToSelectedCourts}
                />
                <FormattedMessage id="common.yes" />
              </div>
            </fieldset>

            <fieldset>
              <label htmlFor="limitToSelectedTime">
                <FormattedMessage id="series.creating-series.limitToSelectedTime" />
              </label>

              <FontAwesomeIcon
                icon={faQuestionCircle}
                id="limitToSelectedTime-tooltip"
                className="ml-2 inline-block text-base"
              />
              <Tooltip
                className="max-w-prose"
                target="#limitToSelectedTime-tooltip"
                content={intl.formatMessage({
                  id: "series.creating-series.limitToSelectedTime.tooltip",
                })}
              />

              <div className="mt-4 flex items-center justify-start gap-4">
                <FormattedMessage id="common.no" />
                <InputSwitch
                  inputId="limitToSelectedTime"
                  onChange={changeParams => {
                    setFieldValue(
                      "rescheduleRules.limitToSelectedTime",
                      changeParams.value,
                    );
                  }}
                  name="rescheduleRules.limitToSelectedTime"
                  checked={values.rescheduleRules.limitToSelectedTime}
                />
                <FormattedMessage id="common.yes" />
              </div>
            </fieldset>

            <fieldset>
              <label htmlFor="blockPrimetime">
                <FormattedMessage id="series.creating-series.blockPrimetime" />
              </label>

              <FontAwesomeIcon
                icon={faQuestionCircle}
                id="blockPrimetime-tooltip"
                className="ml-2 inline-block text-base"
              />
              <Tooltip
                className="max-w-prose"
                target="#blockPrimetime-tooltip"
                content={intl.formatMessage({
                  id: "series.creating-series.blockPrimetime.tooltip",
                })}
              />

              <div className="mt-4 flex items-center gap-4">
                <FormattedMessage id="common.no" />
                <InputSwitch
                  inputId="blockPrimetime"
                  onChange={changeParams => {
                    setFieldValue(
                      "rescheduleRules.limitToLesserPrioPriceCategories",
                      changeParams.value,
                    );
                  }}
                  name="rescheduleRules.limitToLesserPrioPriceCategories"
                  checked={
                    values.rescheduleRules.limitToLesserPrioPriceCategories
                  }
                />
                <FormattedMessage id="common.yes" />
              </div>
            </fieldset>

            <fieldset>
              <label htmlFor="limitToSelectedWeekDays">
                <FormattedMessage id="series.creating-series.limitToSelectedWeekDays" />
              </label>

              <FontAwesomeIcon
                icon={faQuestionCircle}
                id="limitToSelectedWeekDays-tooltip"
                className="ml-2 inline-block text-base"
              />
              <Tooltip
                className="max-w-prose"
                target="#limitToSelectedWeekDays-tooltip"
                content={intl.formatMessage({
                  id: "series.creating-series.limitToSelectedWeekDays.tooltip",
                })}
              />

              <div className="mt-4 flex items-center gap-4">
                <FormattedMessage id="common.no" />
                <InputSwitch
                  inputId="limitToSelectedWeekDays"
                  onChange={changeParams => {
                    setFieldValue(
                      "rescheduleRules.limitToSelectedWeekDays",
                      changeParams.value,
                    );
                  }}
                  name="rescheduleRules.limitToSelectedWeekDays"
                  checked={values.rescheduleRules.limitToSelectedWeekDays}
                />
                <FormattedMessage id="common.yes" />
              </div>
            </fieldset>

            <fieldset>
              <label htmlFor="maxAllowedBookingReschedules">
                <FormattedMessage id="series.reschedule-rules.maxAllowedBookingReschedules" />
              </label>
              <NumberInput
                name="rescheduleRules.maxAllowedBookingReschedules"
                defaultValue={
                  values.rescheduleRules.maxAllowedBookingReschedules
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.errors.rescheduleRules?.maxAllowedBookingReschedules
                }
              />
              {formik.errors["rescheduleRules.maxAllowedBookingReschedules"]}
            </fieldset>
          </div>
        </div>
      )}

      <Button
        className="self-end"
        buttonType="submit"
        type="primary"
        translationName="button.save"
        disabled={!formik.isValid || formik.isSubmitting}
      />
    </form>
  );
};

export default AdminCreateDivisionForm;
