import { useSelectedFacility } from "../../../hooks/swr/useSelectedFacility";

import CustomerCalendar from "../../../modules/checkout/components/Calendar/customer/CustomerCalendar";

export const AdminMobileBookingPage: React.FC = () => {
  const { selectedFacility } = useSelectedFacility();

  if (!selectedFacility) {
    return null;
  }

  return (
    <div className="bg-purewhite p-2">
      <CustomerCalendar
        facility={selectedFacility}
        onChange={() => {
          return;
        }} //mandatory but not used in mobile case.
      />
    </div>
  );
};
