import { FormattedMessage, useIntl } from "react-intl";

import { faInfoCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MDEditor, { commands } from "@uiw/react-md-editor";
import { useFormik } from "formik";

import { CustomerEmailRequest } from "../../../../modules/customer/models/CustomerEmail";
import { useFormValidationSchema } from "../../../../modules/customer/models/CustomerValidationSchemes";
import {
  AdminOverviewUsersFilters,
  User,
} from "../../../../modules/player/models/User";

import { useToaster } from "../../../../hooks/common/useToaster";

import { sendCustomerEmail } from "../../../../modules/player/services/UserService";

import { Button } from "../../../../components/Button";
import { TextInput } from "../../../../components/TextInput";

interface Props {
  facilityId: string;
  users: User[];
  selection: "users" | "all";
  filters: AdminOverviewUsersFilters;
  onCompleted: () => void;
  filteredCount: number;
  query: string;
}

export const AdminSendMessageForm = ({
  facilityId,
  onCompleted,
  users,
  filters,
  selection,
  filteredCount,
  query,
}: Props) => {
  const toaster = useToaster();
  const intl = useIntl();

  const { customerEmailSchema } = useFormValidationSchema();
  const formik = useFormik<CustomerEmailRequest>({
    validationSchema: customerEmailSchema,
    initialValues: {
      subject: "",
      content: intl.formatMessage({
        id: "admin.send.customer.email.initial-values.content",
      }),
      usersList: users?.map(u => u.id),
    },
    onSubmit: async (data: CustomerEmailRequest) => {
      const response = await sendCustomerEmail({
        ...data,
        filter: filters,
        facilityId,
        query,
        isSelectedAll: selection === "all",
      });
      if (response) {
        onCompleted?.();
        toaster.toastSuccess.divisionEmailSent();
      }
    },
    validateOnMount: true,
  });

  return (
    <form
      className="flex h-full flex-col space-y-4"
      onSubmit={formik.handleSubmit}
    >
      <TextInput
        name="subject"
        label={intl.formatMessage({ id: "common.email.subject" })}
        autoFocus
        onChange={formik.handleChange}
      />

      <div className="flex grow" data-color-mode="light">
        <MDEditor
          value={formik.values.content}
          onChange={value => formik.setFieldValue("content", value)}
          visibleDragbar={false}
          highlightEnable={false}
          commands={[
            commands.bold,
            commands.italic,
            commands.strikethrough,
            commands.title,
            commands.divider,
            commands.link,
            commands.hr,
            commands.divider,
            commands.orderedListCommand,
            commands.unorderedListCommand,
          ]}
          className="h-full min-h-[400px] w-full"
        />
      </div>
      <p>
        <FormattedMessage
          id="admin.send.customer.email.name.info"
          values={{
            var: "{$receiver_name}",
            Code: chunk => <code>{chunk}</code>,
          }}
        />
      </p>

      <div>
        <Button
          text={intl.formatMessage({ id: "common.send" })}
          className="inline"
          type="primary"
          onClick={() => {
            formik?.submitForm();
          }}
          disabled={!formik.isValid || formik.isSubmitting}
        />
      </div>
      <p className="text-sm italic">
        <FontAwesomeIcon className="mr-2" icon={faInfoCircle} />
        <FormattedMessage
          id="admin.send.customer.email.receivers.info"
          values={{
            totalCount: selection === "all" ? filteredCount : users?.length,
            Bold: chunk => (
              <span className="font-bold text-primary">{chunk}</span>
            ),
          }}
        />
      </p>
    </form>
  );
};
