import { useMemo } from "react";

import { faCircleInfo } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { uniqueId } from "lodash";
import { MultiSelect, type MultiSelectProps } from "primereact/multiselect";
import { Tooltip } from "primereact/tooltip";

import { Label } from "../Label";

interface SelectInputProps extends MultiSelectProps {
  className?: string;
  error?: string;
  label?: string;
  tooltip?: string;
}

const MultipleSelectInput = ({
  className,
  error,
  label,
  tooltip,
  ...rest
}: SelectInputProps) => {
  const tooltipId = useMemo(
    () => (tooltip ? uniqueId("tooltip") : ""),
    [tooltip],
  );

  const inputId = "id-" + (rest.inputId ?? rest.name ?? uniqueId());

  return (
    <div className={clsx("flex min-w-0 flex-col gap-1.5", className)}>
      {label && (
        <Label htmlFor={inputId} className="flex items-center gap-1">
          {label}
          <span className="text-error">{rest.required ? " *" : ""}</span>

          {tooltip && (
            <>
              <FontAwesomeIcon
                id={tooltipId}
                icon={faCircleInfo}
                className="text-gray-700"
                size="xs"
              />
              <Tooltip
                position="top"
                target={`#${tooltipId}`}
                content={tooltip}
              />
            </>
          )}
        </Label>
      )}

      <MultiSelect
        inputId={inputId}
        panelHeaderTemplate={() => null}
        {...rest}
      />
      {error && <div className="text-error">{error}</div>}
    </div>
  );
};

export default MultipleSelectInput;
