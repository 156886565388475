import { DateTime } from "luxon";

import { DateOnly } from "../../../models/DateOnly";
import { TimeOnly } from "../../../models/TimeOnly";
import { GenderType } from "../../../models/common";
import { PaymentMethodName } from "../../checkout/models/Payment";
import { Price } from "../../checkout/models/Pricing";
import { User } from "../../player/models/User";

import { SerieMatch } from "./SerieMatch";

export enum SeasonStatus {
  Closed = 0,
  OpenForRegistration = 1,
  OpenForRegistrationPlacementLocked = 2,
  Active = 3,
}

export enum MatchStatus {
  Finished,
  Upcoming,
}

export interface ICreateSerieFormValues {
  name: string;
  facilityId: string;
  openForRegistration: boolean;
  registrationOpenTo: DateTime;
  startTime: DateTime;
  endTime?: DateTime;
  gender: number;
  numberOfDivisions?: number;
  numberOfTeamsInDivision?: number;
  numberOfTeamsMovingUp?: number;
  numberOfTeamsMovingDown?: number;
  typeOfSerie?: string;
  hasPrice?: boolean;
  price?: number;
  membersOnly?: boolean;
  rescheduleRules?: RescheduleRules;
}

interface RescheduleRules {
  limitToSelectedCourts: boolean;
  limitToSelectedTime: boolean;
  limitToLesserPrioPriceCategories: boolean;
  limitToSelectedWeekDays: boolean;
  maxAllowedBookingReschedules: number;
}

export interface IEditSerieFormValues {
  id?: string;
  name: string;
  gender: number;
  facilityId: string;
  openForRegistration: boolean;
  openDoor?: boolean;
  startTime: DateTime;
  registrationOpenTo: DateTime;
  hasPrice?: boolean;
  price?: number;
  courtIdsToPlay?: string[];
  endTime?: DateTime;
  numberOfParticipants?: number;
  description?: string;
  rescheduleRules?: RescheduleRules;
}

export interface INewSeasonSerieFormValues
  extends Omit<IEditSerieFormValues, "id"> {
  serieId: string;
}

export interface JoinActivityFormValues {
  activityId: string;
  team?: string[];
  teamCaptainId?: string;
}

export interface SeriesDivisionFormValues {
  serieId: string;
  facilityId: string;
  name: string;
  level: number;
  numberOfTeams: number;
  teamsMovingUp: number;
  teamsMovingDown: number;
  rescheduleRules: RescheduleRules;
}

export interface SeriesScoreboard {
  divisionId: string;
  divisionName: string;
  teamsMovingUp: number;
  teamsMovingDown: number;
  scoreboard: DivisionScoreboard[];
}

export interface SeriesScoreboardApiResponse
  extends Omit<SeriesScoreboard, "scoreboard"> {
  scoreboard: DivisionScoreboardApiResponse[];
}

export interface DivisionScoreboard {
  gameLost: number;
  gameWins: number;
  minusSets: number;
  numberOfEvenGames: number;
  numberOfLosses: number;
  numberOfPlayedMatches: number;
  numberOfTotalMatches: number;
  numberOfWins: number;
  playedGames: SeriesPlayedGames[];
  plusMinusGame: number;
  plusMinusSets: number;
  plusSets: number;
  pointsSetsDrawOvertime: number;
  pointsSetsLost: number;
  pointsSetsLostOvertime: number;
  pointsSetsWon: number;
  pointsSetsWonOvertime: number;
  position: number;
  team: SeriesTeam;
  totalPoints: number;
}

interface DivisionScoreboardApiResponse
  extends Omit<DivisionScoreboard, "team" | "playedGames"> {
  team: SeriesTeamApiResponse;
  playedGames: SeriesPlayedGamesApiResponse[];
}

interface SeriesPlayedGames {
  opponents: SeriesTeam;
  gameScoreOpponents: SeriesGameScore[];
  gameScoreCurrentTeam: SeriesGameScore[];
}

interface SeriesPlayedGamesApiResponse
  extends Omit<SeriesPlayedGames, "opponents"> {
  opponents: SeriesTeamApiResponse;
}

interface SeriesGameScore {
  setNumber: number;
  score: number;
}

interface DivisonResult {
  position: number;
  movingUp: boolean;
  movingDown: boolean;
  totalTeams: number;
  totalPoints: number;
}

export interface IAddTeamToDivisionFormValues {
  serieId: string;
  facilityId: string;
  divisionId: string;
  teamId: string;
}

/* Series */
export interface Series {
  id: string;
  fee: Price;
  description?: string;
  divisions: SeriesDivision[];
  endTime: DateTime;
  facilityId: string;
  facilityLogo: string;
  facilityName: string;
  price?: Price;
  gender: GenderType;
  hasPrice?: boolean;
  imageUrl?: string;
  maxParticipants: number;
  name: string;
  nextSeasonId?: string;
  openForRegistration: boolean;
  previousSeasonId?: string;
  registeredTeams: SeriesTeam[];
  registrationOpenTo: DateTime;
  rescheduleRules: RescheduleRules;
  seasonStatus: SeasonStatus;
  startTime: DateTime;
}

export interface SeriesApiResponse
  extends Omit<
    Series,
    | "divisions"
    | "endTime"
    | "registeredTeams"
    | "registrationOpenTo"
    | "startTime"
  > {
  divisions: SeriesDivisionApiResponse[];
  endTime: string;
  registeredTeams: SeriesTeamApiResponse[] | null;
  registrationOpenTo: string;
  startTime: string;
}

export interface SeriesDivision {
  id: string;
  games: SerieMatch["id"][] | null;
  gamesCreationRules: SeriesMatchCreationRules | null;
  level: number;
  mostRecentScheduleEmailSent: DateTime | null;
  name: string;
  numberOfTeams: number;
  rescheduleRules: RescheduleRules;
  teams: SeriesTeam[];
  teamsMovingDown: number;
  teamsMovingUp: number;
}

export interface SeriesDivisionApiResponse
  extends Omit<
    SeriesDivision,
    "gamesCreationRules" | "mostRecentScheduleEmailSent" | "teams"
  > {
  gamesCreationRules: SeriesMatchCreationRulesApiResponse;
  mostRecentScheduleEmailSent: string | null;
  teams: SeriesTeamApiResponse[];
}

export interface SeriesMatchCreationRules {
  courtsToBePlayed: string[]; // Should be courtIdsToPlay? See GeneralActivities interface.
  firstStartDate: DateOnly;
  firstStartTime: TimeOnly;
  lastStartDate: DateOnly;
  lastStartTime: TimeOnly;
  lengthOfGame: number;
  weekDays: number[];
}

export interface SeriesMatchCreationRulesApiResponse
  extends Omit<
    SeriesMatchCreationRules,
    "firstStartDate" | "firstStartTime" | "lastStartDate" | "lastStartTime"
  > {
  firstStartDate: string;
  firstStartTime: string;
  lastStartDate: string;
  lastStartTime: string;
}

export interface SeriesTeam {
  id: string;
  combinedSkillLevel: number;
  divisionResult?: DivisonResult;
  payments: unknown[];
  registrationDate: DateTime;
  teamCaptainId?: string;
  teamMembers: SeriesPlayer[];
}

export interface SeriesTeamApiResponse
  extends Omit<SeriesTeam, "registrationDate" | "teamMembers"> {
  registrationDate: string;
  teamMembers: SeriesPlayerApiResponse[];
}

export interface SeriesPlayer
  extends Pick<
    User,
    | "id"
    | "displayName"
    | "emailAddress"
    | "firstName"
    | "isPayed"
    | "lastName"
    | "phoneNumber"
    | "profileImage"
    | "skillLevel"
  > {
  isTeamCaptain?: boolean;
  payment?: unknown;
  adminUser: { displayName: string } | null;
  paymentMethod: PaymentMethodName | null;
  lastModified: DateTime | null;
}

export interface SeriesPlayerApiResponse
  extends Omit<SeriesPlayer, "lastModified"> {
  lastModified: string | null;
}

/* Series matches */

export interface SeriesMatchRequest {
  filter?: SeriesMatchRequestFilter;
  roundNumber?: number; // Not implemented yet?
  query?: string; // Not implemented yet?
}

export interface SeriesMatchRequestFilter {
  divisionIds?: string[];
  teamIds?: string[];
  matchStatus?: MatchStatus | null;
}

export interface SeriesMatch {
  id: string;
  homeTeam: SeriesMatchTeam;
  opponentTeam: SeriesMatchTeam;
  courtName: string;
  facilityId: string;
  startTime: DateTime;
  endTime: DateTime;
  finished: boolean;
  sets: SeriesMatchSet[]; // Results, ordered from first played to last played.
  division: SeriesMatchDivision;
  winningTeamId?: string;
  isRescheduleAllowed: boolean;
}

export interface SeriesMatchApiResponse
  extends Omit<SeriesMatch, "endTime" | "startTime"> {
  endTime: string;
  startTime: string;
}

type SeriesMatchDivision = Pick<
  SeriesDivision,
  "id" | "name" | "teamsMovingDown" | "teamsMovingUp"
>;

// Couldnt this team just have an extra field for score instead of a field for team?
// Then we could extend a common Team interface
interface SeriesMatchTeam {
  score: number;
  team: { id: string; players: SeriesMatchPlayer[] };
}
export interface SeriesMatchPlayer
  extends Pick<SeriesPlayer, "firstName" | "lastName" | "skillLevel"> {
  // Some conventions for what to call properties would be good for reusability.

  userId: string; // Should be id to extend User > SeriesPlayer
  imageUrl?: string; // Should be profileImage to extend User > SeriesPlayer
}

interface SeriesMatchSet {
  homeGames: number;
  opponentGames: number;
}

/* Season */
export interface NextSeasonInfo {
  endTime: DateTime;
  facilityId: string;
  facilityName: string;
  price: Price;
  id: string;
  name: string;
  openForRegistration: boolean;
  previousSeasonId: string;
  previousTeam: SeriesTeam;
  registrationOpenTo: DateTime;
  startTime: DateTime;
  team: SeriesTeam;
}

export interface NextSeasonInfoApiResponse
  extends Omit<NextSeasonInfo, "endTime" | "registrationOpenTo" | "startTime"> {
  endTime: string;
  registrationOpenTo: string;
  startTime: string;
}
