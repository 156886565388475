import React from "react";

import { faCheck } from "@fortawesome/pro-light-svg-icons";
import styled from "styled-components";

import { breakpoints } from "../appConstants/common";

import { Button } from "./Button";

const SubmitResetContainer = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column-reverse;
  justify-content: center;
  align-self: flex-end;
  flex: 1 1 auto;
  width: 100%;

  button {
    width: 100%;
    gap: 0.5rem;
    display: flex;
  }

  @media (min-width: ${breakpoints.MOBILE}) {
    margin-top: 0rem;
    justify-content: flex-end;
    flex-direction: row;

    button {
      width: 11rem;
    }
  }
`;

interface Props {
  resetButtonProps?: any;
  submitButtonProps?: any;
  disabled?: boolean;
  onSubmit?: (e?: any) => void;
  onClick?: (e?: any) => void;
  onReset?: (e?: any) => void;
}

export const SubmitResetButtons: React.FC<Props> = ({
  onClick,
  onSubmit,
  onReset,
  disabled = false,
  resetButtonProps,
  submitButtonProps,
}) => (
  <SubmitResetContainer>
    <Button
      text="Återställ"
      buttonType="reset"
      translationName="common.reset"
      onClick={e => onReset?.(e)}
      disabled={disabled}
      {...resetButtonProps}
    />
    <Button
      type="primary"
      buttonType="submit"
      icon={faCheck}
      text="Spara ändringar"
      disabled={disabled}
      translationName="common.save-changes"
      onClick={
        onClick
          ? onClick
          : e => {
              if (onSubmit) {
                e.preventDefault();
                e.stopPropagation();
                onSubmit(e);
              }
            }
      }
      {...submitButtonProps}
    />
  </SubmitResetContainer>
);
