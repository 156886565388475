import { DateOnly } from "../models/DateOnly";
import type {
  BackupPaymentMethod,
  BackupPaymentMethodDTO,
} from "../models/backupPaymentMethod";
import type { FacilityWithUtc } from "../modules/customer/models/Facility";
import type { User } from "../modules/player/models/User";

import { get } from "./apiClient";

const API_VERSION = "v1";

const getEndpointBase = (
  facilityId: FacilityWithUtc["id"],
  userId: User["id"],
) => `/${API_VERSION}/facilities/${facilityId}/users/${userId}`;

export const getBackupPaymentMethod = async (
  facilityId: FacilityWithUtc["id"],
  userId: User["id"],
): Promise<BackupPaymentMethod | null> =>
  get<BackupPaymentMethodDTO>({
    endpoint: `${getEndpointBase(
      facilityId,
      userId,
    )}/settings/payment/backup-payment-method`,
  })
    .then(data => ({
      ...data,
      expiryDate: DateOnly.fromISODate(data.expiryDate),
    }))
    .catch(error => {
      if (error instanceof Response && error.status === 404) {
        return null;
      } else {
        throw error;
      }
    });
