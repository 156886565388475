import React, { useMemo } from "react";

import { faCircleInfo } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { uniqueId } from "lodash";
import { Tooltip } from "primereact/tooltip";

import { Label } from "../Label";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  error?: string;
  inputId?: string;
  label?: string;
  limitInputDigits?: boolean;
  tooltip?: string;
  type?: "number";
}

export const NumberInput: React.FC<Props> = ({
  className,
  label = "",
  tooltip,
  limitInputDigits,
  error,
  ...rest
}) => {
  const wrapperClasses = clsx("grid gap-1.5 text-sm", className);

  const inputClasses = clsx(
    "rounded-10 border bg-purewhite px-3 py-2.5 text-base text-pureblack [appearance:textfield]",
    "focus-visible:border-transparent focus-visible:shadow-input focus-visible:outline-2 focus-visible:outline-primary",
    "disabled:cursor-not-allowed disabled:bg-white disabled:text-gray-700",
    "[&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none",
    error ? "border-error" : "border-gray-50",
  );

  const inputHandler = e => {
    const { value, maxLength } = e.target;

    if (limitInputDigits && String(value).length >= maxLength) {
      e.preventDefault();
      return;
    }
  };

  const tooltipId = useMemo(
    () => (tooltip ? uniqueId("tooltip") : ""),
    [tooltip],
  );

  const inputId = "id-" + (rest.inputId ?? rest.name ?? uniqueId());

  return (
    <div id={rest.id} className={wrapperClasses}>
      {label && (
        <Label htmlFor={inputId} className="flex items-center gap-1">
          {label}
          {rest.required && <span className="text-error"> *</span>}

          {tooltip && (
            <>
              <FontAwesomeIcon
                id={tooltipId}
                icon={faCircleInfo}
                className="text-gray-700"
                size="xs"
              />
              <Tooltip
                position="top"
                target={`#${tooltipId}`}
                content={tooltip}
              />
            </>
          )}
        </Label>
      )}

      <input
        id={inputId}
        type="number"
        onWheel={e => e.currentTarget.blur()}
        className={inputClasses}
        {...rest}
        onBeforeInput={inputHandler}
      />

      {error && <div className="text-error">{error}</div>}
    </div>
  );
};
