import { useCallback, useState } from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";

import { useMsal } from "@azure/msal-react";
import {
  IconLookup,
  faCircleQuestion,
  faMagnifyingGlass,
  faSignOutAlt,
  faWarehouse,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSetRecoilState } from "recoil";

import { adminGetFAQPath, getFAQPath } from "../../helpers/pathHelpers";

import { useIsSuperAdmin } from "../../hooks/permissions";
import { useAppDispatch } from "../../hooks/store/store";

import { applicationInsights } from "../../applicationInsights";
import { useIsAdminMode } from "../../recoil/isAdminModeState";
import { ConfirmationDialog } from "../ConfirmationDialog";
import { isHeaderSearchOpenState } from "./Header";
import { ToolbarLanguagePickerItem } from "./ToolbarLanguagePickerItem";

export const Toolbar = () => {
  const dispatch = useAppDispatch();
  const { instance } = useMsal();
  const intl = useIntl();

  const setIsHeaderSearchOpen = useSetRecoilState(isHeaderSearchOpenState);

  const isAdminMode = useIsAdminMode();
  const isSuperAdmin = useIsSuperAdmin();

  const [isLogoutConfirmationOpen, setIsLogoutConfirmationOpen] =
    useState(false);

  const logout = useCallback(() => {
    applicationInsights.clearAuthenticatedUserContext();
    dispatch({ type: "logout" });
    instance.logoutRedirect({
      postLogoutRedirectUri: process.env.REACT_APP_WEBSITE_URL,
    });
  }, [dispatch, instance]);

  return (
    <>
      <div className="relative flex gap-3 text-gray-700">
        <ToolbarItem
          icon={faMagnifyingGlass}
          onClick={() => setIsHeaderSearchOpen(v => !v)}
          title={intl.formatMessage({ id: "search.profile" })}
        />
        <ToolbarItem
          icon={faCircleQuestion}
          onClick={() =>
            window.open(
              isAdminMode ? adminGetFAQPath() : getFAQPath(),
              "_blank",
            )
          }
          title={intl.formatMessage({ id: "common.faq" })}
        />
        {isAdminMode && isSuperAdmin && (
          <ToolbarItem
            icon={faWarehouse}
            link="/superadmin"
            title={intl.formatMessage({
              id: "navigation.menuitem.superAdmin",
            })}
          />
        )}
        <ToolbarLanguagePickerItem />
        <ToolbarItem
          icon={faSignOutAlt}
          onClick={() => setIsLogoutConfirmationOpen(true)}
          title={intl.formatMessage({ id: "common.sign-out" })}
        />
      </div>
      {isLogoutConfirmationOpen && (
        <ConfirmationDialog
          visible
          onSubmit={logout}
          confirmButtonType="danger"
          onCancel={() => setIsLogoutConfirmationOpen(false)}
          onHide={() => setIsLogoutConfirmationOpen(false)}
          title={intl.formatMessage({ id: "common.sign-out.are-you-sure?" })}
        />
      )}
    </>
  );
};

const toolbarItemClassName =
  "text-inherit transition-colors hover:text-primary relative p-1";

type ToolbarItemProps = {
  icon: IconLookup;
  title: string;
} & (
  | {
      onClick: () => void;
    }
  | {
      link: string;
    }
);

export const ToolbarItem = ({ icon, title, ...props }: ToolbarItemProps) => {
  if ("onClick" in props) {
    return (
      <button
        onClick={props.onClick}
        className={toolbarItemClassName}
        title={title}
        type="button"
      >
        <FontAwesomeIcon icon={icon} />
        <p className="sr-only">{title}</p>
      </button>
    );
  }

  return (
    <Link to={props.link} className={toolbarItemClassName} title={title}>
      <FontAwesomeIcon icon={icon} />
      <p className="sr-only">{title}</p>
    </Link>
  );
};
