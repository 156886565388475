import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import {
  BuyableTypeDetails,
  CoinTransactionLog,
  PaymentTransactionType,
} from "../../../../models/TransactionLogs";
import { BuyableType } from "../../../../modules/checkout/models/Payment";

import { useDateFormat } from "../../../../hooks/useDateFormat";

import { luxonDateFormat } from "../../../../utils/dateFormats";

interface Props {
  logComment: CoinTransactionLog["logComment"];
  buyableType: BuyableType;
  buyableTypeDetails: BuyableTypeDetails;
  paymentTransactionType: PaymentTransactionType;
  facilityId: string;
}

export const CoinLogComment: React.FC<Props> = ({
  logComment,
  buyableType,
  buyableTypeDetails,
  paymentTransactionType,
  facilityId,
}) => {
  const intl = useIntl();
  const { df } = useDateFormat(facilityId);

  if (
    logComment &&
    paymentTransactionType !== PaymentTransactionType.DuePayment
  ) {
    return <div>{logComment}</div>;
  }

  let buyableTypeString = "";

  if (buyableType === BuyableType.Booking) {
    buyableTypeString = intl.formatMessage({
      id: "admin.player-overview.event-log.comment.booking",
    });
  } else if (buyableType === BuyableType.Series) {
    buyableTypeString = "serie";
  } else if (buyableType === BuyableType.Coins) {
    buyableTypeString = "coins";
  } else if (buyableType === BuyableType.Event) {
    buyableTypeString = "event";
  }

  switch (paymentTransactionType) {
    case PaymentTransactionType.None:
      return null;
    case PaymentTransactionType.Purchase:
      return (
        <FormattedMessage
          id="admin.player-overview.event-log.comment.payment-for"
          values={{
            buyableTypeString: buyableTypeString,
            bookingName: buyableTypeDetails.name,
            startDate: df(buyableTypeDetails.startDate, luxonDateFormat),
          }}
        />
      );
    case PaymentTransactionType.Refund:
      return (
        <FormattedMessage
          id="admin.player-overview.event-log.comment.refund-for"
          values={{
            buyableTypeString: buyableTypeString,
            bookingName: buyableTypeDetails.name,
            startDate: df(buyableTypeDetails.startDate, luxonDateFormat),
          }}
        />
      );
    case PaymentTransactionType.DuePayment:
      return (
        <FormattedMessage
          id="admin.player-overview.event-log.comment.due-payment-for"
          values={{
            buyableTypeString: buyableTypeString,
            bookingName: buyableTypeDetails.name,
          }}
        />
      );
    default:
      return null;
  }
};
