import React from "react";

import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { difference } from "lodash";

import { DateOnly } from "../../../../../models/DateOnly";

import { CalendarDateTimeWrapper } from "../../../../../components/CalendarDateTimeWrapper";

interface IProps {
  selectedDate: DateOnly;
  maxDate: DateOnly;
  minDate?: DateOnly;
  onChangeDate: (date: DateOnly) => void;
  allowedWeekDays?: number[];
}

const today = DateOnly.today();

const CustomerCalendarMenu: React.FC<IProps> = ({
  selectedDate,
  maxDate,
  minDate,
  onChangeDate,
  allowedWeekDays,
}) => {
  const prevDay = selectedDate.minus({ days: 1 });
  const nextDay = selectedDate.plus({ days: 1 });

  const prevDisabled = (minDate || today) >= selectedDate;
  const nextDisabled = selectedDate >= maxDate;

  return (
    <div className="flex items-center justify-center">
      <button
        className="group flex items-center p-2 disabled:cursor-not-allowed"
        disabled={prevDisabled}
        onClick={() => !prevDisabled && onChangeDate(prevDay)}
      >
        <FontAwesomeIcon
          className="text-base group-disabled:text-gray-400"
          icon={faChevronLeft}
        />
      </button>

      <CalendarDateTimeWrapper
        showButtonBar
        clearButtonClassName="hidden"
        selectOtherMonths
        showWeek
        className="cursor-pointer"
        inputClassName="bg-transparent text-center border-0 cursor-pointer"
        dateFormat="DD, dd MM"
        minDate={minDate?.toDateTime() || today.toDateTime()}
        maxDate={maxDate.toDateTime()}
        value={selectedDate.toDateTime()}
        onChange={e => onChangeDate(DateOnly.fromDateTime(e.value))}
        disabledDays={
          allowedWeekDays
            ? difference(
                [1, 2, 3, 4, 5, 6, 0],
                allowedWeekDays.map(day => day % 7),
              )
            : []
        }
      />

      <button
        className="group flex cursor-pointer items-center p-2 disabled:cursor-not-allowed"
        disabled={nextDisabled}
        onClick={() => !nextDisabled && onChangeDate(nextDay)}
      >
        <FontAwesomeIcon
          className="text-base group-disabled:text-gray-400"
          icon={faChevronRight}
        />
      </button>
    </div>
  );
};

export default CustomerCalendarMenu;
