import React, { useCallback, useState } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";

import { useIsAuthenticated } from "@azure/msal-react";
import { faCheck } from "@fortawesome/pro-light-svg-icons";

import { updateUserSubscription } from "../modules/player/services/UserService";

import { Button } from "../components/Button";
import { Logo } from "../components/Logo";
import { Title } from "../components/Title";

export const UnsubscribePage: React.FC = () => {
  const { link } = useParams<{ link: string }>();

  const isAuthenticated = useIsAuthenticated();
  const intl = useIntl();

  const [facilityNameUnubscribedFrom, setfacilityNameUnsubscribedFrom] =
    useState("");
  const [successful, setSuccessful] = useState(null);

  const unsubscribe = useCallback(async link => {
    try {
      const facilityName = await updateUserSubscription(link);
      setfacilityNameUnsubscribedFrom(facilityName);
      setSuccessful(true);
    } catch (e) {
      console.error(e.message);
      setSuccessful(false);
    }
  }, []);

  const title =
    facilityNameUnubscribedFrom && successful
      ? intl.formatMessage(
          { id: "unsubscribe.title.successful" },
          { facilityName: facilityNameUnubscribedFrom },
        )
      : intl.formatMessage({ id: "general.error.contact.support" });

  return (
    <div className="flex flex-col items-center justify-center">
      {!isAuthenticated && <Logo className="mb-4 mt-10 w-48" />}
      {successful !== null ? (
        <Title title={title}></Title>
      ) : (
        <>
          <Title
            title={intl.formatMessage({ id: "unsubscribe.title.confirm" })}
          />
          <Button
            type="primary"
            text={intl.formatMessage({ id: "unsubscribe.button.confirm" })}
            onClick={() => unsubscribe(link)}
            icon={faCheck}
          />
        </>
      )}
    </div>
  );
};
