import { DateTime } from "luxon";

import { DateOnly } from "../../../models/DateOnly";
import { TimeOnly } from "../../../models/TimeOnly";
import {
  FacilityWithUtc,
  OpenHours as OpenHoursWithUTC,
} from "../../customer/models/Facility";
import { IBooking } from "../models/Booking";
import { IScheduleSlot } from "../models/Calendar";
import { PriceListResponseWithUTC } from "../models/Pricing";
import { Facility, IBookingWithoutUTC, OpenHours } from "../models/api/booking";
import { IScheduleSlotWithoutUtc } from "../models/api/calendar";
import { PriceListResponse } from "../models/api/pricing";

export function formatOpenHours(openHours: OpenHours[]): OpenHoursWithUTC[] {
  return openHours.map(openHour => ({
    ...openHour,
    startTime: TimeOnly.fromISOTime(openHour.startTime),
    endTime: TimeOnly.fromISOTime(openHour.endTime),
  }));
}

export function formatFacility(facility: Facility): FacilityWithUtc {
  return (
    facility && {
      ...facility,
      openHours: formatOpenHours(facility.openHours),
    }
  );
}

export function formatBooking(booking: IBookingWithoutUTC): IBooking {
  return {
    ...booking,
    startTime: DateTime.fromISO(booking.startTime, { setZone: true }),
    endTime: DateTime.fromISO(booking.endTime, { setZone: true }),
    createdDate: DateTime.fromISO(booking.createdDate, { setZone: true }),
    payments: formatBookingPayments(booking.payments),
  };
}

function formatBookingPayments(
  payments: IBookingWithoutUTC["payments"],
): IBooking["payments"] {
  if (!payments) {
    return [];
  }

  return payments.map(payment => ({
    ...payment,
    lastModified: payment.lastModified
      ? DateTime.fromISO(payment.lastModified, { setZone: true })
      : null,
  }));
}

export function formatBookings(bookings: IBookingWithoutUTC[]): IBooking[] {
  return bookings.map(formatBooking);
}

export function formatPricingResponse(
  pricingData: PriceListResponse,
): PriceListResponseWithUTC {
  return {
    ...pricingData,
    validFrom: DateOnly.fromISODate(pricingData.validFrom),
    validTo: DateOnly.fromISODate(pricingData.validTo),
    schedule: pricingData.schedule.map(schedule => {
      return {
        ...schedule,
        validFrom: TimeOnly.fromISOTime(schedule.validFrom),
        validTo: TimeOnly.fromISOTime(schedule.validTo),
      };
    }),
  };
}

export function formatSlots(slots: IScheduleSlotWithoutUtc[]): IScheduleSlot[] {
  return slots.map(slot => ({
    ...slot,
    startTime: DateTime.fromISO(slot.startTime, { setZone: true }),
    endTime: DateTime.fromISO(slot.endTime, { setZone: true }),
  }));
}
