import { FormattedMessage } from "react-intl";
import { Link, NavLink } from "react-router-dom";

import { getMyProfilePath } from "../../helpers/pathHelpers";

import { useHasAnyFacilityAdminPermission } from "../../hooks/permissions";
import { useCurrentUser } from "../../hooks/swr/useCurrentUser";

import { useIsAdminMode } from "../../recoil/isAdminModeState";
import { ProfileImageWithFallback } from "../ProfileImageWithFallback";
import { AdminModeAndFacilitySelectDropdown } from "./AdminModeAndFacilitySelectDropdown";

export const Player = () => {
  const { currentUser } = useCurrentUser();
  const isAdminMode = useIsAdminMode();
  const { hasAnyFacilityAdminPermission } = useHasAnyFacilityAdminPermission();

  return (
    <div className="flex items-center gap-5">
      {currentUser ? (
        <div className="text-right">
          <NavLink
            to={getMyProfilePath()}
            className="text-md font-bold leading-none text-inherit transition-colors hover:text-primary"
            activeClassName="text-primary"
          >
            {currentUser?.displayName}
          </NavLink>

          {hasAnyFacilityAdminPermission && (
            <AdminModeAndFacilitySelectDropdown />
          )}

          {!hasAnyFacilityAdminPermission && (
            <p className="text-sm">
              {isAdminMode ? (
                <FormattedMessage id="site-mode.admin" />
              ) : (
                <FormattedMessage id="site-mode.player" />
              )}
            </p>
          )}
        </div>
      ) : (
        <div className="flex flex-col items-end gap-1">
          <div className="h-5 w-32 bg-gray-150" />
          <div className="h-4 w-20 bg-gray-150" />
        </div>
      )}

      <Link
        to={getMyProfilePath()}
        className="hidden transition-opacity hover:opacity-80 sm:block"
      >
        <ProfileImageWithFallback
          className="border border-primary-dark"
          width="3.5rem"
          height="3.5rem"
          src={currentUser?.profileImage}
          firstName={currentUser?.firstName}
          lastName={currentUser?.lastName}
        />
      </Link>
    </div>
  );
};
