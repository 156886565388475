import { useHasFacilityOwnerPermission } from "../../../hooks/permissions";

import { VenueStatistics } from "../../../modules/admin/components/VenueStatistics";

export const AdminMobileDashboardPage: React.FC = () => {
  const { hasFacilityOwnerPermission } = useHasFacilityOwnerPermission();

  if (!hasFacilityOwnerPermission) {
    return null;
  }

  return (
    <div className="space-y-2 bg-purewhite p-2">
      <VenueStatistics />
    </div>
  );
};
