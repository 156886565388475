import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router-dom";

import { Divider } from "primereact/divider";

import { MembershipStatus } from "../../../modules/player/models/Membership";

import { useFacilityMemberships } from "../../../hooks/swr/useFacilityMemberships";
import { useUser } from "../../../hooks/swr/useUser";

import { Card } from "../../../components/Card";
import { CardDetails } from "../../../components/CardDetails";
import { ProgressSpinner } from "../../../components/ProgressSpinner";
import { AdminAddCoinsForm } from "./components/AdminAddCoinsForm";
import { AdminPlayerConsentSection } from "./components/AdminPlayerConsentSection";
import { AdminPlayerCustomerInfoSection } from "./components/AdminPlayerCustomerInfo/AdminPlayerCustomerInfoSection";
import { AdminPlayerGroupSection } from "./components/AdminPlayerGroupSection";
import { AdminPlayerManageSection } from "./components/AdminPlayerManageSection";
import { BackupPaymentMethod } from "./components/BackupPaymentMethod";
import { EventLogView } from "./components/EventLogView";

import { useSelectedFacilityId } from "../../../recoil/selectedFacilityIdState";

interface ProfileParams {
  userId?: string;
}

export const AdminPlayerPage: React.FC = () => {
  const selectedFacilityId = useSelectedFacilityId();
  const { userId } = useParams<ProfileParams>();
  const { user, isLoading } = useUser(userId);
  const { memberships } = useFacilityMemberships(selectedFacilityId);
  const [isChangeInCoins, setIsChangeInCoins] = useState<boolean>(false);

  if (isLoading) return <ProgressSpinner />;

  if (!user || !selectedFacilityId)
    return (
      <Card className="h-full">
        <p>
          <FormattedMessage id="toast.error.update.generic" />
        </p>
      </Card>
    );

  return (
    <Card>
      <div className="grid grid-cols-2 gap-x-16 gap-y-8 px-2 pt-16 sm:px-4">
        <div className="col-span-2">
          <AdminPlayerCustomerInfoSection
            user={user}
            isMember={
              memberships?.some(m =>
                m.users?.find(
                  u =>
                    u.userId === userId && u.status === MembershipStatus.Active,
                ),
              ) || false
            }
          />
        </div>

        <Divider className="col-span-2 m-0 border-b border-gray-50" />

        <div className="col-span-2 flex flex-col gap-y-8 xl:col-span-1">
          <AdminAddCoinsForm
            facilityId={selectedFacilityId}
            userId={user?.id}
            setIsChangeInCoins={setIsChangeInCoins}
          />

          <Divider className="m-0 border-b border-gray-50" />

          <div className="max-w-sm space-y-4 xl:max-w-none">
            <h3>
              <FormattedMessage id="common.backup-card" />
            </h3>
            <BackupPaymentMethod userId={user?.id} />
          </div>

          <div className="space-y-4">
            <h3>
              <FormattedMessage id="card-details.user.heading" />
            </h3>
            <CardDetails
              isReadOnly
              facilityId={selectedFacilityId}
              userId={user?.id}
            />
          </div>
        </div>

        <Divider className="col-span-2 m-0 border-b border-gray-50 xl:hidden" />

        <div className="col-span-2 flex flex-col gap-y-8 xl:col-span-1">
          <AdminPlayerConsentSection user={user} />

          <Divider className="col-span-2 m-0 border-b border-gray-50" />

          <AdminPlayerGroupSection
            user={user}
            memberships={memberships?.filter(m =>
              m.users?.find(u => u.userId === userId),
            )}
          />

          {user?.id && (
            <Divider className="col-span-2 m-0 border-b border-gray-50" />
          )}

          <AdminPlayerManageSection userId={user?.id} />
        </div>

        <Divider className="col-span-2 m-0 border-b border-gray-50" />

        <div className="col-span-2">
          <EventLogView
            isChangeInCoins={isChangeInCoins}
            pageSize={10}
            facilityId={selectedFacilityId}
          />
        </div>
      </div>
    </Card>
  );
};
