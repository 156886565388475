import { useCallback, useEffect, useState } from "react";

import useSWR from "swr";

import { FacilityWithUtc } from "../../modules/customer/models/Facility";

import { getPaginatedFacilities } from "../../modules/customer/services/FacilityService";

export const usePaginatedFacilities = (pageSize = 10) => {
  const [facilities, setFacilities] = useState<FacilityWithUtc[]>([]);
  const [nextCursor, setNextCursor] = useState<FacilityWithUtc["id"]>(null);
  const [isLoading, setIsLoading] = useState(false);

  const { data, ...swr } = useSWR(["facilities", pageSize], ([, pageSize]) =>
    getPaginatedFacilities(null, pageSize),
  );

  useEffect(() => {
    if (!data) {
      return;
    }

    // Only set next cursor when on the first "page"
    if (facilities.length > 0) {
      return;
    }

    setNextCursor(data.data.at(-1).id);
  }, [facilities.length, data]);

  const loadMore = useCallback(() => {
    if (!nextCursor) {
      return;
    }
    setIsLoading(true);
    getPaginatedFacilities(nextCursor, pageSize)
      .then(res => {
        setFacilities(prev => [...prev, ...res.data]);

        if (res.data.length < pageSize) {
          return setNextCursor("");
        }

        setNextCursor(res.data.at(-1)?.id);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [nextCursor, pageSize]);

  const reset = useCallback(() => {
    setNextCursor("");
    setFacilities([]);
    swr.mutate();
  }, [swr]);

  return {
    isLoading: swr.isLoading,
    isLoadingMore: isLoading,
    nextCursor: nextCursor,
    facilities:
      typeof data === "undefined" ? undefined : data.data.concat(facilities),
    error: swr.error,
    loadMore,
    reset,
  };
};
