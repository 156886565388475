import useSWR from "swr";

import { FacilityWithUtc } from "../../modules/customer/models/Facility";

import { getPriceLists } from "../../modules/checkout/services/Pricing";

export const usePriceLists = (facilityId?: FacilityWithUtc["id"] | null) => {
  const { data, ...rest } = useSWR(
    facilityId ? ["priceLists", facilityId] : null,
    ([, facilityId]) => getPriceLists(facilityId).then(res => res?.data),
  );

  return {
    ...rest,
    priceLists: data,
  };
};
