import { useCallback, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";

import {
  faTableTennisPaddleBall,
  faTrash,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

import { User } from "../models/User";

import { getProfilePath } from "../../../helpers/pathHelpers";

import { useToaster } from "../../../hooks/common/useToaster";
import { useFacility } from "../../../hooks/swr/useFacility";

import { deleteFriend } from "../services/FriendsService";

import { Button } from "../../../components/Button";
import { Card } from "../../../components/Card";
import { ConfirmationDialog } from "../../../components/ConfirmationDialog";
import { ProfileImageWithFallback } from "../../../components/ProfileImageWithFallback";

interface Props {
  friend: User;
  onUpdate?: () => void;
}

const CenteredFlex = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Container = styled(Card)`
  position: relative;
  justify-content: flex-end !important;
  height: 13rem;
  padding: 0;
  gap: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 1.25rem 0.25rem 4.5rem rgba(50, 50, 50, 0.1);
`;

const SkillLevel = styled(CenteredFlex)`
  padding: 1rem;
  width: 100%;
  gap: 0.5rem;
  flex-direction: row;
  border-top: 2px solid var(--gray-200);
  font-weight: 200;
  font-size: 0.8rem;

  svg {
    color: var(--primary);
  }
`;

const ActionButton = styled(Button)`
  position: absolute;
  top: 0.8rem;
  border: none;
  width: 1rem;
  width: 1rem;
  padding: 0;
  height: 1rem;

  svg {
    font-size: 0.95rem;
  }
`;

const DeleteFriendButton = styled(ActionButton)`
  left: 1rem;

  svg {
    color: var(--gray-300);
  }
  &:hover svg {
    color: #d30707;
  }
`;

export const FriendCard = ({ friend, onUpdate }: Props) => {
  const intl = useIntl();
  const { toastError, toastSuccess } = useToaster();
  const { facility } = useFacility(friend?.favoriteFacilityId, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  const [showConfirmation, setShowConfirmation] = useState(false);

  const onDeleteFriend = useCallback(async () => {
    try {
      await deleteFriend(friend?.id);
      onUpdate?.();
      toastSuccess.friendDeleted();
    } catch {
      toastError.deleteFriendFailed();
    } finally {
      setShowConfirmation(false);
    }
  }, [friend?.id, friend?.displayName]);

  return (
    <>
      <Container>
        <div className="flex flex-col items-center justify-center gap-4">
          <DeleteFriendButton
            icon={faTrash}
            onClick={() => setShowConfirmation(true)}
          />
          <ProfileImageWithFallback
            width="4rem"
            height="4rem"
            src={friend?.profileImage}
            firstName={friend?.firstName}
            lastName={friend?.lastName}
          />
          <div className="flex flex-col items-center justify-center">
            <Link to={getProfilePath(friend.id)}>{friend?.displayName}</Link>
            <small className="text-gray-700">{facility?.name}</small>
          </div>
        </div>
        <SkillLevel>
          <FontAwesomeIcon icon={faTableTennisPaddleBall} />
          <p>
            <FormattedMessage
              id="user-profile.friends.skill-level"
              defaultMessage="Skicklighet: {skillLevel}"
              values={{ skillLevel: friend?.skillLevel }}
            />
          </p>
        </SkillLevel>
      </Container>

      {showConfirmation && (
        <ConfirmationDialog
          visible
          onHide={() => setShowConfirmation(false)}
          onCancel={() => setShowConfirmation(false)}
          onSubmit={() => onDeleteFriend()}
          title={intl.formatMessage({
            id: "profile.button.remove-friend",
          })}
          text={
            <FormattedMessage
              id="user-profile.delete-friend.confirmation"
              values={{ name: friend?.displayName }}
            />
          }
          confirmText={intl.formatMessage({
            id: "profile.button.remove-friend",
          })}
          confirmButtonType="danger"
        />
      )}
    </>
  );
};
