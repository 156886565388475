import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { faCircleInfo, faWarning } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InputSwitch } from "primereact/inputswitch";
import { Tooltip } from "primereact/tooltip";

import { User } from "../../../../player/models/User";
import { SerieMatch } from "../../../models/SerieMatch";
import { Series } from "../../../models/Series";

import { useToaster } from "../../../../../hooks/common/useToaster";
import { useDateFormat } from "../../../../../hooks/useDateFormat";

import { getUsers } from "../../../../player/services/UserService";
import {
  closeSeason,
  fetchUnfinishedGames,
  hasUnfinishedGames,
} from "../../../services/Serie";

import { Button } from "../../../../../components/Button";
import { Dialog } from "../../../../../components/Dialog";
import { ProgressSpinner } from "../../../../../components/ProgressSpinner";
import { UsersList } from "../../../../../components/UsersList";

import { luxonDateTimeFormat } from "../../../../../utils/dateFormats";

interface Props {
  serie: Series;
  visible: boolean;
  onHide: () => void;
  onChange: () => void;
}

export const CloseSeason: React.FC<Props> = ({
  serie,
  visible,
  onHide,
  onChange,
}) => {
  const intl = useIntl();
  const toaster = useToaster();
  const { df } = useDateFormat(serie?.facilityId);
  const [unfinishedGames, setUnfinishedGames] = useState<SerieMatch[]>();
  const [hasCheckedUnfinishedGames, setHasCheckedUnfinishedGames] =
    useState<boolean>(false);
  const [users, setUsers] = useState<User[]>();
  const [sendEmail, setSendEmail] = useState(true);

  useEffect(() => {
    const abortController = new AbortController();

    const checkUnfinished = async (id: string) => {
      const hasUnfinished = (
        await hasUnfinishedGames(id, abortController.signal)
      )?.data;
      if (!hasUnfinished) {
        if (abortController.signal.aborted) return;
        return setHasCheckedUnfinishedGames(true);
      }

      const unfinished = (
        await fetchUnfinishedGames(id, abortController.signal)
      )?.data;
      const playersWithGames = unfinished
        ?.flatMap(g => [
          ...(g.homeTeam?.team?.players ?? []),
          ...(g.opponentTeam?.team?.players ?? []),
        ])
        ?.map(p => p?.userId);
      const users = await getUsers(playersWithGames, abortController.signal);

      if (!abortController.signal.aborted) {
        setUsers(users);
        setUnfinishedGames(unfinished);
        setHasCheckedUnfinishedGames(true);
      }
    };

    if (visible && serie) {
      checkUnfinished(serie?.id);
    }

    return () => abortController.abort();
  }, [visible, serie]);

  const handleClose = async () => {
    try {
      await closeSeason(serie?.id, sendEmail);
    } catch {
      toaster.toastError.unknown();
    }
    onHide?.();
    onChange?.();
  };

  return (
    <Dialog visible={visible} onHide={onHide}>
      <h3 className="mb-2">
        <FormattedMessage
          id="admin.serie.season.close.title"
          values={{ name: serie?.name }}
        />
      </h3>
      <p>
        <FormattedMessage id="admin.serie.season.close.description" />
      </p>
      {!hasCheckedUnfinishedGames && <ProgressSpinner />}
      {hasCheckedUnfinishedGames && unfinishedGames && (
        <>
          <div className="my-4 flex items-center space-x-2">
            <FontAwesomeIcon icon={faWarning} className="text-lg" />
            <h4>
              <FormattedMessage id="admin.serie.season.close.unfinished.title" />
            </h4>
          </div>
          <p className="mb-4 font-bold">
            <FormattedMessage id="admin.serie.season.close.unfinished.description" />
          </p>
          <ol className="divide-y">
            {unfinishedGames?.map(g => (
              <li key={g.id} className="py-2">
                <div className="flex items-center">
                  <strong>{df(g.startTime, luxonDateTimeFormat)}</strong>
                  <p className="ml-2">{g.division?.name}</p>
                </div>
                <div></div>
                <p>
                  <FormattedMessage
                    id="admin.serie.season.close.unfinished.game.teams"
                    values={{
                      homeTeam: `${g.homeTeam?.team?.players?.[0]?.lastName} & ${g.homeTeam?.team?.players?.[1]?.lastName}`,
                      opponentTeam: `${g.opponentTeam?.team?.players?.[0]?.lastName} & ${g.opponentTeam?.team?.players?.[1]?.lastName}`,
                      bold: chunk => <span className="font-bold">{chunk}</span>,
                    }}
                  />
                </p>
              </li>
            ))}
          </ol>
          <UsersList isDisabled users={users} includeContact />
        </>
      )}

      <div className="mt-5 flex items-center justify-end gap-5 text-sm font-bold text-gray-700">
        <Tooltip
          position="top"
          target="#sendEmailTarget"
          content={intl.formatMessage({
            id: "admin.serie.season.close.email-tooltip",
          })}
        />

        <div className="flex items-center gap-2">
          <label htmlFor="sendEmail">
            <FormattedMessage id="admin.send.participant.email" />
          </label>
          <FontAwesomeIcon
            id="sendEmailTarget"
            icon={faCircleInfo}
            className="text-gray-700"
            size="xs"
          />

          <InputSwitch
            inputId="sendEmail"
            checked={sendEmail}
            onChange={() => setSendEmail(!sendEmail)}
          />
        </div>
      </div>

      {hasCheckedUnfinishedGames && (
        <div className="my-4 flex justify-end space-x-2">
          <Button
            type="default"
            size="small"
            onClick={() => onHide()}
            text={intl.formatMessage({ id: "common.cancel" })}
          />
          <Button
            type="primary"
            size="small"
            onClick={handleClose}
            text={intl.formatMessage({
              id: unfinishedGames
                ? "admin.serie.season.close.anyway.cta"
                : "admin.serie.season.close.current.cta",
            })}
          />
        </div>
      )}
    </Dialog>
  );
};
