import React, { useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";

import { ColumnProps, Column as PrimeReactColumn } from "primereact/column";
import {
  DataTableProps,
  DataTable as PrimeReactDataTable,
} from "primereact/datatable";
import styled from "styled-components";

import useBackEndPagination from "../../hooks/common/useBackEndPagination";

interface Column extends ColumnProps {
  id?: string;
  defaultMessage?: string;
  translationValues?: any;
  isSelectionColumn?: boolean;
}

interface Props extends DataTableProps {
  data: any[];
  columns: Column[];
  pagination?: boolean;
  rowsPerPage?: number;
  ref?: any;
  useColumnBorder?: boolean;
  totalNumberOfRecords?: number;
}

interface StyleProps {
  $useColumnBorder?: boolean;
}

const StyledDataTable = styled(PrimeReactDataTable)<StyleProps>`
  .p-sortable-column:focus {
    box-shadow: none;
  }

  .p-sortable-column.p-highlight,
  .p-sortable-column.p-highlight .p-sortable-column-icon {
    color: $primary !important;
  }
  thead th {
    background-color: var(--gray-100) !important;
    .p-column-title {
      font-weight: 700;
      color: var(--gray-400);
    }
    border-left: ${props =>
      props.$useColumnBorder && "1px solid white !important"};
    }
  }
  tbody {
    tr:nth-child(even) {
      background-color: var(--gray-100);
    }
    td {
      color: black;
      padding: 1rem 1rem !important;
      &:not(:nth-child(1)) {
        border-left: ${props =>
          props.$useColumnBorder ? "1px solid white" : "none"};
      }
    }
  }

  .p-paginator-page {
    border: 1px solid var(--primary-light) !important;
    margin: 0.24rem !important;
    color: var(--primary) !important;
    font-weight: var(--bold);
  }

  .p-highlight {
    color: var(--light) !important;
  }
`;

// eslint-disable-next-line react/display-name
export const BackEndPaginatedDataTable: React.FC<Props> = React.forwardRef(
  (
    {
      data = [],
      columns = [],
      pagination = false,
      rowsPerPage = 10,
      useColumnBorder = false,
      totalNumberOfRecords = 0,
      ...tableProps
    },
    ref: any,
  ) => {
    const template = useBackEndPagination(totalNumberOfRecords, rowsPerPage);
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(rowsPerPage);

    const onPage = useCallback(event => {
      setFirst(event.first);
      setRows(event.rows);
    }, []);

    return (
      <StyledDataTable
        ref={ref}
        $useColumnBorder={useColumnBorder}
        value={data}
        emptyMessage={
          <FormattedMessage
            id="common.no-data-to-show"
            defaultMessage="Ingen data att visa"
          />
        }
        responsiveLayout="scroll"
        {...(pagination &&
          totalNumberOfRecords && {
            paginator: true,
            paginatorTemplate: template,
            value: data,
            first,
            rows,
            onPage,
          })}
        {...tableProps}
      >
        {columns.map(
          (
            {
              id,
              defaultMessage,
              translationValues,
              isSelectionColumn,
              ...props
            },
            key,
          ) => (
            <PrimeReactColumn
              key={key}
              {...(tableProps.selectionMode &&
                isSelectionColumn && { selectionMode: "multiple" })}
              {...(id &&
                defaultMessage && {
                  header: (
                    <FormattedMessage
                      id={id}
                      defaultMessage={defaultMessage}
                      values={translationValues}
                    />
                  ),
                })}
              {...props}
            />
          ),
        )}
      </StyledDataTable>
    );
  },
);
