import React, { useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import {
  faArrowAltCircleRight,
  faClock,
  faStopwatch,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import { DateTime } from "luxon";
import { useRecoilState, useRecoilValue } from "recoil";

import {
  BookingType,
  ICreateBookingFormValues,
  PaymentType,
  PlayersFilterType,
} from "../../../../../models/Booking";
import { IScheduleSlot } from "../../../../../models/Calendar";
import { SlotPrice } from "../../../../../models/Pricing";
import {
  AvailableTimes,
  AvailableTimesAdminParameters,
  TimeEntry,
  TimeInterval,
} from "../../../../../models/schedule";

import {
  adminGetMobileBookingFormPath,
  getCheckoutPath,
} from "../../../../../../../helpers/pathHelpers";

import { useToaster } from "../../../../../../../hooks/common/useToaster";
import { useCurrentUser } from "../../../../../../../hooks/swr/useCurrentUser";
import { useCurrencyFormat } from "../../../../../../../hooks/useCurrencyFormat";
import { useDateFormat } from "../../../../../../../hooks/useDateFormat";

import { createOrder } from "../../../../../../../services/myOrdersService";
import {
  createBooking,
  rescheduleBooking,
} from "../../../../../services/Booking";
import { getSlotPrice } from "../../../../../services/Pricing";
import {
  getAvailableTimes,
  getAvailableTimesForAdmin,
} from "../../../../../services/Schedule";

import { Button } from "../../../../../../../components/Button";
import { ConfirmationDialog } from "../../../../../../../components/ConfirmationDialog";
import { Dialog } from "../../../../../../../components/Dialog";
import { RescheduleBox } from "../../../components/RescheduleBox";
import { SlotTimeItem } from "./components/SlotTimeItem";

import {
  bookingDataState,
  facilityDataState,
  isRescheduleState,
} from "../../../../../../../recoil/Customer/rescheduleState";
import { isAdminModeState } from "../../../../../../../recoil/isAdminModeState";
import { luxonTimeFormat } from "../../../../../../../utils/dateFormats";

interface Props {
  baseSlot: IScheduleSlot | IScheduleSlot[];
  facilityId: string;
  courtId: string;
  onHide: () => void;
  bookingType: BookingType;
  nrOfSlots?: number;
  bookingId?: string;
  setShowSlotsSelector?: (arg: boolean) => void;
}

const startTimeSlotMinuteOffsets = [-30, 0, 30];
const endTimeSlotMinuteOffsets = [60, 90, 120];

export const SlotsSelector: React.FC<Props> = ({
  baseSlot,
  facilityId,
  courtId,
  onHide,
  bookingType,
  nrOfSlots,
  bookingId,
  setShowSlotsSelector,
}) => {
  const slot = baseSlot as IScheduleSlot;
  const history = useHistory();
  const intl = useIntl();
  const [selectedStartTime, setSelectedStartTime] = useState<DateTime>();
  const [selectedEndTime, setSelectedEndTime] = useState<DateTime>();
  const [slotTimes, setSlotTimes] = useState<TimeEntry[]>();
  const [price, setPrice] = useState<SlotPrice>();
  const [availableTimes, setAvailableTimes] = useState<AvailableTimes>(null);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const facilityData = useRecoilValue(facilityDataState);
  const bookingData = useRecoilValue(bookingDataState);
  const [isReschedule, setIsReschedule] =
    useRecoilState<boolean>(isRescheduleState);
  const { cf } = useCurrencyFormat(price?.price.currencyCode);
  const [isAdminMode] = useRecoilState(isAdminModeState);

  const { currentUser } = useCurrentUser();

  const startTimeSlots: TimeEntry[] = slotTimes
    ? _.chain(slotTimes)
        ?.groupBy(t => t.startTime)
        ?.map(group => {
          if (group.length === 1) {
            return group.at(0);
          }

          const groupWithoutError = group.find(t => !t.error);

          if (groupWithoutError) {
            return groupWithoutError;
          }

          return group.at(0);
        })
        .value()
    : [];

  const endTimeSlots: TimeEntry[] = selectedStartTime
    ? slotTimes?.filter(t => t?.startTime.equals(selectedStartTime))
    : [];

  const handleGoToPayment = async () => {
    const data: ICreateBookingFormValues = {
      facilityId: facilityId,
      courtId: courtId,
      startTime: selectedStartTime,
      endTime: selectedEndTime,
      type: bookingType ?? BookingType.Regular,
      organizerId: currentUser?.id,
      isOrganizerParticipant: true,
      participants: [currentUser?.id],
      paymentType: PaymentType.Solid,
      playersFilterType: PlayersFilterType.Specific,
      gender: null,
      ageGroup: null,
      skillLevelLower: null,
      skillLevelUpper: null,
      price: price?.price,
    };
    try {
      if (isAdminMode) {
        history.push({
          pathname: adminGetMobileBookingFormPath(),
          state: data,
        });
      } else {
        const { data: booking } = await createBooking(data, facilityId);

        const order = await createOrder(facilityId, {
          $type: "booking",
          bookingId: booking.id,
        });
        history.push(getCheckoutPath(order.id));
      }
    } catch {
      toastError.createBookingFailed();
    }
  };

  const handleSelectStartTime = (t: TimeEntry) => {
    if (!t.error) {
      setSelectedStartTime(t.startTime);
      setSelectedEndTime(null);
      setPrice(null);
    }
  };

  const handleSelectEndTime = (t: TimeEntry) => {
    if (!t.error) {
      setSelectedEndTime(t.endTime);
    }
  };

  useEffect(() => {
    if (slotTimes) {
      const baseSlot = slotTimes?.find(
        t => t?.startTime.equals(slot?.startTime) && !t?.error,
      );
      if (baseSlot) return setSelectedStartTime(baseSlot?.startTime);

      const firstAvailableSlot = slotTimes?.find(t => !t?.error);
      if (firstAvailableSlot) {
        setSelectedStartTime(firstAvailableSlot?.startTime);
      }
    }
  }, [slot, slotTimes]);

  useEffect(() => {
    const abortController = new AbortController();
    const checkTimes = async () => {
      const intervals = startTimeSlotMinuteOffsets.flatMap(startOffset => {
        return endTimeSlotMinuteOffsets.map(endOffset => {
          return {
            startTime: slot?.startTime.plus({ minutes: startOffset }),
            endTime: slot?.startTime.plus({ minutes: startOffset + endOffset }),
          } as TimeInterval;
        });
      });

      const data: AvailableTimesAdminParameters = {
        courtId,
        facilityId,
        bookingType,
        reschedulingBookingId: isReschedule && bookingId,
        intervals,
      };

      try {
        const res = isAdminMode
          ? await getAvailableTimesForAdmin(data, abortController.signal)
          : await getAvailableTimes(data, abortController.signal);
        if (abortController.signal.aborted) return;
        setSlotTimes(res?.data?.entries);
        setAvailableTimes(res?.data);
      } catch (e) {
        console.log(e);
      }
    };

    checkTimes();

    return () => abortController.abort();
  }, [isAdminMode, facilityId, courtId, bookingType, slot?.startTime]);

  useEffect(() => {
    const abortController = new AbortController();
    const fetchPrice = async () => {
      const response = (
        await getSlotPrice(
          facilityId,
          courtId,
          selectedStartTime,
          selectedEndTime,
          abortController.signal,
        )
      )?.data;

      if (!abortController.signal.aborted) {
        setPrice(response);
      }
    };

    if (selectedStartTime && selectedEndTime) {
      fetchPrice();
    }

    return () => abortController.abort();
  }, [selectedStartTime, selectedEndTime]);

  const { toastSuccess, toastError } = useToaster();
  const { df } = useDateFormat(facilityId);

  const handleSubmit = async () => {
    try {
      await rescheduleBooking(bookingData.id, {
        facilityId: availableTimes?.facilityId,
        courtId: availableTimes?.courtId,
        endTime: formattedEndTime,
        startTime: selectedStartTime,
      });
      toastSuccess.bookingRescheduleSuccess();
      setShowSlotsSelector(false);
      setIsReschedule(false);
      history.goBack();
    } catch (e) {
      toastError.bookingRescheduleFailed();
      setShowSlotsSelector(false);
      console.log(e);
    }
  };

  const newCourtName = facilityData?.bookableEntities?.find(
    c => c?.id === availableTimes?.courtId,
  )?.name;

  const formattedEndTime = useMemo(() => {
    if (!isReschedule || !selectedStartTime || !nrOfSlots) return;
    return selectedStartTime.plus({ minutes: nrOfSlots * 30 });
  }, [selectedStartTime]);

  return (
    <>
      <Dialog
        maximized={false}
        key={slot.startTime.toString()}
        position="bottom"
        dismissableMask
        visible
        onHide={onHide}
        blockScroll
      >
        <div className="space-between flex grow flex-col">
          <div className="my-4 flex gap-1 font-bold">
            <div>
              <FontAwesomeIcon icon={faClock} />
            </div>
            <FormattedMessage id="common.start-time" />
          </div>
          <div className="mb-8 flex space-x-4">
            {startTimeSlots?.map(t => (
              <SlotTimeItem
                key={t.startTime.toString()}
                isSelected={selectedStartTime?.equals(t.startTime)}
                isAvailable={!t.error}
                timeString={df(t.startTime, luxonTimeFormat)}
                onClick={() => handleSelectStartTime(t)}
              />
            ))}
          </div>

          {!!endTimeSlots?.length && !isReschedule && (
            <>
              <div className="my-4 flex gap-1 font-bold">
                <div>
                  <FontAwesomeIcon icon={faStopwatch} />
                </div>
                <FormattedMessage id="common.game-duration" />
              </div>
              <div className="flex space-x-4">
                {endTimeSlots?.map(t => (
                  <SlotTimeItem
                    key={t.endTime.toString()}
                    isSelected={selectedEndTime?.equals(t?.endTime)}
                    isAvailable={!t.error}
                    timeString={df(t.endTime, luxonTimeFormat)}
                    duration={
                      t?.endTime.diff(selectedStartTime, "minutes").minutes
                    }
                    onClick={() => handleSelectEndTime(t)}
                  />
                ))}
              </div>
            </>
          )}

          <div className="my-4 flex">
            {price ? (
              <p className="fond-bold text-base">
                <FormattedMessage
                  id="payment.total.price.whereof.booking"
                  values={{
                    price: cf(
                      price?.individualPrice.valueInclTax +
                        price?.individualFee.valueInclTax,
                    ),
                    bookingFee: cf(price?.individualFee.valueInclTax),
                  }}
                />
              </p>
            ) : (
              <div className="h-[24px]"></div>
            )}
          </div>
        </div>

        {isReschedule ? (
          <Button
            className="w-full"
            text="Forsätt"
            translationName="common.continue"
            onClick={() => setShowConfirmation(true)}
          />
        ) : (
          <Button
            className="w-full"
            disabled={!selectedStartTime || !selectedEndTime}
            translationName={
              isAdminMode
                ? "navigation.menuitem.booking"
                : "common.continue-to-payment"
            }
            icon={faArrowAltCircleRight}
            onClick={handleGoToPayment}
          />
        )}

        {showConfirmation && (
          <ConfirmationDialog
            confirmText={intl.formatMessage({
              id: "games.reschedule",
              defaultMessage: "Omboka",
            })}
            visible={showConfirmation}
            onHide={() => setShowConfirmation(false)}
            onCancel={() => setShowConfirmation(false)}
            onSubmit={handleSubmit}
          >
            <RescheduleBox
              facilityId={bookingData?.facility.id}
              startTime={bookingData?.startTime}
              endTime={bookingData?.endTime}
              courtName={bookingData?.court?.name}
              isOldTime
            />
            <RescheduleBox
              facilityId={bookingData?.facility.id}
              startTime={isReschedule && selectedStartTime && selectedStartTime}
              endTime={isReschedule && selectedStartTime && formattedEndTime}
              courtName={newCourtName}
              isOldTime={false}
            />
          </ConfirmationDialog>
        )}
      </Dialog>
    </>
  );
};
