import React from "react";
import { FormattedMessage } from "react-intl";

import clsx from "clsx";

import { MembershipStatus as StatusType } from "../modules/player/models/Membership";

interface Props {
  className?: string;
  status: StatusType | undefined;
  onClick?: (e: React.MouseEvent) => void;
}

export const MembershipStatus: React.FC<Props> = ({
  className,
  status,
  onClick,
}) => {
  const containerClasses = clsx("group flex items-center", className, {
    "cursor-pointer": onClick,
  });

  const dotClasses = clsx(
    "block h-[10px] w-[10px] shrink-0 rounded-full bg-gray-600",
    {
      "bg-green-700": status === StatusType.Active,
      "bg-red-600": status === StatusType.Inactive,
      "group-hover:bg-green-700":
        (status === StatusType.Inactive || status === StatusType.Expired) &&
        onClick,
    },
  );

  return (
    <div
      className={containerClasses}
      onClick={e => onClick && e.stopPropagation()}
    >
      <div
        className="flex items-center gap-2"
        onClick={e => {
          onClick?.(e);
        }}
      >
        <div className={dotClasses}></div>
        <p>
          <FormattedMessage
            id={
              status === StatusType.Active
                ? "common.active"
                : status === StatusType.Inactive
                  ? "common.inactive"
                  : status === StatusType.Expired
                    ? "common.expired"
                    : "common.missing"
            }
          />
        </p>
      </div>
    </div>
  );
};
