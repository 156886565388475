import { useState } from "react";
import { useDropzone } from "react-dropzone";
import { FormattedMessage } from "react-intl";

import {
  faImageSlash,
  faImages,
  faUpFromBracket,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";

import { ImagePicker } from "./ImagePicker";

interface Props {
  className?: string;
  imgSrc?: string;
  onSelect: (image: File | string) => void;
  pickerTitle?: string;
  pickerDescription?: string;
  selectableImages?: string[];
}

export const ImageSelector: React.FC<Props> = ({
  className,
  imgSrc,
  onSelect,
  pickerTitle,
  pickerDescription,
  selectableImages,
}) => {
  const [showPicker, setShowPicker] = useState<boolean>(false);
  const [imagePreview, setImagePreview] = useState<string>(imgSrc);

  const handleFileSelect = async (acceptedFiles: File[]) => {
    const selectedImage = acceptedFiles?.[0];
    if (!selectedImage) return;
    const fileReader = new FileReader();
    fileReader.readAsDataURL(selectedImage);
    fileReader.onload = async () => {
      const b64 = fileReader.result?.toString();
      setImagePreview(b64);
    };
    onSelect(selectedImage);
  };

  const handleImageSelect = (url: string) => {
    setImagePreview(url);
    onSelect(url);
  };

  const {
    getRootProps,
    getInputProps,
    isDragAccept,
    isDragReject,
    isDragActive,
  } = useDropzone({
    multiple: false,
    onDrop: handleFileSelect,
    accept: {
      "image/*": [".png", ".jpg", ".jpeg"],
      "image/heic": [".heic"],
    },
  });

  const wrapperClasses = clsx(
    "group relative aspect-square overflow-hidden",
    className,
  );

  const dropInfoClasses = clsx(
    "absolute left-0 top-0 h-full w-full opacity-50",
    {
      hidden: !isDragActive,
      "bg-white": isDragAccept,
      "cursor-no-drop bg-black": isDragReject,
    },
  );

  const infoBoxClasses = clsx(
    "flex items-center justify-center rounded-[2px] bg-primary text-white transition-all duration-300 hover:bg-white hover:text-primary hover:shadow-lg",
    {
      "h-[44px] w-[44px]": !isDragReject,
      "h-[44px] bg-white px-4 text-black": isDragReject,
    },
  );

  const rejectInfoClasses = clsx("text-black transition-all duration-300");

  const toggleShowPicker = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setShowPicker(true);
  };

  return (
    <>
      <div {...getRootProps({ role: "button" })} className={wrapperClasses}>
        <input {...getInputProps()} />
        {imagePreview ? (
          <img
            src={imagePreview}
            onClick={toggleShowPicker}
            className="h-full w-full rounded-[4px] object-cover group-hover:opacity-80"
          />
        ) : (
          <div
            onClick={toggleShowPicker}
            className="flex h-full w-full items-center justify-center bg-white text-8xl text-primary"
          >
            <FontAwesomeIcon icon={faImageSlash} />
          </div>
        )}
        <div className={dropInfoClasses}></div>

        <div className="absolute bottom-4 right-4 flex space-x-2">
          <div className={infoBoxClasses} onClick={toggleShowPicker}>
            {isDragReject && (
              <div className={rejectInfoClasses}>
                <FormattedMessage id="common.error.filetype.not-supported" />
              </div>
            )}
            {!isDragReject && <FontAwesomeIcon icon={faImages} />}
          </div>
          <div className="flex h-[44px] w-[44px] items-center justify-center rounded-[2px] bg-white text-primary transition-all duration-300 hover:bg-primary hover:text-white hover:shadow-lg">
            <FontAwesomeIcon icon={faUpFromBracket} />
          </div>
        </div>
      </div>
      <ImagePicker
        onFileSelect={handleFileSelect}
        onImageSelect={handleImageSelect}
        visible={showPicker}
        setVisible={setShowPicker}
        title={pickerTitle}
        description={pickerDescription}
        selectableImages={selectableImages}
      />
    </>
  );
};
