import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { faCheck, faEdit, faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

import { breakpoints } from "../../../../../appConstants/common";

import { DateOnly } from "../../../../../models/DateOnly";
import {
  PriceCategory,
  PriceGroup,
  PriceListResponseWithUTC,
  PriceScheduleRequestWithUTC,
  PricingCalendarPayload,
  WeekDays,
} from "../../../../../modules/checkout/models/Pricing";
import { GroupTypeStrings } from "../../../../../modules/player/models/Group";

import { useToaster } from "../../../../../hooks/common/useToaster";
import { useDateFormatWithSelectedFacility } from "../../../../../hooks/useDateFormat";

import {
  addPriceListGroup,
  createPriceCategory,
  createPricingSchedules,
  updatePriceList,
  updatePricingSchedules,
} from "../../../../../modules/checkout/services/Pricing";
import { getPricingSchedule } from "../../../../../modules/checkout/services/Schedule";
import { getGroupsByFacilityId } from "../../../../../modules/player/services/GroupService";

import BackButton from "../../../../../components/BackButton";
import { BackButtonContainer } from "../../../../../components/BackButtonContainer";
import { Button } from "../../../../../components/Button";
import { CalendarInput } from "../../../../../components/CalendarInput";
import { TextInput } from "../../../../../components/TextInput";
import PriceCalendar from "../../../../../modules/checkout/components/Pricing/Calander/PriceCalendar";
import { BlockContainer } from "../../../../../modules/customer/components/BlockContainer";

import { luxonDateFormat } from "../../../../../utils/dateFormats";
import { PriceCategoryTable } from "./PriceCategoryTable";

interface Props {
  currentPriceList: PriceListResponseWithUTC;
  fetchPriceLists?: () => void;
  selectedFacilityId: string;
  onGoBack?: (shouldRefetch?: boolean) => void;
}

const TabDescription = styled.p`
  font-weight: var(--bold);
  color: var(--gray-400);
  max-width: 350px;
`;

const AddButton = styled(Button)`
  @media (min-width: ${breakpoints.MOBILE}) {
    width: 60%;
    justify-content: center;
  }
`;

const PriceCategoryIcon = styled.div`
  display: flex;
  gap: 1rem;
`;
const PriceCategoryIconsContainer = styled.div`
  overflow: auto;
  margin-bottom: 1rem;
`;

const Container = styled(BlockContainer)`
  padding-top: 2rem;

  @media (min-width: ${breakpoints.LARGE}) {
    padding-top: 0;
  }
`;

const EditPriceListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media (min-width: ${breakpoints.LARGE}) {
    flex-direction: row;
  }
`;

const PriceListBaseValues = styled.div`
  display: flex;
  gap: 7rem;
`;

const LabelValue = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  span:first-of-type {
    font-weight: var(--bold);
  }
`;

const ValuesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 90%;

  @media (min-width: ${breakpoints.XL}) {
    gap: 7rem;
    width: auto;
    flex-direction: row;
  }
`;

const EditIconContainer = styled.div`
  width: 10%;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;

  @media (min-width: ${breakpoints.XL}) {
    width: 1rem;
    align-items: center;
  }
`;

export const PriceListManagement: React.FC<Props> = ({
  currentPriceList,
  fetchPriceLists,
  selectedFacilityId,
  onGoBack,
}) => {
  const intl = useIntl();
  const { toastSuccess, toastError } = useToaster();
  const [currentSchedule, setCurrentSchedule] = useState<WeekDays[]>();
  const [currentCategory, setCurrentCategory] = useState<PriceCategory>();
  const [discountGroups, setDiscountGroups] = useState<any>([]);
  const [editBaseValues, setEditBaseValues] = useState(false);
  const [priceListName, setPriceListName] = useState<string>();
  const [priceListValidFrom, setPriceListValidFrom] = useState<DateOnly>();
  const [priceListValidTo, setPriceListValidTo] = useState<DateOnly>();
  const [dirty, setDirty] = useState(false);
  const [shouldRefetch, setShouldRefetch] = useState(false);
  const [priceList, setPriceList] =
    useState<PriceListResponseWithUTC>(currentPriceList);
  const { df } = useDateFormatWithSelectedFacility();

  const setInitialValues = () => {
    setPriceListName(priceList?.name);
    setPriceListValidFrom(priceList?.validFrom);
    setPriceListValidTo(priceList?.validTo);
    setEditBaseValues(false);
  };

  const fetchGroups = async () => {
    const groups = await getGroupsByFacilityId(
      selectedFacilityId,
      GroupTypeStrings.DISCOUNT,
    );
    setDiscountGroups(Array.isArray(groups) ? groups : [groups]);
  };

  const fetchPriceListSchedule = async (priceListId: string) => {
    try {
      const result = await getPricingSchedule(selectedFacilityId, priceListId);
      if (result) setCurrentSchedule(result.data.weekDays);
    } catch (e) {
      console.log(e);
    }
  };

  const onAddToSchedule = async (payload: PricingCalendarPayload) => {
    try {
      const scheduleId = payload?.scheduleId;
      const handleSchedule = scheduleId
        ? updatePricingSchedules
        : createPricingSchedules;
      const result = await handleSchedule(priceList.id, {
        name: "",
        validFrom: payload?.startTime,
        dayOfWeek: payload?.dayId,
        validTo: payload?.endTime,
        categoryId: currentCategory?.id,
        ...(scheduleId && { id: scheduleId }),
      } as PriceScheduleRequestWithUTC);
      if (result) {
        toastSuccess.changesSaved();
      }
    } catch (e) {
      toastError.someChangesUpdateFailed();
    } finally {
      await fetchPriceLists?.();
      await fetchGroups();
    }
  };

  const onAddGroup = async (group: PriceGroup) => {
    const result = await addPriceListGroup(priceList.id, {
      id: group.id,
      name: group.name,
    });
    setPriceList(result.data);

    toastSuccess.discountGroupCreated();
  };

  const onAddCategory = async () => {
    try {
      const result = await createPriceCategory(
        priceList.id,
        intl.formatMessage({ id: "common.new-category" }),
      );

      if (result) {
        toastSuccess.pricingCategoryCreated();
      }
    } catch {
      toastError.createPricingCategoryFailed();
    } finally {
      fetchPriceLists?.();
      fetchGroups();
    }
  };

  const onEditPriceList = async () => {
    try {
      await updatePriceList(priceList.id, {
        ...priceList,
        name: priceListName ?? "",
        validFrom: priceListValidFrom,
        validTo: priceListValidTo,
        defaultCategoryName: "", // Not handled by backend on update.
      });

      toastSuccess.priceListUpdated();
    } catch {
      toastError.updatePriceListFailed();
    } finally {
      fetchPriceLists?.();
    }
  };

  useEffect(() => {
    fetchGroups();
  }, [selectedFacilityId]);

  useEffect(() => {
    setShouldRefetch(dirty);
  }, [dirty]);

  useEffect(() => {
    if (currentPriceList) {
      fetchPriceListSchedule(currentPriceList.id);
      setPriceList(currentPriceList);
    }
  }, [currentPriceList]);

  useEffect(() => {
    setCurrentCategory(priceList?.categories[0]);
  }, []);

  const currentCategoryIndex = currentPriceList?.categories.findIndex(
    ({ id }) => id === currentCategory?.id,
  );

  const categoryColor =
    currentCategoryIndex && currentCategory?.id
      ? currentCategoryIndex < 5
        ? `hsl(218, 100%, ${89 - (currentCategoryIndex % 5) * 15}%)`
        : `repeating-linear-gradient( 180deg, hsl(218, 100%, ${
            89 - (currentCategoryIndex % 4) * 15
          }%), hsl(218, 100%, ${
            89 - (currentCategoryIndex % 4) * 15
          }%) 5px, hsl(218, 100%, ${
            89 - (Math.ceil(currentCategoryIndex / 2) % 5) * 15
          }%) 5px, hsl(218, 100%, ${
            89 - (Math.ceil(currentCategoryIndex / 2) % 5) * 15
          }%) 11px )`
      : "hsl(218,100%,89%)";

  useEffect(() => {
    priceList && setInitialValues();

    //reset currentCategory if the category don't exist anymore
    if (
      currentCategory !== undefined &&
      !priceList?.categories.some(item => item?.id === currentCategory?.id)
    ) {
      setCurrentCategory(priceList?.categories[0]);
    }
  }, [priceList]);

  if (!priceList) return null;

  return (
    <>
      <Container $isFirst>
        <BackButtonContainer>
          <BackButton onClick={() => onGoBack?.(shouldRefetch)} />
        </BackButtonContainer>
        <h3>
          <FormattedMessage
            id="common.step-x-of-y"
            values={{ step: 1, total: 3 }}
            defaultMessage="Steg {step} av {total}"
          />
        </h3>
        {editBaseValues ? (
          <EditPriceListContainer>
            <TextInput
              label={intl.formatMessage({
                id: "pricing.name",
                defaultMessage: "Gäller från",
              })}
              defaultValue={priceListName}
              onChange={e => {
                setDirty(true);
                setPriceListName(e.target.value || "");
              }}
            />
            <CalendarInput
              label={intl.formatMessage({
                id: "common.valid-from",
                defaultMessage: "Gäller från",
              })}
              maxDate={priceListValidTo?.toDateTime()}
              placeholder={intl.formatMessage({
                id: "common.valid-from",
                defaultMessage: "Gäller från",
              })}
              value={priceListValidFrom?.toDateTime()}
              onChange={e => {
                setDirty(true);
                setPriceListValidFrom(DateOnly.fromDateTime(e.value));
              }}
            />
            <CalendarInput
              label={intl.formatMessage({
                id: "common.valid-thru",
              })}
              minDate={priceListValidFrom?.toDateTime()}
              placeholder={intl.formatMessage({
                id: "common.valid-thru",
              })}
              value={priceListValidTo?.toDateTime()}
              onChange={e => {
                setDirty(true);
                setPriceListValidTo(DateOnly.fromDateTime(e.value));
              }}
            />
            <div
              style={{
                display: "flex",
                gap: "1rem",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <div
                style={{
                  width: "1rem",
                  cursor: dirty ? "pointer" : "not-allowed",
                }}
              >
                <FontAwesomeIcon
                  icon={faCheck}
                  onClick={async () => {
                    if (!dirty) return;
                    onEditPriceList();
                  }}
                />
              </div>
              <div style={{ width: "1rem", cursor: "pointer" }}>
                <FontAwesomeIcon icon={faTimes} onClick={setInitialValues} />
              </div>
            </div>
          </EditPriceListContainer>
        ) : (
          <PriceListBaseValues>
            <ValuesContainer>
              <LabelValue>
                <span>
                  <FormattedMessage
                    id="pricing.name"
                    defaultMessage="Namn på prislista"
                  />
                </span>
                <span>{priceList.name}</span>
              </LabelValue>
              <LabelValue>
                <span>
                  <FormattedMessage
                    id="common.valid-from"
                    defaultMessage="Gäller från"
                  />
                </span>
                <span>
                  {priceList.validFrom.toDateTime().isValid
                    ? df(priceList.validFrom, luxonDateFormat)
                    : "-"}
                </span>
              </LabelValue>
              <LabelValue>
                <span>
                  <FormattedMessage
                    id="common.valid-thru"
                    defaultMessage="Gäller till"
                  />
                </span>
                <span>
                  {priceList.validTo?.toDateTime().isValid
                    ? df(priceList.validTo, luxonDateFormat)
                    : "-"}
                </span>
              </LabelValue>
            </ValuesContainer>
            <EditIconContainer>
              <FontAwesomeIcon
                icon={faEdit}
                onClick={() => setEditBaseValues(true)}
              />
            </EditIconContainer>
          </PriceListBaseValues>
        )}
        <h3 className="mt-4">
          <FormattedMessage
            id="common.step-x-of-y"
            values={{ step: 2, total: 3 }}
            defaultMessage="Steg {step} av {total}"
          />
        </h3>
        <TabDescription>
          <FormattedMessage
            id="pricings.manage.step-one-of-two.description"
            defaultMessage="Här lägger du till dina olika priskategorier, vi har förberett några för att göra det lättare.
          Kom ihåg att priset ska anges exklusive bokningsavgift och inklusive moms samt att du sätter priset per person"
          />
        </TabDescription>
        <PriceCategoryTable
          discountGroups={discountGroups}
          currentPriceList={priceList}
          fetchPriceLists={() => {
            fetchPriceLists?.();
          }}
          fetchGroups={fetchGroups}
          onAddGroup={onAddGroup}
        />
        <AddButton
          onClick={onAddCategory}
          text="Lägg till kategori"
          translationName="pricing.category.add"
          type="add"
          buttonType="button"
        />
      </Container>
      <BlockContainer>
        <h3 style={{ marginTop: "1rem" }}>
          <FormattedMessage
            id="common.step-x-of-y"
            values={{ step: 3, total: 3 }}
            defaultMessage="Steg {step} av {total}"
          />
        </h3>
        <TabDescription>
          <FormattedMessage
            id="pricings.manage.step-two-of-two.description"
            defaultMessage="Okej sista steget! Här bestämmer du vilka dagar och tider som dina priser ska gälla. Välj en kategori och markera sedan i kalendern."
          />
        </TabDescription>
        <PriceCategoryIconsContainer>
          <PriceCategoryIcon>
            {priceList?.categories.length &&
              priceList?.categories.map((item: PriceCategory, key) => (
                <div
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "1rem",
                    color: "#000" ?? "unset",
                    border:
                      currentCategory?.id === item.id
                        ? "0.1rem solid #000"
                        : "0.1rem solid #DDD",
                    borderRadius:
                      currentCategory?.id === item.id ? "0.175rem" : "unset",
                    padding: "0.5rem",
                  }}
                  key={key}
                  onClick={() => {
                    setCurrentCategory(item);
                  }}
                >
                  <div
                    style={{
                      background:
                        key < 5
                          ? `hsl(218, 100%, ${89 - (key % 5) * 15}%)`
                          : `repeating-linear-gradient( 180deg,
                      hsl(218, 100%, ${89 - (key % 4) * 15}%),
                      hsl(218, 100%, ${89 - (key % 4) * 15}%) 5px,
                      hsl(218, 100%, ${
                        89 - (Math.ceil(key / 2) % 5) * 15
                      }%) 5px,
                      hsl(218, 100%, ${
                        89 - (Math.ceil(key / 2) % 5) * 15
                      }%) 11px )` ?? "unset",
                      borderRadius: "0.175rem",
                      minWidth: "1rem",
                      minHeight: "1rem",
                    }}
                  />
                  <div style={{ whiteSpace: "nowrap" }}>{item.name}</div>
                </div>
              ))}
          </PriceCategoryIcon>
        </PriceCategoryIconsContainer>
      </BlockContainer>

      {currentCategory && currentSchedule && (
        <PriceCalendar
          selectedCategory={currentCategory?.id}
          priceList={priceList}
          selectedCategoryColor={categoryColor}
          schedules={currentSchedule}
          onChange={onAddToSchedule}
        />
      )}
    </>
  );
};
