import React, { useEffect, useState } from "react";

import styled from "styled-components";

interface IScoreInputProps {
  readonly?: boolean;
  value: number;
  onValueChange: (value: number) => void;
}

const StyledInput = styled.input`
  display: flex;
  width: 100%;
  text-align: center;
  font-weight: var(--bold);
  font-size: 16px;
  padding: 0.2rem 0;
  border: 0.1rem solid var(--gray-200);
  border-radius: 0.3rem;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type="number"] {
    -moz-appearance: textfield;
  }
`;

export const ScoreInput: React.FC<IScoreInputProps> = ({
  readonly = false,
  value,
  onValueChange,
}) => {
  const min = 0;
  const max = 999;

  const [internalValue, setInternalValue] = useState<string>(value.toString());

  const capValue = (value): number => {
    if (!Number.isFinite(value) || value < min) return min;
    if (value > max) return max;
    return value;
  };

  useEffect(() => {
    setInternalValue(value.toString());
  }, [value]);

  return (
    <StyledInput
      disabled={readonly}
      readOnly={readonly}
      min={min}
      max={max}
      inputMode="numeric"
      onBeforeInput={(e: any) => {
        if (e.data.length === 0) {
          return;
        }
        if (/\d+/.test(e.data)) {
          return;
        }

        e.preventDefault();
      }}
      value={internalValue}
      onChange={e => {
        const change = e.currentTarget.valueAsNumber;
        if (!Number.isFinite(change)) {
          setInternalValue("");
        } else {
          onValueChange(capValue(e.currentTarget.valueAsNumber));
        }
      }}
      onFocus={e => !readonly && e.target.select()}
      onBlur={() => {
        if (internalValue === "" || !Number.isFinite(parseInt(internalValue))) {
          onValueChange(min);
        }
      }}
      type="number"
    />
  );
};
