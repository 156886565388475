import { useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import { faCopy, faEdit, faTrash } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormik } from "formik";
import type { DateTime } from "luxon";
import { ProgressSpinner } from "primereact/progressspinner";
import styled from "styled-components";
import { object, string } from "yup";

import { breakpoints } from "../../../../../appConstants/common";

import { DateOnly } from "../../../../../models/DateOnly";
import {
  CreatePriceListRequestWithUTC,
  PriceListResponseWithUTC,
} from "../../../../../modules/checkout/models/Pricing";

import { useToaster } from "../../../../../hooks/common/useToaster";
import { useBookableTypes } from "../../../../../hooks/swr/useBookableTypes";
import { useDateFormatWithSelectedFacility } from "../../../../../hooks/useDateFormat";

import {
  copyPriceList,
  createPriceList,
  deletePriceList,
} from "../../../../../modules/checkout/services/Pricing";

import { Button } from "../../../../../components/Button";
import { CalendarInput } from "../../../../../components/CalendarInput";
import { ConfirmationDialog } from "../../../../../components/ConfirmationDialog";
import { DataTable } from "../../../../../components/DataTable/DataTable";
import { Dialog } from "../../../../../components/Dialog";
import { TextInput } from "../../../../../components/TextInput";
import { AddPriceListForm } from "../../../../../modules/checkout/components/Pricing/form/AddPriceListForm";

import { luxonDateFormat } from "../../../../../utils/dateFormats";

interface Props {
  loading: boolean;
  priceLists?: any;
  openPriceList?: any;
  setShowAddPriceList?: any;
  showAddPriceList?: any;
  selectedFacilityId?: any;
  fetchPriceLists?: any;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
  overflow: auto;
`;

const AddButtonFullWidth = styled(Button)`
  display: flex;
  justify-content: center;
  @media (min-width: ${breakpoints.MOBILE}) {
    width: 100%;
    justify-content: center;
  }
`;

export const PriceLists = ({
  loading,
  priceLists,
  openPriceList,
  setShowAddPriceList,
  showAddPriceList,
  selectedFacilityId,
  fetchPriceLists,
}: Props) => {
  const intl = useIntl();
  const { toastSuccess, toastError } = useToaster();
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [priceListIdToRemove, setPriceListIdToRemove] = useState<string | null>(
    null,
  );
  const [priceListIdToCopy, setPriceListIdToCopy] = useState<string | null>(
    null,
  );
  const [showCopyDialog, setShowCopyDialog] = useState(false);
  const { df } = useDateFormatWithSelectedFacility();
  const history = useHistory();

  const { bookableTypes } = useBookableTypes(selectedFacilityId);

  const onCreatePriceList = async (
    data: Omit<CreatePriceListRequestWithUTC, "facilityId">,
  ) => {
    try {
      const result = await createPriceList({
        bookableEntityType: data.bookableEntityType,
        facilityId: selectedFacilityId,
        name: data.name,
        validFrom: data.validFrom,
        validTo: data.validTo,
        defaultCategoryName: data.defaultCategoryName,
      });

      if (result) {
        fetchPriceLists();
        toastSuccess.priceListCreated();
        setShowAddPriceList(false);

        history.push(`?priceListId=${result.data.id}`);
      }
    } catch (e) {
      toastError.createPriceListFailed();
      console.log(e);
    }
  };

  const onDeletePriceList = (id: string) => {
    setPriceListIdToRemove(id);
    setShowConfirmationDialog(true);
  };

  const onConfirmDeletePriceList = async () => {
    if (!priceListIdToRemove) {
      return;
    }

    try {
      await deletePriceList(priceListIdToRemove);
      toastSuccess.priceListDeleted();
      fetchPriceLists();
    } catch {
      toastError.deletePriceListFailed();
    } finally {
      setShowConfirmationDialog(false);
      setPriceListIdToRemove(null);
    }
  };

  const columns = useMemo(() => {
    return [
      {
        field: "name",
        body: (pricing: PriceListResponseWithUTC) => pricing.name,
        id: "common.name",
      },
      {
        field: "objectType",
        body: (pricing: PriceListResponseWithUTC) =>
          bookableTypes?.find(x => x.id == pricing?.bookableEntityType)?.name ??
          "Unknown",
        id: "pricings.court-type",
      },
      {
        field: "dateFrom",
        body: (pricing: PriceListResponseWithUTC) =>
          pricing.validFrom.toDateTime().isValid
            ? df(pricing.validFrom, luxonDateFormat)
            : "-",
        id: "common.valid-from",
      },
      {
        field: "dateTo",
        body: (pricing: PriceListResponseWithUTC) =>
          pricing.validTo?.toDateTime().isValid
            ? df(pricing.validTo, luxonDateFormat)
            : intl.formatMessage({
                id: "common.until-further-notice",
              }),
        id: "common.valid-thru",
      },
      {
        field: "action",
        body: ({ id, bookableEntityType }: PriceListResponseWithUTC) => (
          <div className="flex justify-end gap-4">
            <button
              type="button"
              onClick={() => openPriceList(id, bookableEntityType)}
            >
              <FontAwesomeIcon icon={faEdit} />
            </button>
            <button
              type="button"
              onClick={() => {
                setPriceListIdToCopy(id);
                setShowCopyDialog(true);
              }}
              title={intl.formatMessage({
                id: "facility-settings.pricing.copy-price-list",
              })}
            >
              <FontAwesomeIcon icon={faCopy} />
            </button>
            <button
              type="button"
              onClick={() => onDeletePriceList(id)}
              title={intl.formatMessage({
                id: "facility-settings.pricing.delete-price-list",
              })}
            >
              <FontAwesomeIcon icon={faTrash} />
            </button>
          </div>
        ),
      },
    ];
  }, [bookableTypes, df, intl, openPriceList]);

  return (
    <>
      {loading ? (
        <ProgressSpinner />
      ) : (
        <Container>
          <h3>
            <FormattedMessage
              id="facility-settings.prices.price-title"
              defaultMessage="Prislistor"
            />
          </h3>
          <DataTable
            loading={loading}
            pagination
            data={priceLists ?? []}
            columns={columns}
          />
          <AddButtonFullWidth
            onClick={() => setShowAddPriceList(true)}
            text="Skapa ny prissättning"
            translationName="pricings.create-new"
            type="add"
            buttonType="button"
          />

          {showAddPriceList && (
            <Dialog onHide={() => setShowAddPriceList(false)} visible>
              <AddPriceListForm
                facilityId={selectedFacilityId}
                handleSavePriceList={onCreatePriceList}
              />
            </Dialog>
          )}

          {showConfirmationDialog && (
            <ConfirmationDialog
              visible
              confirmButtonType="danger"
              title={intl.formatMessage({
                id: "facility-settings.pricing.delete-price-list",
              })}
              onCancel={() => setShowConfirmationDialog(false)}
              onHide={() => setShowConfirmationDialog(false)}
              onSubmit={onConfirmDeletePriceList}
              text={intl.formatMessage({
                id: "facility-settings.pricing.delete-price-list.text",
              })}
            />
          )}
        </Container>
      )}

      {showCopyDialog && priceListIdToCopy && (
        <Dialog visible onHide={() => setShowCopyDialog(false)}>
          <h3>
            <FormattedMessage id="facility-settings.pricing.copy-price-list" />
          </h3>
          <p className="mb-10">
            <FormattedMessage id="facility-settings.pricing.copy-price-list.description" />
          </p>
          <CopyForm
            id={priceListIdToCopy}
            onCancel={() => setShowCopyDialog(false)}
            onPriceListCopied={() => {
              setShowCopyDialog(false);
              fetchPriceLists();
            }}
          />
        </Dialog>
      )}
    </>
  );
};

const CopyForm = ({
  id,
  onCancel,
  onPriceListCopied,
}: {
  id: PriceListResponseWithUTC["id"];
  onCancel: () => void;
  onPriceListCopied: () => void;
}) => {
  const intl = useIntl();
  const toaster = useToaster();

  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik<{
    validFrom: DateTime | undefined;
    validTo: DateTime | undefined;
    name: string;
  }>({
    initialValues: {
      validFrom: undefined,
      validTo: undefined,
      name: "",
    },
    onSubmit: async values => {
      setIsLoading(true);

      try {
        await copyPriceList(id, {
          validFrom: DateOnly.fromDateTime(values.validFrom as DateTime),
          validTo: values.validTo
            ? DateOnly.fromDateTime(values.validTo)
            : undefined,
          name: values.name,
        });

        toaster.toastSuccess.priceListCreated();
        onPriceListCopied();
      } catch (e) {
        toaster.toastError.generalFailure();
      } finally {
        setIsLoading(false);
      }
    },
    validationSchema: object().shape({
      validFrom: object().required(
        intl.formatMessage({ id: "validation.required.field" }),
      ),
      validTo: object(),
      name: string().required(
        intl.formatMessage({ id: "validation.required.field" }),
      ),
    }),
  });

  return (
    <form className="space-y-8" onSubmit={formik.handleSubmit}>
      <div className="flex flex-col gap-6">
        <div className="flex gap-5">
          <CalendarInput
            onChange={formik.handleChange}
            value={formik.values.validFrom}
            className="w-full max-w-none self-start"
            name="validFrom"
            required
            label={intl.formatMessage({ id: "common.valid-from" })}
            showButtonBar
            clearButtonClassName="hidden"
            error={formik.errors.validFrom as string} // type casting due to https://github.com/jaredpalmer/formik/issues/3683
          />
          <CalendarInput
            onChange={formik.handleChange}
            value={formik.values.validTo}
            className="w-full max-w-none self-start"
            name="validTo"
            label={intl.formatMessage({ id: "common.valid-thru" })}
            placeholder={intl.formatMessage({
              id: "common.until-further-notice",
            })}
            showButtonBar
            minDate={formik.values.validFrom}
          />
        </div>
      </div>

      <TextInput
        required
        name="name"
        label={intl.formatMessage({ id: "common.name" })}
        onChange={formik.handleChange}
        error={formik.errors.name}
      />

      <div className="flex justify-center gap-5">
        <Button
          buttonType="button"
          type="default"
          translationName="common.cancel"
          onClick={() => onCancel()}
        />
        <Button
          buttonType="submit"
          type="primary"
          translationName="common.copy"
          disabled={isLoading}
        />
      </div>
    </form>
  );
};
