import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";

import { User } from "../../../../../modules/player/models/User";

import { useGame } from "../../../../../hooks/swr/useGame";
import { useSeriesMatchInfo } from "../../../../../hooks/swr/useSeriesMatchInfo";
import { useDateFormat } from "../../../../../hooks/useDateFormat";

import { getUsers } from "../../../../../modules/player/services/UserService";

import { Button } from "../../../../../components/Button";
import { Card } from "../../../../../components/Card";
import { ProfileImageWithFallback } from "../../../../../components/ProfileImageWithFallback";
import { TextInput } from "../../../../../components/TextInput";
import { ScoreBox } from "./components/ScoreBox";

import { luxonTimeFormat } from "../../../../../utils/dateFormats";

interface SerieDetailsPageParams {
  serieId: string;
  matchId: string;
}

export const AdminMatchPage: React.FC = () => {
  const intl = useIntl();
  const history = useHistory();

  const { serieId, matchId } = useParams<SerieDetailsPageParams>();
  const { matchInfo, mutate } = useSeriesMatchInfo(serieId, matchId);
  const { game } = useGame(matchInfo?.id);
  const [homePlayers, setHomePlayers] = useState<User[]>();
  const [opponentPlayers, setOpponentPlayers] = useState<User[]>();
  const { df, dfInterval } = useDateFormat(matchInfo?.facilityId);

  useEffect(() => {
    const abortController = new AbortController();

    const fetchUsers = async () => {
      const homeUsers = await getUsers(
        matchInfo?.homeTeam?.team?.players?.map(p => p.userId),
        abortController.signal,
      );
      const opponentUsers = await getUsers(
        matchInfo?.opponentTeam?.team?.players?.map(p => p.userId),
        abortController.signal,
      );
      if (abortController.signal.aborted) return;
      setHomePlayers(homeUsers);
      setOpponentPlayers(opponentUsers);
    };

    if (matchInfo) {
      fetchUsers();
    }

    return () => abortController.abort();
  }, [matchInfo]);

  return (
    <>
      {matchInfo && (
        <Card className="overflow-visible">
          <div className="flex flex-col gap-2">
            <p className="text-2xl font-bold">
              <FormattedMessage id="series.homeTeam" />
            </p>
            {homePlayers?.map(p => (
              <div
                key={p.id}
                className="flex flex-col gap-x-4 lg:flex-row lg:items-end"
              >
                <div className="mb-2">
                  <ProfileImageWithFallback
                    width="40px"
                    height="40px"
                    className="border-1 border-black"
                    src={p?.profileImage}
                    firstName={p.firstName}
                    lastName={p.lastName}
                  />
                </div>
                <TextInput
                  className="w-full"
                  label={intl.formatMessage({ id: "common.name" })}
                  value={p?.displayName}
                  disabled
                />
                <TextInput
                  className="w-full"
                  label={intl.formatMessage({ id: "common.email" })}
                  value={p?.emailAddress}
                  disabled
                />
                <TextInput
                  className="w-full"
                  label={intl.formatMessage({ id: "common.mobile" })}
                  value={p?.phoneNumber || ""}
                  disabled
                />
              </div>
            ))}
          </div>

          <div className="flex flex-col gap-2">
            <p className="text-2xl font-bold">
              <FormattedMessage id="series.opponentTeam" />
            </p>
            {opponentPlayers?.map(p => (
              <div
                key={p.id}
                className="flex flex-col gap-x-4 lg:flex-row lg:items-end"
              >
                <div className="mb-2">
                  <ProfileImageWithFallback
                    width="40px"
                    height="40px"
                    className="border-1 border-black"
                    src={p.profileImage}
                    firstName={p.firstName}
                    lastName={p.lastName}
                  />
                </div>
                <TextInput
                  className="w-full"
                  label={intl.formatMessage({ id: "common.name" })}
                  value={`${p?.firstName} ${p?.lastName}`}
                  disabled
                />
                <TextInput
                  className="w-full"
                  label={intl.formatMessage({ id: "common.email" })}
                  value={p?.emailAddress}
                  disabled
                />
                <TextInput
                  className="w-full"
                  label={intl.formatMessage({ id: "common.mobile" })}
                  value={p?.phoneNumber || ""}
                  disabled
                />
              </div>
            ))}
          </div>

          <div className="mt-32 grid gap-8 sm:grid-cols-2">
            <div className="flex flex-col justify-center bg-white p-4">
              <p className="text-2xl font-bold capitalize">
                {`${df(matchInfo.startTime, {
                  weekday: "long",
                  day: "numeric",
                  month: "long",
                })}, ${dfInterval(
                  matchInfo.startTime,
                  matchInfo.endTime,
                  luxonTimeFormat,
                )}`}
              </p>
              <p className="text-xs text-gray-700">{matchInfo?.courtName}</p>
              <div className="mt-4 flex justify-between">
                <p className="text-lg font-bold">{matchInfo?.division?.name}</p>
                {matchInfo?.isRescheduleAllowed && (
                  <Button
                    type="primary"
                    onClick={() =>
                      history?.push(
                        `/reschedule/${matchInfo?.facilityId}/${game?.externalServiceId}`,
                      )
                    }
                  >
                    <FormattedMessage id="games.reschedule" />
                  </Button>
                )}
              </div>
            </div>

            <ScoreBox matchInfo={matchInfo} onFinish={() => mutate()} />
          </div>
        </Card>
      )}
    </>
  );
};
