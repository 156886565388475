import useSWR, { SWRConfiguration } from "swr";

import { getFeatureFlags } from "../../services/mySettingsService";

import { useCurrentUserId } from "../../recoil/currentUserIdState";

export const useFeatureFlags = (options?: SWRConfiguration) => {
  const currentUserId = useCurrentUserId();

  const { data, ...rest } = useSWR(
    currentUserId ? ["feature-flags", currentUserId] : null,
    getFeatureFlags,
    options,
  );

  return {
    ...rest,
    featureFlags: data,
  };
};

export const useIsFeatureFlagEnabled = (featureFlag: string) => {
  const { featureFlags } = useFeatureFlags();

  return featureFlags?.includes(featureFlag) ?? false;
};
