import { FormattedMessage } from "react-intl";

import clsx from "clsx";

interface Props {
  isAvailable: boolean;
  isSelected: boolean;
  timeString: string;
  duration?: number;
  onClick: () => void;
}
export const SlotTimeItem: React.FC<Props> = ({
  isAvailable,
  isSelected,
  timeString,
  duration,
  onClick,
}) => {
  const boxClasses = clsx(
    "flex h-24 min-h-8 grow items-center justify-center rounded-md border-2",
    {
      "border-primary bg-primary text-white": isSelected,
      "border-gray-200 text-primary": isAvailable,
      "border-gray-200 text-gray-400 line-through": !isAvailable,
    },
  );

  const dividerClasses = clsx("my-1 w-full border", {
    "border-white": isSelected,
    "border-gray-200": isAvailable,
    "border-gray-500": !isAvailable,
  });

  return (
    <div onClick={onClick} className={boxClasses}>
      {duration ? (
        <div className="flex flex-col items-center">
          <p className="text-base">
            {duration}
            <FormattedMessage id="common.minutes.short" />
          </p>
          <div className={dividerClasses}></div>
          <p className="text-base">{timeString}</p>
        </div>
      ) : (
        <p className="text-base">{timeString}</p>
      )}
    </div>
  );
};
