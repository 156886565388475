import React, { useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";

import { ColumnProps, Column as PrimeReactColumn } from "primereact/column";
import {
  DataTableProps,
  DataTable as PrimeReactDataTable,
} from "primereact/datatable";
import styled from "styled-components";

import usePagination from "../../hooks/common/usePagination";

interface Column extends ColumnProps {
  id?: string;
  translationValues?: any;
  isSelectionColumn?: boolean;
}

interface Props extends DataTableProps {
  data?: any[];
  columns: Column[];
  pagination?: boolean;
  rowsPerPage?: number;
  ref?: any;
  useColumnBorder?: boolean;
}

interface StyleProps {
  $useColumnBorder?: boolean;
}

const StyledDataTable = styled(PrimeReactDataTable)<StyleProps>`
  .p-sortable-column:focus {
    box-shadow: none;
  }

  .p-sortable-column.p-highlight,
  .p-sortable-column.p-highlight .p-sortable-column-icon {
    color: $primary !important;
  }
  thead th {
    border: none !important;
    background: transparent !important;
    .p-column-title {
      font-weight: 700;
      color: var(--gray-400);
    }
  }
  tbody tr {
    &:nth-child(odd) {
      background-color: var(--gray-50);
    }
    td {
      color: black;
      padding: 1rem 1rem !important;
      &:not(:nth-child(1)) {
        border-left: ${props =>
          props.$useColumnBorder ? "1px solid var(--gray-200)" : "none"};
      }
    }
  }

  .p-paginator-page {
    border: 1px solid var(--primary-light) !important;
    margin: 0.24rem !important;
    color: var(--primary) !important;
    font-weight: var(--bold);
  }

  .p-highlight {
    color: var(--light) !important;
  }
`;

// eslint-disable-next-line react/display-name
export const DataTable: React.FC<Props> = React.forwardRef(
  (
    {
      data = [],
      columns = [],
      pagination = false,
      rowsPerPage = 10,
      useColumnBorder = false,
      ...tableProps
    },
    ref: any,
  ) => {
    const template = usePagination();
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(rowsPerPage);

    const onPage = useCallback(event => {
      setFirst(event.first);
      setRows(event.rows);
    }, []);

    return (
      <StyledDataTable
        ref={ref}
        $useColumnBorder={useColumnBorder}
        value={data}
        emptyMessage={
          <FormattedMessage
            id="common.no-data-to-show"
            defaultMessage="Ingen data att visa"
          />
        }
        responsiveLayout="scroll"
        {...(pagination && {
          paginator: true,
          paginatorTemplate: template,
          value: data,
          first,
          rows,
          onPage,
        })}
        {...tableProps}
      >
        {columns.map(
          ({ id, translationValues, isSelectionColumn, ...props }, key) => (
            <PrimeReactColumn
              key={key}
              {...(tableProps.selectionMode &&
                isSelectionColumn && { selectionMode: "multiple" })}
              {...(id && {
                header: <FormattedMessage id={id} values={translationValues} />,
              })}
              {...props}
            />
          ),
        )}
      </StyledDataTable>
    );
  },
);
