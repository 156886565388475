import { AnyAction, combineReducers } from "@reduxjs/toolkit";

import facilitiesReducer from "./slices/facilities/facilitiesSlice";

const combinedReducer = combineReducers({
  facilities: facilitiesReducer,
});

const rootReducer = (
  state: ReturnType<typeof combinedReducer>,
  action: AnyAction,
) => {
  if (action.type === "logout") {
    state = undefined;
  }

  return combinedReducer(state, action);
};

export default rootReducer;
