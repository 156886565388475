import React, { useMemo } from "react";
import { FormattedMessage } from "react-intl";

import { Interval } from "luxon";
import styled from "styled-components";

import { DateOnly } from "../../../../../../../models/DateOnly";
import { FacilityWithUtc } from "../../../../../../customer/models/Facility";
import { IScheduleSlot } from "../../../../../models/Calendar";

import { HOURS_TO_DISPLAY } from "../../../../../../../hooks/booking/useCalendarDateChanger";
import { useDateFormat } from "../../../../../../../hooks/useDateFormat";

import { luxonDateFormat } from "../../../../../../../utils/dateFormats";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DateContainer = styled.div`
  font-size: 1.1rem;
  font-weight: 600;
`;

const Explanation = styled.div`
  display: flex;
  justify-content: flex-end;
  font-size: 0.7rem;
  color: var(--gray-400);

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.5rem;
  }
`;

const Box = styled.div`
  width: 1rem;
  height: 1rem;
  border: 1px solid #e9e9e9;
  border-radius: 2px;
  margin-right: 0.3rem;
`;

interface IProps {
  facilityId: FacilityWithUtc["id"];
  slots: IScheduleSlot[];
  selectedDate?: DateOnly;
  startIndex?: number;
}

const today = DateOnly.today();

const CustomerCalendarHeadMobile: React.FC<IProps> = ({
  facilityId,
  slots,
  selectedDate,
  startIndex,
}) => {
  const { df, setZone, setLocale } = useDateFormat(facilityId);

  const intervals = useMemo(
    () =>
      Interval.fromDateTimes(
        setLocale(setZone(slots[startIndex].startTime)),
        setLocale(setZone(slots.at(-1).endTime)),
      ).splitBy({ hours: 1 }),
    [setZone, slots, startIndex],
  );

  return (
    <>
      <Container>
        <DateContainer>
          {selectedDate.toDateTime().equals(today.toDateTime()) ? (
            <FormattedMessage id="common.today" defaultMessage="Idag" />
          ) : (
            df(selectedDate, luxonDateFormat)
          )}
        </DateContainer>
        <Explanation>
          <div>
            <Box className="bg-purewhite" />
            <FormattedMessage id="common.available" defaultMessage="Ledigt" />
          </div>
          <div>
            <Box className="bg-gray-600" />
            <FormattedMessage id="common.booked" defaultMessage="Bokat" />
          </div>
        </Explanation>
      </Container>

      <div className="calendar-row calendar-times !sticky top-16 z-20 bg-purewhite">
        {intervals.slice(0, HOURS_TO_DISPLAY).map((interval, index) => {
          const localeParts = interval.start.toLocaleParts({
            hour: "numeric",
            minute: "numeric",
          });

          const hourIndex = localeParts.findIndex(
            (part: Intl.DateTimeFormatPart) => part.type === "hour",
          );

          const minuteStartIndex =
            localeParts[hourIndex + 1].type === "literal"
              ? hourIndex + 2
              : hourIndex + 1;

          const minutes = localeParts
            .slice(minuteStartIndex)
            .map(({ value }) => value)
            .join("");

          return (
            <div key={interval.start.toString()} className="time head-time">
              {localeParts[hourIndex].value}

              <small style={{ marginBottom: "3px", marginLeft: "1px" }}>
                {minutes}
              </small>

              {index !== 0 &&
              interval.start.offset !== intervals.at(index - 1).start.offset
                ? interval.start.toFormat("Z")
                : ""}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default React.memo(CustomerCalendarHeadMobile);
