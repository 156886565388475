import { DateTime } from "luxon";

import type { User } from "./User";

export interface Coin {
  id?: string;
  type: CoinType;
  amount: number;
  isReadOnly?: boolean;
  expires: DateTime;
  comment: string;
}

export interface CoinWallet {
  facilityId: string;
  walletCurrencies: Coin[];
}

export enum CoinType {
  COIN = 0,
  ALLOWANCE = 1,
}

export interface CoinApiResponse extends Omit<Coin, "expires"> {
  expires: string;
}

// Property walletCurrencies is named differently in responses.
// "walletCurrencies" when calling /coins
// "walletCurrencys" when calling /coins/facilityId/user/userId" and "/coins/facilityId"
export interface CoinWalletApiResponse
  extends Omit<CoinWallet, "walletCurrencies"> {
  walletCurrencies: CoinApiResponse[];
}

export interface AdminFacilityUserCoin extends Pick<Coin, "amount" | "type"> {
  expiresUtc: DateTime;
  userId: User["id"];
  firstName: string;
  lastName: string;
  profileImageUrl: string | null;
}

export interface AdminFacilityUserCoinApiResponse
  extends Omit<AdminFacilityUserCoin, "expiresUtc"> {
  expiresUtc: string;
}
