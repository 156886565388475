import { FormattedMessage } from "react-intl";

import { faCheck } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

import { Membership } from "../../../../../../modules/player/models/Membership";

interface ContainerProps {
  color: string;
  isPurchasable?: boolean;
}

interface ButtonProps {
  color: string;
  active: boolean;
  disabled?: boolean;
}

const Container = styled.div<ContainerProps>`
  width: 232px;
  border: 2px solid ${props => props.color || "var(--primary)"};
  border-radius: 20px;
  padding: 1rem 1em 2em;
  position: relative;
  background: var(--light);
  margin-bottom: 20px;
  flex: 1 0 auto;

  ${props => !props?.isPurchasable && "opacity: 0.5;"}
`;

const Button = styled.button<ButtonProps>`
  border: none;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => (props.active ? "var(--black)" : "var(--light)")};
  font-weight: var(--bold);
  height: 40px;
  min-width: 161px;
  ${props => props.active && "border: 2px solid var(--green-500);"}
  background: ${props =>
    props.active ? "var(--light)" : props.color || "var(--primary)"};
  border-radius: 20px;
  ${props => !props.active && !props.disabled && "cursor: pointer;"}
  padding: 0.5rem;
  gap: 0.5rem;
`;

interface Props {
  membership: Membership;
  onSubmit: any;
  active: boolean;
  children?: React.ReactNode;
  className?: string;
}

export const AlternativeBox: React.FC<Props> = ({
  membership,
  children,
  className,
  onSubmit,
  active,
}) => {
  return (
    <Container
      isPurchasable={membership?.isPurchasable}
      color={membership?.color}
      className={className}
    >
      {children}
      <div className="absolute -bottom-5 left-0 flex w-full justify-center">
        {active && (
          <Button disabled color={membership?.color} active={active}>
            <FormattedMessage
              id="membership.currently-active"
              defaultMessage="Aktivt medlemskap"
            />
            <FontAwesomeIcon
              icon={faCheck}
              style={{ color: "var(--green-600)" }}
            />
          </Button>
        )}
        {!active && membership?.isPurchasable && (
          <Button color={membership?.color} active={active} onClick={onSubmit}>
            <FormattedMessage id="common.choose" defaultMessage="Välj" />
          </Button>
        )}
      </div>
    </Container>
  );
};
