import React from "react";

interface Props {
  text?: string;
  className?: string;
}

export const Logo: React.FC<Props> = ({ text = "Court22", className = "" }) => {
  return (
    <svg
      className={className + " fill-primary-dark"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1277.06 167.27"
      aria-label={text}
    >
      <path d="M648.64,124.53c-1.87,3.87-4.23,7.03-7.1,9.5-2.87,2.47-6.07,4.43-9.6,5.9-3.53,1.47-7.33,2.5-11.4,3.1-4.07,.6-8.3,.9-12.7,.9h-53.4c-4.67,0-8.9-.67-12.7-2-3.8-1.33-7.03-3.27-9.7-5.8-2.67-2.53-4.7-5.6-6.1-9.2-1.4-3.6-2.1-7.67-2.1-12.2V48.31l-23.2,23.2v50.82c0,6.27,1.17,12.13,3.5,17.6,2.33,5.47,5.53,10.2,9.6,14.2,4.07,4,8.83,7.17,14.3,9.5,5.46,2.33,11.27,3.5,17.4,3.5h61.8c5.2,0,10.1-.53,14.7-1.6,4.6-1.07,8.7-2.63,12.3-4.7,3.6-2.07,6.6-4.53,9-7.4,2.4-2.87,4-6.1,4.8-9.7h3l-2.4,23.4h23.2V47.13h-23.2V124.53Z" />
      <path d="M753.14,49.23c-4.47,1.4-8.47,3.3-12,5.7-3.53,2.4-6.43,5.17-8.7,8.3-2.27,3.13-3.87,6.37-4.8,9.7h-3l2.4-25.8h-23.2v120h23.2V89.73c1.73-3.2,3.97-6,6.7-8.4,2.73-2.4,5.83-4.43,9.3-6.1,3.47-1.67,7.13-2.9,11-3.7,3.87-.8,7.67-1.2,11.4-1.2h44.63l23.2-23.2h-65.63c-5.2,0-10.03,.7-14.5,2.1Z" />
      <path d="M962.52,70.33l25.15-23.2h-81.64V.13h-23.2V47.13h-14.55l-23.2,23.2h37.75v62.6c0,4.27,.73,8.43,2.2,12.5,1.46,4.07,3.57,7.7,6.3,10.9,2.73,3.2,6.1,5.8,10.1,7.8s8.6,3,13.8,3h5.6c.71,0,28.94-.03,41.88-.07v-22.13h-37.88c-2.53,0-4.93-.47-7.2-1.4-2.27-.93-4.27-2.4-6-4.4-1.73-2-3.1-4.63-4.1-7.9-1-3.27-1.5-7.17-1.5-11.7v-49.2h56.49Z" />
      <path d="M456.72,60.83c-4.07-4.2-8.97-7.53-14.7-10-5.73-2.47-12.07-3.7-19-3.7H198.62c-6.13,0-11.93,1.17-17.4,3.5-5.47,2.33-10.23,5.53-14.3,9.6-4.07,4.07-7.27,8.8-9.6,14.2-2.33,5.4-3.5,11.23-3.5,17.5v30.4c0,6.13,1.17,11.93,3.5,17.4,2.33,5.47,5.53,10.23,9.6,14.3,4.07,4.07,8.83,7.27,14.3,9.6,5.46,2.33,11.27,3.5,17.4,3.5h224.4c6.93,0,13.27-1.13,19-3.4,5.73-2.27,10.63-5.4,14.7-9.4,4.07-4,7.23-8.7,9.5-14.1,2.27-5.4,3.4-11.23,3.4-17.5v-30c0-6-1.13-11.77-3.4-17.3-2.27-5.53-5.43-10.4-9.5-14.6Zm-10.3,52.7c0,4.8-.8,9.1-2.4,12.9-1.6,3.8-3.77,7-6.5,9.6-2.73,2.6-5.93,4.57-9.6,5.9-3.67,1.33-7.57,2-11.7,2H207.82c-4.27,0-8.27-.77-12-2.3-3.73-1.53-7-3.63-9.8-6.3-2.8-2.67-5-5.8-6.6-9.4-1.6-3.6-2.4-7.53-2.4-11.8v-13.6c0-4.53,.83-8.63,2.5-12.3,1.67-3.67,3.9-6.83,6.7-9.5,2.8-2.67,6.03-4.73,9.7-6.2,3.67-1.47,7.5-2.2,11.5-2.2h208.6c4.93,0,9.23,.83,12.9,2.5,3.67,1.67,6.73,3.87,9.2,6.6,2.47,2.73,4.4,5.87,5.8,9.4,1.4,3.53,2.23,7.23,2.5,11.1v13.6Z" />
      <path d="M1231.71,0h-166.48l-56.42,56.42v110.71h41.98c1.12,.08,2.24,.13,3.37,.13h177.55c25.05,0,45.35-20.31,45.35-45.35V45.35c0-25.05-20.31-45.35-45.35-45.35Zm-99.89,116.04c.16,0,.27,.04,.36,.11l-16.97,16.97h-35.61c-.47,0-.7-.23-.7-.7v-15.82c0-.19,.09-.37,.28-.56l34.58-47.46c.65-.84,1.19-1.94,1.61-3.29,.42-1.35,.63-2.54,.63-3.57,0-2.71-.98-5.2-2.94-7.49-1.96-2.29-4.43-3.43-7.42-3.43s-5.34,1.1-7.35,3.29c-2.01,2.19-3.01,4.97-3.01,8.33v6.86c0,.47-.23,.7-.7,.7h-15.68c-.37,0-.56-.23-.56-.7v-6.86c0-5.23,1.28-9.99,3.85-14.28,2.57-4.29,5.93-7.7,10.08-10.22,4.15-2.52,8.61-3.78,13.37-3.78,5.04,0,9.61,1.28,13.72,3.85,4.11,2.57,7.4,5.95,9.87,10.15,2.47,4.2,3.71,8.73,3.71,13.58,0,3.08-.54,6.21-1.61,9.38-1.07,3.17-2.54,5.97-4.41,8.4l-26.6,36.54h31.5Zm74.6,0c.16,0,.27,.04,.36,.11l-16.97,16.97h-35.61c-.47,0-.7-.23-.7-.7v-15.82c0-.19,.09-.37,.28-.56l34.58-47.46c.65-.84,1.19-1.94,1.61-3.29,.42-1.35,.63-2.54,.63-3.57,0-2.71-.98-5.2-2.94-7.49-1.96-2.29-4.43-3.43-7.42-3.43s-5.34,1.1-7.35,3.29c-2.01,2.19-3.01,4.97-3.01,8.33v6.86c0,.47-.23,.7-.7,.7h-15.68c-.37,0-.56-.23-.56-.7v-6.86c0-5.23,1.28-9.99,3.85-14.28,2.57-4.29,5.93-7.7,10.08-10.22,4.15-2.52,8.61-3.78,13.37-3.78,5.04,0,9.61,1.28,13.72,3.85,4.11,2.57,7.4,5.95,9.87,10.15,2.47,4.2,3.71,8.73,3.71,13.58,0,3.08-.54,6.21-1.61,9.38-1.07,3.17-2.54,5.97-4.41,8.4l-26.6,36.54h31.5Z" />
      <path d="M27.4,50.63c-5.47,2.33-10.23,5.53-14.3,9.6-4.07,4.07-7.27,8.83-9.6,14.3-2.33,5.46-3.5,11.27-3.5,17.4v30.4c0,6.27,1.17,12.1,3.5,17.5,2.33,5.4,5.53,10.13,9.6,14.2,4.07,4.07,8.83,7.27,14.3,9.6,5.46,2.33,11.27,3.5,17.4,3.5h59.39v-23.2H53c-4.13,0-8-.77-11.6-2.3-3.6-1.53-6.77-3.63-9.5-6.3-2.73-2.67-4.87-5.83-6.4-9.5-1.53-3.67-2.3-7.63-2.3-11.9v-13.8c0-3.73,.67-7.4,2-11,1.33-3.6,3.27-6.8,5.8-9.6,2.53-2.8,5.63-5.04,9.3-6.7,3.67-1.67,7.9-2.5,12.7-2.5h62.74l23.12-23.2H44.8c-6.13,0-11.93,1.17-17.4,3.5Z" />
    </svg>
  );
};
