import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Link, useHistory } from "react-router-dom";

import {
  faCalendar,
  faChevronRight,
  faFileSignature,
  faSpinnerThird,
  faStar,
  faTableTennis,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

import {
  BookingType,
  ICreateBookingFormValues,
  PaymentType,
  PlayersFilterType,
} from "../../../../modules/checkout/models/Booking";
import { ICalendarPayload } from "../../models/Calendar";
import { Price } from "../../models/Pricing";

import { getCheckoutPath, getVenuePath } from "../../../../helpers/pathHelpers";

import { useIsMobile } from "../../../../hooks/common/useIsMobile";
import { useToaster } from "../../../../hooks/common/useToaster";
import { useCurrentUser } from "../../../../hooks/swr/useCurrentUser";
import { useFacility } from "../../../../hooks/swr/useFacility";
import { useCurrencyFormat } from "../../../../hooks/useCurrencyFormat";

import { createBooking } from "../../../../modules/checkout/services/Booking";
import { createOrder } from "../../../../services/myOrdersService";
import { getFavouriteFacility } from "../../../game/services/Games";
import { getSlotPrice } from "../../services/Pricing";

import { BlockTabs } from "../../../../components/BlockTabs";
import { Button } from "../../../../components/Button";
import { LogoWithFallback } from "../../../../components/LogoWithFallback";
import { NothingToShow } from "../../../../components/NothingToShow";
import { ProgressSpinner } from "../../../../components/ProgressSpinner";

import { CalendarRulesReminder } from "./CalendarRulesReminder";
import CustomerCalendar from "./customer/CustomerCalendar";

const HeaderImage = styled.div`
  display: block;
  width: 5rem;
  height: 5rem;
  overflow: hidden;
  border-radius: var(--border-radius);

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
`;

const FakeCalendarBody = styled.div`
  width: 100%;
  height: 20rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--gray-200);
  padding: 1rem;
`;

const tabs = {
  ACTIVITIES: 0,
  BOOK: 1,
  AMENITIES: 2,
  MEMBERSHIP: 4,
};

const queryParam = {
  activities: tabs.ACTIVITIES,
  book: tabs.BOOK,
  amenities: tabs.AMENITIES,
  membership: tabs.MEMBERSHIP,
};

interface Props {
  facilityId?: string;
  isSpecific?: boolean;
  hasMenu?: boolean;
}

export const UserOverviewCalendar = ({
  facilityId: facilityIdProps,
  isSpecific,
  hasMenu,
}: Props) => {
  const history = useHistory();

  const isMobile = useIsMobile();
  const { currentUser } = useCurrentUser();
  const toaster = useToaster();

  const [facilityId, setFacilityId] = useState(facilityIdProps);
  const [loading, setLoading] = useState(false);
  const [calendarPayload, setCalendarPayload] =
    useState<ICalendarPayload | null>(null);
  const [pendingChange, setPendingChange] = useState<boolean>(false);
  const [price, setPrice] = useState<Price | null>(null);
  const [isCreatingBooking, setIsCreatingBooking] = useState(false);

  const { facility, isLoading: isFacilityLoading } = useFacility(facilityId, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const { cf } = useCurrencyFormat(facility?.localization?.currencyCode ?? "");

  const venueTabs = [
    {
      id: tabs.ACTIVITIES,
      icon: faTableTennis,
      translationId: "venue.menuitem.activities",
      name: "Aktiviteter",
    },
    {
      id: tabs.BOOK,
      icon: faCalendar,
      translationId: "venue.menuitem.book",
      name: "Boka",
    },
    {
      id: tabs.AMENITIES,
      icon: faStar,
      translationId: "venue.menuitem.amenities",
      name: "Bekvämligheter",
    },
    {
      id: tabs.MEMBERSHIP,
      icon: faFileSignature,
      translationId: "venue.menuitem.membership",
      name: "Medlemskap",
    },
  ];

  useEffect(() => {
    const fetchFavoriteFacility = async () => {
      setLoading(true);

      try {
        const result = await getFavouriteFacility();

        if (!result?.data) {
          return;
        }

        setFacilityId(result?.data);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    };

    if (!facilityId) {
      fetchFavoriteFacility();
    }
  }, [facilityId]);

  const handleCalendarPayload = async (data: ICalendarPayload) => {
    setPendingChange(true);
    if (data?.facilityId && data?.courtId && data?.startTime && data?.endTime) {
      if (!isSpecific) {
        const response = (
          await getSlotPrice(
            data?.facilityId,
            data?.courtId,
            data?.startTime,
            data?.endTime,
          )
        )?.data;

        setPrice(response?.price);
      }

      setCalendarPayload({ ...data });
    } else {
      setCalendarPayload(null);
    }
    setPendingChange(false);
  };

  const handleSubmit = async () => {
    setIsCreatingBooking(true);

    const data: ICreateBookingFormValues = {
      facilityId: calendarPayload?.facilityId,
      courtId: calendarPayload?.courtId,
      startTime: calendarPayload?.startTime,
      endTime: calendarPayload?.endTime,
      type: BookingType.Regular,
      organizerId: currentUser?.id,
      isOrganizerParticipant: true,
      participants: [currentUser?.id],
      paymentType: PaymentType.Solid,
      playersFilterType: PlayersFilterType.Specific,
      gender: null,
      ageGroup: null,
      skillLevelLower: null,
      skillLevelUpper: null,
    };
    try {
      const { data: booking } = await createBooking(data, data?.facilityId);
      const order = await createOrder(data?.facilityId, {
        $type: "booking",
        bookingId: booking.id,
      });
      history.push(getCheckoutPath(order.id));
    } catch (error) {
      toaster.toastError.createBookingFailed();
      console.log(error);
      setIsCreatingBooking(false);
    }
  };

  const handleMenuSelect = (tabSelect: number) => {
    if (tabSelect === tabs.BOOK) return;
    // yep.. copy paste, this will soon be changed
    const tabName =
      Object.keys(queryParam)[Object.values(queryParam).indexOf(tabSelect)];

    history.push(getVenuePath(facility?.id, tabName));
  };

  return (
    <div className="flex w-full flex-col gap-4 xl:gap-8">
      {!isSpecific && (
        <h3>
          <FormattedMessage id="calendar.frequently-played-venue" />
        </h3>
      )}
      {loading || isFacilityLoading ? (
        <ProgressSpinner />
      ) : facility ? (
        <>
          <div className="flex justify-between">
            <div className="flex">
              {!isSpecific && (
                <>
                  <HeaderImage>
                    <Link to={getVenuePath(facility?.id)}>
                      <LogoWithFallback src={facility?.logo} />
                    </Link>
                  </HeaderImage>
                  <div className="mx-5 flex flex-col gap-2">
                    <Link to={getVenuePath(facility?.id)} className="text-lg">
                      <strong>{facility?.name}</strong>
                    </Link>

                    <span>
                      {facility?.address?.city}, {facility?.address?.street}
                      {facility?.address?.streetNumber}
                    </span>
                    {price && <span>{cf(price?.valueInclTax)}</span>}
                  </div>
                </>
              )}
            </div>

            {!isMobile && (
              <Button
                onClick={handleSubmit}
                className="w-32 self-end text-sm"
                type="primary"
                text="Boka"
                translationName="button.book"
                disabled={
                  !calendarPayload || pendingChange || isCreatingBooking
                }
                icon={isCreatingBooking ? faSpinnerThird : undefined}
                iconProps={{ spin: isCreatingBooking }}
              />
            )}
          </div>

          {hasMenu && (
            <>
              <p className="sm:hidden">
                <Link
                  className="flex items-center gap-2"
                  to={getVenuePath(facility?.id, "activities")}
                >
                  <FormattedMessage id="activity.show-all" />
                  <FontAwesomeIcon className="text-xs" icon={faChevronRight} />
                </Link>
              </p>
              <div className="hidden sm:flex">
                <BlockTabs
                  tabs={venueTabs}
                  active={tabs.BOOK}
                  onClick={handleMenuSelect}
                />
              </div>
            </>
          )}
          <CustomerCalendar
            facility={facility}
            onChange={handleCalendarPayload}
            initialPayload={calendarPayload}
          />

          <CalendarRulesReminder />
        </>
      ) : (
        <FakeCalendarBody>
          <NothingToShow
            translationId="user-overview.no-bookings"
            values={{ br: <br /> }}
          />
        </FakeCalendarBody>
      )}
    </div>
  );
};
