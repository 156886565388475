import React from "react";

import { DateTime } from "luxon";
import styled from "styled-components";

import { BookingType } from "../../../../../models/Booking";
import {
  IScheduleResponse,
  IScheduleResponseGrouped,
  IScheduleSlot,
} from "../../../../../models/Calendar";

import { HOURS_TO_DISPLAY } from "../../../../../../../hooks/booking/useCalendarDateChanger";

import CustomerCalendarSlotMobile from "./CustomerCalendarSlotMobile";

interface Props {
  schedules: IScheduleResponseGrouped[];
  fullSchedule: IScheduleResponse[];
  courtId?: string;
  startTime?: DateTime;
  endTime?: DateTime;
  nrOfSlots?: number;
  bookingId?: string;
  bookingType: BookingType;
  startIndex?: number;
}

const CalendarSlotsContainer = styled.div`
  position: relative;
  align-items: center;
  justify-content: start;
  position: relative;
  width: 100%;
  height: 2.5rem;
  display: grid;
  grid-template-columns: repeat(14, 1fr);
  border-radius: var(--border-radius) var(--border-radius) 0 0;
`;

const CustomerCalendarBodyMobile: React.FC<Props> = ({
  schedules,
  fullSchedule,
  nrOfSlots,
  bookingId,
  bookingType,
  startIndex,
}) => {
  const fullScheduleSlotIndex = (startTime: DateTime, courtIndex: number) => {
    return fullSchedule[courtIndex]?.slots.findIndex(
      slot => slot.startTime === startTime,
    );
  };

  return (
    <>
      {schedules.map((row, rowIndex) => (
        <div className="mt-2 flex flex-col gap-1" key={row.objectId}>
          <div>
            <h5>{row?.objectName}</h5>
            <p className="text-xs">{row?.objectType?.name}</p>
          </div>
          <CalendarSlotsContainer key={row.objectId}>
            {row.slots
              .slice(startIndex, startIndex + HOURS_TO_DISPLAY * 2)
              .map((slot: IScheduleSlot | IScheduleSlot[]) => (
                <CustomerCalendarSlotMobile
                  slot={slot}
                  courtId={row.objectId}
                  facilityId={row.facilityId}
                  key={`${row.objectId}-${
                    Array.isArray(slot) ? slot[0]?.startTime : slot.startTime
                  }`}
                  row={rowIndex}
                  column={fullScheduleSlotIndex(
                    Array.isArray(slot) ? slot[0]?.startTime : slot.startTime,
                    rowIndex,
                  )}
                  bookingType={bookingType}
                  nrOfSlots={nrOfSlots}
                  bookingId={bookingId}
                />
              ))}
          </CalendarSlotsContainer>
        </div>
      ))}
    </>
  );
};

export default CustomerCalendarBodyMobile;
