import { Amenities } from "./components/Amenities";
import { GeneralInfo } from "./components/GeneralInfo";
import { OpenHours } from "./components/OpenHours";

export const FacilityGeneralSettings = () => {
  return (
    <div className="flex flex-col gap-20">
      <GeneralInfo />
      <Amenities />
      <OpenHours />
    </div>
  );
};
