import { DateTime } from "luxon";

interface TimeParts {
  hours?: number | undefined;
  minutes?: number | undefined;
  seconds?: number | undefined;
  milliseconds?: number | undefined;
}

export class TimeOnly {
  private dateTime: DateTime;

  private constructor() {
    // Intentionally empty
  }

  public static fromDateTime(dateTime: DateTime): TimeOnly {
    const timeOnly = new this();

    timeOnly.dateTime = dateTime.toUTC(0, { keepLocalTime: true }).set({
      year: 1970,
      month: 1,
      day: 1,
    });

    return timeOnly;
  }

  public static fromISOTime(time: string): TimeOnly {
    return TimeOnly.fromDateTime(DateTime.fromISO(time));
  }

  public plus(datePars: TimeParts): TimeOnly {
    return TimeOnly.fromDateTime(this.dateTime.plus(datePars));
  }

  public minus(datePars: TimeParts): TimeOnly {
    return TimeOnly.fromDateTime(this.dateTime.minus(datePars));
  }

  public toDateTime(): DateTime {
    return this.dateTime;
  }

  public valueOf(): string {
    return this.toISO();
  }

  /**
   * Returns an ISO 8601-compliant string representation in the format `hh:mm:ss`
   */
  public toISO(): string {
    return this.dateTime.toISOTime({
      suppressMilliseconds: true,
      includeOffset: false,
      includePrefix: false,
    });
  }

  /**
   * Returns an ISO 8601-compliant string representation in the format `hh:mm:ss`
   */
  public toJSON(): string {
    return this.toISO();
  }
}
